//** Iguroo Mentee & Mentor UserCard for Request & Profile Link URL for Aggrid & Dashboard **//

import React, { useContext } from "react";
import { _getImage } from "../../common/utils/commonFunctions";
import { Link } from "react-router-dom";
import { ProfileType } from "../../enums";
import { AppUrlsEnum } from "../../enums";
import "./styles.scss";
import { AuthContext } from "../../hooks/auth.context.provider";
import { useMemo } from "react";
import { isMentorOrPre } from "../../helpers";
import { ProfileImage } from "../Media";

function UserCard({
  name,
  picture,
  subtext,
  userId,
  requestNumber,
  reqName,
  className,
  subText,
}) {
  console.log(subtext, "sub--");
  const authContext = useContext(AuthContext);

  const userType = useMemo(() => {
    return authContext?.sessionInfo?.role?.toUpperCase();
  }, [authContext.sessionInfo.role]);
  const profileUrl = useMemo(() => {
    if (!requestNumber && userId) {
      if (isMentorOrPre(userType)) {
        return `${AppUrlsEnum.mentorMenteeProfile}?menteeId=${userId}`;
      } else if (userType === ProfileType.mentee) {
        return `${AppUrlsEnum.menteeMentorProfile}?mentorId=${userId}`;
      }
    }
  }, [userType, userId]);
  
  const requestUrl = useMemo(() => {
    localStorage.setItem(requestNumber, reqName);
    if (isMentorOrPre(userType)) {
      return AppUrlsEnum.mentorRequestDetails.replace(":id", requestNumber);
    } else if (userType === ProfileType.mentee) {
      return AppUrlsEnum.menteeRequestDetails.replace(":id", requestNumber);
    }
  }, [userType, requestNumber, reqName]);

  return (
    <Link
      to={requestNumber ? requestUrl : profileUrl}
      className="text-decoration-none user-card"
    >
      <div className="user-card d-flex  mb-2 align-items-center py-1">
        <div className="pic me-3">
          <ProfileImage
            className="profile-img"
            src={picture}
            alt="avatar"
            height={42}
          />
        </div>
        <div className="info">
          <Link
            to={requestNumber ? requestUrl : profileUrl}
            className="text-decoration-none"
          >
            <h4 className={`${className ? className : ""}`}>{name}</h4>
          </Link>
          <Link
            to={requestNumber ? requestUrl : profileUrl}
            className="text-decoration-none"
          >
            <p className="description">{subtext || subText}</p>
          </Link>
        </div>
      </div>
    </Link>
  );
}

export default UserCard;
