//** User Profile Model Profile Image Upload from Camera or from Files can Rotate,crop etc, used in the code,can re-use it anywhere **//

import React, {
  useState,
  useRef,
  useCallback,
  useContext,
  useEffect,
} from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import Modal from "react-bootstrap/Modal";
import "react-image-crop/dist/ReactCrop.css";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { MenteeOnboardingLabels } from "../../res/localization/index";
import { _getImage } from "../../common/utils/commonFunctions";
import WebCam from "react-webcam";
import { uploadFileToS3 } from "../../common/aws.helpers";
import { AuthContext } from "../../hooks/auth.context.provider";
import { ImgSrcEnums, S3Folders } from "../../enums";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { FormErrorComponent } from "../form.error.component";
import { CustomImage } from "../Media/CustomImage";
import { S3_BUCKET, S3_BUCKET_PENDING } from "../../services/config";

const TO_RADIANS = Math.PI / 180;

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function UserProfileModal(props) {
  const { formElementLabels, formErrors } = useIgurooTranslations();
  const [show, setShow] = useState(false);
  const [imgSrc, setImgSrc] = useState({
    src: props.imgUrl,
    type: ImgSrcEnums.props,
  });
  const [imgFileName, setImgFileName] = useState(
    props.imgUrl ? props.imgUrl?.split("/")[2] : ""
  );
  const [imgSrcCropped, setImageSrcCropped] = useState({
    src: props.imgUrl,
    type: ImgSrcEnums.props,
  });
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(props.aspect);
  const [showWebcam, setShowWebcam] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const webcamRef = React.useRef(null);
  const authContext = useContext(AuthContext);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const capture = useCallback(() => {
    setUploadError(false);
    setCrop(undefined);
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc({ src: imageSrc, type: ImgSrcEnums.webcam });
    setShowWebcam(false);
  }, [webcamRef, setImgSrc, setShowWebcam]);

  useEffect(() => {
    if (!show) {
      setBtnDisabled(true);
    }
  }, [show]);

  const handleClose = (isCanceled = true) => {
    setShowWebcam(false);
    setShow(false);
    setScale(1);
    setRotate(0);
    if (isCanceled) {
      setImgSrc({ ...imgSrcCropped });
    }
  };

  useEffect(() => {
    console.log("props image url", props.imgUrl);
    if (props.imgUrl) {
      setImgSrc({ src: props.imgUrl, type: ImgSrcEnums.props });
      setImageSrcCropped({ src: props.imgUrl, type: ImgSrcEnums.props });
    } else {
      setImgSrc({ src: props.imgUrl, type: ImgSrcEnums.normal, del: true });
      setImageSrcCropped({
        src: props.imgUrl,
        type: ImgSrcEnums.normal,
        del: true,
      });
    }

    if (props.imgUrl) {
      setImgFileName(
        props.imgUrl ? props.imgUrl?.split(".com/")[1]?.split("/")[2] : ""
      );
    }
  }, [props.imgUrl]);

  const handleShow = () => setShow(true);

  const handleSave = useCallback(async () => {
    const image = imgRef.current;
    const cropn = completedCrop;
    const canvas = document.createElement("canvas");
    if (image) {
      setProcessing(true);
      const scaleX = image?.naturalWidth / image?.width;
      const scaleY = image?.naturalHeight / image?.height;
      const pixelRatio = window.devicePixelRatio;
      const ctx = canvas.getContext("2d");
      canvas.width = cropn.width * pixelRatio * scaleX;
      canvas.height = cropn.height * pixelRatio * scaleY;
      ctx.scale(pixelRatio, pixelRatio);
      ctx.imageSmoothingQuality = "high";
      const cropX = cropn.x * scaleX;
      const cropY = cropn.y * scaleY;

      const rotateRads = rotate * TO_RADIANS;
      const centerX = image.naturalWidth / 2;
      const centerY = image.naturalHeight / 2;

      ctx.save();
      // 5) Move the cropn origin to the canvas origin (0,0)
      ctx.translate(-cropX, -cropY);
      // 4) Move the origin to the center of the original position
      ctx.translate(centerX, centerY);
      // 3) Rotate around the origin
      ctx.rotate(rotateRads);
      // 2) Scale the image
      ctx.scale(scale, scale);
      // 1) Move the center of the image to the origin (0,0)
      ctx.translate(-centerX, -centerY);
      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight
      );
      canvas.toBlob(async (blob) => {
        const file = new File([blob], imgFileName, { type: "image/jpeg" });
        fileToDataUrl(file, true);
        const ur = await authContext.uploadFileToS3({
          file: file,
          folder: S3Folders.images,
          bucketName: S3_BUCKET,
        });
        console.log("uploaded key", ur);
        //console.log("signedkey",signedImageKey);
        props.getImageUrl(ur);
        setProcessing(false);
        handleClose(false);
      }, "image/jpeg");
    } else {
      console.log(uploadError, "d-none");
      setUploadError(true);
      console.log(uploadError, "d-none");
    }
  }, [imgRef, completedCrop, scale, rotate]);

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const allowedFormats = ["image/jpeg", "image/png"];

      if (allowedFormats.includes(file.type)) {
        setUploadError(false);
        setCrop(undefined);
        fileToDataUrl(file);
      } else {
        setUploadError(true);
        // setImgSrc(null);
        setImgFileName("");
      }
    }
  }

  function fileToDataUrl(f, setToCroppedImage) {
    const reader = new FileReader();
    setImgFileName(f.name);
    reader.addEventListener("load", () => {
      setImgSrc({
        src: reader.result?.toString() || "",
        type: ImgSrcEnums.normal,
      });
      if (setToCroppedImage) {
        setImageSrcCropped({
          src: reader.result?.toString() || "",
          type: ImgSrcEnums.normal,
        });
      }
    });
    reader.readAsDataURL(f);
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  return (
    <>
      <div className="image-stack">
        <div className="image-stack__item image-stack__item--top">
          <CustomImage
            roundedCircle={imgSrcCropped ? true : false}
            resizeMode="contain"
            className="profile-pic"
            src={imgSrcCropped}
            crossOrigin="anonymous"
          />
        </div>
        <div className="image-stack__item image-stack__item--bottom d-flex justify-content-end">
          <div
            className="img-icon d-flex justify-content-center cursor-pointer"
            onClick={handleShow}
          >
            <Image src={_getImage("camera_profile.svg")} />
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{formElementLabels?.profilePic}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            {processing && (
              <Spinner
                style={{
                  color: `rgb(74, 0, 144)`,
                }}
              />
            )}
            {!!imgSrc && !showWebcam && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => {
                  setCrop(percentCrop);
                  setBtnDisabled(false);
                }}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
                circularCrop={true}
                keepSelection={true}
              >
                <CustomImage
                  imgRef={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                  onLoad={onImageLoad}
                  crossOrigin="anonymous"
                />
              </ReactCrop>
            )}
            {showWebcam && !!imgSrc && (
              <div>
                <WebCam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  style={{
                    width: "100%",
                  }}
                />
                <Button
                  style={{ "background-color": "transparent", color: "black" }}
                  onClick={capture}
                >
                  {formElementLabels?.capturePic}
                </Button>
                <Button
                  className="ms-2"
                  style={{ "background-color": "transparent", color: "black" }}
                  onClick={() => {
                    setShowWebcam(false);
                  }}
                >
                  {formElementLabels?.cancel}
                </Button>
              </div>
            )}
          </div>
          <Row className="Crop-Controls">
            <Col>
              {!!imgSrc && (
                <div>
                  <Form.Label htmlFor="scale-input">
                    {formElementLabels?.scale}
                  </Form.Label>
                  <Form.Range
                    min={1}
                    step={0.2}
                    max={2}
                    id="scale-input"
                    value={scale}
                    disabled={!imgSrc}
                    onChange={(e) => setScale(Number(e.target.value))}
                  />
                </div>
              )}
              {!showWebcam && (
                <div className="text-end">
                  <div
                    role="button"
                    onClick={() => {
                      setShowWebcam(true);
                    }}
                    style={{
                      "border-color": `rgb(74, 0, 144)`,
                      "border-style": "solid",
                      "border-width": "3px",
                      "font-size": "14px",
                    }}
                    className="rounded p-2 d-inline-block"
                  >
                    <img src={_getImage("camera.svg")} />
                    <div className="ms-1 d-inline-block">
                      {MenteeOnboardingLabels.useCamera}
                    </div>
                  </div>
                </div>
              )}
            </Col>
            <Col>
              {!!imgSrc && (
                <div>
                  <Form.Label htmlFor="rotate-input">
                    {formElementLabels?.rotate}
                  </Form.Label>
                  <Form.Range
                    min={-10}
                    step={0.2}
                    max={10}
                    id="rotate-input"
                    value={rotate}
                    disabled={!imgSrc}
                    onChange={(e) =>
                      setRotate(
                        Math.min(180, Math.max(-180, Number(e.target.value)))
                      )
                    }
                  />
                </div>
              )}
              {!showWebcam && (
                <div>
                  <label
                    role="button"
                    style={{
                      "border-color": `rgb(74, 0, 144)`,
                      "border-style": "solid",
                      "border-width": "3px",
                      "font-size": "14px",
                    }}
                    htmlFor="profilePicUpload"
                    className="rounded p-2 d-inline-block"
                  >
                    <img src={_getImage("image.svg")} />
                    <div className="ms-1 d-inline-block">
                      {MenteeOnboardingLabels.uploadProfilePic}
                    </div>
                  </label>
                  <input
                    className="profile-pic-input"
                    id="profilePicUpload"
                    type="file"
                    accept="image/*"
                    onChange={onSelectFile}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row className={`${uploadError ? "d-block" : "d-none"} text-center`}>
            <FormErrorComponent>
              {uploadError && <>{formErrors?.uploaderror}</>}
            </FormErrorComponent>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button
            style={{
              "background-color": "transparent",
              color: "black",
              border: "none",
            }}
            variant="secondary"
            className="align-self-start"
            onClick={handleClose}
          >
            {formElementLabels?.close}
          </Button>
          <Button
            style={{
              "background-color": `#5b2aef`,
            }}
            variant="primary"
            onClick={handleSave}
            disabled={
              imgSrc.src === imgSrcCropped.src &&
              scale === 1 &&
              rotate === 0 &&
              btnDisabled
            }
          >
            {formElementLabels?.upload}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UserProfileModal;
