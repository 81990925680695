//** GinieHealth Learner Login,Text Written QuestionType UI component **//

import { Col } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { CustomInputField } from "../CustomInput/customInputField";

export const TextQuestion = ({ control, type, name }) => {
  return (
    <Col className="answer-input" md={6}>
      <Controller
        name={`${name}.exp`}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <CustomInputField
            hookOnBlur={onBlur}
            hookValue={value}
            hookOnChange={onChange}
            className="mb-3"
            placeholder={"Add Explanation"}
          />
        )}
      />
      <Controller
        name={`${name}.note`}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <CustomInputField
            hookOnBlur={onBlur}
            hookValue={value}
            hookOnChange={onChange}
            className="custom_input"
            placeholder={`Write a ${
              type === "short_answer" ? "short" : "long"
            } answer here`}
          />
        )}
      />
    </Col>
  );
};
