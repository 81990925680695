//** Iguroo Assessment SpeedoMeter Component**//

import React from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { useIgurooTranslations } from "../../../hooks/use.iguroo.translation";

const SpeedoMeter = ({ className, value, bad, good, excellent }) => {
  const { reportAssessmentTranslations } = useIgurooTranslations();
  return (
    <div className={className}>
      <div>
        <ReactSpeedometer
          forceRender={false}
          needleColor="rgba(68, 67, 70, 1)"
          maxValue={10}
          segments={5}
          value={value}
          currentValueText={" "}
          segmentColors={[
            "rgb(223,0,19,100%)",
            "rgb(255,92,33,100%)",
            "rgb(252,223,57,100%)",
            "rgb(208,219,68,100%)",
            "rgb(74,181,95,100%)",
          ]}
          customSegmentLabels={[
            {
              text: bad,
              position: "INSIDE",
              color: "#555",
            },
            {
              text: " ",
              position: "INSIDE",
              color: "#555",
            },
            {
              text: good,
              position: "INSIDE",
              color: "#555",
              fontSize: "19px",
            },
            {
              text: " ",
              position: "INSIDE",
              color: "#555",
            },
            {
              text: excellent,
              position: "INSIDE",
              color: "#555",
            },
          ]}
        />
        <p className="text-center speed_text">{`${
          reportAssessmentTranslations.employabilityQuotient
        } - ${value ? value.toFixed(2) : 0} / 10`}</p>
      </div>
    </div>
  );
};

export default SpeedoMeter;
