import moment from "moment/moment";

export const dateToTimestamp = (date) => {
  return date.getTime() / 1000;
};

export const timeStampToDate = (timeStamp) => {
  return new Date(timeStamp * 1000);
};

export const getReduxCalendarDateExists = (
  sessionsBetweenDates,
  year,
  month
) => {
  if (
    sessionsBetweenDates &&
    sessionsBetweenDates[year] &&
    sessionsBetweenDates[year][month]
  ) {
    return sessionsBetweenDates[year][month];
  }
  return null;
};

export const checkSessionValidity = (time) => {
  const date1 = moment.unix(time);
  const currentDate = moment();
  const duration = moment.duration(date1.diff(currentDate)).asMinutes();
  return duration;
};

export const checkCurrentSessionValidity = (time) => {
  const date1 = moment.unix(time);
  const currentDate = moment();
  const duration = moment.duration(date1.diff(currentDate)).asMilliseconds();
  console.log(
    date1,
    duration,
    Math.abs(moment.duration(date1.diff(currentDate)).asSeconds()),
    "callstart duration"
  );
  return duration / 100;
};

export const checkReScheduleSessionValidity = (time) => {
  const date1 = moment.unix(time);
  const currentDate = moment();
  const duration = moment.duration(date1.diff(currentDate)).asMinutes();
  console.log(duration, "duration");
  return duration;
};

export const checkSessionDuration = (startTime, endTime) => {
  const date1 = moment.unix(endTime);
  const date2 = moment.unix(startTime);
  const duration = moment.duration(date1.diff(date2)).asMinutes();
  return duration;
};

export const getAllMonths = (type, language = "en-US") => {
  // Type can be Long or Short
  return Array.from({ length: 12 }, (item, i) => {
    return {
      value: new Date(0, i).toLocaleString(language, { month: type }),
    };
  });
};

export const getListOfYears = (number) => {
  let arr = [];
  var currentYear = new Date().getFullYear();
  for (let i = 0; i < number; i++) {
    arr.push({ value: currentYear + i });
  }
  return arr;
};
