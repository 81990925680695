import { axiosStaticData } from "../axios.service";
import { SWOT_ANALYSIS } from "../../urlConst";
import axios from "axios";
//const testUrl="http://localhost:3000/dev/swot"
export const postSwot = async (swot) => {
  const URL = SWOT_ANALYSIS;
  const res = await axiosStaticData.post(URL, swot);
  return res.data.response;
};

export const updateSwot = async (data) => {
  const URL = SWOT_ANALYSIS;
  const res = await axiosStaticData.put(URL, data);
  return res.data.response;
};

export const getSwot = async (userId, swotId) => {
  const params = {
    userId: userId,
    swotId: swotId,
  };
  const URL = SWOT_ANALYSIS;
  const res = await axiosStaticData.get(URL, { params });
  return res.data.response;
};

export const setSwotLanguage = async (userId, assessmentLanguage) => {
  const params = {
    userId: userId,
    assessmentlanguage: assessmentLanguage,
  };
  const URL = SWOT_ANALYSIS;
  const res = await axiosStaticData.get(URL, { params });
  return res.data.response;
};
