//** Iguroo SelfAssessment,ProgressBar Assessment Answer calucation Component,can re-use required field **//

import React from "react";
import { ProgressBar} from "react-bootstrap";
import { _getImage } from "../../../common/utils/commonFunctions";
import { useIgurooTranslations } from "../../../hooks/use.iguroo.translation";

export function QuestionProgressBar({ now, max, min }) {
  const { testAssessmentTranslations } = useIgurooTranslations();

  return (
    <div className="progress-container">
      <div className="text-center d-flex align-items-center justify-content-center mt-2">
        <span className="my-auto">
          <b>{testAssessmentTranslations?.question}</b>{" "}
          <span className="ig-progress-span">{now}</span>
        </span>
        <span className="ig-question-progress  align-items-center mx-2">
          <ProgressBar now={now} max={max} min={min ? min : 0} />
        </span>
        <span className="ig-progress-span">{max}</span>
      </div>
    </div>
  );
}
