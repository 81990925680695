import React from "react";
import WorkExpScreen from "../../mentor/workExpScreen";

const WorkExperience = () => {
  return (
    <>
      <WorkExpScreen settings={true} />
    </>
  );
};

export default WorkExperience;
