//** Iguroo & GinieHealth OTP Verification code UI**//

import React, { useState, useContext, useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { Form, Row, Col, Card } from "react-bootstrap";
import { AuthContext } from "../../hooks/auth.context.provider";
import { SubmitButton } from "../../components/submit.button";
import { StrConstants, VerificationCodeEnums } from "../../enums";
import { useDispatch } from "react-redux";
import { loader } from "../../redux-tk/slices/loader.slice";
import { Link, useNavigate } from "react-router-dom";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { VerifyMessageComponent } from "../../components/cardComponent/verification.message";
import { CardSecondaryComponent } from "../../components/cardComponent/card.secondary.text";
import { TextLink } from "../../components/text.link.component";
import { BackButton } from "../../components/back.button";
import { AppUrlsEnum } from "../../enums";
import { Heading } from "../../components/heading.component";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import { Popup } from "../../components/CustomInput/popup";
import OtpInput from "react-otp-input";
import { FormErrorComponent } from "../../components/form.error.component";
import "./styles.scss";

export const ConfirmMobileScreen = ({ pageName, session }) => {
  const {
    formElementLabels,
    formErrors,
    headingTranslations,
    mentorOnboardingLabels,
    headerFooterTranslations,
  } = useIgurooTranslations();

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    // mode:"onBlur",
    // resolver: yupResolver(getVerifiyOtpForm(formErrors)),
  });
  const [timer, setTimer] = useState(60);
  const [pageNameURl, setPageName] = useState("");
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );
  const location = useLocation();
  const userOtp = useRef();
  const [showPopup, setShowPopup] = useState(false);
  const [OTP, setOTP] = useState("");
  const [loginSession, setLoginSession] = useState(session);
  const [pageNameD, setpageNameD] = useState(session);

  //console.log(location.state.pageName,"FORGETPASS")
  // console.log(location.state.pageName,"userOTP")
  useEffect(() => {
    (async function () {
      const pageNam = location?.state?.pageName;
      setpageNameD(pageNam);

      console.log(pageName);
    })();
  }, []);
  useEffect(() => {
    (async function () {
      if (pageNameD === VerificationCodeEnums.forgotPassword) {
        console.log("FORGETPASS", "####");
        setPageName(AppUrlsEnum.authForgetPassword);
      } else if (pageNameD === VerificationCodeEnums.login) {
        console.log("userOTP");
        setPageName(AppUrlsEnum.authUserOtp);
      } else if (pageNameD === VerificationCodeEnums.signup) {
        console.log("signup", pageName);
        setPageName(AppUrlsEnum.authSignup);
      } else {
        console.log("signup", pageName);
        setPageName(AppUrlsEnum.authSignup);
      }

      console.log(pageName);
    })();
  }, [pageNameD]);

  const onSubmit = async (data) => {
    const loginOtp = data.otp;
    console.log(OTP, loginOtp);
    const username = localStorage.getItem(StrConstants.username);
    console.log(username, "username", "otp", OTP?.length);

    try {
      dispatch(loader(true));
      if (OTP?.length !== 6) {
        setError("otp", {
          type: "manual",
          message: formErrors.otpError,
        });
      } else {
        clearErrors("otp");
        if (pageNameD === VerificationCodeEnums.forgotPassword) {
          console.log("sending otp", OTP);
          navigate(AppUrlsEnum.authResetPassword, {
            state: {
              pageName: "resetPassword",
              OTP: OTP,
              session: data.Session,
            },
          });
        } else if (pageNameD === VerificationCodeEnums.login) {
          await authContext.confrimSigninOTP(username.toLowerCase(), OTP, loginSession);
        } else if (pageNameD === VerificationCodeEnums.signup) {
          await authContext.verifyCode(username.toLowerCase(), OTP);
          setShowPopup(true);
        } else {
          await authContext.verifyCode(username.toLowerCase(), OTP);
          setShowPopup(true);
        }
      }
      dispatch(loader(false));
      console.log("made call");
    } catch (err) {
      //setError("apiError", { message: "Invalid OTP entered" });
      console.log(err, "api-error");
      const message = err?.message
        ? err.message
        : JSON.parse(String.fromCharCode.apply(null, err?.httpResponse?.body))
            ?.message;
      if (err.code === "UserNotConfirmedException") {
      } else {
        if (pageNameD === VerificationCodeEnums.login) {
          console.log("userOTP");
          setError("apiError", {
            message: "Invalid OTP entered. Please Check for new OTP",
          });
          const returnData = await authContext.signinWithOTP(username.toLowerCase());
          setLoginSession(returnData.Session);
          resetTimer();
        } else {
          setError("apiError", { message: "Invalid OTP entered" });
        }
      }
      dispatch(loader(false));
    }
  };
  //console.log("err", errors);
  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  // console.log(timer);
  const resetTimer = function () {
    if (!timer) {
      setTimer(60);
    }
  };

  const resendPasswordClick = async () => {
    try {
      const username = localStorage.getItem(StrConstants.username);
      console.log(username, "username");
      console.log("loc", location);
      if (pageNameD === VerificationCodeEnums.forgotPassword) {
        console.log("FORGETPASS", "####");
        const returnData = await authContext.sendCode(username.toLowerCase());
        setPageName(AppUrlsEnum.authForgetPassword);
        resetTimer();
      } else if (pageNameD === VerificationCodeEnums.login) {
        console.log("userOTP");
        const returnData = await authContext.signinWithOTP(username.toLowerCase());
        setLoginSession(returnData.Session);
        resetTimer();
      } else if (pageNameD === VerificationCodeEnums.signup) {
        console.log("signup", pageName);
        await authContext.resendConfirmationCode(username.toLowerCase());
        resetTimer();
      } else {
        console.log("signup", pageName);
        await authContext.resendConfirmationCode(username.toLowerCase());
        resetTimer();
      }
    } catch (err) {
      setError("apiError", { message: err.message });
    }
  };

  return (
    <Row className="sign-head  ig-auth-class  verify-otp justify-content-center align-items-center mt-4 ">
      <Col lg="5" md="8" className="main-container">
        <Card className="verify-page">
          <Card.Body>
            <Row className="mb-4 justify-content-center">
              <Col sm="10" lg="9">
                <Card.Title>
                  <Row>
                    <Col xs="2" className="px-0 mt-2">
                      <Link to={pageNameURl}>
                        <BackButton svgName="arrow-left.svg" />
                      </Link>
                    </Col>
                    <Col
                      xs="10"
                      md={
                        pageNameD === VerificationCodeEnums.signup ? "10" : "8"
                      }
                      className="px-0 mt-3 text-center"
                    >
                      <Heading className="mt-3 ig-auth-heading">
                        {pageNameD === VerificationCodeEnums.signup
                          ? headingTranslations?.confirmMobile
                          : headingTranslations?.enterCode}
                      </Heading>
                    </Col>
                  </Row>
                </Card.Title>

                <VerifyMessageComponent
                  text={
                    pageNameD === VerificationCodeEnums.forgotPassword
                      ? headingTranslations?.verificationMessagePhone
                      : headingTranslations?.verificationMessage
                  }
                />
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="my-3 ig-otp-row">
                    <Controller
                      name={`otp`}
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <OtpInput
                          value={OTP}
                          onChange={(value) => {
                            setOTP(value);
                            clearErrors("apiError");
                          }}
                          numInputs={6}
                          className="ig-OTP-field col p-1"
                          shouldAutoFocus={true}
                        />
                      )}
                    />
                  </Row>

                  <CardSecondaryComponent>
                    {formElementLabels?.resendCode}{" "}
                    {timer > 0 ? (
                      formElementLabels?.resendIn
                    ) : (
                      <TextLink url="" onClick={resendPasswordClick}>
                        {formElementLabels?.resend}
                      </TextLink>
                    )}{" "}
                    {timer > 0 ? timer : ""}
                    {timer > 0 ? formElementLabels?.sec : ""}
                  </CardSecondaryComponent>
                  <br />

                  <FormErrorComponent>
                    {errors.otp && errors.otp?.message}
                  </FormErrorComponent>

                  <FormErrorComponent>
                    {errors.apiError && errors.apiError?.message}
                  </FormErrorComponent>

                  <Col lg="12" xs="12" md="12" xl="12" className="d-grid mt-2">
                    <SubmitButton
                      type="submit"
                      text={
                        pageNameD === VerificationCodeEnums.signup
                          ? headerFooterTranslations?.btnConfirm
                          : headerFooterTranslations?.signIn
                      }
                    />
                    <Popup
                      closeOnBackClick={false}
                      showPopup={showPopup}
                      type="popup"
                      text={formElementLabels.signIn}
                      modalBody={mentorOnboardingLabels?.successMessage}
                      url={AppUrlsEnum.authSignin}
                      title={headingTranslations?.success}
                      homeLink={formElementLabels.homePage}
                      submitText={formElementLabels?.signIn}
                      className="OTPSuccess"
                    />
                  </Col>
                </Form>
                <Row className="sigin-pwd text-center my-4 justify-content-center">
                  <Link to={pageNameURl}> {formElementLabels?.cancel}</Link>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
