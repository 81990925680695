import Amplify from "aws-amplify";
import {
  region,
  userPoolId,
  clientId,
  identityPoolId,
  awsAmplifyDomain,
} from "./config";

export const amplifyConfig = {
  Auth: {
    region: region,
    userPoolId: userPoolId,
    userPoolWebClientId: clientId,
    identityPoolId: identityPoolId,
    oauth: {
      domain: awsAmplifyDomain,
      scope: ["email", "profile", "openid"],
      redirectSignIn: "https://ig-web-channel.sb.iguroo.com/auth/signin/social",
      redirectSignOut: "https://ig-web-channel.sb.iguroo.com/auth/signin/social",
      responseType: "token",
    },
  },
};
