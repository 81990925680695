//** Iguroo SelfAssessment,CustomText Answer Radio option Component,can re-use required field **//

import React from "react";
import { Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const CustomAnswerOptions = (props) => {
  const { options, hookValue, hookOnBlur, hookOnChange, name, type } = props;

  if (options?.length === 0) {
    return (
      <Spinner animation="border" variant="primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  return (
    <div key={""} className="custom_drop_down_container">
      {options.map((data, index) => {
        return (
          <Form.Check
            key={index}
            className="radio_option_row"
            label={data.value}
            value={data.value}
            checked={data.value === hookValue}
            onBlur={hookOnBlur}
            onChange={hookOnChange}
            name={name}
            type={type}
            //id={`inline-radio-1`}
          />
        );
      })}
    </div>
  );
};

export default CustomAnswerOptions;
