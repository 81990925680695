import React, { useContext } from "react";
import { CardSecondaryComponent } from "../cardComponent/card.secondary.text";
import { Link } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import './styles.css';

export default function CourseProgress(props) {
    const {
        total,
        completeted,
        className,
     

    } = props;
    return (<>
        {/* <CardSecondaryComponent className="courseProgress-text">
            Your progress <b>{completeted} of {total} completed
                {' '}<Link to={'#'}>Get Cerificate</Link></b>
        </CardSecondaryComponent> */}
        <br />
        <ProgressBar
            className={`${className}  course-progress`} now={(completeted / total) * 100}
        />

    </>);
}