//** Dashboard Mentee RequestDeatiled Page **//

import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import globe from "../../assets/images/globe.svg";
import "plyr-react/plyr.css";
import { useSelector } from "react-redux";
import { calculate_age, isMentorOrPre } from "../../helpers/commons.helpers";
import { useDispatch } from "react-redux";
import { loader } from "../../redux-tk/slices/loader.slice";
import { GetUserProfile } from "../../services/axios/MentorOnbaording";
import { useLocation } from "react-router-dom";
import {
  getRequestdetails,
  getSessionList,
} from "../../services/axios/requests";
import { Heading } from "../../components/heading.component";
import { StatusRenderer } from "../../components/agGridRenderers/status.renderer";
import { CategoryComponent } from "../../components/category.component";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { FormHeading } from "../../components/form.heading.component";
import { FileCards } from "../../components/file.card";
import SessionCardList from "../../components/RequestSession";
import { SkipButton } from "../../components/skip.button";
import { SubmitButton } from "../../components/submit.button";
import { ProfileType, RequestStatus } from "../../enums";
import ProfileInfo from "../../components/ProfileInfo";
import { ProfileImage } from "../../components/Media";
import { getRating } from "../../services/axios/session";
import { GetTotalSessions } from "../../services/axios/Dasboard";

const RequestPageDetails = (state) => {
  const { formElementLabels } = useIgurooTranslations();
  const [userProfile, setUserProfile] = useState({});
  const [requests, setRequests] = useState({});
  const [status, setStatus] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const [sessionData, setSessionData] = useState({});
  const user = useSelector((state) => state.user.profile);
  const [layout, setLayout] = useState(false);
  const [rating, setRating] = useState("0");
  const [sessionsHeld, setSessionsHeld] = useState("0");
  useEffect(() => {
    (async function () {
      dispatch(loader(true));
      const thePath = location.pathname;
      const lastItem = thePath.substring(thePath.lastIndexOf("/") + 1);
      const requestData = await getRequestdetails(lastItem);
      console.log(requestData, "requestData");
      setRequests(requestData?.Items[0]);
      const sessionDetails = await getSessionList(lastItem);
      setSessionData(sessionDetails);
      let userId = "";
      if (isMentorOrPre(user?.type)) {
        userId = requestData?.Items[0].userId;
      } else {
        userId = requestData?.Items[0].mentorId;
      }
      const stat = {
        status: requestData?.Items[0].status,
      };
      setStatus(requestData?.Items[0].status);
      const userDetails = await GetUserProfile(userId);
      setUserProfile(userDetails?.data);
      console.log(userDetails,'userDetails');
      getRating(userId)
        .then((res) => {
          console.log("LOG Rating", res?.data?.response);
          setRating(res?.data?.response);
        })
        .catch((err) => console.log(err));

      GetTotalSessions(userId).then((resp) => {
        console.log("LOG Sessions", resp);
        setSessionsHeld(resp);
      });
      dispatch(loader(false));
    })();
    return () => {
      const thePath = location.pathname;
      const lastItem = thePath.substring(thePath.lastIndexOf("/") + 1);
      localStorage.removeItem(lastItem);
    };
  }, []);

  return (
    <Container className="mb-4 " style={{ fontFamily: "manrope" }}>
      <Row>
        <Col md={5} lg={4} className="mb-5">
          {isMentorOrPre(user?.type) ? (
            <>
            {console.log(userProfile?.profilePic,'userProfile?.profilePic final')}
              <Container>
               {userProfile?.profilePic ? 
               <ProfileImage
                  className="mentee-profile-img mb-4 d-block"
                  src={userProfile?.profilePic}
                  alt="Profile Pic "
                  height={128}
                  width={128}
                />:
                <ProfileImage
                  className="mentee-profile-img mb-4 d-block Profile"
                  src={""}
                  alt="Profile"
                  height={128}
                  width={128}
                />}
              </Container>
              <Container className="mentee-profile-info">
                <ul
                  className="mentee-profile-info-list"
                  style={{ listStyleType: "none", padding: 0 }}
                >
                  <li>
                    <h5 className="mentee-profile-name fw-700">
                      {userProfile?.firstName} {userProfile?.lastName}
                    </h5>
                  </li>

                  <li className="fs-14">
                    {calculate_age(userProfile?.dob)} Years /{" "}
                    {userProfile?.gender?.value}
                  </li>
                  {/* <li className="fs-14">{userProfile?.timeZone?.value}</li> */}
                  <li className="fs-14">
                    <Image height={15} width={15} src={globe} />{" "}
                    {userProfile?.languages?.map((language, i) =>
                      userProfile.languages.length === i + 1
                        ? language.value
                        : `${language.value}, `
                    )}
                  </li>
                </ul>
              </Container>{" "}
            </>
          ) : (
            <>
            {/* Would be used by more sessions */}
              {/* <div>
                <CustomIconBtn
                  text={"Edit"}
                  svgName={"edit.svg"}
                  className="pg-req-button "
                />
                <CustomIconBtn
                  text={"Stop"}
                  svgName={"pause.svg"}
                  className="pg-req-button "
                />
                <CustomIconBtn
                  text={"Delete"}
                  svgName={"trash-red.svg"}
                  className="ig-icon-delete pg-req-button "
                />
              </div> */}
              <Row className="mt-3">
                <FormHeading>{ProfileType.mentor}</FormHeading>
              </Row>
              <ProfileInfo
                {...userProfile}
                hideBtn={true}
                showSendMsgBtn={true}
                rating={rating}
                sessionsHeld={sessionsHeld}
              />
            </>
          )}
        </Col>
        <Col md={7} lg={8}>
          <div className="m-0 p-0 mb-2">
            <StatusRenderer value={status} />
          </div>
          <Heading className="headingDashboard">
            {requests?.title?.trim().charAt(0).toUpperCase() +
              requests?.title?.trim().slice(1)}
          </Heading>
          <div className="m-0 mt-2 p-0 my-2">
            {requests?.category?.map((category) => (
              <CategoryComponent
                className={"category"}
                title={category.value}
              />
            ))}
          </div>
          <Row>
            <Col>
              <div className="description mb-4">
                {requests?.description?.trim().charAt(0).toUpperCase() +
                  requests?.description?.trim().slice(1)}
              </div>
            </Col>
          </Row>
          <Row>
            {requests?.files?.map((file) => {
              return (
                <FileCards fileUrl={file?.fileUrl}>{file?.fileName}</FileCards>
              );
            })}
          </Row>
          <Col lg={12} className="mt-3">
            <Row>
              <Col sm={12} className="mb-4">
                {sessionData?.Items ? (
                  <SessionCardList
                    title="Sessions"
                    badge={sessionData?.Count}
                    data={sessionData?.Items}
                    status={requests?.status}
                    userDetails={userProfile}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Col>
        </Col>
        {requests?.status?.toUpperCase() === RequestStatus.pending &&
        isMentorOrPre(user?.type) ? (
          <>
            <Row>
              {" "}
              <Col>
                <hr />
              </Col>
            </Row>
            <Row className="text-end mb-4 justify-content-end">
              <Col xs="6" sm="2" md="2" className="d-grid">
                <SkipButton
                  className="ig-reject-btn"
                  text={formElementLabels?.reject}
                />
              </Col>
              <Col xs="6" sm="2" md="2" className="d-grid">
                <SubmitButton text={formElementLabels?.accept} />
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
      </Row>
    </Container>
  );
};
export default RequestPageDetails;
