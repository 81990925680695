import * as yup from "yup";

const phoneNumberRegEx =
  /^((\+[1-9]{1,4})(([0-9]{2,3})[-]*)([0-9]{2,4})[-]*)*?[0-9]{3,4}?[-]*[0-9]{3,4}?$/;

export function getContactForm(formErrors) {
  return yup.object({
    firstname: yup
      .string()
      .required(formErrors?.firstname)
      .matches(/^[a-zA-Z0-9\s&,().-]+$/, formErrors?.nameNumSplCharcError),
    lastname: yup
      .string()
      .required(formErrors?.lastname)
      .matches(/^[a-zA-Z0-9\s&,().-]+$/, formErrors?.nameNumSplCharcError),
    mobileNumber: yup
      .string()
      .min(4, formErrors?.mobileNumber)
      .max(13, formErrors?.mobileNumber)
      .required(formErrors?.mobileNumber)
      .matches(phoneNumberRegEx, formErrors?.enterOnlyNumber),
    email: yup
      .string()
      .required(formErrors?.email)
      .test("test-name", formErrors?.enterOnlyEmail, function (value) {
        const emailRegex =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        let isValidEmail = emailRegex.test(value);
        if (!isValidEmail) {
          return false;
        }
        return true;
      }),
    aboutMessage: yup.string().required(formErrors?.aboutMessage),
  });
}
