//** Edit Account,Data was not saved Popup Flag Slice **//

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showPopupflag: false,
};

export const showPopupflagSlice = createSlice({
  name: "popupFlag",
  initialState,
  reducers: {
    showPopupflag: (state, action) => {
      state.showPopupflag = action.payload;
    },
  },
});

export const { showPopupflag } = showPopupflagSlice.actions;

export const getShowPopupFlag = (state) => state.showPopupflag;

export default showPopupflagSlice.reducer;
