//** GinieHealth Learner Login,CheckBoxRadio Options Question UI component code **//

import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "./component/styles.scss";
import update from "immutability-helper";
import { useForm, Controller } from "react-hook-form";
import { TestQuestionComponent } from "./component/question.component";
import MutliCheckBoxRadioAnswerOptions from "./component/custommultiradioOption";
import {
  TimeTakenValue,
  TimeleftValue,
  answerSelected,
  answerValue,
  changeGLCurrentQuestion,
  changeTimeTaken,
  currentGLQuestionValue,
} from "../../redux-tk/slices/genieLabs.currentQuestion";
import { useDispatch, useSelector } from "react-redux";
import QFooter from "../questions/qFooter";
import { AuthContext } from "../../hooks/auth.context.provider";
import { FormErrorComponent } from "../form.error.component";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { loader } from "../../redux-tk/slices/loader.slice";
import { PopupQuestion } from "./component/popup";
import { AppUrlsEnum } from "../../enums";
import { MultiMultiTest } from "./component/heading.component";
import { useNavigate } from "react-router-dom";
import { UpdateAssessmentAttemptApi } from "@iguroo/ig-react-assessment-package";
import Signature from "../Signature";
import moment from "moment";
import CheckBoxRadioAnswerOptions from "./component/customradioOption";

const RadioButtonQuestion = (props) => {
  const { testAssessmentTranslations, genieLabsAssessmentTranslations } =
    useIgurooTranslations();
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    setFocus,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const currentQuestion = useSelector((state) => currentGLQuestionValue(state));
  const timeLeft = useSelector((state) => TimeleftValue(state));
  const timeTaken = useSelector((state) => TimeTakenValue(state));
  const selectedAnswers = useSelector((state) => answerValue(state));
  const [answers, setAnswers] = useState(selectedAnswers);
  const [selectAnswer, setSelectAnswers] = useState([]);
  const [attemptdata, setAttemptData] = useState({});
  const [isSubmitting, setisSubmitting] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [showTimerPopup, setShowTimerPopup] = useState(false);
  const [meta_data, setmeta_data] = useState(null);
  const inputRef = useRef();

  useEffect(() => {
    dispatch(loader(true));
    // const op = [];
    // var meta_data = "";
    // console.log(props,'1655 console',meta_data?.signature_description? meta_data?.signature_description : genieLabsAssessmentTranslations?.signaturSubHeader)
    props?.assessmentData?.assessment_metadata?.forEach((val) => {
      if (val?.metadata_section === "signature_info") {
        // meta_data = val;
        console.log(val, "1655 console");
        setmeta_data(val);
      }
    });
    const ansSelect = selectedAnswers.filter(
      (ans) => ans.question_id === props?.questionId
    );
    if (ansSelect.length > 0) {
      // console.log(ansSelect[0].answer, "console answw");
      setisSubmitting(false);
      setSelectAnswers(ansSelect[0].answer);
      setTimeout(() => {
        //inputRef.current.focus();
        setValue(`answerOption`, ansSelect[0].answer[0]?.options);
        setisSubmitting(false);
      }, 3);
      
     
    } else {
      setisSubmitting(false);
    }
    setAnswers(selectedAnswers);
    dispatch(loader(false));
  }, []);
 useEffect(() => {
  console.log("end test 1655 answerOption");
  inputRef?.current?.focus();
    setFocus("answerOption")
  }, [setFocus])
  useEffect(() => {
   
    if (
      timeLeft == 0 &&
      props?.assessmentData?.isTimeBoxed &&
      timeLeft != null
    ) {
      console.log("end test 1655", props?.assessmentData);
      setShowTimerPopup(true);
      setTimeout(() => {
        SignatureSubmit();
      }, 10000);
    }
    // console.log(timeLeft, "end test 1655", props?.assessmentData);
  }, [timeLeft]);
  const previousClick = () => {
    console.log("next click", currentQuestion);
    if (currentQuestion > 0)
      dispatch(changeGLCurrentQuestion(currentQuestion - 1));
    dispatch(changeTimeTaken(timeLeft));
  };

  const authContext = useContext(AuthContext);
  console.log("165 name", props?.assessmentData?.title);
  const onNextClick = (data) => {
    setisSubmitting(true);
    dispatch(loader(true));
    let isEmptyAllow = false;
    let isEmpty = false;
    // console.log(isEmptyAllow,props?.assessmentData?.isSkippingAllowed,'console  log 165')
    if (props?.assessmentData?.isSkippingAllowed) {
      isEmptyAllow = props?.assessmentData?.isSkippingAllowed;
    }
    if (data?.answerOption === undefined) isEmpty = true

    if (!isEmptyAllow && isEmpty) {
      setError("answerOption", {
        message: testAssessmentTranslations?.AssessmentOptionError,
      });
      dispatch(loader(false));
      setisSubmitting(false);
    } else {
      if (props.totalQuestion == currentQuestion + 1) {
        setShowPopup(true);
      }
      const ansSelect = answers.filter(
        (ans) => ans.question_id === props?.questionId
      );
      let ans = [];
      const seconds = Math.floor((timeLeft / 1000) % 60);
      const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
      const hours = Math.floor((timeLeft / 1000 / 60 / 60) % 24);
      let timeleftDetails = 0;
      if (timeLeft <= 60) {
        timeleftDetails = minutes + 1 + " mins " + seconds + " sec ";
      } else {
        timeleftDetails =
          hours * 60 + (minutes + 1) + " mins " + seconds + " sec ";
      }
      var commentIndex = answers.findIndex(
        (c) => c.question_id === props?.questionId
      );
      let answer = {
        question_id: props.questionId,
        answer: [],
        isMark: false,
      };
      if (props?.assessmentData?.duration) {
        let timeTaken1 = 0;
        if (timeTaken === 0) {
          const total =
            Date.parse(props?.assessmentData.duration) - Date.parse(new Date());
          timeTaken1 = total - timeLeft;
        } else {
          timeTaken1 = timeTaken - timeLeft;
        }
        timeTaken1 = Math.floor((timeTaken1 / 1000) % 60);
        let time = 0;
        if (ansSelect.length !== 0) time = answers[commentIndex].timeTaken;
        console.log("commentIndex 165", time);
        answer.totaltimeleft = timeLeft;
        answer.timeleft = timeleftDetails;
        answer.timeTaken = timeTaken1 + time;
        console.log("console 165", answer, timeTaken);
      }
      // props?.question?.perspectives?.map((perspective) => {
      const ansData = {};
      ansData.options = data.answerOption;
      answer.answer.push(ansData);
      // });
      // answer.answer =[data.answerOption]
      if (ansSelect.length === 0) {
        ans = [...answers, answer];
        setAnswers([...answers, answer]);
      } else {
        let updatedItems = answers;
        if (commentIndex > -1) {
          updatedItems = update(answers, {
            [commentIndex]: { $set: answer },
          });
        }
        ans = updatedItems;
      }
      dispatch(answerSelected(ans));
      const attemptData = {
        attempt_id: props?.attempid,
        status:
          currentQuestion + 1 == props?.totalQuestion
            ? "COMPLETED"
            : "INPROGRESS",
        answers: ans,
        assessment_version_id: props?.assessment_version_id,
        userId: authContext.sessionInfo.sub,
      };
      if (props?.assessmentData?.duration) {
      }
      setAttemptData(attemptData);
      if (
        props.totalQuestion == currentQuestion + 1 &&
        (!props?.assessmentData?.show_assessment_signature ||
          props?.assessmentData?.show_assessment_signature == undefined)
      ) {
        UpdateAssessmentAttemptApi(attemptData);
        dispatch(changeTimeTaken(timeLeft));
      }
      if (props.totalQuestion > currentQuestion + 1) {
        UpdateAssessmentAttemptApi(attemptData)
          .then((res) => {
            dispatch(changeGLCurrentQuestion(currentQuestion + 1));
            dispatch(changeTimeTaken(timeLeft));
            dispatch(loader(false));
            setisSubmitting(false);
          })
          .catch((err) => console.log(err));
      } else {
        dispatch(loader(false));
        setisSubmitting(false);
      }
    }
  };

  const navigate = useNavigate();
  const SignatureSubmit = (data) => {
    dispatch(loader(true));
    let attempt = attemptdata;
    if (Object.keys(attemptdata).length === 0) {
      console.log("Object is empty");
      attempt = {
        attempt_id: props?.attempid,
        status: "INPROGRESS",
        answers: answers,
        assessment_version_id: props?.assessment_version_id,
        userId: authContext.sessionInfo.sub,
      };
    }
    if (data !== undefined) attempt.user_signature = data;
    console.log(data, attempt, "signature console 165");
    UpdateAssessmentAttemptApi(attempt).then((res) => {
      console.log(showTimerPopup, "end 1655");
      dispatch(loader(false));
      setisSubmitting(false);
      setTimeout(() => {
        //inputRef.current.focus();
        navigate(AppUrlsEnum.learnermyassessment, {
          state: { completed: "true" },
        });      
      }, 100);
      
    })
    .catch((err)=>{
      dispatch(loader(false));
    });
  
  };

  const onMarkClick = () => {
    const ansSelect = answers.filter(
      (ans) => ans.question_id === props?.questionId
    );
    let ans = [];
    const answer = {
      question_id: props.questionId,
      answer: [],
      isMark: true,
    };
    if (ansSelect.length === 0) {
      ans = [...answers, answer];
      setAnswers([...answers, answer]);
    } else {
      var commentIndex = answers.findIndex(
        (c) => c.question_id === props?.questionId
      );
      let updatedItems = answers;
      if (commentIndex > -1) {
        updatedItems = update(answers, {
          [commentIndex]: { $set: answer },
        });
      }
      ans = updatedItems;
    }
    dispatch(answerSelected(ans));
    const attemptData = {
      attempt_id: props?.attempid,
      status: "INPROGRESS",
      answers: ans,
      assessment_version_id: props?.assessment_version_id,
      userId: authContext.sessionInfo.sub,
    };
    //console.log(data, attempt, "signature console 165");
    UpdateAssessmentAttemptApi(attemptData).then((res) =>
      dispatch(changeGLCurrentQuestion(currentQuestion + 1))
    );
  };
  const checkKeyDown = (e) => {
    console.log(e, "answersss 165 checkKeyDown", props?.question?.options);
    if (e.key === 'Enter') {
      console.log(errors, "answersss 165 checkKeyDown", props?.question?.options);

      handleSubmit(onNextClick)();
    }

  };
  return (
    <Container className="">
      <Row className="d-flex justify-content-center">
        {props?.assessmentData?.show_assessment_signature ? (
          <Signature
            show={showPopup}
            onHide={() => setShowPopup(false)}
            header={
              meta_data?.signature_title
                ? meta_data?.signature_title
                : "Electronic Signature"
            }
            subHeader={
              meta_data?.signature_description
                ? meta_data?.signature_description
                : genieLabsAssessmentTranslations?.signaturSubHeader
            }
            signature={`${authContext.sessionInfo.firstName} ${authContext.sessionInfo.lastName}`}
            date={moment(new Date()).format("MM/DD/YYYY")}
            signatureDisabled={false}
            dateDisabled={false}
            submit={SignatureSubmit}
          />
        ) : (
          <PopupQuestion
            closeOnBackClick={false}
            showPopup={showPopup}
            type="popup"
            title={"Assessment Completed"}
            submitText={"OK"}
            modalBody={"Assessment Submited Successfully"}
            gotIt={() =>
              navigate(AppUrlsEnum.learnermyassessment, {
                state: { completed: "true" },
              })
            }
          />
        )}
        <PopupQuestion
          closeOnBackClick={() =>
            navigate(AppUrlsEnum.learnermyassessment, {
              state: { completed: "true" },
            })
          }
          showPopup={showTimerPopup}
          type="popup"
          title={"TIme is Up"}
          submitText={"OK"}
          hideSubmitButton={true}
          modalBody={"Assessment Submited Successfully "}
          gotIt={() =>
            navigate(AppUrlsEnum.learnermyassessment, {
              state: { completed: "true" },
            })
          }
        />

        <Col sm="11">
          <Row className="mb-4">
            <MultiMultiTest className="text-capitalize ig-multiweb-header ig-multiweb-header-color">
              {props?.section_title.split("_")[0]}
            </MultiMultiTest>
          </Row>
          <Row className="mb-4">
            <MultiMultiTest className="text-capitalize ig-multiweb-header ">
              {props?.section_title.split("_")[1]}
            </MultiMultiTest>
          </Row>
          <Row className="mt-3">
            <Col>
              <TestQuestionComponent
                text={props?.question?.question_text}
                queNo={currentQuestion + 1}
              />
            </Col>
          </Row>
          <Row className="">
            <Form onSubmit={handleSubmit(onNextClick)} onKeyDown={checkKeyDown} className="mt-1">
              <Row className="test-answer-block p-4 mt-2 ">
                {" "}

                <Col md={12}>

                  <Controller
                    name={`answerOption`}
                    control={control}
                    ref={inputRef}
                    render={({
                      field: { onChange, onBlur, value, name },
                    }) => (
                      <CheckBoxRadioAnswerOptions
                        type={"radio"}
                        hookOnChange={onChange}
                        hookOnBlur={onBlur}
                        options={props?.question?.options}
                        onChange={() => clearErrors(`answerOption`)}
                        hookValue={value}
                        name={name}
                        
                        defaultChecked={selectAnswer[0]?.options}
                        id={'options'}
                        disabled={isSubmitting}
                      />
                    )}
                  />
                </Col>
                {/* );
                })} */}
              </Row>
              <Row>
                <FormErrorComponent>
                  {errors?.answerOption && errors?.answerOption?.message}
                </FormErrorComponent>
              </Row>
              <Row>
                <QFooter
                  showPrevious={
                    props?.assessmentData?.isReverseAllowed !== undefined
                      ? currentQuestion == 0
                        ? false
                        : props?.assessmentData?.isReverseAllowed
                      : currentQuestion == 0
                        ? false
                        : true
                  }
                  onPreviousClick={previousClick}
                  onCheckClick={console.log("Check Btn click")}
                  onMarkClick={onMarkClick}
                  // showMark={ props?.assessmentData?.isMarketingAllowed == undefined || !props?.assessmentData?.isMarketingAllowed ? false:true}
                  nextText={
                    props?.totalQuestion !== currentQuestion + 1
                      ? genieLabsAssessmentTranslations?.next
                      : genieLabsAssessmentTranslations?.submit
                  }
                  onNextClick={() => {
                    clearErrors("answerOption");
                    handleSubmit(onNextClick);
                  }}
                  isSubmitting={isSubmitting}
                />
              </Row>
            </Form>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default RadioButtonQuestion;
