//** GinieHealth can view Assessmentcard ProgressBar **//

import { Button, Card, Col } from "react-bootstrap";
import AssessmentProgressView from "./AssessmentProgressView";

const AssessmentSideBar = (props) => {
  const { data, stage, title, btnValue, currentTab } = props;
  return (
    <Col md={3}>
      <Card className="p-3 border-0 shadow" style={{ width: "240px" }}>
        <Card.Title>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              margin: 0,
              padding: 0,
            }}
          >
            {title}
          </span>
        </Card.Title>
        {Object.keys(data).map((val, index) => {
          const value = data[val];
          // const url = stage + 1 > index ? value.url : "";
          return (
            <AssessmentProgressView
              key={index}
              myKey={value.key}
              value={value.value}
              isChecked={value.key === currentTab.key}
              isActive={stage > index}
              url={""}
            />
          );
        })}
        <Button className="mt-3" disabled>
          {btnValue}
        </Button>
      </Card>
    </Col>
  );
};

export default AssessmentSideBar;
