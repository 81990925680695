//** Common S3 Image & PublicProfile Image Slice **//

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUploadFileFromS3 } from "../../common/aws.helpers";

export const getImageFromKeyAsyncThunk = createAsyncThunk(
  "images/getImageFromKeyAsyncThunk",
  async (data, thunkApi) => {
    try {
      //console.log("fetching image thunk",data);
      const img = await getUploadFileFromS3(data.key, data.isPublicProfile);
      console.log("getUserAsyncThunk user", img);
      return { key: data.key, img: img };
    } catch (err) {
      console.log("api error", err);
      thunkApi.rejectWithValue(err);
    }
  }
);

const initialState = {
  images: {},
};

export const imagesSlice = createSlice({
  name: "images",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getImageFromKeyAsyncThunk.fulfilled, (state, action) => {
        console.log("ac us'", action);
        if (!action?.payload) {
          return;
        }
        const key = action.payload.key;
        const img = action.payload.img;
        state.images[key] = img;
      })
      .addCase(getImageFromKeyAsyncThunk.pending, (state) => {})
      .addCase(getImageFromKeyAsyncThunk.rejected, (state) => {});
  },
});

export const {} = imagesSlice.actions;

export const imageValueSelector = (state, key) => state.images.images[key];

export default imagesSlice.reducer;
