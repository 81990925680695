//** Iguroo & GinieHealth Social Auth Ui,was not implemented for now,Infuture will using this SocialLogin **//

import React, { useContext, useEffect, useState } from "react";
import { Amplify, Auth, Hub } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { amplifyConfig } from "../../services/amplify.config";
import { Button, Image } from "react-bootstrap";
import { AuthContext } from "../../hooks/auth.context.provider";
import google from "../../assets/images/google.svg";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
// import LoginHooks from "./login.hook";

Amplify.configure(amplifyConfig);

export const AwsSocialLogin = () => {
  const [user, setUser] = useState(null);
  const [customState, setCustomState] = useState(null);
  const authContext = useContext(AuthContext);
  const { formElementLabels } = useIgurooTranslations();

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          setUser(data);
          console.log("signin data", data);
          authContext.signinWithGoogle(data.username, data);
          break;
        case "signOut":
          setUser(null);
          break;
        case "customOAuthState":
          setCustomState(data);
      }
    });

    Auth.currentAuthenticatedUser()
      .then((currentUser) => setUser(currentUser))
      .catch(() => console.log("Not signed in"));

    return unsubscribe;
  }, []);
  const responseGoogle = (response) => {
    console.log(response);
  };

  return (
    <>
      {/* <button onClick={() => Auth.federatedSignIn()}>Open Hosted UI</button>
      <button
        onClick={() =>
          Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Facebook,
          })
        }
      >
        Open Facebook
      </button>
      <button
        onClick={() =>
          Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google,
          })
        }
      >
        Open Google
      </button> */}
      <Button
        className="signin-btn"
        onClick={() =>
          Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google,
          })
        }
      >
        <Image src={google}></Image>
        {formElementLabels?.contiueBtn}
      </Button>
      {/* <button
        onClick={() =>
          Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Amazon,
          })
        }
      >
        Open Amazon
      </button>
      <button
        onClick={() =>
          Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Apple,
          })
        }
      >
        Open Apple
      </button> */}
      {/* <button onClick={() => Auth.signOut()}>Sign Out</button> */}
      <div>{user && user.getUsername()}</div>
      {/* <LoginHooks></LoginHooks> */}
    </>
  );
};
