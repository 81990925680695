//** Iguroo & GinieHealth Common AddMore Button Component,can re-use any required field **//

import React from "react";
import { Button } from "react-bootstrap";
import { _getImage } from "../../common/utils/commonFunctions";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";

export function CustomAddMoreBtn(props) {
  const { variant, className, onClick, type, text } = props;
  const { formElementLabels } = useIgurooTranslations();

  return (
    <Button
      type={type ? type : "button"}
      variant={variant ? variant : "outline-secondary"}
      className={`mentorAddBtn ${className}`}
      onClick={onClick}
    >
      {[
        <img className="Add-btn" src={_getImage("plus-circle.svg")} />,
        text ? text : formElementLabels.addAnother,
      ]}
    </Button>
  );
}
