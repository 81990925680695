//** Iguroo & GinieHealth,Common BreadCrum Component,Have used & loacated in dashboard Layout for the Links of the UI **//

import React, { useEffect, useState } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { showPopupflag } from "../../redux-tk/slices/showPopupflag.slice";
import { Container } from "react-bootstrap";
import { PopupUnsaved } from "../CustomInput/popupUnsaved";
import { ProfileType, AppUrlsEnum } from "../../enums";
import { getRequestdetails } from "../../services/axios/requests";
import { useParams } from "react-router-dom";

const Breadcrum = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const popupFlag = useSelector((state) => state.showPopupflag.showPopupflag);
  const [showPopup2, setShowPopup2] = useState(false);
  const [requestTitle, setRequestTitle] = useState("");
  const [urlFlag, setUrlFlag] = useState("");
  const [routes, setRoutes] = useState([]);
  const profile = useSelector((state) => state.user.profile);
  const location = useLocation();
  const loc = location?.pathname?.split("/");
  // console.log(routes,'routes',location);
  let path = "";

  const handleClick = () => {
    if (props.gotIt) {
      props.gotIt();
    }
  };

  const handleLeave = () => {
    setShowPopup2(false);
    (async function () {
      dispatch(showPopupflag(false));
    })();
  };

  const handleStay = () => {
    setShowPopup2(false);
    (async function () {
      dispatch(showPopupflag(true));
    })();
  };

  const getDetails = (id) => {
    getRequestdetails(id)
      .then((res) => {
        if (res?.Items[0]?.title) {
          setRequestTitle(res?.Items[0]?.title);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setRoutes(loc.filter((route) => route));
    return () => {
      setRequestTitle("");
      setRoutes([]);
    };
  }, [location]);

  return (
    <Container>
      <Breadcrumb
        className={`dashboard-breadcrumb ${props.classes ? props.classes : ""}`}
      >
        {routes.map((route, i) => {
          if (
            routes[routes.length - 2] === "requests" &&
            i === routes.length - 1 &&
            id
          ) {
            getDetails(route);
            route = requestTitle ? requestTitle : "";
          }
          if (i === 0) {
            path = path + "/" + route;
            path = path + "/" + routes[1];
          } else if (i !== 1) {
            path = path + "/" + route;
          }
          if (i == 1 && (route == "mentee" || route == "mentor")) {
            return;
          }
          if (route == "testScreen") {
            const learner = localStorage.getItem("learner");
            console.log("165 learner", learner);
            route = learner;
          }
          return (
            <Breadcrumb.Item>
              <Link
                className={i === routes.length - 1 ? "active-link" : ""}
                to={popupFlag ? "#" : `${path}`}
                onClick={() => {
                  if (popupFlag) {
                    setShowPopup2(true);
                    if (profile.type === ProfileType.mentee) {
                      setUrlFlag(AppUrlsEnum.menteeDashboard);
                    } else {
                      setUrlFlag(AppUrlsEnum.mentorDashboard);
                    }
                  } else {
                    handleClick();
                  }
                }}
              >
                {route}
              </Link>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
      <PopupUnsaved
        showPopup={showPopup2}
        type="popup"
        url={urlFlag}
        gotIt={handleLeave}
        gotIt2={handleStay}
      />
    </Container>
  );
};

export default Breadcrum;
