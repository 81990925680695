import { Card, Row, ProgressBar, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { _getImage } from "../../common/utils/commonFunctions";
import { AppUrlsEnum, ImgSrcEnums } from "../../enums";
import { CustomImage } from "../../components/Media";
import moment from "moment";

const CardAsses = (props) => {
  const navigate = useNavigate();
  const {
    data,
    itemsPerPage,
    totalRecords,
    currentPage,
    setCurrentPage,
    setItemsPerPage,
    showProgress,
    showStatus,
    navig,
    myCourses
  } = props;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const renderItemsPageSelect = () => (
    <div>
      <select
        value={itemsPerPage}
        onChange={handleItemsPageChange}
        className="custom-pageChange"
        disabled
      >
        {Array.from({ length: 20 }, (_, i) => i + 1).map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );

  const changePage = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const handleItemsPageChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  return (
    <>
      <Row className="mt-1">
        {data.map((details) => {
          console.log(details,details?.courseDetail,'course detail');
          let item =details;
          if(myCourses){
            item = details?.courseDetail;
          }
          // const currentTime = new Date().getTime();
          // let dueDateClassName = "validDate";
          // if (currentTime > item?.due_datetime) {
          //   dueDateClassName = "duedateExpired";
          // }
          return (
            <Col sm={12} md={4} lg={3} className="p-0"><Card
              className="m-0 p-2 mx-2 my-2 shadow border-0"
              // style={{ width: "15rem" }}
              role="button"
              onClick={() =>
                navigate(`${navig}?courses_id=${item?.course_id}&version=${item?.version}`
                ,{
                  state:{data: details}
                })
              }
            >
              <div className="img-container">
                <CustomImage
                  variant="top"
                  src={
                    item.banner_image
                      ? {
                          src: `${item?.banner_image}`,
                          type: ImgSrcEnums.props,
                        }
                      : {
                          src: `${_getImage("coursesBanner.jpg")}`,
                          type: ImgSrcEnums.normal,
                        }
                  }
                  height="150px"
                  width="100%"
                />
              </div>
              <div className="title d-flex justify-content-between my-1">
                <span>{item?.title}</span>
              </div>
              <div className="author mb-1">
                <span>{item?.course_description ? item?.course_description : ""}</span>
              </div>
              <Container>
                {/* {showStatus ?
                <Row>
                  {item?.status !== "COMPLETED" ? (
                    <Col xs="12" md="7" lg="7" xl="7" className="px-0 ">
                      <img
                        className="me-1"
                        width="22px"
                        height="22px"
                        src={_getImage("schedule.svg")}
                      /> */}
{/* 
                      {currentTime <= item?.due_datetime ? (
                        <span
                          className={`validtill-date ${dueDateClassName} px-1`}
                        >
                          {moment
                            .unix(item?.due_datetime / 1000)
                            .format(process.env.REACT_APP_DATE_FORMAT2)}
                        </span>
                      ) : (
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "13px",
                            backgroundColor: "#D73D3D",
                            border: `1px solid #D73D3D`,
                            borderRadius: "4px",
                            padding: "2px 6px",
                            color: "white",
                            height: "22px",
                            width: "90px",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                          className={`text-bg-#D73D3D mt-1`}
                        >
                          Expired
                        </span>
                      )}
                    </Col>
                  ) : (
                    <Col xs="12" md="7" lg="7" xl="7" className="px-0 ">
                      <span
                        style={{
                          fontWeight: "500",
                          fontSize: "13px",
                          backgroundColor: "#198754",
                          border: `1px solid #198754`,
                          borderRadius: "4px",
                          padding: "2px 6px",
                          color: "white",
                          height: "22px",
                          width: "90px",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                        className={`text-bg-#198754 mt-1`}
                      >
                        COMPLETED
                      </span>
                    </Col>
                  )}
                </Row>:<></>} */}
                {showProgress?        
                <Row>
                  <Col xs={9} className="px-0 mt-3">
                    <ProgressBar
                      now={item?.percentageCompleted || "0"}
                      className="assessProgress"
                    />
                  </Col>
                  <Col xs={3} className="px-2 mt-1">
                    <label>
                      {item?.percentageCompleted?.toFixed(2) || "0"}%
                    </label>
                  </Col>
                </Row>:<></>}
              </Container>
            </Card>
            </Col>
          );
        })}
      </Row>
      {data.length > 0 && (
        <Row>
          <Col>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(totalRecords / itemsPerPage)}
              forcePage={currentPage}
              onPageChange={changePage}
              containerClassName={"assessmentBtn"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"assessmentActive"}
            />
          </Col>
          <Col className="d-flex justify-content-end mt-2">
            <div className="card-count mb-2 mr-2">
              Results: {indexOfFirstItem + 1}-
              {Math.min(indexOfLastItem, totalRecords)} of {totalRecords}
            </div>
            <div>{renderItemsPageSelect()}</div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CardAsses;
