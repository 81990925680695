//**Iguroo Admin Mentor and Mentee AgGrid UI code**//
//** Commented code will be used in future Enhancement**//

import React, {
  useCallback,
  useRef,
  useMemo,
  useState,
  useContext,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Approve from "./Approve";
import Block from "./Block";
import StatusTag from "./StatusTag";
import ApprovePic from "./ApprovePic";
import ApproveVideo from "./ApproveVideo";
import ProfileLink from "./ProfileLink";
import { useEffect } from "react";
import { getApproveMedia, getApproveUsers } from "../../services/axios/session";
import { AuthContext } from "../../hooks/auth.context.provider";
import "./admin.scss";
import { getAllStats } from "../../services/axios/Admin/admin";

const AdminTables = () => {
  const gridRef = useRef();
  const authContext = useContext(AuthContext);
  const userInfo = useMemo(() => {
    return authContext?.sessionInfo;
  }, [authContext.authStatus]);

  useEffect(() => {}, []);

  const [rowData, setRowData] = useState([]);
  const [rowData2, setRowData2] = useState([]);

  const [columnDefs] = useState([
    {
      field: "User",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      cellRenderer: ProfileLink,
      resizable: true,
    },
    { field: "Email", resizable: true },
    { field: "Phone", resizable: true },
    { field: "User type" },
    { field: "Status", cellRenderer: StatusTag },
    // { field: "Profile_action" },
    // { field: "Block_or_Unblock", cellRenderer: Block },
    {
      field: "Approve User",
      cellRenderer: Approve,
      cellRendererParams: {
        getUsers: handleGetUsers,
      },
    },
    // { field: "Approve_Pic", cellRenderer: ApprovePic },
    // { field: "Approve_Video", cellRenderer: ApproveVideo },
  ]);

  const [columnDefs2] = useState([
    {
      field: "User",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      cellRenderer: ProfileLink,
      resizable: true,
    },
    { field: "Email", resizable: true },
    { field: "Phone", resizable: true },
    { field: "User type" },
    { field: "Status", cellRenderer: StatusTag },
    // { field: "Profile_action" },
    // { field: "Block_or_Unblock", cellRenderer: Block },
    // { field: "Approve_User", cellRenderer: Approve },
    {
      field: "Pic Approval",
      cellRenderer: ApprovePic,
      cellRendererParams: {
        getMedia: handleMedia,
      },
    },
    {
      field: "Video Approval",
      cellRenderer: ApproveVideo,
      cellRendererParams: {
        getMedia: handleMedia,
      },
    },
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
  }));

  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event);
  }, []);

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    document.querySelector("#selectedRows").innerHTML =
      selectedRows.length === 1 ? selectedRows[0].athlete : "";
  }, []);

  const isRowSelectable = useMemo(() => {
    return (params) => {
      return !!params.data;
    };
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.forEachNode((node) => node.setSelected(false));
  }, []);

  const components = useMemo(() => {
    return {
      Approve: Approve,
      Block: Block,
      StatusTag: StatusTag,
      ProfileLink: ProfileLink,
    };
  }, []);

  // const isFullWidthRow = useCallback((params) => {
  //   // in this example, we check the fullWidth attribute that we set
  //   // while creating the data. what check you do to decide if you
  //   // want a row full width is up to you, as long as you return a boolean
  //   // for this method.
  //   return !!params;
  // }, []);

  function handleMedia() {
    getApproveMedia()
      .then((res) => {
        if (res?.data?.data) {
          const users = res?.data?.data?.map((user) => {
            return {
              userId: user.userId,
              User: `${user?.firstName} ${user.lastName}`,
              Email: user?.email ? user?.email : "----",
              Phone: user?.mobile ? user?.mobile : "----",
              "User type": user?.type,
              Status: user?.isApproved,
              "Profile action": user?.isApproved,
              approverId: userInfo?.sub,
              isProfilePicApproved: user?.isProfilePicApproved,
              isProfileVidApproved: user?.isProfileVidApproved,
              isNewProfilePicRejected: user?.isNewProfilePicRejected,
              isNewProfileVidRejected: user?.isNewProfileVidRejected,
              profilePic: user?.profilePic,
              newProfilePic: user?.newProfilePic,
              newProfileVideoLink: user?.newProfileVideoLink,
              profileVideoLink: user?.profileVideoLink,
            };
          });
          setRowData2(users);
        }
      })
      .catch((err) => console.log(err));
  }

  function handleGetUsers() {
    handleMedia();
    getApproveUsers()
      .then((res) => {
        if (res?.data?.data) {
          const users = res?.data?.data?.map((user) => {
            return {
              userId: user.userId,
              User: `${user?.firstName} ${user.lastName}`,
              Email: user?.email ? user?.email : "----",
              Phone: user?.mobile ? user?.mobile : "----",
              "User type": user?.type,
              Status: user?.isApproved,
              "Profile action": user?.isApproved,
              approverId: userInfo?.sub,
              isProfilePicApproved: user?.isProfilePicApproved,
              isProfileVidApproved: user?.isProfileVidApproved,
            };
          });
          setRowData(users);
        }
      })
      .catch((err) => console.log(err));
  }

  function handleAllStats() {
    console.log("called--2");
    getAllStats()
      .then((res) => {
        console.log("console 188 getAllStats", res);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    handleGetUsers();
    handleMedia();
    // handleAllStats();
  }, []);

  return (
    <div className="d-flex flex-column" style={{ fontFamily: "manrope" }}>
      <h5 className="ms-4 mt-2">Approve Mentors</h5>
      <div className="ag-theme-alpine px-4 py-3" style={{ height: "400px" }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
          onCellClicked={cellClickedListener}
          onSelectionChanged={onSelectionChanged}
          suppressRowClickSelection={true}
          isRowSelectable={isRowSelectable}
          onFirstDataRendered={onFirstDataRendered}
          components={components}
          suppressDragLeaveHidesColumns={true}
        />
      </div>
      <h5 className="ms-4 mt-3">Approve Media</h5>
      <div className="ag-theme-alpine px-4 py-3" style={{ height: "400px" }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData2}
          columnDefs={columnDefs2}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
          onCellClicked={cellClickedListener}
          onSelectionChanged={onSelectionChanged}
          suppressRowClickSelection={true}
          isRowSelectable={isRowSelectable}
          onFirstDataRendered={onFirstDataRendered}
          components={components}
          suppressDragLeaveHidesColumns={true}
        />
      </div>
    </div>
  );
};

export default AdminTables;
