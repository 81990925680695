//** SelfAssessment,all gathered in the Index file for Iguroo **//

import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { _getImage } from "../../common/utils/commonFunctions";
import { CardSecondaryComponent } from "../../components/cardComponent/card.secondary.text";
import { IconLabelComponent } from "../../components/cardComponent/icon.label";
import { AppUrlsEnum, Languages, ProfileType } from "../../enums";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { loader } from "../../redux-tk/slices/loader.slice";
import {
  GetExam,
  GetExamAttemptCompleted,
  GetExamDetails,
} from "../../services/axios/Exam";
import { setAssessmentLanguage } from "../../services/axios/Assessment/assessment";
import { AuthContext } from "../../hooks/auth.context.provider";
import moment from "moment";
import { SwotPopup } from "../../components/SwotComponents/SwotPopup";
import { SwotPopupLanguage } from "../../components/SwotComponents/SwotPopupLanguage";
import { getSwotAsyncThunk, swotValue } from "../../redux-tk/slices/swot.slice";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Progress from "./Progress";
import "./styles.scss";
import { GetExamAttempt } from "../../services/axios/Exam";
import QuickDetailedPopup from "./quickDeatailedPopup";
import { Popup } from "../../components/CustomInput/popup";

const SelfAssessment = (props) => {
  const location = useLocation();
  const { formElementLabels, headingTranslations, menteeOnboardingLabels } =
    useIgurooTranslations();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [examDetails, setExamDetails] = useState([]);
  const [viewReport, setViewReport] = useState(false);
  const authContext = useContext(AuthContext);
  const [attemptId, setAttemptId] = useState(null);
  const [examID, setExamId] = useState(null);
  const [showSwotPopup, setShowSwotPopup] = useState(false);
  const [showLangPopup, setShowLangPopup] = useState(false);
  const swotDataValue = useSelector((state) => swotValue(state));
  const [selectedLang, setSelectedLang] = useState("english");
  const dispatch = useDispatch();
  const closeClicked = localStorage.getItem("closeClicked");
  const onEndOnHandleClose = () => {
    setShowSwotPopup(false);
  };
  const [questionsCompleted, setQuestionsCompleted] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [examCompleted, setExamCompleted] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [examType, setExamType] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    console.log("modal", showModal);
  };

  const handleOnSubmit = async (examId) => {
    console.log(examId, "examid");
    setShowModal(false);
    if (attemptId != null) {
      setExamId(examId);
      setShowPopup(true);
    } else {
      setExamId(examId);
      try {
        if (examId === 1) {
          setExamType("IGUROO_ASSESSMENT");
          navigate(AppUrlsEnum.testAssesment, {
            state: {
              examType: "IGUROO_ASSESSMENT",
              examId: 1,
            },
          });
        } else if (examId === 2) {
          setExamType("IGUROO_ASSESSMENT_QUICK");
          navigate(AppUrlsEnum.testAssesment, {
            state: {
              examType: "IGUROO_ASSESSMENT_QUICK",
              examId: 2,
            },
          });
        } else if (examId !== null) {
          setExamType("Not Started");
          console.log("Invalid examId");
        }
      } catch (error) {
        console.error("Error API call", error);
      }
    }
  };

  const handleGotIt = () => {
    navigate(AppUrlsEnum.testAssesment, {
      state: {
        examType: examType,
        examId: examID,
      },
    });
  };

  const onLangHandleClose = () => {
    localStorage.setItem("closeClicked", true);
    setShowLangPopup(false);
    if (localStorage.getItem("assessmentlanguageFlag") === "false") {
      console.log("LOG =>> setLang CLOSE === false");
      localStorage.setItem("assessmentlanguageFlag", true);
    } else {
      console.log("LOG =>> setLang CLOSE === true");
    }
  };
  const user = useSelector((state) => state.user.profile);

  const onEndOnButtonTwoClick = () => {
    setShowSwotPopup(false);
    if (user.type === ProfileType.mentee) {
      navigate(AppUrlsEnum.swotAnalysis, {
        state: {
          examType: "swot",
          examId: "swot",
        },
      });
    }
    if (user.type === ProfileType.mentor) {
      navigate(AppUrlsEnum.swotAnalysisMentor, {
        state: {
          examType: "swot",
          examId: "swot",
        },
      });
    }
  };

  const onValueChange = (event) => {
    setSelectedLang(event.target.value);
  };

  const onLangSaveClick = () => {
    (async function () {
      // dispatch(loader(true));
      setAssessmentLanguage(authContext?.sessionInfo?.sub, selectedLang)
        .then((res) => {
          const response = res?.response;

          localStorage.setItem("assessmentlanguage", selectedLang);

          setShowLangPopup(false);
        })
        .catch((err) => console.log("LOG  ERROR", err));
    })();
  };

  const languageList = [
    {
      value: Languages.english,
      label: menteeOnboardingLabels?.english,
      checked: true,
    },
    {
      value: Languages.hindi,
      label: menteeOnboardingLabels?.hindi,
      checked: false,
    },
    {
      value: Languages.telugu,
      label: menteeOnboardingLabels?.telugu,
      checked: false,
    },
    {
      value: Languages.tamil,
      label: menteeOnboardingLabels?.tamil,
      checked: false,
    },
    {
      value: Languages.kannada,
      label: menteeOnboardingLabels?.kannada,
      checked: false,
    },
    {
      value: Languages.odiya,
      label: menteeOnboardingLabels?.odia,
      checked: false,
    },
  ];

  useEffect(() => {
    (async function () {
      try {
        const examAttempt = await GetExamAttempt({
          userId: authContext?.sessionInfo?.sub,
        });
        console.log(
          "LOG console answers = examAttempt",
          examAttempt.data.response.Items[0].answers?.length
        );
        setQuestionsCompleted(
          examAttempt.data.response.Items[0].answers?.length
        );
        if (examAttempt.data.response.Items[0].examId)
          setExamId(examAttempt.data.response.Items[0].examId);
        else setExamId(0);

        return examAttempt;
      } catch (err) {
        console.log("LOG console answers = LOG console user err", err);
        setExamId(0);
      }
    })();
  }, []);
  useEffect(() => {
    (async function () {
      console.log("LOG console answers = ", examID);
      if (examID == 1) {
        console.log("LOG console answers = examID if", examID);
        setExamType("IGUROO_ASSESSMENT");
      } else if (examID == 2) {
        console.log("LOG console answers = examID if else", examID);
        console.log("LOG console answers = ", examID);
        setExamType("IGUROO_ASSESSMENT_QUICK");
      } else if (examID != null) {
        setExamType("Not Started");
        console.log("LOG console answers = examID else", examID);
        console.log("Invalid examId");
      }
    })();
  }, [examID]);

  useEffect(() => {
    const activeLang = localStorage.getItem("assessmentlanguage");
    if (activeLang === null) {
      setShowLangPopup(true);
    }
  }, [location]);

  useEffect(() => {
    const setLang = localStorage.getItem("assessmentlanguageFlag");
    if (setLang === "true") {
      setShowLangPopup(true);
      localStorage.setItem("assessmentlanguageFlag", false);
    }

    (async function () {
      dispatch(loader(true));
      console.log("LOG console answers = ", examID, examType);
      if (examID != null && examType != "" && examType != "Not Started") {
        GetExamDetails(examType)
          .then((res) => {
            const examDet = res?.response.Items;
            console.log(res, examDet, "console");
            setExamDetails(examDet);
          })
          .catch((err) => console.log(err));
        GetExam(examID) //Exam Id
          .then((res) => {
            const examDet = res?.response.length;
            setTotalQuestions(examDet);
            console.log(res, examDet, "console QUE");
          })
          .catch((err) => console.log(err));
      } else if (examID != null && examType == "Not Started") {
        console.log("LOG console answers = ", examType);
        GetExamDetails("IGUROO_ASSESSMENT")
          .then((res) => {
            const examDet = res?.response.Items;
            console.log(res, examDet, "console");
            setExamDetails(examDet);
          })
          .catch((err) => console.log(err));
      }
      if (examID == null && attemptId == null) {
        console.log("LOG console answers = ", examID, attemptId);
        GetExamAttemptCompleted(authContext.sessionInfo.sub)
          .then((res) => {
            const examDet = res?.data?.response?.Items;
            const result = examDet?.sort(
              (date1, date2) =>
                moment(date2.updatedAt) - moment(date1.updatedAt)
            );
            setAttemptId(result[0]?.attemptId);
            setExamCompleted(examDet.length);
            console.log(res, examDet, result, "console 54");
            if (examDet?.length > 0) {
              setViewReport(true);
            }
          })
          .catch((err) => console.log(err));
        await dispatch(
          getSwotAsyncThunk({ userId: authContext?.sessionInfo?.sub })
        );
      }
      dispatch(loader(false));
    })();
  }, [examID, examType]);
  //console.log("formswot","HI")

  const handleShowModal = (details) => {
    if (examID == 0) setShowModal(true);
    else {
      navigate(AppUrlsEnum.testAssesment, {
        state: {
          examType: details?.examType,
          examId: details?.examId,
        },
      });
    }
  };

  useEffect(() => {
    const lang = localStorage.getItem("assessmentlanguage");
    if (lang?.length > 1) {
      i18n.changeLanguage(lang?.toLocaleLowerCase());
    } else {
      i18n.changeLanguage(Languages.english);
    }
  }, []);

  return (
    <Container>
      <SwotPopup
        closeOnBackClick={true}
        showPopup={showSwotPopup}
        type="popup"
        submitText={
          formElementLabels?.placeHolders?.swotTypes?.swotStartPopupButtonOne
        }
        modalBody={
          formElementLabels?.placeHolders?.swotTypes?.swotStartPopupBody
        }
        title={formElementLabels?.placeHolders?.swotTypes?.swotStartPopupHeader}
        hideSVG={true}
        className={"swot-popup"}
        infoText={true}
        onHandleClose={onEndOnHandleClose}
        buttonTwoOnClick={onEndOnButtonTwoClick}
      />

      <SwotPopupLanguage
        closeOnBackClick={true}
        showPopup={showLangPopup}
        type="popup"
        submitText={formElementLabels?.save}
        modalBody={menteeOnboardingLabels?.selectLanguageForSwot}
        title={menteeOnboardingLabels?.selectLanguage}
        languageList={languageList}
        hideSVG={true}
        //className={"swot-popup"}
        infoText={true}
        onHandleClose={onLangHandleClose}
        buttonTwoOnClick={onLangSaveClick}
        onValueChange={onValueChange}
      />

      <Row>
        {examDetails?.map((details, index) => (
          <Col md={4} className="mt-4">
            <Card className="border-0">
              <div className="ig-mentor-img">
                <img src={_getImage("self-discovery.png")} width={"100%"} />
                <IconLabelComponent
                  className="ig-self-discovery"
                  svgName="three-dot.svg"
                  onClick={() => {}}
                ></IconLabelComponent>
                <div class="overlay"></div>
                <Row className="d-flex justify-content-center">
                  <Col
                    className="button ig-selfdiscovery-howerbtn p-2"
                    sm={8}
                    role={"button"}
                    onClick={() => handleShowModal(details)}
                  >
                    {" "}
                    {questionsCompleted > 0
                      ? headingTranslations?.selfDiscoveryTestLinkContinue
                      : headingTranslations?.selfDiscoveryTestLinkStart}
                  </Col>
                </Row>
              </div>
              <Card.Body>
                <CardSecondaryComponent>
                  {headingTranslations?.testHeading}
                </CardSecondaryComponent>
                <Row className="mt-3 d-flex justify-content-center mx-10">
                  <Progress
                    className="blue_progress"
                    text={"Progress"}
                    value={
                      questionsCompleted > 0
                        ? (
                            (questionsCompleted / totalQuestions) *
                            100
                          ).toPrecision(3)
                        : examCompleted > 0
                        ? 100
                        : 0
                    }
                  />
                </Row>
                <Row className="d-flex justify-content-center my-3 mx-auto">
                  <Col md={6} className="d-flex  justify-content-center">
                    {viewReport ? (
                      <Button
                        className="btn btn-outline-dark ig-contine-btn"
                        onClick={() =>
                          navigate(AppUrlsEnum.assessmentReports, {
                            state: {
                              attemptId: attemptId,
                            },
                          })
                        }
                      >
                        {formElementLabels?.reportBtn}
                      </Button>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
        <Col md={4} className="mt-4">
          {/* Swot Assessment,Before have used & commented now,will be used in future*/}
          {/* <Card className="border-0">
            <div
              className="ig-mentor-img"
              // role={"button"}
            >
              <img src={_getImage("SWOT.svg")} width={"100%"} />
              <IconLabelComponent
                className="ig-self-discovery"
                svgName="three-dot.svg"
                onClick={() => {}}
              ></IconLabelComponent>
              <div class="overlay"></div>
              <Row className="d-flex justify-content-center">
                <Col
                  className="button ig-selfdiscovery-howerbtn p-2"
                  sm={8}
                  role={"button"}
                  onClick={() => setShowSwotPopup(true)}
                >
                  {" "}
                  {headingTranslations?.selfDiscoveryTestLink}
                </Col>
              </Row>
            </div>
            <Card.Body>
              <CardSecondaryComponent>
                {headingTranslations?.swotAssessmentHeading}
              </CardSecondaryComponent>
              <Row className="d-flex justify-content-center my-3 mx-auto">
                <Col md={6} className="d-flex  justify-content-center">
                  {swotDataValue.swotId ? (
                    <Button
                      className="btn btn-outline-dark ig-contine-btn"
                      onClick={onEndOnButtonTwoClick}
                    >
                      {formElementLabels?.swotReportBtn}
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card> */}
        </Col>
      </Row>
      <QuickDetailedPopup
        showModal={showModal}
        closeOnBackClick={handleCloseModal}
        onSubmit={handleOnSubmit}
      />
      {attemptId != null ? (
        <Popup
          closeOnBackClick={() => {
            setShowPopup(false);
            setExamId(0);
            return true;
          }}
          showPopup={showPopup}
          type="popup"
          title="Warning"
          submitText={"Confirm"}
          modalBody={
            "Your previous report will be overwritten. Click 'Confirm' to proceed."
          }
          onHide={handleGotIt}
          hideSVG={true}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default SelfAssessment;
