//** Iguroo Assessment FetchDetails Hooks **//

import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  getMyAssessmentByIdThunk,
  tempCreateAssessmentSelector,
} from "../redux-tk/slices/assessment.slice";
import { useEffect } from "react";

export const useAssessmentDetailFetch = ({ assessmentId, versionId }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const tempCreateDataValue = useSelector((state) =>
    tempCreateAssessmentSelector(state)
  );
  console.log("searchParams", assessmentId, versionId);

  useEffect(() => {
    // const assessmentId = searchParams?.get("assessmentId");
    // const versionId = searchParams?.get("versionId");
    if (assessmentId && versionId) {
      dispatch(
        getMyAssessmentByIdThunk({
          ...(versionId && { version: versionId }),
          assessment_id: assessmentId,
        })
      );
    }
  }, [assessmentId, versionId]);

  return searchParams;
};
