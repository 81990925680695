//** Iguroo & GinieHealth Common Dashboard of Sidebar,Header & Footer**//

import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header";
import FooterComponent from "../../components/footer";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import { useLocation, Outlet } from "react-router-dom";
import { AppUrlsEnum } from "../../enums";

const Dashboard = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const [breadcrumbView, setBreadcrumbView] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      pathname === AppUrlsEnum.mentorSettings ||
      pathname === AppUrlsEnum.menteeSettings
    ) {
      setBreadcrumbView(false);
    } else {
      setBreadcrumbView(true);
    }
  }, [pathname]);
  console.log("### dashboard ####");

  return (
    <Row className="m-0 p-0">
      <Col sm={12} md={2} className="border-end m-0 p-0">
        <Sidebar userType={props.userType} />
      </Col>
      <Col sm={12} md={10}>
        <Row>
          <Col className="border-bottom">
            <Header />
          </Col>
        </Row>
        <Row>
          <Col className="g-0">
            {breadcrumbView && (
              <Container className="mt-3">
                <Breadcrumb />
              </Container>
            )}
            <Container className="p-0">
              <Outlet />
            </Container>
          </Col>
        </Row>
      </Col>
      <Row className="p-0 m-0">
        <Col className="p-0">
          <FooterComponent />
        </Col>
      </Row>
    </Row>
  );
};

export default Dashboard;