//** Iguroo CollegesList Summary Loactaion & Filtering,Currently not using this ,Infurture we would implementing this code **//

import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import classNames from "classnames";
import "./styles.scss";
import Header from "../../../components/header";
import FooterComponent from "../../../components/footer";

const CollegesSummary = () => {
  const streamJson =
    [
      {
        "Engneering": {
          "title": "Engineering",
          "subCategory": {
            "collegeByDegree": {
              "title": "College By Degree",
              "list": [
                "BTech", "B.SC", "Diploma", "M.Tech", "B.Arch"
              ]

            },
            "collegeByLocation": {
              "title": "College By Location",
              "list": [
                "Maharashtra", "Tamilnadu", "Andhra", "Karnataka", "kerala", "Goa", "Madhaya Pradesh", "Hyderabad"
              ]

            },
            "collegeByTop": {
              "title": "Popular Colleges",
              "list": [
                "Amity University", "S V University", "Parul University", "K.R Mangalam University", "Andhra University"
              ]

            }
          }
        }
      },

      {
        "Management": {
          "title": "Management",
          "subCategory": {
            "collegeByDegree": {
              "title": "College By Degree",
              "list": [
                "MBA", "BBA", "Diploma", "PGDM", "BBM"
              ]

            },
            "collegeByLocation": {
              "title": "College By Location",
              "list": [
                "Maharashtra", "Tamilnadu", "Delhi", "Andhra", "Karnataka", "kerala", "Goa", "Madhaya Pradesh", "Hyderabad"
              ]

            },
            "collegeByTop": {
              "title": "Popular Colleges",
              "list": [
                "GNA University", "Parul University", "Amit University", "S V University", "Andhra University"
              ]

            }
          }
        }

      },

      {
        "Commerce": {
          "title": "Commerce",
          "subCategory": {
            "collegeByDegree": {
              "title": "College By Degree",
              "list": [
                "B.Com", "M.Com", "Diploma", "BBM", "B.A"
              ]

            },
            "collegeByLocation": {
              "title": "College By Location",
              "list": [
                "Maharashtra", "Tamilnadu", "Delhi", "Andhra", "Karnataka", "kerala", "Goa", "Madhaya Pradesh", "Hyderabad"
              ]

            },
            "collegeByTop": {
              "title": "Popular Colleges",
              "list": [
                "S V University", "Amity University", "Parul University", "Andhra University", "Kakatiya University"
              ]

            }
          }
        }
      },

      {
        "Medical": {
          "title": "Medical",
          "subCategory": {
            "collegeByDegree": {
              "title": "College By Degree",
              "list": [
                "MBBS", "MD", "MS", "BAMS", "BHMS"
              ]

            },
            "collegeByLocation": {
              "title": "College By Location",
              "list": [
                "Maharashtra", "Tamilnadu", "Delhi", "Andhra", "Karnataka", "kerala", "Goa", "Madhaya Pradesh", "Hyderabad"
              ]

            },
            "collegeByTop": {
              "title": "Popular Colleges",
              "list": [
                "AIIMS Delhi", "CMC Vellore", "KAsturba Medical College", "Madras Medical College", "B.J Medical College"
              ]

            }
          }
        }
      }

    ]

  const [isHovering, setIsHovering] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedList, setSelectedList] = React.useState([]);
  const [selectedTopCollegesList, setSelectedTopCollegesList] = React.useState([]);
  const [selectedCollegesDegreeList, setSelectedCollegesByDegreeList] = React.useState([]);
  const [selectedCollegeLocationList, setSelectedCollegeLocationList] = React.useState([]);

  React.useEffect(() => {
    setSelectedList(streamJson[0]);
    console.log(selectedList);
    setSelectedTopCollegesList(streamJson[0]['Engneering']['subCategory']['collegeByTop']['list']);
    setSelectedCollegesByDegreeList(streamJson[0]['Engneering']['subCategory']['collegeByDegree']['list']);
    setSelectedCollegeLocationList(streamJson[0]['Engneering']['subCategory']['collegeByLocation']['list']);

  }, []);

  const handleMouseOver = (event, key) => {
    console.log(event);
    console.log(key);
    setSelectedIndex(key);
    setIsHovering(true);
    setSelectedList(event);
    setSelectedTopCollegesList(event[Object.keys(event)[0]]['subCategory']['collegeByTop']['list']);
    setSelectedCollegesByDegreeList(event[Object.keys(event)[0]]['subCategory']['collegeByDegree']['list']);
    setSelectedCollegeLocationList(event[Object.keys(event)[0]]['subCategory']['collegeByLocation']['list']);

  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <>
      <Header />
      <div className='menu-block sidebar-menu'>
        <div className="sidebar-block">
          <div className="left-sidebar">
            <ul className="list-block">
              {/* <li className="list-item"
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}> <a>IBPS Clerk</a> </li> */}
              {
                streamJson.map((prop, key) => {
                  return (
                    <li key={key}
                      onMouseOver={() => handleMouseOver(prop, key)}
                      className={classNames("list-item", {
                        highlight: selectedIndex === key
                      })}
                    > <a >{Object.keys(prop)[0]}</a> </li>
                  )
                })

              }
            </ul>
          </div>

          <div className="right-sidebar-content">
            <div className="left-sidebar-list-item">
              <ul className="left-sidebar-list-item-block">
                <li className="left-sidebar-list--item-header"> Colleges By Degree </li>

                {
                  selectedCollegesDegreeList.length > 0 && selectedCollegesDegreeList.map((prop, key) => {
                    return (
                      <li className="list-item"

                      > <a >{prop}</a> </li>
                    )
                  })

                }
                <div className="viewAllLbl">View All</div>

              </ul>
            </div>
            <div className="left-sidebar-item">
              <ul className="left-sidebar-list-item-block">
                <li className="left-sidebar-list--item-header"> Top Colleges </li>
                {
                  selectedTopCollegesList.length > 0 && selectedTopCollegesList.map((prop, key) => {
                    return (
                      <li className="list-item"

                      > <a >{prop}</a> </li>
                    )
                  })

                }
                <div className="viewAllLbl">View All</div>
              </ul>
            </div>
            <div className="left-sidebar-item">
              <ul className="left-sidebar-list-item-block">
                <li className="left-sidebar-list--item-header"> Colleges By Location </li>
                {
                  selectedCollegeLocationList.length > 0 && selectedCollegeLocationList.map((prop, key) => {
                    return (
                      <li className="list-item"

                      > <a >{prop}</a> </li>
                    )
                  })

                }
                <div className="viewAllLbl">View All</div>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};
export default CollegesSummary;