import * as yup from "yup";
export function getAoeFormTranslated(formErrors) {
  return yup.object({
    areaOfExpertise: yup
      .array()
      .of(
        yup.object({
          value: yup.string(),
          dataId: yup.string(),
          dataType: yup.string(),
        })
      )
      .required(formErrors.areaOfEmphasisRequired)
      .typeError(formErrors?.areaOfEmphasisRequired)
      .min(1, formErrors?.areaOfEmphasisRequired)
      .max(5, formErrors?.areaOfEmphasisRequiredMax),
  });
}
