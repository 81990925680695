//** Iguroo Request Session Details of SessionSummaryNotes & RescheduleSession**//

import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  Card,
  ListGroup,
  Badge,
  Accordion,
  Row,
  Col,
  Popover,
  Button,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { _getImage } from "../../common/utils/commonFunctions";
import { RequestStatus } from "../../enums";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import {
  downloadSessionNotesFromApi,
  JoinRoom,
  getRating,
} from "../../services/axios/session";
import { TextLink } from "../text.link.component";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import {
  checkReScheduleSessionValidity,
  checkSessionValidity,
  isMentorOrPre,
} from "../../helpers";
import { Popup } from "../CustomInput/popup";
import RescheduleSession from "../Reschedule/rescheduleSession";
import { GetUserAvailability } from "../../services/axios/MentorOnbaording";
import "./styles.scss";
import SessionSummaryNotes from "../SessionSummaryNotes";
import { getSessionRating } from "../../services/axios/Rating";
import { GetTotalSessions } from "../../services/axios/Dasboard";

function SessionCardList({
  title,
  className,
  badge,
  data,
  footerProps,
  userDetails,
}) {
  const [sessionUpcomming, setupcomming] = useState([]);
  const [completed, setComplete] = useState([]);
  const { formElementLabels, dashboardTranslations } = useIgurooTranslations();
  const user = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [avail, setAvail] = useState([]);
  const [requestNum, setReqNum] = useState("");
  const [sessionNum, setSessionNum] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showPreJoinPopup, setShowPreJoinPopup] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const [dataSession, setDataSession] = useState("");
  const [prbStatement, setPrbStatement] = useState("");
  const [recommGuidance, setRecommGuidance] = useState("");
  const [menteeNote, setMenteeNote] = useState("");
  const [mentorNote, setMentorNote] = useState("");
  const [rating, setRating] = useState("0");
  const [sessionsHeld, setSessionsHeld] = useState("0");

  useEffect(() => {
    (async function () {
      const upcomming = data.filter(
        (value) => value.status.toUpperCase() !== RequestStatus.completed
      );
      const complt = data.filter(
        (value) => value.status.toUpperCase() === RequestStatus.completed
      );
      setupcomming(upcomming);
      setComplete(complt);
      if (userDetails.userId) {
        GetUserAvailability(userDetails.userId)
          .then((responce) => {
            if (responce.data) {
              console.log(responce, "responce");
              setAvail(responce.data);
            }
          })
          .catch((err) =>
            console.log(err, userDetails.userId, "err callstart")
          );
        getRating(userDetails.userId)
          .then((res) => {
            console.log("LOG Rating", res?.data?.response);
            setRating(res?.data?.response);
          })
          .catch((err) => console.log(err));
        GetTotalSessions(userDetails.userId).then((resp) => {
          console.log("LOG Sessions", resp);
          setSessionsHeld(resp);
        });
      }
    })();

    return () => {
      localStorage.removeItem("meetingLink");
    };
  }, [userDetails, data]);

  function handleRoomJoin(sessionNumber, requestNumber, userId, roomId, item) {
    localStorage.setItem("session_info", JSON.stringify(item));
    // console.log( checkSessionValidity(item?.startTime) <= 15 &&
    if (
      checkSessionValidity(item?.startTime) <= 15 &&
      checkSessionValidity(item?.endTime) >= 0
    ) {
      JoinRoom(sessionNumber, requestNumber, userId)
        .then((res) => {
          if (res) {
            localStorage.setItem("zegoToken", res.token);
            navigate(
              `/livesession/${requestNumber}/${sessionNumber}/${roomId}`
            );
          }
        })
        .catch((err) => console.log(err));
    } else {
      setShowPreJoinPopup(true);
    }
  }

  const downloadSessionNotes = (item) => {
    console.log(item, "session-item");
    downloadSessionNotesFromApi(item)
      .then((res) => {
        if (res) {
          console.log(res, "s-res");
          setDataSession(res.data.response.Items);
          res.data.response.Items?.map((item) => {
            item.fromMentor === true
              ? setMentorNote(item.note)
              : setMenteeNote(item.note);
          });
          setShowRating(true);
        }
      })
      .catch((err) => console.log("LOG err", err));

    getSessionRating(item.sessionNumber, item.mentorId)
      .then((res) => {
        if (res) {
          setPrbStatement(res.data.response.Items[0].questions.Q1);
          setRecommGuidance(res.data.response.Items[0].questions.Q2);
        }
      })
      .catch((err) => console.log("LOG  getSessionRating ERROR", err));
  };

  // Popover filed was not in use,will be future enhancement
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body className="p-0">
        <ListGroup>
          <ListGroup.Item>
            {" "}
            <img
              src={_getImage("hours.svg")}
              height="16"
              className="ms-1"
              alt="tick"
            />{" "}
            {formElementLabels?.reschedule}
          </ListGroup.Item>
          <ListGroup.Item>
            <img
              src={_getImage("x-red.svg")}
              height="16"
              className="ms-1"
              alt="tick"
            />{" "}
            {formElementLabels?.cancel}
          </ListGroup.Item>
        </ListGroup>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <Card className={className}>
        <Card.Header className=" d-flex aligns-items-center  justify-content-between request-card-request">
          <div className="ig-session-rqTitle align-self-center d-inline">
            {title}
          </div>
          <div className="float-end d-inline">
            {badge !== undefined && (
              <Badge pill className="ig-req-detail-session-badge" bg="success">
                {badge}
              </Badge>
            )}
          </div>
        </Card.Header>
        <ListGroup>
          {sessionUpcomming.length > 0 ? (
            <Accordion flush defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="ig-accordion-header">
                  {formElementLabels?.upcoming}
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  {sessionUpcomming?.map((item, i) =>
                    i <= 4 ? (
                      <ListGroup.Item>
                        {/* {console.log(item, "s-item")} */}
                        <Row>
                          <Col
                            className={` ig-session-detail ${item.status.toUpperCase()} ${
                              checkReScheduleSessionValidity(item?.endTime) < 0
                                ? "ig-resechedule"
                                : "ig-schedule"
                            }`}
                          >
                            {item.status.toUpperCase() ===
                            RequestStatus.cancelled ? (
                              <>
                                <img
                                  src={_getImage("x-red.svg")}
                                  height="16"
                                  className="ms-1"
                                  alt="tick"
                                />
                                {"  "}
                              </>
                            ) : (
                              <></>
                            )}
                            {moment
                              .unix(item.startTime)
                              .format("DD/MM/YYYY .  HH:mm A")}
                            {checkReScheduleSessionValidity(item?.endTime) >
                              0 &&
                            item.status.toUpperCase() !==
                              RequestStatus.cancelled ? (
                              <span className="ig-schedule"> (Scheduled)</span>
                            ) : (
                              <span className="ig-reschedule">
                                {" "}
                                (Session incomplete)
                              </span>
                            )}
                          </Col>
                          <Col xs="auto" className="text-end">
                            {" "}
                            {item.status.toUpperCase() !==
                            RequestStatus.cancelled ? (
                              <>
                                {/* {console.log(item, "item",(checkReScheduleSessionValidity(item?.endTime) < 0) , isMentor(user?.type),user?.type)} */}
                                {checkReScheduleSessionValidity(item?.endTime) <
                                  0 && !isMentorOrPre(user?.type) ? (
                                  <TextLink
                                    className="ig-session-join px-2 py-1"
                                    onClick={() => {
                                      setReqNum(item.requestNumber);
                                      setSessionNum(item.sessionNumber);
                                      setShow(true);
                                    }}
                                  >
                                    {formElementLabels?.reschedule}
                                  </TextLink>
                                ) : (
                                  <TextLink
                                    className="ig-session-join px-2 py-1"
                                    onClick={() =>
                                      handleRoomJoin(
                                        item.sessionNumber,
                                        item.requestNumber,
                                        item.mentorId || item.menteeId,
                                        item.roomId,
                                        item
                                      )
                                    }
                                  >
                                    {formElementLabels?.join}
                                  </TextLink>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ) : (
                      <></>
                    )
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ) : (
            <></>
          )}
          {completed.length > 0 ? (
            <Accordion>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="ig-accordion-header">
                  {formElementLabels?.completed}
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  {completed?.map((item, i) =>
                    i <= 4 ? (
                      <ListGroup.Item
                        className={` ig-session-detail ${item.status.toUpperCase()}`}
                      >
                        <div className="d-flex bd-highlight">
                          <div className="bd-highlight flex-grow-1">
                            <img
                              src={_getImage("check.svg")}
                              height="16"
                              className="ms-1"
                              alt="tick"
                            />
                            {"  "}
                            {moment
                              .unix(item.startTime)
                              .format("DD/MM/YYYY .  HH:mm A")}
                          </div>
                          <div className="bd-highlight">
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={() => downloadSessionNotes(item)}
                              className="session_notes"
                            >
                              Notes
                            </Button>
                          </div>
                        </div>
                      </ListGroup.Item>
                    ) : (
                      <></>
                    )
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ) : (
            <></>
          )}
        </ListGroup>

        {footerProps && (
          <Card.Footer>
            <Card.Link href="#">
              {footerProps.text}
              <img
                src={_getImage("arrow.svg")}
                height="16"
                className="ms-1"
                alt="arrow"
              />
            </Card.Link>
          </Card.Footer>
        )}
      </Card>
      <Popup
        closeOnBackClick={() => {
          showPopup(false);
          return true;
        }}
        showPopup={showPopup}
        type="popup"
        modalBody={dashboardTranslations?.joinDelay}
        svgImg={"alert-triangle.svg"}
        submitText={formElementLabels?.close}
        gotIt={() => {
          showPopup(false);
        }}
      />
      <Popup
        closeOnBackClick={() => {
          setShowPreJoinPopup(false);
        }}
        showPopup={showPreJoinPopup}
        type="popup"
        title={"INFORMATION"}
        hideSVG={true}
        infoText={true}
        modalBody={
          <>
            <p className="text-start fw-500">
              Oops! We are sorry to inform you that the meeting link you are
              trying to access, seems to be not active currently.
            </p>
            <p className="text-start fw-500">
              If you believe that the link should still be active, please try
              again later or contact our support team for assistance.
            </p>
          </>
        }
        gotIt={() => {
          setShowPreJoinPopup(false);
        }}
      />
      <RescheduleSession
        show={show}
        {...userDetails}
        onHide={(show) => setShow(show)}
        primaryBtn={formElementLabels?.reschedule}
        secondaryBtn={"Cancel"}
        avail={avail}
        requestNum={requestNum}
        sessionNum={sessionNum}
        rating={rating}
        sessionsHeld={sessionsHeld}
      />
      <SessionSummaryNotes
        show={showRating}
        onHide={(show) => setShowRating(show)}
        primaryBtn={"Rate"}
        secondaryBtn={"Skip"}
        dataSession={dataSession}
        menteeNote={menteeNote}
        mentorNote={mentorNote}
        prbStatement={prbStatement}
        recommGuidance={recommGuidance}
      />
    </>
  );
}

export default SessionCardList;
