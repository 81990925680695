//** Iguroo SWOT Assessment ->Swot ExamplePopupSingle Component,Can re-use anywhere in required Field**//
//** Currently not using this code,will be implemented in future **//

import React from "react";

export function SwotExamplePopupSingle({
  field,
}) {
  return (
    <>
      <div className="mt-3 p-3 swot-single-example">
        <div>{field.data}</div>
      </div>
    </>
  );
}
