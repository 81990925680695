//** Iguroo Admin SideAdministration will be Future Enhancement,Not using for now this UI **//

import React from "react";

const SiteAdministration = () => {
  return (
    <div className="text-center mt-5">
      Site Administration Under Construction
    </div>
  );
};

export default SiteAdministration;
