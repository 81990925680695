//** Iguroo SWOT Assessment,Currently not using this code,will be implemented in future **//

import React, { useEffect } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { _getImage } from "../../common/utils/commonFunctions";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export function SwotPopupLanguage({
  showPopup,
  modalBody,
  modalBodyTwo,
  modalBodyList,
  languageList,
  buttonTextOne,
  buttonOneOnClick,
  buttonTwoOnClick,
  onHandleClose,
  onValueChange,
  url,
  gotIt,
  title,
  submitText,
  className,
  svgImg,
  hideSVG,
  infoText,
  children,
}) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  useEffect(() => {
    console.log("LOG SwotPopupLanguage=>showPopup", showPopup);
    setShow(showPopup);
  }, [showPopup]);

  const handleSubmit = () => {
    setShow(false);
    if (gotIt) {
      gotIt();
    }
    if (url.length > 1) {
      navigate(url);
    }
  };
  const onValChange = (event) => {
    if (onValueChange) {
      onValueChange(event);
    }
  };

  return (
    <>
      <Modal
        className={`${className}`}
        show={show}
        onHide={onHandleClose}
        backdrop="static"
        keyboard={false}
      >
        {infoText ? (
          <Modal.Header className="text-center" closeButton>
            <Modal.Title className="w-100">{title}</Modal.Title>
          </Modal.Header>
        ) : (
          <Modal.Header className="text-center">
            <Modal.Title className="w-100">{title}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          {hideSVG ? (
            <></>
          ) : (
            <div className="text-center mt-4">
              <Image
                className="d-inline-block"
                src={_getImage(svgImg)}
                fluid={true}
              />
            </div>
          )}
          {modalBody && (
            <div className="popup-body mt-4 text-center mb-4">
              {modalBody}
              <p>
                <br />
                {modalBodyTwo}
              </p>
            </div>
          )}
          {modalBodyList && (
            <div className="popup-body mt-4 text-center mb-4">
              {modalBodyList}
            </div>
          )}
          {languageList.map((x, i) => (
            <label
              key={i}
              className="align-items-center justify-content-center mt-3 col-2 ml-5 lang-popup"
            >
              <input
                type="radio"
                className="radio-popup-lang"
                name="lang"
                value={x.value}
                defaultChecked={x.checked === true ? true : null}
                onChange={onValChange}
              />{" "}
              {x.label}
            </label>
          ))}
          {children ? children : <></>}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          {buttonTextOne && (
            <Button variant="primary" onClick={buttonOneOnClick}>
              {buttonTextOne}
            </Button>
          )}
          {submitText && (
            <Button variant="primary" onClick={buttonTwoOnClick}>
              {submitText}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
