//** Mentee Skill Proficiency Page **//

import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { GetAllData } from "../../../services/axios/staticData";
import { allDataConverter, getProfileObject } from "../../../helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomInputField } from "../../../components/CustomInput/customInputField";
import { _getImage } from "../../../common/utils/commonFunctions";
import { Form, Row, Col, Container } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useIgurooTranslations } from "../../../hooks/use.iguroo.translation";
import { getSkillFormTranslated } from "../../../FormSchemas";
import { getStepsObject } from "../../../helpers";
import Toaster from "../../../components/toaster";
import { toast } from "react-toastify";
import { CustomSelect } from "../../../components/CustomInput/custom.select";
import { SubmitButton } from "../../../components/submit.button";
import { FormErrorComponent } from "../../../components/form.error.component";
import { useDispatch, useSelector } from "react-redux";
import {
  profileDataValue,
  skillDataAction,
  workExpAndEduValue,
} from "../../../redux-tk/slices/mentee.onboarding.data";
import { loader } from "../../../redux-tk/slices/loader.slice";
import { Heading } from "../../../components/heading.component";
import { AppUrlsEnum, ProfileType, OnboardingSteps } from "../../../enums";
import { PostUserProfile } from "../../../services/axios/MentorOnbaording";
import { AuthContext } from "../../../hooks/auth.context.provider";
import {
  getUserAsyncThunk,
  postUserProfileAsyncThunk,
  userProfileValue,
} from "../../../redux-tk/slices/user.slice";
import { MentorSetupSidebar } from "../../../components/mentor.setup.sidebar";
import { Popup } from "../../../components/CustomInput/popup";
import { OnboardingHeading } from "../../../components/onboarding.heading.component";
import { FormLabelComponent } from "../../../components/form.label.component";
import { showPopupflag } from "../../../redux-tk/slices/showPopupflag.slice";

function MenteeSkillsAndProficiency({ settings }) {
  const {
    menteeOnboardingLabels,
    formElementLabels,
    formErrors,
    headingTranslations,
    headerFooterTranslations,
  } = useIgurooTranslations();
  const profile = useSelector((state) => profileDataValue(state));
  const workeduValue = useSelector((state) => workExpAndEduValue(state));
  const selectedValue = useSelector((state) => userProfileValue(state));
  console.log(selectedValue, "selectedValue");
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: { ...selectedValue },
    resolver: yupResolver(getSkillFormTranslated(formErrors)),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isDirty) {
      (async function () {
        dispatch(showPopupflag(true));
      })();
    }
  }, [isDirty]);

  useEffect(() => {
    console.log("ae", selectedValue);
    if (selectedValue?.hobbies) {
      reset({
        ...selectedValue,
      });
    }
  }, [selectedValue]);

  const authContext = useContext(AuthContext);

  const onSubmit = async (data) => {
    console.log("profile ###", profile, data);
    data.type = ProfileType.mentee;
    console.log("data", profile);

    console.log("re'", data);
    const finalData = getProfileObject(data, authContext.sessionInfo.sub);
    console.log("finalData object ", finalData);

    const result = await PostUserProfile(
      finalData,
      authContext.sessionInfo.sub
    );
    console.log("re'", result);
    dispatch(skillDataAction(data));
    dispatch(loader(false));
    await handleGotIt();
    Toaster(toast.success, headerFooterTranslations.toasterMsgOnboarding);
    if (!settings) {
      setShowPopup(true);
    }
  };
  const [showPopup, setShowPopup] = useState(false);
  const [skillDetail, setSkillDetail] = useState([]);
  const [hobbiesDetail, SethobbiesDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async function () {
      dispatch(loader(true));
      dispatch(getUserAsyncThunk({ userId: authContext.sessionInfo.sub }));

      const items = await GetAllData();
      allDataConverter.setAllApiData(items);
      const skill = allDataConverter.getDataType("skills.english");

      setSkillDetail(skill);
      const hobbies = allDataConverter.getDataType("hobbies.english");
      SethobbiesDetail(hobbies);
      dispatch(loader(false));
    })();
  }, []);

  async function handleGotIt() {
    if (!settings) {
      await dispatch(
        postUserProfileAsyncThunk(
          getStepsObject(
            OnboardingSteps[ProfileType.mentee][3].value,
            authContext.sessionInfo.sub
          )
        )
      );
    }
  }
  const [value, setInputValue] = useState("");
  const [error, setErrors] = useState(false);
  const handleChange = (event) => {
    if (event.target.value.length <= 999) {
      setInputValue(event.target.value);
      setErrors(false);
    } else {
      setErrors(true);
    }
  };
  return (
    <div>
      {!settings && <OnboardingHeading />}
      <Container className="px-3">
        <Row>
          <Col md="12" lg={settings ? 12 : 9}>
            {!settings && <Heading>{headingTranslations?.menteeSkill}</Heading>}
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col className="mt-3">
                  <label>
                    {" "}
                    <FormLabelComponent>
                      {menteeOnboardingLabels.skillPosses}
                    </FormLabelComponent>
                  </label>

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomSelect
                        opLabel="value"
                        opValue="dataId"
                        items={skillDetail}
                        onReactHookFormBlur={onBlur}
                        onReactHookFormChange={onChange}
                        value={value}
                        sendFullValueInHook={true}
                        normalSelect={true}
                        isLoading={isLoading}
                        isMulti={true}
                        placeholder={formElementLabels?.placeHolders?.skills}
                      />
                    )}
                    name="skills"
                  />
                  <FormErrorComponent>
                    {errors.skills && errors.skills?.message}
                  </FormErrorComponent>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3">
                  <label>
                    {" "}
                    <FormLabelComponent>
                      {menteeOnboardingLabels.hobbies}{" "}
                    </FormLabelComponent>
                  </label>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomSelect
                        opLabel="value"
                        opValue="dataId"
                        items={hobbiesDetail}
                        onReactHookFormBlur={onBlur}
                        onReactHookFormChange={onChange}
                        value={value}
                        sendFullValueInHook={true}
                        normalSelect={true}
                        isLoading={isLoading}
                        isMulti={true}
                        placeholder={formElementLabels?.placeHolders?.hobbies}
                      />
                    )}
                    name="hobbies"
                  />

                  <FormErrorComponent>
                    {errors.hobbies && errors.hobbies?.message}
                  </FormErrorComponent>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3">
                  <label>
                    {" "}
                    <FormLabelComponent>
                      {menteeOnboardingLabels.describachievements}
                    </FormLabelComponent>
                  </label>
                  <Controller
                    name={`workingExp`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInputField
                        placeholder={menteeOnboardingLabels.placeHoldersdesc}
                        hookOnBlur={onBlur}
                        hookValue={value}
                        formAs={"textarea"}
                        rows={6}
                        hookOnChange={(event) => {
                          handleChange(event);
                          onChange(event);
                        }}
                        maxLength={1000}
                      />
                    )}
                  />
                  {error && (
                    <FormErrorComponent>
                      {formErrors.describeTitle}
                    </FormErrorComponent>
                  )}
                  <FormErrorComponent className="errorMsg">
                    {errors.describachievements &&
                      errors.describachievements?.message}
                  </FormErrorComponent>
                </Col>
              </Row>

              <Row className="mt-1 mb-3">
                <FormErrorComponent>
                  {errors.apiError && errors.apiError?.message}
                </FormErrorComponent>
              </Row>

              <hr />
              <Row className="text-end mb-4 justify-content-end save-btn-end">
                <Col sm="12" md="2" className="d-grid ">
                  <SubmitButton type="submit" text={formElementLabels?.save} />
                  <Popup
                    closeOnBackClick={false}
                    showPopup={showPopup}
                    type="popup"
                    text={formElementLabels?.buttonSave}
                    modalBody={menteeOnboardingLabels?.onboardingSuccess}
                    url={AppUrlsEnum.selfDiscovery}
                    gotIt={handleGotIt}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
          {!settings && (
            <Col>
              {menteeOnboardingLabels?.rightSetupModule?.mentee && (
                <MentorSetupSidebar
                  typeToUse={menteeOnboardingLabels?.rightSetupModule?.mentee}
                  active={
                    menteeOnboardingLabels?.rightSetupModule?.mentee?.values
                      ?.skillsAndProf
                  }
                />
              )}
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default MenteeSkillsAndProficiency;
