//** GinieHealth Publisher,Create New Assessment of Assessment Settings UI code **//

import React, { useEffect, useState } from "react";
import "./MyCalender.scss";
import { Row, Col, Card, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import CustomAnswerOptions from "../../components/assessment/components/customradioOption";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { CustomSelect } from "../../components/CustomInput/custom.select";
import { FormLabelComponent } from "../../components/form.label.component";
import { FormErrorComponent } from "../../components/form.error.component";
import { SubmitButton } from "../../components/submit.button";
import { CustomCheckButton } from "../../components/CustomInput/custom.switch";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { getPublisherSettingsFormTranslated } from "../../FormSchemas/publisher.settings.form";
import { getPublisherTestFormTranslated } from "../../FormSchemas/publisher.test.form";
import { yupResolver } from "@hookform/resolvers/yup";
import AssessmentSideBar from "../../components/PublisherComponent/AssessmentSideBar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppUrlsEnum } from "../../enums";
import { getAssessmentObject } from "../../common/DTOS/assessment.dtos";
import { useDispatch, useSelector } from "react-redux";
import {
  AssessmentDetailsDataSelector,
  addDataToAssessmentDetails,
  createAssessmentAsyncThunk,
  tempCreateAssessmentSelector,
} from "../../redux-tk/slices/assessment.slice";
import { GetAssessmentsEmailTemplates } from "@iguroo/ig-react-assessment-package";
import { useAssessmentDetailFetch } from "../../hooks/useAssessmentDetailFetch";
import { DatePicker } from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import moment from "moment";

const AssessmenSettings = () => {
  const { formErrors } = useIgurooTranslations();
  const [searchParams, setSearchParams] = useSearchParams();
  const [emailTemplates, setEmailTemplates] = useState([]);
  const tempCreateDataValue = useSelector((state) =>
    tempCreateAssessmentSelector(state)
  );
  const params = useAssessmentDetailFetch({
    assessmentId: searchParams?.get("assessmentId"),
    versionId: searchParams?.get("versionId"),
  });

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    resetField,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      ...tempCreateDataValue.apiData,
      isTimeBoxed: tempCreateDataValue.apiData.isTimeBoxed ? "Yes" : "No",
      isRadomizeAnswers: tempCreateDataValue.apiData.isRadomizeAnswers
        ? "Yes"
        : "No",
      isRadomizeQuestions: tempCreateDataValue.apiData.isRadomizeQuestions
        ? "Yes"
        : "No",
      isResetAllowed: tempCreateDataValue.apiData.isResetAllowed ? "Yes" : "No",
    },
    mode: "onTouched",
    resolver: yupResolver(getPublisherSettingsFormTranslated(formErrors)),
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const assessmentDetailsData = useSelector((state) =>
    AssessmentDetailsDataSelector(state)
  );

  const options = [{ value: "Yes" }, { value: "No" }];

  const timeBoxed = watch("isTimeBoxed");

  const today = new Date().setHours(23, 59, 59);
  const tomorrow = new Date(today);
  console.log(today, "today ");
  const tomorrowMax = new Date(4102424999000);
  tomorrow.setDate(tomorrow.getDate() + 1);

  useEffect(() => {
    if (timeBoxed === "No") {
      resetField("isShowTotalTime");
      resetField("isShowTimeElaspsed");
      resetField("isShowTimeLeft");
      resetField("duration");
      resetField("min_duration_expected");
      resetField("max_duration_expected");
    }
  }, [timeBoxed]);

  useEffect(() => {
    setValue("duration", tempCreateDataValue.apiData.duration);
    setValue(
      "min_duration_expected",
      tempCreateDataValue.apiData.min_duration_expected
    );
    setValue(
      "max_duration_expected",
      tempCreateDataValue.apiData.max_duration_expected
    );
  }, [tempCreateDataValue.apiData.duration]);

  const randomizeQuestionsOptions = [{ value: "Yes" }, { value: "No" }];
  const randomizeAnswersOptions = [{ value: "Yes" }, { value: "No" }];
  const resetAllowedOptions = [{ value: "Yes" }, { value: "No" }];

  const onSubmit = async (data) => {
    let finalData = {
      ...assessmentDetailsData,
      isShowTotalTime: false,
      isShowTimeElaspsed: false,
      isShowTimeLeft: false,
      isResumeAllowed: false,
      isReverseAllowed: false,
      isSkippingAllowed: false,
      isMarketingAllowed: false,
      number_of_questions: 0,
      show_assessment_signature: true,
    };
    const ids = {
      versionId: searchParams.get("versionId"),
      assessmentId: searchParams.get("assessmentId"),
    };
    try {
      const assessmentObj = getAssessmentObject(data);
      finalData = { ...finalData, ...assessmentObj };
      const res = await dispatch(createAssessmentAsyncThunk(finalData));
      console.log("%%%%", res);
      dispatch(addDataToAssessmentDetails(finalData));
      if (ids.assessmentId && ids.versionId) {
        if (res?.payload) {
          navigate(
            `${AppUrlsEnum.pricing}?versionId=${ids.versionId}&assessmentId=${ids.assessmentId}`
          );
        }
      } else {
        if (res?.payload) {
          navigate(AppUrlsEnum.pricing);
        }
      }
      console.log(finalData, "f-data");
    } catch (err) {}
  };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  useEffect(() => {
    GetAssessmentsEmailTemplates()
      .then((res) => {
        if (res?.response?.Items) {
          const updatesTemplates = res?.response?.Items?.map((template, i) => {
            return {
              ...template,
              value: template?.template_name,
              dataId: template?.template_name,
            };
          });
          setEmailTemplates(updatesTemplates);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (tempCreateDataValue?.apiData?.assessment_id) {
      reset({ ...tempCreateDataValue.apiData });
      setValue(
        "isTimeBoxed",
        tempCreateDataValue.apiData.isTimeBoxed ? "Yes" : "No"
      );
      setValue(
        "isRadomizeAnswers",
        tempCreateDataValue.apiData.isRadomizeAnswers ? "Yes" : "No"
      );
      setValue(
        "isRadomizeQuestions",
        tempCreateDataValue.apiData.isRadomizeQuestions ? "Yes" : "No"
      );
      setValue(
        "isResetAllowed",
        tempCreateDataValue.apiData.isResetAllowed ? "Yes" : "No"
      );
    }
  }, [tempCreateDataValue?.apiData?.assessment_id]);

  useEffect(() => {
    if (!(tempCreateDataValue.apiData.valid_till === undefined)) {
      const d = new Date(moment.unix(tempCreateDataValue.apiData.valid_till));
      setValue(
        "valid_till",
        moment.unix(tempCreateDataValue.apiData.valid_till)
      );
    }
  }, [tempCreateDataValue?.apiData?.validtill]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <p className="fs-23 fw-700">Time</p>
        <div className="ps-5">
          <Row>
            <Col md={2}>
              <p className="fs-16 fw-700">Is Time Boxed</p>
              <Controller
                name={"isTimeBoxed"}
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <CustomAnswerOptions
                    type={"radio"}
                    options={options}
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    name={name}
                  />
                )}
              />
              <FormErrorComponent>
                {errors.isTimeBoxed && errors.isTimeBoxed?.message}
              </FormErrorComponent>
            </Col>
            <Col md={4}>
              <p className="fs-16 fw-700">Time Display</p>
              <Controller
                name={"isShowTotalTime"}
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <CustomCheckButton
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    name={name}
                    type="checkbox"
                    label="Show Total Time"
                    defaultChecked={false}
                    checked={value}
                    disabled={timeBoxed === "No"}
                  />
                )}
              />
              <Controller
                name={"isShowTimeElaspsed"}
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <CustomCheckButton
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    name={name}
                    type="checkbox"
                    label="Show Time Elapsed"
                    defaultChecked={false}
                    checked={value}
                    disabled={timeBoxed === "No"}
                  />
                )}
              />
              <Controller
                name={"isShowTimeLeft"}
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <CustomCheckButton
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    name={name}
                    type="checkbox"
                    label="Show Time Left"
                    defaultChecked={false}
                    checked={value}
                    disabled={timeBoxed === "No"}
                  />
                )}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>
              <Form.Label className="fs-16 fw-700 form-title p-0">
                Duration
              </Form.Label>
              <Controller
                name="duration"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomInputField
                    type="number"
                    disabled={getValues("isTimeBoxed") === "No"}
                    className="w-50"
                    min={0}
                    max={1440}
                    placeholder="Duration"
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    onChange={onChange}
                    defaultValue={0}
                  />
                )}
              />

              <FormErrorComponent>
                {getValues("isTimeBoxed") === "No"
                  ? null
                  : errors.duration && errors.duration?.message}
              </FormErrorComponent>
            </Col>
            <Col md={4}>
              <Form.Label className="fs-16 fw-700 form-title p-0">
                Min Duration Expected
              </Form.Label>
              <Controller
                name="min_duration_expected"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomInputField
                    type="number"
                    className="w-50"
                    placeholder="Min Duration Expected"
                    disabled={getValues("isTimeBoxed") === "No"}
                    min={0}
                    max={1440}
                    defaultValue={0}
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    onChange={onChange}
                  />
                )}
              />
              <FormErrorComponent>
                {getValues("isTimeBoxed") === "No"
                  ? null
                  : errors.duration && errors.duration?.message}
              </FormErrorComponent>
            </Col>
            <Col md={4}>
              <Form.Label className="fs-16 fw-700 form-title p-0">
                Max Duration Expected
              </Form.Label>
              <Controller
                name="max_duration_expected"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomInputField
                    type="number"
                    className="w-50"
                    placeholder="Max Duration Expected"
                    disabled={getValues("isTimeBoxed") === "No"}
                    min={0}
                    max={1440}
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    defaultValue={0}
                    onChange={onChange}
                  />
                )}
              />
              <FormErrorComponent>
                {getValues("isTimeBoxed") === "No"
                  ? null
                  : errors.duration && errors.duration?.message}
              </FormErrorComponent>
            </Col>
          </Row>
        </div>
        <p className="fs-23 fw-700 mt-3">Questions</p>
        <div className="ps-5">
          {/* Infuture Enhancement would be used this NumberofQuestions Inputfield */}
          {/* <Form.Label className="fs-16 fw-400 form-title p-0">
            Number of Questions
          </Form.Label>
          <Controller
            name="number_of_questions"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <CustomInputField
                type="number"
                className="w-25"
                placeholder="Number of Questions"
                hookOnChange={onChange}
                hookOnBlur={onBlur}
                hookValue={value}
                onChange={onChange}
                // disabled={isSwitchEnabled ? !enableInput : false}
              />
            )}
          /> */}
          {/* <FormErrorComponent>
            {errors.numberOfQuestions && errors.numberOfQuestions?.message}
          </FormErrorComponent> */}
          <Form.Label className="fs-16 fw-400 form-title p-0 mt-3">
            Cutoff Score
          </Form.Label>
          <Controller
            name="cutoff_score"
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <CustomInputField
                type="number"
                className="w-25"
                placeholder="Cutoff Score"
                hookOnChange={onChange}
                hookOnBlur={onBlur}
                hookValue={value}
                name={name}
              />
            )}
          />
          <FormErrorComponent>
            {errors.cutoff_score && errors.cutoff_score?.message}
          </FormErrorComponent>
        </div>
        <p className="fs-23 fw-700 mt-3">Navigation</p>
        <div className="ps-5">
          <Controller
            name={"isResumeAllowed"}
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <CustomCheckButton
                hookOnChange={onChange}
                hookOnBlur={onBlur}
                hookValue={value}
                name={name}
                type="checkbox"
                label="Resume Allowed"
                defaultChecked={false}
                checked={value}
              />
            )}
          />
          <Controller
            name={"isReverseAllowed"}
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <CustomCheckButton
                hookOnChange={onChange}
                hookOnBlur={onBlur}
                hookValue={value}
                name={name}
                type="checkbox"
                label="Reverse Allowed"
                defaultChecked={false}
                checked={value}
              />
            )}
          />
          <Controller
            name={"isSkippingAllowed"}
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <CustomCheckButton
                hookOnChange={onChange}
                hookOnBlur={onBlur}
                hookValue={value}
                name={name}
                type="checkbox"
                label="Skipping Allowed"
                defaultChecked={false}
                checked={value}
              />
            )}
          />
          <Controller
            name={"isMarketingAllowed"}
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <CustomCheckButton
                hookOnChange={onChange}
                hookOnBlur={onBlur}
                hookValue={value}
                name={name}
                type="checkbox"
                label="Mark Allowed"
                defaultChecked={false}
                checked={value}
              />
            )}
          />
          <p className="fs-16 fw-400 form-title p-0 m-0 mt-3">
            Randomize Questions
          </p>
          <Controller
            name={"isRadomizeQuestions"}
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <CustomAnswerOptions
                type={"radio"}
                options={randomizeQuestionsOptions}
                hookOnChange={onChange}
                hookOnBlur={onBlur}
                hookValue={value}
                name={name}
              />
            )}
          />
          <FormErrorComponent>
            {errors.isRadomizeQuestions && errors.isRadomizeQuestions?.message}
          </FormErrorComponent>
          <p className="fs-16 fw-400 form-title p-0 m-0 mt-3">
            Randomize Answers
          </p>
          <Controller
            name={"isRadomizeAnswers"}
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <CustomAnswerOptions
                type={"radio"}
                options={randomizeAnswersOptions}
                hookOnChange={onChange}
                hookOnBlur={onBlur}
                hookValue={value}
                name={name}
              />
            )}
          />
          <FormErrorComponent>
            {errors.isRadomizeAnswers && errors.isRadomizeAnswers?.message}
          </FormErrorComponent>
        </div>
        <p className="fs-23 fw-700 mt-3">Validity</p>
        <div className="ps-5">
          <p className="fs-16 fw-400 form-title">Is reset allowed</p>
          <Controller
            name={"isResetAllowed"}
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <CustomAnswerOptions
                type={"radio"}
                options={resetAllowedOptions}
                hookOnChange={onChange}
                hookOnBlur={onBlur}
                hookValue={value}
                name={name}
              />
            )}
          />
          <FormErrorComponent>
            {errors.isResetAllowed && errors.isResetAllowed?.message}
          </FormErrorComponent>
          <Form.Label className="fs-16 fw-400 form-title p-0 w-100">
            Attempts Allowed
          </Form.Label>
          <Controller
            name="attempts_allowed"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <CustomInputField
                type="number"
                className="w-25"
                min={0}
                placeholder="Attempts Allowed"
                hookOnChange={onChange}
                hookOnBlur={onBlur}
                hookValue={value}
                onChange={onChange}
              />
            )}
          />
          <FormErrorComponent>
            {errors.attempts_allowed && errors.attempts_allowed?.message}
          </FormErrorComponent>
          <Form.Label className="fs-16 fw-400 form-title p-0 mt-2 w-100">
            Valid Till
          </Form.Label>
          <Controller
            name="valid_till"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <DatePicker
                calendarClassName="react-calendar"
                name="date"
                format={process.env.REACT_APP_DATEPICKER_FORMAT}
                onChange={onChange}
                value={value}
                minDate={tomorrow}
                maxDate={tomorrowMax}
                clearIcon={null}
              />
            )}
          />
          <FormErrorComponent>
            {errors.valid_till && errors.valid_till?.message}
          </FormErrorComponent>
        </div>
        <p className="fs-23 fw-700 mt-3">Miscellaneous</p>
        <div className="ps-5">
          <Form.Group>
            <FormLabelComponent className="fs-16 fw-700 form-title">
              Email template for inviting users
            </FormLabelComponent>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <CustomSelect
                  opLabel="value"
                  opValue="dataId"
                  className="w-25"
                  items={emailTemplates}
                  onReactHookFormBlur={onBlur}
                  onReactHookFormChange={onChange}
                  value={value}
                  placeholder={"Default template"}
                />
              )}
              name="email_template_name"
            />
            <FormErrorComponent>{""}</FormErrorComponent>
          </Form.Group>
        </div>
        <div className="d-flex justify-content-end mt-5">
          <SubmitButton className="next-btn" text="Next" />
        </div>
      </div>
    </Form>
  );
};

const PubAssessmentSettings = () => {
  const { formElementLabels, assessmentsTranslations } =
    useIgurooTranslations();
  return (
    <div className="p-2">
      <h4 className="text-center p-2">
        {assessmentsTranslations?.assessmentSettings}
      </h4>
      <Row className="d-flex justify-content-center">
        <Col md={8} className="">
          <Card className="p-4 border-0 shadow">
            <AssessmenSettings />
          </Card>
        </Col>
        <AssessmentSideBar
          data={assessmentsTranslations?.assessmentProgressView}
          stage={2}
          title={assessmentsTranslations?.assessmentDetails}
          btnValue={formElementLabels?.publishBtn}
          currentTab={
            assessmentsTranslations?.assessmentProgressView?.assessmentSettings
          }
        />
      </Row>
    </div>
  );
};

export default PubAssessmentSettings;
