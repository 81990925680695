//** BackButton Component Field for Back to the page,can re-use this common Component required Field **//

import React from "react";
import { Button } from "react-bootstrap";
import { _getImage } from "../common/utils/commonFunctions";

export function BackButton(props) {
  const { text, onClick, className, variant, svgName } =
    props;
  return (
    <Button
      className={`ig-back-btn btn btn-block btn-outline-primary ${className}`}
      variant={variant ? variant : `outline-primary`}
      type="submit"
      onClick={onClick}
    >
      {svgName ? (
        <>
          <img src={_getImage(svgName ? svgName : "")} alt="icon-img" />{" "}
        </>
      ) : (
        ""
      )}
      {text ? text : ""}
    </Button>
  );
}