//** Iguroo SWOT Assessment ->Swot Example Popup ListView,Currently not using this code,will be implemented in future **//

import { Tab, Tabs, Card } from "react-bootstrap";
import "./swotcomponent.css";
import { SwotExamplePopupListView } from "./SwotExamplePopupListView";

export const SwotExampPopupleComponent = ({
  swotType,
  swotPlanType,
  swotName,
  data,
  className,
}) => {
  return (
    <>
      <Tabs
        className={`my-example-tab ${className}`}
        defaultActiveKey={swotType}
        id={`uncontrolled-tab-example${swotType}`}
      >
        <Tab eventKey={swotType} title={swotName}>
          <SwotExamplePopupListView
            swotField={data}
            swotType={swotType}
            swotPlanType={swotPlanType}
          />
        </Tab>
      </Tabs>
    </>
  );
};
