//** Dashboard Request Aggrid Page **//

import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useMentorRequestDefinitions } from "../../AgGridDefinitions/mentor.requests.grid.definitions";
import { searchRequests } from "../../services/axios/opensearch";
import { useContext } from "react";
import { AuthContext } from "../../hooks/auth.context.provider";
import {
  getStatusSelect,
} from "../../helpers/grid.helpers";
import { CustomSelect } from "../../components/CustomInput/custom.select";
import { AgGridFieldsEnum, AgGridNoRowsParams, AppUrlsEnum, RequestStatus } from "../../enums";
import { Col, Image, Row } from "react-bootstrap";
import { AgGridMain } from "../../components/agGridRenderers/ag.grid.main";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { SubmitButton } from "../../components/submit.button";
import { _getImage } from "../../common/utils/commonFunctions";
import { FormLabelComponent } from "../../components/form.label.component";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { useNavigate } from "react-router-dom";
import { Heading } from "../../components/heading.component";
import { useDispatch, useSelector } from "react-redux";
import { decrementLoader, incrementLoader, loader } from "../../redux-tk/slices/loader.slice";
import { GetDataType } from "../../services/axios/staticData";
import { languageValue } from "../../redux-tk/slices/general.info.slice";
import { requestsCombiner } from "../../helpers/combiners/grid.combiners";

export function MentorRequests() {
  const { formElementLabels, staticSelectTranslations, headingTranslations } =
    useIgurooTranslations();
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  const [selectCategorySearch, setSelectCategorySearch] = useState();
  const [selectStatusSearch, setSelectStatusSearch] = useState();
  const [categorySearch, setCategorySearch] = useState();
  const [searchSearch, setSearchSearch] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const selectedLanguage = useSelector((state) => languageValue(state));
  const requestStatusItems = useMemo(
    () => getStatusSelect(staticSelectTranslations),
    [staticSelectTranslations]
  );
  const authContext = useContext(AuthContext);
  const { fieldsToHide, headers } = useMentorRequestDefinitions();

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    resizable: true,
    filter: true,
  }));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let isLoad = false;
  useEffect(() => {
    if (!isLoad) {
      (async function () {
        dispatch(incrementLoader());
        setIsLoading(true);
        const aoi = await GetDataType("requestCategory", selectedLanguage);
        aoi.unshift({ dataId: RequestStatus.all, value: staticSelectTranslations.all })
        setCategorySearch(aoi);
        dispatch(decrementLoader());
        setIsLoading(false);
      })();
    }
    return () => {
      isLoad = true;
      setCategorySearch([]);
    };
  }, []);

  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event);
    const reqNumber = event.data.requestNumber;
    localStorage.setItem(reqNumber, event.data.title);
    const Url = AppUrlsEnum.mentorRequestDetails.replace(":id", reqNumber);
    navigate(`${Url}`);
    
  }, []);

  const getDataG = async (
    mentorId,
    category,
    searchText,
    status,
    startRow,
    endRow
  ) => {
    const d = await searchRequests({
      mentorId: mentorId,
      category: category,
      searchText: searchText,
      status: status,
      startRow: startRow,
      endRow: endRow,
      isMentor: true,
    });
    return d;
  };

  const onGridReadyNew = useCallback((params) => {
    //console.log("initial params",params);
    const paginationProxy = params.api.paginationProxy;
    const currentPage = paginationProxy?.currentPage;
    const pageSize = paginationProxy?.pageSize;
    const masterRowCount = paginationProxy?.masterRowCount;
    console.log(
      "pages",
      paginationProxy,
      currentPage,
      pageSize,
      masterRowCount
    );
    
    const dataSource = {
      rowCount: null,
      getRows: async function (rparams) {
        dispatch(incrementLoader());
        try {
          const filterModel = rparams.filterModel;
          console.log("rows params", rparams);
          const categoryFilter = filterModel[AgGridFieldsEnum.category]?.filter;
          const statusFilter = filterModel[AgGridFieldsEnum.status]?.filter;
          const titleFilter = filterModel[AgGridFieldsEnum.requestNumber]?.filter;
          const endRow = rparams.endRow;
          const startRow = rparams.startRow;
          console.log(
            "filter',",
            categoryFilter,
            statusFilter,
            titleFilter,
            filterModel,
            endRow,
            startRow
          );
          //console.log("calln,data",statusFilter,RequestStatus.all,statusFilter === RequestStatus.all);

          const d = await getDataG(
            authContext.sessionInfo.sub,
            categoryFilter === RequestStatus.all ? undefined : categoryFilter,
            titleFilter,
            statusFilter === RequestStatus.all ? undefined : statusFilter,
            startRow,
            endRow - startRow
          );
          // const data = extractDataFromOpensearch(d.hits, "userId");
          // console.log("data got ", data);
          // const promises = [getListFromSessionList(data.requestNumbers),GetListOfUserProfiles(data.userIds)];
          // const allPromiseData = await Promise.all(promises);
          // const convertedSessions = getRequestKeySession(allPromiseData[0].response);
          // const convertedUsers = getRequestKeyUser(allPromiseData[1].data);
          // const mergedRequestsAndSessions = mergeRequestAndSessions(data.data, convertedSessions, convertedUsers, "userId")
          const mergedRequestsAndSessions = await requestsCombiner(d,"userId");
          console.log("mergedRequestsAndSessions", mergedRequestsAndSessions);
          if (!d.total.value) {
            gridRef.current.api.showNoRowsOverlay();
          } else {
            gridRef.current.api.hideOverlay();
          }
          rparams.successCallback(mergedRequestsAndSessions, d.total.value);
        } catch (err) {
          console.log("err", err);
          gridRef.current.api.showNoRowsOverlay();
          rparams.successCallback([], 0);
        } finally {
          dispatch(decrementLoader());
        }
      },
    };
    params.api.setDatasource(dataSource);
  }, []);

  const applyCategoryModel = (e) => {
    console.log("val", selectStatusSearch, selectCategorySearch, searchSearch);
    gridRef.current.api.setFilterModel({
      [AgGridFieldsEnum.status]: {
        filter: selectStatusSearch?.dataId,
        filterType: "text",
        type: "equals",
      },
      [AgGridFieldsEnum.category]: {
        filter: selectCategorySearch?.dataId,
        filterType: "text",
        type: "equals",
      },
      [AgGridFieldsEnum.requestNumber]: {
        filter: searchSearch,
        filterType: "text",
        type: "contains",
      },
    });
  };
  const onRowClicked = (params) => {
    console.log(params.data, "params.data");
    const reqNumber = params.data.requestNumber;
    localStorage.setItem(reqNumber, params.data.title);
    navigate(`${AppUrlsEnum.mentorRequests}/${reqNumber}`);
  };

  const changePage = () => {
    window.scrollTo({ top:0, behavior: "smooth" });
  };

  return (
    <div className="ms-3 me-3">
      {/* Example using Grid's API */}
      {/* <button onClick={buttonListener}>Push Me</button> */}
      <Heading className="headingDashboard">
        {headingTranslations?.myRequests}
      </Heading>
      <Row className="d-flex align-items-end mb-3">
        <Col md={2} className="pe-0">
          <Row className="d-flex align-items-center">
            <Col sm={2}>
              <FormLabelComponent></FormLabelComponent>
              <Image src={_getImage("filter.svg")} />
            </Col>
            <Col sm={10} className="mt-3">
              <FormLabelComponent>{formElementLabels.status}</FormLabelComponent>
              <CustomSelect
                opLabel="value"
                opValue="dataId"
                items={requestStatusItems}
                sendFullValueInHook={true}
                onChangeCallback={setSelectStatusSearch}
                value={selectStatusSearch}
                normalSelect={true}
              />
            </Col>
          </Row>
        </Col>
        <Col md={3} className="pe-0 mt-3">
          <FormLabelComponent>{formElementLabels.category}</FormLabelComponent>
          <CustomSelect
            opLabel="value"
            opValue="dataId"
            items={categorySearch}
            onReactHookFormChange={(e) => {
              console.log(e, "setSelectCategorySearch");
              setSelectCategorySearch(e);
            }}
            value={selectCategorySearch}
            isLoading={isLoading}
            sendFullValueInHook={true}
            normalSelect={true}
          />
        </Col>
        <Col md={5} lg={6}>
          <FormLabelComponent></FormLabelComponent>
          <CustomInputField
            placeholder={formElementLabels?.placeHolders?.search}
            onChange={setSearchSearch}
            hookValue={searchSearch}
          />
        </Col>
        <Col sm={12} md={2} lg={1} className="mt-3 d-grid">
          <SubmitButton onClick={applyCategoryModel} />
        </Col>
      </Row>
      <div className="mb-3">
        <AgGridMain
          gridRef={gridRef}
          columnDefs={headers} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          onCellClicked={cellClickedListener} // Optional - registering for Grid Event
          onGridReady={onGridReadyNew}
          colsToHide={fieldsToHide}
          //getLocaleText={getLocaleText}
          onRowClicked={onRowClicked}
          rowHeight={100}
          className={'ig-aggrid-css'}
          noRowsParams={{ [AgGridNoRowsParams.isMentorRequests]: true }}
          onPaginationChanged={changePage}
        />
      </div>
    </div>
  );
}
