//** GinieHealth Auhtor,Assessment DetailsCard Header Component,re-use any required field**//

import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { _getImage } from "../../common/utils/commonFunctions";
import "./styles.scss";
import Rating from "react-rating";
import { useGetAssessments } from "../../hooks/useGetAssessments";
import { GetAssessmentsApi } from "@iguroo/ig-react-assessment-package";
import { FormHeading } from "../form.heading.component";
import { CustomImage } from "../Media";
import { ImgSrcEnums } from "@iguroo/ig-react-common-package";
import { Link } from "react-router-dom";
import { BackButton } from "../back.button";
import { AppUrlsEnum } from "../../enums";
import { card_rate_display, card_price_display } from "../../services/config";

function AssessmentDetailsHeader(props) {
  const { profilePic, className, style, assessment_id, version } = props;
  const { assessments, fetchInitialAssessments } = useGetAssessments({
    apiFunction: GetAssessmentsApi,
    initialQuery: { assessment_id, version },
  });

  useEffect(() => {
    fetchInitialAssessments({ assessment_id, version });
  }, []);

  console.log(profilePic, "key profileImage console");
  return (
    <>
      {assessments.length > 0 ? (
        <Container className={`assessment-info p-4 ${className}`} style={style}>
          <Row>
            {assessments.map((data) => {
              return (
                <>
                  <Col>
                    <Link to={AppUrlsEnum.authorUserPage}>
                      <BackButton
                        svgName="back_btn1.svg"
                        className="author-back-btn"
                      />
                    </Link>
                  </Col>
                  <Col sm={2}>
                    <div className="profile-img d-flex justify-content-center">
                      {data.banner_image ? (
                        <CustomImage
                          src={{
                            src: `${data?.banner_image}`,
                            type: ImgSrcEnums.props,
                          }}
                          height="100px"
                          width="100px"
                          roundedCircle={true}
                        />
                      ) : (
                        <img
                          height="100px"
                          width="100px"
                          src={_getImage("default_logo_new.jpg")}
                          className="icon rounded-circle"
                        />
                      )}
                    </div>
                  </Col>
                  <Col sm={9}>
                    <div className="info mt-2">
                      <h1
                        className="name-firstletter"
                        style={{ cursor: "pointer", color: "var(--black)" }}
                      >
                        {data.title}
                      </h1>
                      <div className="description my-1">{data.subtitle}</div>
                      <p className="my-2">{data.details}</p>
                      <p>
                        {data.duration}(
                        {data.number_of_questions
                          ? data.number_of_questions
                          : "0"}{" "}
                        Question{data.number_of_questions === 1 ? "" : "s"})
                      </p>
                      {card_rate_display ? (
                        <Rating
                          placeholderRating={data.rating ? data.rating : 0}
                          readonly={true}
                          emptySymbol={
                            <img
                              height={20}
                              width={20}
                              src={_getImage("emptyBlueStar.svg")}
                              className="icon"
                            />
                          }
                          placeholderSymbol={
                            <img
                              height={20}
                              width={20}
                              src={_getImage("bluestar.svg")}
                              className="icon"
                            />
                          }
                          fullSymbol={
                            <img
                              height={20}
                              width={20}
                              src={_getImage("bluestar.svg")}
                              className="icon"
                            />
                          }
                        />
                      ) : (
                        " "
                      )}
                      {card_price_display ? (
                        <div className="price my-2">{data.price}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </>
              );
            })}
          </Row>
        </Container>
      ) : (
        <FormHeading className="text-center mt-4">Loading...</FormHeading>
      )}
    </>
  );
}

export default AssessmentDetailsHeader;
