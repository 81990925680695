//** Heading Component,Title Heading,can re-use this common Component required Field **//

import React from "react";

export function Heading(props) {
  const { text, className } = props;
  return (
    <span className={`ig-heading ${className}`}>
      {props.children?.length > 0 ? props.children : text}
    </span>
  );
}
