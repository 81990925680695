//** File Upload and Delete,can re-use this common Component required Field**//

import React, { useContext, useEffect, useState } from "react";
import { ActivityIndicator} from "react-native-web";
import {  Text} from "react-native";
import { AuthContext } from "../hooks/auth.context.provider";
import { S3Folders } from "../enums";
import { getUploadFileFromS3, uploadFileToS3 } from "../common/aws.helpers";
import { useFileUploader } from "../hooks/useFileUploader";
import { CustomDeleteBtn } from "./CustomInput/customDeleteBtn";
import { extractNameFromS3Url } from "../helpers";
import Toaster from "./toaster";
import { toast } from "react-toastify";
import { S3_BUCKET } from "../services/config";
require(`../css/${process.env.REACT_APP_CSS_INPUT}`);

function FileUploader(props) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [urls, setUrls] = useState([]);
  const authContext = useContext(AuthContext);
  const MAX_COUNT = props.maxCount;
  console.log(
    "uploaded props",
    props?.uploadedFileUrls,
    props?.uploadedFileUrls?.length > 0 ? true : false
  );

  const [fileLimit, setFileLimit] = useState(
    props?.uploadedFileUrls?.length > 0 ? true : false
  );
  console.log("uploaded props", props?.uploadedFileUrls, fileLimit);

  const uploadFile = (uploaded) => {
    console.log("setting file limit to false", fileLimit);
    if (fileLimit) {
      Toaster(
        toast.error,
        "Please delete an already uploaded file to upload a new one."
      );
      return;
    }
    const numberOfFiles = uploaded ? uploaded.length : uploadedFiles.length;
    const files = uploaded ? uploaded : uploadedFiles;
    setUploading(true);
    let fNumber = 0;
    const fUrls = [];
    console.log("no of file", numberOfFiles);
    files.forEach(async (file) => {
      const url = await authContext.uploadFileToS3({
        file: file,
        folder: S3Folders.images,
        bucketName: props.bucketName ? props.bucketName : S3_BUCKET,
      });
      //** this furl will need in future implement **//
      //uploadFileToS3(file,authContext.sessionInfo.sub, S3Folders.video);
      //const furl = await getUploadFileFromS3(url.key);
      //console.log("uploaded url",furl);
      // const theme = useTheme();
      // const [fileLimit, setFileLimit] = useState(
      //   props?.uploadedFileUrls?.length > 0 ? true : false
      // );
      if (url) fUrls.push({ fileName: file.name, url: url });
      setProgress((fNumber / numberOfFiles) * 100);
      fNumber++;
      console.log("fn", fNumber, numberOfFiles);
      if (fNumber === numberOfFiles) {
        setUploading(false);
        setUrls(fUrls);
        props.fileUrls(fUrls);
      }
    });
  };

  const deleteFile = async (file) => {
    console.log(file, "u-file");
    const updatedFiles = uploadedFiles.filter((f) => f.name != file.name);
    const updatedUrls = urls.filter((f) => f.fileName != file.name);
    setUploadedFiles(updatedFiles);
    setUrls(updatedUrls);
    props.fileUrls(updatedUrls, true);
    console.log("setting file limit to false");
    setFileLimit(false);
  };

  const deleteFileUrl = async (url) => {
    console.log("setting file limit to false");

    props.fileUrls(url, true);
    setFileLimit(false);
  };

  console.log("file limit", fileLimit);
  const handleUploadFiles = (files, fileType) => {
    console.log("setting file limit to false", fileLimit);

    if (fileLimit) {
      Toaster(
        toast.error,
        "Please delete an already uploaded file to upload a new one."
      );
      return;
    }
    const uploaded = [...uploadedFiles];
    console.log("up", uploaded, files);
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
        if (!fileType.includes("video") && file.size > 100000) {
          Toaster(toast.error, "Please upload files less than 100KB");
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    console.log(uploadedFiles.length, "uploadedFiles");
    if (!limitExceeded) setUploadedFiles(uploaded);
    if (props.uploadOnSelect) {
      uploadFile(uploaded);
    }
  };

  const handleFileEvent = (e) => {
    console.log("Please upload  Format", e.target.attributes.accept.value);
    const fileType = e.target.attributes.accept.value;
    if (
      !e.target.files[0].type.includes("video") &&
      fileType.includes("video")
    ) {
      console.log("Please upload only Video Format");
      Toaster(toast.error, "Please upload only Video Format");
    } else {
      const chosenFiles = Array.prototype.slice.call(e.target.files);
      handleUploadFiles(chosenFiles, fileType);
    }
  };

  useEffect(() => {
    (async function () {
      console.log(props?.uploadedFileUrls, "$$$$$$$");
      if (props?.uploadedFileUrls?.length > 0) {
        setFileLimit(true);
      } else {
        setFileLimit(false);
      }
    })();
  }, [props]);

  return (
    <div>
      <div className="mt-2">
        <ActivityIndicator animating={uploading} color={"rgb(74, 0, 144)"} />
      </div>
      <div className="uploaded-files-list text-center mb-2">
        {uploadedFiles.length > 0 ? (
          <>
            {uploadedFiles?.map((file, index) => (
              <div key={index} className="row">
                <div
                  style={{
                    overflow: "hidden",
                    "text-overflow": "ellipsis",
                    "white-space": "nowrap",
                  }}
                  className="col-12 text-center"
                >
                  {file.name}
                  {" up"}

                  <CustomDeleteBtn
                    onClick={() => deleteFile(file)}
                    className="d-inline"
                    classNameImage="mb-1"
                  />
                </div>
              </div>
            ))}
          </>
        ) : (
          props?.uploadedFileUrls?.map((url) => (
            <div key={Date.now()} className="row ">
              <div
                style={{
                  overflow: "hidden",
                  "text-overflow": "ellipsis",
                  "white-space": "nowrap",
                }}
                className="col-12 text-center"
              >
                {extractNameFromS3Url(url)}
                {" url"}

                <CustomDeleteBtn
                  onClick={() => deleteFileUrl(url)}
                  className="d-inline"
                  classNameImage="mb-1"
                />
              </div>
            </div>
          ))
        )}
      </div>
      <div className="text-center">
        <label
          htmlFor="fileUpload"
          className="file-upload-label cursor-pointer"
        >
          <img src={props.labelImage} />
          <div
            className="ms-2 d-inline-block"
            style={props.uploadInputLabelTextStyle}
          >
            {!fileLimit ? props.uploadInputLabelText : props.uploadedtext}
          </div>
        </label>
      </div>
      <input
        id="fileUpload"
        type="file"
        multiple
        accept={props.uploadFileTypes}
        onChange={handleFileEvent}
        onClick={(e) => {
          e.target.value = null;
        }}
        className={props.inputClass}
      />

      <label>
        {props.uploadButtonText && (
          <button
            style={{
              all: "unset",
              cursor: "pointer",
            }}
            onClick={uploadFile}
            className={`btn btn-primary`}
          >
            <Text style={props.uploadButtonTextStyle}>
              {props.uploadButtonText}
            </Text>
          </button>
        )}
      </label>
    </div>
  );
}

export default FileUploader;
