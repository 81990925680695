//** Currently was not implemented any where,Will be used in future **//
import React from "react";
import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { CustomCheckButton } from "../../components/CustomInput/custom.switch";
import { SubmitButton } from "../../components/submit.button";

const UserSettings = () => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const submit = (data) => {
    console.log(data);
  };
  return (
    <div className="m-3 p-2">
      <Form onSubmit={handleSubmit(submit)}>
        <Controller
          name={`email`}
          control={control}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <CustomCheckButton
              hookOnChange={onChange}
              hookOnBlur={onBlur}
              hookValue={value}
              name={name}
              type="checkbox"
              label={`Opt-out Emails`}
              defaultChecked={false}
              checked={value}
            />
          )}
        />
        <Controller
          name={`sms`}
          control={control}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <CustomCheckButton
              hookOnChange={onChange}
              hookOnBlur={onBlur}
              hookValue={value}
              name={name}
              type="checkbox"
              label={`Opt-out SMS`}
              defaultChecked={false}
              checked={value}
            />
          )}
        />
        <SubmitButton className="mt-2" />
      </Form>
    </div>
  );
};

export default UserSettings;
