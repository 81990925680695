//** VideoLink can Uplaod,can re-use this common Component required Field **//

import React, { useEffect, useState } from "react";
import { Col, Card, Row } from "react-bootstrap";
import { getUploadFileFromS3 } from "../common/aws.helpers";
import { _getImage } from "../common/utils/commonFunctions";

export function FileCards(props) {
  const { className, text, svgName, fileUrl } = props;
  const [vidLink, setVidLink] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    console.log("video key is callstart", fileUrl);
    if (fileUrl) {
      (async function () {
        setIsLoading(true);
        const vid = await getUploadFileFromS3(fileUrl);
        console.log("base64img", vid);
        setIsLoading(false);
        setVidLink(vid);
      })();
    }
  }, [fileUrl]);

  return (
    <Col xs="12" md="6" lg="4" xd="4" className={` my-2  ${className}`}>
      <Card>
        <Card.Body className="px-2 py-2">
          <Row>
            <Col xs="auto">
              <img
                src={_getImage(svgName ? svgName : "file-icon.svg")}
                alt={props.logName}
              />
            </Col>
            <Col className="ps-0 ig-file-col">
              <Row className="ig-file-name">
                <a href={vidLink ? vidLink?.url : "#"}>
                  {props.children?.length > 0 ? props.children : text}
                </a>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}