//** GinieHealth common Search component UI code,can re-use it any required field **//

import { Form, InputGroup, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { _getImage } from "../../common/utils/commonFunctions";
import { CustomInputField } from "../CustomInput/customInputField";
import "./styles.css";

const SearchButton = ({ submit, handleOnChange }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <Form
      onSubmit={handleSubmit(submit)}
      className="d-flex flex-column flex-md-row align-items-md-center"
    >
      <Form.Group>
        <InputGroup className="search-field mt-2">
          <InputGroup.Text>
            <img src={_getImage("searchbar.svg")} />
          </InputGroup.Text>
          <Controller
            name="search"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <CustomInputField
                placeholder={"search"}
                hookOnChange={onChange}
                onChange={handleOnChange}
                hookOnBlur={onBlur}
                hookValue={value}
              />
            )}
          />
        </InputGroup>
      </Form.Group>
      <Button type="submit" className="me-md-3 mb-3 mb-md-0 ms-md-3 mt-2">
        Search
      </Button>
    </Form>
  );
};

export default SearchButton;
