//** Iguroo Swot Assessment Slice **//
//** Currently not using this code,will be used in Future Enhancement **//

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSwot, postSwot, updateSwot } from "../../services/axios/Swot/swot";
import uuid from "react-uuid";

export const getSwotAsyncThunk = createAsyncThunk(
  "swot/getSwotAsyncThunk",
  async (data, thunkApi) => {
    //console.debug("calling get swot with data", data);
    if (!data.userId) {
      return thunkApi.rejectWithValue(new Error("Invalid user"));
    }
    try {
      const swot = await getSwot(data.userId, data.swotId);
      //console.debug("getUserAsyncThunk swot", swot);
      return { data: swot.Items };
    } catch (err) {
      console.log("api error", err);
      thunkApi.rejectWithValue(err);
    }
  }
);

export const postSwotAsyncThunk = createAsyncThunk(
  "swot/postSwotAsyncThunk",
  async (data, thunkApi) => {
    try {
      console.log("swot data", data);
      const swot = await postSwot(data);
      console.log("swot", swot);
      return swot;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);

export const putSwotAsyncThunk = createAsyncThunk(
  "swot/putSwotAsyncThunk",
  async (data, thunkApi) => {
    try {
      const swot = await updateSwot(data);
      console.log("swot", swot);
      return swot.Attributes;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);

const initialState = {
  swot: {
    strPlans: [
      {
        id: uuid(),
        data: "",
      },
    ],
    opportunities: [
      {
        id: uuid(),
        data: "",
      },
    ],
    strengths: [
      {
        id: uuid(),
        data: "",
      },
    ],
    weaknesses: [
      {
        id: uuid(),
        data: "",
      },
    ],
    threats: [
      {
        id: uuid(),
        data: "",
      },
    ],
    swotId: null,
    opoPlans: [
      {
        id: uuid(),
        data: "",
      },
    ],
    wekPlans: [
      {
        id: uuid(),
        data: "",
      },
    ],
    thrPlans: [
      {
        id: uuid(),
        data: "",
      },
    ],
  },
};

export const swotSlice = createSlice({
  name: "swotData",
  initialState,
  reducers: {
    addSwotData: (state, action) => {
      state.swot[action.payload.type].push({
        id: uuid(),
        data: action.payload.data,
      });
    },
    updateSwotData: (state, action) => {
      const type = action.payload.type;
      const id = action.payload.id;
      const data = action.payload.data;

      const nSwotData = state.swot[type].map((val) => {
        if (val.id === id) {
          val.data = data;
        }
      });
      state.swot[type] = nSwotData;
    },
    deleteSwotData: (state, action) => {
      const type = action.payload.type;
      const id = action.payload.id;

      const index = state.swot[type].findIndex((val) => {
        return val.id === id;
      });
      if (index) {
        state.swot[type].slice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSwotAsyncThunk.fulfilled, (state, action) => {
        if (!action.payload.data) {
          return;
        }
        if (action.payload.data?.length === 0) {
          return;
        }
        state.swot = action.payload.data[0];
      })
      .addCase(getSwotAsyncThunk.pending, (state) => {})
      .addCase(getSwotAsyncThunk.rejected, (state) => {})
      .addCase(postSwotAsyncThunk.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        state.swot = action.payload;
      })
      .addCase(postSwotAsyncThunk.pending, (state) => {})
      .addCase(postSwotAsyncThunk.rejected, (state) => {})
      .addCase(putSwotAsyncThunk.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        state.swot = action.payload;
      })
      .addCase(putSwotAsyncThunk.pending, (state) => {})
      .addCase(putSwotAsyncThunk.rejected, (state) => {});
  },
});

export const { addSwotDataSlice, updateSwotDataSlice, deleteSwotDataSlice } =
  swotSlice.actions;

export const swotValue = (state) => state?.swot?.swot;

export default swotSlice.reducer;
