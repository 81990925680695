import moment from "moment";
import * as yup from "yup";
export function getMentorAvailabilityFormTranslated(formErrors) {
  return yup.object({
    timeZone: yup
      .object({
        value: yup.string(),
        dataId: yup.string(),
        dataType: yup.string(),
      })
      .required(formErrors?.areaOfEmphasisRequired)
      .typeError(formErrors?.areaOfEmphasisRequired),
    avails: yup
      .array()
      .of(
        yup.object({
          availability: yup
            .string()
            .required(formErrors?.availabilityIsRequired),
          timeRange: yup.object({
            endTime: yup
              .date()
              .required()
              .min(
                yup.ref("startTime"),
                formErrors?.startTimeGreatedThanEndTime
              ),
            startTime: yup.date().required(),
          }),
        })
      )
      .test("Unique", "Values have to be unique", (values) => {
        console.log("va", values);
        if (!values) return true;
        for (let i = 0; i < values.length; i++) {
          const avOne = values[i];
          for (let j = i + 1; j < values.length; j++) {
            const avTwo = values[j];
            if (avOne.availability === avTwo.availability) {
              if (
                Math.max(
                  convertAvailTimeToCurrentDate(avOne.timeRange.startTime),
                  convertAvailTimeToCurrentDate(avTwo.timeRange.startTime)
                ) <
                Math.min(
                  convertAvailTimeToCurrentDate(avOne.timeRange.endTime),
                  convertAvailTimeToCurrentDate(avTwo.timeRange.endTime)
                )
              ) {
                console.log("error", false);
                return false;
              }
            }
          }
        }
        return true;
      })
      .required(),
  });
}

function convertAvailTimeToCurrentDate(dateTime) {
  return moment({
    hours: dateTime.getHours(),
    minutes: dateTime.getMinutes(),
    seconds: dateTime.getSeconds(),
  });
}
