//** Onboarding Basic Profile Page **//

import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UserProfileModal from "../../components/userProfile/user.profile.modal";
import { getBasicFormTranslated } from "../../FormSchemas";
import { MentorSetupSidebar } from "../../components/mentor.setup.sidebar";
import { SubmitButton } from "../../components/submit.button";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { _getImage } from "../../common/utils/commonFunctions";
import {
  getCountryInfo,
  getState,
  getCities,
} from "../../services/mentorServices";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import CustomRadioOptions from "../../components/CustomRadioOptions";
import { CustomSelect } from "../../components/CustomInput/custom.select";
import FileUploader from "../../components/file.upload";
import { Heading } from "../../components/heading.component";
import { AlertComponent } from "../../components/alert.component";
import { FormErrorComponent } from "../../components/form.error.component";
import { FormLabelComponent } from "../../components/form.label.component";
import { useDispatch, useSelector } from "react-redux";
import { loader } from "../../redux-tk/slices/loader.slice";
import { GetDataType } from "../../services/axios/staticData";
import { profileDataAction } from "../../redux-tk/slices/mentor.onboarding.data";
import { PostUserProfile } from "../../services/axios/MentorOnbaording";
import { AuthContext } from "../../hooks/auth.context.provider";
import { AppUrlsEnum, ProfileType } from "../../enums";
import { getGenderFromId, getProfileObject } from "../../helpers";
import { FormHeading } from "../../components/form.heading.component";
import Toaster from "../../components/toaster";
import { toast } from "react-toastify";
import {
  getUserAsyncThunk,
  updateOnboardingStage,
  userProfileValue,
} from "../../redux-tk/slices/user.slice";
import { userDataToMentorProfile } from "../../helpers/form.data.converters";
import { OnboardingSteps } from "../../enums/setup.steps.enums";
import { CustomDeleteBtn } from "../../components/CustomInput/customDeleteBtn";
import { OnboardingHeading } from "../../components/onboarding.heading.component";
import { CustomVideo } from "../../components/Media/CustomVideo";
import { showPopupflag } from "../../redux-tk/slices/showPopupflag.slice";
import { Popup } from "../../components/CustomInput/popup";
require(`../../css/${process.env.REACT_APP_CSS_MENTOR}`);

export default function ProfileScreen({ settings, setShowPopupUnsaved }) {
  const {
    mentorOnboardingLabels,
    formElementLabels,
    formErrors,
    headingTranslations,
    headerFooterTranslations,
  } = useIgurooTranslations();
  const selectedValue = useSelector((state) => userProfileValue(state));
  const authContext = useContext(AuthContext);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    reset,
    setFocus,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {
      ...userDataToMentorProfile(selectedValue),
      type: ProfileType.mentor,
    },
    resolver: yupResolver(getBasicFormTranslated(formErrors)),
  });
  const [showPopup2, setShowPopup2] = useState(false);

  useEffect(() => {
    if (isDirty) {
      (async function () {
        dispatch(showPopupflag(true));
      })();
    }
  }, [isDirty]);

  React.useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      console.log(field, a, "field, a");
      return !!errors[field] ? field : a;
    }, null);
    console.log(firstError);
    if (firstError) {
      setFocus(firstError);
      const input = document.getElementsByName(firstError)[0];
      console.log(input);
      input?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  }, [errors, setFocus]);

  const navigate = useNavigate();
  let isSelLoaded = false;
  useEffect(() => {
    if (isSelLoaded) return;
    reset({ ...userDataToMentorProfile(selectedValue) });
    const videoLink =
      selectedValue.profileVideoLink &&
      selectedValue.profileVideoLink != "dummy"
        ? selectedValue.profileVideoLink
        : "";
    setVideoLink(videoLink);
    const userNewVideoLink = selectedValue?.newProfileVideoLink;
    setNewVideoLink(userNewVideoLink);
    setValue("profileVideoLink", videoLink);
    setValue("newProfileVideoLink", userNewVideoLink);
    const profilePic =
      selectedValue.profilePic && selectedValue.profilePic != "dummy"
        ? selectedValue.profilePic
        : "";
    setProfilePic(profilePic);
    setNewProfilePic(selectedValue?.newProfilePic);
    setValue("profilePic", profilePic);
    setValue("newProfilePic", selectedValue?.newProfilePic);
    //console.log("pic", profilePic);
    loadSelectedCityAndState();

    return () => {
      isSelLoaded = true;
    };
  }, [selectedValue]);
  console.log("err", errors);
  const onSubmit = async (data) => {
    try {
      //console.log("data)", data);
      dispatch(loader(true));
      if (settings) {
        data.onBoardStage = 6;
      } else {
        data.onBoardStage = OnboardingSteps[ProfileType.mentor][1].value;
      }
      //data.timeZone = availValue?.timeZone;
      data.firstName = authContext.sessionInfo.firstName;
      data.lastName = authContext.sessionInfo.lastName;
      data.email = authContext.sessionInfo.email;
      data.mobile = authContext.sessionInfo.phoneNumber;
      data.gender = getGenderFromId(data.gender, genderValue);
      const finalData = getProfileObject(data, authContext.sessionInfo.sub);
      finalData.newProfileVideoLink = newVideoLink;
      finalData.newProfilePic = newProfilePic;
      finalData.profileVideoLink = videoLink;
      finalData.profilePic = profilePic;
      finalData.newProfileVideoLink =
        newVideoLink === undefined ? "" : newVideoLink;
      finalData.newProfilePic =
        newProfilePic === undefined ? "" : newProfilePic;
      finalData.isNewProfileVidRejected =
        newVideoLink === (undefined || "") ? true : false;
      finalData.isNewProfilePicRejected =
        newProfilePic === (undefined || "") ? true : false;
      console.log("LOG Mentor data", finalData);
      data.gender = data.gender.dataId;
      const result = await PostUserProfile(
        finalData,
        authContext.sessionInfo.sub
      );
      dispatch(profileDataAction(data));
      console.log("res", result);
      dispatch(loader(false));
      Toaster(toast.success, headerFooterTranslations.toasterMsgOnboarding);
      if (!settings) {
        dispatch(updateOnboardingStage(data.onBoardStage));
        navigate(AppUrlsEnum.mentorAreaOfExperience);
      }
    } catch (err) {
      setError("apiError", { message: err.message });
      Toaster(toast.error, err?.response?.data?.message);
      dispatch(loader(false));
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const [stateIsLoading, setStateIsLoading] = useState(false);
  const [cityIsLoading, setCityIsLoading] = useState(false);
  const [mentoringYear, setMentoringYear] = useState([]);
  const [genderValue, setGender] = useState([]);
  const [language, setlang] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [currentCountry, setCurrentCountry] = useState();
  const [stateOptions, setStateOptions] = useState([]);
  const [currentState, setCurrentState] = useState();
  const [cityOptions, setCityOptions] = useState([]);
  const [currentCity, setCurrentCity] = useState();
  const [profilePic, setProfilePic] = useState("");
  const [newProfilePic, setNewProfilePic] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [newVideoLink, setNewVideoLink] = useState("");
  //console.log("pro", selectedValue.profileVideoLink);
  const getFileUrls = (fileUrls, deleted) => {
    if (deleted) {
      const removeName = newVideoLink
        ? "newProfileVideoLink"
        : "profileVideoLink";
      if (newVideoLink) {
        setNewVideoLink("");
        (async function () {
          dispatch(showPopupflag(true));
        })();
      } else {
        setVideoLink("");
      }
      setValue(removeName, "");
    }
    if (fileUrls?.length === 0) return;
    console.log("video new file urls", fileUrls);
    setNewVideoLink(fileUrls[0].url);
    setValue("newProfileVideoLink", fileUrls[0].url, { shouldValidate: true });

    if (!deleted) {
      (async function () {
        dispatch(showPopupflag(true));
      })();
      setShowPopup2(true);
    }
  };
  console.log("video profile screen", videoLink);
  let isLoad = false;
  useEffect(() => {
    if (!isLoad) {
      (async function () {
        dispatch(loader(true));
        dispatch(getUserAsyncThunk({ userId: authContext.sessionInfo.sub }));
        setIsLoading(true);
        const gItems = await GetDataType("gender", "english");
        if (settings) {
          const selectedGender = gItems.filter(
            (value) => value.dataId === selectedValue?.gender?.dataId
          );
          console.log(
            selectedGender,
            "selectedGender",
            selectedValue?.gender?.dataId
          );
          setGender(selectedGender);
        } else {
          console.log(
            gItems,
            "gItems selectedGender",
            selectedValue?.gender?.dataId
          );
          setGender(gItems);
        }

        const endOfTheYearItems = await GetDataType("endyear", "english");
        setMentoringYear(endOfTheYearItems);

        const languageItems = await GetDataType("languages", "english");
        setlang(languageItems);

        const countries = await getCountryInfo();
        console.log(countries);
        setCountryOptions(countries?.Items);
        //console.log("sel",selectedValue);
        loadSelectedCityAndState();
        dispatch(loader(false));
        setIsLoading(false);
      })();
    }
    return () => {
      isLoad = true;
    };
  }, []);

  const handleClose = () => {
    setShowPopup2(false);
  };

  const loadSelectedCityAndState = async () => {
    console.log(countryOptions, "countryOptions value");
    if (selectedValue?.gender?.value) {
      //console.log(selectedValue?.gender?.value,"seselectedValue?.gender?.value)");
      setValue("gender", selectedValue?.gender?.dataId);
    }
    if (selectedValue?.location?.country) {
      getStateFunc(selectedValue?.location?.country.country_code);
    }
    if (selectedValue?.location?.state) {
      getCity(selectedValue?.location?.state.state_code);
    }
  };

  useEffect(() => {
    (async function () {
      if (!currentCountry) return;
      getStateFunc(currentCountry.country_code);
      setValue("state", {});
    })();
  }, [currentCountry]);

  const getStateFunc = async (countryCode) => {
    setStateIsLoading(true);
    const states = await getState(countryCode);
    setStateOptions(states.Items);
    setStateIsLoading(false);
  };

  useEffect(() => {
    (async function () {
      if (!currentState) return;
      getCity(currentState.state_code);
      setValue("city", {});
    })();
  }, [currentState]);

  const getCity = async (stateCode) => {
    setCityIsLoading(true);
    const cities = await getCities(stateCode);
    setCityOptions(cities.Items);
    setCityIsLoading(false);
  };

  const getProfileImageUrl = (pfp) => {
    if (pfp) {
      //console.log("pfp", pfp);
      setNewProfilePic(pfp);
      setValue("newProfilePic", pfp, { shouldValidate: true });
      (async function () {
        dispatch(showPopupflag(true));
      })();
      setShowPopup2(true);
    }
  };

  const deleteProfilePic = () => {
    if (newProfilePic) {
      console.log("LOG deleteProfilePic NEW");
    } else {
      console.log("LOG deleteProfilePic OLD");
      setProfilePic("");
    }
    const removeName = newProfilePic ? "newProfilePic" : "profilePic";
    setValue(removeName, "");
    setNewProfilePic("");
    (async function () {
      dispatch(showPopupflag(true));
    })();
  };

  const [value, setInputValue] = useState("");
  const [error, setErrors] = useState(false);
  const handleChange = (event) => {
    if (event.target.value.length <= 79) {
      setInputValue(event.target.value);
      setErrors(false);
    } else {
      setErrors(true);
    }
  };

  const [errorDesc, setErrorsDesc] = useState(false);
  const handleDescChange = (event) => {
    if (event.target.value.length <= 1999) {
      setInputValue(event.target.value);
      setErrorsDesc(false);
    } else {
      setErrorsDesc(true);
    }
  };

  const genderMentoringField = () => {
    return (
      <>
        <div>
          <Controller
            name={`type`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="hidden"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              ></Form.Control>
            )}
          />
          <Controller
            name={`profilePic`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="hidden"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              ></Form.Control>
            )}
          />
          <Controller
            name={`profileVideoLink`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="hidden"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              ></Form.Control>
            )}
          />
          <Controller
            name={`newProfilePic`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="hidden"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              ></Form.Control>
            )}
          />
          <Controller
            name={`newProfileVideoLink`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="hidden"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              ></Form.Control>
            )}
          />
        </div>
        <Form.Group>
          <Row>
            <Col
              md="8"
              lg="8"
              className={`mt-3 ${
                settings
                  ? "d-flex  justify-content-center ig-radio-disable"
                  : ""
              }`}
            >
              <Controller
                name={"gender"}
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <CustomRadioOptions
                    label={mentorOnboardingLabels.gender}
                    options={genderValue}
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    name={name}
                  />
                )}
              />

              <FormErrorComponent>
                {errors.gender && errors.gender?.message}
              </FormErrorComponent>
            </Col>
            <Col md="4" lg="4" className="mt-3">
              <FormLabelComponent>
                {mentorOnboardingLabels.menitoringSince}
              </FormLabelComponent>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomSelect
                    opLabel="value"
                    opValue="dataId"
                    items={mentoringYear}
                    onReactHookFormBlur={onBlur}
                    onReactHookFormChange={onChange}
                    value={value}
                    isLoading={isLoading}
                    sendFullValueInHook={true}
                    normalSelect={true}
                    placeholder={formElementLabels?.placeHolders?.mentoringYear}
                  />
                )}
                name="mentoringFrom"
              />
              <FormErrorComponent>
                {errors.mentoringFrom && errors.mentoringFrom?.message}
              </FormErrorComponent>
            </Col>
          </Row>
        </Form.Group>
      </>
    );
  };

  const restFormField = () => {
    return (
      <>
        <Form.Group>
          <Row className="mt-4">
            <Col>
              <FormHeading>{mentorOnboardingLabels.language}</FormHeading>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomSelect
                    opLabel="value"
                    opValue="dataId"
                    items={language}
                    onReactHookFormBlur={onBlur}
                    onReactHookFormChange={onChange}
                    value={value}
                    sendFullValueInHook={true}
                    normalSelect={true}
                    isLoading={isLoading}
                    isMulti={true}
                    hideSelectedOptions={false}
                    placeholder={formElementLabels?.placeHolders?.language}
                  />
                )}
                name="languages"
              />
              <FormErrorComponent>
                {errors.languages && errors.languages?.message}
              </FormErrorComponent>
            </Col>
          </Row>

          <Row className="mt-4">
            <FormHeading>{mentorOnboardingLabels.location}</FormHeading>
          </Row>

          <Row>
            <Col md="4" lg="4" className="mt-3">
              <FormLabelComponent>
                {mentorOnboardingLabels.country}
              </FormLabelComponent>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomSelect
                    opLabel="country"
                    opValue="country_code"
                    items={countryOptions}
                    onReactHookFormBlur={onBlur}
                    onReactHookFormChange={onChange}
                    value={value}
                    sendFullValueInHook={true}
                    normalSelect={true}
                    isLoading={isLoading}
                    placeholder={formElementLabels?.placeHolders?.country}
                    onChangeCallback={(obj) => {
                      setCurrentCountry(obj);
                      setCurrentState(null);
                      setCurrentCity(null);
                      setStateOptions([]);
                      setCityOptions([]);
                      return setCountryOptions;
                    }}
                  />
                )}
                name="country"
              />
              <FormErrorComponent>
                {errors?.country?.country && errors?.country?.country?.message}
              </FormErrorComponent>
            </Col>
            <Col md="4" lg="4" className="mt-3">
              <FormLabelComponent>
                {mentorOnboardingLabels.state}
              </FormLabelComponent>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomSelect
                    opLabel="state"
                    opValue="state_code"
                    items={stateOptions}
                    onReactHookFormBlur={onBlur}
                    onReactHookFormChange={onChange}
                    value={value}
                    sendFullValueInHook={true}
                    normalSelect={true}
                    isLoading={stateIsLoading}
                    placeholder={formElementLabels?.placeHolders?.state}
                    onChangeCallback={(obj) => {
                      setCurrentState(obj);
                      setCurrentCity(null);
                      setCityOptions([]);
                      return setStateOptions;
                    }}
                  />
                )}
                name="state"
              />
              <FormErrorComponent>
                {errors.state?.state && errors.state?.state?.message}
              </FormErrorComponent>
            </Col>
            <Col md="4" lg="4" className="mt-3">
              <FormLabelComponent>
                {mentorOnboardingLabels.city}
              </FormLabelComponent>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomSelect
                    opLabel="city"
                    opValue="city_code"
                    items={cityOptions}
                    sendFullValueInHook={true}
                    normalSelect={true}
                    onReactHookFormBlur={onBlur}
                    onReactHookFormChange={onChange}
                    value={value}
                    isLoading={cityIsLoading}
                    placeholder={formElementLabels?.placeHolders?.city}
                  />
                )}
                name="city"
              />
              <FormErrorComponent>
                {errors.city?.city && errors.city?.city?.message}
              </FormErrorComponent>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <FormLabelComponent>
                {mentorOnboardingLabels.profileTitle}
              </FormLabelComponent>
              <Controller
                name={`profileTitle`}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomInputField
                    placeholder={formElementLabels?.placeHolders?.profileTitle}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    hookOnChange={(event) => {
                      handleChange(event);
                      onChange(event);
                    }}
                    maxLength={80}
                  />
                )}
              />
              <Form.Label className="formLabelNote">
                {mentorOnboardingLabels.profileTitleNote}
              </Form.Label>
              <div>
                {error && (
                  <FormErrorComponent>
                    {formErrors.titleDescription}
                  </FormErrorComponent>
                )}
                <FormErrorComponent>
                  {errors.profileTitle && errors.profileTitle?.message}
                </FormErrorComponent>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <FormLabelComponent>
                {mentorOnboardingLabels.description}
              </FormLabelComponent>
              <Controller
                name={`bio`}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomInputField
                    placeholder={formElementLabels?.placeHolders?.description}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    formAs={"textarea"}
                    rows={6}
                    hookOnChange={(event) => {
                      handleDescChange(event);
                      onChange(event);
                    }}
                    maxLength={2000}
                  />
                )}
              />
              {errorDesc && (
                <FormErrorComponent>
                  {formErrors.messageTitle}
                </FormErrorComponent>
              )}
              <FormErrorComponent>
                {errors.bio && errors.bio?.message}
              </FormErrorComponent>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="mt-3">
              <div
                className={`${
                  newVideoLink || videoLink ? "d-block" : "d-none"
                }`}
              >
                <CustomVideo
                  videoLink={newVideoLink ? newVideoLink : videoLink}
                />
              </div>

              <FileUploader
                maxCount={1}
                fileUrls={getFileUrls}
                uploadOnSelect={true}
                uploadFileTypes="video/*"
                inputClass={"video-file-input"}
                labelImage={_getImage("ic_upload.png")}
                uploadInputLabelText={mentorOnboardingLabels.uploadProfileVideo}
                uploadedtext={mentorOnboardingLabels.uploadedProfileVideo}
                deleteFileImage={_getImage("Delete.svg")}
                uploadedFileUrls={
                  newVideoLink ? [newVideoLink] : videoLink ? [videoLink] : []
                }
              />
              <FormErrorComponent>
                {errors.profileVideoLink && errors.profileVideoLink?.message}
              </FormErrorComponent>
            </Col>
          </Row>
        </Form.Group>

        <Row className="mt-1 mb-3">
          <FormErrorComponent>
            {errors.apiError && errors.apiError?.message}
          </FormErrorComponent>
        </Row>

        <hr />
        <Row className="text-end mb-4 justify-content-end save-btn-end">
          <Col sm="12" md="2" className="d-grid ">
            <SubmitButton type="submit" text={formElementLabels?.save} />
          </Col>
        </Row>
      </>
    );
  };
  const profilePhotoField = () => {
    return (
      <>
        <Col
          sm="12"
          md="2"
          className="mt-3 d-flex flex-column align-items-center"
        >
          <Row className="ig-form-label">
            <label>
              <FormLabelComponent>
                {mentorOnboardingLabels?.profilePhoto}
              </FormLabelComponent>
            </label>
          </Row>
          <UserProfileModal
            label={mentorOnboardingLabels?.profile}
            getImageUrl={getProfileImageUrl}
            aspect={1 / 1}
            imgUrl={newProfilePic ? newProfilePic : profilePic}
          />

          <Row className="text-center mt-3 justify-content-center">
            <div>
              <FormErrorComponent>
                {errors.profilePic && errors.profilePic?.message}
              </FormErrorComponent>
              <FormErrorComponent>
                {errors.newProfilePic && errors.newProfilePic?.message}
              </FormErrorComponent>
            </div>
            {profilePic || newProfilePic ? (
              <CustomDeleteBtn
                noChngIconToText={true}
                textClassName="profile-delete-text"
                svgName="trash_profile.svg"
                text="Remove"
                onClick={deleteProfilePic}
              ></CustomDeleteBtn>
            ) : (
              ""
            )}
          </Row>
        </Col>
      </>
    );
  };
  return (
    <Container>
      {!settings && <OnboardingHeading profileType={ProfileType.mentor} />}
      <Container className="px-3">
        <Row>
          <Col md="12" lg={settings ? 12 : 9}>
            {!settings && (
              <>
                <Heading>{headingTranslations?.mentorProfile}</Heading>
                <AlertComponent showIcon={true}>
                  {headingTranslations?.mentorProfileAlert}
                </AlertComponent>
              </>
            )}
            <Form onSubmit={handleSubmit(onSubmit)} className="mt-2">
              {settings ? (
                <>
                  <Row className="setting-page">
                    {profilePhotoField()}

                    <Col md="10">{genderMentoringField()}</Col>
                  </Row>
                  <Row>
                    <Col md={settings ? 12 : 10}>{restFormField()}</Col>
                  </Row>
                </>
              ) : (
                <Row>
                  {profilePhotoField()}
                  <Col md="10">
                    {genderMentoringField()}
                    {restFormField()}
                  </Col>
                </Row>
              )}
            </Form>
          </Col>
          {!settings && (
            <Col>
              {mentorOnboardingLabels?.rightSetupModule?.mentor && (
                <MentorSetupSidebar
                  typeToUse={mentorOnboardingLabels?.rightSetupModule?.mentor}
                  active={
                    mentorOnboardingLabels?.rightSetupModule?.mentor?.values
                      ?.basicInfo
                  }
                />
              )}
            </Col>
          )}
        </Row>
      </Container>
      <Popup
        closeOnBackClick={false}
        showPopup={showPopup2}
        type="popup"
        text={formElementLabels?.buttonSave}
        modalBody={formElementLabels?.placeHolders?.adminApproval}
        gotIt={handleClose}
      />
    </Container>
  );
}
