//** createRootReducer & Store the Data using createRootStore function **//

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { userApi } from "../queries/user.queries";
import { createRootReducer } from "./root.reducer";

/**
 * Creates the root redux store
 * @param {Object} persistConfig Config to persist redux
 * @returns the root store
 */

export const createRootStore = (persistConfig) => {
  const isDevelopment = process.env.NODE_ENV === "development";

  const rootReducer = createRootReducer();
  //const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      const defaultMiddleware = getDefaultMiddleware({
        serializableCheck: false,
      });
      //   return isDevelopment
      //     ? defaultMiddleware.concat(logger)
      //     : defaultMiddleware;
      return defaultMiddleware.concat(userApi.middleware);
    },
    devTools: isDevelopment,
  });
  //const persistor = persistStore(store);
  setupListeners(store.dispatch);
  //return { store, persistor };
  return { store };
};
