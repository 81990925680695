import { axiosStaticData } from "../axios.service";
import { RECOMMENDATION, LATEST_ATTEMPT } from "../../urlConst";

export async function GetRecommendation(userId) {
  try {
    const params = {
      userId: userId,
    };
    const res = await axiosStaticData.get(RECOMMENDATION, { params });
    return res?.data?.response?.Items;
  } catch (err) {
    throw err;
  }
}

export const UpdateRecommendation = async (data) => {
  try {
    const response = await axiosStaticData.post(RECOMMENDATION, data);
    return response?.data.response;
  } catch (err) {
    throw err;
  }
};

export const GetLatestAttempt = async (userId) => {
  try {
    const params = {
      userId: userId,
    };
    const res = await axiosStaticData.get(LATEST_ATTEMPT, { params });
    return res?.data?.response?.Items;
  } catch (err) {
    throw err;
  }
};
