//** Onboarding WorkExperience Page **//

import React, { useContext, useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Row, Col, Container } from "react-bootstrap";
import { allDataConverter, getStepsObject } from "../../helpers";
import { useNavigate } from "react-router-dom";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import Toaster from "../../components/toaster";
import { toast } from "react-toastify";
import { MentorSetupSidebar } from "../../components/mentor.setup.sidebar";
import { SubmitButton } from "../../components/submit.button";
import { CustomAddMoreBtn } from "../../components/CustomInput/customAddMoreBtn";
import { AppUrlsEnum, OnboardingSteps, ProfileType } from "../../enums";
import { AlertComponent } from "../../components/alert.component";
import { Heading } from "../../components/heading.component";
import { WorkExpItemComponent } from "../../components/work.exp.item.component";
import { AuthContext } from "../../hooks/auth.context.provider";
import { useDispatch, useSelector } from "react-redux";
import {
  deletWorkExpAsyncThunk,
  getWorkExpAsyncThunk,
  workExpAction,
  workExpValue,
} from "../../redux-tk/slices/mentor.onboarding.data";
import { PostWorkExp } from "../../services/axios/MentorOnbaording";
import { getWorkExpFormTranslated } from "../../FormSchemas";
import { loader } from "../../redux-tk/slices/loader.slice";
import { postUserProfileAsyncThunk } from "../../redux-tk/slices/user.slice";
import { OnboardingHeading } from "../../components/onboarding.heading.component";
import { FormErrorComponent } from "../../components/form.error.component";
import { showPopupflag } from "../../redux-tk/slices/showPopupflag.slice";
require(`../../css/${process.env.REACT_APP_CSS_MENTOR}`);

export const WORK_EXPERIENCE_TEMPLATE = {
  id: -1,
  role: "",
  company: "",
  year: "",
};

export default function WorkExpScreen({ settings }) {
  const authContext = useContext(AuthContext);
  const dispatch = useDispatch();
  const selectedValue = useSelector((state) => workExpValue(state));
  //console.log("se", selectedValue);
  const navigate = useNavigate();
  const {
    mentorOnboardingLabels,
    formElementLabels,
    formErrors,
    headingTranslations,
    headerFooterTranslations,
  } = useIgurooTranslations();
  const {
    control,
    reset,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: { workExp: selectedValue },
    resolver: yupResolver(getWorkExpFormTranslated(formErrors)),
  });
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "workExp", // unique name for your Field Array
    shouldUnregister: true,
  });
  let ignore = false;

  useEffect(() => {
    if (isDirty) {
      (async function () {
        dispatch(showPopupflag(true));
      })();
    }
  }, [isDirty]);

  useEffect(() => {
    if (!ignore) {
      dispatch(getWorkExpAsyncThunk({ userId: authContext.sessionInfo.sub }));
    }
    return () => {
      ignore = true;
    };
  }, []);
  useEffect(() => {
    if (selectedValue[0]?.id != -1) {
      reset({ workExp: selectedValue });
    }
  }, [selectedValue]);

  const onSubmit = async (data) => {
    console.log("sub", data);
    dispatch(loader(true));
    const finalData = allDataConverter.removeDefaultId(data.workExp);
    const result = await PostWorkExp(authContext.sessionInfo.sub, finalData);
    if (!settings) {
      await dispatch(
        postUserProfileAsyncThunk(
          getStepsObject(
            OnboardingSteps[ProfileType.mentor][3].value,
            authContext.sessionInfo.sub
          )
        )
      );
    }
    //console.log("result", result);
    dispatch(workExpAction(result.data));
    dispatch(loader(false));
    Toaster(toast.success, headerFooterTranslations.toasterMsgOnboarding);
    if (!settings) {
      navigate(AppUrlsEnum.mentorEducation);
    }
  };

  const removeAvailabilityInputs = async (inputIndex, id) => {
    if (fields.length > 1) {
      const value = getValues(`workExp.${inputIndex}.id`);
      if (value === "-1" || value === -1) {
        remove(inputIndex);
        return;
      }
      //console.log('dele"',field,value);
      dispatch(deletWorkExpAsyncThunk({ id: value }));
    }
  };

  const addAvailabilityInputs = () => {
    const wet = { ...WORK_EXPERIENCE_TEMPLATE };
    append(wet);
  };

  return (
    <div>
      {!settings && <OnboardingHeading profileType={ProfileType.mentor} />}
      <Container className="px-3">
        <Row>
          <Col md="12" lg={settings ? 12 : 9}>
            {!settings && (
              <>
                <Heading>{headingTranslations?.mentorWorkExpQuestion}</Heading>
                <AlertComponent showIcon={true}>
                  {headingTranslations?.mentorWorkExpScreenAlert}
                </AlertComponent>
              </>
            )}
            <Form onSubmit={handleSubmit(onSubmit)} className="mt-3">
              <Form.Group>
                {fields.map((field, index) => {
                  //console.log("fil'", field);
                  return (
                    <WorkExpItemComponent
                      key={field.id}
                      removeFunction={removeAvailabilityInputs}
                      hookControl={control}
                      itemIndex={index}
                      errors={errors}
                      profileType={ProfileType.mentor}
                    />
                  );
                })}
                <Row>
                  <Col md="4" className="d-grid d-md-block">
                    <CustomAddMoreBtn
                      className="mt-3"
                      onClick={(e) => {
                        addAvailabilityInputs();
                      }}
                    />
                  </Col>
                  <Col md="8"></Col>
                </Row>
              </Form.Group>

              <Row className="mt-1 mb-3">
                <FormErrorComponent>
                  {errors.apiError && errors.apiError?.message}
                </FormErrorComponent>
              </Row>

              <hr />
              <Row className="text-end mb-4 justify-content-end save-btn-end">
                <Col sm="12" md="2" className="d-grid ">
                  <SubmitButton type="submit" text={formElementLabels?.save} />
                </Col>
              </Row>
            </Form>
          </Col>
          {!settings && (
            <Col>
              {mentorOnboardingLabels?.rightSetupModule?.mentor && (
                <MentorSetupSidebar
                  typeToUse={mentorOnboardingLabels?.rightSetupModule?.mentor}
                  active={
                    mentorOnboardingLabels?.rightSetupModule?.mentor?.values?.we
                  }
                />
              )}
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}
