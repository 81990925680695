import React from "react";
import AvailabilityScreen from "../../mentor/availability.screen.js";

const Availability = () => {
  return (
    <>
      <AvailabilityScreen settings={true} />
    </>
  );
};

export default Availability;
