//** Iguroo Aggrid main,RequestTitle(Mentor & Mentee) Data Field,can re-use required field **//

import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { _getImage } from "../../common/utils/commonFunctions";
import { AppUrlsEnum } from "../../enums";
import { isMentorOrPre } from "../../helpers";
import { getRequestdetails } from "../../services/axios/requests";

export const RequestTitleRenderer = ({ value, data }) => {
  const [request, setRequest] = useState();
  const profile = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  let active = false;

  useEffect(() => {
    if (data?.title) {
      setRequest(data);
    } else {
      if (!active) {
        (async function () {
          try {
            if (!value) return;
            console.log("req value", value);
            const requestDetail = await getRequestdetails(value);
            setRequest(requestDetail?.Items[0]);
          } catch (err) {
            console.log(
              "err getting request",
              "value",
              value,
              err.response,
              "req",
              err.request,
              "mess",
              err.message,
              "config",
              err.config
            );
          }
        })();
      }
    }

    return () => {
      active = true;
    };
  }, [value, data?.title]);
  if (!value) {
    return <></>;
  }

  const handleCardClick = () => {
    localStorage.setItem(value, request?.title);
    if (isMentorOrPre(profile?.type)) {
      navigate(AppUrlsEnum.mentorRequestDetails.replace(":id", value));
    } else {
      console.log(
        "profile?.type",
        profile?.type,
        AppUrlsEnum.menteeRequestDetails
      );
      navigate(AppUrlsEnum.menteeRequestDetails.replace(":id", value));
    }
  };

  return (
    <>
      <div
        className={`ig-request-heading `}
        onClick={() => handleCardClick()}
        role={"button"}
      >
        {request?.title?.trim()?.charAt(0)?.toUpperCase()}
        {""}
        {request?.title?.trim().slice(1)}
      </div>
    </>
  );
};
