//** Detailed and Quick Assessment Selection Popup for Iguroo**//

import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { SubmitButton } from "../../components/submit.button";
import { Card, Col, Row } from "react-bootstrap";
import { _getImage } from "../../common/utils/commonFunctions";

function QuickDetailedPopup(props) {
  const { showModal, closeOnBackClick } = props;
  const [selectedAssessment, setSelectedAssessment] = useState(1);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setSelectedAssessment(1);
    setShow(false);
    closeOnBackClick();
  };

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  useEffect(() => {
    setSelectedAssessment(1);
  }, []);

  const handleQuickAssessment = () => {
    setSelectedAssessment(2);
  };

  const handleDetailedAssessment = () => {
    setSelectedAssessment(1);
  };

  const handleSubmit = () => {
    props.onSubmit(selectedAssessment);
  };
  return (
    <>
      <Modal
        show={props.showModal}
        onHide={closeOnBackClick ? handleClose : null}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          style={{ borderBottom: "none" }}
          className="text-center"
          closeButton
        >
          <Modal.Title className="w-100 detailed-popup">
            Select Assessment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="quickDetailPopup">
          <Row>
            <Col xs={6} className="mt-2 ">
              <Card
                className={`mx-lg-4 mx-md-2 text-center qns-popup ${
                  selectedAssessment === 1 ? "selected" : ""
                }`}
                onClick={handleDetailedAssessment}
              >
                <div className="m-2">
                  <img
                    src={_getImage("Assessment_Detailed.svg")}
                    alt="analyst"
                    className="rounded-img assessment-select"
                  />
                  <div className="mt-2">Detailed Assessment</div>
                </div>
              </Card>
            </Col>
            <Col xs={6} className="mt-2 ">
              <Card
                className={`mx-lg-4 mx-md-2 text-center qns-popup ${
                  selectedAssessment === 2 ? "selected" : ""
                }`}
                onClick={handleQuickAssessment}
              >
                <div className="m-2">
                  <img
                    src={_getImage("Assessment_Quick.svg")}
                    alt="analyst"
                    className="rounded-img assessment-select"
                  />
                  <div className="mt-2">Quick Assessment</div>
                </div>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer
          className="d-flex justify-content-center"
          style={{ borderTop: "none" }}
        >
          <SubmitButton
            variant="primary"
            onClick={handleSubmit}
            text="Next"
            className="deatiled-btn"
          >
            Next
          </SubmitButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default QuickDetailedPopup;
