//** Iguroo & GinieHealth User(Otp) for Mobile & Email UI code**//

import React, { useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getUserOtpLoginForm } from "../../FormSchemas";
import { Form, Row, Col, Button, Image, Container } from "react-bootstrap";
import { AuthContext } from "../../hooks/auth.context.provider";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { SubmitButton } from "../../components/submit.button";
import { useDispatch } from "react-redux";
import {
  decrementLoader,
  incrementLoader,
  loader,
} from "../../redux-tk/slices/loader.slice";
import { useNavigate } from "react-router-dom";
import { Heading } from "../../components/heading.component";
import { Link } from "react-router-dom";
import google from "../../assets/images/google.svg";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { FormErrorComponent } from "../../components/form.error.component";
import { AppUrlsEnum, StrConstants, VerificationCodeEnums } from "../../enums";
import { useRef } from "react";
import { _getImage } from "../../common/utils/commonFunctions";
import "./styles.scss";
import { TextLink } from "../../components/text.link.component";

export const UserOtpScreen = () => {
  const usernameRef = useRef();
  const authContext = useContext(AuthContext);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [showResendCode, setShowResendCode] = useState(false);
  const [ginieUrl, setGinieUrl] = useState(false);

  const {
    profileLabels,
    formElementLabels,
    formErrors,
    headingTranslations,
    headerFooterTranslations,
  } = useIgurooTranslations();

  const [passwordShown, setPasswordShown] = useState(false);
  const [loginType, setLoginType] = useState("mobile");

  const togglePassword = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    resetField,
    reset,
    clearErrors,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(getUserOtpLoginForm(formErrors)),
  });
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    console.log(usernameRef);
    const uname = `${StrConstants.signupStr}${data.username}`;
    localStorage.setItem(StrConstants.username, uname);
    try {
      dispatch(incrementLoader());
      console.log("username", data);
      const returnData = await authContext.signinWithOTP(data.username.toLowerCase());
      console.log("login, return data", returnData);
      navigate(AppUrlsEnum.verifyMobile, {
        state: { pageName: "userOTP", session: returnData.Session },
      });
      console.log("made call");
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        setError("apiError", { message: err.message });
        setShowResendCode(true);
      } else if (err.code === "UserNotFoundException") {
        setError("apiError", { message: err.message });
        setShowResendCode(true);
      } else {
        setError("apiError", { message: err.message });
      }
    } finally {
      dispatch(decrementLoader());
    }
  };

  useEffect(() => {
    (async function () {
      setValue("loginType", "mobile");
      console.log(process.env.REACT_APP_IS_SIGNUP, "console165 session");
      if (process.env.REACT_APP_IS_SIGNUP === "false") {
        setGinieUrl(true);
      } else {
        setGinieUrl(false);
      }
    })();
  }, []);

  const resendPasswordClick = async () => {
    try {
      dispatch(loader(true));
      const username = localStorage.getItem(StrConstants.username);
      await authContext.resendConfirmationCode(username.toLowerCase());
      dispatch(incrementLoader());
      navigate(AppUrlsEnum.verifyMobile, {
        state: { pageName: VerificationCodeEnums.signup, session: "" },
      });
    } catch (err) {
      console.log("signinerror", err, "err code", err.code);
      if (err.code === "InvalidParameterException") {
        setShowResendCode(false);
      }
      setError("apiError", { message: err.message });
    } finally {
      dispatch(decrementLoader());
    }
  };

  return (
    <Row className="sign-head ig-auth-class  signin justify-content-center align-items-center mt-4">
      <Col md={6} lg={4} className="p-2 main-container">
        <Form className="signin-top w-100" onSubmit={handleSubmit(onSubmit)}>
          <Heading className="ig-sign-heading">
            {headingTranslations?.signIn}
          </Heading>
          <Container>
            <Form.Group>
              <Controller
                name={`loginType`}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomInputField
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    type={"hidden"}
                  />
                )}
              />
              <Col>
                <Form.Label>
                  {formElementLabels?.placeHolders?.mobileNo}
                </Form.Label>
                <Controller
                  name={`username`}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CustomInputField
                      className={`sign-input ${
                        errors.username ? "ig-error-signin" : ""
                      }`}
                      placeholder={formElementLabels?.placeHolders?.mobileNo}
                      hookOnChange={onChange}
                      onChange={(val) => {}}
                      hookOnBlur={onBlur}
                      hookValue={value}
                      // {...register("username")}
                      //  ref={usernameRef}
                      // defaultValue={"+91"}
                      // maxLength={16}
                      // name={name}
                    />
                  )}
                />
                <FormErrorComponent>
                  {errors.username && errors.username?.message}
                </FormErrorComponent>
              </Col>
            </Form.Group>
            <Row className="mt-1 mb-3">
              {process.env.REACT_APP_IS_SIGNUP === "true" && showResendCode && (
                <TextLink url="" onClick={resendPasswordClick}>
                  {formElementLabels?.resendUserNotConfirmed}
                </TextLink>
              )}
            </Row>
            <Row className="mb-2">
              <FormErrorComponent>
                {errors.apiError && formElementLabels?.otpError}
              </FormErrorComponent>
            </Row>
            <Row>
              {" "}
              <Col className="d-grid mt-2">
                <SubmitButton
                  type="submit"
                  text={headerFooterTranslations?.btnOtpCode}
                  onClick={() => {
                    clearErrors("apiError");
                  }}
                  // disabled={!isDirty || !isValid}
                />
              </Col>
            </Row>
          </Container>
          <Col className="sigin-pwd m-1">
            <Link to={AppUrlsEnum.authSignin}>
              {formElementLabels?.usePassword}
            </Link>
          </Col>
          {/* Google Login UI,currently not in use,Infuture will be implement this code */}
          {/* <Container>
          <Row>
            <Col>
              <hr className="hr ig-hr-tag" />
            </Col>
            <Col xs={"auto"} className="or-tag-name">
              {profileLabels?.or}
            </Col>
            <Col>
              <hr className="hr ig-hr-tag" />
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              <Link to="#">
              <Button className="signin-btn">
                <Image src={google}></Image>
                {formElementLabels?.contiueBtn}
              </Button>
              </Link>
            </Col>
          </Row>
        </Container> */}
          {!ginieUrl ? (
            <Col className="mt-2">
              <div className="signin-link">
                {profileLabels?.signinAccount}{" "}
                <Link to={AppUrlsEnum.authSignup}>
                  {headerFooterTranslations?.signUp}
                </Link>
              </div>
            </Col>
          ) : (
            ""
          )}
        </Form>
      </Col>
    </Row>
  );
};
