//** Iguroo & GinieHealth Dashboard SideBar for Mentee,Mentee,Author,Publisher, **//
//** Mentee,Mentee,Author,Publisher,Supervisor(Recruiter),Admin(Iguroo & GH),Learner,Complaince Logins **//

import React, { useState, useMemo, useContext } from "react";
import bar_chart from "../../assets/images/bar-chart.svg";
import thick_bar_chart from "../../assets/images/thick_bar-chart.svg";
import box from "../../assets/images/box.svg";
import thick_box from "../../assets/images/thick_box.svg";
import users from "../../assets/images/users.svg";
import thick_users from "../../assets/images/thick_users.svg";
import assessment from "../../assets/images/Assessments_Unselect.svg";
import thick_assessment from "../../assets/images/Assessments_Selected.svg";
import self_discovery from "../../assets/images/selfDiscovery.png";
import message from "../../assets/images/message.svg";
import Approvetest from "../../assets/images/approvetest.svg";
import Updatetest from "../../assets/images/updatetest.svg";
import PublisherIcon from "../../assets/images/publisher_icon.png";
import MyAssessment from "../../assets/images/myassessments.svg";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { AppUrlsEnum, ProfileType } from "../../enums";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../hooks/auth.context.provider";
import { useSelector, useDispatch } from "react-redux";
import { showPopupflag } from "../../redux-tk/slices/showPopupflag.slice";
import { PopupUnsaved } from "../CustomInput/popupUnsaved";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import supervisor from "../../assets/images/supervisor.svg";
import { _getImage } from "../../common/utils/commonFunctions";

const Sidebar = (props) => {
  const {
    headerFooterTranslations,
    headingTranslations,
    genieLabsTranslations,
    authorTranslations,
  } = useIgurooTranslations();
  const dispatch = useDispatch();
  const [activeLink, setActiveLink] = useState("Dashboard");
  const [navLinks, setNavLinks] = useState([]);
  const { pathname } = useLocation();
  const popupFlag = useSelector((state) => state.showPopupflag.showPopupflag);
  const [showPopup2, setShowPopup2] = useState(false);
  const [urlFlag, setUrlFlag] = useState("");

  const handleClick = (e) => {
    setActiveLink(e.target.id);
  };

  const handleLeave = () => {
    setShowPopup2(false);
    (async function () {
      dispatch(showPopupflag(false));
    })();
  };

  const handleStay = () => {
    setShowPopup2(false);
    (async function () {
      dispatch(showPopupflag(true));
    })();
  };

  const user = useSelector((state) => state.user.profile);

  const authContext = useContext(AuthContext);

  const userType = useMemo(() => {
    return authContext?.sessionInfo?.role?.toUpperCase();
  }, [authContext.authStatus]);

  const mentorNavLinks = [
    {
      link: AppUrlsEnum.mentorDashboard,
      linkName: "Dashboard",
      img: bar_chart,
      thich_img: thick_bar_chart,
    },
    {
      link: AppUrlsEnum.mentorRequests,
      linkName: "Requests",
      img: box,
      thich_img: thick_box,
    },
    {
      link: AppUrlsEnum.mentorMentees,
      linkName: "My Mentees",
      img: users,
      thich_img: thick_users,
    },
    {
      link: AppUrlsEnum.selfDiscoveryMentor,
      linkName: headerFooterTranslations?.assessmentsMsg,
      img: self_discovery,
      thich_img: self_discovery,
    },
    {
      link: AppUrlsEnum.coursesUrlMentor,
      linkName: "Courses",
      img: users,
      thich_img: thick_users,
    },
    {
      link: AppUrlsEnum.myCoursesMentor,
      linkName: "My Courses",
      img: users,
      thich_img: thick_users,
    },
    // {
    //     link: (AppUrlsEnum.mentorMeassages),
    //     linkName: 'Messages',
    //     img: message,
    //     thich_img : message
    // },
  ];

  const menteeNavLinks = [
    {
      link: AppUrlsEnum.menteeDashboard,
      linkName: headerFooterTranslations?.dashboard,
      img: bar_chart,
      thich_img: thick_bar_chart,
    },
    {
      link: AppUrlsEnum.menteeRequests,
      linkName: headerFooterTranslations?.request,
      img: box,
      thich_img: thick_box,
    },
    {
      link: AppUrlsEnum.menteeMentors,
      linkName: headerFooterTranslations?.myMentor,
      img: users,
      thich_img: thick_users,
    },
    {
      link: AppUrlsEnum.findMentor,
      linkName: headingTranslations?.findMentor,
      img: users,
      thich_img: thick_users,
    },

    {
      link: AppUrlsEnum.selfDiscovery,
      linkName: headerFooterTranslations?.assessmentsMsg,
      img: self_discovery,
      thich_img: self_discovery,
    },
    {
      link: AppUrlsEnum.coursesUrl,
      linkName: "Courses",
      img: users,
      thich_img: thick_users,
    },
    {
      link: AppUrlsEnum.myCoursesMentee,
      linkName: "My Courses",
      img: users,
      thich_img: thick_users,
    },
  ];

  const AdminNavLinks = [
    {
      link: AppUrlsEnum.adminDashboard,
      linkName: "Dashboard",
      img: bar_chart,
      thich_img: thick_bar_chart,
    },
    {
      link: AppUrlsEnum.adminUsers,
      linkName: "Approval Management",
      img: users,
      thich_img: thick_users,
    },
    {
      link: AppUrlsEnum.adminUserManagement,
      linkName: "User Management",
      img: users,
      thich_img: thick_users,
    },
    {
      link: AppUrlsEnum.adminSiteAdministration,
      linkName: "Site Administration",
      img: box,
      thich_img: thick_box,
    },
    {
      link: AppUrlsEnum.adminProfilePublicPrivate,
      linkName: "User Profile Public/Private",
      img: assessment,
      thich_img: thick_assessment,
    },

    {
      link: AppUrlsEnum.recommendations,
      linkName: "Recommendations",
      img: assessment,
      thich_img: thick_assessment,
    },
    {
      link: AppUrlsEnum.approveTestimonial,
      linkName: "Approve Testimonials",
      img: Approvetest,
      thich_img: thick_assessment,
    },
    {
      link: AppUrlsEnum.updateTestimonial,
      linkName: "Update Testmonial",
      img: Updatetest,
      thich_img: thick_assessment,
    },
    {
      link: AppUrlsEnum.assessmentReportAdmin,
      linkName: "Assessment Report",
      img: Updatetest,
      thich_img: thick_assessment,
    },
    {
      link: AppUrlsEnum.coursesUrladmin,
      linkName: "Courses",
      img: users,
      thich_img: thick_users,
    },
  ];

  const GLEARNER = [
    {
      link: AppUrlsEnum.learnermyassessment,
      linkName: "My Assessments",
      img: MyAssessment,
      thich_img: MyAssessment,
    },
  ];

  const GLAUTHOR = [
    {
      link: AppUrlsEnum.authorUserPage,
      linkName: authorTranslations?.author,
      img: users,
      thich_img: thick_users,
    },
  ];

  const GLPUBLISHER = [
    {
      link: AppUrlsEnum.publisherUser,
      linkName: headerFooterTranslations?.publisher,
      img: PublisherIcon,
      thich_img: PublisherIcon,
    },
  ];

  const GLSUPERVISOR = [
    {
      link: AppUrlsEnum.superVisorUser,
      linkName: genieLabsTranslations?.supervisor,
      img: supervisor,
      thich_img: supervisor,
    },
    {
      link: AppUrlsEnum.superVisorGinieUser,
      linkName: "User Management",
      img: users,
      thich_img: users,
    },
  ];

  const GinieADMINLinks = [
    {
      link: AppUrlsEnum.superVisor,
      linkName: genieLabsTranslations?.supervisor,
      img: supervisor,
      thich_img: supervisor,
    },
    {
      link: AppUrlsEnum.Users,
      linkName: "User Management",
      img: users,
      thich_img: users,
    },
  ];

  const GLCOMPLIANCELinks = [
    {
      link: AppUrlsEnum.compliancePublisher,
      linkName: headerFooterTranslations?.publisher,
      img: PublisherIcon,
      thich_img: PublisherIcon,
    },
    {
      link: AppUrlsEnum.complianceSuperVisor,
      linkName: genieLabsTranslations?.supervisor,
      img: supervisor,
      thich_img: supervisor,
    },
    {
      link: AppUrlsEnum.complianceGinieUsers,
      linkName: "User Management",
      img: users,
      thich_img: users,
    },
  ];

  useEffect(() => {
    if (userType === ProfileType.mentor) {
      setNavLinks(mentorNavLinks);
    } else if (userType === ProfileType.mentee) {
      setNavLinks(menteeNavLinks);
    } else if (userType === ProfileType.admin) {
      if (process.env.REACT_APP_IS_MENU === "false") {
        setNavLinks(GinieADMINLinks);
      } else {
        setNavLinks(AdminNavLinks);
      }
    }
    // else if (userType === ProfileType.admin) {
    //   setNavLinks(GinieADMINLinks);
    // }
    else if (userType === ProfileType.learner) {
      setNavLinks(GLEARNER);
    } else if (userType === ProfileType.author) {
      setNavLinks(GLAUTHOR);
    } else if (userType === ProfileType.publisher) {
      setNavLinks(GLPUBLISHER);
    } else if (userType === ProfileType.recruiter) {
      setNavLinks(GLSUPERVISOR);
    } else if (userType === ProfileType.compliance) {
      setNavLinks(GLCOMPLIANCELinks);
    }
  }, [userType]);

  useEffect(() => {
    if (user.type) {
      if (user.type === ProfileType.mentor) {
        setNavLinks(mentorNavLinks);
      } else if (user.type === ProfileType.mentee) {
        setNavLinks(menteeNavLinks);
      } else if (user.type === ProfileType.admin) {
        if (process.env.REACT_APP_IS_MENU === "false") {
          setNavLinks(GinieADMINLinks);
        } else {
          setNavLinks(AdminNavLinks);
        }
      } else if (userType === ProfileType.learner) {
        setNavLinks(GLEARNER);
      } else if (userType === ProfileType.author) {
        setNavLinks(GLAUTHOR);
      } else if (userType === ProfileType.publisher) {
        setNavLinks(GLPUBLISHER);
      } else if (userType === ProfileType.recruiter) {
        setNavLinks(GLSUPERVISOR);
      } else if (userType === ProfileType.compliance) {
        setNavLinks(GLCOMPLIANCELinks);
      }
    }
  }, [user]);

  console.log(userType, "sidebar-user-type");

  useEffect(() => {
    let paths = [];
    if (userType === ProfileType.mentor) {
      paths = [...mentorNavLinks];
    } else if (userType === ProfileType.mentee) {
      paths = [...menteeNavLinks];
    } else if (userType === ProfileType.admin) {
      if (process.env.REACT_APP_IS_MENU === "false") {
        paths = [...GinieADMINLinks];
      } else {
        paths = [...AdminNavLinks];
      }
    } else if (userType === ProfileType.learner) {
      setNavLinks(GLEARNER);
      paths = [...GLEARNER];
    } else if (userType === ProfileType.author) {
      paths = [...GLAUTHOR];
    } else if (userType === ProfileType.publisher) {
      paths = [...GLPUBLISHER];
    } else if (userType === ProfileType.recruiter) {
      paths = [...GLSUPERVISOR];
    } else if (userType === ProfileType.compliance) {
      paths = [...GLCOMPLIANCELinks];
    }
    console.log("console 372 ", pathname, paths);
    const resultPath = paths.filter((path) => path.link === pathname);
    setActiveLink(resultPath[0]?.link);
  }, [pathname]);

  useEffect(() => {
    if (pathname) {
      console.log("console 372 ", pathname);
      setActiveLink(pathname);
    }
  }, [authContext.authStatus]);

  return (
    <Container
      fluid
      className="d-none d-md-block p-0"
      style={{ height: "100vh" }}
    >
      <Container className="py-3 px-1">
        <a
          href={
            userType === "MENTOR"
              ? AppUrlsEnum.mentorDashboard
              : userType === ProfileType.author ||
                userType === ProfileType.publisher ||
                userType === ProfileType.learner ||
                userType === ProfileType.recruiter ||
                userType === ProfileType.compliance ||
                userType === ProfileType.admin
              ? AppUrlsEnum.homepage
              : AppUrlsEnum.menteeDashboard
          }
        >
          <Image
            height="40px"
            width="180px"
            src={_getImage(process.env.REACT_APP_SITE_LOGO)}
          />
        </a>
      </Container>
      <Container className="side-nav p-0 mt-2">
        <Nav defaultActiveKey="/home" className="flex-column">
          {console.log("LOG NAVVVV", activeLink)}
          {navLinks.map((navLink, i) => (
            <>
              <Link
                onClick={(e) => {
                  localStorage.setItem("routeLink", navLink.link);
                  if (popupFlag) {
                    setShowPopup2(true);
                    setUrlFlag(navLink.link);
                  } else {
                    handleClick(e);
                  }
                  console.log("LOG NAVVVV", navLink.linkName, activeLink);
                }}
                id={navLink.linkName}
                className={activeLink === navLink.link ? "active" : ""}
                to={popupFlag ? "#" : navLink.link}
                state={{
                  langFlag: navLink.link === activeLink ? true : false,
                }}
              >
                {console.log(
                  "activeLink === navLink.link LOG NAVVVV",
                  activeLink,
                  navLink.link,
                  activeLink === navLink.link
                )}
                <Image
                  className="side-nav-icon"
                  src={
                    activeLink === navLink.link
                      ? navLink.thich_img
                      : navLink.img
                  }
                />
                {navLink.linkName}
                {navLink.linkName === "Messages" ? (
                  <Badge className="message-badge mt-1" pill bg="danger">
                    99+
                  </Badge>
                ) : (
                  ""
                )}
              </Link>
            </>
          ))}
        </Nav>
      </Container>
      <PopupUnsaved
        showPopup={showPopup2}
        type="popup"
        url={urlFlag}
        gotIt={handleLeave}
        gotIt2={handleStay}
      />
    </Container>
  );
};
export default Sidebar;
