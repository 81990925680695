//** Mentor Dashboard Page **//

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, } from "react-bootstrap";
import CardList from "../../../components/CardList";
import UserCard from "../../../components/UserCard";
import ProfileInfo from "../../../components/ProfileInfo";
import RequestCard from "../../../components/RequestCard";
import SessionCard from "../../../components/SessionCard";
import "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserAsyncThunk,
  getUserAvailabilityAsyncThunk,
} from "../../../redux-tk/slices/user.slice";
import {
  _getImage,
  _getCurrencySymbol,
} from "../../../common/utils/commonFunctions";
import moment from "moment";
import ShowMore from "../../../components/ShowMore";
import { useContext } from "react";
import { AuthContext } from "../../../hooks/auth.context.provider";
import { useGetMentorsMentees } from "../../../hooks/use.get.mentor.mentee";
import useRequests from "../../../hooks/useRequests.js";
import { useIgurooTranslations } from "../../../hooks/use.iguroo.translation";
import Rupee from "../../../assets/images/rupee.svg";
import CustomCalendar from "../../../components/CustomCalendar";
import useGetCalendarSessions from "../../../hooks/useGetCalendarSessions";
import { getAllSessionsInDates } from "../../../redux-tk/slices/session.slice";
import { AppUrlsEnum, CardTypeEnums, ProfileType } from "../../../enums";
import { rateDataValue } from "../../../redux-tk/slices/mentor.onboarding.data";
import { GetTotalSessions } from "../../../services/axios/Dasboard";
import { getRating } from "../../../services/axios/session";

const MentorDashboard = () => {
  const { dashboardTranslations } = useIgurooTranslations();
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const profile = useSelector((state) => {
    return state.user.profile;
  });
  const rates = useSelector((state) => {
    //console.log(rateDataValue(state),'callStart')
    return rateDataValue(state);
  });
  const userAvailability = useSelector((state) => {
    // console.log(state,'callstart')
    return state.user.userAvailability;
  });
  const sessionsBetweenDates = useSelector((state) =>
    getAllSessionsInDates(state)
  );
  const userAvailabilityLoaded = useSelector(
    (state) => state.user.userAvailabilityLoaded
  );
  useEffect(() => {
    (async function () {
      if (!authContext?.sessionInfo?.sub) {
        return;
      }
      dispatch(getUserAsyncThunk({ userId: authContext.sessionInfo.sub }));
    })();
  }, []);
  const {
    currentSession,
    onDateChange,
    onMonthChange,
    isHighlighted,
    isGreyed,
  } = useGetCalendarSessions({
    isByUser: false,
    userId: authContext.sessionInfo.sub,
  });
  const { areaOfExpertise = [], bio, userId } = profile;
  const { users, totalRequestsCount } = useRequests({
    id: userId,
    isMentor: true,
  });
  const [rating, setRating] = useState("0");
  const [sessionsHeld, setSessionsHeld] = useState("0");
  // console.log(users, "rrr-data");

  useEffect(() => {
    (async function () {
      if (userId) {
        dispatch(getUserAvailabilityAsyncThunk({ userId }));
      }
      getRating(userId)
        .then((res) => {
          console.log("LOG Rating", res?.data?.response);
          setRating(res?.data?.response);
        })
        .catch((err) => console.log(err));

      GetTotalSessions(userId).then((resp) => {
        console.log("LOG Sessions", resp);
        setSessionsHeld(resp);
      });
    })();
  }, [userId, dispatch]);

  const mentorsMentees = useGetMentorsMentees({
    getMentors: true,
    userId: authContext.sessionInfo.sub,
    limit: 2,
  });

  return (
    <Container fluid className="mentor-dashboard g-0">
      <div>
        <Col lg={12} className="user-info mb-4">
          {userId ? (
            <Container fluid>
              <Row>
                <Col lg="4">
                  <ProfileInfo
                    {...profile}
                    pageNavigate={true}
                    rating={rating}
                    sessionsHeld={sessionsHeld}
                  />
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <div className="description mb-4">
                        <div className="skills mb-3 lh-lg">
                          {areaOfExpertise.map(({ value }) => (
                            <span
                              key={value}
                              className="badge me-2 rounded-pill bg-warning"
                            >
                              {value}
                            </span>
                          ))}
                        </div>
                        <ShowMore data={bio} />
                      </div>
                    </Col>
                    <Row>
                      <Col md={6} sm={12}>
                        <div>
                          <h2 className="ig-heading mb-3">
                            <img
                              className="me-2"
                              height={20}
                              src={_getImage("clock.svg")}
                              alt="availability"
                            />
                            {dashboardTranslations?.myAvailability}
                          </h2>
                          <ul className="availability">
                            {userAvailabilityLoaded
                              ? userAvailability.map(
                                  ({ from, to, availability, id }) => (
                                    <li key={id}>
                                      <div className="week">{availability}</div>
                                      <div className="time">
                                        {moment(from).format("hh:mm A")} -{" "}
                                        {moment(to).format("hh:mm A")}
                                      </div>
                                    </li>
                                  )
                                )
                              : "Loading ..."}
                            {userAvailabilityLoaded === "error" &&
                              "Server error!"}
                          </ul>
                        </div>
                      </Col>
                      <Col md={6} sm={12}>
                        <div>
                          <h2 className="ig-heading mb-3">
                            <img
                              className="me-2"
                              height={20}
                              width={20}
                              src={Rupee}
                              alt="availability"
                            />
                            {dashboardTranslations?.myRates}
                          </h2>
                          <div className="rates">
                            {profile.rates.map(
                              ({ rate, curreny, timeFramePerSession }) => (
                                <span
                                  key={rate + curreny}
                                  className="badge rounded-pill bg-grey mb-2"
                                >
                                  {timeFramePerSession} -{" "}
                                  {_getCurrencySymbol(curreny)} {rate}
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
            </Container>
          ) : (
            "Loading..."
          )}
        </Col>
        <Col className="mx-4">
          <Row>
            <Col sm={12} md={6} lg={4} className="mb-4">
              <CardList
                title="Requests"
                badge={totalRequestsCount}
                data={users}
                type={profile?.type}
                view={RequestCard}
              />
            </Col>
            <Col sm={12} md={6} lg={4} className="mb-4">
              <CardList
                title="My Mentees"
                badge={mentorsMentees?.length}
                footerProps={{
                  text: "All Mentees",
                  icon: "",
                  url:
                    profile.type === ProfileType.mentor
                      ? AppUrlsEnum.mentorMentees
                      : AppUrlsEnum.menteeMentors,
                }}
                data={mentorsMentees}
                view={UserCard}
              />
            </Col>
            <Col sm={12} md={6} lg={4} className="mb-4">
              <CustomCalendar
                onDateChange={onDateChange}
                sessionDates={sessionsBetweenDates}
                onDateMonthChange={onMonthChange}
                isHighlighted={isHighlighted}
                isGreyed={isGreyed}
              />
              <CardList
                title="Sessions"
                headerIcon="headphone.svg"
                listTitle={currentSession.listTitle}
                listTitleClass={"date"}
                data={currentSession.sessions}
                className="no-borders"
                view={SessionCard}
                cardType={CardTypeEnums.session}
              />
            </Col>
          </Row>
        </Col>
      </div>
    </Container>
  );
};

export default MentorDashboard;
MentorDashboard.propTypes = {
  title: PropTypes.string,
};
