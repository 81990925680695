//** BulkUpload Users Data Details for GinieHealth Ui code of UserManagement**//

import { useState, useRef } from "react";
import { Button, Modal, Form, Card, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { bulkUploadCSV } from "../../services/axios/Admin/admin";
import { CustomCheckButton } from "../../components/CustomInput/custom.switch";
import Toaster from "../../components/toaster";
import { bulkIgurooUploadCSV } from "../../services/axios/UserManagement";
import * as AWS from "aws-sdk";

export const BulkUpload = (props) => {
  const { show, onHide, updateList, bulkUploadgenie } = props;
  const [loading, setLoading] = useState(false);
  const [consent, setConsent] = useState(false);
  const [fileBU, setFileBU] = useState();
  const [errorModalShow, setErrorModalShow] = useState(false);
  const ref = useRef();

 
  const fileHandler = (e) => {
    const file = e.target.files[0];
    const extension = file.name.split(".").pop();
    if (extension !== "csv") {
      alert("Only csv file is allowed.");
      ref.current.value = "";
      return;
    }
    setFileBU(file);
  };
  const validateData = (file) => {
    const reader = new FileReader();
    reader.readAsText(file);
  
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        const csv = reader.result;
        const rows = csv.split("\n");

        for (let i = 1; i < rows.length; i++) {
          const rowData = rows[i].split(",");
          if (rowData.length < 4) {
            continue;
          }

          const mobileNumber = rowData[3]?.trim();
          const email = rowData[4]?.trim();

          if (!validateMobileNumber(mobileNumber)) {
            setErrorModalShow(true);
            reject();
            return;
          }

          
          if (!validateEmail(email)) {
            setErrorModalShow(true);
            reject();
            return;
          }
        }

        resolve();
      };

      reader.onerror = () => {
        reject("Error reading file.");
      };
    });
  };

  const validateMobileNumber = (mobileNumber) => {
    const re = /^\+(91|\d{1})\d{10}$/;
    return re.test(mobileNumber);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const BulkUploadSubmit = () => {
    setLoading(true);
    validateData(fileBU)
      .then(() => {
        const uploadFunction = bulkUploadgenie
          ? bulkUploadCSV
          : "" ;
        uploadFunction(fileBU)
          .then((res) => {
            setFileBU();
            updateList();
            props.onHide();
            Toaster(toast.success, "User updated successfully");
            console.log(res?.data?.feedbackResponse, "bulkUploadCSV");
            props.feedbackResponse(res?.data?.feedbackResponse);
            setLoading(false);
            updateList();
          })
          .catch((err) => {
            Toaster(toast.error, "Something went wrong");
            setLoading(false);
          });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const generatePresignedUrl = () => {
    (async function () {
      try {
        AWS.config.update({
          accessKeyId: process.env.AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
          region: "us-east-1",
          signatureVersion: "v4",
        });  

        const s3 = new AWS.S3();
        const myBucket = process.env.REACT_APP_S3_BUCKET;
        const myKey = "Bulk_User_List_Sample.csv";
        const signedUrlExpireSeconds = 60 * 60;
        const url = await s3.getSignedUrlPromise("getObject", {
          Bucket: myBucket,
          Key: myKey,
          Expires: signedUrlExpireSeconds,
        });
        console.log("LOG url of PDF --------------------------------", url);
        window.open(url, "csv");
      } catch (error) {
        console.log("LOG generatePresignedUrl error ", error);
      }
    })();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        className="pop-up"
        size="lg"
      >
        <Modal.Header closeButton className="text-center">
          <Modal.Title className="w-100">Import Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Card>
              <Card.Header>
                <span className="spanHeader">File upload</span>
                <Button onClick={generatePresignedUrl}>
                  Download Sample File
                </Button>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Uploaded file must be an excel bulk load template. See link
                  above for instructions and to download sample files.
                </Card.Text>
                <Form.Control
                  type="file"
                  accept=".csv"
                  onChange={fileHandler}
                  ref={ref}
                />
                <CustomCheckButton
                  hookOnChange={() => setConsent(!consent)}
                  hookOnBlur={() => {}}
                  hookValue={consent}
                  name={"consent"}
                  type="checkbox"
                  label="Only validate the file(No changes will be made)"
                  defaultChecked={false}
                  checked={consent}
                  className="checkbox mt-3"
                />
              </Card.Body>
            </Card>
            <div className="d-flex justify-content-center">
              <Button
                variant="primary"
                className="my-3"
                onClick={BulkUploadSubmit}
                disabled={!fileBU}
              >
                Upload
              </Button>
            </div>
          </Form>
        </Modal.Body>
        {loading && (
          <div className="spinner">
            <Spinner animation="border" />
          </div>
        )}
      </Modal>\  <Modal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
        backdrop="static"
        className="pop-up"
      >
        <Modal.Header closeButton className="text-center">
        <Modal.Title className="w-100">Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Mobile or Email format is incorrect. Please check the input.</p>
        </Modal.Body>
        <Modal.Footer>
        <div className="d-flex justify-content-center">
        <Button
                variant="primary"
                onClick={() => setErrorModalShow(false)}
              >
            Close
          </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};