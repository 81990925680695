//** Iguroo Mentor Profile -> Like and Dislike Icon for the Feedback,Currently not using this component,will implement in future **//

import React, { Fragment } from "react";
import "./styles.scss";

function ThumbsRadioBtn({ className = "" }) {
  return (
    <Fragment>
      <div className={"thumbs-radio " + className}>
        <input type="radio" id={"like"} name={"feedback"} value={true} />
        <label for={"like"} className="up"></label>
      </div>
      <div className={"thumbs-radio " + className}>
        <input type="radio" id={"dislike"} name={"feedback"} value={false} />
        <label for={"dislike"} className="down"></label>
      </div>
    </Fragment>
  );
}

export default ThumbsRadioBtn;