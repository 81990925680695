//** Iguroo Get Mentor & Mentee Hooks **//

import { useEffect, useState } from "react";
import { GetListOfUserProfiles } from "../services/axios/MentorOnbaording";
import { getMenteesMentors } from "../services/menteeServices";
import { getMentorsMentees } from "../services/mentorServices";

export const useGetMentorsMentees = ({ getMentors, userId, limit }) => {
  let active = false;
  const [mentorsMentees, setMentorsMentees] = useState([]);
  useEffect(() => {
    if (!active) {
      (async function () {
        let data = [];
        if (getMentors) {
          data = await getMentorsMentees(userId);
        } else {
          data = await getMenteesMentors(userId);
        }
        const finalData = [];
        //console.log("got data", data);
        const userIdsToFetch = [];
        data?.forEach((val) => {
          if (
            userIdsToFetch.indexOf(getMentors ? val.userId : val.mentorId) ===
            -1
          ) {
            userIdsToFetch.push(getMentors ? val.userId : val.mentorId);
          }
        });
        //console.log("got data ids",userIdsToFetch);
        if (userIdsToFetch.length > 0) {
          const userData = await GetListOfUserProfiles(userIdsToFetch);
          //console.log("got data axios",userData);
          userData?.data?.forEach((val) => {
            finalData.push({
              name: val.firstName + " " + val.lastName,
              subText: val.profileTitle,
              picture: val.profilePic,
              userId: val.userId,
            });
          });
          //console.log("got data final data",finalData);
          setMentorsMentees([...finalData]);
        }
      })();
    }
    return () => {
      active = true;
    };
  }, []);
  return mentorsMentees;
};
