//** GinieHealth Publisher,CreateNewAssessment of Certification Assessments Completed UI code**//

import { Button, Card, Row, Col, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import CustomAnswerOptions from "../assessment/components/customradioOption";
import { CustomInputField } from "../CustomInput/customInputField";
import { CustomSelect } from "../CustomInput/custom.select";
import { getAllMonths, getListOfYears } from "../../helpers/date.time.helpers";
import { certificationValidation } from "../../FormSchemas/publisher.form";
import { AppUrlsEnum } from "../../enums/app.url.enums";
import { FormErrorComponent } from "../form.error.component";
import {
  AssessmentDetailsDataSelector,
  addDataToAssessmentDetails,
  createAssessmentAsyncThunk,
  tempCreateAssessmentSelector,
} from "../../redux-tk/slices/assessment.slice";
import { getAssessmentObject } from "../../common/DTOS/assessment.dtos";
import { useDispatch, useSelector } from "react-redux";
import AssessmentSideBar from "./AssessmentSideBar";
import "./styles.css";
import { useEffect } from "react";
import { useAssessmentDetailFetch } from "../../hooks/useAssessmentDetailFetch";

const options = [
  { value: "Is Practice Test" },
  { value: "Is Certification Assessment" },
];

const Certification = () => {
  const { formElementLabels, assessmentsTranslations, formErrors } =
    useIgurooTranslations();
  const tempCreateDataValue = useSelector((state) =>
    tempCreateAssessmentSelector(state)
  );
  const {
    control,
    handleSubmit,
    getValues,
    watch,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      ...tempCreateDataValue.apiData,
      certification_type: tempCreateDataValue.apiData?.certification_type
        ? tempCreateDataValue.apiData?.certification_type
        : "Is Practice Test",
      validity_year: "",
    },
    resolver: yupResolver(certificationValidation(formErrors)),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const assessmentDetailsData = useSelector((state) =>
    AssessmentDetailsDataSelector(state)
  );
  const params = useAssessmentDetailFetch({
    assessmentId: searchParams?.get("assessmentId"),
    versionId: searchParams?.get("versionId"),
  });
  const submit = async (data) => {
    let finalData = { ...assessmentDetailsData };
    const ids = {
      versionId: searchParams.get("versionId"),
      assessmentId: searchParams.get("assessmentId"),
    };
    try {
      const assessmentObj = getAssessmentObject(data);
      finalData = { ...finalData, ...assessmentObj };
      const res = await dispatch(createAssessmentAsyncThunk(finalData));
      dispatch(addDataToAssessmentDetails(finalData));
      if (ids.assessmentId && ids.versionId) {
        if (res?.payload) {
          navigate(
            `${AppUrlsEnum.author}?versionId=${ids.versionId}&assessmentId=${ids.assessmentId}`
          );
        }
      } else {
        if (res?.payload) {
          navigate(AppUrlsEnum.author);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const assessment = watch("certification_type");
  useEffect(() => {
    const year = parseInt(tempCreateDataValue.apiData.validity_year);
    if (year) {
      setValue("validity_year", year);
    }
  }, [tempCreateDataValue.apiData.validity_year]);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (tempCreateDataValue?.apiData?.assessment_id) {
      reset({ ...tempCreateDataValue?.apiData });
      setValue(
        "certification_type",
        tempCreateDataValue.apiData?.certification_type
          ? tempCreateDataValue.apiData?.certification_type
          : "Is Practice Test"
      );
    }
  }, [tempCreateDataValue?.apiData?.assessment_id]);

  return (
    <div className="p-2">
      <h4 className="text-center p-2">
        {assessmentsTranslations?.certification}
      </h4>
      <Row className="d-flex justify-content-center">
        <Col md={6} className="">
          <Card className="p-4 border-0 shadow">
            <Form onSubmit={handleSubmit(submit)}>
              <p className="subHeader mb-1">Certification</p>
              <Controller
                name={"certification_type"}
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <CustomAnswerOptions
                    type={"radio"}
                    options={options}
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    name={name}
                  />
                )}
              />
              <FormErrorComponent>
                {errors.certification_type &&
                  errors.certification_type?.message}
              </FormErrorComponent>
              {getValues("certification_type") ===
                "Is Certification Assessment" && (
                <>
                  <p className="subHeader mt-3 mb-1">
                    Certification Template URL
                  </p>
                  <div className="d-flex align-items-center">
                    <Controller
                      name={`certification_url`}
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomInputField
                          hookOnBlur={onBlur}
                          hookValue={value}
                          hookOnChange={onChange}
                        />
                      )}
                    />
                    <Button
                      className="copyBtn ms-1"
                      onClick={() =>
                        navigator.clipboard.writeText(
                          getValues("certification_url")
                        )
                      }
                    >
                      Copy
                    </Button>
                  </div>
                  {errors.certification_url && (
                    <FormErrorComponent>
                      {errors.certification_url?.message}
                    </FormErrorComponent>
                  )}
                  <p className="subHeader mb-1 mt-3">Certification Validity</p>
                  <div className="d-flex">
                    <div>
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomSelect
                            className="dropdowns"
                            opLabel="value"
                            opValue="value"
                            items={getAllMonths("short")}
                            onReactHookFormBlur={onBlur}
                            onReactHookFormChange={onChange}
                            value={value}
                            isLoading={false}
                            placeholder={"Month"}
                          />
                        )}
                        name="validity_month"
                      />
                      <FormErrorComponent>
                        {errors.validity_month && errors.validity_month.message}
                      </FormErrorComponent>
                    </div>
                    <div>
                      <Controller
                        control={control}
                        name="validity_year"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomSelect
                            className="ms-3 dropdowns"
                            opLabel="value"
                            opValue="value"
                            items={getListOfYears(50)}
                            onReactHookFormBlur={onBlur}
                            onReactHookFormChange={onChange}
                            value={value}
                            isLoading={false}
                            placeholder={"Year"}
                          />
                        )}
                      />
                      <FormErrorComponent>
                        {errors.validity_year && errors.validity_year?.message}
                      </FormErrorComponent>
                    </div>
                  </div>
                </>
              )}
              <div className="d-flex justify-content-end">
                <Button type="submit" className="nextBtn">
                  {formElementLabels?.nextBtn}
                </Button>
              </div>
            </Form>
          </Card>
        </Col>
        <AssessmentSideBar
          data={assessmentsTranslations?.assessmentProgressView}
          stage={5}
          title={assessmentsTranslations?.assessmentDetails}
          btnValue={formElementLabels?.publishBtn}
          currentTab={
            assessmentsTranslations?.assessmentProgressView?.certification
          }
        />
      </Row>
    </div>
  );
};

export default Certification;
