//** Iguroo & GinieHealth Common Popup Component,can re-use any required field **//
//** if it is reusable can modfiy the text as our convinient **//

import React, { useEffect } from "react";
import { Button, Image, Modal, Col } from "react-bootstrap";
import { _getImage } from "../../common/utils/commonFunctions";
import { useState } from "react";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { useNavigate } from "react-router-dom";
import { AppUrlsEnum } from "../../enums";

export function Popup(props) {
  const {
    showPopup,
    closeOnBackClick,
    modalBody,
    url,
    gotIt,
    onHide,
    title,
    submitText,
    homeLink,
    className,
    svgImg,
    hideSVG,
    infoText,
  } = props;
  const navigate = useNavigate();
  const { mentorOnboardingLabels } = useIgurooTranslations();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    // if (gotIt) {
    //   gotIt();
    // }
    closeOnBackClick();
  };

  useEffect(() => {
    setShow(showPopup);
  }, [showPopup]);

  const handleSubmit = () => {
    const Id = localStorage.getItem("attemptId");
    console.log(Id, "id attempt");
    setShow(false);
    if (gotIt) {
      gotIt();
    }
    if (onHide) {
      onHide();
    }
    if (url?.length > 1) {
      navigate(url, {
        state: {
          attemptId: Id ? Id : null,
        },
      });
    }
  };

  return (
    <>
      <Modal
        className={`completed-popup ${className}`}
        show={show}
        onHide={closeOnBackClick ? handleClose : null}
        backdrop="static"
        keyboard={false}
      >
        {infoText ? (
          <Modal.Header className="text-center p-1" closeButton>
            <Modal.Title className="w-100">
              {title ? title : mentorOnboardingLabels.done}
            </Modal.Title>
          </Modal.Header>
        ) : (
          <Modal.Header className="text-center">
            <Modal.Title className="w-100">
              {title ? title : mentorOnboardingLabels.done}
            </Modal.Title>
          </Modal.Header>
        )}

        <Modal.Body>
          {hideSVG ? (
            <></>
          ) : (
            <div className="text-center mt-4">
              <Image
                className="d-inline-block"
                src={_getImage(svgImg ? svgImg : "green_completed.svg")}
              />
            </div>
          )}
          <div className="popup-body mt-4 text-center mb-4">
            {modalBody ? modalBody : mentorOnboardingLabels?.onboardingSuccess}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSubmit} required={true}>
            {submitText ? submitText : mentorOnboardingLabels.popupDone}
          </Button>
          {process.env.REACT_APP_IS_SIGNUP === "true" && homeLink ? (
            <Col className="d-grid sigin-pwd text-center mt-3 justify-content-center">
              <a href={AppUrlsEnum.landingPage}>{homeLink}</a>
            </Col>
          ) : (
            ""
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
