//** Iguroo SWOT Assessment ->Swot SingleItem Component,Can re-use anywhere in required Field**//
//** Currently not using this code,will be implemented in future **//

import React, { useMemo } from "react";
import { useIgurooTranslations } from "../../hooks";
import { Controller } from "react-hook-form";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FormErrorComponent } from "../form.error.component";
import { CustomDeleteBtn } from "../CustomInput/customDeleteBtn";
import { CustomInputField } from "../CustomInput/customInputField";
import { SwotTypesEnum } from "../../enums";

export function SwotSingleItem({
  itemIndex,
  hookControl,
  removeFunction,
  errors,
  swotName,
  swotType,
  swotFieldName,
  isPlan,
}) {
  const { formElementLabels } = useIgurooTranslations();
  //console.log("errlist", errors);
  const removeRow = () => {
    removeFunction(itemIndex);
  };
  const swotLabelName = useMemo(() => {
    return `${swotName} ${itemIndex + 1}:`;
  }, [swotName, itemIndex]);
  const inputPlaceholder = useMemo(() => {
    let placeHolder = "";
    switch (swotType) {
      case SwotTypesEnum.opportunities:
        placeHolder =
          formElementLabels?.placeHolders?.swotTypes[
            `opportunities${isPlan ? "Plan" : ""}`
          ];
        break;
      case SwotTypesEnum.strength:
        placeHolder =
          formElementLabels?.placeHolders?.swotTypes[
            `strength${isPlan ? "Plan" : ""}`
          ];
        break;
      case SwotTypesEnum.threats:
        placeHolder =
          formElementLabels?.placeHolders?.swotTypes[
            `threats${isPlan ? "Plan" : ""}`
          ];
        break;
      case SwotTypesEnum.weakness:
        placeHolder =
          formElementLabels?.placeHolders?.swotTypes[
            `weakness${isPlan ? "Plan" : ""}`
          ];
        break;
      default:
        break;
    }
    return placeHolder;
  }, [swotType, formElementLabels]);
  // console.debug(
  //   "swotfilename",
  //   errors,
  //   swotFieldName,
  //   errors[`${swotFieldName}`]
  // );
  return (
    <>
      <Row className="mt-3">
        <Col xs={11}>
          <Controller
            name={`${swotFieldName}.${itemIndex}.id`}
            control={hookControl}
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="hidden"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              ></Form.Control>
            )}
          />
          <Form.Group>
            <InputGroup>
              <InputGroup.Text id="basic-addon3">
                {swotLabelName}
              </InputGroup.Text>
              <Controller
                control={hookControl}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomInputField
                    placeholder={inputPlaceholder}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    hookOnChange={(event) => {
                      //handleChange(event);
                      onChange(event);
                    }}
                    maxLength={500}
                    className={"swot-input"}
                  />
                )}
                name={`${swotFieldName}.${itemIndex}.data`}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col
          md="1"
          sm="1"
          className="d-flex align-items-center justify-content-center"
          xs={1}
        >
          {itemIndex != 0 && (
            <CustomDeleteBtn onClick={removeRow} addMt={false} />
          )}
        </Col>
      </Row>
      <Row>
        <FormErrorComponent>
          {errors[`${swotFieldName}`]?.length > 0 &&
            errors[`${swotFieldName}`][itemIndex]?.data &&
            errors[`${swotFieldName}`][itemIndex].data?.message}
        </FormErrorComponent>
      </Row>
    </>
  );
}
