//** GinieHealth Learner Login,Question Header UI component**//

import React, { useContext, useEffect, useState, useRef } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { Heading } from "../heading.component";
import { _getImage, _getVideoUrl } from "../../common/utils/commonFunctions";
import { useDispatch } from "react-redux";
import { loader } from "../../redux-tk/slices/loader.slice";
import "./styles.css";
import { GetExamDetails } from "../../services/axios/Exam";
import { useLocation, useNavigate } from "react-router-dom";
import { Languages } from "../../enums";
import "plyr-react/plyr.css";
import { AuthContext } from "../../hooks/auth.context.provider";
import { getExamAttemptAsyncThunk } from "../../redux-tk/slices/exam.currentQuestion";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { min } from "moment";
import { changeGLTime } from "../../redux-tk/slices/genieLabs.currentQuestion";

const QHeader = (props) => {
  const [examDetails, setExamDetails] = useState({});
  const [viewReport, setViewReport] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const [lang, setLang] = useState(Languages.english);
  const { genieLabsAssessmentTranslations } = useIgurooTranslations();

  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  );

  const Ref = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState("");
  const [timerElapsed, setTimerElapsed] = useState("");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    // console.log("LOG getTimeRemaining", total, hours, minutes, seconds);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);

    if (total >= 0) {
      if (props.duration <= 60) {
        dispatch(changeGLTime(total));
        setTimer(minutes + " mins " + seconds + " sec ");
        setTimerElapsed(props.duration - (minutes + 1) + " mins ");
      } else {
        dispatch(changeGLTime(total));
        setTimer(hours * 60 + minutes + " mins");
        setTimerElapsed(props.duration - (hours * 60 + minutes + 1) + " mins");
      }
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    // setTimer('00:00:00');
    // setTimerElapsed('00:00:00')
    // dispatch(changeGLTime(0));
    setTimer("0 mins ");
    setTimerElapsed("0 mins");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + props.duration * 60);
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime());
    return () => {
      setTimer("0 mins ");
      setTimerElapsed("0 mins");
    };
  }, []);

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  return (
    <Container>
      <Row>
        <Col md="12" className="mt-4 text-center">
          <Heading className="headingDashboard textcolor_primary">
            {props.title}
          </Heading>
        </Col>
      </Row>

      <Row className="text-center mt-4 mb-4 justify-content-center">
        <Col sm="12" md="2" className="d-grid ">
          {props?.assessmentData?.isShowTotalTime ? (
            <Row className="mb-2 textcolor_gray">
              {genieLabsAssessmentTranslations?.maxDuration}
            </Row>
          ) : (
            ""
          )}
          {props?.assessmentData?.isShowTimeElaspsed ? (
            <Row className="mb-2 textcolor_gray">
              {genieLabsAssessmentTranslations?.elapseTime}
            </Row>
          ) : (
            ""
          )}

          {props?.assessmentData?.isShowTimeLeft ? (
            <Row className="mb-2 textcolor_gray">
              {genieLabsAssessmentTranslations?.remainingTime}
            </Row>
          ) : (
            ""
          )}
          {!props?.assessmentData?.isShowTimeLeft &&
          !props?.assessmentData?.isShowTimeElaspsed &&
          !props?.assessmentData?.isShowTotalTime ? (
            <Row className="mb-2 textcolor_red">Total Time :</Row>
          ) : (
            ""
          )}
        </Col>
        <Col sm="12" md="2" className="d-grid ">
          {props?.assessmentData?.isShowTotalTime ? (
            <>
              <Row className="mb-2">{props.totalTime} min</Row>
            </>
          ) : (
            ""
          )}{" "}
          {props?.assessmentData?.isShowTimeElaspsed ? (
            <>
              <Row className="mb-2 textcolor_green">{timerElapsed}</Row>
            </>
          ) : (
            ""
          )}
          {props?.assessmentData?.isShowTimeLeft ? (
            <Row className="mb-2 textcolor_red">{timer}</Row>
          ) : (
            ""
          )}
          {!props?.assessmentData?.isShowTimeLeft &&
          !props?.assessmentData?.isShowTimeElaspsed &&
          !props?.assessmentData?.isShowTotalTime ? (
            <Row className="mb-2 textcolor_red">{props.totalTime} min</Row>
          ) : (
            ""
          )}
        </Col>

        <Col sm="12" md="1" className="d-grid ">
          {/* <Row className="mb-2">
            {
              <img
                height={20}
                width={20}
                src={_getImage("Hint_new.svg")}
                className="icon"
              />
            } 
          </Row> */}

          {/* <Row className="mb-2">
             {
              <img
                height={20}
                width={20}
                src={_getImage("Explanation.svg")}
                className="icon"
              />
            } 
          </Row> */}

          <Row className="mb-2">
            {
              <img
                height={20}
                width={20}
                src={_getImage("Attempt.svg")}
                className="icon"
              />
            }
          </Row>
        </Col>

        <Col sm="12" md="3" className="d-grid ">
          {/* <Row className="mb-2">
           {genieLabsAssessmentTranslations?.hint} 
          </Row>
          <Row className="mb-2"
            {genieLabsAssessmentTranslations?.explanation} 
          </Row> */}
          <Row className="mb-2">
            Attempt# {props.attemptLeft}/{props.attempt}
          </Row>
        </Col>

        <Col sm="12" md="1" className="d-grid ">
          <Row className="mb-2">
            {
              <img
                height={20}
                width={20}
                src={_getImage("Marked.svg")}
                className="icon"
              />
            }
          </Row>

          <Row className="mb-2" onClick={props?.pauseClick}>
            {
              <img
                height={20}
                width={20}
                src={_getImage("pause_.svg")}
                className="icon"
              />
            }
          </Row>

          <Row className="mb-2">
            {
              <img
                height={20}
                width={20}
                src={_getImage("questCount.svg")}
                className="icon"
              />
            }
          </Row>
        </Col>

        <Col sm="12" md="2" className="d-grid ">
          <Row className="mb-2 textcolor_yellow">
            {genieLabsAssessmentTranslations?.marked}
          </Row>
          <Row className="mb-2 textcolor_red" onClick={props?.pauseClick}>
            {genieLabsAssessmentTranslations?.pause}
          </Row>
          <Row className="mb-2 textcolor_primary">
            {props.currentQuestion}/{props.question}
          </Row>
        </Col>
        <Row className="mt-3 mb-2">
          <ColoredLine color="#6c757d" />
        </Row>
      </Row>
    </Container>
  );
};

export default QHeader;
