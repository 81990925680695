import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";

import { _getImage } from "../../common/utils/commonFunctions";
import { Link } from "react-router-dom";
import { GetQuestionsAssessmentApi } from "@iguroo/ig-react-assessment-package";
import { useGetAssessments } from "../../hooks/useGetAssessments";
import { getAssessmenById } from "../../services/axios/Assessment/assessment";
import { SubmitButton } from "../submit.button";
import { AuthContext } from "../../hooks/auth.context.provider";
import { GetAssessmentAttemptStatusApi } from "@iguroo/ig-react-assessment-package";
import { Heading } from "../heading.component";
import CourseQuiz from "./courseQuiz";

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1,
      alignItems: "center",
      justifyContent: "center",
    }}
  />
);
export default function CourseQuizMain(props) {
  const {
    title,
    assessment_version_id,
    number,
    nextClick,
    link,

  } = props;



  const authContext = useContext(AuthContext);
  const [completeAssessment, setCompleteAssessment] = useState(0);
  const [inProgress, setinProgress] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [startTest, setStartTest] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const {
    assessments,
    setAssessments,
    fetchAssessments,
    fetchInitialAssessments,
    getSearchOnChange,
    getSubmit,
    setType,
    type,
  } = useGetAssessments({
    apiFunction: getAssessmenById,
    initialQuery: {
      assessment_version_id: assessment_version_id,
    },
  });
  useEffect(() => {
    fetchInitialAssessments(assessment_version_id);
    GetAssessmentAttemptStatusApi({
      assessment_version_id: assessment_version_id,
      userId: authContext.sessionInfo.sub,
    })
      .then((res) => {
        console.log("res 165", res);
        setCompleteAssessment(res?.response?.data?.completed_attempts);
        setCompleted(res?.response?.data?.completed);
        setinProgress(res?.response?.data?.in_progress);
      })
      .catch((err) => console.log(err));
    setStartTest(false);
    setIsLoading(false);
  }, [assessment_version_id]);

  console.log('asssessmentw', assessments);
  const onButtonClick = () => {
    setIsLoading(true);
    setStartTest(true);
  };
  return (
    <>
      <Card
        className={` p-3  course-url-card my-4 w-80`}
      >
        <div className="ig-course-URL-title">
          <Heading className="user_title_main al_center color-primary">{number}{'. '}{title}</Heading></div>
        {isLoading && (
          <div className="loader-background">
            <div className="stripe_loader">
              <Spinner animation="border" variant="primary" />
            </div>
          </div>
        )}
        {!startTest ?
          <div className="ig-courses-URL-subtitle mt-3">
            <Container>
              <Row className="mt-3 mb-0">
                <ColoredLine color="#6c757d" />
              </Row>
              <Row className="user_desc">
                <span>Before starting the assessment, please make sure that the
                  information displayed on this page is accurate. To begin, click on
                  the "Start Assessment" button located at the bottom of this page.
                  Please note that the assessment can only be progressed forward and
                  that you must answer all questions. Your responses will be saved
                  automatically as you proceed through the assessment. Once you have
                  finished, your results will be submitted to iGuroo automatically.</span>
                <br /><b className="mt-2">Best of luck to you!</b>
              </Row>
              <Row className="mt-5 user_subtitle">Exam Information</Row>
              <Row className="mt-2 mb-0">
                <ColoredLine color="#6c757d" />
              </Row>
              <Row className="mt-0 list_subtitle al_left">
                <Col md="5">Assessment Title :</Col>
                <Col className="user_desc">{title}</Col>
              </Row>
              <Row className="mt-2 list_subtitle al_left">
                <Col md="5">Number of Questions :</Col>
                <Col className="user_desc">
                  {assessments?.number_of_questions
                    ? assessments?.number_of_questions
                    : 0}
                </Col>
              </Row>
              <Row className="mt-2 mb-0">
                <ColoredLine color="#6c757d" />
              </Row>
              <Row className="mt-2">
                <Col md={3}>
                  <SubmitButton
                    type="submit"
                    text={
                      inProgress && completed
                        ? "Resume Assessment"
                        : !inProgress && completed
                          ? "Reattempt Assessment"
                          : !inProgress && !completed
                            ? "Start Assessment"
                            : "Resume Assessment"
                    }


                    onClick={onButtonClick}
                  />
                </Col>
              </Row>
            </Container>


          </div> :
          quizCompleted ?
            <><Card className="mt-2 text-center p-4">
              <Row>
                <h3>Quiz Submitted Successfully </h3>
                <Row className="justify-content-center">
                  <Col md={5} className="">
                  
                    <SubmitButton
                      text={'Next'}
                      onClick={()=>nextClick()} /></Col></Row>
              </Row>
            </Card></> :
            <><CourseQuiz
              assessment_version_id={assessment_version_id}
              setIsLoading={(val) => setIsLoading(val)}
              submitQuiz={() => {
                console.log('submit')
                setQuizCompleted(true);
              }}
            /></>}
      </Card>
    </>
  )
}
