//** Giniehealth Aggird Action MailPopup,Extend,Rovoke,Pdf **//

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { _getImage } from "../../common/utils/commonFunctions";
import * as AWS from "aws-sdk";
import PopPdf from "../UserManagement/PopPdf";
import ActionCellRendererRevoked from "./Action";
import ExtendPopup from "./ExtendPopup";
import Toaster from "../../components/toaster";
import ActionMailPopup from "./ActionMailPopup";
import RetakeAssessmentPopup from "./RetakeAssessPopup";
import "./supervisor.scss";

export const ActionCellRender = (props) => {
  const { updateList } = props;
  const [show, setShow] = useState(false);
  const [showExtend, setShowExtend] = useState(false);

  const [imageSRC, setImageSRC] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [urlPdf, setUrlPdf] = useState(false);
  const [retakeShow, setRetakeShow] = useState(false);

  useEffect(() => {
    (async function () {
      const datechecking = props?.expiryDate;
      var currDate = new Date(new Date().getTime());
      var currDate2 = new Date(datechecking * 1000);
      console.log("123456 ", new Date().getTime(), currDate, datechecking);
      const diffMain = Math.ceil((currDate2 - currDate) / (1000 * 3600 * 24));
      console.log("LOGGG12  CURR DATE DIFF status status", diffMain);
      if (diffMain >= 1) {
        console.log("LOGGG12  CURR DATE DIFF status status", diffMain);
        setShowActions(true);
      } else {
        console.log("LOGGG12  CURR DATE DIFF status status else", diffMain);
        setShowActions(false);
      }
      if (props?.value?.openPopup == "show") {
        generatePresignedUrl(props?.value?.url);
      }
      if (
        props?.value?.status === "COMPLETED" &&
        props?.value?.url != null &&
        props?.value?.url !== ""
      ) {
        setImageSRC("pdf_new.svg");
      } else if (props?.value?.status === "EXPIRED") {
        setImageSRC("Extend_Assessment.svg");
      } else if (props?.value?.status === "PENDING") {
        setImageSRC("Revoke_Assessment.svg");
      }
      else{
        setImageSRC(false);
      }
      // console.log('lastItem',props.value,props.value.openPopup)
    })();
  }, [showActions, props]);

  console.log(
    "lastItem vaildTill",
    props,
    props?.expiryDate,
    props?.value?.expiryDate
  );

  const generatePresignedUrl = (props) => {
    (async function () {
      try {
        console.log("inside generatepresigned 165", props);
        AWS.config.update({
          accessKeyId: process.env.AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
          region: "us-east-1",
          signatureVersion: "v4",
        });
        const s3 = new AWS.S3();
        const urlPdf = props.toString().split(".s3.amazonaws.com/");
        const myBucket = urlPdf[0].split("https://")[1];
        const myKey = urlPdf[1];
        const signedUrlExpireSeconds = 60 * 60;
        const url = await s3.getSignedUrlPromise("getObject", {
          Bucket: myBucket,
          Key: myKey,
          Expires: signedUrlExpireSeconds,
        });
        //console.log("LOG url of PDF --------------------------------",url);
        setShow(true);
        setUrlPdf(url);
      } catch (error) {
        console.log("LOG generatePresignedUrl error ", error);
      }
    })();
  };

  const onCLickImage = () => {
    console.log(
      "button clicked status",
      props?.value?.status,
      props?.value?.status === "PENDING"
    );
    if (props?.value?.status === "COMPLETED") {
      generatePresignedUrl(props?.value?.url);
    } else if (props?.value?.status === "EXPIRED") {
      console.log(
        "LOGGG12  CURR DATE DIFF status status showActions",
        showActions
      );
      if (!showActions) {
        Toaster(toast.error, "Assignment has expired");
      } else {
        setShowExtend(true);
        updateList();
        // console.log("updateList")
      }
    } else if (props?.value?.status === "PENDING") {
      console.log(
        "LOGGG12  CURR DATE DIFF status status showActions",
        showActions
      );
      if (!showActions) {
        Toaster(toast.error, "Assignment has expired");
      } else {
        setShow(true);
        updateList();
        // console.log("updateList")
      }
    }
  };
  // console.log("rowdataui", props?.data["First Name"]);

  return (
    <>
      {!props.value ? (
        <></>
      ) : (
        <>
          <span className="">
            <ActionMailPopup
              assessments={props?.value?.assessments}
              userName={`${props?.data["firstName"]} ${props?.data["lastName"]}`}
            />
            {imageSRC ? (
              <div className="tooltip-container">
                <img
                  src={_getImage(imageSRC)}
                  height="20"
                  width="20"
                  alt="pdf"
                  role="button"
                  onClick={onCLickImage}
                  className="mb-1 mail-icon"
                />
                <span className="tooltip-text">
                  {props?.value?.status === "COMPLETED"
                    ? "Report"
                    : props?.value?.status === "EXPIRED"
                    ? "Extend"
                    : props?.value?.status === "PENDING"
                    ? "Revoke"
                    : ""}
                </span>
              </div>
            ) : (
              ""
            )}
            {props?.value?.status === "COMPLETED" ? (
              <>
                <RetakeAssessmentPopup
                  show={retakeShow}
                  onHide={() => setRetakeShow(false)}
                  assessments={props?.value?.assessments}
                  assessment_version_id={props?.value?.assessment_version_id}
                  LoadPage={() => props?.value?.LoadPage()}
                  updateList={updateList}
                />
                <div className="tooltip-container">
                  <img
                    src={_getImage("Retake_icon.svg")}
                    alt="icon-img"
                    height="20"
                    width="20"
                    role="button"
                    onClick={() => setRetakeShow(true)}
                    className="mb-1 mail-icon"
                  />
                  <span className="tooltip-text">Retake</span>
                </div>
              </>
            ) : (
              ""
            )}
            {props?.value?.status === "COMPLETED" && props?.value?.url ? (
              <PopPdf
                show={show}
                onHide={(show) => setShow(false)}
                url={urlPdf}
                showDownload={props?.value?.transcript_download}
              />
            ) : (
              ""
            )}
            {props?.value?.status === "PENDING" && showActions ? (
              <ActionCellRendererRevoked
                show={show}
                onHide={(show) => setShow(false)}
                assessments={props?.value?.assessments}
                assessment_version_id={props?.value?.assessment_version_id}
                LoadPage={() => props?.value?.LoadPage()}
                updateList={updateList}
                // console.log("updateList")
              />
            ) : (
              ""
            )}
            {props?.value?.status === "EXPIRED" || props?.value?.status === "PENDING"  ? (
              <>
              <ExtendPopup
                show={showExtend}
                onHide={(show) => setShowExtend(false)}
                assessments={props?.value?.assessments}
                validTill={props?.value?.validTill}
                assessment_version_id={props?.value?.assessment_version_id}
                LoadPage={() => props?.value?.LoadPage()}
                updateList={updateList}
                // console.log("updateList")
              />
              {props?.value?.status === "PENDING" &&(
                <div className="tooltip-container">
                <img
                  src={_getImage("Extend_Assessment.svg")}
                  alt="icon-img"
                  height="20"
                  width="20"
                  role="button"
                  onClick={() => setShowExtend(true)}
                  className="mb-1 mail-icon"
                />
                <span className="tooltip-text">Extend</span>
              </div>
              )}
</>
            ) : (
              ""
            )}
          </span>
        </>
      )}
    </>
  );
};
