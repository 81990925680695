//** Iguroo SWOT Assessment ->Swot ScreenIcons Component,Can re-use anywhere in required Field**//
//** Currently not using this code,will be implemented in future **//

import { _getImage } from "../../common/utils/commonFunctions";

export const SwotScreenIcons = ({
  makeRight,
  svgName,
  comPClassname,
  comTextClassName,
}) => {
  return (
    <div className={`${makeRight ? "text-right" : ""}`}>
      <div className={`d-inline-block ${comPClassname ? comPClassname : ""}`}>
        <div
          className={`text-center ${comTextClassName ? comTextClassName : ""}`}
        >
          <img className="swot-single-icon" src={svgName} />
        </div>
      </div>
    </div>
  );
};
