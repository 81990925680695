//** GinieHealth Auhtor,Graph Calculation Assessment component**//

import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { _getImage } from "../../common/utils/commonFunctions";
import "./styles.scss";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function AssessmentDetailsCards(props) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
    elements: {
      point: {
        radius: 0,
      },
      line: {
        tension: 0,
      },
    },
    maintainAspectRatio: false,
    bezierCurve: true,
    cubicInterpolationMode: "monotone",
    tension: 1,
    scales: {
      // to remove the labels
      x: {
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
        // to remove the x-axis grid
        grid: {
          drawBorder: false,
          display: false,
        },
      },
      // to remove the y-axis labels
      y: {
        ticks: {
          display: false,
          beginAtZero: true,
        },

        border: {
          display: false,
        },
        // to remove the y-axis grid
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    },
  };
  // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  const data = {
    labels: [1, 2, 3, 4, 5],
    datasets: [
      {
        data: [40, 20, 60, 30, 40, 80],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "none",
      },
    ],
  };
  const {
    activeTitle,
    cardPic,
    description,
    graphdetails,
    percentageChange,
    incrementDecrementPic,
    className,
  } = props;
  console.log(cardPic, "key profileImage console");

  return (
    <Card className={`assessment-info-card p-2 `}>
      <Container>
        <Row className="d-flex">
          <Col
            sm={3}
            className="profile-img-card d-flex align-items-center justify-content-center"
          >
            <div className="my-2">
              <img
                width={40}
                src={cardPic || _getImage("avatar.svg")}
                alt="profile pic"
              />
            </div>
          </Col>
          <Col sm={8}>
            <div className={`info mt-2 ${className}`}>
              <h1 className="card-activeTitle">{activeTitle}</h1>
              <p className="card-description my-1">{description}</p>
            </div>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col
            sm={3}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="my-auto">
              <Row>
                <Col>
                  {incrementDecrementPic ? (
                    <img src={incrementDecrementPic} alt="profile pic" />
                  ) : (
                    ""
                  )}
                  {"  "}
                  {percentageChange}
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm={9}>
            {graphdetails ? (
              <Line options={options} data={graphdetails} height="80px" />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

export default AssessmentDetailsCards;
