//** Iguroo Aggrid main,Request Details(Mentor & Mentee) Data Field,can re-use required field **//

import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { _getImage } from "../../common/utils/commonFunctions";
import { AppUrlsEnum, RequestStatus } from "../../enums";
import { isMentorOrPre } from "../../helpers";
import { getRequestdetails } from "../../services/axios/requests";
import { CategoryComponent } from "../category.component";
import "./user.renderer.scss";

export const RequestDetailRenderer = ({ value }) => {
  const [request, setRequest] = useState();
  const [statusImage, setStatusImage] = useState(null);
  const profile = useSelector((state) => state.user.profile);
  const navigate = useNavigate();

  let active = false;
  useEffect(() => {
    if (!active) {
      (async function () {
        try {
          if (!value) return;
          console.log("req value", value);
          const requestDetail = await getRequestdetails(value);
          //const completedSessions = getCompletedSessions(sessions?.Items);
          //console.log("request.sessions", sessions,data,completedSessions);
          // setLastSession(sessions?.Items[0]);
          setRequest(requestDetail?.Items[0]);

          if (
            requestDetail?.Items[0].status?.toUpperCase() ===
            RequestStatus.completed
          ) {
            setStatusImage(_getImage("check-circle.svg"));
          } else if (
            requestDetail?.Items[0].status?.toUpperCase() ===
            RequestStatus.created
          ) {
            setStatusImage(_getImage("clock.svg"));
          } else if (
            requestDetail?.Items[0].status?.toUpperCase() ===
            RequestStatus.active
          ) {
            setStatusImage(_getImage("play.svg"));
          } else if (
            requestDetail?.Items[0].status?.toUpperCase() ===
            RequestStatus.pending
          ) {
            setStatusImage(_getImage("clock.svg"));
          } else if (
            requestDetail?.Items[0].status?.toUpperCase() ===
            RequestStatus.cancelled
          ) {
            setStatusImage(_getImage("Delete.svg"));
          }
        } catch (err) {
          console.log("err getting sessions", err);
        }
      })();
    }
    return () => {
      active = true;
    };
  }, [value]);
  //console.log("d", lastSession, typeof lastSession?.scheduledDate);
  if (!value) {
    return <></>;
  }

  const handleCardClick = () => {
    localStorage.setItem(value, request?.title);
    if (isMentorOrPre(profile?.type)) {
      navigate(AppUrlsEnum.mentorRequestDetails.replace(":id", value));
    } else {
      console.log(
        "profile?.type",
        profile?.type,
        AppUrlsEnum.menteeRequestDetails
      );
      navigate(AppUrlsEnum.menteeRequestDetails.replace(":id", value));
    }
  };
  return (
    <>
      <Link
        to={
          isMentorOrPre(profile?.type)
            ? AppUrlsEnum.mentorRequestDetails.replace(":id", value)
            : AppUrlsEnum.menteeRequestDetails.replace(":id", value)
        }
        className="user-request-details"
      >
        <div
          className={`ig-request-heading mt-4`}
          onClick={() => handleCardClick()}
          role={"button"}
        >
          {statusImage != null ? (
            <>
              <img src={statusImage} />{" "}
            </>
          ) : (
            ""
          )}
          {request?.title?.trim()?.charAt(0)?.toUpperCase()}
          {""}
          {request?.title?.trim()?.slice(1)}
        </div>
        {request?.category
          ? request?.category?.map((category) => (
              <CategoryComponent
                className={"category"}
                title={category.value}
              />
            ))
          : ""}
      </Link>
    </>
  );
};
