//** GinieHealth Supervisor List card Assessment **//

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { _getImage } from "../../common/utils/commonFunctions";
import AssessmentCard from "../../components/PublisherComponent/AssessmentCard";
import SearchButton from "../../components/PublisherComponent/SearchButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppUrlsEnum, ProfileType } from "../../enums";
import { useGetAssessments } from "../../hooks/useGetAssessments";
import { GetAssessmentBySupervisorIdSearchApi } from "@iguroo/ig-react-assessment-package";
import { AuthContext } from "../../hooks/auth.context.provider";
import { loader } from "../../redux-tk/slices/loader.slice";
import { useDispatch } from "react-redux";
import { FormHeading } from "../../components/form.heading.component";

const supervisor_id = 3;
const SuperVisorContainer = () => {
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [searchData, setSearchData] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    assessments,
    setAssessments,
    fetchAssessments,
    getSearchOnChange,
    getSubmit,
    totalRecords,
  } = useGetAssessments({
    apiFunction: GetAssessmentBySupervisorIdSearchApi,
    initialQuery: {
      supervisor_id,
      from: currentPage,
      limit: itemsPerPage,
    },
    currentPage,
    itemsPerPage,
  });

  const submit = useCallback(
    async (data) => {
      try {
        dispatch(loader(true));
        console.error(
          " submitting data:",
          supervisor_id,
          currentPage,
          itemsPerPage
        );
        setSearchData(data?.search);
        await getSubmit(data, {
          supervisor_id,
          ...(data?.search && { search: data?.search }),
          from: currentPage,
          limit: itemsPerPage,
        });
      } catch (error) {
        console.error("Error submitting data:", error);
        setSearchError(true);
      } finally {
        dispatch(loader(false));
      }
    },
    [setAssessments]
  );
  console.log("asessment-pagination", assessments);

  const searchOnChange = useCallback((val) => {
    if (val.length > 0) {
      getSearchOnChange(val);
    } else {
      const fetchInitialData = async () => {
        try {
          dispatch(loader(true));
          setLoading(true);
          await fetchInitialAssessments({
            supervisor_id,
            from: currentPage,
            limit: itemsPerPage,
          });
          dispatch(loader(false));
          setLoading(false);
        } catch (error) {
          console.error("Error fetching initial assessments:", error);
          setSearchError(true);
          setLoading(false);
        }
      };
      fetchInitialData();
    }
  }, []);

  const fetchInitialAssessments = useCallback(
    async (query) => {
      const auth = await fetchAssessments({
        apiSearchParams: {
          ...query,
          ...(searchData && { search: searchData }),
          from: currentPage * itemsPerPage,
          limit: itemsPerPage,
        },
      });
      console.log("fetched assessments105", query);
      console.log("fetched assessments", auth);
      setAssessments(auth);
    },
    [currentPage, itemsPerPage]
  );

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        dispatch(loader(true));
        setLoading(true);
        await fetchInitialAssessments({
          supervisor_id,
          from: currentPage,
          limit: itemsPerPage,
        });
        dispatch(loader(false));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching initial assessments:", error);
        setSearchError(true);
        setLoading(false);
      }
    };
    fetchInitialData();
  }, [currentPage, itemsPerPage]);

  const authContext = useContext(AuthContext);

  const userType = useMemo(() => {
    return authContext?.sessionInfo?.role?.toUpperCase();
  }, [authContext.authStatus]);
  const handleClicked = (assessment_id, version, assessment_version_id) => {
    if (userType === ProfileType.admin) {
      navigate(
        `${AppUrlsEnum.todoSupervisor}?versionId=${version}&assessmentId=${assessment_id}`
      );
    } else if (
      userType === ProfileType.supervisor ||
      userType === ProfileType.admin
    ) {
      navigate(
        `${AppUrlsEnum.superVisorUserTodo}?versionId=${version}&assessmentId=${assessment_id}`
      );
    } else if (userType === ProfileType.compliance) {
      navigate(
        `${AppUrlsEnum.complianceSvTodo}?versionId=${version}&assessmentId=${assessment_id}`
      );
    }
  };

  return (
    <>
      <div className="m-2 p-2">
        <Row className="d-flex align-items-end ">
          <Col></Col>
          <Col sm={12} md={6} lg={5} xl={4} xxl={4} className="">
            <SearchButton submit={submit} handleOnChange={searchOnChange} />
          </Col>
        </Row>
        <hr />
        {assessments.length > 0 ? (
          <AssessmentCard
            data={assessments}
            itemsPerPage={itemsPerPage}
            totalRecords={totalRecords}
            setCurrentPage={setCurrentPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            onClick={handleClicked}
          />
        ) : loading ? (
          <FormHeading className="text-center mt-4">Loading...</FormHeading>
        ) : searchError ? (
          <FormHeading className="text-center mt-4">
            No Result Found in assessment
          </FormHeading>
        ) : (
          <FormHeading className="text-center mt-4">No Data Found</FormHeading>
        )}
      </div>
    </>
  );
};

export default SuperVisorContainer;
