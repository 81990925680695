import { axiosStaticData } from "../axios.service";
import { IGURU_DEV_URL } from "../../config";
import { COURSE_DATA, PROGRAMMES_DATA, SEARCH_COLLEGE, COLLEGES_DATA } from "../../urlConst";

export async function getCourseData(iguroo_institute_id) {
  try {
    const URL = IGURU_DEV_URL + COURSE_DATA + `${iguroo_institute_id}`;
    const response = await axiosStaticData.get(URL);
    // console.log("checking data for courses and college name", response.data.response.collegeName)
    return response.data.response;

  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function getProgramsData(iguroo_institute_id) {
  try {
    const URL = IGURU_DEV_URL + PROGRAMMES_DATA + `${iguroo_institute_id}`;
    const response = await axiosStaticData.get(URL);
    // console.log("checking programs data", response.data.response.Items)
    return response.data.response.Items;

  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function getSearchData(keyword) {
  
  const URL = IGURU_DEV_URL + SEARCH_COLLEGE
  // const URL = "https://i2pa0tjqcg.execute-api.us-east-1.amazonaws.com/sandbox/college/searchCollege"
  console.log("search coming here",keyword);
  const params = {
    input: keyword.input,
  };
  console.log("search coming here",params);
  try {
    // const URL = IGURU_DEV_URL + COURSE_DATA;
    const payload=keyword;
    const response = await axiosStaticData.post(`${URL}`,keyword);
    // console.log("Checking for colleges===>", response.data.response.colleges);
    return response;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function getCollegesData(filters) {

  const URL = IGURU_DEV_URL + COLLEGES_DATA

  // const URL = "https://i2pa0tjqcg.execute-api.us-east-1.amazonaws.com/sandbox"
  try {
    // const URL = IGURU_DEV_URL + COURSE_DATA;
    const payload=filters;
    const response = await axiosStaticData.post(`${URL}`, payload);
    // console.log("Checking for colleges===>", response.data.response.colleges);
    return response.data.response;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}


