//** Key and Value of Header & Footer Translations -> Check with Value name in en.language.json **//

export function HeaderFooterTranslations(t) {
  return {
    key: "headerFooterTranslations",
    value: {
      editAccount: t("headerFooterTranslations.editAccount"),
      logOut: t("headerFooterTranslations.logOut"),
      accountSettings: t("headerFooterTranslations.accountSettings"),
      careerPlan: t("headerFooterTranslations.careerPlan"),
      mentoring: t("headerFooterTranslations.mentoring"),
      testPrep: t("headerFooterTranslations.testPrep"),
      digiLearning: t("headerFooterTranslations.digiLearning"),
      blog: t("headerFooterTranslations.blog"),
      signIn: t("headerFooterTranslations.signIn"),
      signUp: t("headerFooterTranslations.signUp"),
      dashboard: t("headerFooterTranslations.dashboard"),
      request: t("headerFooterTranslations.request"),
      myMentor: t("headerFooterTranslations.myMentor"),
      message: t("headerFooterTranslations.message"),
      aboutUs: t("headerFooterTranslations.aboutUs"),
      termUse: t("headerFooterTranslations.termUse"),
      policy: t("headerFooterTranslations.policy"),
      support: t("headerFooterTranslations.support"),
      download: t("headerFooterTranslations.download"),
      agreeWith: t("headerFooterTranslations.agreeWith"),
      and: t("headerFooterTranslations.and"),
      buttonContinue: t("headerFooterTranslations.buttonContinue"),
      btnSendcode: t("headerFooterTranslations.btnSendcode"),
      btnOtpCode: t("headerFooterTranslations.btnOtpCode"),
      btnConfirm: t("headerFooterTranslations.btnConfirm"),
      assessmentsMsg: t("headerFooterTranslations.assessmentsMsg"),
      toasterMsgOnboarding: t("headerFooterTranslations.toasterMsgOnboarding"),
      contactUs: t("headerFooterTranslations.contactUs"),
      selfDiscovery: t("headerFooterTranslations.selfDiscovery"),
      home: t("headerFooterTranslations.home"),
      exploreColleges: t("headerFooterTranslations.exploreColleges"),
      blogs: t("headerFooterTranslations.blogs"),
      forum: t("headerFooterTranslations.forum"),
      assessmentLanguage: t("headerFooterTranslations.assessmentLanguage"),
      publisher: t("headerFooterTranslations.publisher"),
      myAssessment: t("headerFooterTranslations.myAssessment"),
    },
  };
}
