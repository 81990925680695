//** Iguroo & GinieHealth Signup UI **//

import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Form,
  Row,
  Col,
  Button,
  Image,
  InputGroup,
  Container,
} from "react-bootstrap";
import { AuthContext } from "../../hooks/auth.context.provider";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { SubmitButton } from "../../components/submit.button";
import { useDispatch } from "react-redux";
import { loader } from "../../redux-tk/slices/loader.slice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Heading } from "../../components/heading.component";
import { Link } from "react-router-dom";
import google from "../../assets/images/google.svg";
import checkbox from "../../assets/images/checkbox.svg";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import eyeoff from "../../assets/images/eyeoff.svg";
import { FormErrorComponent } from "../../components/form.error.component";
import { CustomCheckButton } from "../../components/CustomInput/custom.switch";
import { getSignUPFormTranslated } from "../../FormSchemas/create.user.schema";
import { createUserValue } from "../../redux-tk/slices/create.user.slice";
import {
  AppUrlsEnum,
  ProfileType,
  StrConstants,
  VerificationCodeEnums,
} from "../../enums";
import eyeopen from "../../assets/images/eyeopen.svg";
import "./styles.scss";
import { AwsSocialLogin } from "./social.auth";

export const SignUpScreen = () => {
  const {
    formElementLabels,
    formErrors,
    headingTranslations,
    headerFooterTranslations,
    cognitoTranslations,
  } = useIgurooTranslations();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { role } = useParams();
  const location = useLocation();

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm({
    resolver: yupResolver(getSignUPFormTranslated(formErrors)),
    defaultValues: {
      mobileNumber: "+91",
    },
  });

  useEffect(() => {
    (async function () {
      const thePath = location.search;
      const lastItem = thePath.split("=");
      console.log("console referencecode", lastItem);
      if (lastItem[0] == "?referencecode") {
        console.log("console referencecode", lastItem);
        const referencecode = thePath.substring(thePath.lastIndexOf("=") + 1);
        localStorage.setItem("referencecode", referencecode);
      }
    })();
  }, []);

  const onSubmit = async (data) => {
    console.log(data);
    try {
      dispatch(loader(true));
      const uname = `${StrConstants.signupStr}${data.mobileNumber}`;
      localStorage.setItem(StrConstants.username, uname);
      let profileType = ProfileType.mentee;
      const protectedLocation = location.pathname.startsWith(
        AppUrlsEnum.authSignupMentor
      );
      if (role && ProfileType[role] && protectedLocation) {
        profileType = ProfileType[role];
      }

      console.log(
        "location",
        location,
        "role",
        role,
        "pftype",
        profileType,
        protectedLocation,
        AppUrlsEnum.authSignupMentor
      );
      await authContext.signUpWithEmail(
        uname,
        data.email,
        data.password,
        data.mobileNumber,
        data.firstname,
        data.lastname,
        profileType
      );
      navigate(AppUrlsEnum.verifyMobile, {
        state: { pageName: VerificationCodeEnums.signup, session: "" },
      });
      console.log("made call");
    } catch (err) {
      console.log(
        "UsernameExistsException",
        "first",
        err,
        "message",
        err.message,
        "type",
        typeof err
      );
      if (err.code === "UserNotConfirmedException") {
        console.log("UsernameExistsException");
      } else if (err.code === "UserLambdaValidationException") {
        if (err.message.includes("phone_number_already_in_use")) {
          setError("apiError", {
            message: cognitoTranslations.phoneNumberAlreadyExists,
          });
        } else if (err.message.includes("email_already_in_use")) {
          setError("apiError", {
            message: cognitoTranslations.emailAlreadyExists,
          });
        } else {
          setError("apiError", { message: err.message });
        }
      } else {
        setError("apiError", { message: err.message });
        console.log("err", err);
      }
    } finally {
      dispatch(loader(false));
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const [value, setInputValue] = useState("");
  const [error, setErrors] = useState(false);
  const handleChange = (event) => {
    if (event.target.value.length <= 34) {
      setInputValue(event.target.value);
      setErrors(false);
    } else {
      setErrors(true);
    }
  };
  const [errorMsg, setErrorsmsg] = useState(false);
  const handleChanges = (event) => {
    if (event.target.value.length <= 34) {
      setInputValue(event.target.value);
      setErrorsmsg(false);
    } else {
      setErrorsmsg(true);
    }
  };

  return (
    <Row className="sign-head signup ig-auth-class justify-content-center align-items-center m-0 p-0 py-1">
      <Col md={6} lg={4} className="px-4 py-4 main-container">
        <Form className="signin-top w-100" onSubmit={handleSubmit(onSubmit)}>
          <Heading className="ig-sign-heading">
            {headingTranslations?.signUp}
          </Heading>
          <Container>
            <Row>
              <Col className="">
                {/* <Link to="#"> */}
                {/* <Button className="signin-btn">
                  <Image src={google}></Image>
                  {formElementLabels?.contiueBtn}
                </Button> */}
                {/* </Link> */}
                {/* <AwsSocialLogin /> */}
              </Col>
            </Row>
            {/* <Row className="mt-1">
              <Col>
                <hr className="hr ig-hr-tag" />
              </Col>
              <Col xs={"auto"} className="or-tag-name">
                {profileLabels?.or}
              </Col>
              <Col>
                <hr className="hr ig-hr-tag" />
              </Col>
            </Row> */}
          </Container>
          <Container>
            <Form.Group>
              <Row>
                <Col md="6" xs="12" sm="12" className="">
                  <Form.Label>{formElementLabels.firstNameSignup}</Form.Label>
                  <Controller
                    name={`firstname`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInputField
                        className={`sign-input ${
                          errors.firstname ? "ig-error-signin" : ""
                        }`}
                        placeholder={formElementLabels?.placeHolders?.firstName}
                        hookOnBlur={onBlur}
                        hookValue={value}
                        hookOnChange={(event) => {
                          handleChange(event);
                          onChange(event);
                        }}
                        maxLength={35}
                      />
                    )}
                  />
                  {error && (
                    <FormErrorComponent>
                      {formErrors.firstLastname}
                    </FormErrorComponent>
                  )}
                  <FormErrorComponent>
                    {errors.firstname && errors.firstname?.message}
                  </FormErrorComponent>
                </Col>
                <Col md="6" xs="12" sm="12" className="">
                  <Form.Label>{formElementLabels.lastNameSignup}</Form.Label>
                  <Controller
                    name={`lastname`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInputField
                        className={`sign-input ${
                          errors.lastname ? "ig-error-signin" : ""
                        }`}
                        placeholder={formElementLabels?.placeHolders?.lastName}
                        hookOnBlur={onBlur}
                        hookValue={value}
                        hookOnChange={(event) => {
                          handleChanges(event);
                          onChange(event);
                        }}
                        maxLength={35}
                      />
                    )}
                  />
                  {errorMsg && (
                    <FormErrorComponent>
                      {formErrors.firstLastname}
                    </FormErrorComponent>
                  )}
                  <FormErrorComponent>
                    {errors.lastname && errors.lastname?.message}
                  </FormErrorComponent>
                </Col>
              </Row>
              <Col className="mt-2">
                <Form.Label>{formElementLabels.mobileNum}</Form.Label>
                <Controller
                  name={`mobileNumber`}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CustomInputField
                      className={`sign-input ${
                        errors.mobileNumber ? "ig-error-signin" : ""
                      }`}
                      placeholder={formElementLabels?.placeHolders?.mobileNo}
                      hookOnChange={onChange}
                      hookOnBlur={onBlur}
                      hookValue={value}
                      maxLength={16}
                    />
                  )}
                />
                <FormErrorComponent>
                  {errors.mobileNumber && errors.mobileNumber?.message}
                </FormErrorComponent>
              </Col>
              <Col className="mt-2">
                <Form.Label>{formElementLabels.emailSigupRequired}</Form.Label>
                <Controller
                  name={`email`}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CustomInputField
                      type="email"
                      className={`sign-input ${
                        errors.email ? "ig-error-signin" : ""
                      }`}
                      placeholder={formElementLabels?.placeHolders?.email}
                      hookOnChange={onChange}
                      hookOnBlur={onBlur}
                      hookValue={value}
                    />
                  )}
                />
                <FormErrorComponent>
                  {errors.email && errors.email?.message}
                </FormErrorComponent>
              </Col>
              <Col className="mt-2">
                <Form.Label>{formElementLabels.passwordSign}</Form.Label>
                <InputGroup className="ig-password-field">
                  <Controller
                    name={`password`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInputField
                        type={passwordShown ? "text" : "password"}
                        className={`sign-input ${
                          errors.password ? "ig-error-signin" : ""
                        }`}
                        placeholder={formElementLabels?.placeHolders?.password}
                        hookOnChange={onChange}
                        hookOnBlur={onBlur}
                        hookValue={value}
                      />
                    )}
                  />
                  <InputGroup.Text
                    className={`password-icon ${
                      errors.password ? "ig-error-signin" : ""
                    }`}
                  >
                    <img
                      onClick={togglePassword}
                      src={passwordShown ? eyeopen : eyeoff}
                    />
                  </InputGroup.Text>
                </InputGroup>
                <FormErrorComponent>
                  {errors.password && errors.password?.message}
                </FormErrorComponent>
              </Col>
            </Form.Group>
            <Col className="align-self-start mt-3">
              <div className="signin-link d-inline">
                <Controller
                  name={"termAndPolicy"}
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <CustomCheckButton
                      className="d-inline-block text-start"
                      hookOnChange={onChange}
                      hookOnBlur={onBlur}
                      hookValue={value}
                      name={name}
                      type="checkbox"
                      defaultChecked={false}
                      checked={value}
                    />
                  )}
                />
                {headerFooterTranslations?.agreeWith}{" "}
                <a
                  href={AppUrlsEnum.termOfusePage}
                  target="_blank"
                  rel="noopener"
                >
                  {headerFooterTranslations?.termUse}
                </a>{" "}
                {headerFooterTranslations?.and}{" "}
                <a href={AppUrlsEnum.policyPage} target="_blank" rel="noopener">
                  {headerFooterTranslations?.policy}
                </a>
              </div>
            </Col>
            <Row className="align-self-start">
              <FormErrorComponent>
                {errors.termAndPolicy && errors.termAndPolicy?.message}
              </FormErrorComponent>
            </Row>
            <Row>
              <FormErrorComponent>
                {errors.apiError && errors.apiError?.message}
              </FormErrorComponent>
            </Row>
            <Col className="d-grid mt-3">
              <SubmitButton
                type="submit"
                text={headerFooterTranslations?.buttonContinue}
                onClick={() => {
                  clearErrors("apiError");
                  handleSubmit(onSubmit)();
                }}
                disabled={isSubmitting}
              />
            </Col>
            <Col className="mt-2">
              <div className="signin-link text-center pt-2">
                Already have an account?
                <Link className="ms-1" to={AppUrlsEnum.authSignin}>
                  Sign In
                </Link>
              </div>
            </Col>
          </Container>
        </Form>
      </Col>
    </Row>
  );
};
