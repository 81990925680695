//**  **//

export const MenteeOnboardingLabels = {
  profile: "Profile Photo",
  dateOfBirth: "Date of Birth",
  gender: "Gender",
  languagesYouSpeak: "Language you speak",
  addALanguage: "Add a language",
  male: "Male",
  female: "Female",
  dontWantToProvide: "Don’t want to provide",
  location: "Location",
  country: "Country",
  state: "State",
  field: "Specialization",
  city: "City",
  about: "About yourself",
  uploadProfileVideo: "Upload a Profile Video",
  iGuru: "iGuru",
  language: "Language",
  createMenteeProfile: "Create a mentee profile",
  tellUsAbout: "Tell us and our mentor about yourself",
  levelOfProficiency: "Level of Proficiency",
  languageYouSpeak: "Select languages you are comfortable  with",
  describeAboutYourself: " Describe yourself and your areas of interest",
  whatBestDescribesYou: "What best describes you?",
  uploadProfilePic: "Upload Image",
  useCamera: "Use Camera",
};

export const MentorOnboardingLabels = {
  createMentorProfile: "Create a mentor profile",
  profile: "Profile Photo",
  dateOfBirth: "Date of Birth",
  gender: "Gender",
  languagesYouSpeak: "Language you speak",
  addALanguage: "Add a language",
  menitoringSince: "Mentoring since",
  basicFormLang:
    "Select languages in which you can provide mentorship to the mentees",
  male: "Male",
  female: "Female",
  proTitle: "Profile title",
  proTitleNote:
    "This profile title goes on your profile card in the Mentors section.",
  dontWantToProvide: "Don’t want to provide",
  location: "Location",
  country: "Country",
  state: "State",
  city: "City",
  about: "About yourself",
  description: "Description",
  uploadProfileVideo: "Upload a Profile Video",
  endYear: "End Year",
  field: "Field of Study",
  degree: "Degree/Certification",
  school: "School/College/Institute",
  yearofexp: "Years of Experience",
  company: "Company/Organization",
  role: "Role/Designation/Title",
  sessionInterval: "Preferred session time interval",
  currency: "Currency",
  sessionCharge: "One session rate",
  sessionNote: 'Just leave "0" if you are going to do the sessions for free.',
  freeSession: "First session is free",
  monthlyRate: "Monthly rate (5 sessions)",
  quaterlyRate: "Quarterly (15 sessions)",
  anualRate: "Yearly (50 sessions)",
  rightSetupModule: {
    mentor: {
      title: "Mentor Profile",
      values: {
        aoe: "Areas Of Experience",
        we: "Work Experience",
        edu: "Education",
        avail: "Availability",
        basicInfo: "Basic Information",
        rates: "Rates",
      },
    },
  },
};
