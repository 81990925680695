//** Common Language Slice **//

import { createSlice } from "@reduxjs/toolkit";
import { Languages } from "../../enums";

const initialState = {
  language: Languages.english,
  organization:'iguroo',
};

export const generalInfoSlice = createSlice({
  name: "generalInfo",
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      state.language = action.payload;
    },
    changeOrganization: (state, action) => {
      state.organization = action.payload;
    },
  },
});

export const { changeLanguage, changeOrganization } = generalInfoSlice.actions;

export const languageValue = (state) => state.general.language;
export const organizationValue = (state) => state.general.organization;


export default generalInfoSlice.reducer;
