import React from "react";
import MenteeProfile from "../../mentee/profileInfo";

const MainInfo = () => {
  return (
    <>
      <p className="fs-18 fw-700 mt-4 ps-3">About Yourself</p>
      <MenteeProfile settings={true} />
    </>
  );
};
export default MainInfo;
