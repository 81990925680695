//** Common Routes for All the Components with Producted,LearnerProducted Route,Common Header & Footer **//

import React, { useState, useContext, useMemo } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import ProfileInfo from "../screens/mentee/profileInfo";
import SkillProficiency from "../screens/mentee/skillProficiency";
import EducationExperience from "../screens/mentee/educationExperience";
import AoeScreen from "../screens/mentor/aoe.screen";
import WorkExpScreen from "../screens/mentor/workExpScreen";
import EducationScreen from "../screens/mentor/EducationDetails";
import { SignInScreen } from "../screens/auth/signin";
import AvailabilityScreen from "../screens/mentor/availability.screen";
import ProfileScreen from "../screens/mentor/profileScreen";
import { AppUrlsEnum, ProfileType, RouteParamsEnum } from "../enums";
import MentorRateScreen from "../screens/mentor/rates.screen";
import { InitialRoute } from "../components/initial.route.component";
import MentorDashboard from "../screens/mentor/dashboard/MentorDashboard";
import Dashboard from "../screens/Dashboard/DashboardLayout";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { ConfirmMobileScreen } from "../screens/auth/auth.verification.code";
import { SignUpScreen } from "../screens/auth/signup";
import { ResetPassword } from "../screens/auth/reset.password";
import { UserOtpScreen } from "../screens/auth/auth.user.otp";
import { AwsSocialLogin } from "../screens/auth/social.auth";
import { ForgetPassword } from "../screens/auth/forget.password";
import MentorProfile from "../screens/mentor/mentorProfile/MentorProfile";
import LiveSession from "../screens/liveSession";
import ProtectedRoute from "./ProtectedRoute";
import PageNotFound from "../components/PageNotFound";
import { AuthContext } from "../hooks/auth.context.provider";
import Admin from "../screens/Admin/Admin";
import MentorMentees from "../screens/mentor/MentorMentees";
import MenteeMentors from "../screens/mentee/MenteeMentors";
import { MenteeRequests } from "../screens/mentee/mentee.requests";
import Settings from "../screens/settings/Settings";
import ZegoCloud from "../screens/zegoCloud";
import RequestPageDetails from "../screens/mentor/requests.page";
import FindMentorScreen from "../screens/mentee/find.mentor";
import MenteeMessages from "../screens/mentee/MenteeAssessments";
import MenteeProfile from "../screens/mentee/menteeProfile/MenteeProfile";
import { MentorRequests } from "../screens/mentor/mentor.requests";
import MentorMessages from "../screens/mentor/MentorMessages";
import MenteeDashboard from "../screens/mentee/dashboard/MenteeDashboard";
import AdminDashboard from "../screens/Admin/Dashboard";
import SiteAdministration from "../screens/Admin/SiteAdministration";
import AdminTables from "../screens/Admin/AdminTables";
import HeaderAndFooter from "../components/HeaderAndFooter";
import MentorPublicPrivateProfile from "../screens/Admin/MentorPubicPrivateProfile";
import AssessmentReports from "../screens/AssessmentReports/AssessmentReports";
import TestAssessments from "../screens/TestAssessments";
import CollegesList from "../screens/Colleges/collegeList/CollegesList";
import CollegesSummary from "../screens/Colleges/collegeSummary/CollegesSummary";
import Course from "../screens/Colleges/courses/courses";
import TestAssessmentsQuestions from "../screens/TestAssessments/questions";
import TestAssessmentsLists from "../screens/TestAssessments/assessmentList";
import { SortableExample } from "../components/DND/SortableExample";
import SelfAssessment from "../screens/TestAssessments/self.assessment";
import { SwotContainer } from "../screens/SwotAssessment/SwotContainer";
import ApproveTestimonials from "../screens/Admin/ApproveTestimonals";
import UpdateTestimonials from "../screens/Admin/UpdateTestimonial";
import RecommendationPage from "../screens/Admin/RecommendationPage";
import AssessmentReportAdmin from "../screens/Admin/AssessmentReportAdmin";
import MyAssessment from "../screens/genieLabs/myAssessment/myAssessment";
import SearchAssessment from "../screens/genieLabs/searchAssessment/searchAssessment";
import PublisherScreen from "../screens/Publisher";
import AssessmentDetails from "../screens/Course/AsessmentDetails";
import AssessmentDetailsPage from "../screens/Author/assessment.details";
import AuthorPage from "../screens/Author";
import TodoSupervisor from "../screens/SuperVisor/Todo.supervisor";
import SuperVisorContainer from "../screens/SuperVisor/Supervisor";
import Pricing from "../components/PublisherComponent/Pricing";
import LogicAndReportBuilder from "../components/PublisherComponent/LogicAndReportBuilder";
import Certification from "../components/PublisherComponent/Certification";
import Author from "../components/PublisherComponent/Author";
import TestQuestionScreen from "../screens/TestQuestions";
import PubAssessmentDetails from "../screens/AssessmentTests/AssessmentDetails";
import PubAssessmentSettings from "../screens/AssessmentTests/AssessmentSettings";
import PubIntendedLerners from "../screens/AssessmentTests/IntendedLearners";
import Questions from "../screens/Questions";
import AssessmentStartPage from "../screens/AssessmentStartPage";
import { SubmitButton } from "../components/submit.button";
import Users from "../screens/UserManagement";
import LearnerProtectedRoute from "./AdminProtectedRoute";
import UserSettings from "../screens/UserSettings";
import StripeSuccess from "../screens/Payment/StripeSuccess";
import StripeError from "../screens/Payment/StripeError";
import AdminUserManagement from "../screens/Admin/UserManagement";
import Courses from "../screens/Courses/Courses";
import CourseDetails from "../screens/Courses/courseDetails";
import MyCourses from "../screens/Courses/myCourses";
import CourseInvite from "../screens/Courses/course.invite";
import TranscriptList from "../screens/UserManagement/transcriptList";
import UserAssessmentList from "../screens/UserManagement/userAssessmentList";

export const MainRoute = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useSelector((state) => state.user.profile);
  const [layout, setLayout] = useState(false);
  const authContext = useContext(AuthContext);

  const userType = useMemo(() => {
    return authContext?.sessionInfo?.role?.toUpperCase();
  }, [authContext.authStatus]);

  useEffect(() => {
    if (
      (userType === ProfileType.mentee && user.onBoardStage === 3) ||
      (userType === ProfileType.mentor && user.onBoardStage === 6)
    ) {
      setLayout(true);
    } else {
      setLayout(false);
    }
  }, [pathname]);

  return (
    <InitialRoute>
      <div>
        <Routes>
          {/* Publisher Login -> Create New Assessments Screen Routes */}
          <Route
            exact
            path={AppUrlsEnum.assessmentDetails}
            element={
              <ProtectedRoute>
                <AssessmentDetails />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/questions"
            element={
              <ProtectedRoute>
                <HeaderAndFooter background="none">
                  <Questions />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={AppUrlsEnum.publisherAssessmentDetails}
            element={
              <HeaderAndFooter>
                <div className="ps-4">
                  <SubmitButton
                    text="Go Back"
                    className="ms-5 mt-5"
                    onClick={() => navigate(-1)}
                  />
                </div>
                <PubAssessmentDetails />
              </HeaderAndFooter>
            }
          />
          <Route
            exact
            path={AppUrlsEnum.publisherAssessmentDetails2}
            element={
              <HeaderAndFooter>
                <div className="ps-4">
                  <SubmitButton
                    text="Go Back"
                    className="ms-5 mt-5"
                    onClick={() => navigate(-1)}
                  />
                </div>
                <PubAssessmentDetails />
              </HeaderAndFooter>
            }
          />
          <Route
            exact
            path={AppUrlsEnum.publisherAssessmentSettings}
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <div className="ps-4">
                    <SubmitButton
                      text="Go Back"
                      className="ms-5 mt-5"
                      onClick={() => navigate(-1)}
                    />
                  </div>
                  <PubAssessmentSettings />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={AppUrlsEnum.publisherAssessmentSettings2}
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <div className="ps-4">
                    <SubmitButton
                      text="Go Back"
                      className="ms-5 mt-5"
                      onClick={() => navigate(-1)}
                    />
                  </div>
                  <PubAssessmentSettings />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={AppUrlsEnum.intendedLearners}
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <div className="ps-4">
                    <SubmitButton
                      text="Go Back"
                      className="ms-5 mt-5"
                      onClick={() => navigate(-1)}
                    />
                  </div>
                  <PubIntendedLerners />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={AppUrlsEnum.intendedLearners2}
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <div className="ps-4">
                    <SubmitButton
                      text="Go Back"
                      className="ms-5 mt-5"
                      onClick={() => navigate(-1)}
                    />
                  </div>
                  <PubIntendedLerners />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={AppUrlsEnum.pricing}
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <div className="ps-4">
                    <SubmitButton
                      text="Go Back"
                      className="ms-5 mt-5"
                      onClick={() => navigate(-1)}
                    />
                  </div>
                  <Pricing />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={AppUrlsEnum.pricing2}
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <div className="ps-4">
                    <SubmitButton
                      text="Go Back"
                      className="ms-5 mt-5"
                      onClick={() => navigate(-1)}
                    />
                  </div>
                  <Pricing />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={AppUrlsEnum.logicBuilder}
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <div className="ps-4">
                    <SubmitButton
                      text="Go Back"
                      className="ms-5 mt-5"
                      onClick={() => navigate(-1)}
                    />
                  </div>
                  <LogicAndReportBuilder />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={AppUrlsEnum.logicBuilder2}
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <div className="ps-4">
                    <SubmitButton
                      text="Go Back"
                      className="ms-5 mt-5"
                      onClick={() => navigate(-1)}
                    />
                  </div>
                  <LogicAndReportBuilder />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={AppUrlsEnum.certification}
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <div className="ps-4">
                    <SubmitButton
                      text="Go Back"
                      className="ms-5 mt-5"
                      onClick={() => navigate(-1)}
                    />
                  </div>
                  <Certification />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={AppUrlsEnum.certification2}
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <div className="ps-4">
                    <SubmitButton
                      text="Go Back"
                      className="ms-5 mt-5"
                      onClick={() => navigate(-1)}
                    />
                  </div>
                  <Certification />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />

          {/* Author Login Screen Routes */}
          <Route
            exact
            path={AppUrlsEnum.author}
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <div className="ps-4">
                    <SubmitButton
                      text="Go Back"
                      className="ms-5 mt-5"
                      onClick={() => navigate(-1)}
                    />
                  </div>
                  <Author />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={AppUrlsEnum.author2}
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <div className="ps-4">
                    <SubmitButton
                      text="Go Back"
                      className="ms-5 mt-5"
                      onClick={() => navigate(-1)}
                    />
                  </div>
                  <Author />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />

          {/* Mentee Login -> Dashboard Screen Routes */}
          <Route
            exact
            path={AppUrlsEnum.menteeDashboard}
            element={
              <ProtectedRoute>
                <Dashboard userType={user.type} />
              </ProtectedRoute>
            }
          >
            <Route
              exact
              path={AppUrlsEnum.menteeDashboard}
              element={
                <ProtectedRoute>
                  <MenteeDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.menteeMentors}
              element={
                <ProtectedRoute>
                  <MenteeMentors />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.menteeRequests}
              element={
                <ProtectedRoute>
                  <MenteeRequests />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.menteeSettings}
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.menteeLive}
              element={
                <ProtectedRoute>
                  <ZegoCloud />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.menteeRequestDetails}
              element={
                <ProtectedRoute>
                  <RequestPageDetails />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.findMentor}
              element={
                <ProtectedRoute>
                  <FindMentorScreen />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.menteeAssessments}
              element={
                <ProtectedRoute>
                  <MenteeMessages />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.menteeMentorProfile}
              element={
                <ProtectedRoute>
                  <MentorProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.selfDiscovery}
              exact
              element={
                <ProtectedRoute>
                  <SelfAssessment />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.swotAnalysis}
              exact
              element={
                <ProtectedRoute>
                  <SwotContainer />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.stripeSuccess}
              exact
              element={
                <ProtectedRoute>
                  <StripeSuccess />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.stripeError}
              exact
              element={
                <ProtectedRoute>
                  <StripeError />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.coursesUrl}
              exact
              element={
                <ProtectedRoute>
                  <Courses />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.myCoursesMentee}
              exact
              element={
                <ProtectedRoute>
                  <MyCourses />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Route>

          {/* Mentor Login -> Dashboard Screen Routes */}
          <Route
            exact
            path={AppUrlsEnum.mentorDashboard}
            element={
              <ProtectedRoute>
                <Dashboard userType={user.type} />
              </ProtectedRoute>
            }
          >
            <Route
              exact
              path={AppUrlsEnum.mentorDashboard}
              element={
                <ProtectedRoute>
                  <MentorDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.mentorMentees}
              element={
                <ProtectedRoute>
                  <MentorMentees />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.mentorMenteeProfile}
              element={<MenteeProfile />}
            />
            <Route
              exact
              path={AppUrlsEnum.mentorRequests}
              element={
                <ProtectedRoute>
                  <MentorRequests />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.selfDiscoveryMentor}
              exact
              element={
                <ProtectedRoute>
                  <SelfAssessment />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.swotAnalysisMentor}
              exact
              element={
                <ProtectedRoute>
                  <SwotContainer />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.mentorMeassages}
              element={
                <ProtectedRoute>
                  <MentorMessages />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.mentorSettings}
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.mentorRequestDetails}
              element={
                <ProtectedRoute>
                  <RequestPageDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.coursesUrlMentor}
              exact
              element={
                <ProtectedRoute>
                  <Courses />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.myCoursesMentor}
              exact
              element={
                <ProtectedRoute>
                  <MyCourses />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Route>

          {/* Iguroo Admin Login Screen Routes */}
          <Route
            exact
            path={AppUrlsEnum.adminDashboard}
            element={
              <ProtectedRoute>
                <Dashboard userType={user.type} />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path={AppUrlsEnum.adminDashboard}
            element={
              <ProtectedRoute>
                <Admin />
              </ProtectedRoute>
            }
          >
            <Route
              exact
              path={AppUrlsEnum.adminDashboard}
              element={
                <ProtectedRoute>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.adminUsers}
              element={
                <ProtectedRoute>
                  <AdminTables />
                </ProtectedRoute>
              }
            />
             <Route
              exact
              path={AppUrlsEnum.adminUserManagement}
              element={
                <ProtectedRoute>
                  <AdminUserManagement />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.adminSiteAdministration}
              element={
                <ProtectedRoute>
                  <SiteAdministration />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.adminProfilePublicPrivate}
              element={
                <ProtectedRoute>
                  <MentorPublicPrivateProfile />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.approveTestimonial}
              element={
                <ProtectedRoute>
                  <ApproveTestimonials />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.updateTestimonial}
              element={
                <ProtectedRoute>
                  <UpdateTestimonials />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.assessmentReportAdmin}
              element={
                <ProtectedRoute>
                  <AssessmentReportAdmin />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.adminMentee}
              element={
                <ProtectedRoute>
                  <div className="pt-5">
                    <MenteeProfile isAdmin={true} />
                  </div>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.recommendations}
              element={
                <ProtectedRoute>
                  <RecommendationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.coursesUrladmin}
              exact
              element={
                <ProtectedRoute>
                  <Courses />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.coursesInvite}
              exact
              element={
                <ProtectedRoute>
                  <CourseInvite />
                </ProtectedRoute>
              }
            />
            
            <Route path="*" element={<PageNotFound />} />
          </Route>

          {/* Mentee Login -> Onboarding Screen Routes */}
          <Route
            path={AppUrlsEnum.homepage}
            exact
            element={
              <HeaderAndFooter className={"signup-background"}>
                <SignInScreen />
              </HeaderAndFooter>
            }
          />
          <Route
            exact
            path={AppUrlsEnum.menteeProfile}
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <ProfileInfo />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            path={AppUrlsEnum.menteeSkill}
            exact
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <SkillProficiency />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            path={AppUrlsEnum.menteeEduAndWork}
            exact
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <EducationExperience />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          {/* Mentor Login -> Onboarding Screen Routes */}
          <Route
            path={AppUrlsEnum.mentorAreaOfExperience}
            exact
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <AoeScreen />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            path={AppUrlsEnum.mentorWorkExperience}
            exact
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <WorkExpScreen />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            path={AppUrlsEnum.mentorEducation}
            exact
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <EducationScreen />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            path={AppUrlsEnum.mentorAvailability}
            exact
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <AvailabilityScreen />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            path={AppUrlsEnum.mentorProfile}
            exact
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <ProfileScreen />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            path={AppUrlsEnum.mentorRates}
            exact
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <MentorRateScreen />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />

          <Route
            path={AppUrlsEnum.searchAssessment}
            exact
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <SearchAssessment />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />

          {/* SignUp,MentorSignUp,SignIn,OTP,ReferenceCode,ForgetPassword,ResetPassword,SocialLogin  */}
          {/* Credentials Screen Routes  */}
          <Route
            path={AppUrlsEnum.authSignin}
            exact
            element={
              <>
                {" "}
                <HeaderAndFooter className={"signup-background"}>
                  <SignInScreen />
                </HeaderAndFooter>
              </>
            }
          />
          <Route
            path={AppUrlsEnum.authSignup}
            exact
            element={
              <>
                <HeaderAndFooter className={"signup-background"}>
                  <SignUpScreen />
                </HeaderAndFooter>
              </>
            }
          />
          <Route
            path={AppUrlsEnum.referencecode}
            exact
            element={
              <>
                <HeaderAndFooter className={"signup-background"}>
                  <SignUpScreen />
                </HeaderAndFooter>
              </>
            }
          />
          <Route
            path={AppUrlsEnum.mentorDashboard1}
            exact
            element={
              <ProtectedRoute>
                <MentorDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path={AppUrlsEnum.authSigninSocial}
            exact
            element={
              <>
                <HeaderAndFooter className={"signup-background"}>
                  <AwsSocialLogin />
                </HeaderAndFooter>
              </>
            }
          />
          <Route
            path={AppUrlsEnum.liveSession}
            exact
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <LiveSession />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            path={AppUrlsEnum.testAssesment}
            exact
            element={
              <ProtectedRoute>
                <HeaderAndFooter background="none">
                  <TestAssessments />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={AppUrlsEnum.testAssesmentsquestion}
            element={
              <ProtectedRoute>
                <HeaderAndFooter background="none">
                  <TestAssessmentsQuestions />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={AppUrlsEnum.testAssesmentLists}
            element={
              <ProtectedRoute>
                <HeaderAndFooter background="none">
                  <TestAssessmentsLists />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            path={`${AppUrlsEnum.authSignupMentor}${RouteParamsEnum.role}`}
            exact
            element={
              <HeaderAndFooter className={"signup-background"}>
                <SignUpScreen />
              </HeaderAndFooter>
            }
          />
          <Route
            path={AppUrlsEnum.unauthorizedMentorProfile}
            exact
            element={
              <ProtectedRoute>
                <HeaderAndFooter>
                  <MentorProfile />
                </HeaderAndFooter>
              </ProtectedRoute>
            }
          />
          <Route
            path={AppUrlsEnum.verifyMobile}
            exact
            element={
              <HeaderAndFooter className={"signup-background"}>
                <ConfirmMobileScreen />
              </HeaderAndFooter>
            }
          />
          <Route
            path={AppUrlsEnum.authForgetPassword}
            exact
            element={
              <HeaderAndFooter className={"signup-background"}>
                <ForgetPassword />
              </HeaderAndFooter>
            }
          />
          <Route
            path={AppUrlsEnum.authResetPassword}
            exact
            element={
              <HeaderAndFooter className={"signup-background"}>
                <ResetPassword />
              </HeaderAndFooter>
            }
          />
          <Route
            path={AppUrlsEnum.authUserOtp}
            exact
            element={
              <HeaderAndFooter className={"signup-background"}>
                <UserOtpScreen />
              </HeaderAndFooter>
            }
          />

          {/* Mentor & Mentee Detail PublicPage Screen Routes */}
          <Route
            path={AppUrlsEnum.mentorDetailsPublicPage}
            exact
            element={
              <HeaderAndFooter>
                <MentorProfile isPublicProfile={true} />
              </HeaderAndFooter>
            }
          />
          <Route
            path={AppUrlsEnum.adminMentorDetailsPublicPage}
            exact
            element={
              <HeaderAndFooter>
                <MentorProfile admin={true} />
              </HeaderAndFooter>
            }
          />
          <Route
            path={AppUrlsEnum.adminMenteeDetailsPublicPage}
            exact
            element={
              <HeaderAndFooter>
                <MenteeProfile admin={true} />
              </HeaderAndFooter>
            }
          />
          <Route
            path={AppUrlsEnum.adminMenteeDetailsPublicPage}
            exact
            element={
              <HeaderAndFooter>
                <MenteeProfile admin={true} />
              </HeaderAndFooter>
            }
          />

          {/* Mentor & Mentee Login ->Self Discovery -> Assessment Report Screen Route */}
          <Route
            path={AppUrlsEnum.assessmentReports}
            exact
            element={<AssessmentReports />}
          />
          <Route
            path={AppUrlsEnum.dndTest}
            exact
            element={<SortableExample />}
          />

          {/* Iguroo Colleges Summary,List,Details Screen Routes */}
          {/* Currently not using this Routes,would be use in Future Enhancement */}
          <Route
            path={AppUrlsEnum.CollegesSummary}
            exact
            element={<CollegesSummary />}
          />
          <Route
            path={AppUrlsEnum.collegesDetails}
            exact
            element={<Course />}
          />
          <Route
            path={AppUrlsEnum.collegesList}
            exact
            element={<CollegesList />}
          />
           <Route
              exact
              path={AppUrlsEnum.coursesDetail}
              element={
                <ProtectedRoute>
                  <HeaderAndFooter>
                    <CourseDetails />
                  </HeaderAndFooter>

                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.coursesDetailMentor}
              element={
                <ProtectedRoute>
                  <HeaderAndFooter>
                    <CourseDetails />
                  </HeaderAndFooter>

                </ProtectedRoute>
              }
            />
          <Route path="*" element={<PageNotFound />} />

          {/* GinieHealth Admin Login Screen Routes */}
          <Route
            exact
            path={AppUrlsEnum.glAdminDashboard}
            element={
              <ProtectedRoute>
                <Dashboard userType={user.type} />
              </ProtectedRoute>
            }
          >
            <Route
              exact
              path={AppUrlsEnum.authorPage}
              element={
                <ProtectedRoute>
                  <AuthorPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.publisher}
              exact
              element={
                <ProtectedRoute>
                  <PublisherScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.authorAssessmentDetailPage}
              exact
              element={
                <ProtectedRoute>
                  <AssessmentDetailsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.StartScreen}
              exact
              element={
                <ProtectedRoute>
                  <AssessmentStartPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.superVisor}
              exact
              element={
                <ProtectedRoute>
                  <SuperVisorContainer />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.todoSupervisor}
              exact
              element={
                <ProtectedRoute>
                  <TodoSupervisor />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.TestScreen}
              exact
              element={
                <ProtectedRoute>
                  <TestQuestionScreen />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.Users}
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.userSettings}
              element={
                <ProtectedRoute>
                  <UserSettings />
                </ProtectedRoute>
              }
            />
              
            <Route path="*" element={<PageNotFound />} />
          </Route>

          {/* Supervisor(Recuriter) Login Screen Routes */}
          <Route
            exact
            path={AppUrlsEnum.superVisorUser}
            element={
              <ProtectedRoute>
                <Dashboard userType={user.type} />
              </ProtectedRoute>
            }
          >
            <Route
              path={AppUrlsEnum.superVisorUser}
              exact
              element={
                <ProtectedRoute>
                  <SuperVisorContainer />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.superVisorUserTodo}
              exact
              element={
                <ProtectedRoute>
                  <TodoSupervisor />
                </ProtectedRoute>
              }
            />

            <Route
              exact
              path={AppUrlsEnum.superVisorGinieUser}
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.supervisorUserSettings}
              element={
                <ProtectedRoute>
                  <UserSettings />
                </ProtectedRoute>
              }
            />
              <Route
              exact
              path={AppUrlsEnum.recruiterTranscript}
              element={
                <ProtectedRoute>
                  <TranscriptList />
                </ProtectedRoute>
              } 
            />
             <Route
              exact
              path={AppUrlsEnum.recruiterUserAssessment}
              element={
                <ProtectedRoute>
                  <UserAssessmentList />
                </ProtectedRoute>
              } 
            />
            <Route path="*" element={<PageNotFound />} />
          </Route>

          {/* Author Login Screen Routes */}
          <Route
            exact
            path={AppUrlsEnum.authorUserPage}
            element={
              <ProtectedRoute>
                <Dashboard userType={user.type} />
              </ProtectedRoute>
            }
          >
            <Route
              exact
              path={AppUrlsEnum.authorUserPage}
              element={
                <ProtectedRoute>
                  <AuthorPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.authorUserAssessmentDetailPage}
              exact
              element={
                <ProtectedRoute>
                  <AssessmentDetailsPage />
                </ProtectedRoute>
              }
            />

            <Route path="*" element={<PageNotFound />} />
          </Route>

          {/* Learner Login Screen Routes */}
          <Route
            exact
            path={AppUrlsEnum.learner}
            element={
              <ProtectedRoute>
                <Dashboard userType={user.type} />
              </ProtectedRoute>
            }
          >
            <Route
              path={AppUrlsEnum.learnermyassessment}
              exact
              element={
                <LearnerProtectedRoute>
                  <MyAssessment />
                </LearnerProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.learnerStartScreen}
              exact
              element={
                <ProtectedRoute>
                  <AssessmentStartPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.learnerTestScreen}
              exact
              element={
                <ProtectedRoute>
                  <TestQuestionScreen />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Route>

          {/* Publisher Login Screen Routes */}
          <Route
            exact
            path={AppUrlsEnum.publisherUser}
            element={
              <ProtectedRoute>
                <Dashboard userType={user.type} />
              </ProtectedRoute>
            }
          >
            <Route
              path={AppUrlsEnum.publisherUser}
              exact
              element={
                <ProtectedRoute>
                  <PublisherScreen />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Route>

          {/* Compliance Login Screen Routes */}
          <Route
            exact
            path={AppUrlsEnum.complianceRole}
            element={
              <ProtectedRoute>
                <Dashboard userType={user.type} />
              </ProtectedRoute>
            }
          >
            <Route
              path={AppUrlsEnum.compliancePublisher}
              exact
              element={
                <ProtectedRoute>
                  <PublisherScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.complianceSuperVisor}
              exact
              element={
                <ProtectedRoute>
                  <SuperVisorContainer />
                </ProtectedRoute>
              }
            />
            <Route
              path={AppUrlsEnum.complianceSvTodo}
              exact
              element={
                <ProtectedRoute>
                  <TodoSupervisor />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.complianceGinieUsers}
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.complianceGinieSettings}
              element={
                <ProtectedRoute>
                  <UserSettings />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppUrlsEnum.complianceGinieSettings}
              element={
                <ProtectedRoute>
                  <UserSettings />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </div>
    </InitialRoute>
  );
};
