//** GinieHealth Learner Login,CheckBox RadioOption FormLabel QuestionType UI component,used in radioButton component **//

import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./styles.scss";

const CheckBoxRadioAnswerOptions = (props) => {
  const {
    options,
    hookValue,
    hookOnBlur,
    hookOnChange,
    name,
    type,
    checked,
    defaultChecked,
    onChange,
    id,
    disabled,
    ref
  } = props;

  if (options?.length === 0) {
    return (
      <Spinner animation="border" variant="primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }
  console.log('CustomAnswerOptions',options)
  return (
    <div key={""} className="custom_drop_down_container"  >
      {options?.map((data, index) => {
       

        return (
          data.option_text != null && data.option_text != ""?
          
          <Form.Check type="radio"   id={`${id}-${index}`} className={"test_radio_option_row mx-3"}>
          <Form.Check.Input
            type={"radio"}
            autoFocus
            key={`${id}-${index}`}
            className=" "
            name={name}
            value={data?.option_id}
            onBlur={hookOnBlur}
            onChange={(e) => {
              if (hookOnChange) hookOnChange(e);
              if (onChange) onChange(e.target.value);
            }}
           {...( defaultChecked == data?.option_id && { defaultChecked: defaultChecked })}
           {...( disabled &&  {disabled :disabled})}
          />
          <Form.Check.Label for={`${id}-${index}`} className="">
            {data?.option_text}
          </Form.Check.Label>
        </Form.Check>
          :<></>
        );
      })}
    </div>
  );
};

export default CheckBoxRadioAnswerOptions;
