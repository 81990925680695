//** GinieHealth Learner Login,Popup Question UI component code **//
//** Used in Multiprespective & Text Question Component **//

import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIgurooTranslations } from "../../../hooks/use.iguroo.translation";

export function PopupQuestion(props) {
  const {
    showPopup,
    closeOnBackClick,
    modalBody,
    url,
    gotIt,
    title,
    submitText,
    className,
  } = props;

  const navigate = useNavigate();
  const { mentorOnboardingLabels } = useIgurooTranslations();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (closeOnBackClick) closeOnBackClick();
  };

  useEffect(() => {
    setShow(showPopup);
  }, [showPopup]);

  const handleSubmit = () => {
    setShow(false);
    if (gotIt) {
      gotIt();
    }
    if (url !== undefined && url.length > 1) {
      navigate(url);
    }
  };

  return (
    <>
      <Modal
        className={`completed-popup popupQuestion ${className}`}
        show={show}
        onHide={closeOnBackClick ? handleClose : null}
        backdrop="static"
        keyboard={false}
      >
        {closeOnBackClick ? (
          <Modal.Header className="text-center p-1" closeButton>
            <Modal.Title className="w-100">{title}</Modal.Title>
          </Modal.Header>
        ) : (
          <Modal.Header className="text-center p-1">
            <Modal.Title className="w-100">{title}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          <div className="popup-body mt-4 text-center mb-4">{modalBody}</div>
        </Modal.Body>
        {props?.hideSubmitButton ? (
          " "
        ) : (
          <Modal.Footer>
            <Button
              variant="primary w-100  btn-block"
              onClick={handleSubmit}
              required={true}
            >
              {submitText ? submitText : mentorOnboardingLabels.popupDone}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}
