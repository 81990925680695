//** Iguroo AvailableSlots Hooks**//

import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSessionsInDates,
  getRequestSessoinAsyncThunk,
  getSessionBetweenDatesAsyncThunk,
  getUserProfileSessionAsyncThunk,
} from "../redux-tk/slices/session.slice";
import moment from "moment";
import { getReduxCalendarDateExists, timeStampToDate } from "../helpers";

export default function useAvailableSlots({
  isByUser,
  userId,
  rates,
  sessionAvailable,
}) {
  const dispatch = useDispatch();
  const sessionsBetweenDates = useSelector((state) => {
    return getAllSessionsInDates(state);
  });
  const [BookedSession, setBookedSessions] = useState([]);
  const [currentSession, setCurrentSessions] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const onDateChange = useCallback(
    async (date) => {
      setCurrentDate(date);
      createCurrentSlot(date);
    },
    [sessionsBetweenDates, setCurrentSessions, setCurrentDate, sessionAvailable]
  );

  useEffect(() => {
    mainLogic(currentDate);
    if (moment(currentDate, "day").isSame(moment(new Date()), "day")) {
      sessionAvailable?.map((avails) =>
        days[currentDate.getDay()] == avails.availability ||
        avails.availability == "Everyday"
          ? createCurrentSlot(currentDate)
          : ""
      );
    }
  }, [currentDate, sessionsBetweenDates, sessionAvailable]);
  const createCurrentSlot = (date) => {
    const slots = [];

    const rateTime = rates ? rates[0]?.time : "";
    const time = rateTime ? rateTime?.substring(0, rateTime?.indexOf(" ")) : 60;

    let timeDiff = time * 60;
    console.log(sessionAvailable, "slots  sessionAvailable");
    sessionAvailable?.map((avails) => {
      if (
        days[date.getDay()] == avails.availability ||
        avails.availability == "Everyday"
      ) {
        let startTimestamp = moment(date).set({
          h: moment(avails.from).format("HH"),
          m: moment(avails.from).format("mm"),
          s: moment(avails.from).format("ss"),
        });
        let endTimestamp = moment(date).set({
          h: moment(avails.to).format("HH"),
          m: moment(avails.to).format("mm"),
          s: moment(avails.to).format("ss"),
        });
        startTimestamp = moment(startTimestamp.toDate()).unix();
        endTimestamp = moment(endTimestamp.toDate()).unix();
        while (
          startTimestamp < endTimestamp &&
          startTimestamp + timeDiff <= endTimestamp
        ) {
          const slot = {
            startTime: startTimestamp,
            endTime: startTimestamp + timeDiff,
          };
          if (startTimestamp > moment(new Date()).unix()) {
            const checkSlot = slots.filter((currentSlot) => {
              console.log(currentSlot.startTime, slot.startTime, "checkSlot");
              return currentSlot.startTime == slot.startTime;
            });
            console.log(checkSlot, "checkSlot", checkSlot.length, slots);
            if (checkSlot.length == 0) slots.push(slot);
          }
          startTimestamp += timeDiff;
        }
      }
    });
    slots.sort((a, b) => (a.startTime > b.startTime ? 1 : -1));
    console.log(slots, "checkSlot 107");
    setAvailableSlots(slots);
    console.log("slotsss", slots);
    const year = date.getFullYear();
    const month = date.getMonth();
    const sessions = getReduxCalendarDateExists(
      sessionsBetweenDates,
      year,
      month
    );
    if (sessions) {
      console.log("slotsss", sessions);
      //console.log("isHighlight if");
      for (let i = 0; i < sessions.length; i++) {
        const session = sessions[i];
        const sessionStartTime = session.startTime;
        setAvailableSlots((slot) =>
          slot.filter(
            (currentSlot) => currentSlot.startTime !== sessionStartTime
          )
        );
      }
    }
  };

  const onMonthChange = useCallback(
    (year, month, from, to) => {
      let endDate = moment(to).endOf("month");
      endDate = endDate.toDate();
      // console.log('end callstart' + moment(to).endOf('month'),  endDate)
      dispatch(
        getSessionBetweenDatesAsyncThunk({
          userId: userId,
          startDate: moment(from).unix(),
          endDate: moment(endDate).unix(),
          year: year,
          month: month,
        })
      );
    },
    [dispatch, getSessionBetweenDatesAsyncThunk, isByUser, userId]
  );

  const isHighlighted = useCallback(
    (date) => {
      let availDays = [];
      sessionAvailable?.map((avails) =>
        days[date.getDay()] == avails.availability ||
        avails.availability == "Everyday"
          ? availDays.push(date)
          : ""
      );
      //  console.log("seleect date slots ######", sessionAvailable);
      if (availDays.includes(date)) {
        //console.log("seleect date ######", date, sessionAvailable);
        return true;
      }
    },
    [sessionAvailable, userId]
  );

  const mainLogic = useCallback(
    async (date) => {
      // const available = dispatch(
      //   getUserAvailabilityAsyncThunk({ userId })
      // );
      const year = date.getFullYear();
      const month = date.getMonth();
      const d = date.getDate();
      const selectedSessions = [];
      const sessions = getReduxCalendarDateExists(
        sessionsBetweenDates,
        year,
        month
      );
      //  console.log(sessions, "mentor %%%%%%%%");

      if (sessions) {
        for (let i = 0; i < sessions.length; i++) {
          const session = sessions[i];
          const sessionStartTime = timeStampToDate(session.startTime);
          const sessionEndTime = timeStampToDate(session.endTime);
          const momentStart = moment(sessionStartTime);
          const momentEnd = moment(sessionEndTime);
          const timeDiff = moment.duration(momentEnd.diff(momentStart));
          if (sessionStartTime.getDate() === date.getDate()) {
            const req = session.request;
            const user = session.user;
            const mentor = session.mentor;
            if (!user && !isByUser) {
              console.log(session, "user %%%%%%%%");
              dispatch(
                getUserProfileSessionAsyncThunk({
                  year: year,
                  month: month,
                  sessionNumber: session.sessionNumber,
                  isUser: true,
                  userId: session.userId,
                })
              );
            }
            if (!mentor && isByUser) {
              console.log(session, "mentor %%%%%%%%");
              dispatch(
                getUserProfileSessionAsyncThunk({
                  year: year,
                  month: month,
                  sessionNumber: session.sessionNumber,
                  isUser: false,
                  userId: session.mentorId,
                })
              );
            }
            if (!req) {
              //console.log(session, "mentor %%%%%%%%", req);
              dispatch(
                getRequestSessoinAsyncThunk({
                  year: year,
                  month: month,
                  sessionNumber: session.sessionNumber,
                  requestNumber: session.requestNumber,
                })
              );
            }
            //console.log("request", req);
            //console.log("user name")
            const userToUse = isByUser ? mentor : user;
            const conSession = {
              session: {
                date: sessionStartTime.toDateString(),
                time: momentStart.format("HH:mm"),
                timeLeft: `${timeDiff.humanize()}`,
              },
              mentors: [
                {
                  name: userToUse?.firstName
                    ? `${userToUse?.firstName} ${userToUse?.lastName}`
                    : "",
                  subtext: req?.title,
                  picture: userToUse?.profilePic ? userToUse.profilePic : null,
                  userId: userToUse?.userId,
                  requestNumber: session.requestNumber,
                },
              ],
            };
            //console.log("state session pushing into array",conSession);
            selectedSessions.push(conSession);
          }
        }
        //console.log("state session onDateChange array", selectedSessions);
        setBookedSessions(selectedSessions);
      }
    },
    [sessionsBetweenDates, setCurrentSessions, dispatch, isByUser]
  );

  return {
    onDateChange,
    currentSession,
    setCurrentSessions,
    onMonthChange,
    isHighlighted,
    availableSlots,
  };
}
