//** Iguroo & GinieHealth,VerifyOTP MessageComponent for OTP popup **//

import React from "react";
import { _getImage } from "../../common/utils/commonFunctions";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { TextLink } from "../text.link.component";

export function VerifyMessageComponent(props) {
  const { headingTranslations } = useIgurooTranslations();
  const { text, className, mobileNumber, linkText, url } = props;
  return (
    <div className={`ig-card-verification-text  ${className}`}>
      {text ? text : headingTranslations?.verificationMessage}
      <br />
      {mobileNumber ? mobileNumber : ""}{" "}
      {/* <TextLink url= {url} className="ig-auth-link">{linkText? linkText: headingTranslations?.wrongNumber}</TextLink> */}
    </div>
  );
}
