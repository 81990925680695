//** Iguroo SWOT Assessment ->Swot ExamplePopup Component,Currently not using this code,will be implemented in future **//

import { Col, Row } from "react-bootstrap";
import { SwotTypesEnum } from "../../enums";
import { SwotExampPopupleComponent } from "./SwotExamplePopupComponent";

export const SwotExamplePopupView = ({ exampleData }) => {
  console.debug("example data got ", exampleData);
  return (
    <>
      <div>
        <Row>
          <Col xs={12} sm={12} md={6}>
            <SwotExampPopupleComponent
              data={exampleData?.strengths}
              swotType={SwotTypesEnum.strength}
              swotPlanType={SwotTypesEnum.strPlans}
              swotName={"Strength"}
              className={"example-str-tab"}
            />
          </Col>
          <Col xs={12} sm={12} md={6} className="mt-4 mt-sm-4 mt-md-0">
            <SwotExampPopupleComponent
              data={exampleData?.weaknesses}
              swotType={SwotTypesEnum.weakness}
              swotPlanType={SwotTypesEnum.wekPlans}
              swotName={"Weakness"}
              className={"example-wek-tab"}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={12} sm={12} md={6}>
            <SwotExampPopupleComponent
              data={exampleData?.opportunities}
              swotType={SwotTypesEnum.opportunities}
              swotPlanType={SwotTypesEnum.opoPlans}
              swotName={"Opportunity"}
              className={"example-oppo-tab"}
            />
          </Col>
          <Col xs={12} sm={12} md={6} className="mt-4 mt-sm-4 mt-md-0">
            <SwotExampPopupleComponent
              data={exampleData?.threats}
              swotType={SwotTypesEnum.threats}
              swotPlanType={SwotTypesEnum.thrPlans}
              swotName={"Threat"}
              className={"example-thr-tab"}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
