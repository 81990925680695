//** Iguroo Aggrid main Empty Data,message will show this file,can re-use required field **//

import { AgGridNoRowsParams } from "../../enums";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";

export const AgGridNoRows = (params) => {
  const { gridColumnDefinitions } = useIgurooTranslations();
  let messageToShow = gridColumnDefinitions.noDataToShow;
  if (
    params[AgGridNoRowsParams.isMenteeRequests] ||
    params[AgGridNoRowsParams.isMentorRequests]
  ) {
    messageToShow = gridColumnDefinitions.noDataRequests;
  } else if (params[AgGridNoRowsParams.isMenteeUsers]) {
    messageToShow = gridColumnDefinitions.noDataMenteeUsers;
  } else if (params[AgGridNoRowsParams.isMentorUsers]) {
    messageToShow = gridColumnDefinitions.noDataMentorUsers;
  } else if(params[AgGridNoRowsParams.isAssessmentUsers]){
    messageToShow = gridColumnDefinitions.noAssessments
  }
  console.log("no rows", params);
  return (
    <>
      <div>{messageToShow}</div>
    </>
  );
};
