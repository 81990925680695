//** GinieHealth of Status & Action Ui of UserManagement **//

import { useContext, useEffect, useMemo, useState } from "react";
import { AddUser } from "./AddUser";
import { _getImage } from "../../common/utils/commonFunctions";
import * as AWS from "aws-sdk";
import PopPdf from "./PopPdf";
import { AppUrlsEnum, ProfileType } from "../../enums";
import { AuthContext } from "../../hooks/auth.context.provider";
import { useNavigate } from "react-router-dom";

export const StatusTag = ({ value }) => {
  console.log("values", value);
  const statusData = {
    ACTIVE: { bg: "success", status: "Active", icon: "play_white.svg" },
    INACTIVE: { bg: "danger", status: "Inactive", icon: "clock_white.svg" },
  };

  return (
    <>
      {value && (
        <span
          style={{ fontWeight: "500", fontSize: "12px", width: "90px" }}
          class={`badge py-1 text-center rounded-pill text-bg-${statusData[value.toUpperCase()]?.bg
            }`}
        >
          <img
            src={_getImage(statusData[value.toUpperCase()]?.icon)}
            className="me-2"
            height="18"
            alt="box"
          />
          {statusData[value.toUpperCase()]?.status}
        </span>
      )}
    </>
  );
};

export const Action = (props) => {
  const navigate = useNavigate();
  const { updateList } = props;
  console.log("action", props);
  const authContext = useContext(AuthContext);
  const [addUserPopUp, setAddUserPopUp] = useState(false);
  const [userEditAction, setUserEditAction] = useState(false);
  const currentUser = useMemo(() => {
    console.log(authContext?.sessionInfo?.role, "authContext");
    return authContext?.sessionInfo;
  }, [authContext.authStatus]);

  useEffect(() => {
    console.log(props, "cellrenders js props");
    if (
      props?.data?.type === ProfileType.admin &&
      currentUser?.role.toUpperCase() === ProfileType.admin
    ) {
      setUserEditAction(false);
    } else {
      setUserEditAction(true);
    }
  }, []);

  return (
    <>
      {userEditAction && (
        <span className="">
          <img
            src={_getImage("edit.svg")}
            className="ms-2"
            height="20"
            width="20"
            alt="edit"
            role="button"
            onClick={() => setAddUserPopUp(true)}
          />
        </span>
      )}{" "}
      {/* {(currentUser?.role.toUpperCase() === ProfileType.recruiter && props?.data?.available_transcript) && (
        <span className="">
          <img
            src={_getImage("icon-pdf.svg")}
            className="ms-2"
            height="20"
            width="20"
            alt="pdf"
            role="button"
            onClick={() => {
              navigate(AppUrlsEnum.recruiterTranscript,
                {
                  state: {
                    selectedUserID: props?.data?.userId,
                    selectedUserName: props?.data?.firstName + " " + props?.data?.lastName
                  }
                }
              )
            }}
          />
        </span>
      )
      } */}
      {(currentUser?.role.toUpperCase() === ProfileType.recruiter &&
        <span className="">
          <img
            src={_getImage("assessments_icon.svg")}
            className="ms-2"
            height="20"
            width="20"
            alt="assessments"
            role="button"
            onClick={() => {
              navigate(AppUrlsEnum.recruiterUserAssessment,
                {
                  state: {
                    selectedUserID: props?.data?.userId,
                    selectedUserName: props?.data?.firstName + " " + props?.data?.lastName
                  }
                }
              )
            }}
          />
        </span>)}
      {addUserPopUp && (
        <AddUser
          show={addUserPopUp}
          onHide={() => setAddUserPopUp(false)}
          data={props.data}
          edit
          updateList={updateList}
          showPasswordFields
          igurooUser={props.igurooUser}
        />
      )}
    </>
  );
};

export const Name = ({ value }) => {
  return (
    <div className="d-flex align-items-center">
      <img
        src={_getImage("avatar.svg")}
        className="me-2"
        height="30"
        width="30"
        alt="name"
      />
      <span className="name">{value}</span>
    </div>
  );
};

export const Pdf = (props) => {
  const [show, setShow] = useState(false);
  const [urlPdf, setUrlPdf] = useState(false);

  useEffect(() => {
    (async function () {
      if (props?.value?.openPopup == "show") {
        generatePresignedUrl(props?.value?.url);
      }
      // console.log('lastItem',props.value,props.value.openPopup)
    })();
  }, []);

  const generatePresignedUrl = (props) => {
    (async function () {
      try {
        console.log("inside generatepresigned 165", props);
        AWS.config.update({
          accessKeyId: process.env.AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
          region: "us-east-1",
          signatureVersion: "v4",
        });
        const s3 = new AWS.S3();
        const urlPdf = props.toString().split(".s3.amazonaws.com/");
        const myBucket = urlPdf[0].split("https://")[1];
        const myKey = urlPdf[1];
        const signedUrlExpireSeconds = 60 * 60;
        const url = await s3.getSignedUrlPromise("getObject", {
          Bucket: myBucket,
          Key: myKey,
          Expires: signedUrlExpireSeconds,
        });
        //console.log("LOG url of PDF --------------------------------",url);
        setUrlPdf(url);
        setShow(true);
      } catch (error) {
        console.log("LOG generatePresignedUrl error ", error);
      }
    })();
  };

  return (
    <>
      {!props?.value?.url && props?.value?.status == "COMPLETED" ? (
        <img
          src={_getImage("SpinnersGif.gif")}
          height="30"
          alt="pdf"
          role="button"
        />
      ) : props?.value?.status == "COMPLETED" && (
        <>
          <span className="">
            <img
              src={_getImage("pdf_new.svg")}
              height="20"
              width="20"
              alt="pdf"
              role="button"
              onClick={() => generatePresignedUrl(props?.value?.url)}
            />
            <PopPdf
              show={show}
              onHide={(show) => setShow(false)}
              url={urlPdf}
              showDownload={props?.value?.transcript_download}
            />
          </span>
        </>
      )}
    </>
  );
};
export const AssessmentName = ({ value }) => {
  return (
    <div className="d-flex align-items-center">
      
      <span className="assessmentName">{value}</span>
    </div>
  );
};