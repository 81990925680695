//** Iguroo Mentor Onboarding Data Slice **//

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { availabilityResponseToRedux } from "../../helpers";
import { EDUCATION_TEMPLATE } from "../../screens/mentor/EducationDetails";
import { WORK_EXPERIENCE_TEMPLATE } from "../../screens/mentor/workExpScreen";
import {
  DeleteAvailability,
  DeleteEducation,
  DeleteWorkExp,
  GetAvailability,
  GetEducation,
  GetWorkExp,
} from "../../services/axios/MentorOnbaording";
import { decrementLoader, incrementLoader, loader } from "./loader.slice";

export const getWorkExpAsyncThunk = createAsyncThunk(
  "mentor/getWorkExpThunk",
  async (data, thunkApi) => {
    try {
      const workExp = await GetWorkExp(data.userId);
      return workExp.data;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);

function getFromPath(obj, path) {
  let r = obj;
  path.forEach((key) => {
    r = r[key];
  });
  return r;
}

function sortObjectsArr(objectsArray, ...path) {
  objectsArray.sort((a, b) => getFromPath(b, path) - getFromPath(a, path));
}

export const getEducationAsyncThunk = createAsyncThunk(
  "mentor/getEducationThunk",
  async (data, thunkApi) => {
    try {
      const workExp = await GetEducation(data.userId);
      sortObjectsArr(workExp.data, "year", "value");
      return workExp.data;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);

export const getAvailabilityAsyncThunk = createAsyncThunk(
  "mentor/getAvailabilityThunk",
  async (data, thunkApi) => {
    try {
      const avail = await GetAvailability(data.userId);
      return avail.data;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);

export const deletWorkExpAsyncThunk = createAsyncThunk(
  "mentor/deleteWorkExpThunk",
  async (data, thunkApi) => {
    try {
      thunkApi.dispatch(incrementLoader());
      const workExp = await DeleteWorkExp(data.id);
      return data.id;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    } finally {
      thunkApi.dispatch(decrementLoader());
    }
  }
);

export const deleteEducationAsyncThunk = createAsyncThunk(
  "mentor/deleteEducationThunk",
  async (data, thunkApi) => {
    try {
      thunkApi.dispatch(incrementLoader());
      const workExp = await DeleteEducation(data.id);
      return data.id;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    } finally {
      thunkApi.dispatch(decrementLoader());
    }
  }
);

export const deleteAvailabilityAsyncThunk = createAsyncThunk(
  "mentor/deleteAvailabilityThunk",
  async (data, thunkApi) => {
    try {
      thunkApi.dispatch(incrementLoader());
      const avail = await DeleteAvailability(data.id);
      return data.id;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    } finally {
      thunkApi.dispatch(decrementLoader());
    }
  }
);

const initialState = {
  areaOfEmphasis: [],
  availability: {
    timeZone: "",
    avails: [
      {
        id: -1,
        availability: "",
        timeRange: {
          startTime: moment().format(),
          endTime: moment().add(1, "hour").format(),
        },
      },
    ],
  },
  workExp: [WORK_EXPERIENCE_TEMPLATE],
  education: [EDUCATION_TEMPLATE],
  profileData: {},
  rateData: {
    sessionCharge: 0,
    monthlyCharge: 0,
  },
};

export const mentorOnboardingSlice = createSlice({
  name: "mentorOnboardingFormData",
  initialState,
  reducers: {
    areaOfEmphasAction: (state, action) => {
      console.log("ac", action);
      state.areaOfEmphasis = action.payload;
    },
    availabilityAction: (state, action) => {
      state.availability.timeZone = action.payload.timeZone;
      state.availability.avails = [...action?.payload?.avails];
    },
    workExpAction: (state, action) => {
      state.workExp = [...action?.payload];
    },
    educationAction: (state, action) => {
      state.education = [...action?.payload];
    },
    profileDataAction: (state, action) => {
      state.profileData = { ...action?.payload };
    },
    rateDataAction: (state, action) => {
      state.rateData = { ...action?.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkExpAsyncThunk.fulfilled, (state, action) => {
        if (!action.payload) return;
        //console.log("action)", action);
        state.workExp = [...action?.payload];
      })
      .addCase(getWorkExpAsyncThunk.pending, (state) => {})
      .addCase(getWorkExpAsyncThunk.rejected, (state) => {})
      .addCase(deletWorkExpAsyncThunk.fulfilled, (state, action) => {
        //console.log("action)", action);
        let indexFound = -1;
        for (let i = 0; i < state.workExp.length; i++) {
          const ele = state.workExp[i];
          if (ele.id === action.payload) {
            indexFound = i;
            break;
          }
        }
        if (indexFound != -1) {
          console.log("deleting", indexFound, state.workExp[indexFound]);
          state.workExp.splice(indexFound, 1);
        }
      })
      .addCase(deletWorkExpAsyncThunk.pending, (state) => {})
      .addCase(deletWorkExpAsyncThunk.rejected, (state) => {})
      .addCase(getEducationAsyncThunk.fulfilled, (state, action) => {
        //console.log("action)", action);
        state.education = [...action?.payload];
      })
      .addCase(getEducationAsyncThunk.pending, (state) => {})
      .addCase(getEducationAsyncThunk.rejected, (state) => {})
      .addCase(getAvailabilityAsyncThunk.fulfilled, (state, action) => {
        if (!action.payload) return;
        console.log("action av)", action);
        console.log("modded", [...availabilityResponseToRedux(action.payload)]);
        state.availability.avails = [
          ...availabilityResponseToRedux(action.payload),
        ];
      })
      .addCase(getAvailabilityAsyncThunk.pending, (state) => {})
      .addCase(getAvailabilityAsyncThunk.rejected, (state) => {})
      .addCase(deleteEducationAsyncThunk.fulfilled, (state, action) => {
        //console.log("action)", action);
        let indexFound = -1;
        for (let i = 0; i < state.education.length; i++) {
          const ele = state.education[i];
          if (ele.id === action.payload) {
            indexFound = i;
            break;
          }
        }
        if (indexFound != -1) {
          console.log("deleting", indexFound, state.education[indexFound]);
          state.education.splice(indexFound, 1);
        }
      })
      .addCase(deleteEducationAsyncThunk.pending, (state) => {})
      .addCase(deleteEducationAsyncThunk.rejected, (state) => {})
      .addCase(deleteAvailabilityAsyncThunk.fulfilled, (state, action) => {
        //console.log("action)", action);
        let indexFound = -1;
        for (let i = 0; i < state.availability.avails.length; i++) {
          const ele = state.availability.avails[i];
          if (ele.id === action.payload) {
            indexFound = i;
            break;
          }
        }
        if (indexFound != -1) {
          console.log(
            "deleting",
            indexFound,
            state.availability.avails[indexFound]
          );
          state.availability.avails.splice(indexFound, 1);
        }
      })
      .addCase(deleteAvailabilityAsyncThunk.pending, (state) => {})
      .addCase(deleteAvailabilityAsyncThunk.rejected, (state) => {});
  },
});

export const {
  areaOfEmphasAction,
  availabilityAction,
  workExpAction,
  educationAction,
  profileDataAction,
  rateDataAction,
} = mentorOnboardingSlice.actions;

export const areaOfEmphasisValue = (state) =>
  state.mentorOnboarding.areaOfEmphasis;

export const availabilityValue = (state) => state.mentorOnboarding.availability;

export const workExpValue = (state) => state.mentorOnboarding.workExp;
export const educationValue = (state) => state.mentorOnboarding.education;
export const profileDataValue = (state) => state.mentorOnboarding.profileData;
export const rateDataValue = (state) => state.mentorOnboarding.rateData;

export default mentorOnboardingSlice.reducer;
