//** Iguroo Aggrid main,Status(Active & Inactive) Data Field,can re-use required field **//

import { _getImage } from "../../common/utils/commonFunctions";
import { RequestStatus } from "../../enums";
import { CategoryComponent } from "../category.component";

export const StatusRenderer = ({ value }) => {
  console.log(value, "vallllll");
  if (value?.toUpperCase() === RequestStatus.completed) {
    return (
      <CategoryComponent
        className={"status completed"}
        title={"Completed"}
        iconSrc={_getImage("green_check.svg")}
      />
    );
  } else if (value?.toUpperCase() === RequestStatus.created) {
    return (
      <CategoryComponent
        className={"status pending"}
        title={"Created"}
        iconSrc={_getImage("clock_white.svg")}
      />
    );
  } else if (value?.toUpperCase() === RequestStatus.active) {
    return (
      <CategoryComponent
        className={"status active"}
        title={"Active"}
        iconSrc={_getImage("play_white.svg")}
      />
    );
  } else if (value?.toUpperCase() === RequestStatus.pending) {
    return (
      <CategoryComponent
        className={"status pending"}
        title={"Pending"}
        iconSrc={_getImage("clock_white.svg")}
      />
    );
  } else if (value?.toUpperCase() === RequestStatus.cancelled) {
    return (
      <CategoryComponent
        className={"status cancelled"}
        title={"Cancelled"}
        iconSrc={_getImage("close_white.svg")}
      />
    );
  } else {
    return <></>;
  }
};
