//** Iguroo & GinieHealth Common Footer component ,all the footer functionalities have defined here**//

import React, { useState } from "react";
import Support from "./Support";
import { Link } from "react-router-dom";
import { _getImage } from "../../common/utils/commonFunctions";
import { Row, Col, Image, Container } from "react-bootstrap";
import twitter from "../../assets/images/twitter.svg";
import facebook from "../../assets/images/facebook.svg";
import instagram from "../../assets/images/instagram.svg";
import linkedin from "../../assets/images/linkedin.svg";
import youtube from "../../assets/images/youtube.svg";
import footer_logo from "../../assets/images/footer_logo.svg";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { AppUrlsEnum } from "../../enums";
import BookSession from "../BookSession/BookSession";
import { SubmitButton } from "../../components/submit.button";

const FooterComponent = () => {
  const displayAboutUs =
    process.env.REACT_APP_DISPLAY_FOOTER_ABOUTUS === "true";
  const displayTerms = process.env.REACT_APP_DISPLAY_FOOTER_TERMS === "true";
  const displayPolicy = process.env.REACT_APP_DISPLAY_FOOTER_POLICY === "true";
  const displaySupport =
    process.env.REACT_APP_DISPLAY_FOOTER_SUPPORT === "true";
  const displayFB = process.env.REACT_APP_DISPLAY_FOOTER_FB === "true";
  const displayInsta = process.env.REACT_APP_DISPLAY_FOOTER_INSTA === "true";
  const displayTwitter =
    process.env.REACT_APP_DISPLAY_FOOTER_TWITTER === "true";
  const displayLinkedIn =
    process.env.REACT_APP_DISPLAY_FOOTER_LINKEDIN === "true";
  const displayYouTube =
    process.env.REACT_APP_DISPLAY_FOOTER_YOUTUBE === "true";

  const [show, setShow] = useState(false);

  const onBookCLick = () => {
    console.log("checking click button");
    setShow(true);
  };

  const { headerFooterTranslations } = useIgurooTranslations();

  return (
    <Row className="footer-screen w-100">
      <Col
        className="footer-logo-img"
        xs={{ span: 12, order: 3 }}
        sm={{ span: 12, order: 3 }}
        md={{ span: 3, order: 1 }}
        lg={{ span: 3, order: 1 }}
        xl={{ span: 3, order: 1 }}
      >
        {process.env.REACT_APP_FOOTER === "Genie.svg" ? (
          <a>
            <Image
              src={_getImage(process.env.REACT_APP_FOOTER)}
              data-testid="footer-logo"
            />
          </a>
        ) : (
          <a href={AppUrlsEnum.landingPage}>
            <Image
              src={_getImage(process.env.REACT_APP_FOOTER)}
              data-testid="footer-logo"
            />
          </a>
        )}
      </Col>
      <Col
        className="footer-text d-flex justify-content-center"
        xs={{ span: 12, order: 2 }}
        sm={{ span: 12, order: 2 }}
        md={{ span: 6, order: 2 }}
        lg={{ span: 6, order: 2 }}
        xl={{ span: 6, order: 2 }}
      >
        {displayAboutUs && (
          <a
            href={AppUrlsEnum.aboutPage}
            target="_blank"
            rel="noopener"
            data-testid="About Us"
          >
            {headerFooterTranslations?.aboutUs}
          </a>
        )}
        {displayTerms && (
          <a
            href={AppUrlsEnum.termOfusePage}
            target="_blank"
            rel="noopener"
            data-testid="Term of Use"
          >
            {headerFooterTranslations?.termUse}
          </a>
        )}
        {displayPolicy && (
          <a
            href={AppUrlsEnum.policyPage}
            target="_blank"
            rel="noopener"
            data-testid="Policy"
          >
            {headerFooterTranslations?.policy}
          </a>
        )}
        {displaySupport && (
          <React.Fragment>
            <Link to="#" onClick={onBookCLick}>
              {headerFooterTranslations?.support}
            </Link>
            <Support
              show={show}
              onHide={() => setShow(false)}
              data-testid="support-modal"
            />
          </React.Fragment>
        )}
      </Col>
      <Col
        className="footer-icons d-flex justify-content-center justify-content-md-end"
        xs={{ span: 12, order: 1 }}
        sm={{ span: 12, order: 1 }}
        md={{ span: 3, order: 3 }}
        lg={{ span: 3, order: 3 }}
        xl={{ span: 3, order: 3 }}
      >
        {displayFB && (
          <a href={AppUrlsEnum.facebookPage}>
            <Image
              className="mx-4 mx-md-3"
              src={facebook}
              data-testid="Facebook"
            />
          </a>
        )}
        {displayInsta && (
          <a href={AppUrlsEnum.instagramPage}>
            <Image
              className="mx-4 mx-md-3"
              src={instagram}
              data-testid="Instagram"
            />
          </a>
        )}
        {displayTwitter && (
          <a href={AppUrlsEnum.twitterPage}>
            <Image
              className="mx-4 mx-md-3"
              src={twitter}
              data-testid="Twitter"
            />
          </a>
        )}
        {displayLinkedIn && (
          <a href={AppUrlsEnum.linkedInPage}>
            <Image
              className="mx-4 mx-md-3"
              src={linkedin}
              data-testid="LinkedIn"
            />
          </a>
        )}
        {displayYouTube && (
          <a href={AppUrlsEnum.youtubePage}>
            <Image
              className="mx-4 mx-md-3"
              src={youtube}
              data-testid="YouTube"
            />
          </a>
        )}
      </Col>
    </Row>
  );
};

export default FooterComponent;
