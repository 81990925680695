import {  axiosStaticData } from "../axios.service";
import {
  GET_LIST_SESSION_LIST,
  GET_SESSION_LIST,
  GET_SESSION_LIST_DATES_MENTEE,
  GET_SESSION_LIST_DATES_MENTOR,
  GET_SLOT_LIST_DATES_MENTOR,
} from "../../urlConst";

export const getSessionList = async (requestNumber) => {
  try {
    const params = {
      requestNumber: requestNumber,
    };
    const res = await axiosStaticData.get(GET_SESSION_LIST, {
      params,
    });
    return res.data.response;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

/**
 * Fetches the sessions for a user between given dates.
 * @param {boolean} isByUser Indicates if it is for a user or a mentor
 * @param {string} userId The id of the user
 * @param {number} startDate Start date as a timestamp
 * @param {number} endDate End date as a timestamp
 * @returns {Array} Array of sessions
 */
export const getSessionBetweenDatesList = async (
  isByUser,
  userId,
  startDate,
  endDate
) => {
  try {
    let url = isByUser
      ? GET_SESSION_LIST_DATES_MENTEE
      : GET_SESSION_LIST_DATES_MENTOR;
    let paramName = isByUser ? "userId" : "mentorId";
    const params = {
      endTime: endDate,
      [paramName]: userId,
      startTime: startDate,
    };
    const res = await axiosStaticData.get(url, { params });
    return res?.data?.response?.Items ? res?.data?.response?.Items : [];
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const getSlotsBetweenDatesList = async (userId, startDate, endDate) => {
  try {
    let url = GET_SLOT_LIST_DATES_MENTOR;
    // let paramName = isByUser ? "userId" : "mentorId";
    const params = {
      userId: userId,
      startDateTime: startDate,
      endDateTime: endDate,
    };
    const res = await axiosStaticData.get(url, { params });
    return res?.data?.response?.Items ? res?.data?.response?.Items : [];
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};


export const getListFromSessionList = async (requestNumbers) => {
  try {
    const body = {
      requestIds: requestNumbers,
    };
    const res = await axiosStaticData.post(GET_LIST_SESSION_LIST,body);
    return res.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

