import { axiosStaticData, axiosAwsIam } from "../axios.service";
import { IGURU_DEV_URL } from "../../config";
import { STATIC_ALL_DATA, STATIC_DATA_DATA_TYPE } from "../../urlConst";

export async function GetAllData() {
  try {
    const URL = IGURU_DEV_URL + STATIC_ALL_DATA;
    const response = await axiosStaticData.get(URL);
    return response.data.response.Items;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function GetDataType(dataType, language) {
  try {
    const URL =
      IGURU_DEV_URL +
      STATIC_DATA_DATA_TYPE +
      `?datatype=${dataType}.${language}`;
    const response = await axiosStaticData.get(URL);
    return response.data.response.Items;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

// export async function getTestimonials(userId) {
//   const URL = `${IGURU_DEV_URL}/user/testimonial?userId=${userId}`;
//   const responce = await axiosStaticData.get(URL);
//   return responce.data;
// }

export async function getTestData() {
  try {
    const url = `/test`;
    //const response = await axiosAwsIam.get(url);
    const responsePost = await axiosAwsIam.post(url, {
      posting: "data",
    });
    console.log("tokens cred post api response", responsePost.data);
    return responsePost.data;
  } catch (error) {
    //console.log("tokens cred api error",err);
    if (error.response) {
      // Request made and server responded
      console.log("tokens cred api error data", error.response.data);
      console.log("tokens cred api error status", error.response.status);
      console.log("tokens cred api error headers", error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.log("tokens cred api error request", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("tokens cred api error", error.message);
    }
  }
}
