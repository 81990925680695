//** Alert Component icon Field for Error Message,can use this common Component required Field **//

import { useState } from "react";
import { Alert, Row, Col } from "react-bootstrap";
import { _getImage } from "../common/utils/commonFunctions";

export function AlertComponent(props) {
  const [show, setShow] = useState(true);
  if (show) {
    return (
      <Alert
        className="workExpAlert mt-4"
        variant="primary"
        onClose={() => setShow(false)}
        dismissible
      >
        <Row>
          <Col md={"auto"} xs="auto">
            {props.showIcon ? (
              <img src={_getImage("info.svg")} alt="icon-img" />
            ) : (
              ""
            )}{" "}
          </Col>
          <Col dangerouslySetInnerHTML={{ __html: props.children }}></Col>
        </Row>
      </Alert>
    );
  }
  return <></>;
}
