//** GinieHealth DetailsCardHeaderAssessment Component Code UI **//

import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { _getImage } from "../../common/utils/commonFunctions";
import "./supervisor.scss";
import Rating from "react-rating";
import { useGetAssessments } from "../../hooks/useGetAssessments";
import { GetAssessmentsApi } from "@iguroo/ig-react-assessment-package";
import { FormHeading } from "../../components/form.heading.component";
import { CustomImage } from "../../components/Media";
import { ImgSrcEnums } from "../../enums";
import moment from "moment";
import {
  card_date_display,
  card_rate_display,
  card_price_display,
} from "../../services/config";

function DetailsCardHeaderAssessment(props) {
  const { profilePic, className, style, assessment_id, version } = props;
  console.log(profilePic, "key profileImage console");
  // console.log(assessment_id,"assessment-idd")
  const { assessments, fetchInitialAssessments } = useGetAssessments({
    apiFunction: GetAssessmentsApi,
    initialQuery: { assessment_id, version },
  });

  useEffect(() => {
    fetchInitialAssessments({ assessment_id, version });
  }, []);
  useEffect(() => {
    if (assessments.length > 0) {
      props?.validDate(assessments[0].valid_till);
    }
  }, [assessments]);

  return (
    <>
      {assessments.length > 0 ? (
        <Container className={`assessment-info p-2 ${className}`} style={style}>
          <Row>
            {assessments.map((data) => {
              var currDate = new Date(new Date().getTime());
              var currDate2 = new Date((data.valid_till + 86340) * 1000);
              const diffMain = Math.ceil(
                (currDate2 - currDate) / (1000 * 3600 * 24)
              );
              return (
                <>
                  <Col sm={2}>
                    <div className="profile-img-assessment d-flex justify-content-center">
                      {data.banner_image ? (
                        <CustomImage
                          src={{
                            src: `${data?.banner_image}`,
                            type: ImgSrcEnums.props,
                          }}
                          height="100px"
                          width="100px"
                          roundedCircle={true}
                        />
                      ) : (
                        <img
                          height="100px"
                          width="100px"
                          src={_getImage("default_logo_new.jpg")}
                          className="icon rounded-circle"
                        />
                      )}
                    </div>
                  </Col>
                  <Col sm={9}>
                    <div className="info mt-2">
                      <h1
                        className="name-firstletter"
                        style={{ cursor: "pointer", color: "var(--black)" }}
                      >
                        {data.title}
                      </h1>
                      <div className="description my-1">{data.subtitle}</div>
                      <p className="my-2">{data.details}</p>
                      <p>
                        {data.duration}(
                        {data.number_of_questions
                          ? data.number_of_questions
                          : "0"}{" "}
                        Question
                        {data.number_of_questions === undefined ||
                        data.number_of_questions === 0 ||
                        data.number_of_questions === 1
                          ? ""
                          : "s"}
                        )
                      </p>
                      {card_rate_display ? (
                        <Rating
                          placeholderRating={data.rating ? data.rating : 0}
                          readonly={true}
                          emptySymbol={
                            <img
                              height={20}
                              width={20}
                              src={_getImage("emptyBlueStar.svg")}
                              className="icon"
                            />
                          }
                          placeholderSymbol={
                            <img
                              height={20}
                              width={20}
                              src={_getImage("bluestar.svg")}
                              className="icon"
                            />
                          }
                          fullSymbol={
                            <img
                              height={20}
                              width={20}
                              src={_getImage("bluestar.svg")}
                              className="icon"
                            />
                          }
                        />
                      ) : (
                        " "
                      )}
                      {card_date_display ? (
                        <Col xs="12" md="7" lg="7" xl="7" className="px-0 mt-3">
                          <img
                            className="me-1"
                            width="22px"
                            height="22px"
                            src={_getImage("schedule.svg")}
                          />

                          {diffMain > 0 ? (
                            <span className="validtill-date px-1">
                              {moment
                                .unix(data?.valid_till)
                                .format(process.env.REACT_APP_DATE_FORMAT2)}
                            </span>
                          ) : (
                            <span
                              style={{
                                fontWeight: "500",
                                fontSize: "13px",
                                backgroundColor: "#D73D3D",
                                border: `1px solid #D73D3D`,
                                borderRadius: "4px",
                                padding: "2px 6px",
                                color: "white",
                                height: "22px",
                                width: "90px",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "5px",
                              }}
                              className={`text-bg-#D73D3D mt-3`}
                            >
                              Expired
                            </span>
                          )}
                        </Col>
                      ) : (
                        ""
                      )}
                      {card_price_display ? (
                        <div className="price my-2 mt-3">{data.price}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </>
              );
            })}
          </Row>
        </Container>
      ) : (
        <FormHeading className="text-center mt-4">Loading...</FormHeading>
      )}
    </>
  );
}

export default DetailsCardHeaderAssessment;
