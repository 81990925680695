import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext,
} from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { useRef } from "react";

import { _getImage } from "../../common/utils/commonFunctions";
import "./Cources.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import CardAsses from "./CardAsses";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../hooks/auth.context.provider";
import { useGetAssessments } from "../../hooks/useGetAssessments";


import { FormHeading } from "../../components/form.heading.component";
import SearchButton from "../../components/PublisherComponent/SearchButton";
import { AppUrlsEnum } from "../../enums";
import { GetMyCourseListApi } from "@iguroo/ig-react-assessment-package";
import { organizationValue } from "../../redux-tk/slices/general.info.slice";

//const organization_id = 'iguroo';

const MyCourses = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
   const [courses, setCourses] = useState([]);
   const organization_id = useSelector((state) => organizationValue(state));

  const {
    assessments,
    setAssessments,
    fetchAssessments,
    fetchInitialAssessments,
    getSearchOnChange,
    getSubmit,
    setType,
    type,
  } = useGetAssessments({
    apiFunction: GetMyCourseListApi,
    initialQuery: {
      organization_id: organization_id
    },

  });

  useEffect(() => {
    console.log(assessments,'assessments assessments')
    fetchInitialAssessments({ organization_id: organization_id }).then(()=>{
      //setIsLoading(false);
    });
  }, []);
  useEffect(() => {
    console.log(assessments,'assessmw')
       if (assessments.length>0) {
            setCourses(assessments)
           setIsLoading(false);
       }
   }, [assessments]);
  // const courses = {
  //   data: [{
  //     courses_version_id: "bd558529-ee63-442c-b195-aadd182b13c2-1",
  //     attempts_allowed: 1,
  //      banner_image: "9ebd098d-fea7-4fa8-8d75-4d572fc69719/images/genie-health-logo-new.jpeg",

  //     due_datetime: 1704047399999,
  //     extended_attempts: 0,
  //     isExtended: false,
  //     percentageCompleted: 0,
  //     start_datetime: 1702035207772,
  //     status: "ACTIVE",
  //     course_description: "ULearn AWS in 24 hours",
  //     title: "Learn AWS in 2 hours",
  //     userId: "5faab0ac-0b1b-4762-bb89-3ca76a611f2c"

  //   },
  //   {
  //     courses_version_id: "bd558529-ee63-442c-b195-aadd182b13c2-1",
  //     attempts_allowed: 1,
  //      banner_image: "9ebd098d-fea7-4fa8-8d75-4d572fc69719/images/genie-health-logo-new.jpeg",

  //     due_datetime: 1704047399999,
  //     extended_attempts: 0,
  //     isExtended: false,
  //     percentageCompleted: 0,
  //     start_datetime: 1702035207772,
  //     status: "ACTIVE",
  //     course_description: "Learn AWS in 24 hours",
  //     title: "Learn AWS in 24 hours",
  //     userId: "5faab0ac-0b1b-4762-bb89-3ca76a611f2c"

  //   }
  //   ],
  //   totalCoursesCount: 2
  // }
  const [showTranscript, setShowTranscript] = useState(false);
  const [showTranscriptAttempt, setShowTranscriptAttempt] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  // const [isLoading, setIsLoading] = useState(false);
  const [isGridLoading, setIsGridLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const state = location.state;
  // const [type, setType] = useState(!state ? "pending" : "completed");
  const userInfo = useMemo(() => {
    return authContext?.sessionInfo;
  }, [authContext.authStatus]);



  const buttonColor = (assessType) => {
    if (assessType === type) {
      return { backgroundColor: "#5B2AEF", color: "white" };
    } else {
      return { backgroundColor: "#F4F8FB", color: "#A3A3A3" };
    }
  };

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);

  useEffect(() => {

  }, []);

  const [columnDefs] = useState([
    {
      field: "SI",
      headerName: "S.No.",
      maxWidth: 100,
    },
    {
      field: "Title",
      filter: true,
      sortable: true,
    },
    {
      field: "Grade",
      filter: true,
      sortable: true,
    },
    {
      field: "Due Date",
      filter: true,
      sortable: true,
    },
    {
      field: "Completed on",
      filter: true,
      sortable: true,
    },

  ]);

  const defaultColDef = useMemo(() => ({
    flex: 1,
    floatingFilter: true,
    unSortIcon: true,
  }));





  const rowHeight = 80;
  const searchOnChange = () => {

  }
  const submit = () => {

  }
  return (
    <>
      <div className="m-2 p-2 publisherContainer">
        <div className="mt-4 status-button-myassessment">
          <Row className="d-flex align-items-end">
            <Col sm={12} md={6} lg={6}>

            </Col>
            <Col
              sm={12}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              className="d-flex justify-content-end"
            >
              <SearchButton submit={submit} handleOnChange={searchOnChange} />
            </Col>
          </Row>
          {/* <Row>
              <Col>
                <Button
                  onClick={() => {
                    setIsLoading(true);
                    setType("pending");
                    setShowTranscript(false);
                    localStorage.removeItem("tab");
                  }}
                  style={buttonColor("pending")}
                >
                  Pending
                </Button>
                <Button
                  onClick={() => {
                    setIsLoading(true);
                    setType("completed");
                    setShowTranscript(false);
                    localStorage.removeItem("tab");
                  }}
                  style={buttonColor("completed")}
                >
                  Completed
                </Button>
  
                <Button
                  onClick={() => {
                    setShowTranscript(true);
                    setType("transcript");
                    localStorage.setItem("tab", "transcript");
                  }}
                  style={buttonColor("transcript")}
                >
                  Transcript
                </Button>
              </Col>
  
            </Row> */}
        </div>
        {showTranscript ? (
          <>
            <div className="ag-theme-alpine" style={{ height: "500px" }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
                rowHeight={rowHeight}
              />
            </div>
          </>
        ) : (
          <></>
        )}


        {!showTranscript && (
          <>
            {/* {!isLoading ? ( */}
            {console.log(courses,'assessmw courses')}
            {courses?.length > 0 &&
              
              <CardAsses
                data={courses}
                itemsPerPage={itemsPerPage}
                totalRecords={courses.length}
                setCurrentPage={setCurrentPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                showProgress={false}
                showStatus={true}
                navig={AppUrlsEnum.coursesDetail}
               myCourses={true}
              />
           }
            {/* ) 
              : (
                <FormHeading className="text-center mt-4">Loading...</FormHeading>
              )} */}
          </>
        )}
      </div>
    </>
  );
};
export default MyCourses;
