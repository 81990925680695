import { axiosStaticData } from "../axios.service";
import {
  EXAM_ATTEMP,
  EXAM_ATTEMP_COMPLETED,
  EXAM_DETAILS,
  EXAM_TYPE,
} from "../../urlConst";

export async function GetExamDetails(exam_type) {
  try {
    const URL = EXAM_TYPE;
    const params = {
      examType: exam_type,
    };
    const response = await axiosStaticData.get(URL, {
      params,
    });
    // console.log(response.data, "Console GetExamDetails");
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function GetExam(exam_id) {
  try {
    const URL = EXAM_DETAILS;
    const params = {
      examId: exam_id,
    };
    const response = await axiosStaticData.get(URL, {
      params,
    });
    // console.log(response.data, "Console GetExamDetails");
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export const ExamAttempt = async (examId, userId, completeExam, answers) => {
  try {
    console.log(examId,", payload 165");
    const payload = {
      examId: examId.toString(),
      userId: userId,
      completeExam: completeExam,
      answers: answers,
    };
    
    const res = await axiosStaticData.post(EXAM_ATTEMP, payload);
    localStorage.setItem("attemptId",res.data.response.attemptId)
    console.log(res.data.response.attemptId,"attedmpt iddd")
    return res.data.response;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const GetExamAttempt = async (parameter) => {
  try {
    const URL = EXAM_ATTEMP;

    const params = {
       ...(parameter.examId && { examId: parameter.examId }),

      userId: parameter.userId,
    };
    // const response = await axiosAwsIam.get(URL, {
    //   params,
    // });
    const res = await axiosStaticData.get(EXAM_ATTEMP, { params });
    return res;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const GetExamAttemptCompleted = async (userId, attemptId) => {
  try {
    const params = {
      attemptId: attemptId,
      userId: userId,
    };
    // const response = await axiosAwsIam.get(URL, {
    //   params,
    // });
    const res = await axiosStaticData.get(EXAM_ATTEMP_COMPLETED, { params });
    return res;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export async function ResetExam(attemptId, userId) {
  try {

    const params = {
      attemptId: attemptId,
      userId: userId,
    };
    const response = await axiosStaticData.delete(EXAM_ATTEMP, {
      params,
    });
    return response;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}
