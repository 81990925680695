//** GinieHealth AssessmentDetail Status,Currently not using this,Infurture we would implementing this code **//

import React, { useEffect } from "react";
import "./asessmentDetails.scss";
import { _getImage } from "../../common/utils/commonFunctions";
import { Col, Row } from "react-bootstrap";
import { SubmitButton } from "../../components/submit.button";
import { CustomVideo } from "../../components/Media";
import Rating from "react-rating";
import { useGetAssessments } from "../../hooks/useGetAssessments";
import { GetAssessmentsApi } from "@iguroo/ig-react-assessment-package";
// import { FormHeading } from "../components/form.heading.component";
import moment from "moment";

const assessment_id = "4252f6b1-f186-4a58-afb7-467a468f0a2e";
const AssessmentDetails = () => {
  const {
    assessments,
    setAssessments,
    fetchAssessments,
    fetchInitialAssessments,
    getSearchOnChange,
    getSubmit,
    setType,
    type,
  } = useGetAssessments({
    apiFunction: GetAssessmentsApi,
    initialQuery: { assessment_id },
  });
  console.log(assessment_id, "assessmentidpassed");
  useEffect(() => {
    fetchInitialAssessments({ assessment_id });
  }, []);

  return (
    <>
      {/* {assessments.length > 0 ? ( */}
      <div className="course-details-container">
        <div className="course-info p-3">
          {assessments.map((data) => {
            console.log(assessments, "assessmentidpassed");
            console.log(data, "assessmentidpassed");
            return (
              <>
                <h1 className="asessment-title">{data.title}</h1>
                <p className="asessment-desc">{data.subtitle}</p>
                <div className="my-2 d-flex">
                  <span className="chip px-2 py-1 me-2">Best Seller</span>
                  <span className="fs-16 fw-500 me-2">{4.8}</span>
                  <span className="me-2">
                    <Rating
                      placeholderRating={4.5}
                      readonly={true}
                      emptySymbol={
                        <img
                          height={25}
                          width={25}
                          src={_getImage("empty_star.svg")}
                          className="icon"
                        />
                      }
                      placeholderSymbol={
                        <img
                          height={25}
                          width={25}
                          src={_getImage("full_star.svg")}
                          className="icon"
                        />
                      }
                      fullSymbol={
                        <img
                          height={25}
                          width={25}
                          src={_getImage("full_star.svg")}
                          className="icon"
                        />
                      }
                    />
                  </span>
                  <span className="rating-text fs-15 fw-500 me-2">
                    {"(9788 Ratings)"}
                  </span>
                  <span>{"72000+ Students"}</span>
                </div>
                <p>
                  <span className="created fs-16 fw-400">Created By: </span>
                  <span className="author fs-16 fw-700">
                    {data.author_name}
                  </span>
                </p>
                <div className="d-flex">
                  <span className="fs-12 fw-400 me-2 d-flex">
                    <img
                      height={15}
                      width={15}
                      src={_getImage("info_icon.svg")}
                      className="me-1"
                    />{" "}
                    {/* Last updated {data.updatedAt} */}
                    Last updated {moment(data.updatedAt).format("MMM YYYY")}
                  </span>{" "}
                  <span className="fs-12 fw-400 d-flex">
                    <img
                      height={15}
                      width={15}
                      src={_getImage("globe_w.svg")}
                      className="me-1"
                    />{" "}
                    {data.language}
                  </span>
                </div>
              </>
            );
          })}
        </div>
        <Row className="mt-3">
          {assessments.map((data) => {
            return (
              <>
                <Col md={8}>
                  <div className="outcomes mb-2">
                    <p className="fs-14 fw-700">What you'll learn</p>
                    <Row>
                      {data.assessment_metadata.map((data1, index) => {
                        const whatWillYouLearn = data1.what_will_you_learn
                          ? JSON.parse(data1.what_will_you_learn.details)
                          : null;

                        const learnText =
                          whatWillYouLearn?.blocks[0]?.text || "";

                        return (
                          <Col md={6} className="d-flex mb-2" key={index}>
                            <span className="me-2">
                              <img
                                height={15}
                                width={15}
                                src={_getImage("tick.svg")}
                              />
                            </span>
                            <span className="fs-14 fw-400">{learnText}</span>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>

                  <div className="outcomes">
                    <p className="fs-14 fw-700">This course includes:</p>
                    <Row>
                      {data.assessment_metadata.map((data1, index) => {
                        if (data1.table_of_content) {
                          const tableOfContent = JSON.parse(
                            data1.table_of_content.details
                          );
                          const contentBlocks = tableOfContent.blocks || [];

                          return contentBlocks.map((block, blockIndex) => (
                            <Col
                              md={6}
                              className="d-flex mb-2"
                              key={blockIndex}
                            >
                              <span className="me-2">
                                <img
                                  height={15}
                                  width={15}
                                  src={_getImage("tick.svg")}
                                />
                              </span>
                              <span className="fs-14 fw-400">{block.text}</span>
                            </Col>
                          ));
                        }
                        return null;
                      })}
                    </Row>
                  </div>

                  <div className="mt-3">
                    {data.assessment_metadata.map((data1, index) => {
                      const prerequisiteDetails = data1.prerequisite
                        ? JSON.parse(data1.prerequisite.details)
                        : null;

                      if (prerequisiteDetails) {
                        const prerequisiteText =
                          prerequisiteDetails.blocks[0]?.text || "";

                        return (
                          <div className="mt-3" key={index}>
                            <p className="fs-14 fw-700">Requirements</p>
                            <div>
                              <ul className="fs-14 fw-400">
                                <li>{prerequisiteText}</li>
                              </ul>
                            </div>
                          </div>
                        );
                      }

                      return null; // Skip rendering if prerequisite is not present
                    })}
                  </div>

                  <div>
                    <p className="fs-14 fw-700">Description</p>
                    {data.assessment_metadata.map((data1, index) => {
                      if (data1.description) {
                        const descriptionDetails = JSON.parse(
                          data1.description.details
                        );
                        const descriptionText =
                          descriptionDetails.blocks[0]?.text || "";
                        return (
                          <Col md={12} className="mb-2" key={index}>
                            <span className="fs-14 fw-400 me-2">
                              {descriptionText}
                            </span>
                          </Col>
                        );
                      }
                      return null;
                    })}

                    <p className="fs-14 fw-700">Who this course is for:</p>
                    <div>
                      <ul className="fs-14 fw-400">
                        {data.assessment_metadata.map((data1, index) => {
                          const assessmentFor = data1.assessment_for
                            ? JSON.parse(data1.assessment_for.details)
                            : null;

                          return assessmentFor?.blocks.map(
                            (block, blockIndex) => (
                              <li key={blockIndex}>{block.text}</li>
                            )
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col md={4} className="px-4 right-content">
                  <div className="right-video-div">
                    <div className="p-3">
                      <div className="video-div">
                        <CustomVideo videoLink={data.banner_video} />
                      </div>
                      <p className="fs-18 fw-600">
                        Basic Calculus: {data.number_of_questions} Questions
                      </p>
                      <p className="fs-14 fw-500">
                        Solve some basic calculus problems to understand the
                        concept better
                      </p>
                      <p className="fs-14 fw-500 learn-more">Learn more</p>
                      <SubmitButton
                        text="Purchase"
                        className="purchase_btn w-100 my-2"
                      />
                      <p className="mt-2 fs-10 fw-600 rate-text text-center">
                        ₹829.00 per month. Cancel anytime.
                      </p>
                      <div className="ps-3">
                        <span className="me-3 fs-24 fw-600">₹449</span>
                        <span className="me-3 fs-24 fw-600 discount">
                          ₹3,199
                        </span>
                        <span className="fs-12 fw-700 p-off">85 % off</span>
                      </div>
                      <div className="d-flex justify-content-center mt-2 action-btn fs-14 fw-600">
                        <div className="me-2 flex-grow-1 text-center">
                          Start your Test
                        </div>
                        <div>
                          <img
                            height={18}
                            width={18}
                            src={_getImage("heart_outline.svg")}
                          />
                        </div>
                      </div>
                      <p className="text-center px-5 mt-3 fs-12 fw-600">
                        30-Day Money-Back Guarantee Full Lifetime Access{" "}
                      </p>
                    </div>
                    <div className="p-1">
                      <div className="d-flex justify-content-around share_div py-3">
                        <span className="fs-8 fw-600">
                          <img
                            height={13}
                            width={13}
                            src={_getImage("share_g.svg")}
                            className="me-1"
                          />{" "}
                          Share
                        </span>
                        <span className="fs-8 fw-600">
                          <img
                            height={13}
                            width={13}
                            src={_getImage("gift_g.svg")}
                            className="me-1"
                          />{" "}
                          Gift this test
                        </span>
                        <span className="fs-8 fw-600">
                          <img
                            height={13}
                            width={13}
                            src={_getImage("coupon_g.svg")}
                            className="me-1"
                          />{" "}
                          Apply Coupon
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            );
          })}
        </Row>
      </div>
      {/* ) : ( */}
      {/* <div className="text-center mt-4">Loading..</div> */}
      {/* )} */}
    </>
  );
};

export default AssessmentDetails;
