//**Iguroo Admin Mentor and Mentee User Picture Approve and Rejected UI code**//

import React from "react";
import { approvePic, rejectPic } from "../../services/axios/Admin/admin";
import Toaster from "../../components/toaster";
import { toast } from "react-toastify";

const ApprovePic = (props) => {
  console.log(props, "a-p");
  const handleApprove = () => {
    approvePic(props.data.userId, props.data.approverId)
      .then((res) => {
        props.getMedia();
        console.log(props, "called--3");
        Toaster(toast.success, "Profile picture is approved");
      })
      .catch((err) => console.log(err));
  };

  const handleReject = () => {
    rejectPic(props.data.userId)
      .then((res) => {
        props.getMedia();
        console.log(props, "called--3");
        Toaster(toast.success, "Profile picture is rejected");
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {props.data.newProfilePic === "" &&
      props.data.profilePic === "" &&
      props.data.isNewProfilePicRejected === true ? (
        <button disabled={true} class="btn danger-button btn-sm rounded-5">
          REJECTED
        </button>
      ) : props.data.newProfilePic === "" && props.data.profilePic === "" ? (
        <div></div>
      ) : props.data.newProfilePic === "" && props.data.isProfilePicApproved ? (
        <button
          disabled={true}
          onClick={handleApprove}
          class="btn primary-button btn-sm rounded-5"
        >
          Approved
        </button>
      ) : props.data.newProfilePic === "" &&
        props.data.isProfilePicApproved === false ? (
        <button disabled={true} class="btn danger-button btn-sm rounded-5">
          REJECTED
        </button>
      ) : (
        <div>
          <button
            onClick={handleApprove}
            class="btn primary-button btn-sm margin-lr"
          >
            Approve
          </button>
          <button
            onClick={handleReject}
            class="btn danger-button btn-sm margin-lr"
          >
            Reject
          </button>
        </div>
      )}
    </>
  );
};

export default ApprovePic;
