//** Mentor Profile Page **//

import React, { useEffect, useState, useMemo } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import tick_mark from "../../../assets/images/tick_mark.svg";
import globe from "../../../assets/images/globe.svg";
import users from "../../../assets/images/users.svg";
import user1 from "../../../assets/images/user.svg";
import clock from "../../../assets/images/clock.svg";
import brief_case from "../../../assets/images/briefcase.svg";
import book_open from "../../../assets/images/book-open.svg";
import check_circle from "../../../assets/images/primary-check-circle.svg";
import primary_bookmark from "../../../assets/images/primary_bookmark.svg";
import CustomBadge from "../../../components/customBadge/badge";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Modal, ModalTitle, Form } from "react-bootstrap";
import "plyr-react/plyr.css";
import { useDispatch, useSelector } from "react-redux";
import {
  calculate_age,
  getSessionType,
} from "../../../helpers/commons.helpers";
import { getTestimonials } from "../../../services/axios/staticData";
import BookSession from "../../../components/BookSession/BookSession";
import { useIgurooTranslations } from "../../../hooks/use.iguroo.translation";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import {
  GetPublicMentorAsync,
  GetUserAvailability,
} from "../../../services/axios/MentorOnbaording";
import {
  getPrivateMentor,
} from "../../../services/mentorServices";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../hooks/auth.context.provider";
import { useContext } from "react";
import { AuthStatus, ProfileType, RatesTimeFrame } from "../../../enums";
import { useNavigate } from "react-router-dom";
import { AppUrlsEnum } from "../../../enums";
import { getUserAvailabilityAsyncThunk } from "../../../redux-tk/slices/user.slice";
import { getRating } from "../../../services/axios/session";
import Rating from "react-rating";
import { _getImage } from "../../../common/utils/commonFunctions";
import { ProfileImage } from "../../../components/Media";
import {
  BookMarkAPI,
  GetTotalSessions,
} from "../../../services/axios/Dasboard";
import { CustomVideo } from "../../../components/Media";
import "react-quill/dist/quill.snow.css";
import { CustomInputField } from "../../../components/CustomInput/customInputField";
import { Controller, useForm } from "react-hook-form";
import {
  postTestimonials,
  getMentorTestimonials,
  getMentorMenteeTestimonials,
} from "../../../services/axios/Testimonials";
import { Popup } from "../../../components/CustomInput/popup";
import { SubmitButton } from "../../../components/submit.button";
import { ImgSrcEnums } from "../../../enums";

const MentorProfile = ({ isPublicProfile }) => {
  const { dashboardTranslations, formElementLabels } = useIgurooTranslations();
  const location = useLocation();
  const [user, setuser] = useState();
  const [wrkExp, setWrkExp] = useState([]);
  const [edu, setEdu] = useState([]);
  const { mentorId } = useParams();
  const [searchParams] = useSearchParams();
  const userInfo = useSelector((state) => state.user);
  const [testimonials, setTestimonials] = useState([]);
  const [testimonialText, setTestimonialText] = useState("");
  const [newTestimonials, setNewtestimonials] = useState([]);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: { latestTestimonials: newTestimonials },
  });
  const [show, setShow] = useState(false);
  const [avail, setAvail] = useState([]);
  const [mentorRate, setMentorRate] = useState(RatesTimeFrame.perSession);
  const [mentorRatePerSession, setMentorRatePerSession] = useState({});
  const [rating, setRating] = useState(0);
  const [session, setSession] = useState("");
  const [imgLink, setImgLink] = useState("");
  const [newImgLink, setNewImgLink] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [newVideoLink, setNewVideoLink] = useState("");
  const [bookmark, setBookmark] = useState(false);

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const userType = useMemo(() => {
    return authContext?.sessionInfo?.role?.toUpperCase();
  }, [authContext.authStatus]);

  const menteeId = useMemo(() => {
    return authContext?.sessionInfo?.sub;
  }, [authContext.authStatus]);
  console.log(menteeId, "menteeID");
  console.log(user, "userID");
  const dispatch = useDispatch();
  console.log(testimonials, "testimonilas-test");

  const [notesText, setNotesText] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handlePopupClose = async (data) => {
    console.log(data, "data-data");
    const request = {
      mentorId: user?.userId,
      userId: menteeId,
      testimonialText: data.latestTestimonials[0].testimonialText,
    };
    console.log("testimonials", request);
    try {
      const res = await postTestimonials(request);
      console.log("checking-testimonials", res);
      setShowPopup(false);
      setShowSecondPopup(true);
    } catch (err) {
      console.log("err", err);
    }
    setShowPopup(false);
  };

  const handleCardClick = () => {
    setShowPopup(true);
  };
  useEffect(() => {
    if (newTestimonials) {
      reset({ latestTestimonials: newTestimonials });
    }
  }, [newTestimonials]);
  console.log("newTest", newTestimonials)
  useEffect(() => {
    if (showPopup) {
      try {
        const testimonialsResponse = async () => {
          const res = await getMentorMenteeTestimonials(
            searchParams.get("mentorId"),
            menteeId
          );
          console.log(res, "testimoanil,,,,");
          setNewtestimonials(res?.Items);
        };
        testimonialsResponse();
      } catch (err) {
        console.log("testimonials:", err);
      }
    }
  }, [showPopup]);
  const handleSecondPopupClose = () => {
    setShowSecondPopup(false);
  };

  useEffect(() => {
    // (async function () {
    //   const thePath = location.search;
    //   const lastItem = thePath.substring(thePath.lastIndexOf("=") + 1);
    //   const userdata = await GetUserProfile(lastItem);
    //   const workExp = await GetWorkExp(lastItem);
    //   const education = await GetEducation(lastItem);
    //
    //   console.log(workExp, "workExp");
    //   setWrkExp(workExp?.data);
    //   setEdu(education?.data);
    //   setuser(userdata?.data);

    //   // if (localStorage.getItem("bookSession")) {
    //   //   setShow(true);
    //   // }

    //   getTestimonials(3)
    //     .then((responce) => {
    //       if (responce.data) {
    //         setTestimonials(responce.data);
    //       }
    //     })
    //     .catch((err) => console.log(err));
    // })();

    const thePath = location.search;
    const lastItem = thePath.substring(thePath.lastIndexOf("=") + 1);
    //console.log("public mentor last ", lastItem, isPublicProfile, thePath, "location", location);
    if (lastItem && !isPublicProfile) {
      dispatch(getUserAvailabilityAsyncThunk({ userId: lastItem }));
      getPrivateMentor(lastItem, authContext.sessionInfo.sub)
        .then((res) => {
          setWrkExp(res?.workHistory);
          setEdu(res?.educationHistory);
          setuser(res?.profile);
          setBookmark(res?.isBookmarked);
          if (res?.profile?.rates)
            setMentorRatePerSession(res?.profile?.rates[0]);
        })
        .catch((err) => console.log(err));

      // getMentorTestimonials(lastItem)
      //   .then((responce) => {
      //     if (responce.data) {
      //       setTestimonials(responce.data);
      //     }
      //   })
      //   .catch((err) => console.log(err));

      GetUserAvailability(lastItem)
        .then((responce) => {
          if (responce.data) {
            setAvail(responce.data);
          }
        })
        .catch((err) => console.log(err));

      getRating(lastItem)
        .then((res) => {
          setRating(res?.data?.response);
        })
        .catch((err) => console.log(err));

      GetTotalSessions(lastItem).then((resp) => {
        setSession(resp);
      });
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (mentorId && userType !== ProfileType.admin) {
      GetPublicMentorAsync(mentorId)
        .then((val) => {
          console.log("public mentor", val);
          setWrkExp(val?.workHistory);
          setEdu(val?.educationHistory);
          setuser(val?.profile);
          if (val?.profile?.rates)
            setMentorRatePerSession(val?.profile?.rates[0]);
          setSession(val?.session);
          setRating(val?.rating);
        })
        .catch((err) => {
          console.log("public mentor error", err);
        });
    } else if (mentorId) {
      getPrivateMentor(mentorId, authContext.sessionInfo.sub)
        .then((res) => {
          setWrkExp(res?.workHistory);
          setEdu(res?.educationHistory);
          setuser(res?.profile);
          setBookmark(res?.isBookmarked);
          if (res?.profile?.rates)
            setMentorRatePerSession(res?.profile?.rates[0]);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  function handleBook() {
    if (authContext.authStatus === AuthStatus.SignedOut) {
      localStorage.setItem("bookSession", true);
      localStorage.setItem("mentorId", mentorId);
      navigate(`${AppUrlsEnum.menteeMentorProfile}?mentorId=${mentorId}`);
    } else {
      setShow(true);
    }
  }

  useEffect(() => {
    if (authContext.authStatus === AuthStatus.SignedIn) {
      localStorage.removeItem("bookSession");
      localStorage.removeItem("mentorId");
    }
  }, [authContext]);

  const onbookmarked = async () => {
    // const form = formData;
    // // setFormData({ type: ProfileType.mentor});
    // setloading(true);
    console.log(user, "callStart");
    const searchData = await BookMarkAPI(
      authContext.sessionInfo.sub,
      user.userId,
      !bookmark
    );
    setBookmark(!bookmark);
  };
  const handleCard = (index, rate) => {
    setMentorRate(rate.timeFrame);
    setMentorRatePerSession(rate);
    localStorage.setItem("defaultRate", rate.timeFrame);
  };

  const handleDefaultRate = (value) => {
    localStorage.setItem("defaultRate", value);
    setMentorRate(value);
  };

  useEffect(() => {

    if (user?.profilePic) {
      setImgLink(user.profilePic);
    }
    if (user?.newProfilePic) {
      setNewImgLink(user.newProfilePic);
    }
    if (user?.profileVideoLink) {
      setVideoLink(user.profileVideoLink);
    }
    if (user?.newProfileVideoLink) {
      setNewVideoLink(user.newProfileVideoLink);
    }
    if (user)
      setIsLoading(false);
  }, [user]);

  useEffect(() => {
    const fetchTestimonials = async (mentorId) => {
      console.log(mentorId, "mentorId-for-mentor");
      try {
        const response = await getMentorTestimonials(mentorId);
        // console.log('Testimonials data', response);
        setTestimonials(response.Items);
      } catch (error) {
        // console.log('Error fetching testimonials', error);
      }
    };
    if (user?.userId) {
      fetchTestimonials(user.userId);
    }
  }, [user?.userId]);

  return (
    <Container style={{ fontFamily: "manrope" }}>
      {/* <Container className='m-0 p-0 justify-content-center'>
                <Breadcrum classes='d-flex justify-content-center' />
            </Container> */}

      {userType === ProfileType.admin && (
        <div className="pt-3">
          <Link className="back-link mt-3" to={AppUrlsEnum.adminUsers}>
            Back to Approval Management
          </Link>
        </div>
      )}
      <Container className="m-0 p-0 d-flex justify-content-center mt-3">
        {/* <Image
          className="mentor-profile-img d-block"
          height={200}
          width={200}
          src={user?.profilePic ? user?.profilePic : avatar}
        />
         */}
        {!isLoading ? (userType === ProfileType.admin ? (
          <ProfileImage
            isPublicProfile={isPublicProfile}
            className="mentor-profile-img d-block"
            src={newImgLink ? newImgLink : imgLink}
            alt="Profile Pic"
            height={200}
            width={200}
          />
        ) : (<>{console.log(newImgLink, imgLink, isLoading, 'profile finalData')}
          <ProfileImage
            isPublicProfile={isPublicProfile}
            className="mentor-profile-img d-block"
            src={imgLink ? imgLink : ''}
            alt="Profile Pic"
            height={200}
            width={200}
          /></>
        )):
        <ProfileImage
        isPublicProfile={isPublicProfile}
        className="mentor-profile-img d-block"
        src={imgLink}
        alt="Profile Pic"
        height={200}
        width={200}
      />}
        <div className="green-circle"></div>
      </Container>
      <Container className="m-0 p-0 d-flex justify-content-center mt-2">
        <div className="name-firstletter">
          <h3>
            {user?.firstName} {user?.lastName}
          </h3>
        </div>
        <div className="mt-1 ms-1">
          {user?.isTrusted && <Image src={tick_mark} height={25} width={25} />}
        </div>
      </Container>
      <Container className="m-0 p-0">
        <div className="text-center">
          <p className="m-0 p-0 fs-14">
            {user?.location?.city?.city}, {user?.location?.state?.state},{" "}
            {user?.location?.country?.country}
            {/* {user?.timeZone?.value} */}
          </p>
          <p className="m-0 p-0 fs-14">
            <Image src={globe} height={15} width={15} />{" "}
            {user?.languages?.map((language, i) =>
              user?.languages?.length === i + 1
                ? language?.value
                : `${language?.value}, `
            )}
          </p>
          <p className="m-0 p-0 fs-18 fw-700">{user?.profileTitle}</p>
        </div>
        <div className="d-md-flex justify-content-center mt-2">
          {user?.areaOfExpertise?.map((area) => (
            <CustomBadge text={area?.value} type="mt-1 fs-15 fw-400 orange" />
          ))}
        </div>
      </Container>
      <Container className="m-0 p-0 d-md-flex justify-content-center my-5">
        {user?.mentoringFrom?.value ? (
          <>
            <div className="m-2">
              <Image className="me-2 mb-2" height={25} width={20} src={users} />
              <p className="d-inline">
                <h4 className="fw-700 d-inline">
                  {calculate_age(user?.mentoringFrom?.value)}
                </h4>{" "}
                <sub>{dashboardTranslations?.yearsMentoring}</sub>
              </p>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="m-2">
          <Image className="me-2 mb-2" height={25} width={20} src={clock} />
          <p className="d-inline">
            <h4 className="fw-700 d-inline">{session}</h4>{" "}
            <sub>{dashboardTranslations?.sessionHeld}</sub>
          </p>
        </div>
        <div className="m-2 d-flex">
          {/* <h4 className="fw-700">{rating ? rating : 0}</h4>
          <Image className="me-2 mb-2 ms-2" height={25} width={20} src={star} /> */}
          {/* <p className="d-inline"> */}
          {/* <h4 className="fw-700 d-inline">{user?.profileLikes}</h4>{" "} */}
          {/* <sub>{dashboardTranslations?.likes}</sub> */}
          {/* </p> */}
          <Rating
            placeholderRating={rating ? rating : 0}
            readonly={true}
            emptySymbol={
              <img
                height={25}
                width={25}
                src={_getImage("empty_star.svg")}
                className="icon"
              />
            }
            placeholderSymbol={
              <img
                height={25}
                width={25}
                src={_getImage("full_star.svg")}
                className="icon"
              />
            }
            fullSymbol={
              <img
                height={25}
                width={25}
                src={_getImage("full_star.svg")}
                className="icon"
              />
            }
          />
        </div>
      </Container>
      <Container className="m-0 p-0 mt-3 d-md-flex justify-content-center">
        {user?.rates?.map((rate, i) => {
          let title = "";
          const sessionType = getSessionType(rate?.timeFrame);
          if (rate?.timeFrame == RatesTimeFrame.perSession) {
            title =
              sessionType.charAt(0).toUpperCase() +
              sessionType.slice(1) +
              " Session (" +
              rate?.time +
              ")";
          } else {
            title =
              sessionType.charAt(0).toUpperCase() +
              sessionType.slice(1) +
              " (" +
              rate?.timeFramePerSession +
              " Sessions)";
          }

          return (
            <Card
              className={`p-2 mx-md-2 my-2 w-100 w-md-25 ${rate.timeFrame === mentorRate ? "primary-border" : ""
                }`}
              onClick={() => handleCard(i, rate)}
            >
              <Card.Body className="p-0">
                <p className="p-o m-0 fs-11 secondary-text">
                  {title}{" "}
                  {rate.timeFrame === mentorRate ? (
                    <Image
                      height={15}
                      width={15}
                      src={check_circle}
                      className="float-end"
                    />
                  ) : (
                    <></>
                  )}
                </p>
                <p className="m-0 p-0 fw-400">
                  {rate?.rate > 0
                    ? `${rate?.curreny} ${rate?.rate}`
                    : `${formElementLabels?.placeHolders?.free}`}
                </p>
              </Card.Body>
            </Card>
          );
        })}
      </Container>
      <Container className="m-0 p-0 pe-2 mt-4 d-md-flex justify-content-center">
        <div className="my-2">
          <Button
            className="primary-dark-text mentor-btn me-1"
            variant="light"
            onClick={onbookmarked}
          >
            <Image
              className="me-2"
              height={25}
              width={15}
              src={
                !bookmark ? primary_bookmark : _getImage("bookmarkedicon.svg")
              }
            />
            {bookmark
              ? dashboardTranslations?.bookmarked
              : dashboardTranslations?.bookMark}
          </Button>
        </div>
        <div className="d-flex justify-content-end align-items-center flex-grow-1">
          <Button
            className="float-md-center card-testimonilas "
            // onClick={()=>{handleCardClick(mentorId,menteeId)}}
            onClick={handleCardClick}
          >
            {/* Share your thoughts about {user?.firstName} {user?.lastName} */}
            {dashboardTranslations?.testimonialHeading}
          </Button>
        </div>

        <Modal
          show={showPopup}
          onHide={() => setShowPopup(false)}
          className="modal-border"
        >
          <Modal.Header closeButton>
            <ModalTitle className="testmonials-head w-100">
              {dashboardTranslations?.testimonialHeading}
            </ModalTitle>
          </Modal.Header>
          <Modal.Body className="">
            <Form onSubmit={handleSubmit(handlePopupClose)}>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => {
                  console.log(value, "testimonilas");
                  return (
                    <CustomInputField
                      className="card-testimonilas"
                      placeholder={formElementLabels?.placeHolders?.testimonialsPH}
                      hookOnChange={onChange}
                      hookOnBlur={onBlur}
                      hookValue={value}
                      rows={5}
                      formAs={"textarea"}
                    />
                  );
                }}
                name={`latestTestimonials.${0}.testimonialText`}
              />
              <div className=" mt-4 d-flex justify-content-center align-items-center">
                <SubmitButton variant="primary" type="submit" text={formElementLabels?.save} />
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        {showSecondPopup && (
          <Popup
            closeOnBackClick={false}
            showPopup={showSecondPopup}
            type="popup"
            text={formElementLabels?.buttonSave}
            modalBody={dashboardTranslations?.testimonialDesc}
            gotIt={handleSecondPopupClose}
          />
        )}
        {/* <div className="my-2">
          <Button className="primary-dark-text mentor-btn mx-1" variant="light">
            <Image className="me-2" height={15} width={15} src={share} />
            {dashboardTranslations?.share}
          </Button>
        </div>
        <div className="my-2">
          <Button className="primary-dark-text mentor-btn mx-1" variant="light">
            <Image
              className="me-2"
              height={15}
              width={15}
              src={primary_message}
            />
            {dashboardTranslations?.sendMessage}
          </Button>
        </div> */}
        <div className="flex-grow-1 my-2">
          {userType !== ProfileType.admin && (
            <Button
              onClick={handleBook}
              className="primary-dark-text float-md-end book-now-btn"
              variant="light"
            >
              {dashboardTranslations.bookNow}
            </Button>
          )}
        </div>
      </Container>
      <Container className="mt-3">
        <p className="fs-18 fw-800">
          <Image height={20} width={20} src={user1} className="" />
          {dashboardTranslations?.about}
        </p>
        <p className="">{user?.bio}</p>
      </Container>
      {user?.profileVideoLink || user?.newProfileVideoLink ? (
        <Container
          className="px-md-5 py-3"
          style={
            authContext.authStatus === AuthStatus.SignedOut
              ? { width: "70%" }
              : { width: "100%" }
          }
        >
          {userType === ProfileType.admin ? (
            <CustomVideo
              videoLink={user?.isProfileVidApproved ? videoLink : newVideoLink}
            />
          ) : (
            <CustomVideo
              isPublicProfile={isPublicProfile}
              videoLink={user?.profileVideoLink}
            />
          )}
        </Container>
      ) : (
        ""
      )}
      <Container className="mt-3 mb-3">
        <Row className="mentee-details">
          <Col sm={6} xs={12}>
            <div className="d-flex">
              <Image height={20} width={20} src={brief_case} />
              <h6 className="ms-2 fs-18 fw-700">
                {dashboardTranslations?.workExp}
              </h6>
            </div>
            <ul>
              {wrkExp?.map((work) => (
                <li>
                  {work?.years || work?.year} years as {work?.role} at{" "}
                  {work?.company}
                </li>
              ))}
            </ul>
          </Col>
          <Col sm={6} xs={12}>
            <div className="d-flex">
              <Image height={20} width={20} src={book_open} />
              <h6 className="ms-2 fs-18 fw-700">
                {dashboardTranslations?.education}
              </h6>
            </div>
            <ul>
              {edu?.map((education) => (
                <li>
                  <span className="fw-600">{education?.instituteName}</span>,{" "}
                  {education?.specialization?.value}, {education?.year?.value}
                </li>
              ))}
            </ul>
          </Col>
          {/* <Col sm={4} xs={12}>
            <Card>
              <Card.Body>
                  {edu?.map((education) => (
                    <div>
                      <span className="fw-600">{education?.instituteName}</span>
                      , {education?.specialization?.value},{" "}
                      {education?.year?.value}
                    </div>
                  ))}
                     <div className="m-0 p-0 d-flex justify-content-center mt-5">
                  {userType === ProfileType.admin ? (
                    <ProfileImage
                      isPublicProfile={isPublicProfile}
                      className="mentor-profile-img d-block"
                      src={user?.isProfilePicApproved ? imgLink : newImgLink}
                      alt="Profile Pic"
                      height={45}
                      width={45}
                    />
                  ) : (
                    <ProfileImage
                      isPublicProfile={isPublicProfile}
                      className="mentor-profile-img d-block"
                      src={imgLink}
                      alt="Profile Pic"
                      height={45}
                      width={45}
                    />
                  )}
                  </div>
                  <div className="name-firstletter m-0 p-0 d-flex justify-content-center mt-2">
                    <h3 className="mentor-name-font">
                      {user?.firstName} {user?.lastName}
                    </h3>
                  </div>
              </Card.Body>
            </Card>
          </Col> */}
        </Row>
      </Container>
      <Container>
        <Row className="d-flex my-2">
          {testimonials
            ?.sort((a, b) => b.updatedAt - a.updatedAt)
            .map((testimonial, i) =>
              i <= 3 &&
                testimonial &&
                testimonial.menteeDetail &&
                testimonial.menteeDetail[0] ? (
                <Col sm={12} md={4} key={testimonial.testimonialId}>
                  <Card className="h-100">
                    <Card.Body>
                      <p>{testimonial.testimonialText}</p>
                    </Card.Body>
                    <div className="d-flex justify-content-center my-4">
                      {userType === ProfileType.admin &&
                        !testimonial?.menteeDetail[0]?.isProfilePicApproved ? (
                        <ProfileImage
                          isPublicProfile={isPublicProfile}
                          className="mentor-profile-img d-block"
                          src={testimonial?.menteeDetail[0]?.newImgLink}
                          alt="Profile Pic"
                          height={60}
                          width={60}
                        />
                      ) : (
                        <ProfileImage
                          isPublicProfile={isPublicProfile}
                          className="mentor-profile-img d-block"
                          src={testimonial?.menteeDetail[0]?.profilePic}
                          alt="Profile Pic"
                          height={60}
                          width={60}
                        />
                      )}
                    </div>
                    <p className="p-0 m-0 text-center fs-18 fw-800 mb-3">
                      {testimonial?.menteeDetail[0]?.firstName}{" "}
                      {testimonial?.menteeDetail[0]?.lastName}
                    </p>
                  </Card>
                </Col>
              ) : (
                ""
              )
            )}
        </Row>
      </Container>
      <BookSession
        show={show}
        {...user}
        onHide={(show) => setShow(show)}
        primaryBtn={"Book"}
        secondaryBtn={"Cancel"}
        avail={avail}
        handleDefaultRate={handleDefaultRate}
        rating={rating}
        sessionsHeld={session}
      />
    </Container>
  );
};

export default MentorProfile;
