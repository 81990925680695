//** Progress Bar for after completed the assessment,calculated the answers and displayed in AssessmentCard for Iguroo**//

import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Row, Col } from "react-bootstrap";

const Progress = ({ className, value, text }) => {
  return (
    <Row className="cent">
      <Col className="progress_text">{text}</Col>
      <Col>
        <ProgressBar className={`${className} c_progress`} now={value} />
      </Col>
      <Col>{value}%</Col>
    </Row>
  );
};

export default Progress;
