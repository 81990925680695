//** ShowMore can add additional Fields above UI,can use this common Component required Field **//

import React, { Fragment, useState } from "react";

const ShowMore = ({
  data = "",
  showMore = "show more",
  showLess = "show less",
  textLength = 180,
}) => {
  const [expanded, setExpanded] = useState(false);
  const dataForDisplay = expanded ? data : data.slice(0, textLength);
  return data.length > 100 ? (
    <Fragment>
      {dataForDisplay}
      <a className="ms-2 link-primary" onClick={() => setExpanded(!expanded)}>
        {expanded ? showLess : showMore}
      </a>
    </Fragment>
  ) : (
    data
  );
};

export default ShowMore;
