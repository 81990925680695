//** GinieHealth Learner Login,LinearScale QuestionType UI component **//

export const LinearScale = ({ type, control }) => {
  return (
    <>
      <p>1. Label(Optional)</p>
      <p>10. Label(Optional)</p>
    </>
  );
};
