//** Iguroo Assessment Progress Component**//

import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Row, Col } from "react-bootstrap";

const Progress = ({ className, value, text }) => {
  return (
    <Row className="progress_div">
      <Col md={6} className="progress_text">
        <span className="ms-2">{text}</span>
      </Col>
      <Col ms={5} className="p-0">
        <ProgressBar className={`${className} c_progress`} now={value} />
      </Col>
      <Col md={1} className="d-flex align-content-center">
        <p className="percentage_text d-inline">{value}%</p>
      </Col>
    </Row>
  );
};

export default Progress;
