import * as yup from "yup";

export function priceFormValidator(formErrors) {
  return yup.object({
    isFree: yup.string(),
    price: yup.string().when("isFree", {
      is: (val) => val === "Paid",
      then: () => yup.string().required(),
    }),
    price_currency: yup.string().when("isFree", {
      is: (val) => val === "Paid",
      then: () => yup.string().required("*Required"),
    }),
  });
}

export function logicBuilderValidation(formErrors) {
  return yup.object().shape({
    assessment_template: yup
      .string()
      .required("Assessment Template is required"),
    transcript: yup.string().required("Transcript Template is required"),
    transcript_template: yup
      .string()
      .required("Select a valid Transcript Template"),
    evaluation_type: yup
      .string()
      .required("Select a valid Assessment Template"),
    transcript_download: yup.string().optional(),
    custom_logic: yup.string().optional(),
    custom_report: yup.string().optional(),
  });
}

export function certificationValidation(formErrors) {
  return yup.object({
    certification_type: yup
      .string()
      .required("please choose appropriate option"),
    certification_url: yup.string().when("certification_type", {
      is: (val) => val === "Is Certification Assessment",
      then: () => yup.string().required("*Required"),
    }),
    validity_month: yup.string().when("certification_type", {
      is: (val) => val === "Is Certification Assessment",
      then: () => yup.string().required("*Required"),
    }),
    validity_year: yup.string().when("certification_type", {
      is: (val) => val === "Is Certification Assessment",
      then: () => yup.string().required("*Required"),
    }),
  });
}

export function authorValidation(formErrors) {
  return yup.object({
    author_name: yup.string().required(),
    access_type: yup.string().required(),
  });
}
