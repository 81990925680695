//** Onboarding Education Page **//

import React, { useContext, useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Form, Row, Col, Container } from "react-bootstrap";
import { allDataConverter, getStepsObject } from "../../helpers";
import { useNavigate } from "react-router-dom";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { MentorSetupSidebar } from "../../components/mentor.setup.sidebar";
import { SubmitButton } from "../../components/submit.button";
import { CustomAddMoreBtn } from "../../components/CustomInput/customAddMoreBtn";
import { AppUrlsEnum, OnboardingSteps, ProfileType } from "../../enums";
import { GetDataType } from "../../services/axios/staticData";
import { useDispatch, useSelector } from "react-redux";
import { loader } from "../../redux-tk/slices/loader.slice";
import Toaster from "../../components/toaster";
import { toast } from "react-toastify";
import {
  deleteEducationAsyncThunk,
  educationAction,
  educationValue,
  getEducationAsyncThunk,
} from "../../redux-tk/slices/mentor.onboarding.data";
import { Heading } from "../../components/heading.component";
import { AlertComponent } from "../../components/alert.component";
import { EducationComponent } from "../../components/education.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { getEducationFormTranslated } from "../../FormSchemas";
import { AuthContext } from "../../hooks/auth.context.provider";
import { PostEduExp } from "../../services/axios/MentorOnbaording";
import { languageValue } from "../../redux-tk/slices/general.info.slice";
import { postUserProfileAsyncThunk } from "../../redux-tk/slices/user.slice";
import { OnboardingHeading } from "../../components/onboarding.heading.component";
import { FormErrorComponent } from "../../components/form.error.component";
import { showPopupflag } from "../../redux-tk/slices/showPopupflag.slice";
require(`../../css/${process.env.REACT_APP_CSS_MENTOR}`);

export const EDUCATION_TEMPLATE = {
  id: "-1",
  instituteName: "",
  specialization: "",
  courseName: "",
  year: "",
};

export default function EducationScreen({ settings }) {
  const {
    mentorOnboardingLabels,
    formElementLabels,
    formErrors,
    headingTranslations,
    headerFooterTranslations,
  } = useIgurooTranslations();
  const dispatch = useDispatch();
  const selectedValue = useSelector((state) => educationValue(state));
  const selectedLanguage = useSelector((state) => languageValue(state));
  const authContext = useContext(AuthContext);
  const {
    control,
    reset,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: { edu: selectedValue },
    resolver: yupResolver(getEducationFormTranslated(formErrors)),
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "edu", // unique name for your Field Array
      shouldUnregister: true,
    }
  );
  const navigate = useNavigate();
  const [degreeCertificates, setDegreeCertificateData] = useState([]);
  const [fieldData, setfield] = useState([]);
  const [yearData, setYear] = useState([]);

  const onSubmit = async (data) => {
    //console.log("data", data);
    dispatch(loader(true));
    const finalData = allDataConverter.removeDefaultId(data.edu);
    const result = await PostEduExp(authContext.sessionInfo.sub, finalData);
    console.log("re'", result);
    dispatch(educationAction(result.data));
    if (!settings) {
      dispatch(
        postUserProfileAsyncThunk(
          getStepsObject(
            OnboardingSteps[ProfileType.mentor][4].value,
            authContext.sessionInfo.sub
          )
        )
      );
    }
    dispatch(loader(false));
    Toaster(toast.success, headerFooterTranslations.toasterMsgOnboarding);
    if (!settings) {
      navigate(AppUrlsEnum.mentorAvailability);
    }
  };

  useEffect(() => {
    if (isDirty) {
      (async function () {
        dispatch(showPopupflag(true));
      })();
    }
  }, [isDirty]);

  useEffect(() => {
    (async function () {
      dispatch(loader(true));
      dispatch(getEducationAsyncThunk({ userId: authContext.sessionInfo.sub }));
      const qItems = await GetDataType("qualification", selectedLanguage);
      setDegreeCertificateData(qItems);
      const endOfYearItems = await GetDataType("endyear", selectedLanguage);
      setYear(endOfYearItems);
      const fieldItems = await GetDataType("fieldOfStudy", selectedLanguage);
      setfield(fieldItems);
      dispatch(loader(false));
    })();
  }, []);

  useEffect(() => {
    if (selectedValue[0]?.id != -1) {
      reset({ edu: selectedValue });
    }
  }, [selectedValue]);

  const removeEducationInputs = (inputIndex) => {
    if (fields.length > 1) {
      const value = getValues(`edu.${inputIndex}.id`);
      if (value === "-1" || value === -1) {
        remove(inputIndex);
        return;
      }
      //console.log('dele"',field,value);
      dispatch(deleteEducationAsyncThunk({ id: value }));
    }
  };

  const addEducationInputs = () => {
    const wet = { ...EDUCATION_TEMPLATE };
    append(wet);
  };

  return (
    <div>
      {!settings && <OnboardingHeading profileType={ProfileType.mentor} />}
      <Container className="px-3">
        <Row>
          <Col md="12" lg={settings ? 12 : 9}>
            {!settings && (
              <>
                <Heading>{headingTranslations?.mentorEducation}</Heading>
                <AlertComponent showIcon={true}>
                  {headingTranslations?.mentorEducationAlert}
                </AlertComponent>
              </>
            )}
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                {fields.map((field, index) => {
                  return (
                    <EducationComponent
                      key={field.id}
                      removeFunction={removeEducationInputs}
                      hookControl={control}
                      profileType={ProfileType.mentor}
                      itemIndex={index}
                      errors={errors}
                      certificateItems={degreeCertificates}
                      fieldOfStudyItems={fieldData}
                      endOfYearItems={yearData}
                    />
                  );
                })}
                <Row className="mt-3">
                  <Col md="4" className="d-grid d-md-block">
                    <CustomAddMoreBtn
                      onClick={() => {
                        addEducationInputs();
                      }}
                    />
                  </Col>
                  <Col md="8"></Col>
                </Row>
              </Form.Group>

              <Row className="mt-1 mb-3">
                <FormErrorComponent>
                  {errors.apiError && errors.apiError?.message}
                </FormErrorComponent>
              </Row>

              <hr />
              <Row className="text-end mb-4 justify-content-end save-btn-end">
                <Col sm="12" md="2" className="d-grid ">
                  <SubmitButton type="submit" text={formElementLabels?.save} />
                </Col>
              </Row>
            </Form>
          </Col>
          {!settings && (
            <Col>
              {mentorOnboardingLabels?.rightSetupModule?.mentor && (
                <MentorSetupSidebar
                  typeToUse={mentorOnboardingLabels?.rightSetupModule?.mentor}
                  active={
                    mentorOnboardingLabels?.rightSetupModule?.mentor?.values
                      ?.edu
                  }
                />
              )}
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}
