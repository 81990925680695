import * as yup from "yup";
export function getPublisherSettingsFormTranslated(formErrors) {
  return yup.object({
    isTimeBoxed: yup.string().required(formErrors?.fieldRequired),
    duration: yup.number().when("isTimeBoxed", {
      is: (val) => val === "Yes",
      then: () =>
        yup
          .number()
          .min(1, "minimum allowed value is 1")
          .max(1440, "maximum allowed value is 1440")
          .required(formErrors?.fieldRequired)
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          ),
    }),
    min_duration_expected: yup.number().when("isTimeBoxed", {
      is: (val) => val === "Yes",
      then: () =>
        yup
          .number()
          .min(1, "minimum allowed value is 1")
          .max(1440, "maximum allowed value is 1440")
          .required(formErrors?.fieldRequired)
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          ),
    }),
    max_duration_expected: yup.number().when("isTimeBoxed", {
      is: (val) => val === "Yes",
      then: () =>
        yup
          .number()
          .min(1, "minimum allowed value is 1")
          .max(1440, "maximum allowed value is 1440")
          .required(formErrors?.fieldRequired)
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          ),
    }),

    cutoff_score: yup
      .number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .required("This field is required")
      .typeError("This field is required"),
    isRadomizeQuestions: yup.string().required(formErrors?.fieldRequired),
    isRadomizeAnswers: yup.string().required(formErrors?.fieldRequired),
    isResetAllowed: yup.string().required(formErrors?.fieldRequired),
    attempts_allowed: yup
      .number()
      .min(1, "Minimum allowed value is 1")
      .max(99, "Maximum allowed value is 99")
      .required(formErrors?.fieldRequired)
      .typeError("This field should be a number"),

    valid_till: yup.date().required(formErrors?.fieldRequired),
  });
}
