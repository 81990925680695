//** Calender Logics(getSession,GreyedOut,RequestDate) Hooks **//

import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSessionsInDates,
  getRequestSessoinAsyncThunk,
  getSessionBetweenDatesAsyncThunk,
  getUserProfileSessionAsyncThunk,
} from "../redux-tk/slices/session.slice";
import moment from "moment";
import {
  dateToTimestamp,
  getReduxCalendarDateExists,
  timeStampToDate,
} from "../helpers";

export default function useGetCalendarSessions({ isByUser, userId }) {
  const dispatch = useDispatch();
  const sessionsBetweenDates = useSelector((state) =>
    getAllSessionsInDates(state)
  );
  const [currentSession, setCurrentSessions] = useState({
    sessions: [],
    listTitle: null,
  });
  const [currentDate, setCurrentDate] = useState(new Date());

  const onDateChange = useCallback(
    async (date) => {
      const input = document.getElementById("ig-session-card");
      console.log(input, "console ig-session-card getElementById");
      setTimeout(
        () =>
          input?.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          }),
        300
      );
      setCurrentDate(date);
    },
    [sessionsBetweenDates, setCurrentSessions, setCurrentDate]
  );

  useEffect(() => {
    mainLogic(currentDate);
  }, [currentDate, sessionsBetweenDates]);

  const onMonthChange = useCallback(
    (year, month, from, to) => {
      // console.log("from", from.getTime(), "to callstart", to.getTime(),to);
      let endDate = moment(to).endOf("month");
      endDate = endDate.toDate();
      //  console.log('end callstart' + moment(to).endOf('month'),  endDate)
      dispatch(
        getSessionBetweenDatesAsyncThunk({
          isByUser: isByUser,
          userId: userId,
          startDate: dateToTimestamp(from),
          endDate: dateToTimestamp(endDate),
          year: year,
          month: month,
        })
      );
    },
    [dispatch, getSessionBetweenDatesAsyncThunk, isByUser]
  );

  const isHighlighted = useCallback(
    (date) => {
      //console.info("highlight called", date, date.getMonth());
      // highlight any data that is divisible by 5
      const year = date.getFullYear();
      const month = date.getMonth();
      //console.log("isHighlight", year, month, sessionDates);
      const sessions = getReduxCalendarDateExists(
        sessionsBetweenDates,
        year,
        month
      );
      if (sessions) {
        //console.log("isHighlight if");
        //const sessions = sessionsBetweenDates[year][month];
        for (let i = 0; i < sessions.length; i++) {
          const session = sessions[i];
          const sessionStartTime = timeStampToDate(session.startTime);
          //console.log("converted date",sessionStartTime);
          if (sessionStartTime.getDate() === date.getDate()) {
            return true;
          }
        }
      }
    },
    [sessionsBetweenDates]
  );

  const isGreyed = useCallback(
    (date) => {
      const year = date.getFullYear();
      const month = date.getMonth();
      const curDate = Date.now();
      const newDate = new Date();
      if (newDate.getDate() === date.getDate()) {
        return false;
      }
      if (newDate.getDate() === date.sess) {
      }
      if (date.getTime() >= curDate) {
        return false;
      }
      const sessions = getReduxCalendarDateExists(
        sessionsBetweenDates,
        year,
        month
      );
      if (sessions) {
        for (let i = 0; i < sessions.length; i++) {
          const session = sessions[i];
          const sessionStartTime = timeStampToDate(session.startTime);

          if (
            curDate > date.getTime() &&
            sessionStartTime.getDate() === date.getDate()
          ) {
            return true;
          }
        }
      }
      return false;
    },
    [sessionsBetweenDates]
  );

  const mainLogic = useCallback(
    async (date) => {
      const year = date.getFullYear();
      const month = date.getMonth();
      const selectedSessions = [];
      let listTitle = null;
      const sessions = getReduxCalendarDateExists(
        sessionsBetweenDates,
        year,
        month
      );
      if (sessions) {
        const currentDate = new Date();
        for (let i = 0; i < sessions.length; i++) {
          const session = sessions[i];
          const sessionStartTime = timeStampToDate(session.startTime);
          const sessionEndTime = timeStampToDate(session.endTime);
          const momentStart = moment(sessionStartTime);
          const sTime = moment(session.startTime);
          const eTime = moment(session.endTime);
          const hoursDiff = moment.duration(sTime.diff(eTime)).asHours();
          const minDiff = moment.duration(sTime.diff(eTime)).minutes();
          const momentEnd = moment(sessionEndTime);
          const timeDiffs = moment.duration(momentEnd.diff(momentStart));
          const timeDiff = momentEnd.diff(momentStart, "minutes");
          if (sessionStartTime.getDate() === date.getDate()) {
            const req = session.request;
            const user = session.user;
            const mentor = session.mentor;
            // console.log(session,momentEnd.diff(momentStart),momentStart.diff(momentEnd, "minutes"),'momentEnd.diff(momentStart)')
            if (!user && !isByUser) {
              dispatch(
                getUserProfileSessionAsyncThunk({
                  year: year,
                  month: month,
                  sessionNumber: session.sessionNumber,
                  isUser: true,
                  userId: session.userId,
                })
              );
            }
            if (!mentor && isByUser) {
              dispatch(
                getUserProfileSessionAsyncThunk({
                  year: year,
                  month: month,
                  sessionNumber: session.sessionNumber,
                  isUser: false,
                  userId: session.mentorId,
                })
              );
            }
            if (!req) {
              dispatch(
                getRequestSessoinAsyncThunk({
                  year: year,
                  month: month,
                  sessionNumber: session.sessionNumber,
                  requestNumber: session.requestNumber,
                })
              );
            }
            //console.log("request", req);
            //console.log("user name")
            const userToUse = isByUser ? mentor : user;
            if (!listTitle) {
              listTitle = moment(sessionStartTime).format("dddd, DD MMMM");
            }
            const conSession = {
              session: {
                //date: sessionStartTime.toDateString(),
                grey: currentDate > sessionEndTime,
                time: momentStart.format("HH:mm"),
                timeLeft: `${timeDiff}`,
              },
              mentors: [
                {
                  name: userToUse?.firstName
                    ? `${userToUse?.firstName} ${userToUse?.lastName}`
                    : "",
                  subtext: req?.title,
                  picture: userToUse?.profilePic ? userToUse.profilePic : null,
                  userId: userToUse?.userId,
                  requestNumber: session.requestNumber,
                  reqName: req?.title,
                },
              ],
            };
            //console.log("state session pushing into array",conSession);
            selectedSessions.push(conSession);
          }
        }
        //console.log("state session onDateChange array", selectedSessions);
        setCurrentSessions({
          sessions: selectedSessions,
          listTitle: listTitle,
        });
      }
    },
    [sessionsBetweenDates, setCurrentSessions, dispatch, isByUser]
  );

  return {
    onDateChange,
    currentSession,
    setCurrentSessions,
    onMonthChange,
    isHighlighted,
    isGreyed,
  };
}
