//** Common Header and Footer for All the Pages **//

import React from "react";
import Header from "./header";
import FooterComponent from "./footer";

const HeaderAndFooter = ({ children, background, className }) => {
  return (
    <div className="iguroo-main-div">
      <div className="border-bottom" data-testid="header-component">
        <Header />
      </div>
      <div
        className={`${
          background == "none" ? "content-bgnone" : "content-div"
        } ${className}`}
        data-testid="content-div"
        style={
          process.env.REACT_APP_SITE_SET_BACKGROUND == "true"
            ? {
                "--img": `url( ${require(`../assets/images/${process.env.REACT_APP_SITE_SIGNUP_BACKGROUND}`)}) `,
              }
            : { "--img": "linear-gradient(180deg, #f4f8fb 0%, #e3eefa 100%)" }
        }
      >
        {children}
      </div>
      <div data-testid="footer-component">
        <FooterComponent />
      </div>
    </div>
  );
};

export default HeaderAndFooter;
