//** GinieHealth Learner Login,Question Footer (Next,Previous,Submit) UI component code**//

import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { _getImage, _getVideoUrl } from "../../common/utils/commonFunctions";
import "plyr-react/plyr.css";
import "./styles.css";
import { SubmitButton } from "../submit.button";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";

const QFooter = (props) => {
  const { genieLabsAssessmentTranslations } = useIgurooTranslations();
  return (
    <Container>
      <Row>
        <Col md="3" className="mt-4 mb-4 text-center">
          {props?.showPrevious ? (
            <SubmitButton
              text={genieLabsAssessmentTranslations.previous}
              className="margin_add color_primary"
              onClick={props.onPreviousClick}
              type={'button'}
            />
          ) : (
            ""
          )}
        </Col>
        <Col md="3" className="mt-4 mb-4 text-center">
          {/* Check click would be use in Future Enhancement */}
          {/* <SubmitButton text={genieLabsAssessmentTranslations.check} className="margin_add color_green" 
        onClick={props.onCheckClick} />*/}
        </Col>
        <Col md="3" className="mt-4 mb-4 text-center">
          {props?.showMark ? (
            <SubmitButton
              text={genieLabsAssessmentTranslations.mark}
              className="margin_add color_yellow"
              onClick={props.onMarkClick}
              type={'button'}
            />
          ) : (
            ""
          )}
        </Col>
        <Col md="3" className="mt-4 mb-4 text-center">
          <SubmitButton
            text={props?.nextText}
            className="margin_add color_primary"
            onClick={props.onNextClick}
            disabled={props?.isSubmitting}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default QFooter;
