//** GinieHealth TodoSupervisor AgGrid & DetailsCardHeaderAssessment code UI **//

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { _getImage } from "../../common/utils/commonFunctions";
import { Link, useSearchParams } from "react-router-dom";
import EmailUsersPopup from "./EmailPopup";
import { AgGridMain } from "../../components/agGridRenderers/ag.grid.main";
import { useRef } from "react";
import TodoStatus from "../SuperVisor/Status.Todo";
import { AppUrlsEnum, ProfileType } from "../../enums";
import { BackButton } from "../../components/back.button";
import InviteUserPopup from "./UserPopup";
import DetailsCardHeaderAssessment from "./DetailCardHeader";
import { GetAssesmentAssignmentApi } from "@iguroo/ig-react-assessment-package";
import { pdfjs } from "react-pdf";
import { ActionCellRender } from "./ActionCellRender";
import { TodoGridName, formatDate } from "./SuperVisorTags";
import { SortColumnsAggrid } from "../../components/agGridRenderers/aggrid.sort.columns";
import SearchButton from "../../components/PublisherComponent/SearchButton";
import { AuthContext } from "../../hooks/auth.context.provider";

const TodoSupervisor = () => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const [showPopup1, setShowPopup1] = useState(false);
  const [test, setTest] = useSearchParams();
  const assessment_id = test.get("assessmentId");
  const version = test.get("versionId");
  const assessment_version_id = assessment_id + "-" + version;
  console.log(test.get("assessmentId"), "testtt");
  console.log(assessment_id, "assessment-idd");
  const [loadPage, setLoadPage] = useState(false);
  const [validTill, setValidTill] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchSortText, setSearchSortText] = useState("");

  const [update, setUpdate] = useState(false);
  const [gridData, setGridData] = useState({});
  const [sortData, setSortData] = useState([
    "First Name",
    "Last Name",
    "Status",
  ]);
  const [resetedCols, setResetCols] = useState([]);
  const authContext = useContext(AuthContext);
  const userType = useMemo(() => {
    return authContext?.sessionInfo?.role?.toUpperCase();
  }, [authContext.authStatus]);

  const refreshTable = useCallback((params) => {
    console.log("checking addnew user");
    setSearchText("");
    console.log("Refresh checking", update, params);
    setUpdate(!update);
   setTimeout(()=> gridRef.current.api.refreshInfiniteCache(),3000);
  }, []);

  const onGridReady = useCallback(
    (params) => {
      console.log("Refresh");
      const dataSource = {
        rowCount: null,
        getRows: async function (rparams) {
          try {
            gridRef.current?.api?.showLoadingOverlay();
            const filterModel = rparams.filterModel;
            const endRow = rparams.endRow;
            const startRow = rparams.startRow;
            const inputQuery = {
              assessment_version_id,
              from: startRow,
              limit: endRow,
            };
            if (filterModel.hasOwnProperty("search")) {
              inputQuery.search = filterModel?.search?.filter;
            }
            if (filterModel.hasOwnProperty("firstNameSortType")) {
              inputQuery.firstNameSortType =
                filterModel?.firstNameSortType?.filter;
            }
            if (filterModel.hasOwnProperty("lastNameSortType")) {
              inputQuery.lastNameSortType =
                filterModel?.lastNameSortType?.filter;
            }
            // if (filterModel.hasOwnProperty("statusSortType")) {
            //   inputQuery.statusSortType = filterModel?.statusSortType?.filter;
            // }
            const listData = await GetAssesmentAssignmentApi(inputQuery);
            let d = listData.response.data;
            const listD = listData.response.data.map((d, index) => {
              const currentTime = new Date().getTime();

              let statusValue = "";
              if (d.status === "PLANNED") {
                if (currentTime < d.start_datetime) {
                  statusValue = "PENDING";
                } else if (
                  d.start_datetime <= currentTime &&
                  currentTime <= d.due_datetime
                ) {
                  statusValue = "PENDING";
                } else {
                  statusValue = "EXPIRED";
                }
              } else if (d.status === "ACTIVE") {
                if (currentTime <= d.due_datetime) {
                  statusValue = "PENDING";
                } else {
                  statusValue = "EXPIRED";
                }
              } else if (d.status === "COMPLETED") {
                statusValue = "COMPLETED";
              } else if (d.status === "PENDING") {
                if (currentTime <= d.due_datetime) {
                  statusValue = "PENDING";
                } else {
                  statusValue = "EXPIRED";
                }
              }else if (d.status === "EXPIRED") {
                statusValue = "EXPIRED";
              }

              let resData = d;
              console.log("Refresh call 90", validTill);
              console.log("Refresh call 105", resData, statusValue);
              
              resData.action = {
                url: d?.transcript_url?.length ? d?.transcript_url : "",
                transcript_download: true,
                openPopup: "hide",
                status: statusValue,
                assessments: d?.userId,
                validTill: d?.due_datetime,
                assessment_version_id: d?.assessment_version_id,
                expiryDate: validTill,
                LoadPage: () => setLoadPage(!loadPage),
              };
              console.log("Refresh call 136", resData,resData.start_datetime,d.start_datetime);
              return resData;
            });
            console.log("Refresh call 105", listD);
            console.log("Refresh call 105", listData.response.data);
            if (listData.response.data && listData.response.total === 0) {
              gridRef.current?.api?.showNoRowsOverlay();
            } else {
              gridRef.current?.api?.hideOverlay();
            }
            rparams.successCallback(listD, listData.response.total);
            setGridData({
              from: startRow + 1,
              to: endRow,
              total: listData.response.total,
            });
          } catch (err) {
            console.log("params", err);
            gridRef?.current?.api?.showNoRowsOverlay();
            rparams.successCallback([], 0);
          }
        },
      };
      params?.api?.setDatasource(dataSource);
    },
    [gridRef, setUpdate, searchText,searchSortText]
  );
  console.log("validTill_Expiry", validTill);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      flex: 1,
      unSortIcon: true,
      minWidth: 150,
      wrapText: true,
      autoHeight: true,
      sortable: false,
    }),
    []
  );

  const onPageChange = useCallback((params) => {
    window.scroll({
      top: 30,
      behavior: "smooth",
    });
  }, []);

  const applySearch = () => {
    setSearchSortText(searchText)
    gridRef.current.api.setFilterModel({
      search: {
        filter: searchText,
        filterType: "text",
        type: "equals",
      },
    });
    setResetCols(sortData);
  };

  // const sortBy = (displayName, sortType, sortField) => {
  //   const filterModel = {
  //     [sortField]: {
  //       filter: sortType,
  //       filterType: "text",
  //       type: "equals",
  //     },
  //   };
  //   gridRef.current.api.setFilterModel(filterModel);
  //   const dataSorting = sortData.filter((item) => item !== displayName);
  //   setResetCols(dataSorting);
  // };

  const sortBy = (displayName, sortType, sortField) => {
    setTimeout(()=>{
      gridRef.current.api.setFilterModel({
        [sortField]: {
          filter: sortType,
          filterType: "text",
          type: "equals",
        },
        search: {
          filter: searchSortText,
          filterType: "text",
          type: "equals",
        },
      });
      const dataSorting = sortData.filter((item) => item !== displayName);
      setResetCols(dataSorting);
    },700)
   
  };
  const columnDefs = useMemo(
    () => [
      // {
      //   field: "SI",
      //   headerName: "S.No.",
      //   headerCheckboxSelection: true,
      //   checkboxSelection: true,
      //   showDisabledCheckboxes: true,
      //   resizable: true,
      // },
      {
        headerName: "search",
        colId: "search",
        suppressColumnsToolPanel: true,
        sortable: false,
        hide: true,
        filter: true,
      },
      {
        headerName: "firstNameSortType",
        colId: "firstNameSortType",
        suppressColumnsToolPanel: true,
        sortable: false,
        hide: true,
        filter: true,
      },
      {
        field: "firstName",
  resizable: true,
  cellRenderer: TodoGridName,
  headerComponent: SortColumnsAggrid,
  headerComponentParams: {
    sortByFunction: (displayName, sortType, sortField) => sortBy(displayName, sortType, sortField),
    sortData: resetedCols,
    sortField: "firstNameSortType",
        },
      },
      {
        headerName: "lastNameSortType",
        colId: "lastNameSortType",
        suppressColumnsToolPanel: true,
        sortable: false,
        hide: true,
        filter: true,
      },
      {
        field: "lastName",
        resizable: true,
        cellRenderer: TodoGridName,
        headerComponent: SortColumnsAggrid,
        headerComponentParams: {
          sortByFunction: (displayName, sortType, sortField) => sortBy(displayName, sortType, sortField),
          sortData: resetedCols,
          sortField: "lastNameSortType",
        },
      },
      {
        field: "start_datetime",
        headerName: "Start Date",
        resizable: true,
        cellRenderer: formatDate,
      },
      {
        field: "due_datetime",
        headerName: "Due Date",
        resizable: true,
        cellRenderer: formatDate,
      },
      // {
      //   headerName: "statusSortType",
      //   colId: "statusSortType",
      //   suppressColumnsToolPanel: true,
      //   sortable: false,
      //   hide: true,
      //   filter: true,
      // },
      {
        field: "status",
        cellRenderer: TodoStatus,
        resizable: true,
        // headerComponent: SortColumnsAggrid,
        // headerComponentParams: {
        //   sortByFunction: sortBy,
        //   sortData: resetedCols,
        //   sortField: "statusSortType",
        // },
      },
      {
        field: "total_score",
        headerName: "Score",
        resizable: true,
        // cellRenderer: formatDate,
      },
      {
        field: "action",
        cellRenderer: ActionCellRender,
        filter: false,
        unSortIcon: false,
        sortable: false,
        cellRendererParams: {
          updateList: refreshTable,
          expiryDate: validTill,
        },
      },
    ],
    [resetedCols, validTill,searchText,searchSortText]
  );
  console.log("validTill_Expiry315", validTill);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const BackButtonLink = () => {
    if (userType === ProfileType.compliance) {
      return AppUrlsEnum.complianceSuperVisor;
    } else if (userType === ProfileType.admin) {
      return AppUrlsEnum.superVisor;
    } else if (userType === ProfileType.supervisor) {
      return AppUrlsEnum.superVisorUser;
    } else {
      return "";
    }
  };

  return (
    <>
      {
        <>
          <Row>
            <Col>
              <Link to={BackButtonLink()}>
                <BackButton svgName="back_btn1.svg" />
              </Link>
            </Col>
            <Col className="details-header assessment-col mb-2" md={10} lg={11}>
              <DetailsCardHeaderAssessment
                assessment_id={assessment_id}
                version={version}
                profilePic={_getImage("avatar.svg")}
                className="details-header"
                style={{ backgroundColor: "white" }}
                validDate={(res) => {
                  setValidTill(res);
                  console.log(res, "Response of validTill");
                  refreshTable();
                }}
              />
            </Col>
          </Row>
          <Row className="mb-4 mt-2">
            <Col
              md={6}
              lg={6}
              className="d-flex align-items-start justify-content-start"
            >
              <InviteUserPopup
                className="w-100 user-btn"
                assessment_version_id={assessment_version_id}
                assessment_id={assessment_id}
                version={version}
                showPopup1={showPopup1}
                handleGetAssignedUserList={() => {
                  console.log("error 165 handleGetAssignedUserList");
                  refreshTable();
                }}
              ></InviteUserPopup>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={6}
              className="d-flex align-items-end justify-content-end"
            >
              <SearchButton
                submit={applySearch}
                handleOnChange={(e) => setSearchText(e)}
              />
            </Col>
          </Row>
          {/* <EmailUsersPopup className="w-100"></EmailUsersPopup> */}
          <hr />
          <div className="ag-theme-alpine mb-3 user-table">
            <AgGridMain
              gridRef={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              rowHeight={70}
              onPaginationChanged={onPageChange}
              pageObj={{
                pageSize: 20,
                cacheBlockSize: 20,
                maxBlocksInCache: 20,
              }}
              // className="user-tables"
              // suppressPaginationPanel={true}
            />
          </div>
        </>
      }
    </>
  );
};

export default TodoSupervisor;
