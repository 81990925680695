//** Iguroo Assessment,Sortable Question Component **//
//** Currently not using this,will be implement in future enhancement**//

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from 'react-dnd-touch-backend'
import { useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import { SortableItem } from "./SortableItem";
import { SingleItemExample } from "./SingleItemExample";
import { SortableList } from "./SortableList";
import { Col, Row } from "react-bootstrap";
import "./dndCommonStyles.css";

export const SortableQuestion = ({ items, isAnswers, onAnswerListChange }) => {
  const [originalList, setOriginaList] = useState(items);
  const [answersList, setAnswersList] = useState(isAnswers ? [] : items);
  const [sortedAnswerList, setSortedAnswerList] = useState(
    isAnswers ? items : new Array(items.length).fill(null)
  );
  // console.log(
  //   items,
  //   "sortableQuestion 16",
  //   originalList,
  //   answersList,
  //   sortedAnswerList
  // );
  useEffect(() => {
    const nullExists = sortedAnswerList.findIndex(
      (val) => val === null || val === undefined
    );
    // console.log(
    //   "SortableQuestion sortedAnswerListchanged",
    //   sortedAnswerList,
    //   nullExists > -1
    // );
    if (typeof onAnswerListChange === "function") {
      onAnswerListChange(sortedAnswerList, nullExists > -1);
    }
  }, [sortedAnswerList]);

  useEffect(() => {
    setOriginaList(items);
    setAnswersList(isAnswers ? [] : items);
    setSortedAnswerList(isAnswers ? items : new Array(items.length).fill(null));
  }, [items, isAnswers, setOriginaList, setAnswersList, setSortedAnswerList]);

  const answerDndFinished = useCallback(
    (updatedCards, dragIndex, hoverIndex, item) => {
      console.log(
        "SortableQuestion answer dropped",
        updatedCards,
        dragIndex,
        hoverIndex,
        item
      );
      if (item?.isListDrag) {
        delete item?.isListDrag;
        return;
      }
      if (typeof item?.setItem === "function") {
        item?.setItem(null);
        delete item?.setItem;
        console.log("SortableQuestion answer dropped item", item);
      }
      setSortedAnswerList((prevSortedAnswerList) => {
        const index = prevSortedAnswerList.findIndex((val) => {
          return val?.id === item.id;
        });
        let updatedItems = prevSortedAnswerList;
        if (index > -1) {
          updatedItems = update(prevSortedAnswerList, {
            [index]: { $set: null },
          });
        }
        // console.log(
        //   "SortableQuestion sorted item dropped SortableQuestion sortedAnswerList",
        //   updatedItems
        // );
        return updatedItems;
      });
      setAnswersList((prevAnswerList) => {
        const exists = prevAnswerList.find((val) => val.id === item.id);
        if (exists) {
          return prevAnswerList;
        }
        const updatedAnswerList = update(prevAnswerList, { $push: [item] });
        return updatedAnswerList;
      });
    },
    [setAnswersList, setSortedAnswerList]
  );
  const onCardAddedBack = useCallback(
    (updatedCards, dragIndex, hoverIndex, item) => {
      // if (typeof item?.setItem === "function") {
      //   item?.setItem(null);
      //   delete item?.setItem;
      //   console.log("SortableQuestion answer dropped item", item);
      // }
      answerDndFinished(updatedCards, dragIndex, hoverIndex, item);
    },
    [answerDndFinished]
  );
  const sortedAnswerDropped = useCallback(
    (droppedItem, itemIndex) => {
      console.log(
        "SortableQuestion sorted item dropped",
        droppedItem,
        itemIndex
      );
      // if (typeof droppedItem?.setItem === "function") {
      //   //droppedItem?.setItem(null);
      //   delete droppedItem?.setItem;
      //   console.log("SortableQuestion answer dropped item", droppedItem);
      // }
      setSortedAnswerList((prevSortedAnswerList) => {
        const index = prevSortedAnswerList.findIndex((val) => {
          return val?.id === droppedItem.id;
        });
        let updatedItems = prevSortedAnswerList;
        if (index > -1) {
          updatedItems = update(prevSortedAnswerList, {
            [itemIndex]: { $set: droppedItem },
            [index]: { $set: null },
          });
        } else {
          updatedItems = update(prevSortedAnswerList, {
            [itemIndex]: { $set: droppedItem },
          });
        }
        //   console.log(
        //     "SortableQuestion sorted item dropped sortedAnswerList",
        //     updatedItems
        //   );
        return updatedItems;
      });
      setAnswersList((prevCards) => {
        const index = prevCards.findIndex((val) => {
          return val.id === droppedItem.id;
        });
        if (index > -1) {
          const updatedItems = update(prevCards, {
            $splice: [[index, 1]],
          });
          //dndDone(updatedItems);
          return updatedItems;
        }
        return prevCards;
      });
      //setItem(droppedItem);
    },
    [setSortedAnswerList, setAnswersList]
  );
  // function getDropTargetElementsAtPoint(x, y, dropTargets) {
  //   return dropTargets.filter((t) => {
  //     const rect = t.getBoundingClientRect()
  //     return (
  //       x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
  //     )
  //   })
  // }
  // const hasNative =
  // document && (document.elementsFromPoint || document.msElementsFromPoint)
  // // use custom function only if elementsFromPoint is not supported
  
  // const backendOptions = {
  //   getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint
  // }
  // console.log(hasNative,backendOptions,'console 167')
  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <Row className="align-items-stretch">
        <Col>
          <SortableList
            items={answersList}
            setItems={setAnswersList}
            dndDone={answerDndFinished}
            SortableItem={SortableItem}
            allowCardSort={false}
            bubbleDndCallback={true}
            onCardAddedBack={onCardAddedBack}
          />
        </Col>
        <Col>
          {sortedAnswerList?.map((val, index) => {
            return (
              <SingleItemExample
                key={val?.id}
                itemIndex={index}
                itemDropped={sortedAnswerDropped}
                initialItem={val}
              />
            );
          })}
        </Col>
      </Row>
    </DndProvider>
  );
};
