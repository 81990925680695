//** Iguroo & GinieHealth,CardSecondaryText Component,can re-use & add any SecondaryText using this component **//

import React from "react";
import { _getImage } from "../../common/utils/commonFunctions";

export function CardSecondaryComponent(props) {
  const { text, className, svgName } = props;
  return (
    <div className={`ig-card-sec-text ${className}`}>
      {svgName ? (
        <>
          <img src={_getImage(svgName ? svgName : "")} />{" "}
        </>
      ) : (
        ""
      )}
      {props.children?.length > 0 ? props.children : text}
    </div>
  );
}
