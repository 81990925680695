//** Iguroo ->After completing the Live session Rating popup of Mentee & Mentor**//

import React, { useEffect, useState } from "react";
import { Modal, Button, Container, Form, Row, Col } from "react-bootstrap";
import "./styles.scss";
import { FormLabelComponent } from "../form.label.component";
import { useForm, Controller } from "react-hook-form";
import { CustomInputField } from "../CustomInput/customInputField";
import Rating from "react-rating";
import { _getImage } from "../../common/utils/commonFunctions";
import ThumbsRadio from "../ThumbsRadioBtn";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { postRating, postMentorRating } from "../../services/axios/Rating";
import { useSelector } from "react-redux";
import { ProfileType, RequestStatus } from "../../enums";
import { updateSession } from "../../services/axios/session/sessions";
import { useParams } from "react-router-dom";
import { RateMeetingTranslations } from "../../FormSchemas/rate.meeting.form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormErrorComponent } from "../form.error.component";
require(`../../css/${process.env.REACT_APP_CSS_COMMON}`);

function RateMeeting(props) {
  const { formErrors } = useIgurooTranslations();
  const [questions, setQuestions] = useState({
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    q5: 0,
  });
  const [mentorRating, setMentorRating] = useState(0);
  const user = useSelector((state) => state.user.profile);
  const { show, onHide, primaryBtn, secondaryBtn } = props;
  const { requestId } = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm(
    user.type === ProfileType.mentor
      ? {
          resolver: yupResolver(RateMeetingTranslations(formErrors)),
        }
      : {}
  );

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  function checkProperties(obj) {
    for (const key in obj) {
      // console.log(obj[key], key,'callstart')
      if (obj[key] !== null && obj[key] != 0) return false;
    }
    return true;
  }
// const handleRatesPost = () => {};
  const handleQuestions = (data) => {
    setQuestions({ ...questions, ...data });
  };

  useEffect(() => {
    // console.log(questions, "callstart");
    const isEmpty = Object.values(questions).some((x) => {
      // console.log(x, "callstart questions");
      return x == "" || x == 0;
    });
    // console.log(isEmpty, checkProperties(questions), "callstart");
    if (!isEmpty) setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
  }, [questions]);

  const onSubmit = async (data) => {
    const sessionInfo = JSON.parse(localStorage.getItem("session_info"));
    console.log(props.timeCount, "callstart");
    if (user.type === ProfileType.mentor) {
      const data1 = {
        mentorId: sessionInfo.mentorId?.toString(),
        sessionNumber: sessionInfo.sessionNumber?.toString(),
        menteeId: sessionInfo.userId?.toString(),
        questions: {
          ...data,
        },
        rating: mentorRating,
      };
      // console.log(data1, "form-data");
      postMentorRating(data1)
        .then((res) => {
          if (res.success) {
            onHide(false);
            localStorage.setItem("mentorRating", JSON.stringify(data));
            const session_info = {
              requestNumber: requestId,
              status: RequestStatus.completed.toLowerCase(),
              startTime: sessionInfo.startTime,
              endTime: sessionInfo.endTime,
              sessionNumber: sessionInfo.sessionNumber?.toString(),
              slot: {
                userId: sessionInfo.userId?.toString(),
                mentorId: sessionInfo.mentorId?.toString(),
                addCount: true,
              },
            };
            updateSession(session_info);
          }
        })
        .catch((err) => {
          console.log(err);
          onHide(false);
        });
    } else {
      const data2 = {
        mentorId: sessionInfo.mentorId?.toString(),
        sessionNumber: sessionInfo.sessionNumber?.toString(),
        menteeId: sessionInfo.userId?.toString(),
        questions: {
          ...questions,
        },
        ...(data.description && { description: data.description }),
      };
      postRating(data2)
        .then((res) => {
          if (res.success) {
            onHide(false);
            localStorage.setItem("menteeRating", JSON.stringify(questions));
          }
        })
        .catch((err) => {
          console.log(err);
          onHide(false);
        });
    }
  };

  const handleskip = (e) => {
    e.preventDefault();
    const sessionInfo = JSON.parse(localStorage.getItem("session_info"));
    const session_info = {
      requestNumber: requestId,
      status: RequestStatus.completed.toLowerCase(),
      startTime: sessionInfo.startTime,
      endTime: sessionInfo.endTime,
      sessionNumber: sessionInfo.sessionNumber?.toString(),
      slot: {
        userId: sessionInfo.userId?.toString(),
        mentorId: sessionInfo.mentorId?.toString(),
      },
    };
    updateSession(session_info);
    onHide(false);
  };

  useEffect(() => {
    const mentorRating = JSON.parse(localStorage.getItem("mentorRating"));
    const menteeRating = JSON.parse(localStorage.getItem("menteeRating"));
    if (mentorRating) {
      setMentorRating(mentorRating);
    }
    if (menteeRating) {
      setQuestions(menteeRating);
    }
  }, []);

  return (
    <Modal
      size={"lg"}
      show={show}
      onHide={() => onHide(false)}
      className="rate-meeting"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <Modal.Title className="w-100">
            {user.type === ProfileType.mentee ? (
              " Please provide feedback. Your feedback will help us improve."
            ) : (
              <div>
                <p>Please provide feedback. </p>
                <p>
                  Your feedback will be shared with mentee for record keeping.
                </p>
              </div>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body scrollable={true}>
          {user.type === ProfileType.mentee && (
            <Container fluid>
              {/* <div className="text-center mb-4">
            <h2>Please provide feedback. Your feedback will help us improve.</h2>
            <ThumbsRadio />
          </div> */}
              <div className="mb-4">
                <Row>
                  <Col md={8}>
                    <p className="rate-text">
                      How do you rate the mentor? Was mentor polite, courteous,
                      professional, on time, and well prepared for the session?
                    </p>
                  </Col>
                  <Col md={4}>
                    <Rating
                      onClick={(rate) => handleQuestions({ q1: rate })}
                      placeholderRating={questions.q1}
                      emptySymbol={
                        <img
                          src={_getImage("empty_star.svg")}
                          className="icon"
                        />
                      }
                      placeholderSymbol={
                        <img
                          src={_getImage("full_star.svg")}
                          className="icon"
                        />
                      }
                      fullSymbol={
                        <img
                          src={_getImage("full_star.svg")}
                          className="icon"
                        />
                      }
                    />
                  </Col>
                  {/* {console.log(questions, "queee")} */}
                </Row>
                <Row>
                  <Col md={8}>
                    <p className="rate-text">
                      Did mentor spend enough time to understand you and your
                      specific needs? Did mentor listen to you and your issues
                      patiently?
                    </p>
                  </Col>
                  <Col md={4}>
                    <Rating
                      onClick={(value) => handleQuestions({ q2: value })}
                      placeholderRating={questions.q2}
                      emptySymbol={
                        <img
                          src={_getImage("empty_star.svg")}
                          className="icon"
                        />
                      }
                      placeholderSymbol={
                        <img
                          src={_getImage("full_star.svg")}
                          className="icon"
                        />
                      }
                      fullSymbol={
                        <img
                          src={_getImage("full_star.svg")}
                          className="icon"
                        />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <p className="rate-text">
                      How do you rate the guidance/advice/coaching provided by
                      the mentor? Does the guidance given takes you closer to
                      your goal?
                    </p>
                  </Col>
                  <Col md={4}>
                    <Rating
                      onClick={(value) => handleQuestions({ q3: value })}
                      placeholderRating={questions.q3}
                      emptySymbol={
                        <img
                          src={_getImage("empty_star.svg")}
                          className="icon"
                        />
                      }
                      placeholderSymbol={
                        <img
                          src={_getImage("full_star.svg")}
                          className="icon"
                        />
                      }
                      fullSymbol={
                        <img
                          src={_getImage("full_star.svg")}
                          className="icon"
                        />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <p className="rate-text">
                      Is the assessment report or career development plan
                      provided to you is comprehensive, well articulated and
                      easy to understand?
                    </p>
                  </Col>
                  <Col md={4}>
                    <Rating
                      onClick={(value) => handleQuestions({ q4: value })}
                      placeholderRating={questions.q4}
                      emptySymbol={
                        <img
                          src={_getImage("empty_star.svg")}
                          className="icon"
                        />
                      }
                      placeholderSymbol={
                        <img
                          src={_getImage("full_star.svg")}
                          className="icon"
                        />
                      }
                      fullSymbol={
                        <img
                          src={_getImage("full_star.svg")}
                          className="icon"
                        />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <p className="rate-text">
                      How likely are you to recommend the mentor and iGuroo to a
                      friend or colleague?
                    </p>
                  </Col>
                  <Col md={4}>
                    <Rating
                      onClick={(value) => handleQuestions({ q5: value })}
                      placeholderRating={questions.q5}
                      emptySymbol={
                        <img
                          src={_getImage("empty_star.svg")}
                          className="icon"
                        />
                      }
                      placeholderSymbol={
                        <img
                          src={_getImage("full_star.svg")}
                          className="icon"
                        />
                      }
                      fullSymbol={
                        <img
                          src={_getImage("full_star.svg")}
                          className="icon"
                        />
                      }
                    />
                  </Col>
                </Row>
              </div>
              <section className="feedback-form">
                <Form.Group className="mb-3">
                  <FormLabelComponent>
                    Additional feedback or Suggestions
                  </FormLabelComponent>
                  <Controller
                    name={`description`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInputField
                        placeholder={
                          "Leave additional feedback here to improve future sessions"
                        }
                        hookOnChange={onChange}
                        hookOnBlur={onBlur}
                        hookValue={value}
                        formAs={"textarea"}
                        rows={5}
                      />
                    )}
                  />
                </Form.Group>
              </section>
            </Container>
          )}
          {user.type === ProfileType.mentor && (
            <Container fluid>
              <Row>
                <Col md={8}>
                  <p className="rate-text">
                    How do you rate the quality of session?
                  </p>
                </Col>
                <Col md={4}>
                  <Rating
                    onClick={(rate) => {
                      setMentorRating(rate);
                      handleQuestions({ q1: rate });
                    }}
                    placeholderRating={questions.q1}
                    emptySymbol={
                      <img src={_getImage("empty_star.svg")} className="icon" />
                    }
                    placeholderSymbol={
                      <img src={_getImage("full_star.svg")} className="icon" />
                    }
                    fullSymbol={
                      <img src={_getImage("full_star.svg")} className="icon" />
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Form.Group className="mb-3">
                  <FormLabelComponent>
                    Summarize the problem statement mentee came up with for the
                    session
                  </FormLabelComponent>
                  <Controller
                    name={`Q1`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInputField
                        placeholder={"Summarize the problem statement"}
                        hookOnChange={onChange}
                        hookOnBlur={onBlur}
                        hookValue={value}
                        formAs={"textarea"}
                        rows={5}
                      />
                    )}
                  />
                  <FormErrorComponent>
                    {errors.Q1 && errors.Q1?.message}
                  </FormErrorComponent>
                </Form.Group>
                <Form.Group className="mb-3">
                  <FormLabelComponent>
                    Summarize the recommendations and guidance given to the
                    mentee
                  </FormLabelComponent>
                  <Controller
                    name={`Q2`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInputField
                        placeholder={
                          "Summarize the recommendations and guidance"
                        }
                        hookOnChange={onChange}
                        hookOnBlur={onBlur}
                        hookValue={value}
                        formAs={"textarea"}
                        rows={5}
                      />
                    )}
                  />
                  <FormErrorComponent>
                    {errors.Q2 && errors.Q2?.message}
                  </FormErrorComponent>
                </Form.Group>
                <Form.Group className="mb-3">
                  <FormLabelComponent>
                    Any feedback or suggestion to improve the mentoring process
                  </FormLabelComponent>
                  <Controller
                    name={`Q3`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInputField
                        placeholder={"Summarize the feedback or suggestion"}
                        hookOnChange={onChange}
                        hookOnBlur={onBlur}
                        hookValue={value}
                        formAs={"textarea"}
                        rows={5}
                      />
                    )}
                  />
                </Form.Group>
              </Row>
            </Container>
          )}
        </Modal.Body>
        <Modal.Footer
          className={
            user.type === ProfileType.mentee ? "mentee-footer" : "mentor-footer"
          }
        >
          {user.type === ProfileType.mentee && (
            <>
              <Button variant="outline-primary" onClick={handleskip}>
                {secondaryBtn}
              </Button>
              <Button
                // onClick={handleRatesPost}
                type="submit"
                variant="primary"
                disabled={isButtonDisabled}
              >
                {primaryBtn}
              </Button>
            </>
          )}
          {user.type === ProfileType.mentor && (
            <Button
              className="submit-btn"
              // onClick={handleRatesPost}
              variant="primary"
              type="submit"
            >
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default RateMeeting;
