//** GinieHealth Publisher,Create New Assessment of Assessment Details UI code **//

import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Form, Row, Col, Card } from "react-bootstrap";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { Editor } from "react-draft-wysiwyg";
import ToolbarConfig from "./../liveSession/EditorConfig";
import {
  EditorState,
  RichUtils,
  convertFromRaw,
  convertToRaw,
  ContentState,
} from "draft-js";
import { code, blockquote } from "../../assets/images";
import { CustomSelect } from "../../components/CustomInput/custom.select";
import { FormLabelComponent } from "../../components/form.label.component";
import { FormErrorComponent } from "../../components/form.error.component";
import { SubmitButton } from "../../components/submit.button";
import { _getImage } from "../../common/utils/commonFunctions";
import { CustomImage, CustomVideoMemo } from "../../components/Media";
import { getPublisherDetailsFormTranslated } from "../../FormSchemas/publisher.details.test.form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { AppUrlsEnum, ImgSrcEnums, Languages, S3Folders } from "../../enums";
import AssessmentSideBar from "../../components/PublisherComponent/AssessmentSideBar";
import { useAssessmentDetailFetch } from "../../hooks/useAssessmentDetailFetch";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  createAssessmentAsyncThunk,
  tempCreateAssessmentSelector,
  addDataToAssessmentDetails,
  AssessmentDetailsDataSelector,
} from "../../redux-tk/slices/assessment.slice";
import {
  fuseAllAssessmentWithForm,
  getAssessmentObject,
} from "../../common/DTOS/assessment.dtos";
import { useDispatch, useSelector } from "react-redux";
import AssessmentFileUploader from "../../components/assessment.file.uploader";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const AssessmentDetails = () => {
  const { formErrors, mentorOnboardingLabels } = useIgurooTranslations();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [editorFocus, setEditorFocus] = useState(false);
  const [images, setImages] = React.useState([]);
  const tempCreateDataValue = useSelector((state) =>
    tempCreateAssessmentSelector(state)
  );

  const params = useAssessmentDetailFetch({
    assessmentId: searchParams?.get("assessmentId"),
    versionId: searchParams?.get("versionId"),
  });

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [assessmentInfoEditorState, setAssessmentInfoEditorState] = useState(
    EditorState.createEmpty()
  );
  const [selectedVideo, setSelectedVideo] = useState(
    tempCreateDataValue.apiData?.banner_video
  );
  const [selectedImage, setSelectedImage] = useState(
    tempCreateDataValue.apiData?.banner_image
  );

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields, touchedFields },
    reset,
  } = useForm({
    defaultValues: {
      ...tempCreateDataValue.apiData,
      language: tempCreateDataValue.apiData?.language
        ? tempCreateDataValue.apiData?.language
        : "English",
      language_level: tempCreateDataValue.apiData?.language_level
        ? tempCreateDataValue.apiData?.language_level
        : "Medium",
    },
    resolver: yupResolver(getPublisherDetailsFormTranslated(formErrors)),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (tempCreateDataValue?.apiData?.assessment_id) {
      reset({ ...tempCreateDataValue.apiData });
      setSelectedVideo(tempCreateDataValue.apiData?.banner_video);
      setSelectedImage(tempCreateDataValue.apiData?.banner_image);
      try {
        JSON.parse(tempCreateDataValue?.editorState);
      } catch (e) {
        return;
      }
      const parsedEditorState = JSON.parse(tempCreateDataValue?.editorState);
      const parsedAssessmentInfoState = JSON.parse(
        tempCreateDataValue?.assessmentInfo
      );
      if (parsedEditorState.blocks) {
        setEditorState(
          tempCreateDataValue?.editorState
            ? EditorState.createWithContent(convertFromRaw(parsedEditorState))
            : EditorState.createEmpty()
        );
        setAssessmentInfoEditorState(
          tempCreateDataValue?.assessmentInfo
            ? EditorState.createWithContent(
                convertFromRaw(parsedAssessmentInfoState)
              )
            : EditorState.createEmpty()
        );
      } else if (parsedEditorState.content) {
        const blocksFromHtml = htmlToDraft(parsedEditorState?.content);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(
          tempCreateDataValue?.editorState
            ? editorState
            : EditorState.createEmpty()
        );
        const blocksFromHtml2 = htmlToDraft(parsedAssessmentInfoState?.content);
        const contentState2 = ContentState.createFromBlockArray(
          blocksFromHtml2.contentBlocks,
          blocksFromHtml2.entityMap
        );
        const infoState = EditorState.createWithContent(contentState2);
        setAssessmentInfoEditorState(
          tempCreateDataValue?.assessmentInfo
            ? infoState
            : EditorState.createEmpty()
        );
      }
    }
  }, [tempCreateDataValue?.apiData?.assessment_id]);

  useEffect(() => {
    if (!tempCreateDataValue?.apiData?.assessment_id) {
      try {
        JSON.parse(tempCreateDataValue?.assessmentInfo);
      } catch (e) {
        return;
      }
      const parsedAssessmentInfoState = JSON.parse(
        tempCreateDataValue?.assessmentInfo
      );
      if (parsedAssessmentInfoState.blocks) {
        setAssessmentInfoEditorState(
          tempCreateDataValue?.assessmentInfo
            ? EditorState.createWithContent(
                convertFromRaw(parsedAssessmentInfoState)
              )
            : EditorState.createEmpty()
        );
      } else if (parsedAssessmentInfoState.content) {
        const blocksFromHtml2 = htmlToDraft(parsedAssessmentInfoState?.content);
        const contentState2 = ContentState.createFromBlockArray(
          blocksFromHtml2.contentBlocks,
          blocksFromHtml2.entityMap
        );
        const infoState = EditorState.createWithContent(contentState2);
        setAssessmentInfoEditorState(
          tempCreateDataValue?.assessmentInfo
            ? infoState
            : EditorState.createEmpty()
        );
      }
    }
  }, [tempCreateDataValue?.apiData?.assessmentInfo]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    localStorage.setItem(
      "sessionText",
      editorState.getCurrentContent().getPlainText()
    );
  };

  const onAssessmentInfoEditorStateChange = (editorState) => {
    setAssessmentInfoEditorState(editorState);
  };

  function CustomOption(props) {
    const { editorState, onChange } = props;
    let [bqActive, setBqActive] = useState(false);
    let [codeActive, setCodeActive] = useState(false);

    const toggleBlock = () => {
      codeActive && setCodeActive(bqActive);
      setBqActive(!bqActive);
      const newState = RichUtils.toggleBlockType(editorState, "blockquote");
      if (newState) {
        onChange(newState);
      }
    };
    const toggleCode = () => {
      bqActive && setBqActive(codeActive);
      setCodeActive(!codeActive);
      const newState = RichUtils.toggleBlockType(editorState, "code-block");
      if (newState) {
        onChange(newState);
      }
    };

    return (
      <div className="rdw-custom-wrapper">
        <div
          className={`rdw-option-wrapper ${
            codeActive ? "rdw-option-active" : ""
          }`}
          title="Code"
        >
          <img
            src={code}
            alt="code"
            className="rdw-storybook-custom-code"
            onClick={toggleCode}
          />
        </div>
        <div
          className={`rdw-option-wrapper ${
            bqActive ? "rdw-option-active" : ""
          }`}
          title="Blockquote"
        >
          <img
            src={blockquote}
            alt="blockquote"
            className="rdw-storybook-custom-blockquote"
            onClick={toggleBlock}
          />
        </div>
      </div>
    );
  }

  const onSubmit = async (data) => {
    try {
      const draftContent = JSON.stringify({
        content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      });
      const assessmentInfoDraftContent = JSON.stringify({
        content: draftToHtml(
          convertToRaw(assessmentInfoEditorState.getCurrentContent())
        ),
      });
      data.assessment_metadata = {
        basic_details: {
          description: {
            details: draftContent,
          },
        },
      };
      data.assessment_info = assessmentInfoDraftContent;
      data.banner_video = selectedVideo;
      data.banner_image = selectedImage;
      const assessmentObj = getAssessmentObject(data);
      const ids = {
        versionId: searchParams.get("versionId"),
        assessmentId: searchParams.get("assessmentId"),
      };
      const fusedObject = fuseAllAssessmentWithForm(
        tempCreateDataValue.apiData,
        assessmentObj
      );
      const res = await dispatch(createAssessmentAsyncThunk(fusedObject));
      let finalData = {
        ...fusedObject,
      };
      if (
        res &&
        res?.payload &&
        res?.payload?.apiData &&
        res?.payload?.apiData?.version &&
        res?.payload?.apiData?.assessment_id
      ) {
        finalData = {
          ...finalData,
          version: res?.payload?.apiData?.version,
          assessment_id: res?.payload?.apiData?.assessment_id,
        };
      }
      dispatch(addDataToAssessmentDetails(finalData));
      if (ids.assessmentId && ids.versionId) {
        if (res?.payload) {
          navigate(
            `${AppUrlsEnum.intendedLearners}?versionId=${ids.versionId}&assessmentId=${ids.assessmentId}`
          );
        }
      } else {
        if (res?.payload) {
          navigate(AppUrlsEnum.intendedLearners);
        }
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const languages = [
    {
      label: Languages.English,
      value: Languages.English,
    },
    // ** In future the language will be used for the Assessment** //
    // {
    //   label: Languages.hindi,
    //   value: Languages.hindi,
    // },
    // {
    //   label: Languages.odiya,
    //   value: Languages.odiya,
    // },
    // {
    //   label: Languages.tamil,
    //   value: Languages.tamil,
    // },
    // {
    //   label: Languages.kannada,
    //   value: Languages.kannada,
    // },
    // {
    //   label: Languages.telugu,
    //   value: Languages.telugu,
    // },
  ];

  const languagesLevel = [
    {
      label: "Easy",
      value: "Easy",
      dataId: "Easy",
    },
    {
      label: "Medium",
      value: "Medium",
      dataId: "Medium",
    },
    {
      label: "Hard",
      value: "Hard",
      dataId: "Hard",
    },
  ];

  const onChange = (imageList, addUpdateIndex) => {
    setImages([]);
    setImages(imageList);
  };

  const fileHandler = (e) => {
    setSelectedVideo(e.target.files[0]);
  };

  const getFileUrls = (fileUrls, deleted) => {
    console.log("fileurls", fileUrls, "deleted", deleted);
    if (!deleted) {
      setSelectedVideo(fileUrls[0].url);
    }
  };

  const getImageFileUrls = (fileUrls, deleted) => {
    console.log("fileurls", fileUrls, "deleted", deleted);
    if (!deleted) {
      setSelectedImage(fileUrls[0].url);
    }
  };

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="p-3 justify-content">
      <h4 className="fs-24 fw-700">Basic Details</h4>
      <p className="fs-15 fw-400">
        This is where you'll lay the foundation for your assessment. Provide
        essential information about your assessment, such as its title,
        description and assessment objectives. Crafting clear and concise
        details here will help guide students or professionals in understanding
        the purpose and scope of your assessment. Let's get started on building
        an impactful assessment experience!
      </p>
      <div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mx-0 mt-2">
            <Form.Label className="fs-16 fw-700 form-title p-0 m-0">
              Assessment Title
            </Form.Label>
            <Controller
              name="title"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <CustomInputField
                  type="text"
                  className=""
                  placeholder="Assessment Title"
                  hookOnChange={onChange}
                  hookOnBlur={onBlur}
                  hookValue={value}
                  onChange={onChange}
                />
              )}
            />
            <span className="fs-12 fw-400 sub-text ps-0 mt-2">
              Your title should be a mix of attention-grabbing, informative, and
              optimized for search
            </span>
            <FormErrorComponent>
              {errors.title && errors.title?.message}
            </FormErrorComponent>
          </Row>
          <Row className="mt-2 mx-0">
            <Form.Label className="fs-16 fw-700 form-title p-0 m-0">
              Assessment subtitle
            </Form.Label>
            <Controller
              name="subtitle"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <CustomInputField
                  type="text"
                  className=""
                  placeholder="Assessment subtitle"
                  hookOnChange={onChange}
                  hookOnBlur={onBlur}
                  hookValue={value}
                  onChange={onChange}
                />
              )}
            />
            <span className="fs-12 fw-400 ps-0 mt-2">
              Your title should be a mix of attention-grabbing, informative, and
              optimized for search
            </span>
            <FormErrorComponent>
              {errors.subtitle && errors.subtitle?.message}
            </FormErrorComponent>
          </Row>
          <Row className="mt-2 ">
            <Form.Label className="fs-16 fw-700 form-title">
              Assessment Description
            </Form.Label>
            <Editor
              wrapperClassName="notes-wrapper"
              editorClassName={`notes-editor ${
                editorFocus ? "editor-focus" : ""
              }`}
              editorState={editorState}
              onFocus={(_) => setEditorFocus(true)}
              onBlur={(_) => setEditorFocus(false)}
              placeholder="Take your notes here..."
              onEditorStateChange={onEditorStateChange}
              toolbarCustomButtons={[<CustomOption />]}
              toolbar={ToolbarConfig}
            />
          </Row>
          <Row className="mt-2">
            <Col md={3}>
              <Form.Group>
                <FormLabelComponent className="fs-16 fw-700 form-title">
                  Assessment Language
                </FormLabelComponent>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CustomSelect
                      opLabel="label"
                      opValue="value"
                      items={languages}
                      onReactHookFormBlur={onBlur}
                      onReactHookFormChange={onChange}
                      value={value}
                      placeholder={""}
                      // will be used in Future Enhancement
                      // sendFullValueInHook={true}
                      // normalSelect={true}
                      // isDisabled={true}
                      // defaultValue={Languages.english}
                      // defaultValue={timeZones}
                    />
                  )}
                  name="language"
                />
                <FormErrorComponent>{""}</FormErrorComponent>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <FormLabelComponent className="fs-16 fw-700 form-title">
                  Level
                </FormLabelComponent>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CustomSelect
                      opLabel="value"
                      opValue="dataId"
                      items={languagesLevel}
                      onReactHookFormBlur={onBlur}
                      onReactHookFormChange={onChange}
                      value={value}
                      placeholder={""}
                    />
                  )}
                  name="language_level"
                />
                <FormErrorComponent>{""}</FormErrorComponent>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <h4>Categories and Tags</h4>
            <Col md={3}>
              <Form.Group>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CustomSelect
                      opLabel="value"
                      opValue="dataId"
                      items={[]}
                      onReactHookFormBlur={onBlur}
                      onReactHookFormChange={onChange}
                      value={value}
                      onChangeCallback={() => {}}
                      placeholder={""}
                      sendFullValueInHook={true}
                      normalSelect={true}
                      isDisabled={true}
                    />
                  )}
                  name="category"
                />
                <FormErrorComponent>{""}</FormErrorComponent>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CustomSelect
                      opLabel="value"
                      opValue="dataId"
                      items={[]}
                      onReactHookFormBlur={onBlur}
                      onReactHookFormChange={onChange}
                      value={value}
                      onChangeCallback={() => {}}
                      placeholder={""}
                      sendFullValueInHook={true}
                      normalSelect={true}
                      isDisabled={true}
                    />
                  )}
                  name="tags"
                />
                <FormErrorComponent>{""}</FormErrorComponent>
              </Form.Group>
            </Col>
          </Row>

          <h4 className="mt-3">Banner and Video</h4>

          <Row className="mt-3">
            <p className="fs-16 fw-700">Course image</p>
            <Col>
              <div className="uploaded-img d-flex justify-content-center">
                {selectedImage ? (
                  <>
                    <CustomImage
                      src={{ src: selectedImage, type: ImgSrcEnums.props }}
                      width="100%"
                      height="100%"
                      defaultImage={_getImage("upload-image.svg")}
                    />
                  </>
                ) : (
                  <img
                    className="selected-img"
                    src={_getImage("upload-image.svg")}
                    height={30}
                    width={30}
                  />
                )}
              </div>
            </Col>
            <Col className="pe-5">
              <div className="pe-5">
                <p>
                  Upload your course image here. It must meet our course image
                  quality standards to be accepted. Important guidelines:
                  750x422 pixels; .jpg, .jpeg,. gif, or .png. no text on the
                  image.
                </p>
                <div className="d-flex u-image">
                  <AssessmentFileUploader
                    maxCount={1}
                    fileUrls={getImageFileUrls}
                    uploadOnSelect={true}
                    uploadFileTypes="image/*"
                    s3FolderName={S3Folders.images}
                    inputId={"image-input"}
                    inputClass={"video-file-input"}
                    labelImage={_getImage("ic_upload.png")}
                    uploadInputLabelText={
                      mentorOnboardingLabels.uploadProfileVideo
                    }
                    uploadedtext={mentorOnboardingLabels.uploadedProfileVideo}
                    deleteFileImage={_getImage("Delete.svg")}
                    uploadButtonText={"upload"}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <p className="fs-16 fw-700">Promotional video</p>
            <Col>
              <div className="uploaded-img d-flex justify-content-center">
                {selectedVideo ? (
                  <div
                    className={`${
                      selectedVideo ? "d-block selected-video-div" : "d-none"
                    }`}
                  >
                    <CustomVideoMemo videoLink={selectedVideo} />
                  </div>
                ) : (
                  <img
                    className="selected-video"
                    src={_getImage("upload-video.svg")}
                    height={30}
                    width={30}
                  />
                )}
              </div>
            </Col>
            <Col className="pe-5">
              <div className="pe-5">
                <p>
                  Upload your course image here. It must meet our course image
                  quality standards to be accepted. Important guidelines:
                  750x422 pixels; MP4, MOV, WMV, AVI, AVCHD, FLV, F4V and SWF,
                  MKV,
                </p>
                <div className="d-flex u-video">
                  <AssessmentFileUploader
                    maxCount={1}
                    fileUrls={getFileUrls}
                    uploadOnSelect={true}
                    inputId={"video-input"}
                    uploadFileTypes="video/*"
                    s3FolderName={S3Folders.video}
                    inputClass={"video-file-input"}
                    labelImage={_getImage("ic_upload.png")}
                    uploadInputLabelText={
                      mentorOnboardingLabels.uploadProfileVideo
                    }
                    uploadedtext={mentorOnboardingLabels.uploadedProfileVideo}
                    deleteFileImage={_getImage("Delete.svg")}
                    uploadButtonText={"upload"}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Form.Label className="fs-16 fw-700 form-title">
              Assessment Information
            </Form.Label>
            <Editor
              wrapperClassName="notes-wrapper"
              editorClassName={`notes-editor ${
                editorFocus ? "editor-focus" : ""
              }`}
              editorState={assessmentInfoEditorState}
              onFocus={(_) => setEditorFocus(true)}
              onBlur={(_) => setEditorFocus(false)}
              placeholder="Take your notes here..."
              onEditorStateChange={onAssessmentInfoEditorStateChange}
              toolbarCustomButtons={[<CustomOption />]}
              toolbar={ToolbarConfig}
            />
          </Row>
          <div className="d-flex justify-content-end mt-3">
            <SubmitButton className="next-btn" text="Next" />
          </div>
        </Form>
      </div>
    </div>
  );
};

const PubAssessmentDetails = () => {
  const { formElementLabels, assessmentsTranslations } =
    useIgurooTranslations();
  return (
    <div className="p-2">
      <h4 className="text-center p-2">
        {assessmentsTranslations?.assessmentDetails}
      </h4>
      <Row className="d-flex justify-content-center">
        <Col md={8} className="">
          <Card className="p-4 border-0 shadow">
            <AssessmentDetails />
          </Card>
        </Col>
        <AssessmentSideBar
          data={assessmentsTranslations?.assessmentProgressView}
          stage={0}
          title={assessmentsTranslations?.assessmentDetails}
          btnValue={formElementLabels?.publishBtn}
          currentTab={
            assessmentsTranslations?.assessmentProgressView?.assessmentDetails
          }
        />
      </Row>
    </div>
  );
};

export default PubAssessmentDetails;
