export const ProfileType = {
  mentor: "MENTOR",
  mentee: "MENTEE",
  approver: "APPROVER",
  supportEngineer: "SUPPORTENGINEER",
  igurooadmin: "GLADMIN",
  admin: "ADMIN",
  preApproval: "PREAPPROVAL",
  glAdmin: "GLADMIN",
  learner: "LEARNER",
  author: "AUTHOR",
  publisher: "PUBLISHER",
  supervisor: "SUPERVISOR",
  compliance: "COMPLIANCE",
  recruiter: "SUPERVISOR",
};
