//**Iguroo Admin Mentor and Mentee User Media Approval UI code**//

import React from "react";
import { approveUser } from "../../services/axios/Admin/admin";
import Toaster from "../../components/toaster";
import { toast } from "react-toastify";

const Approve = (props) => {
  const handleApprove = () => {
    // Approve user
    approveUser(props.data.userId, props.data.approverId)
      .then((res) => {
        props.getUsers();
        Toaster(toast.success, "Mentor profile is approved");
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {props.Status ? (
        <button
          onClick={handleApprove}
          className="btn btn-sm primary-button rounded-5"
        >
          Approved
        </button>
      ) : (
        <button onClick={handleApprove} className="btn btn-sm primary-button">
          Approve
        </button>
      )}
    </>
  );
};

export default Approve;
