//** Iguroo Admin MentorProfile Public or Private, Hide or show using the userId UI code **//

import React from "react";
import Card from "react-bootstrap/Card";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormErrorComponent } from "../../components/form.error.component";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { SubmitButton } from "../../components/submit.button";
import { CustomCheckButton } from "../../components/CustomInput/custom.switch";
import { adminMentorPublicPrivate } from "../../FormSchemas/admin.mentor.management";
import { mentorPublicPrivate } from "../../services/axios/Admin/admin";
import Toaster from "../../components/toaster";
import { toast } from "react-toastify";

const MentorPublicPrivateProfile = () => {
  const { formErrors } = useIgurooTranslations();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: { mentorName: "", publicOrPrivate: false },
    resolver: yupResolver(adminMentorPublicPrivate(formErrors)),
  });

  const onSubmit = async (data) => {
    console.log(data, "private-data");
    mentorPublicPrivate(data)
      .then((res) => {
        console.log(res, "public-res");
        if (res.message) {
          Toaster(toast.success, "Profile status is updated");
        }
      })
      .catch((err) => {
        Toaster(toast.error, "Please enter valid profile ID");
      });
  };

  return (
    <div className="px-3 pt-5">
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6}>
                <Controller
                  name={`mentorName`}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CustomInputField
                      placeholder={"Please enter mentor name"}
                      hookOnChange={onChange}
                      hookOnBlur={onBlur}
                      hookValue={value}
                      rows={6}
                    />
                  )}
                />
                <FormErrorComponent>
                  {errors.mentorName && errors.mentorName?.message}
                </FormErrorComponent>
              </Col>
              <Col md={3} className="pt-4 d-flex justify-content-center">
                <Form.Label className="formLabel">Private</Form.Label>
                <Controller
                  name={"publicOrPrivate"}
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <CustomCheckButton
                      hookOnChange={onChange}
                      hookOnBlur={onBlur}
                      hookValue={value}
                      defaultChecked={false}
                      checked={value}
                      disabled={false}
                      className={"d-flex justify-content-center"}
                    />
                  )}
                />
                <Form.Label className="formLabel">Public</Form.Label>
              </Col>
              <Col md={3} className="pt-4">
                <SubmitButton type="submit" text="Submit" />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default MentorPublicPrivateProfile;
