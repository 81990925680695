import moment from "moment";
import { RatesTimeFrame, RequestStatus } from "../enums";
import uuid from "react-uuid";

export function getProfileObject(profile, userId) {
  const formData = {
    ...(profile.profileTitle && { profileTitle: profile.profileTitle }),
    ...(profile.type && { type: profile.type }),
    ...(userId && { userId: userId }),
    ...((profile.profilePic || profile.profilePic === "delete") && {
      profilePic: profile.profilePic === "delete" ? "" : profile.profilePic,
    }),
    ...(profile.newProfilePic && {
      newProfilePic: profile.newProfilePic,
    }),
    ...(profile.gender && { gender: profile.gender }),
    ...(profile.city &&
      profile.country &&
      profile.state && {
        location: {
          city: profile.city,
          country: profile.country,
          state: profile.state,
        },
      }),
    ...(profile.dateofBirth && { dob: profile.dateofBirth }),
    ...(profile.bio && { bio: profile.bio }),
    ...(profile.profileCategory && {
      profileCategory: profile.profileCategory,
    }),
    ...(profile.languages && { languages: profile.languages }),
    ...((profile.profileVideoLink || profile.profileVideoLink === "delete") && {
      profileVideoLink:
        profile.profileVideoLink === "delete" ? "" : profile.profileVideoLink,
    }),
    ...(profile.newProfileVideoLink && {
      newProfileVideoLink: profile.newProfileVideoLink,
    }),
    ...(profile.timeZone && { timeZone: profile.timeZone }),
    ...(profile.firstName && { firstName: profile.firstName }),
    ...(profile.lastName && { lastName: profile.lastName }),
    ...(profile.email && { email: profile.email }),
    ...(profile.mobile && { mobile: profile.mobile }),
    ...(profile.hobbies && { hobbies: profile.hobbies }),
    ...(profile.skills && { skills: profile.skills }),
    ...(profile.certifications && { certifications: profile.certifications }),
    ...(profile.onBoardStatus && { onboard_status: profile.onBoardStatus }),
    ...(profile.isPrivateProfile && {
      isPrivateProfile: profile.isPrivateProfile,
    }),
    ...(profile.isTrusted && { isTrusted: profile.isTruste }),
    ...(profile.profileLikes && { profileLikes: profile.profileLikes }),
    ...(profile.areaOfExpertise && {
      areaOfExpertise: profile.areaOfExpertise,
    }),
    ...(profile.workingExp && { workingExp: profile.workingExp }),
    ...(profile.mentoringFrom && { mentoringFrom: profile.mentoringFrom }),
    ...(profile.onBoardStage && { onBoardStage: profile.onBoardStage }),
    ...(profile.referenceCode && { reference_code: profile.referenceCode }),
  };
  return formData;
}

export const getGenderFromId = (genderId, genders) => {
  const result = genders.filter((gender) => genderId === gender.dataId);
  return result[0];
};

export const getTimeZoneObject = (timeZone, step, userId) => {
  return {
    timeZone: timeZone,
    onBoardStage: step,
    userId: userId,
  };
};

export const getAoeObject = (aoe, step, userId) => {
  return {
    areaOfExpertise: aoe,
    onBoardStage: step,
    userId: userId,
  };
};

export const getStepsObject = (step, userId) => {
  return {
    onBoardStage: step,
    userId: userId,
  };
};

export function getRatesObject(data, userId) {
  const finalArr = [];
  finalArr.push(
    createRateObj(
      {
        ...data,
        timeFrame: RatesTimeFrame.perSession,
        numberOfSessions: 1,
        chargeFree: false,
        charge: data.sessionCharge,
      },
      userId
    )
  );
  if (data.monthlyChargeSwitchEnable) {
    finalArr.push(
      createRateObj(
        {
          ...data,
          timeFrame: RatesTimeFrame.monthly,
          numberOfSessions: 5,
          chargeFree: data.monthlyChargeFree,
          charge: data.monthlyCharge,
        },
        userId
      )
    );
  }
  if (data.quarterlyChargeSwitchEnable) {
    console.log("dataquartely", data);
    finalArr.push(
      createRateObj(
        {
          ...data,
          timeFrame: RatesTimeFrame.quarterly,
          numberOfSessions: 15,
          chargeFree: data.quarterlyChargeFree,
          charge: data.quarterlyCharge,
        },
        userId
      )
    );
  }
  if (data.yearChargeSwitchEnable) {
    finalArr.push(
      createRateObj(
        {
          ...data,
          timeFrame: RatesTimeFrame.yearly,
          numberOfSessions: 50,
          chargeFree: data.yearlyChargeFree,
          charge: data.yearCharge,
        },
        userId
      )
    );
  }
  return finalArr;
}

function createRateObj(data, userId) {
  const cd = {
    userId: userId,
    timeFrame: data.timeFrame, //-->> week , year, persession, montg
    timeFramePerSession: data.numberOfSessions, //--> total session kitne h wo ayega
    time: data.time, //--> figma me jo 45 min wala time dikhaya h wo ayega
    curreny: data.currency, //-->> figma me currency h wo
    rate: data.charge, //-->> rate ki value figma me 0
    isFirstFree: data.chargeFree, //- fistsession free h k nai wo nicheck wala check box
  };
  if (data.id) cd.id = data.id;
  return cd;
}

function getHobies(datahob) {
  const finalArr = [];
  datahob.map((DataValue) => {
    finalArr.push(DataValue.value);
  });
  return finalArr;
}
function getskills(dataskills) {
  const finalArr = [];
  dataskills.map((DataValue) => {
    finalArr.push({ skill: DataValue.value });
  });
  return finalArr;
}
export const availabilityResponseToRedux = (avails) => {
  return avails.map((val) => {
    return {
      availability: val.availability,
      id: val.id,
      timeRange: {
        startTime: new Date(val.from),
        endTime: new Date(val.to),
      },
    };
  });
};

export const openSearchBodyObject = (data) => {
  const languages = [];
  if (data.language)
    data?.language?.map((DataValue) => {
      languages.push({ language: DataValue.value });
    });
  const areaOfExpertise = data.areaOfExpertise?.value;

  let body = {
    type: data.type,

    ...(data.language && { languages: languages }),
    ...(data.search && { search: data.search }),

    ...(data.bookmarked && { bookmarked: data?.bookmarked?.value }),
    ...(data.areaOfExpertise && { areaOfExpertise: areaOfExpertise }),
    ...(data.sortBy && { sortType: data.sortBy?.value }),
  };
  return body;
};

export function getsSessionObject(
  userId,
  session,
  mentorId,
  duration,
  firstname,
  starttime,
  endTime
) {
  const formData = {
    ...(session.title && { title: session.title }),
    ...(session.description && { description: session.description }),
    ...(userId && { userId: userId }),

    status: session.status ? session.status : true,
    ...(duration && { duration: duration }),

    ...(mentorId && { mentorId: mentorId }),

    ...(firstname && { participants: [firstname] }),
    ...(endTime && { endDateTime: endTime }),
    ...(starttime && { startDateTime: starttime }),
    meetingLocation: "zoom",
  };

  return formData;
}

export function getRequestObject(userId, request, mentorId) {
  const formData = {
    ...(request.title && { title: request.title }),
    ...(request.description && { description: request.description }),
    ...(userId && { userId: userId }),

    status: request.status ? request.status : RequestStatus.created,
    ...(request.numberOfSessions && {
      numberOfSessions: request.numberOfSessions,
    }),
    ...(request.areasOfInterest && {
      category: request.areasOfInterest,
    }),
    ...(mentorId && { mentorId: mentorId }),
    ...(request.files && { files: request.files }),
    ...(request.payment && { payment: { amount: "", currency: "" } }),
  };

  return formData;
}

export function CreateSessionObject(userId, request, mentorId, redID) {
  const formData = {
    ...(userId && { userId: userId }),

    status: request.status ? request.status : RequestStatus.created,
    ...(request.slot && { startTime: request.slot }),

    ...(request.slot && { endTime: request.slot }),
    ...(redID && { requestNumber: redID }),

    ...(mentorId && { mentorId: mentorId }),
  };

  return formData;
}

export function createBookaSession(
  userId,
  session,
  mentorId,
  endTime,
  starttime,
  menteeFirstName,
  menteeLastName,
  mentorFirstName,
  mentorLastName,
  duration
) {
  const formData = {
    ...(session.title && { title: session.title }),
    ...(session.description && { description: session.description }),
    ...(userId && { userId: userId }),

    status: session.status ? session.status : RequestStatus.active,

    ...(mentorId && { mentorId: mentorId }),
    ...(session.numberOfSessions && {
      numberOfSessions: session.numberOfSessions,
    }),
    ...(session.areasOfInterest && {
      category: session.areasOfInterest,
    }),

    ...(starttime && { startTime: starttime }),
    ...(endTime && { endTime: endTime }),
    ...(session.files && { files: session.files }),

    ...(menteeFirstName && { menteeFirstName: menteeFirstName }),
    ...(menteeLastName && { menteeLastName: menteeLastName }),
    ...(mentorFirstName && { mentorFirstName: mentorFirstName }),
    ...(mentorLastName && { mentorLastName: mentorLastName }),
    slot: getsSessionObject(
      userId,
      session,
      mentorId,
      duration,
      mentorFirstName,
      starttime,
      endTime
    ),
  };

  return formData;
}

export function reschedule(
  userId,
  sessionNum,
  requestNum,
  session,
  mentorId,
  endTime,
  starttime,
  mentorFirstName,
  duration
) {
  console.log(
    "finaldata",
    userId,
    sessionNum,
    requestNum,
    session,
    mentorId,
    endTime,
    starttime,
    mentorFirstName,
    duration
  );
  const formData = {
    ...(requestNum && { requestNumber: requestNum }),
    ...(sessionNum && { sessionNumber: sessionNum }),
    status: session.status ? session.status : RequestStatus.active,
    ...(starttime && { startTime: starttime }),
    ...(endTime && { endTime: endTime }),

    slot: getsSessionObject(
      userId,
      session,
      mentorId,
      duration,
      mentorFirstName,
      starttime,
      endTime
    ),
  };

  return formData;
}

export function StipeBookaSession(
  userId,
  session,
  mentorId,
  endTime,
  starttime,
  menteeFirstName,
  menteeLastName,
  mentorFirstName,
  mentorLastName,
  duration
) {
  const formData = {
    ...(session.title && { title: session.title }),
    ...(session.description && { description: session.description }),
    ...(userId && { userId: userId }),

    status: session.status ? session.status : RequestStatus.active,

    ...(mentorId && { mentorId: mentorId }),
    ...(session.numberOfSessions && {
      numberOfSessions: session.numberOfSessions,
    }),
    ...(session.areasOfInterest && {
      category: JSON.stringify(session.areasOfInterest),
    }),

    ...(starttime && { startTime: starttime }),
    ...(endTime && { endTime: endTime }),
    ...(session.files && { files: JSON.stringify(session.files) }),

    ...(menteeFirstName && { menteeFirstName: menteeFirstName }),
    ...(menteeLastName && { menteeLastName: menteeLastName }),
    ...(mentorFirstName && { mentorFirstName: mentorFirstName }),
    ...(mentorLastName && { mentorLastName: mentorLastName }),
    sessionNumber: uuid(),
    requestNumber: uuid(),
    paymentUrl: window.location.origin + "/",
  };

  return formData;
}
