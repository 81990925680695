//** Key and Value of Cognito Translations -> Check with Value name in en.language.json **//

export function CognitoTranslations(t) {
  return {
    key: "cognitoTranslations",
    value: {
      userNotFoundException: t("cognitoErrors.UserNotFoundException"),
      phoneNumberAlreadyExists: t("cognitoErrors.PhoneNumberAlreadyExists"),
      emailAlreadyExists: t("cognitoErrors.emailAlreadyExists"),
    },
  };
}
