//** GinieHealth Assessment Courses Deatils UI,StaicData was Implement,Currently not using,Infurture we would implementing this code **//

import React, { useEffect, useState } from "react";
import { Col, Row, Button, Card, Dropdown, ProgressBar } from "react-bootstrap";
import "./searchAssessment.scss";
import searchAssessmentsmain from "../../../../src/assets/images/searchAssessmentsmain.svg";
import { _getImage } from "../../../common/utils/commonFunctions";
import PersonImage from "../../../assets/images/person.png";
import Rating from "react-rating";

const tempData = [
  {
    title: "Basic Calculus: 100 Questions",
    price: 499,
    author: "Daniel Walter Scott",
    duration: "3 HR",
    numberOfQuestions: 100,
    rating: 1.5,
    currency: "\u20B9",
    status: "draft",
  },
  {
    title: "JEE 2015 Question Paper",
    price: 499,
    author: "Daniel Walter Scott",
    duration: "3 HR",
    numberOfQuestions: 100,
    rating: 1,
    currency: "\u20B9",
    status: "published",
  },
  {
    title: "JEE 2016 Question Paper",
    price: 499,
    author: "Daniel Walter Scott",
    duration: "3 HR",
    numberOfQuestions: 100,
    rating: 1,
    currency: "\u20B9",
    status: "active",
  },
  {
    title: "JEE 2017 Question Paper",
    price: 499,
    author: "Daniel Walter Scott",
    duration: "3 HR",
    numberOfQuestions: 100,
    rating: 1,
    currency: "\u20B9",
    status: "active",
  },
];

const SearchAssessment = (props) => {
  return (
    <>
      <Row className="mt-3 mb-3 checking">
        <Col className="sub-Category md-2">Jee</Col>
        <Col className="sub-Category md-2">Neet</Col>
        <Col className="sub-Category md-2">Banking</Col>
        <Col className="sub-Category md-2">Nursing</Col>
        <Col className="sub-Category md-2">AWS</Col>
        {/* <Col className="sub-Category md-2">Class 5</Col>
        <Col className="sub-Category md-2">Filter</Col> */}

        <Col className="sub-Category md-2">
          <div className="toggle-downArrow">
            <Dropdown>
              <Dropdown.Toggle>
                Class 5
                <img src={_getImage("arrow-down.svg")} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>Class 6</Dropdown.Item>
                <Dropdown.Item>Class 7</Dropdown.Item>
                <Dropdown.Item>Class 8</Dropdown.Item>
                <Dropdown.Item>Class 9</Dropdown.Item>
                <Dropdown.Item>Class 10</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
        <Col className="sub-Category md-2">
          <div className="toggle-downArrow">
            <Dropdown>
              <Dropdown.Toggle>Filter</Dropdown.Toggle>
              <Dropdown.Menu>
                {/* <img src={_getImage("arrow-down.svg")} /> */}
                <Dropdown.Item>
                  Ratings
                  {/* <img src={_getImage("arrow-down.svg")} /> */}
                </Dropdown.Item>
                <Dropdown.Item>Video Duration</Dropdown.Item>
                <Dropdown.Item>Topics</Dropdown.Item>
                <Dropdown.Item>SubCategory</Dropdown.Item>
                <Dropdown.Item>Level</Dropdown.Item>
                <Dropdown.Item>Price</Dropdown.Item>
                <Dropdown.Item>Features</Dropdown.Item>
                <Dropdown.Item>Subtitle</Dropdown.Item>
                <Dropdown.Item>Language</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="align-items-center justify-content-center d-flex ">
          <Card className="w-75">
            <img src={searchAssessmentsmain} />
          </Card>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col className="align-items-center justify-content-center d-flex">
          {tempData.map((item) => (
            <Card
              className="m-0 p-2 mx-2 my-2 shadow border-0"
              style={{ width: "15rem" }}
            >
              <div className="img-container">
                <Dropdown className="icon-container">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <p className="three-dots">...</p>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>Publish</Dropdown.Item>
                    <Dropdown.Item>Activate/Deactivate</Dropdown.Item>
                    <Dropdown.Item>Edit</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Card.Img variant="top" src={PersonImage} />
              </div>
              <div className="title d-flex justify-content-between my-1">
                <p>{item.title}</p>
                <p>
                  {item.currency}
                  {item.price}
                </p>
              </div>
              <p className="author mb-1">{item.author}</p>
              <div className="rating d-flex justify-content-between">
                <p>
                  {item.duration}({item.numberOfQuestions}) Questions
                </p>
                <Rating
                  placeholderRating={item.rating ? item.rating : 0}
                  readonly={true}
                  emptySymbol={
                    <img
                      height={14}
                      width={14}
                      src={_getImage("empty_star.svg")}
                      className="icon"
                    />
                  }
                  placeholderSymbol={
                    <img
                      height={14}
                      width={14}
                      src={_getImage("full_blue_star.svg")}
                      className="icon"
                    />
                  }
                  fullSymbol={
                    <img
                      height={14}
                      width={14}
                      src={_getImage("full_blue_star.svg")}
                      className="icon"
                    />
                  }
                />
              </div>
            </Card>
          ))}
        </Col>
      </Row>
    </>
  );
};
export default SearchAssessment;
