//**Iguroo Admin Mentor Reject Testimonails UI code**//

import React, { useState } from "react";
import { postApproveTestimonials } from "../../services/axios/Testimonials";
import Toaster from "../../components/toaster";
import { toast } from "react-toastify";

const Block = (props) => {
  const [disabledRow, setDisabledRow] = useState(false);

  const handleBlock = () => {
    //Reject testimonials
    if (props.data.testimonialId || props.data.mentorId) {
      const data = {
        mentorId: props.data.mentorId,
        testimonialId: props.data.testimonialId,
        status: "REJECTED",
        testimonialText: props.data.testimonialText,
      };
      console.log(data, "approve-testi");
      postApproveTestimonials(data)
        .then((res) => {
          props.getTestimonials();
          Toaster(toast.success, "Testimonial Rejected");
          console.log(data, "approve-testi");
          setDisabledRow(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      {props.Status ? (
        <button
          disabled={true}
          onClick={handleBlock}
          className="btn btn-sm btn-danger rounded-5"
        >
          Rejected
        </button>
      ) : (
        <button onClick={handleBlock} className="btn btn-danger btn-sm">
          Reject
        </button>
      )}
    </>
  );
};

export default Block;