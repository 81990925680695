//** SwotContainer Assessment Popup for Iguroo,Will be use these component in future **//

import { useContext, useEffect, useMemo, useState } from "react";
import { SwotScreen } from "./SwotScreen";
import { useSwotConatiner } from "./useSwotContainer";
import { AuthContext } from "../../hooks/auth.context.provider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { swotAssessmentYup } from "../../FormSchemas/swot.assessment";
import { useIgurooTranslations } from "../../hooks";
import { SwotPopup } from "../../components/SwotComponents/SwotPopup";
import { SwotButton } from "../../components/SwotComponents/SwotButton";
import { useNavigate } from "react-router-dom";
import { AppUrlsEnum, ProfileType } from "../../enums";
import { SwotExamplePopupView } from "../../components/SwotComponents/SwotExamplePopupView";
import { swotExampleData } from "../../helpers/combiners/swot.example.data";
import { useSelector } from "react-redux";

export const SwotContainer = () => {
  const authContext = useContext(AuthContext);
  const [showPopup, setShowPopup] = useState(false);
  const [showExamplePopup, setShowExamplePopup] = useState(false);
  const { formElementLabels, formErrors } = useIgurooTranslations();
  const swotExamplePopupData = useMemo(() => {
    const ex = swotExampleData(formElementLabels);
    //console.debug("ex is ", ex);
    return ex;
  }, [formElementLabels?.placeHolders?.swotTypes?.swotExamples]);
  const navigate = useNavigate();
  const {
    addSwotData,
    deleteSwotData,
    saveSwotData,
    swotDataValue,
    updateSwotData,
  } = useSwotConatiner();
  //console.debug("swot data value", swotDataValue);
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {
      ...swotDataValue,
    },
    resolver: yupResolver(swotAssessmentYup(formErrors)),
  });
  const user = useSelector((state) => state.user.profile);

  useEffect(() => {
    if (swotDataValue) {
      reset({
        ...swotDataValue,
      });
    }
  }, [swotDataValue]);

  const onSubmit = async (data) => {
    //console.debug("swot data submitted", data);
    data.userId = authContext?.sessionInfo?.sub;
    const d = await saveSwotData(data);
    //console.debug("swot return data", d);
    if (d) setShowPopup(true);
  };
  //console.debug("swot container", errors);
  const onEndOnHandleClose = () => {
    setShowPopup(false);
  };
  const onEndOnButtonOneClick = () => {
    navigate(AppUrlsEnum.findMentor);
  };
  const onEndOnButtonTwoClick = () => {
    if (user.type === ProfileType.mentee) {
      navigate(AppUrlsEnum.menteeDashboard);
    }
    if (user.type === ProfileType.mentor) {
      navigate(AppUrlsEnum.mentorDashboard);
    }
  };

  const onExampleOnHandleClose = () => {
    setShowExamplePopup(false);
  };

  const onCancelClick = () => {
    navigate(AppUrlsEnum.selfDiscovery);
  };
  const onExampleClick = () => {
    setShowExamplePopup(true);
  };
  return (
    <>
      {/* <div className="text-right">
        <SwotButton
          type="submit"
          text={formElementLabels?.placeHolders?.swotTypes?.swotExampleButton}
          className={"swot-example-button"}
          onClick={() => setShowExamplePopup(true)}
          // disabled={ !isValid}
        />
      </div> */}
      <SwotPopup
        closeOnBackClick={false}
        showPopup={showPopup}
        type="popup"
        submitText={
          formElementLabels?.placeHolders?.swotTypes?.swotEndPopupButtonTwo
        }
        buttonTextOne={
          formElementLabels?.placeHolders?.swotTypes?.swotEndPopupButtonOne
        }
        modalBody={
          formElementLabels?.placeHolders?.swotTypes?.swotEndPopupBodyOne
        }
        modalBodyTwo={
          formElementLabels?.placeHolders?.swotTypes?.swotEndPopupBodyTwo
        }
        title={formElementLabels?.placeHolders?.swotTypes?.swotEndPopupHeader}
        hideSVG={true}
        className={"swot-popup"}
        infoText={true}
        onHandleClose={onEndOnHandleClose}
        buttonOneOnClick={onEndOnButtonOneClick}
        buttonTwoOnClick={onEndOnButtonTwoClick}
      />
      <SwotPopup
        closeOnBackClick={true}
        showPopup={showExamplePopup}
        type="popup"
        submitText={formElementLabels?.close}
        hideSVG={true}
        className={"swot-popup-example"}
        infoText={true}
        onHandleClose={onExampleOnHandleClose}
        buttonTwoOnClick={onExampleOnHandleClose}
        title={formElementLabels?.placeHolders?.swotTypes?.swotExampleHeader}
      >
        <SwotExamplePopupView exampleData={swotExamplePopupData} />
      </SwotPopup>
      <SwotScreen
        addSwotData={addSwotData}
        deleteSwotData={deleteSwotData}
        saveSwotData={saveSwotData}
        swotDataValue={swotDataValue}
        updateSwotData={updateSwotData}
        userId={authContext?.sessionInfo?.sub}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        getValues={getValues}
        errors={errors}
        control={control}
        onCancelClick={onCancelClick}
        onExampleClick={onExampleClick}
      />
    </>
  );
};
