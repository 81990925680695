//** Iguroo Assessment Page Heading Component **//

import React from "react";

const PageContainer = ({ children, className }) => {
  return (
    <div className="page_container">
      <div className={`${className}`}>
        <div className="white_bg">
          <div className="py-2">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PageContainer;
