//** TextLink can add text with Link Common component **//

import React from "react";
import { Link } from "react-router-dom";

export function TextLink(props) {
  const { url, text, className, onClick } = props;
  return (
    <Link className={`ig-auth-link ${className}`} to={url} onClick={onClick}>
      {props.children?.length > 0 ? props.children : text}
    </Link>
  );
}
