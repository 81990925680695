//** Iguroo SelfAssessment,Button Footer Component,used in AllType Questions of self-Assessemnts **//

import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { _getImage } from "../../../common/utils/commonFunctions";
import { useIgurooTranslations } from "../../../hooks/use.iguroo.translation";

export function ButtonFooter(props) {
  const { formElementLabels } = useIgurooTranslations();
  const {
    className,
    firstButton,
    secondBtn,
    svgSecond,
    svgFirst,
    onFirstBtnClick,
    onSecondBtnClick,
    previousButton,
    svgPrevious,
    onprevBtnClick,
    disabled,
  } = props;

  return (
    <>
      <Row className="ig-assessment-buttonftr d-flex justify-content-between p-3">
        <Col xs={previousButton ? 4 : 6} className="justify-c">
          <Button
            className={`${className} btn btn-outline-dark ig-backCancel-btn`}
            onClick={onFirstBtnClick ? onFirstBtnClick : () => {}}
          >
            {svgFirst ? (
              <>
                {" "}
                <Image
                  height={20}
                  width={20}
                  src={_getImage(svgFirst)}
                  className=""
                />{" "}
              </>
            ) : (
              ""
            )}
            {firstButton ? firstButton : formElementLabels?.buttonCancel}
          </Button>
        </Col>
        <Col xs={previousButton ? 8 : 6} className="d-flex justify-content-end">
          {previousButton ? (
            <Button
              className="btn btn-outline-dark ig-backCancel-btn me-2"
              onClick={onprevBtnClick ? onprevBtnClick : () => {}}
            >
              {svgPrevious ? (
                <>
                  {" "}
                  <Image
                    height={20}
                    width={20}
                    src={_getImage(svgPrevious)}
                    className=""
                  />{" "}
                </>
              ) : (
                ""
              )}
              {previousButton}
            </Button>
          ) : (
            ""
          )}
          <Button
            className="btn btn-outline-dark ig-contine-btn"
            onClick={onSecondBtnClick ? onSecondBtnClick : () => {}}
            disabled={disabled}
          >
            {secondBtn ? secondBtn : formElementLabels?.buttonContinue}
            {svgSecond ? (
              <>
                {" "}
                <Image
                  height={20}
                  width={20}
                  src={_getImage(svgSecond)}
                  className=""
                />{" "}
              </>
            ) : (
              ""
            )}
          </Button>
        </Col>
      </Row>
    </>
  );
}
