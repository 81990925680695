//** Mentee Login --> Can view Mentor Details Aggrid of Requests **//
//** AgGrid Data in helpers-->gird.helpers.js File**//

import { useMemo } from "react";
import { AgGridFieldsEnum } from "../enums";
import { getMenteeGridDefinitions } from "../helpers/grid.helpers";
import { useIgurooTranslations } from "../hooks/use.iguroo.translation";

export function useMenteeRequestDefinitions() {
  const { gridColumnDefinitions } = useIgurooTranslations();
  const headers = useMemo(() => {
    return getMenteeGridDefinitions(gridColumnDefinitions);
  }, [gridColumnDefinitions]);

  const fieldsToHide = useMemo(() => {
    return [AgGridFieldsEnum.category, AgGridFieldsEnum.status];
  }, headers);

  return { headers, fieldsToHide };
}