//** Initial Route after Login for Iguroo & GinieHealth **//

import { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AuthStatus, OnboardingSteps, ProfileType } from "../enums";
import { AuthContext } from "../hooks/auth.context.provider";
import {
  getUserAsyncThunk,
  userProfileOnboardingStageInitialValue,
} from "../redux-tk/slices/user.slice";
import { useLocation } from "react-router-dom";
import { AppUrlsEnum } from "../enums";
import {
  decrementLoader,
  incrementLoader,
} from "../redux-tk/slices/loader.slice";
import { isMentorOrPre } from "../helpers";
import { extractOrganization } from "../common/utils/commonFunctions";
import { changeOrganization } from "../redux-tk/slices/general.info.slice";

export const InitialRoute = (props) => {
  const authContext = useContext(AuthContext);
  const userOnboardingStage = useSelector((state) =>
    userProfileOnboardingStageInitialValue(state)
  );
  const userType = useMemo(() => {
    console.log("role being renewed", authContext.sessionInfo.role);
    return authContext.sessionInfo.role?.toUpperCase();
  }, [authContext.sessionInfo.role]);

  const authStatus = useMemo(() => {
    return authContext.authStatus;
  }, [authContext.authStatus]);
  const location = useLocation();
  const { pathname, search } = location;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let loaded = false;
  let loadUser = false;
  let onceLoaded = false;

  const authRotes = [
    "/",
    AppUrlsEnum.authSignup,
    AppUrlsEnum.authSignin,
    AppUrlsEnum.verifyMobile,
    AppUrlsEnum.authSigninSocial,
    AppUrlsEnum.authForgetPassword,
    AppUrlsEnum.authResetPassword,
    AppUrlsEnum.authUserOtp,
    AppUrlsEnum.dndTest,
  ];

  const adminRoutes = [
    AppUrlsEnum.adminDashboard,
    AppUrlsEnum.adminUsers,
    AppUrlsEnum.adminUserManagement,
    AppUrlsEnum.adminSiteAdministration,
    AppUrlsEnum.adminProfilePublicPrivate,
    AppUrlsEnum.approveTestimonial,
    AppUrlsEnum.updateTestimonial,
    AppUrlsEnum.assessmentReportAdmin,
    AppUrlsEnum.recommendations,
  ];

  const assessmentDone = useSelector((state) => state.user);

  console.log("checked initital route", assessmentDone);

  useEffect(() => {
    if (authContext.sessionInfo.sub) {
      console.log(
        "running,",
        onceLoaded,
        authContext.sessionInfo.sub,
        authStatus
      );
      (async function () {
        dispatch(incrementLoader());
        const organization =  extractOrganization(window.location.origin);
        localStorage.setItem('organization_id',organization )
        await dispatch(getUserAsyncThunk({ userId: authContext.sessionInfo.sub }));
        
       
        console.log(organization,'organization')
        dispatch(changeOrganization(organization))

        dispatch(decrementLoader());
      })();
    }
    return () => {
      onceLoaded = true;
    };
  }, [authStatus]);
  console.log("using user", userOnboardingStage, userType, authStatus);

  useEffect(() => {
    (async function () {
      console.log("called-before-2", location);
      const urlToNavigateTo = pathname + search;
      if (
        !loaded &&
        authStatus === AuthStatus.SignedInTokensGot &&
        userType === ProfileType.admin
      ) {
        if (adminRoutes.includes(pathname)) {
          navigate(urlToNavigateTo);
        } else {
          navigate(AppUrlsEnum.adminDashboard);
        }
      }
      if (
        !loaded &&
        authStatus === AuthStatus.SignedInTokensGot &&
        userOnboardingStage != null
      ) {
        try {
          console.log("using user", userOnboardingStage, userType, authStatus);
          const onboarding = userOnboardingStage ? userOnboardingStage : 0;
          if (isMentorOrPre(userType)) {
            if (onboarding !== 6) {
              const url =
                OnboardingSteps[ProfileType.mentor][onboarding + 1].url;
              navigate(url);
              console.log("using user navigated to ", url);
            } else if (onboarding === 6) {
              if (authRotes.includes(pathname)) {
                const meetingLink = localStorage.getItem("meetingLink");
                if (meetingLink) {
                  navigate(meetingLink);
                } else {
                  navigate(AppUrlsEnum.mentorDashboard);
                }
                console.log(
                  "using user mentor navigating to auth",
                  authContext.authStatus,
                  pathname,
                  AppUrlsEnum.menteeDashboard
                );
              } else {
                navigate(urlToNavigateTo);
                console.log("using user mentor navigating to path", pathname);
              }
            }
          } else if (userType === ProfileType.mentee) {
            if (onboarding != 3) {
              const url =
                OnboardingSteps[ProfileType.mentee][onboarding + 1].url;
              navigate(url);
              console.log("using user navigated to ", url);
            } else if (onboarding === 3) {
              if (authRotes.includes(pathname)) {
                if (localStorage.getItem("bookSession")) {
                  navigate(
                    `${
                      AppUrlsEnum.menteeMentorProfile
                    }?mentorId=${localStorage.getItem("mentorId")}`
                  );
                } else {
                  const meetingLink = localStorage.getItem("meetingLink");
                  if (meetingLink) {
                    navigate(meetingLink);
                  } else {
                    navigate(AppUrlsEnum.menteeDashboard);
                  }

                  if (
                    !assessmentDone.assessmentCompleted ||
                    assessmentDone.assessmentPending
                  ) {
                    navigate(AppUrlsEnum.selfDiscovery);
                  }
                }
                console.log(
                  "using user navigating to auth",
                  authContext.authStatus,
                  pathname,
                  AppUrlsEnum.menteeDashboard
                );
              } else {
                navigate(urlToNavigateTo);
                console.log("using user navigating to path", pathname);
              }
            }
          }
        } catch (err) {
          console.log("using user error", err);
        }
      }
      if (
        !loaded &&
        authStatus === AuthStatus.SignedInTokensGot &&
        userType === ProfileType.admin
      ) {
        if (authRotes.includes(pathname)) {
          if (process.env.REACT_APP_IS_MENU === "false") {
            navigate(AppUrlsEnum.superVisor);
            console.log("using user navigating to path", pathname);
          } else {
            navigate(AppUrlsEnum.adminUsers);
            console.log("using user navigating to path", pathname);
          }
        } else {
          navigate(urlToNavigateTo);
        }
      }
      if (
        !loaded &&
        authStatus === AuthStatus.SignedInTokensGot &&
        userType === ProfileType.learner
      ) {
        if (authRotes.includes(pathname)) {
          navigate(AppUrlsEnum.learnermyassessment);
        } else {
          navigate(urlToNavigateTo);
          console.log("using user navigating to path", pathname);
        }
      }
      if (
        !loaded &&
        authStatus === AuthStatus.SignedInTokensGot &&
        userType === ProfileType.author
      ) {
        if (authRotes.includes(pathname)) {
          navigate(AppUrlsEnum.authorUserPage);
        } else {
          navigate(urlToNavigateTo);
          console.log("using user navigating to path", pathname);
        }
      }
      if (
        !loaded &&
        authStatus === AuthStatus.SignedInTokensGot &&
        userType === ProfileType.publisher
      ) {
        if (authRotes.includes(pathname)) {
          navigate(AppUrlsEnum.publisherUser);
        } else {
          navigate(urlToNavigateTo);
          console.log("using user navigating to path", pathname);
        }
      }
      if (
        !loaded &&
        authStatus === AuthStatus.SignedInTokensGot &&
        userType === ProfileType.supervisor
      ) {
        if (authRotes.includes(pathname)) {
          navigate(AppUrlsEnum.superVisorUser);
        } else {
          navigate(urlToNavigateTo);
          console.log("using user navigating to path", pathname);
        }
      }
      if (
        !loaded &&
        authStatus === AuthStatus.SignedInTokensGot &&
        userType === ProfileType.compliance
      ) {
        if (authRotes.includes(pathname)) {
          navigate(AppUrlsEnum.compliancePublisher);
        } else {
          navigate(urlToNavigateTo);
          console.log("using user navigating to path", pathname);
        }
      }
    })();

    return () => {
      loaded = true;
      loadUser = true;
    };
  }, [userOnboardingStage, userType, authStatus]);
  return <>{props.children}</>;
};
