//** Iguroo & GinieHealth Image Uploading Field with Required Data storage,can re-use required filed**//

import React from "react";
import { useEffect, useMemo, useState } from "react";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { _getImage } from "../../common/utils/commonFunctions";
import { ImgSrcEnums } from "../../enums";
import {
  getImageFromKeyAsyncThunk,
  imageValueSelector,
} from "../../redux-tk/slices/images.slice";

export const CustomImage = ({
  roundedCircle,
  resizeMode,
  className,
  src,
  onClick,
  crossOrigin,
  imgRef,
  alt,
  style,
  onLoad,
  width,
  height,
  isPublicProfile,
  defaultImage,
}) => {
  const [imgSrc, setImgSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const imageValue = useSelector((state) => imageValueSelector(state, src.src));
  const memoImageValue = useMemo(() => {
    console.log("key is useMemo final", imgSrc, "val", imageValue);
    if (imgSrc?.del) {
      return defaultImage ? defaultImage : _getImage("avatar.svg");
    }
    return imageValue?.url
      ? imageValue?.url
      : imgSrc?.src
      ? imgSrc?.src
      : defaultImage
      ? defaultImage
      : _getImage("avatar.svg");
  }, [imageValue?.url, imgSrc?.src]);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("key is final", src);
    if (src) {
      //console.log("dispatching key",src,Date.now() + imageValue?.expiresIn,imageValue?.expires);
      (async function () {
        //console.log("dispatching key",src,Date.now() + imageValue?.expiresIn,imageValue?.expires);
        if (src.type === ImgSrcEnums.props) {
          if (!imageValue || Date.now() >= imageValue?.expires) {
            try {
              console.log(
                "dispatching key",
                src,
                Date.now() + imageValue?.expiresIn,
                imageValue?.expires
              );
              setIsLoading(true);
              //const img = await authContext.getSignedImageFromKey(src.src);
              //const img = await getUploadFileFromS3(src.src);
              //console.log("base64img", img);
              console.log("presigned url final", imageValue);
              setImgSrc(null);
              //https://iguroo-s3-data-sb.s3.amazonaws.com/f22b35e2-7519-41e9-a52d-d2dfe4a61f69/images/pp-size.jpeg
              dispatch(
                getImageFromKeyAsyncThunk({ key: src.src, isPublicProfile })
              );
            } catch (err) {
              console.log("image error", err);
            } finally {
              setIsLoading(false);
            }
          }
        } else {
          setImgSrc(src);
        }
      })();
    }
  }, [src.src, imageValue?.url]);
  console.log("image src final", imageValue, memoImageValue);

  return (
    <Image
      {...(roundedCircle ? { roundedCircle: roundedCircle } : {})}
      {...(resizeMode ? { resizeMode: resizeMode } : {})}
      {...(className ? { className: className } : {})}
      {...(onClick ? { onClick: onClick } : {})}
      {...(crossOrigin ? { crossOrigin: crossOrigin } : {})}
      {...(imgRef ? { ref: imgRef } : {})}
      {...(alt ? { alt: alt } : {})}
      {...(style ? { style: style } : {})}
      {...(onLoad ? { onLoad: onLoad } : {})}
      {...(width ? { width: width } : {})}
      {...(height ? { height: height } : {})}
      src={memoImageValue}
    />
  );
};
