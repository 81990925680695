import React from "react";
import ProfileScreen from "../../mentor/profileScreen";

const MainInfo = () => {
  return (
    <>
      <p className="fs-18 fw-700 mt-4 ps-3">About Yourself</p>
      <ProfileScreen settings={true} />
    </>
  );
};

export default MainInfo;
