//** Iguroo SWOT Assessment ->Swot Button Component,Can re-use anywhere in required Field**//
//** Currently not using this code,will be implemented in future **//

import React from "react";
import { Button } from "react-bootstrap";

export function SwotButton({
  text,
  className,
  disabled,
  variant,
  type,
  onClick,
}) {
  return (
    <Button
      className={`submit-btn btn btn-block ${className}`}
      {...(disabled && { disabled: disabled })}
      variant={variant ? variant : "primary"}
      type={type ? type : "submit"}
      onClick={onClick}
    >
      {text}
    </Button>
  );
}
