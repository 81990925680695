import React from "react";
import { MyMentorsList } from "./mentee.montorList";

const MenteeMentors = () => {
  return (
    <>
      <MyMentorsList />
    </>
  );
};

export default MenteeMentors;
