//** Iguroo Aggrid main,Request Session Data Field,can re-use required field **//

import moment from "moment";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { getCompletedSessions } from "../../helpers/grid.helpers";
import { getSessionList } from "../../services/axios/requests";
import { RequestProgressBar } from "../RequestCard/request.progress.bar";

export const RequestRenderer = ({ value, data }) => {
  const [requestSessions, setRequestSessions] = useState();
  const [lastSession, setLastSession] = useState();
  let active = false;
  useEffect(() => {
    if (!active) {
      (async function () {
        try {
          if (!value) return;
          console.log("req value", value);
          const sessions = await getSessionList(value);
          const completedSessions = getCompletedSessions(sessions?.Items);
          const noOfSessions = data?.numberOfSessions;
          //console.log("request.sessions", sessions,data,completedSessions);
          setLastSession(sessions?.Items[0]);
          setRequestSessions(completedSessions);
        } catch (err) {
          console.log(
            "err getting sessions",
            "value",
            value,
            err.response,
            "req",
            err.request,
            "mess",
            err.message,
            "config",
            err.config
          );
        }
      })();
    }
    return () => {
      active = true;
    };
  }, [value]);

  const lastSessionDate = useMemo(() => {
    if (lastSession) {
      return moment
        .unix(new Date(parseInt(lastSession?.startTime)))
        .format("DD/MM/YYYY . hh:mm A");
    } else {
      return "";
    }
  }, [lastSession]);

  if (!value) {
    return <></>;
  }

  return (
    <>
      <div>
        {lastSessionDate}
        {/* Would be required for multiple sessions */}
        {/* <RequestProgressBar
          min={0}
          max={data?.numberOfSessions}
          now={requestSessions}
        /> */}
      </div>
    </>
  );
};
