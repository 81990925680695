//** Iguroo CollegesList Courses Table List UI,Currently not using this ,Infurture we would implementing this code **//

import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { Row, Col, Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Header from "../../../components/header/index";
import FooterComponent from "../../../components/footer";
import ReactPaginate from "react-paginate";
import { useParams, useNavigate } from "react-router-dom";
import {
  getCourseData,
  getProgramsData,
} from "../../../services/axios/Colleges";
import { FormHeading } from "../../../components/form.heading.component";
import { SubmitButton } from "../../../components/submit.button";
import { AppUrlsEnum } from "../../../enums";

const Course = () => {
  const [pagenumber, setPageNumber] = useState(0);
  const [courses, setCourses] = useState([]);
  const [collegeName, setCollegeName] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [loading, setloading] = useState(true);
  const { iguroo_institute_id } = useParams();
  const scollToRef = useRef();
  const navigate = useNavigate();
  const usersPerPage = 10;
  const pageVisited = pagenumber * usersPerPage;
  const displayUsers = programs.slice(pageVisited, pageVisited + usersPerPage);
  const pageCount = Math.ceil(programs.length / usersPerPage);

  useEffect(() => {
    const fetchCourseData = async () => {
      getCourseData(iguroo_institute_id).then((json) => {
        setCourses(json.courseDetail.Items);
        setCollegeName(json.collegeName);
        if (loading) setloading(false);
      });
    };
    fetchCourseData();
  }, [loading]);

  useEffect(() => {
    const fetchProgramsData = async () => {
      getProgramsData(iguroo_institute_id).then((json) => {
        setPrograms(json);
        if (loading) setloading(false);
      });
    };
    fetchProgramsData();
  }, [loading]);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    // window.scrollTo({ top:0, behavior: "smooth" });
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Header />
      {courses.length > 0 ? (
        <>
          <Row className="headerCol">
            <Col className="justify-content-center">
              <Col className="align-items-start" md="3">
                <SubmitButton
                  onClick={() => navigate(AppUrlsEnum.collegesList)}
                  type="submit"
                  className="d-inline front-button mt-1"
                  text="Back to Colleges"
                />
              </Col>
              <h1 className="inst-name text-center">
                {collegeName.collegeName}
              </h1>
            </Col>
          </Row>
          <Container>
            <Row>
              <Row>
                <Col md="12">
                  <Row className="justify-content-center">
                    <Col md="8">
                      <Table
                        className="table m-2 w-100"
                        bordered
                      >
                        <thead>
                          <tr>
                            <th>Courses</th>
                            <th>Total tuition Fees</th>
                            <th>Eligibility</th>
                          </tr>
                        </thead>
                        <tbody>
                          {courses.map((d, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <span className="courseName">{d.course}</span>
                                  <br></br>
                                  <span className="courseCount">
                                    {d.course_count}
                                  </span>
                                </td>
                                <td>
                                  <div className="fees">{d.fees}</div>
                                  <div className="duration">{d.duration}</div>
                                </td>
                                <td>
                                  <div>
                                    {/* <span className="c985">10+2 :</span> */}
                                    <span className="eligibility">
                                      {d.eligibility}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="c985">Exams :</span>
                                    <span className="exams">
                                      {d.eligibility_exam}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                      <Row className="text-center mt-2" ref={scollToRef}>
                        <h2 className="heading">
                          Showing {programs.length} Courses
                        </h2>
                        <span className="subTxt">
                          Get fees, exams required & eligibility for all
                          courses.
                        </span>
                      </Row>
                      <Row className="cont mb-4">
                        <Row className="mt-2">
                          {displayUsers.map((p, index) => (
                            <Col md="8" lg="6" className="my-2">
                              <Card key={index} className="h-100 cardProg">
                                <Card.Title className="fRow">
                                  <Row>
                                    <Col className="text-start mt-3">
                                      <p className="program-heading">
                                        {p.program_title}
                                      </p>
                                      <p className="years text-end">{p.course_period}</p>
                                    </Col>
                                    {/* <Col className="text-end">
                                      
                                    </Col> */}
                                  </Row>
                                </Card.Title>
                                <Card.Body className="m-0 p-1">
                                  <div className="subCont">
                                    <Card.Text>
                                      <div className="srow">
                                        <Row>
                                          <Col>
                                            <label className="blockLabel">
                                              No. of Seats
                                            </label>
                                            <div className="valueTxt">
                                              {p.no_of_seats}
                                            </div>
                                          </Col>
                                          <Col>
                                            <label className="blockLabel">
                                              Exams
                                            </label>
                                            <div className="valueTxt">
                                              {p.exams}
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                      <div className="trow">
                                        <Row>
                                          <Col>
                                            <label className="blockLabel">
                                              Total Tuition Fees
                                            </label>
                                            <div className="valueTxt">
                                              {p.total_tution_fees}
                                            </div>
                                          </Col>
                                          <Col>
                                            <label className="blockLabel">
                                              Median Salary
                                            </label>
                                            <div className="valueTxt">
                                              {p.median_salary}
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                      <div className="forow">
                                        <Row>
                                          <Col>
                                            <label className="blockLabel">
                                              Course Rating
                                            </label>
                                            <div className="valueTxt">
                                              {p.course_rating}
                                            </div>
                                          </Col>
                                          <Col>
                                            <label className="blockLabel">
                                              Ranked
                                            </label>
                                            <div className="valueTxt">
                                              {p.ranking}
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card.Text>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </Row>
                    </Col>
                    {programs.length > 10 ? (
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"paginationBttns"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"paginationDisabled"}
                          activeClassName={"paginationActive"}
                        />
                      ) : (
                        ""
                      )}
                  </Row>
                </Col>
              </Row>
            </Row>
          </Container>
        </>
      ) : (
        <FormHeading className="text-center mt-4">Loading..</FormHeading>
      )}
      <FooterComponent />
    </>
  );
};

export default Course;
