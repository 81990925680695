//** Key and Value of ReportAssessment Translations -> Check with Value name in en.language.json **//

export function ReportAssessmentTranslations(t) {
  return {
    key: "reportAssessmentTranslations",
    value: {
      reportHeading: t("reportAssessmentTranslations.reportHeading"),
      reportDescription: t("reportAssessmentTranslations.reportDescription"),
      reportFramework: t("reportAssessmentTranslations.reportFramework"),
      completeAssessment: t("reportAssessmentTranslations.completeAssessment"),
      completeAssessment1: t(
        "reportAssessmentTranslations.completeAssessment1"
      ),
      completeAssessment2: t(
        "reportAssessmentTranslations.completeAssessment2"
      ),
      completeAssessment3: t(
        "reportAssessmentTranslations.completeAssessment3"
      ),
      completeAssessment4: t(
        "reportAssessmentTranslations.completeAssessment4"
      ),
      personalityHeading: t("reportAssessmentTranslations.personalityHeading"),
      personalitydesc: t("reportAssessmentTranslations.personalitydesc"),
      personalitydesc1: t("reportAssessmentTranslations.personalitydesc1"),
      personalitydesc2: t("reportAssessmentTranslations.personalitydesc2"),
      personalitydesc3: t("reportAssessmentTranslations.personalitydesc3"),
      personalitydesc4: t("reportAssessmentTranslations.personalitydesc4"),
      analystReport: t("reportAssessmentTranslations.analystReport"),
      conservativeReport: t("reportAssessmentTranslations.conservativeReport"),
      strategistReport: t("reportAssessmentTranslations.strategistReport"),
      socializerReport: t("reportAssessmentTranslations.socializerReport"),
      meaningHeader: t("reportAssessmentTranslations.meaningHeader"),
      meaningdesc: t("reportAssessmentTranslations.meaningdesc"),
      suitableFields: t("reportAssessmentTranslations.suitableFields"),
      suitableFieldsdesc: t("reportAssessmentTranslations.suitableFieldsdesc"),
      suitableFieldsdesc1: t(
        "reportAssessmentTranslations.suitableFieldsdesc1"
      ),
      suitableFieldsdesc2: t(
        "reportAssessmentTranslations.suitableFieldsdesc2"
      ),
      suitableFieldsdesc3: t(
        "reportAssessmentTranslations.suitableFieldsdesc3"
      ),
      suitableFieldsdesc4: t(
        "reportAssessmentTranslations.suitableFieldsdesc4"
      ),
      suitableFieldsdesc5: t(
        "reportAssessmentTranslations.suitableFieldsdesc5"
      ),
      suitableFieldsdesc6: t(
        "reportAssessmentTranslations.suitableFieldsdesc6"
      ),
      suitableFieldsdesc7: t(
        "reportAssessmentTranslations.suitableFieldsdesc7"
      ),
      suitableFieldsdesc8: t(
        "reportAssessmentTranslations.suitableFieldsdesc8"
      ),
      suitableFieldsdesc9: t(
        "reportAssessmentTranslations.suitableFieldsdesc9"
      ),
      suitableFieldsdesc10: t(
        "reportAssessmentTranslations.suitableFieldsdesc10"
      ),
      suitableFieldsdesc11: t(
        "reportAssessmentTranslations.suitableFieldsdesc11"
      ),
      suitableCareer: t("reportAssessmentTranslations.suitableCareer"),
      suitableCareerdesc: t("reportAssessmentTranslations.suitableCareerdesc"),
      suitableCareerdesc1: t(
        "reportAssessmentTranslations.suitableCareerdesc1"
      ),
      suitableCareerdesc2: t(
        "reportAssessmentTranslations.suitableCareerdesc2"
      ),
      suitableCareerdesc3: t(
        "reportAssessmentTranslations.suitableCareerdesc3"
      ),
      suitableCareerdesc4: t(
        "reportAssessmentTranslations.suitableCareerdesc4"
      ),
      suitableCareerdesc5: t(
        "reportAssessmentTranslations.suitableCareerdesc5"
      ),
      suitableCareerdesc6: t(
        "reportAssessmentTranslations.suitableCareerdesc6"
      ),
      suitableCareerdesc7: t(
        "reportAssessmentTranslations.suitableCareerdesc7"
      ),
      suitableCareerdesc8: t(
        "reportAssessmentTranslations.suitableCareerdesc8"
      ),
      suitableCareerdesc9: t(
        "reportAssessmentTranslations.suitableCareerdesc9"
      ),
      suitableCareerdesc10: t(
        "reportAssessmentTranslations.suitableCareerdesc10"
      ),
      suitableCareerdesc11: t(
        "reportAssessmentTranslations.suitableCareerdesc11"
      ),
      suitableCareerdesc12: t(
        "reportAssessmentTranslations.suitableCareerdesc12"
      ),
      conservativeMeaning: t(
        "reportAssessmentTranslations.conservativeMeaning"
      ),
      strategistMeaning: t("reportAssessmentTranslations.strategistMeaning"),
      scoializerMeaning: t("reportAssessmentTranslations.scoializerMeaning"),
      disclaimerHeading: t("reportAssessmentTranslations.disclaimerHeading"),
      disclaimerDesc: t("reportAssessmentTranslations.disclaimerDesc"),
      interestHeading: t("reportAssessmentTranslations.interestHeading"),
      interestDesc: t("reportAssessmentTranslations.interestDesc"),
      interestDesc1: t("reportAssessmentTranslations.interestDesc1"),
      interestDesc2: t("reportAssessmentTranslations.interestDesc2"),
      interestDesc3: t("reportAssessmentTranslations.interestDesc3"),
      interestDesc4: t("reportAssessmentTranslations.interestDesc4"),
      interestDesc5: t("reportAssessmentTranslations.interestDesc5"),
      interestDesc6: t("reportAssessmentTranslations.interestDesc6"),
      interestDesc7: t("reportAssessmentTranslations.interestDesc7"),
      interestDesc8: t("reportAssessmentTranslations.interestDesc8"),
      interestDesc9: t("reportAssessmentTranslations.interestDesc9"),
      realisticHeading: t("reportAssessmentTranslations.realisticHeading"),
      realisticMeaning: t("reportAssessmentTranslations.realisticMeaning"),
      investiveHeading: t("reportAssessmentTranslations.investiveHeading"),
      investiveMeaning: t("reportAssessmentTranslations.investiveMeaning"),
      artisticHeading: t("reportAssessmentTranslations.artisticHeading"),
      artisticMeaning: t("reportAssessmentTranslations.artisticMeaning"),
      socialHeading: t("reportAssessmentTranslations.socialHeading"),
      socialMeaning: t("reportAssessmentTranslations.socialMeaning"),
      enterpriceHeading: t("reportAssessmentTranslations.enterpriceHeading"),
      enterpriceMeaning: t("reportAssessmentTranslations.enterpriceMeaning"),
      conventionHeading: t("reportAssessmentTranslations.conventionHeading"),
      conventionMeaning: t("reportAssessmentTranslations.conventionMeaning"),
      interestCode: t("reportAssessmentTranslations.interestCode"),
      suitableFieldsdesc12: t(
        "reportAssessmentTranslations.suitableFieldsdesc12"
      ),
      suitableFieldsdesc13: t(
        "reportAssessmentTranslations.suitableFieldsdesc13"
      ),
      suitableCareerdesc13: t(
        "reportAssessmentTranslations.suitableCareerdesc13"
      ),
      suitableCareerdesc14: t(
        "reportAssessmentTranslations.suitableCareerdesc14"
      ),
      personalitydesc5: t("reportAssessmentTranslations.personalitydesc5"),
      personalitydesc6: t("reportAssessmentTranslations.personalitydesc6"),
      aptiHeading: t("reportAssessmentTranslations.aptiHeading"),
      aptiDesc: t("reportAssessmentTranslations.aptiDesc"),
      aptiDesc1: t("reportAssessmentTranslations.aptiDesc1"),
      aptiDesc2: t("reportAssessmentTranslations.aptiDesc2"),
      aptiDesc3: t("reportAssessmentTranslations.aptiDesc3"),
      aptiDesc4: t("reportAssessmentTranslations.aptiDesc4"),
      selfMgHeading: t("reportAssessmentTranslations.selfMgHeading"),
      selfMgDesc: t("reportAssessmentTranslations.selfMgDesc"),
      teamwrkHeading: t("reportAssessmentTranslations.teamwrkHeading"),
      teamwrkDesc: t("reportAssessmentTranslations.teamwrkDesc"),
      enterPriHeading: t("reportAssessmentTranslations.enterPriHeading"),
      enterPriDesc: t("reportAssessmentTranslations.enterPriDesc"),
      probSolveHeading: t("reportAssessmentTranslations.probSolveHeading"),
      probSolveDesc: t("reportAssessmentTranslations.probSolveDesc"),
      speakingHeading: t("reportAssessmentTranslations.speakingHeading"),
      speakingDesc: t("reportAssessmentTranslations.speakingDesc"),
      developSkillHeading: t(
        "reportAssessmentTranslations.developSkillHeading"
      ),
      developSkillDesc: t("reportAssessmentTranslations.developSkillDesc"),
      scoringSkillHeading: t(
        "reportAssessmentTranslations.scoringSkillHeading"
      ),
      scoringSkillDesc: t("reportAssessmentTranslations.scoringSkillDesc"),
      strengthHeading: t("reportAssessmentTranslations.strengthHeading"),
      strengthDesc: t("reportAssessmentTranslations.strengthDesc"),
      strengthDesc1: t("reportAssessmentTranslations.strengthDesc1"),
      periodicTable: t("reportAssessmentTranslations.periodicTable"),
      wisdomHeading: t("reportAssessmentTranslations.wisdomHeading"),
      wisdomDesc: t("reportAssessmentTranslations.wisdomDesc"),
      courageHeading: t("reportAssessmentTranslations.courageHeading"),
      courageDesc: t("reportAssessmentTranslations.courageDesc"),
      humanityHeading: t("reportAssessmentTranslations.humanityHeading"),
      humanityDesc: t("reportAssessmentTranslations.humanityDesc"),
      transcenHeading: t("reportAssessmentTranslations.transcenHeading"),
      transcenDesc: t("reportAssessmentTranslations.transcenDesc"),
      justiceHeading: t("reportAssessmentTranslations.justiceHeading"),
      justiceDesc: t("reportAssessmentTranslations.justiceDesc"),
      moderationHeading: t("reportAssessmentTranslations.moderationHeading"),
      moderationDesc: t("reportAssessmentTranslations.moderationDesc"),
      strengthHead: t("reportAssessmentTranslations.strengthHead"),
      strength1: t("reportAssessmentTranslations.strength1"),
      strength2: t("reportAssessmentTranslations.strength2"),
      strength3: t("reportAssessmentTranslations.strength3"),
      balanceSkills: t("reportAssessmentTranslations.balanceSkills"),
      balanceSkillsDesc: t("reportAssessmentTranslations.balanceSkillsDesc"),
      valueChange: t("reportAssessmentTranslations.valueChange"),
      valueChangeDesc: t("reportAssessmentTranslations.valueChangeDesc"),
      openquesHeading: t("reportAssessmentTranslations.openquesHeading"),
      openquesDesc: t("reportAssessmentTranslations.openquesDesc"),
      openquesDesc1: t("reportAssessmentTranslations.openquesDesc1"),
      ques1Heading: t("reportAssessmentTranslations.ques1Heading"),
      ques1Desc: t("reportAssessmentTranslations.ques1Desc"),
      ques2Heading: t("reportAssessmentTranslations.ques2Heading"),
      ques2Desc: t("reportAssessmentTranslations.ques2Desc"),
      topstreamHeading: t("reportAssessmentTranslations.topstreamHeading"),
      topstreamDesc: t("reportAssessmentTranslations.topstreamDesc"),
      recommendHeader: t("reportAssessmentTranslations.recommendHeader"),
      recommendDesc: t("reportAssessmentTranslations.recommendDesc"),
      recommend1Header: t("reportAssessmentTranslations.recommend1Header"),
      recommend1Desc: t("reportAssessmentTranslations.recommend1Desc"),
      recommend2Header: t("reportAssessmentTranslations.recommend2Header"),
      recommend2Desc: t("reportAssessmentTranslations.recommend2Desc"),
      topCareerHeading: t("reportAssessmentTranslations.topCareerHeading"),
      stepsHeading: t("reportAssessmentTranslations.stepsHeading"),
      step1: t("reportAssessmentTranslations.step1"),
      step2: t("reportAssessmentTranslations.step2"),
      step3: t("reportAssessmentTranslations.step3"),
      step4: t("reportAssessmentTranslations.step4"),
      step1Desc: t("reportAssessmentTranslations.step1Desc"),
      step2Desc: t("reportAssessmentTranslations.step2Desc"),
      step2Desc1: t("reportAssessmentTranslations.step2Desc1"),
      step2Desc2: t("reportAssessmentTranslations.step2Desc2"),
      step3Desc: t("reportAssessmentTranslations.step3Desc"),
      step3Desc1: t("reportAssessmentTranslations.step3Desc1"),
      step3Desc2: t("reportAssessmentTranslations.step3Desc2"),
      step4Desc: t("reportAssessmentTranslations.step4Desc"),
      step4Desc1: t("reportAssessmentTranslations.step4Desc1"),
      focus: t("reportAssessmentTranslations.focus"),
      decisions: t("reportAssessmentTranslations.decisions"),
      drive: t("reportAssessmentTranslations.drive"),
      personalityProfile: t("reportAssessmentTranslations.personalityProfile"),
      dataMinings: t("reportAssessmentTranslations.dataMinings"),
      modelingDesc: t("reportAssessmentTranslations.modelingDesc"),
      priorityDesc: t("reportAssessmentTranslations.priorityDesc"),
      connectionDesc: t("reportAssessmentTranslations.connectionDesc"),
      insightDesc: t("reportAssessmentTranslations.insightDesc"),
      collabDesc: t("reportAssessmentTranslations.collabDesc"),
      reflectionDesc: t("reportAssessmentTranslations.reflectionDesc"),
      criticalDesc: t("reportAssessmentTranslations.criticalDesc"),
      adaptableDesc: t("reportAssessmentTranslations.adaptableDesc"),
      resilienceDesc: t("reportAssessmentTranslations.resilienceDesc"),
      toleranceDesc: t("reportAssessmentTranslations.toleranceDesc"),
      imageDesc: t("reportAssessmentTranslations.imageDesc"),
      activityDesc: t("reportAssessmentTranslations.activityDesc"),
      commitDesc: t("reportAssessmentTranslations.commitDesc"),
      problemDesc: t("reportAssessmentTranslations.problemDesc"),
      visionDesc: t("reportAssessmentTranslations.visionDesc"),
      designDesc: t("reportAssessmentTranslations.designDesc"),
      enterpriceDesc: t("reportAssessmentTranslations.enterpriceDesc"),
      l1Heading: t("reportAssessmentTranslations.l1Heading"),
      l2Heading: t("reportAssessmentTranslations.l2Heading"),
      r1Heading: t("reportAssessmentTranslations.r1Heading"),
      r2Heading: t("reportAssessmentTranslations.r2Heading"),
      realisticDesc: t("reportAssessmentTranslations.realisticDesc"),
      investigateDesc: t("reportAssessmentTranslations.investigateDesc"),
      atristicDesc: t("reportAssessmentTranslations.atristicDesc"),
      socialDesc: t("reportAssessmentTranslations.socialDesc"),
      enterpriDesc: t("reportAssessmentTranslations.enterpriDesc"),
      conventionDesc: t("reportAssessmentTranslations.conventionDesc"),
      sixDimensions: t("reportAssessmentTranslations.sixDimensions"),
      intresetRate: t("reportAssessmentTranslations.intresetRate"),
      analystRate: t("reportAssessmentTranslations.analystRate"),
      profileScore: t("reportAssessmentTranslations.profileScore"),
      aptiRate: t("reportAssessmentTranslations.aptiRate"),
      aptiScore: t("reportAssessmentTranslations.aptiScore"),
      intresetScore: t("reportAssessmentTranslations.intresetScore"),
      listenCommunication: t(
        "reportAssessmentTranslations.listenCommunication"
      ),
      assessSummary: t("reportAssessmentTranslations.assessSummary"),
      assess1Summary: t("reportAssessmentTranslations.assess1Summary"),
      assess2Summary: t("reportAssessmentTranslations.assess2Summary"),
      assess3Summary: t("reportAssessmentTranslations.assess3Summary"),
      assess4Summary: t("reportAssessmentTranslations.assess4Summary"),
      assessmentReport: t("reportAssessmentTranslations.assessmentReport"),
      reportDesc: t("reportAssessmentTranslations.reportDesc"),
      congratsHeading: t("reportAssessmentTranslations.congratsHeading"),
      selfDesc1: t("reportAssessmentTranslations.selfDesc1"),
      selfDesc2: t("reportAssessmentTranslations.selfDesc2"),
      selfDesc3: t("reportAssessmentTranslations.selfDesc3"),
      selfDesc4: t("reportAssessmentTranslations.selfDesc4"),
      selfDesc5: t("reportAssessmentTranslations.selfDesc5"),
      selfDesc6: t("reportAssessmentTranslations.selfDesc6"),
      selfDesc7: t("reportAssessmentTranslations.selfDesc7"),
      congratsDesc: t("reportAssessmentTranslations.congratsDesc"),
      periodTab1: t("reportAssessmentTranslations.periodTab1"),
      periodTab2: t("reportAssessmentTranslations.periodTab2"),
      periodTab3: t("reportAssessmentTranslations.periodTab3"),
      periodTab4: t("reportAssessmentTranslations.periodTab4"),
      periodTab5: t("reportAssessmentTranslations.periodTab5"),
      periodTab6: t("reportAssessmentTranslations.periodTab6"),
      periodTab7: t("reportAssessmentTranslations.periodTab7"),
      periodTab8: t("reportAssessmentTranslations.periodTab8"),
      periodTab9: t("reportAssessmentTranslations.periodTab9"),
      periodTab10: t("reportAssessmentTranslations.periodTab10"),
      periodTab11: t("reportAssessmentTranslations.periodTab11"),
      periodTab12: t("reportAssessmentTranslations.periodTab12"),
      periodTab13: t("reportAssessmentTranslations.periodTab13"),
      periodTab14: t("reportAssessmentTranslations.periodTab14"),
      periodTab15: t("reportAssessmentTranslations.periodTab15"),
      periodTab16: t("reportAssessmentTranslations.periodTab16"),
      periodTab17: t("reportAssessmentTranslations.periodTab17"),
      periodTab18: t("reportAssessmentTranslations.periodTab18"),
      periodTab19: t("reportAssessmentTranslations.periodTab19"),
      periodTab20: t("reportAssessmentTranslations.periodTab20"),
      periodTab21: t("reportAssessmentTranslations.periodTab21"),
      periodTab22: t("reportAssessmentTranslations.periodTab22"),
      periodTab23: t("reportAssessmentTranslations.periodTab23"),
      periodTab24: t("reportAssessmentTranslations.periodTab24"),
      periodTab25: t("reportAssessmentTranslations.periodTab25"),
      periodTab26: t("reportAssessmentTranslations.periodTab26"),
      periodTab27: t("reportAssessmentTranslations.periodTab27"),
      periodTab28: t("reportAssessmentTranslations.periodTab28"),
      periodTab29: t("reportAssessmentTranslations.periodTab29"),
      nameDesc: t("reportAssessmentTranslations.nameDesc"),
      dateDesc: t("reportAssessmentTranslations.dateDesc"),
      selfAssessment: t("reportAssessmentTranslations.selfAssessment"),
      scheduleSession: t("reportAssessmentTranslations.scheduleSession"),
      bad: t("reportAssessmentTranslations.bad"),
      good: t("reportAssessmentTranslations.good"),
      excellent: t("reportAssessmentTranslations.excellent"),
      threevaluesInLife: t("reportAssessmentTranslations.threeValuesInLife"),
      employabilityQuotient: t(
        "reportAssessmentTranslations.employabilityQuotient"
      ),
      anyQuestion: t("reportAssessmentTranslations.anyQuestion"),
      contactExpert: t("reportAssessmentTranslations.contactExpert"),
      contactExpert: t("reportAssessmentTranslations.contactExpert"),
      yourScore: t("reportAssessmentTranslations.yourScore"),
      assessmentScorePersonality: t(
        "reportAssessmentTranslations.assessmentScorePersonality"
      ),
      assessmentScoreInterests: t(
        "reportAssessmentTranslations.assessmentScoreInterests"
      ),
      assessmentScoreAptitude: t(
        "reportAssessmentTranslations.assessmentScoreAptitude"
      ),
      assessmentScoreEmployabilityQuotient: t(
        "reportAssessmentTranslations.assessmentScoreEmployabilityQuotient"
      ),
      topCharecterStrengths: t(
        "reportAssessmentTranslations.topCharecterStrengths"
      ),
      topValuesInLife: t("reportAssessmentTranslations.topValuesInLife"),
      detailedInfoHeading: t(
        "reportAssessmentTranslations.detailedInfoHeading"
      ),
      detailedInfoSubHeading: t(
        "reportAssessmentTranslations.detailedInfoSubHeading"
      ),
    },
  };
}
