//** GinieHealth QuestionBank UI code **//

import { Card, Col } from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useState } from "react";
import QuestionType from "./QuestionType";
import { CustomCheckButton } from "../../components/CustomInput/custom.switch";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import {
  CustomDropdown,
  SvgImageContainer,
  dropdownItems,
} from "../../components/QuestionBuilder";
import "./styles.css";

const Questions = () => {
  const {
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      quesArray: [{ value: "", questionType: {}, exp: "", note: "" }],
    },
  });

  const { fields, remove, prepend, update } = useFieldArray({
    control,
    name: "quesArray",
    shouldUnregister: true,
  });

  const updateQuestion = (index, data) => {
    update(index, data);
  };

  const addQuestion = () => {
    prepend({ value: "", questionType: {} });
  };

  const deleteQues = (index) => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  return (
    <div className="px-5 py-3">
      <div className="d-flex">
        <Col md={2}></Col>
        <Col md={8}>
          <Card className="ps-4 py-2 pe-2">
            <SvgImageContainer
              onClick={() => alert("ok")}
              image="three-dots-black.svg"
              className="align-self-end"
              width={20}
              height={20}
            />
            <h5>Section Title</h5>
            <p>From description</p>
          </Card>
        </Col>
      </div>
      <div className="d-flex mt-3">
        <Col md={2}></Col>
        <Col md={8}>
          {fields.map((field, index) => (
            <QuestionContainer
              item={field}
              deleteQues={deleteQues}
              index={index}
              control={control}
              updateQuestion={updateQuestion}
            />
          ))}
        </Col>
        <div className="ms-3">
          <SvgImageContainer
            onClick={addQuestion}
            image="add-question.svg"
            width={56}
            height={80}
          />
        </div>
      </div>
    </div>
  );
};

export default Questions;

const QuestionContainer = ({
  item,
  deleteQues,
  index,
  control,
  updateQuestion,
}) => {
  const [questionType] = useState({
    icon: "short-answer.svg",
    type: "short_answer",
    title: "Short answer",
  });

  const onChangeQuestionType = (data) => {
    const selectedType = dropdownItems.find((x) => x.eventKey === data);
    updateQuestion(index, { ...item, questionType: selectedType });
  };

  return (
    <Card className="ps-4 py-2 pe-2 pt-4 mb-3" key={item.id}>
      <div className="mb-4 d-flex align-items-center">
        <div className="question-input">
          <Controller
            name={`quesArray.${index}.value`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <CustomInputField
                hookOnBlur={onBlur}
                hookValue={value}
                hookOnChange={onChange}
                className="custom_input"
                placeholder="Question"
              />
            )}
          />
        </div>
        <div className="img-container">
          <SvgImageContainer
            onClick={() => alert("ok")}
            image="picture.svg"
            className="mx-3"
          />
        </div>
        <div className="dropdown-container">
          <CustomDropdown
            icon={item?.questionType?.icon || questionType?.icon}
            title={item?.questionType?.title || questionType?.title}
            onChangeQuestionType={onChangeQuestionType}
          />
        </div>
      </div>
      <QuestionType
        type={item?.questionType?.type || questionType?.type}
        control={control}
        index={index}
      />
      <Card.Footer className="d-flex justify-content-between px-0 mt-4 py-2">
        <div>
          <SvgImageContainer
            onClick={() => alert("ok")}
            image="circle-check-blue.svg"
          />
        </div>
        <div className="d-flex align-items-center">
          <SvgImageContainer
            onClick={() => alert("ok")}
            image="input-text.svg"
            className="mx-2"
          />
          <SvgImageContainer
            onClick={() => alert("ok")}
            image="board.svg"
            className="mx-2"
          />
          <SvgImageContainer
            onClick={() => alert("ok")}
            image="light-bulb.svg"
            className="mx-2"
          />
          <SvgImageContainer
            onClick={() => alert("ok")}
            image="info_icon.svg"
            className="mx-2"
          />
          <SvgImageContainer
            onClick={() => alert("ok")}
            image="clone.svg"
            className="mx-2"
          />
          <SvgImageContainer
            onClick={() => deleteQues(index)}
            image="delete-grey.svg"
            className="mx-2"
          />
          <div
            style={{
              height: "20px",
              backgroundColor: "#D7D7D7",
              width: "2px",
            }}
            className="mx-2"
          />
          <span style={{ color: "#6D6A6A", fontSize: "14px" }} className="mx-2">
            Required
          </span>
          <CustomCheckButton
            hookOnChange={() => {}}
            hookOnBlur={() => {}}
            name={"name"}
            type="switch"
          />
          <SvgImageContainer
            onClick={() => alert("ok")}
            image="three-dots-black.svg"
            width={20}
            height={20}
          />
        </div>
      </Card.Footer>
    </Card>
  );
};
