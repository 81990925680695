import { axiosAwsIam } from "../axios.service";

export const iamResponseInterceptor = (instance) => {
  instance.interceptors.response.use(undefined, (err) => {
    const { config, message } = err;
    console.log("retrying",config);
    if (!config || !config.retry) {
      return Promise.reject(err);
    }
    console.log("retrying 1st if",message);
    // retry while Network timeout or Network Error
    //|| message.includes("Network Error")
    if (!(message.includes("The security token included in the request is invalid.") )) {
      return Promise.reject(err);
    }
    console.log("retrying second if");
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        console.log("retry the request", config.url,config.retry,message);
        resolve();
      }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => axiosAwsIam(config));
  });
};
