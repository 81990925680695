import React from "react";
import RatesScreen from "../../mentor/rates.screen.js";

const Rates = () => {
  return (
    <>
      <RatesScreen settings={true} />
    </>
  );
};

export default Rates;
