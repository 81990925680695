//** GinieHealth Publisher,CreateNewAssessment of Pricing component UI code  **//

import { useEffect, useState } from "react";
import { Button, Card, Row, Col, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { _getImage } from "../../common/utils/commonFunctions";
import { AppUrlsEnum } from "../../enums/app.url.enums";
import { priceFormValidator } from "../../FormSchemas/publisher.form";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { GetDataType } from "../../services/axios/staticData";
import { loader } from "../../redux-tk/slices/loader.slice";
import CustomAnswerOptions from "../assessment/components/customradioOption";
import { CustomSelect } from "../CustomInput/custom.select";
import { FormErrorComponent } from "../form.error.component";
import AssessmentSideBar from "./AssessmentSideBar";
import "./styles.css";
import { getAssessmentObject } from "../../common/DTOS/assessment.dtos";
import {
  addDataToAssessmentDetails,
  AssessmentDetailsDataSelector,
  createAssessmentAsyncThunk,
  tempCreateAssessmentSelector,
} from "../../redux-tk/slices/assessment.slice";
import { useAssessmentDetailFetch } from "../../hooks/useAssessmentDetailFetch";

const options = [{ value: "Free" }, { value: "Paid" }];

const currencyTypes = [
  {
    dataId: "0397161e-rt9e-4c8b-895d-a5db3fa540c0",
    dataType: "currency.english",
    value: "INR",
  },
  {
    dataId: "0397161e-rt9e-4c8b-895d-a5db3fa540c1",
    dataType: "currency.english",
    value: "USD",
  },
];

const prices = [
  {
    dataId: "0397161e-rt9e-4c8b-895d-a5db3fa540c0",
    dataType: "currency.english",
    value: "\u20B9" + "7500",
  },
  {
    dataId: "0397161e-rt9e-4c8b-895d-a5db3fa540c1",
    dataType: "currency.english",
    value: "\u20B9" + "8500",
  },
];

const Pricing = () => {
  const dispatch = useDispatch();
  const tempCreateDataValue = useSelector((state) =>
    tempCreateAssessmentSelector(state)
  );
  const { formElementLabels, assessmentsTranslations, formErrors } =
    useIgurooTranslations();
  const [currencies, setCurrencies] = useState([]);
  const {
    control,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      ...tempCreateDataValue.apiData,
      isFree: "Free",
      price_currency: tempCreateDataValue.apiData?.price_currency
        ? tempCreateDataValue.apiData?.price_currency != " "
          ? tempCreateDataValue.apiData?.price_currency
          : "INR"
        : "INR",
      price: tempCreateDataValue.apiData?.price
        ? tempCreateDataValue.apiData?.price != " "
          ? tempCreateDataValue.apiData?.price
          : ""
        : "",
    },
    resolver: yupResolver(priceFormValidator(formErrors)),
  });
  const navigate = useNavigate();
  const answerOption = watch("isFree");
  const [searchParams, setSearchParams] = useSearchParams();
  const assessmentDetailsData = useSelector((state) =>
    AssessmentDetailsDataSelector(state)
  );
  const params = useAssessmentDetailFetch({
    assessmentId: searchParams?.get("assessmentId"),
    versionId: searchParams?.get("versionId"),
  });
  useEffect(() => {
    (async function () {
      dispatch(loader(true));
      const currency = await GetDataType("currency", "english");
      setCurrencies(currency);
      dispatch(loader(false));
    })();
  }, []);

  const submit = async (data1) => {
    let finalData = { ...assessmentDetailsData };
    const ids = {
      versionId: searchParams.get("versionId"),
      assessmentId: searchParams.get("assessmentId"),
    };
    try {
      let assessmentObj;
      if (getValues("isFree") === "Free") {
        assessmentObj = getAssessmentObject({
          isFree: "Free",
          price: " ",
          price_currency: " ",
        });
      } else {
        assessmentObj = getAssessmentObject(data1);
      }
      finalData = { ...finalData, ...assessmentObj };
      const res = await dispatch(createAssessmentAsyncThunk(finalData));
      dispatch(addDataToAssessmentDetails(finalData));
      if (ids.assessmentId && ids.versionId) {
        if (res?.payload) {
          navigate(
            `${AppUrlsEnum.logicBuilder}?versionId=${ids.versionId}&assessmentId=${ids.assessmentId}`
          );
        }
      } else {
        if (res?.payload) {
          navigate(AppUrlsEnum.logicBuilder);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (tempCreateDataValue?.apiData?.assessment_id) {
      if (tempCreateDataValue?.apiData?.isFree === false) {
        setValue("isFree", "Paid");
      }
      setValue(
        "price_currency",
        tempCreateDataValue.apiData?.price_currency
          ? tempCreateDataValue.apiData?.price_currency != " "
            ? tempCreateDataValue.apiData?.price_currency
            : "INR"
          : "INR"
      );
      setValue(
        "price",
        tempCreateDataValue.apiData?.price
          ? tempCreateDataValue.apiData?.price != " "
            ? tempCreateDataValue.apiData?.price
            : ""
          : ""
      );
    }
  }, [tempCreateDataValue?.apiData?.assessment_id]);

  return (
    <div className="p-2">
      <h4 className="text-center p-2">{assessmentsTranslations?.pricing}</h4>
      <Row className="d-flex justify-content-center">
        <Col md={6} className="">
          <Card className="p-4 border-0 shadow">
            <p className="subHeader mb-2">
              {assessmentsTranslations?.priceTier}
            </p>
            <Form onSubmit={handleSubmit(submit)}>
              <Form.Group>
                <Controller
                  name={"isFree"}
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => {
                    return (
                      <CustomAnswerOptions
                        type={"radio"}
                        options={options}
                        hookOnChange={onChange}
                        hookOnBlur={onBlur}
                        hookValue={value}
                        name={name}
                      />
                    );
                  }}
                />
              </Form.Group>
              {getValues("isFree") === "Paid" && (
                <>
                  <p className="subHeader mb-2 mt-4">
                    {assessmentsTranslations?.price}
                  </p>
                  <Form.Group className="d-flex">
                    <div className="me-3">
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomSelect
                            className="selectStyle"
                            opLabel="value"
                            opValue="value"
                            items={currencyTypes}
                            onReactHookFormBlur={onBlur}
                            onReactHookFormChange={onChange}
                            value={value}
                            isLoading={false}
                            placeholder={
                              formElementLabels?.placeHolders?.selectCurrency
                            }
                          />
                        )}
                        name="price_currency"
                      />
                    </div>
                    <div>
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomSelect
                            className="selectStyle"
                            opLabel="value"
                            opValue="value"
                            items={prices}
                            onReactHookFormBlur={onBlur}
                            onReactHookFormChange={onChange}
                            value={value}
                            isLoading={false}
                            placeholder={"Price"}
                          />
                        )}
                        name="price"
                      />
                      <FormErrorComponent>
                        {errors.price && errors.price?.message}
                      </FormErrorComponent>
                    </div>
                  </Form.Group>
                </>
              )}
              <div className="d-flex justify-content-end">
                <Button type="submit" className="nextBtn">
                  {formElementLabels?.nextBtn}
                </Button>
              </div>
            </Form>
          </Card>
        </Col>
        <AssessmentSideBar
          data={assessmentsTranslations?.assessmentProgressView}
          stage={3}
          title={assessmentsTranslations?.assessmentDetails}
          btnValue={formElementLabels?.publishBtn}
          currentTab={assessmentsTranslations?.assessmentProgressView?.pricing}
        />
      </Row>
    </div>
  );
};

export default Pricing;
