import {  axiosStaticData } from "../axios.service";
import { IGURU_DEV_URL } from "../../config";
import { POST_RATING, POST_MENTOR_RATING } from "../../urlConst";

export const postRating = async (data) => {
  const URL = POST_RATING;
  const response = await axiosStaticData.post(URL, data);
  return response.data;
};

export const postMentorRating = async (data) => {
  const URL = POST_MENTOR_RATING;
  const response = await axiosStaticData.post(URL, data);
  return response.data;
};

export const getSessionRating = async (sessionNumber, mentorId) => {
  const URL = POST_MENTOR_RATING;
  const params = {
    sessionNumber: sessionNumber,
    mentorId: mentorId,
  };
  const res = axiosStaticData.get(URL, { params });
  return res;
};
