import moment from "moment";
import * as yup from "yup";
export function getmenteeBasicFormTranslated(formErrors) {
  return yup.object({
    gender: yup.string().required(formErrors?.genderError),
    dateofBirth: yup
      .string()
      .required(formErrors?.dateofBirth)
      .test("dateofBirth", formErrors?.menteeDobGreaterThan, (value) => {
        return moment().diff(moment(value), "years") >= 15;
      })
      .test("minDateOfBirth", formErrors?.dobMinLimit, (value) => {
        const minDate = moment("01-01-1900", "DD-MM-YYYY");
        const selectedDate = moment(value, "YYYY-MM-DD");
        return selectedDate.isSameOrAfter(minDate);
      }),
    profileCategory: yup
      .object({
        value: yup.string().required(formErrors?.profileCategory),
        dataId: yup.string(),
        dataType: yup.string(),
      })
      .required(formErrors?.profileCategory)
      .typeError(formErrors?.profileCategory),
    languages: yup
      .array()
      .of(
        yup.object({
          value: yup.string(),
          dataId: yup.string(),
          dataType: yup.string(),
        })
      )
      .required(formErrors?.language)
      .min(1, formErrors?.language),
    country: yup
      .object({
        value: yup.string(),
        dataId: yup.string(),
        dataType: yup.string(),
        country: yup.string().required(formErrors?.country),
      })
      .required(formErrors?.country)
      .typeError(formErrors?.country),
    state: yup
      .object({
        value: yup.string(),
        dataId: yup.string(),
        dataType: yup.string(),
        state: yup.string().required(formErrors?.state),
      })
      .required(formErrors?.state)
      .typeError(formErrors?.state),
    city: yup
      .object({
        value: yup.string(),
        dataId: yup.string(),
        dataType: yup.string(),
        city: yup.string().required(formErrors?.city),
      })
      .required(formErrors?.city)
      .typeError(formErrors?.city),
    bio: yup.string().required(formErrors?.describErrormsg),
    profilePic: yup.string().optional(),
    newProfilePic: yup.string().optional(),
    profileVideoLink: yup.string().optional(),
    newProfileVideoLink: yup.string().optional(),
  });
}
