//** GinieHealth Publisher,Create New Assessment of Intended Learners UI code **//

import React, { useState, useEffect } from "react";
import "./index.scss";
import { useForm } from "react-hook-form";
import { Editor } from "react-draft-wysiwyg";
import { Form, Row, Col, Card } from "react-bootstrap";
import {
  EditorState,
  RichUtils,
  convertFromRaw,
  convertToRaw,
  ContentState,
} from "draft-js";
import ToolbarConfig from "./../liveSession/EditorConfig";
import { code, blockquote } from "../../assets/images";
import { SubmitButton } from "../../components/submit.button";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import AssessmentSideBar from "../../components/PublisherComponent/AssessmentSideBar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppUrlsEnum } from "../../enums";
import { getPublisherIntendedFormTranslated } from "../../FormSchemas/pulisher.intended.form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getAssessmentObject } from "../../common/DTOS/assessment.dtos";
import {
  AssessmentDetailsDataSelector,
  addDataToAssessmentDetails,
  createAssessmentAsyncThunk,
  tempCreateAssessmentSelector,
} from "../../redux-tk/slices/assessment.slice";
import { useDispatch, useSelector } from "react-redux";
import { useAssessmentDetailFetch } from "../../hooks/useAssessmentDetailFetch";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const IntendedLearners = () => {
  const { formErrors } = useIgurooTranslations();
  const tempCreateDataValue = useSelector((state) =>
    tempCreateAssessmentSelector(state)
  );
  const {
    handleSubmit,
    formState: { errors, isDirty, dirtyFields, touchedFields },
  } = useForm({
    resolver: yupResolver(getPublisherIntendedFormTranslated(formErrors)),
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const params = useAssessmentDetailFetch({
    assessmentId: searchParams?.get("assessmentId"),
    versionId: searchParams?.get("versionId"),
  });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorState2, setEditorState2] = useState(EditorState.createEmpty());
  const [editorState3, setEditorState3] = useState(EditorState.createEmpty());
  const [editorState4, setEditorState4] = useState(EditorState.createEmpty());
  const [editorFocus, setEditorFocus] = useState(false);
  const assessmentDetailsData = useSelector((state) =>
    AssessmentDetailsDataSelector(state)
  );

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    localStorage.setItem(
      "iText",
      editorState.getCurrentContent().getPlainText()
    );
  };

  const navigate = useNavigate();

  const onEditorStateChange2 = (editorState) => {
    setEditorState2(editorState);
    localStorage.setItem(
      "iText2",
      editorState.getCurrentContent().getPlainText()
    );
  };

  const onEditorStateChange3 = (editorState) => {
    setEditorState3(editorState);
    localStorage.setItem(
      "iText3",
      editorState.getCurrentContent().getPlainText()
    );
  };

  const onEditorStateChange4 = (editorState) => {
    setEditorState4(editorState);
    localStorage.setItem(
      "iText4",
      editorState.getCurrentContent().getPlainText()
    );
  };

  function CustomOption(props) {
    const { editorState, onChange } = props;
    let [bqActive, setBqActive] = useState(false);
    let [codeActive, setCodeActive] = useState(false);

    const toggleBlock = () => {
      codeActive && setCodeActive(bqActive);
      setBqActive(!bqActive);
      const newState = RichUtils.toggleBlockType(editorState, "blockquote");
      if (newState) {
        onChange(newState);
      }
    };

    const toggleCode = () => {
      bqActive && setBqActive(codeActive);
      setCodeActive(!codeActive);
      const newState = RichUtils.toggleBlockType(editorState, "code-block");
      if (newState) {
        onChange(newState);
      }
    };

    return (
      <div className="rdw-custom-wrapper">
        <div
          className={`rdw-option-wrapper ${
            codeActive ? "rdw-option-active" : ""
          }`}
          title="Code"
        >
          <img
            src={code}
            alt="code"
            className="rdw-storybook-custom-code"
            onClick={toggleCode}
          />
        </div>
        <div
          className={`rdw-option-wrapper ${
            bqActive ? "rdw-option-active" : ""
          }`}
          title="Blockquote"
        >
          <img
            src={blockquote}
            alt="blockquote"
            className="rdw-storybook-custom-blockquote"
            onClick={toggleBlock}
          />
        </div>
      </div>
    );
  }

  const onSubmit = async (data) => {
    const itext1 = JSON.stringify({
      content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
    const itext2 = JSON.stringify({
      content: draftToHtml(convertToRaw(editorState2.getCurrentContent())),
    });
    const itext3 = JSON.stringify({
      content: draftToHtml(convertToRaw(editorState3.getCurrentContent())),
    });
    const itext4 = JSON.stringify({
      content: draftToHtml(convertToRaw(editorState4.getCurrentContent())),
    });
    const ids = {
      version: searchParams.get("versionId"),
      assessment_id: searchParams.get("assessmentId"),
    };
    try {
      data.intended_learners = {
        what_will_you_learn: {
          details: itext1,
        },
        assessment_for: {
          details: itext2,
        },
        prerequisite: {
          details: itext3,
        },
        table_of_content: {
          details: itext4,
        },
      };
      const assessmentObj = getAssessmentObject(data);
      let finaldata = { ...assessmentDetailsData };
      finaldata.assessment_metadata = {
        ...finaldata.assessment_metadata,
        intended_learners: { ...assessmentObj.intended_learners },
      };
      const res = await dispatch(createAssessmentAsyncThunk(finaldata));
      dispatch(addDataToAssessmentDetails(finaldata));
      if (ids.assessment_id && ids.version) {
        if (res?.payload) {
          navigate(
            `${AppUrlsEnum.publisherAssessmentSettings}?versionId=${ids.version}&assessmentId=${ids.assessment_id}`
          );
        }
      } else {
        if (res?.payload) {
          navigate(AppUrlsEnum.publisherAssessmentSettings);
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (tempCreateDataValue?.apiData?.assessment_id) {
      try {
        JSON.parse(tempCreateDataValue.intendedLearners.what_will_you_learn);
        JSON.parse(tempCreateDataValue.intendedLearners.assessment_for);
        JSON.parse(tempCreateDataValue.intendedLearners.prerequisite);
        JSON.parse(tempCreateDataValue.intendedLearners.table_of_content);
      } catch (e) {
        return;
      }
      if (tempCreateDataValue?.intendedLearners?.what_will_you_learn) {
        const state1 = JSON.parse(
          tempCreateDataValue?.intendedLearners?.what_will_you_learn
        );
        if (
          state1.blocks &&
          EditorState.createWithContent(
            convertFromRaw(
              JSON.parse(
                tempCreateDataValue?.intendedLearners?.what_will_you_learn
              )
            )
          )
        ) {
          setEditorState(
            tempCreateDataValue?.intendedLearners?.what_will_you_learn
              ? EditorState.createWithContent(
                  convertFromRaw(
                    JSON.parse(
                      tempCreateDataValue?.intendedLearners?.what_will_you_learn
                    )
                  )
                )
              : EditorState.createEmpty()
          );
        } else if (
          state1.content &&
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(state1?.content)?.contentBlocks,
              htmlToDraft(state1?.content)?.entityMap
            )
          )
        ) {
          setEditorState(
            tempCreateDataValue?.intendedLearners?.what_will_you_learn
              ? EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                    htmlToDraft(state1?.content)?.contentBlocks,
                    htmlToDraft(state1?.content)?.entityMap
                  )
                )
              : EditorState.createEmpty()
          );
        }
      }
      if (tempCreateDataValue?.intendedLearners?.assessment_for) {
        const state2 = JSON.parse(
          tempCreateDataValue?.intendedLearners?.assessment_for
        );
        if (
          state2.blocks &&
          EditorState.createWithContent(
            convertFromRaw(
              JSON.parse(tempCreateDataValue?.intendedLearners?.assessment_for)
            )
          )
        ) {
          setEditorState2(
            tempCreateDataValue?.intendedLearners?.assessment_for
              ? EditorState.createWithContent(
                  convertFromRaw(
                    JSON.parse(
                      tempCreateDataValue?.intendedLearners?.assessment_for
                    )
                  )
                )
              : EditorState.createEmpty()
          );
        } else if (
          state2.content &&
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(state2?.content)?.contentBlocks,
              htmlToDraft(state2?.content)?.entityMap
            )
          )
        ) {
          setEditorState2(
            tempCreateDataValue?.intendedLearners?.assessment_for
              ? EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                    htmlToDraft(state2?.content)?.contentBlocks,
                    htmlToDraft(state2?.content)?.entityMap
                  )
                )
              : EditorState.createEmpty()
          );
        }
      }
      if (tempCreateDataValue?.intendedLearners?.prerequisite) {
        const state3 = JSON.parse(
          tempCreateDataValue?.intendedLearners?.prerequisite
        );
        if (
          state3.blocks &&
          EditorState.createWithContent(
            convertFromRaw(
              JSON.parse(tempCreateDataValue?.intendedLearners?.prerequisite)
            )
          )
        ) {
          setEditorState3(
            tempCreateDataValue?.intendedLearners?.prerequisite
              ? EditorState.createWithContent(
                  convertFromRaw(
                    JSON.parse(
                      tempCreateDataValue?.intendedLearners?.prerequisite
                    )
                  )
                )
              : EditorState.createEmpty()
          );
        } else if (
          state3.content &&
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(state3?.content)?.contentBlocks,
              htmlToDraft(state3?.content)?.entityMap
            )
          )
        ) {
          setEditorState3(
            tempCreateDataValue?.intendedLearners?.prerequisite
              ? EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                    htmlToDraft(state3?.content)?.contentBlocks,
                    htmlToDraft(state3?.content)?.entityMap
                  )
                )
              : EditorState.createEmpty()
          );
        }
      }
      if (tempCreateDataValue?.intendedLearners?.table_of_content) {
        const state4 = JSON.parse(
          tempCreateDataValue?.intendedLearners?.table_of_content
        );
        if (
          state4.blocks &&
          EditorState.createWithContent(
            convertFromRaw(
              JSON.parse(
                tempCreateDataValue?.intendedLearners?.table_of_content
              )
            )
          )
        ) {
          setEditorState4(
            tempCreateDataValue?.intendedLearners?.table_of_content
              ? EditorState.createWithContent(
                  convertFromRaw(
                    JSON.parse(
                      tempCreateDataValue?.intendedLearners?.table_of_content
                    )
                  )
                )
              : EditorState.createEmpty()
          );
        } else if (
          state4.content &&
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(state4?.content)?.contentBlocks,
              htmlToDraft(state4?.content)?.entityMap
            )
          )
        ) {
          setEditorState4(
            tempCreateDataValue?.intendedLearners?.table_of_content
              ? EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                    htmlToDraft(state4?.content)?.contentBlocks,
                    htmlToDraft(state4?.content)?.entityMap
                  )
                )
              : EditorState.createEmpty()
          );
        }
      }
    }
  }, [tempCreateDataValue?.apiData?.assessment_id]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, []);

  return (
    <div className="justify-content">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="intend-title-div px-2 py-3 m-0">
          <p className="fs-24 fw-700 m-0">Intended learners</p>
        </div>
        <div>
          <p className="fs-16 fe-400 mt-5">
            The following descriptions will be publicly visible on your Course
            Landing Page and will have a direct impact on your course
            performance. These descriptions will help learners decide if your
            course is right for them.
          </p>
          <p className="fs-16 fw-700 mt-5 mb-0">What you’ll learn? </p>
          <p className="fs-16 fw-400">
            Envision your learners' journey – outline the key takeaways and
            outcomes they'll gain from this assessment
          </p>
          <Editor
            wrapperClassName="notes-wrapper"
            editorClassName={`notes-editor ${
              editorFocus ? "editor-focus" : ""
            }`}
            editorState={editorState}
            onFocus={(_) => setEditorFocus(true)}
            onBlur={(_) => setEditorFocus(false)}
            placeholder="Take your notes here..."
            onEditorStateChange={onEditorStateChange}
            toolbarCustomButtons={[<CustomOption />]}
            toolbar={ToolbarConfig}
          />
          <p className="fs-16 fw-700 mt-3 mb-0">
            Who this course/assessment is for
          </p>
          <p className="fs-16 fw-400">
            Define your target audience – specify who will benefit most from
            this course/assessment
          </p>
          <Editor
            wrapperClassName="notes-wrapper"
            editorClassName={`notes-editor ${
              editorFocus ? "editor-focus" : ""
            }`}
            editorState={editorState2}
            onFocus={(_) => setEditorFocus(true)}
            onBlur={(_) => setEditorFocus(false)}
            placeholder="Take your notes here..."
            onEditorStateChange={onEditorStateChange2}
            toolbarCustomButtons={[<CustomOption />]}
            toolbar={ToolbarConfig}
          />
          <p className="fs-16 fw-700 mt-3 mb-0">Prerequisite</p>
          <p className="fs-16 fw-400">
            Set the foundation – list any recommended knowledge, skills, or
            prerequisites that learners should have before embarking on this
            assessment
          </p>
          <Editor
            wrapperClassName="notes-wrapper"
            editorClassName={`notes-editor ${
              editorFocus ? "editor-focus" : ""
            }`}
            editorState={editorState3}
            onFocus={(_) => setEditorFocus(true)}
            onBlur={(_) => setEditorFocus(false)}
            placeholder="Take your notes here..."
            onEditorStateChange={onEditorStateChange3}
            toolbarCustomButtons={[<CustomOption />]}
            toolbar={ToolbarConfig}
          />
          <p className="fs-16 fw-700 mt-3 mb-0">Table of content</p>
          <p className="fs-16 fw-400">
            Map out the journey – outline the structured breakdown of topics and
            sections covered in this assessment
          </p>
          <Editor
            wrapperClassName="notes-wrapper"
            editorClassName={`notes-editor ${
              editorFocus ? "editor-focus" : ""
            }`}
            editorState={editorState4}
            onFocus={(_) => setEditorFocus(true)}
            onBlur={(_) => setEditorFocus(false)}
            placeholder="Take your notes here..."
            onEditorStateChange={onEditorStateChange4}
            toolbarCustomButtons={[<CustomOption />]}
            toolbar={ToolbarConfig}
          />
          <div className="d-flex justify-content-end mt-5">
            <SubmitButton className="next-btn" text="Next" />
          </div>
        </div>
      </Form>
    </div>
  );
};

const PubIntendedLerners = () => {
  const { formElementLabels, assessmentsTranslations } =
    useIgurooTranslations();
  return (
    <div className="p-2">
      <h4 className="text-center p-2">
        {assessmentsTranslations?.intendedLearners}
      </h4>
      <Row className="d-flex justify-content-center">
        <Col md={8} className="">
          <Card className="p-4 border-0 shadow">
            <IntendedLearners />
          </Card>
        </Col>
        <AssessmentSideBar
          data={assessmentsTranslations?.assessmentProgressView}
          stage={1}
          title={assessmentsTranslations?.assessmentDetails}
          btnValue={formElementLabels?.publishBtn}
          currentTab={
            assessmentsTranslations?.assessmentProgressView?.intendedLearners
          }
        />
      </Row>
    </div>
  );
};

export default PubIntendedLerners;
