//** Iguroo Mentor SideBar & MutlistepProgressBar Onboarding Stage **//

import React, { useEffect, useState } from "react";
import { _getImage } from "../common/utils/commonFunctions";
import { MentorSetupSidebarValue } from "./mentor.setup.sidebar.value";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { userProfileOnboardingStageValue } from "../redux-tk/slices/user.slice";
import MultiStepProgressBar from "./setup.steps";

export function MentorSetupSidebar(props) {
  const { typeToUse, active } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const onBoardingStage = useSelector((state) =>
    userProfileOnboardingStageValue(state)
  );
  //console.log("on",onBoardingStage);

  useEffect(() => {
    let ai = Object.keys(typeToUse?.values).findIndex(
      (val) => val === active.key
    );
    setActiveIndex(ai);
  }, [activeIndex, setActiveIndex, active, typeToUse]);

  return (
    <div
      className="mentor-card"
      style={{
        flex: 1,
        alignItems: "flex-end",
      }}
    >
      <div className="multi-step-progress-bar">
        <MultiStepProgressBar
          className="d-lg-none mb-4"
          active={active}
          typeToUse={typeToUse}
        />
      </div>
      <Card className="d-none d-lg-block">
        <Card.Body>
          <Card.Title>
            <span className="ig-heading">{typeToUse.title}</span>
          </Card.Title>
          <div style={{ backgroundColor: "white" }}>
            {Object.keys(typeToUse.values).map((val, index) => {
              const value = typeToUse.values[val];
              const url = onBoardingStage + 1 > index ? value.url : "";
              //console.log("va", value, index, onBoardingStage);

              return (
                <MentorSetupSidebarValue
                  key={index}
                  myKey={value.key}
                  value={value.value}
                  isActive={value.key === active.key}
                  isChecked={onBoardingStage > index}
                  url={url}
                />
              );
            })}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
