class AllDataConverter {
  constructor() {
    this.allApiData = [];
  }

  setAllApiData(items) {
    this.allApiData = items;
  }

  getDataType(dataType) {
    return this.allApiData.filter((val) => val.dataType === dataType);
  }

  removeDefaultId(arr) {
    return arr?.map((val) => {
      if (
        val.id === -1 ||
        val.id === undefined ||
        val.id === null ||
        val.id === "-1"
      ) {
        delete val.id;
      }
      return val;
    });
  }
}

export const allDataConverter = new AllDataConverter();
