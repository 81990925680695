//** Iguroo SelfAssessment,QuestionAbout Component used in TestAssessment index file **//

import React from "react";

export function QuestionAboutComponent(props) {
  const { text, className} = props;
  const questaboutText = props.children?.length > 0 ? props.children : text;
  const quesAboutSplit = questaboutText?.split("\n");

  const quesAbout = quesAboutSplit?.map((line, index) => (
    <p
      key={index}
      className={
        index != quesAboutSplit.length - 1
          ? "ig-questaboutText-hint"
          : "ig-questaboutText-abovehead"
      }
    >
      {line}
    </p>
  ));
  console.log(quesAbout, "console");

  return (
    <div className={`ig-test-aboutQuestion ${className}`}>{quesAbout}</div>
  );
}
