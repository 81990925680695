//** Iguroo Assessment Input Text Component **//

import React from "react";
import { Row, Container } from "react-bootstrap";

const InputCard = ({ contentText, className }) => {
  return (
    <div className={`${className} m-0 input-card-text`}>{contentText}</div>
  );
};

export default InputCard;
