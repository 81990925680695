//**Iguroo Admin Mentee Testimonials AgGrid Can View or Update and Approve or Reject the Testimoanils UI code**//

import React, {
  useCallback,
  useRef,
  useMemo,
  useState,
  useContext,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Approve from "./Approve";
import Block from "./Block";
import StatusTag from "./StatusTag";
import { useEffect } from "react";
import { AuthContext } from "../../hooks/auth.context.provider";
import "./admin.scss";
import { Modal, Button, Form } from "react-bootstrap";
import {
  getTestimonials,
  postApproveTestimonials,
} from "../../services/axios/Testimonials";
import moment from "moment";
import Toaster from "../../components/toaster";
import { toast } from "react-toastify";
import ApproveApiTestimonials from "./ApiApproveTesti";

const ApproveTestimonials = () => {
  const gridRef = useRef();
  const authContext = useContext(AuthContext);
  const menteeId = useMemo(() => {
    return authContext?.sessionInfo;
  }, [authContext.authStatus]);
  console.log(menteeId, "mentee-iddd");
  const [currentTestimonials, setCurrenttestimonials] = useState({});

  useEffect(() => {}, []);
  function handleGetTestimonials() {
    getTestimonials()
      .then((res) => {
        console.log("API Response:", res);
        if (res) {
          const users = res.map((user) => {
            const menteeName =
              user?.menteeDetail?.[0]?.firstName +
              " " +
              user?.menteeDetail?.[0]?.lastName;
            const mentorName =
              user?.mentorDetail?.[0]?.firstName +
              " " +
              user?.mentorDetail?.[0]?.lastName;
            console.log("Testimonial:", user);
            return {
              "Mentee Name": menteeName,
              "Mentor Name": mentorName,
              Status: user?.status,
              "Date/Time": moment(user.testimonialId).format(
                "DD/MM/YYYY / HH:mm A"
              ),
              Testimonials: user?.testimonialText,
              testimonialId: user?.testimonialId,
              mentorId: user?.mentorId,
              testimonialText: user?.testimonialText,
            };
          });
          console.log("Mapped-Users:", users);
          setRowData(users);
        }
      })
      .catch((err) => console.log(err));
  }

  const [rowData, setRowData] = useState([]);
  const [selectedTestimonial, setSelectedTestimonial] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [updatedTestimonial, setUpdatedTestimonial] = useState("");
  const [OriginalTestimonial, setOriginalTestimonial] = useState("");

  const handleTestimonialClick = (data) => {
    setSelectedTestimonial(data.Testimonials);
    setOriginalTestimonial(data.Testimonials);
    setUpdatedTestimonial(data.Testimonials);
    setCurrenttestimonials(data);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleTestimonialUpdate = async (data) => {
    // console.log(data, "data-update");
    const updatedTestimonialText = `${updatedTestimonial}`;
    const updatedRowData = rowData.map((rowData) => {
      // console.log(rowData, "rowdata-update");
      if (rowData.Testimonials === selectedTestimonial) {
        return {
          ...rowData,
          Testimonials: updatedTestimonialText,
          testimonialText: updatedTestimonialText,
        };
      } else {
        return rowData;
      }
    });
    try {
      const testimonialData = {
        mentorId: currentTestimonials?.mentorId,
        status: "NEW",
        testimonialText: updatedTestimonialText,
        testimonialId: currentTestimonials?.testimonialId,
      };
      await postApproveTestimonials(testimonialData);
      setRowData(updatedRowData);
      setSelectedTestimonial(updatedTestimonialText);
      closeModal();
      Toaster(toast.success, "Testimonial updated successfully");
    } catch (err) {
      console.error(err);
    }
  };

  const TestimonialCellRenderer = ({ value, data }) => {
    const handleUpdate = () => {
      handleTestimonialClick(data);
    };

    return (
      <div>
        <Button
          className="button-text"
          variant="primary"
          size="sm"
          onClick={handleUpdate}
        >
          View / Update
        </Button>
      </div>
    );
  };

  const [columnDefs] = useState([
    {
      field: "Mentor Name",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      resizable: true,
    },
    {
      field: "Mentee Name",
      resizable: true,
    },
    {
      field: "Testimonials",
      cellRenderer: "testimonialCellRenderer",
      cellRendererFramework: TestimonialCellRenderer,
      cellRendererParams: {
        onUpdate: handleTestimonialClick,
      },
    },
    {
      field: "Approve",
      cellRenderer: ApproveApiTestimonials,
      cellRendererParams: {
        getTestimonials: handleGetTestimonials,
      },
    },
    {
      field: "Reject",
      cellRenderer: Block,
      cellRendererParams: {
        getTestimonials: handleGetTestimonials,
      },
    },
    {
      field: "Date/Time",
    },
    { field: "Status", cellRenderer: StatusTag },
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
  }));

  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event);
  }, []);

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    document.querySelector("#selectedRows").innerHTML =
      selectedRows.length === 1 ? selectedRows[0].athlete : "";
  }, []);

  const isRowSelectable = useMemo(() => {
    return (params) => {
      return !!params.data;
    };
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.forEachNode((node) => node.setSelected(false));
  }, []);

  const components = useMemo(() => {
    return {
      Approve: Approve,
      StatusTag: StatusTag,
      approveApiTestimonials: ApproveApiTestimonials,
      block: Block,
    };
  }, []);

  useEffect(() => {
    handleGetTestimonials();
  }, []);

  return (
    <div className="d-flex flex-column" style={{ fontFamily: "manrope" }}>
      <h5 className="ms-4 mt-2">Approve Testimonials</h5>
      <div className="ag-theme-alpine px-4 py-3" style={{ height: "400px" }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
          onCellClicked={cellClickedListener}
          onSelectionChanged={onSelectionChanged}
          suppressRowClickSelection={true}
          isRowSelectable={isRowSelectable}
          onFirstDataRendered={onFirstDataRendered}
          components={components}
          suppressDragLeaveHidesColumns={true}
          frameworkComponents={{
            testimonialCellRenderer: TestimonialCellRenderer,
          }}
        />
        <Modal show={modalIsOpen} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>View / Update Testimonial</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>View / Update Testimonial Text</Form.Label>
              <Form.Control
                as="textarea"
                rows={10}
                value={updatedTestimonial}
                onChange={(e) => setUpdatedTestimonial(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleTestimonialUpdate}>
              Update Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default ApproveTestimonials;
