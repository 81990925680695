//** Iguroo Mentor -> Onboarding & EditAccount,Slots Timing Availability Settings code UI **//

import React from "react";
import { CustomSelect } from "./CustomInput/custom.select";
import TimeRange from "react-time-range";
import { useIgurooTranslations } from "../hooks/use.iguroo.translation";
import {  Controller } from "react-hook-form";
import {  Col, Form, Row } from "react-bootstrap";
import { CustomDeleteBtn } from "./CustomInput/customDeleteBtn";
import { FormErrorComponent } from "./form.error.component";
import { FormLabelComponent } from "./form.label.component";
import moment from "moment";

export function AvailabilityItemComponent(props) {
  const { itemIndex, items, hookControl, removeFunction, errors } = props;
  const { mentorOnboardingLabels, formElementLabels, formErrors } =
    useIgurooTranslations();
  //console.log("err", errors);
  const removeRow = () => {
    removeFunction(itemIndex);
  };

  return (
    <Row className="mt-2">
      <Col md="6" className="mt-3">
        <Controller
          name={`avails.${itemIndex}.id`}
          control={hookControl}
          render={({ field: { onChange, onBlur, value } }) => (
            <Form.Control
              type="hidden"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            ></Form.Control>
          )}
        />
        <Form.Group>
          <FormLabelComponent>
            {mentorOnboardingLabels?.availability}
          </FormLabelComponent>
          <Controller
            control={hookControl}
            render={({ field: { onChange, onBlur, value } }) => (
              <CustomSelect
                opLabel="value"
                opValue="value"
                items={items}
                onReactHookFormBlur={onBlur}
                onReactHookFormChange={onChange}
                value={value}
                placeholder={formElementLabels?.placeHolders?.availability}
              />
            )}
            name={`avails.${itemIndex}.availability`}
          />
          <FormErrorComponent>
            {errors?.avails?.length > 0 &&
              errors?.avails[itemIndex]?.availability &&
              errors?.avails[itemIndex].availability?.message}
          </FormErrorComponent>
        </Form.Group>
      </Col>
      <Col md="5" className="mt-3">
        <Form.Group>
          <Controller
            control={hookControl}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                {console.log(
                  moment(value.startTime).utcOffset(330),
                  value,
                  "timezone callstart"
                )}
                <TimeRange
                  use24Hours={true}
                  showErrors={false}
                  startMoment={moment(value.startTime).utcOffset(330)}
                  endMoment={moment(value?.endTime).utcOffset(330)}
                  onChange={onChange}
                  startLabel={mentorOnboardingLabels?.startTime}
                  endLabel={mentorOnboardingLabels?.endTime}
                />
              </>
            )}
            name={`avails.${itemIndex}.timeRange`}
          />
          <FormErrorComponent>
            {errors?.avails?.length > 0 &&
              errors?.avails[itemIndex]?.timeRange &&
              errors?.avails[itemIndex]?.timeRange?.endTime?.message}
          </FormErrorComponent>
        </Form.Group>
      </Col>
      <Col className="d-flex align-items-center  justify-content-center mt-3 ">
        {itemIndex != 0 && <CustomDeleteBtn onClick={removeRow} />}
      </Col>
    </Row>
  );
}
