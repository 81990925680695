//** Iguroo Onboarding Two lines of Heading Component **//

import { useContext } from "react";
import { useMemo } from "react";
import { formatStr, isMentorOrPre } from "../helpers";
import { AuthContext } from "../hooks/auth.context.provider";
import { useIgurooTranslations } from "../hooks/use.iguroo.translation";
import { MainHeadingComponent } from "./main.heading.component";

export function OnboardingHeading({ profileType }) {
  const { mentorOnboardingLabels, menteeOnboardingLabels } =
    useIgurooTranslations();
  const authContext = useContext(AuthContext);
  //console.log(authContext,'authContext $$$');
  const heading = useMemo(() => {
    let h = isMentorOrPre(profileType)
      ? mentorOnboardingLabels.createMentorProfile
      : menteeOnboardingLabels.createMenteeProfile;
    return formatStr(h, {
      username:
        authContext.sessionInfo?.firstName?.charAt(0).toUpperCase() +
        authContext.sessionInfo?.firstName?.slice(1),
    });
  }, [profileType, menteeOnboardingLabels, mentorOnboardingLabels]);
  //split up heading line for change the font-size
  const head = heading.split("\n").map((line, index) => (
    <p
      key={index}
      className={index != 0 ? "ig-onboarding-head" : "ig-onboarding-abovehead"}
    >
      {line}
    </p>
  ));
  console.log(head);
  return <MainHeadingComponent>{head}</MainHeadingComponent>;
}
