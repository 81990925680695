//** GinieHealth Learner,Click on Assessment Start Page UI code **//

import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Heading } from "../../components/heading.component";
import { useLocation, useNavigate } from "react-router-dom";
import "./assessmentstartpage.scss";
import { AuthContext } from "../../hooks/auth.context.provider";
import { SubmitButton } from "../../components/submit.button";
import { CustomVideo } from "../../components/Media";
import { getAssessmenById } from "../../services/axios/Assessment/assessment";
import { AppUrlsEnum } from "../../enums";
import { GetAssessmentAttemptStatusApi } from "@iguroo/ig-react-assessment-package";
import moment from "moment";
import { FormHeading } from "../../components/form.heading.component";

const AssessmentStartPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const [responseData, setResponseData] = useState();
  const [assessmentID, setassessmentID] = useState();
  const [completeAssessment, setCompleteAssessment] = useState(0);
  const [totalAttempt, setTotalAttempt] = useState(0);
  const [attemptLeft, setAttemptLeft] = useState(0);
  const [inProgress, setinProgress] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [dueDate, setdueDate] = useState();
  const [status, setStatus] = useState();

  const [loading, setLoading] = useState(false);
  const [assessmentInfo, setAssessmentInfo] = useState();

  useEffect(() => {
    (async function () {
      const pageState = location?.state.assessment_version_id;
      setdueDate(location?.state?.dueDate);
      setStatus(location?.state?.status);
      const assessmentId = pageState.substr(0, pageState.lastIndexOf("-"));
      console.log("LOG responseData 165", pageState);
      setassessmentID(pageState);
      let dataA = null;

      getAssessmenById(assessmentId)
        .then((res) => {
          dataA = res.response.data[0];
          const info = JSON.parse(dataA.assessment_info)
          setResponseData(dataA);
          setAssessmentInfo(info.content)
          console.log("LOG reponseData 165", responseData, info.content);

          GetAssessmentAttemptStatusApi({
            assessment_version_id: pageState,
            userId: authContext.sessionInfo.sub,
          })
            .then((res) => {
              console.log(
                "res 165",
                res,
                res?.response?.data?.remaining_attempts
              );
              setCompleteAssessment(res?.response?.data?.completed_attempts);
              setCompleted(res?.response?.data?.completed);
              setinProgress(res?.response?.data?.in_progress);
              setTotalAttempt(
                res?.response?.data?.remaining_attempts === undefined ||
                  res?.response?.data?.remaining_attempts === ""
                  ? dataA?.attempts_allowed
                  : res?.response?.data?.attempts_allowed
              );
              setAttemptLeft(
                res?.response?.data?.remaining_attempts === undefined ||
                  res?.response?.data?.remaining_attempts === ""
                  ? dataA?.attempts_allowed -
                  res?.response?.data?.completed_attempts
                  : res?.response?.data?.remaining_attempts
              );
              setLoading(true);
              console.log(
                "resData 68",
                res,
                responseData,
                dataA?.attempts_allowed
              );
              console.log("resData 70", dataA?.attempts_allowed);
              console.log("resData 72", res?.response?.data?.attempts_allowed);
              console.log(
                "resData 73",
                dataA?.attempts_allowed -
                res?.response?.data?.completed_attempts
              );
              console.log(
                "resData 74",
                res?.response?.data?.remaining_attempts
              );
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    })();
  }, [setLoading]);

  const onButtonClick = () => {
    localStorage.setItem("learner", responseData?.title);
    console.log("165 learner", responseData?.title, responseData);
    navigate(AppUrlsEnum.learnerTestScreen, {
      state: {
        assessment_version_id: assessmentID,
        assessmentData: responseData,
        attempts: totalAttempt,
        attemptLeft: attemptLeft,
      },
    });
  };

  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  );

  return (
    <div className="white_bg ms-4">
      <Container>
        <Row>
          <Col md="12" className="mt-4 text-center">
            <Heading className="user_title_main al_center color-primary">
              {responseData?.title}
            </Heading>
          </Col>
          <Row className="mt-4 mb-4">
            <Row className="user_title al_center color-black">
              Welcome, {authContext.sessionInfo.firstName}{" "}
              {authContext.sessionInfo.lastName}
              <Col className="user_desc">{authContext.sessionInfo.email}</Col>
            </Row>
            <Row className="mt-3 mb-0">
              <ColoredLine color="#6c757d" />
            </Row>
            <Row className="user_desc">
              Before starting the assessment, please make sure that the
              information displayed on this page is accurate. To begin, click on
              the "Start Assessment" button located at the bottom of this page.
              Please note that the assessment can only be progressed forward and
              that you must answer all questions. Your responses will be saved
              automatically as you proceed through the assessment. Once you have
              finished, your results will be submitted automatically.
              Best of luck to you!
            </Row>
            <Row className="mt-3 mb-0">
              <ColoredLine color="#6c757d" />
            </Row>
            <Col>
              <Row className="user_subtitle">Review Materials</Row>
              <Row className="mt-2 mb-0"></Row>
              <Row className="user_desc">
              If you need to refer any documentation prior taking assessment, please reach out to your Recruiter / Supervisor.
              </Row>
              <Row className="mt-4 mb-2 list_title  color-primary">
                {responseData?.title}
              </Row>
              <Col md="11">
                <CustomVideo
                  videoLink={
                    responseData?.banner_video === undefined ||
                      responseData?.banner_video === ""
                      ? ""
                      : responseData?.banner_video
                  }
                />
              </Col>
              <Row className="mt-5 user_subtitle">Exam Information</Row>
              <Row className="mt-2 mb-0">
                <ColoredLine color="#6c757d" />
              </Row>
              <Row className="mt-0 list_subtitle al_left">
                <Col md="5">Assessment Title :</Col>
                <Col className="user_desc">{responseData?.title}</Col>
              </Row>

              <Row className="mt-2 list_subtitle al_left">
                <Col md="5">Number of Questions :</Col>
                <Col className="user_desc">
                  {responseData?.number_of_questions
                    ? responseData?.number_of_questions
                    : 0}
                </Col>
              </Row>

              <Row className="mt-2 list_subtitle al_left">
                <Col md="5">Cut Off Score :</Col>
                <Col className="user_desc">  {responseData?.cutoff_score
                  ? responseData?.cutoff_score
                  : 0}</Col>
              </Row>

              <Row className="mt-2 list_subtitle al_left">
                <Col md="5">Time Remaining :</Col>
                <Col className="user_desc">-</Col>
              </Row>

              <Row className="mt-2 list_subtitle al_left">
                <Col md="5">Attempts Remaining :</Col>
                <Col className="user_desc">
                  {!loading ? (
                    <FormHeading>Loading...</FormHeading>
                  ) : (
                    attemptLeft + " / " + totalAttempt
                  )}
                </Col>
              </Row>
            </Col>
            <Col className="ms-4 me-4 border-left qSection">
              <Row className="ms-4">
                <Row className="user_subtitle">Information</Row>
                {/* <Row className="mt-2">1. This test has one attempt.</Row>
                <Row className="mt-2">
                  2. There is no negative marking in this test.
                </Row>
                <Row className="mt-2">
                  3. The test will pause if you navigate elsewhere.
                </Row>
                <Row className="mt-2">
                  4. The answers will auto-save periodically.
                </Row> */}
                <div className='mt-2 px-0 ' dangerouslySetInnerHTML={{ __html: assessmentInfo }} />
           
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md="4"></Col>
            <Col
              md="7"
              className="mt-4 mb-4 sub_btn d-flex align-items-end  justify-content-end"
            >
              
              <SubmitButton
                type="submit"
                text={
                  inProgress && completed
                    ? "Resume Assessment"
                    : !inProgress && completed
                      ? "Reattempt Assessment"
                      : !inProgress && !completed
                        ? "Start Assessment"
                        : "Resume Assessment"
                }
                disabled={
                  status == "EXPIRED" || status == "PLANNED" ?
                    true :
                    new Date().getTime() <= dueDate
                      ? attemptLeft <= 0
                        ? true
                        : false
                      : true
                }
                isDisabled={
                  status == "EXPIRED" || status == "PLANNED" ?
                    true :
                    new Date().getTime() <= dueDate
                      ? attemptLeft <= 0
                        ? true
                        : false
                      : true
                }
                onClick={() => {
                  
                  if (status !== "EXPIRED" && status !== "PLANNED" && new Date().getTime() < dueDate && attemptLeft > 0) {
                    console.log('responseData?.status', status !== "EXPIRED" && status !== "PLANNED" && new Date().getTime() < dueDate && attemptLeft > 0, dueDate, attemptLeft, responseData?.status)
                    onButtonClick();
                  }
                }}
              />
            </Col>
          </Row>
        </Row>
      </Container>
    </div>
  );
};

export default AssessmentStartPage;
