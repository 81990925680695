//** GinieHealth Publisher Assessment PublishedCard Component with pagination UI code **//

import { useCallback, useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { _getImage } from "../../common/utils/commonFunctions";
import { AppUrlsEnum } from "../../enums/app.url.enums";
import AssessmentCard from "./AssessmentCard";
import SearchButton from "./SearchButton";
import { GetAssesmentByPublisherSearchApi } from "@iguroo/ig-react-assessment-package";
import "./styles.css";
import { useGetAssessments } from "../../hooks/useGetAssessments";
import { useDispatch } from "react-redux";
import { removeDataFromAssessmentDetails } from "../../redux-tk/slices/assessment.slice";
import { loader } from "../../redux-tk/slices/loader.slice";
import { FormHeading } from "../form.heading.component";

const publisher_id = 2;
const PublisherContainer = () => {
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [searchData, setSearchData] = useState(null);

  const dispatch = useDispatch();
  const {
    assessments,
    setAssessments,
    getSearchOnChange,
    getSubmit,
    type,
    fetchAssessments,
    totalRecords,
  } = useGetAssessments({
    apiFunction: GetAssesmentByPublisherSearchApi,
    initialQuery: {
      publisher_id,
      from: currentPage,
      limit: itemsPerPage,
    },
    currentPage,
    itemsPerPage,
  });

  const submit = useCallback(
    async (data) => {
      try {
        dispatch(loader(true));
        setSearchData(data?.search);
        await getSubmit(data, {
          publisher_id,
          ...(data?.search && { search: data?.search }),
          from: currentPage,
          limit: itemsPerPage,
        });
      } catch (error) {
        console.error("Error submitting data:", error);
        setSearchError(true);
      } finally {
        dispatch(loader(false));
      }
    },
    [setAssessments]
  );
  console.log("asessment-pagination", assessments);

  const searchOnChange = useCallback((val) => {
    if (val.length > 0) {
      getSearchOnChange(val);
    } else {
      const fetchInitialData = async () => {
        try {
          dispatch(loader(true));
          setLoading(true);
          await fetchInitialAssessments({
            publisher_id,
            from: currentPage,
            limit: itemsPerPage,
          });
          dispatch(loader(false));
          setLoading(false);
        } catch (error) {
          console.error("Error fetching initial assessments:", error);
          setSearchError(true);
          setLoading(false);
        }
      };
      fetchInitialData();
    }
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        dispatch(loader(true));
        setLoading(true);
        await fetchInitialAssessments({
          publisher_id,
          from: currentPage,
          limit: itemsPerPage,
        });
        dispatch(loader(false));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching initial assessments:", error);
        setSearchError(true);
        setLoading(false);
      }
    };
    fetchInitialData();
  }, [currentPage, itemsPerPage]);

  const fetchInitialAssessments = useCallback(
    async (query) => {
      const auth = await fetchAssessments({
        apiSearchParams: {
          ...query,
          ...(searchData && { search: searchData }),
          from: currentPage * itemsPerPage,
          limit: itemsPerPage,
        },
      });
      console.log("fetched assessments", auth);
      setAssessments(auth);
    },
    [currentPage, itemsPerPage]
  );

  const handleCardClick = () => {
    try {
      console.log("Clicked on card");
      // navigate(AppUrlsEnum.publisher);
    } catch (error) {
      console.error("Error navigating to todo-supervisor:", error);
    }
  };

  return (
    <div className="m-2 p-2 publisherContainer">
      <Row className="d-flex align-items-end">
        <Col sm={12} md={6} lg={6}>
          <Button
            onClick={() => navigate(AppUrlsEnum.publisherAssessmentDetails)}
          >
            <img src={_getImage("plus.svg")} className="me-1" /> Create New
            Assessment
          </Button>
        </Col>
        <Col
          sm={12}
          md={6}
          lg={6}
          xl={6}
          xxl={6}
          className="d-flex justify-content-end"
        >
          <SearchButton submit={submit} handleOnChange={searchOnChange} />
        </Col>
      </Row>
      <hr />
      {/* Draft,Published & Active will be future Enhancement */}
      {/* <div className="mt-4 status-button">
        <Button onClick={() => setType("draft")} style={buttonColor("draft")}>
          Draft
        </Button>
        <Button
          onClick={() => setType("published")}
          style={buttonColor("published")}
        >
          Published
        </Button>
        <Button onClick={() => setType("active")} style={buttonColor("active")}>
          Active
        </Button>
      </div> */}
      {assessments.length > 0 ? (
        <AssessmentCard
          data={assessments}
          itemsPerPage={itemsPerPage}
          totalRecords={totalRecords}
          setCurrentPage={setCurrentPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          onClick={handleCardClick}
        />
      ) : loading ? (
        <FormHeading className="text-center mt-4">Loading...</FormHeading>
      ) : (
        <FormHeading className="text-center mt-4">No Data Found</FormHeading>
      )}
    </div>
  );
};

export default PublisherContainer;
