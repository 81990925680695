//** Iguroo & GinieHealth Support component used in Footer component,can send a email to support team**//

import React, { useContext, useEffect, useState } from "react";
import { Modal, Container, Col, Row, Form } from "react-bootstrap";
import {
  _getCurrencySymbol,
  _getImage,
} from "../../common/utils/commonFunctions";
import { FormErrorComponent } from "../../components/form.error.component";
import { FormLabelComponent } from "../../components/form.label.component";
import { useForm, Controller } from "react-hook-form";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { SubmitButton } from "../submit.button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { getContactForm } from "../../FormSchemas";
import { contactUs } from "../../services/axios/ContactUs/index";
import { Popup } from "../CustomInput/popup";
import { AuthContext } from "../../hooks/auth.context.provider";
require(`../../css/${process.env.REACT_APP_CSS_COMMON}`);

function Support(props) {
  const displaySupportCountryCode =
    process.env.REACT_APP_DISPLAY_SUPPORT_COUNTRYCODE === "true";
  const { show, onHide } = props;
  const {
    formElementLabels,
    formErrors,
    headerFooterTranslations,
    dashboardTranslations,
  } = useIgurooTranslations();
  const user = useSelector((state) => state.user.profile);
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    reset,
    clearErrors,
  } = useForm({
    resolver: yupResolver(getContactForm(formErrors)),
    defaultValues: { email: "", mobileNumber: "", firstname: "", lastname: "" },
  });
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const attrs = authContext?.attrInfo;
    if (attrs?.length > 0) {
      attrs?.map((user) => {
        const data = JSON.parse(user);
        if (data.Name === "phone_number") {
          setValue("mobileNumber", data.Value);
        }
        if (data.Name === "email") {
          setValue("email", data?.Value);
        }
      });
    }
  }, [authContext?.attrInfo]);

  const [value, setInputValue] = useState("");

  const [error, setErrors] = useState(false);
  const handleChange = (event) => {
    if (event.target.value.length <= 34) {
      setInputValue(event.target.value);
      setErrors(false);
    } else {
      setErrors(true);
    }
  };

  const [errorMsg, setErrorsmsg] = useState(false);
  const handleChanges = (event) => {
    if (event.target.value.length <= 34) {
      setInputValue(event.target.value);
      setErrorsmsg(false);
    } else {
      setErrorsmsg(true);
    }
  };

  const [errorabout, setErrorsabout] = useState(false);

  const handleChangemsg = (event) => {
    if (event.target.value.length <= 1999) {
      setInputValue(event.target.value);
      setErrorsabout(false);
    } else {
      setErrorsabout(true);
    }
  };

  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    console.log("checkinggg", data);
    try {
      const res = await contactUs(data);
      setShowPopup(true);
      console.log(res);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleGotIt = () => {
    setShowPopup(false);
    onHide(false);
    reset({
      firstname: "",
      lastname: "",
    });
  };

  return (
    <>
      <Popup
        closeOnBackClick={false}
        showPopup={showPopup}
        type="popup"
        text={formElementLabels?.buttonSave}
        modalBody={dashboardTranslations?.contactPopup}
        onHide={handleGotIt}
      />
      <Modal
        size={"lg"}
        show={show}
        onHide={() => {
          clearErrors();
          return onHide(false);
        }}
        className="book-session"
      >
        <Modal.Header closeButton>
          <Modal.Title>{headerFooterTranslations?.contactUs}</Modal.Title>
        </Modal.Header>
        <Form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body scrollable={true}>
            <Container className="">
              <Form.Group>
                <Row className="m-0 p-0">
                  <Col md={6} className="m-0 p-0 p-2">
                    <Form.Label>{dashboardTranslations?.firstName}</Form.Label>
                    <Controller
                      name={`firstname`}
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomInputField
                          type="text"
                          className="sign-input w-100"
                          placeholder={
                            formElementLabels?.placeHolders?.firstName
                          }
                          hookOnChange={(event) => {
                            handleChange(event);
                            onChange(event);
                          }}
                          hookOnBlur={onBlur}
                          hookValue={value}
                          maxLength={35}
                        />
                      )}
                    />
                    {error && (
                      <FormErrorComponent>
                        {formErrors.firstLastname}
                      </FormErrorComponent>
                    )}
                    <FormErrorComponent>
                      {errors.firstname && errors.firstname?.message}
                    </FormErrorComponent>
                  </Col>
                  <Col md={6} className="m-0 p-0 p-2">
                    <Form.Label>{dashboardTranslations?.lastName}</Form.Label>
                    <Controller
                      name={`lastname`}
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomInputField
                          type="text"
                          className="sign-input w-100"
                          placeholder={
                            formElementLabels?.placeHolders?.lastName
                          }
                          hookOnChange={(event) => {
                            handleChanges(event);
                            onChange(event);
                          }}
                          hookOnBlur={onBlur}
                          hookValue={value}
                          maxLength={35}
                        />
                      )}
                    />
                    {errorMsg && (
                      <FormErrorComponent>
                        {formErrors.firstLastname}
                      </FormErrorComponent>
                    )}
                    <FormErrorComponent>
                      {errors.lastname && errors.lastname?.message}
                    </FormErrorComponent>
                  </Col>
                </Row>
                <Row className="m-0 p-0">
                  <Col md={6} className="m-0 p-0 p-2">
                    <Form.Label>{formElementLabels?.supportMobile}</Form.Label>
                    <Controller
                      name={`mobileNumber`}
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <>
                          {displaySupportCountryCode && (
                            <CustomInputField
                              placeholder={formElementLabels?.supportMobile}
                              hookOnChange={onChange}
                              hookOnBlur={onBlur}
                              hookValue={value}
                              maxLength={13}
                              defaultValue={"+91"}
                            />
                          )}
                          {!displaySupportCountryCode && (
                            <CustomInputField
                              placeholder={formElementLabels?.supportMobile}
                              hookOnChange={onChange}
                              hookOnBlur={onBlur}
                              hookValue={value}
                              maxLength={13}
                            />
                          )}
                        </>
                      )}
                    />
                    <FormErrorComponent>
                      {errors.mobileNumber && errors.mobileNumber?.message}
                    </FormErrorComponent>
                  </Col>
                  <Col md={6} className="m-0 p-0 p-2">
                    <Form.Label>{formElementLabels.emailSigup}</Form.Label>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomInputField
                          type="text"
                          className="sign-input w-100"
                          placeholder={formElementLabels?.placeHolders?.email}
                          hookOnChange={onChange}
                          hookOnBlur={onBlur}
                          hookValue={value}
                        />
                      )}
                    />
                    <FormErrorComponent>
                      {errors.email && errors.email?.message}
                    </FormErrorComponent>
                  </Col>
                </Row>
                <Row className="p-2">
                  <Col>
                    <FormLabelComponent>
                      {formElementLabels.messageTitle}
                    </FormLabelComponent>
                    <Controller
                      name={`aboutMessage`}
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomInputField
                          placeholder={formElementLabels?.placeHolders?.aboutUs}
                          hookOnBlur={onBlur}
                          hookValue={value}
                          formAs={"textarea"}
                          rows={6}
                          hookOnChange={(event) => {
                            handleChangemsg(event);
                            onChange(event);
                          }}
                          maxLength={2000}
                        />
                      )}
                    />
                    {errorabout && (
                      <FormErrorComponent>
                        {formErrors.messageTitle}
                      </FormErrorComponent>
                    )}
                    <FormErrorComponent>
                      {errors.aboutMessage && errors.aboutMessage?.message}
                    </FormErrorComponent>
                  </Col>
                </Row>
              </Form.Group>
              <Row className="m-0 p-0">
                <Col className="m-0 p-0 p-2">
                  <span className="float-end mt-3">
                    <SubmitButton
                      type="submit"
                      text={formElementLabels?.SubmitButton}
                      disabled={isSubmitting}
                    />
                  </span>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}

export default Support;
