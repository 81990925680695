//** Iguroo & GinieHealth Common CheckBox Button Component,can re-use any required field **//

import React from "react";
import { Form } from "react-bootstrap";

export function CustomCheckButton(props) {
  const {
    id,
    className,
    defaultChecked,
    type,
    onChange,
    label,
    hookOnChange,
    hookValue,
    hookOnBlur,
    name,
    checked,
    disabled,
  } = props;
  console.log(disabled, "disabled");
  return (
    <Form.Check
      name={name}
      type={type ? type : "switch"}
      {...(id ? { id: id } : {})}
      label={label ? label : ""}
      className={className}
      defaultChecked={defaultChecked ? defaultChecked : false}
      checked={checked}
      {...(hookValue ? { value: hookValue } : {})}
      onChange={(e) => {
        if (hookOnChange) hookOnChange(e);
        if (onChange) onChange(e.target.value);
      }}
      onBlur={(val) => {
        if (hookOnBlur) hookOnBlur(val);
      }}
      {...(disabled ? { disabled: disabled } : { disabled: false })}
    />
  );
}
