//** Iguroo Aggrid main for My Mentor & Mentee & Request Component,can re-use required field **//

import { AgGridReact } from "ag-grid-react";
import React, { useCallback } from "react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { AgGridNoRows } from "./ag.grid.no.rows";

export const AgGridMain = ({
  columnDefs,
  defaultColDef,
  cellClickedListener,
  onGridReady,
  gridRef,
  pageObj,
  onRowSelected,
  onRowClicked,
  colsToHide,
  noRowsParams,
  className,
  onPaginationChanged,
}) => {
  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const checkColsToHide = useCallback(
    (column) => {
      if (!colsToHide) return false;
      if (colsToHide.length === 0) return false;
      const colField = column?.userProvidedColDef?.field;
      const colFound = colsToHide?.find((val) => {
        if (val === colField) return true;
      });
      return colFound ? true : false;
    },
    [colsToHide]
  );

  const onGridSizeChanged = useCallback(
    (params) => {
      // get the current grids width
      const gridWidth = document.getElementById("grid-wrapper").offsetWidth;
      // keep track of which columns to hide/show
      const columnsToShow = [];
      const columnsToHide = [];
      // iterate over all columns (visible or not) and work out
      // now many columns can fit (based on their minWidth)
      let totalColsWidth = 0;
      const allColumns = gridRef.current.columnApi.getColumns();
      if (allColumns && allColumns.length > 0) {
        for (let i = 0; i < allColumns.length; i++) {
          const column = allColumns[i];
          totalColsWidth += column.getMinWidth() || 0;
          //console.log("col", column);
          const isHide = checkColsToHide(column);
          //console.log("col",isHide,totalColsWidth,gridWidth, column);
          if (totalColsWidth > gridWidth && isHide) {
            columnsToHide.push(column.getColId());
          } else {
            columnsToShow.push(column.getColId());
          }
        }
      }
      // show/hide columns based on current grid width
      // gridRef.current.columnApi.setColumnsVisible(columnsToShow, true);
      // gridRef.current.columnApi.setColumnsVisible(columnsToHide, false);
      // fill out any available space to ensure there are no gaps
      gridRef.current.api.sizeColumnsToFit();
    },
    [checkColsToHide]
  );

  //   const getLocaleText = (params) => {
  //     // to avoid key clash with external keys, we add 'grid' to the start of each key.
  //     const gridKey = "grid." + params.key;
  //     //console.log("para", params);
  //     return gridKey;
  //     // look the value up using an application wide service
  //     //return applicationLocaleService(gridKey);
  //   };

  return (
    <div id="grid-wrapper">
      <div className="ag-theme-alpine" style={{ height: "100vh" }}>
        <AgGridReact
          ref={gridRef}
          className={className} // Ref for accessing Grid's API
          //rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          rowSelection="multiple" // Options - allows click selection of rows
          onCellClicked={cellClickedListener} // Optional - registering for Grid Event
          onGridReady={onGridReady}
          //getLocaleText={getLocaleText}
          onRowClicked={onRowClicked}
          onRowSelected={onRowSelected}
          pagination={true}
          paginationPageSize={pageObj?.pageSize ? pageObj?.pageSize : 10}
          cacheBlockSize={pageObj?.cacheBlockSize ? pageObj?.cacheBlockSize : 10}
          maxBlocksInCache={pageObj?.maxBlocksInCache ? pageObj?.maxBlocksInCache : 30}
          rowModelType="infinite"
          onFirstDataRendered={onFirstDataRendered}
          onGridSizeChanged={onGridSizeChanged}
          rowHeight={85}
          noRowsOverlayComponent={AgGridNoRows}
          noRowsOverlayComponentParams={noRowsParams}
          onPaginationChanged={onPaginationChanged}
          suppressDragLeaveHidesColumns={true}
          enableCellTextSelection={true}
        />
      </div>
    </div>
  );
};
