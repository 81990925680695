import uuid from "react-uuid";

export const swotExampleData = (formElementLabels) => {
  const strengthsData =
    formElementLabels?.placeHolders?.swotTypes?.swotExamples?.strengths;
  const weaknessData =
    formElementLabels?.placeHolders?.swotTypes?.swotExamples?.weakness;
  const oppoData =
    formElementLabels?.placeHolders?.swotTypes?.swotExamples?.opportunities;
  const threatsData =
    formElementLabels?.placeHolders?.swotTypes?.swotExamples?.threats;
  const strData = strengthsData?.map((val) => {
    return {
      id: uuid(),
      data: val,
    };
  });
  const wekData = weaknessData?.map((val) => {
    return {
      id: uuid(),
      data: val,
    };
  });
  const opData = oppoData?.map((val) => {
    return {
      id: uuid(),
      data: val,
    };
  });
  const thrData = threatsData?.map((val) => {
    return {
      id: uuid(),
      data: val,
    };
  });
  return {
    opportunities: opData,
    strengths: strData,
    weaknesses: wekData,
    threats: thrData,
  };
};
