//** GinieHealth TodoSupervisor AgGrid & DetailsCardHeaderAssessment code UI **//

import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row, Button, Container } from "react-bootstrap";
import { _getImage } from "../../common/utils/commonFunctions";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { useRef } from "react";
import { GetAssignedCourseListOrganizationApi } from "@iguroo/ig-react-assessment-package";
import moment from "moment";

import CourseDetailsHeader from "../../components/CoursesComponent/CourseDetailsHeader";
import { SubmitButton } from "../../components/submit.button";
import InviteCourseUserPopup from "./inviteCourseUserPopup";
import { AgGridMain } from "../../components/agGridRenderers/ag.grid.main";

const pagesize = 30;
const pagenumber = 1;

const CourseInvite = () => {
  const [data, setData] = useState({});
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const [showInvitePopUp, setShowInvitePopup] = useState(false);
  const [updateData, setUpdateData] = useState(false);

  const location = useLocation();
  const statePath = location.state;
  


  useEffect(() => {
    const statePath = location.state;
    setData(statePath?.data);
  }, []);
  

  const refreshTable = useCallback((params) => {
    console.log("checking addnew user");
    setUpdateData(!updateData);
    gridRef.current.api.refreshInfiniteCache();
  }, []);

  const onPageChange = useCallback((params) => {
    window.scroll({
      top: 30,
      behavior: "smooth",
    });
  }, []);

  const onGridReadyNew = useCallback(
    (params) => {
      console.log("Refresh");
      const dataSource = {
        rowCount: null,
        getRows: async function (rparams) {
          try {
            gridRef.current?.api?.showLoadingOverlay();
            const filterModel = rparams.filterModel;
            const endRow = rparams.endRow;
            const startRow = rparams.startRow;
            const inputQuery = {
              from: startRow,
              limit: endRow - startRow,
            };
            console.log("Refresh");
            const d = await GetAssignedCourseListOrganizationApi({
              organization_id: statePath?.data?.organization_id,
              course_version_id:
                statePath?.data?.course_id + "-" + statePath?.data?.version,
            });
           // const d= assessments;
            console.log("Refresh call", d);
            if (d.total === 0) {
              gridRef.current?.api?.showNoRowsOverlay();
            } else {
              gridRef.current?.api?.hideOverlay();
            }
            const mappedData = d.response.data.map((item) => {
              return {
                firstName: item?.userData?.firstName,
                lastName: item?.userData?.lastName,
                email: item?.userData?.email,
                mobile: item?.userData?.mobile,
                startDate: moment(item?.createdAt).format("DD/MM/YYYY"),
              };
            });
            rparams.successCallback(mappedData, d.response.total);
          } catch (err) {
            console.log("params", err);
            gridRef?.current?.api?.showNoRowsOverlay();
          }
        },
      };
      params?.api?.setDatasource(dataSource);
    },
    [gridRef]
  );

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      filter: true,
      resizable: true,
      minWidth: 150,
      rowHeight: 30,
    }),
    []
  );

  const rowHeight = 80;

  const [columnDefs] = useState([
    {
      field: "firstName",
      resizable: true,
      sortable: true,
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
    },
    {
      field: "lastName",
      resizable: true,
      sortable: true,
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
    },
    {
      field: "email",
      resizable: true,
      minWidth: 250,
      sortable: true,
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
    },
    {
      field: "mobile",
      resizable: true,
      sortable: true,
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
    },
    {
      field: "startDate",
      resizable: true,
      sortable: true,
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
    },
    {
      field: "Status",
      resizable: true,
      sortable: true,
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
    },
    {
      field: "Action",

      filter: false,
      unSortIcon: false,
      sortable: false,
    },
  ]);

  return (
    <>
      {
        <Container>
          <Row>
            <Col className="details-header assessment-col mb-4" md={12} lg={12}>
              <CourseDetailsHeader
                courseDetails={data}
                // version={version}
                profilePic={_getImage("avatar.svg")}
                className="details-header"
                style={{ backgroundColor: "white" }}
                // validDate={(res) => setValidTill(res)}
              />
            </Col>
          </Row>
          <Row>
            <Col className="todo-btn">
              <SubmitButton
                text={"Invite"}
                onClick={() => setShowInvitePopup(true)}
              />
            </Col>
          </Row>
          <InviteCourseUserPopup 
          show={showInvitePopUp} 
          setShow={(val)=>setShowInvitePopup(val)}
          course_version_id = { statePath?.data?.course_id + "-" + statePath?.data?.version}
          handleGetAssignedUserList={refreshTable}/>
          <hr />
          <div>
            <AgGridMain
              gridRef={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReadyNew}
              onPaginationChanged={onPageChange}
              pageObj={{ pageSize: 20 }}
            />
          </div>
        </Container>
      }
    </>
  );
};

export default CourseInvite;
