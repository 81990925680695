//** GinieHealth Learner Login,MultiPerspective Question UI component code **//

import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../../questionComponent/component/styles.scss";
import update from "immutability-helper";
import { useForm, Controller } from "react-hook-form";
import {
  TimeTakenValue,
  TimeleftValue,
  answerSelected,
  answerValue,
  changeGLCurrentQuestion,
  changeTimeTaken,
  currentGLQuestionValue,
} from "../../../redux-tk/slices/genieLabs.currentQuestion";
import { useDispatch, useSelector } from "react-redux";
import QFooter from "../../questions/qFooter";
import { AuthContext } from "../../../hooks/auth.context.provider";
import { FormErrorComponent } from "../../form.error.component";
import { useIgurooTranslations } from "../../../hooks/use.iguroo.translation";
import { loader } from "../../../redux-tk/slices/loader.slice";
import { useNavigate } from "react-router-dom";
import { UpdateAssessmentAttemptApi } from "@iguroo/ig-react-assessment-package";
import { TestQuestionComponent } from "../../questionComponent/component/question.component";
import MutliCheckBoxRadioAnswerOptions from "../../questionComponent/component/custommultiradioOption"
const MultiPerspectivequestionCourses = (props) => {
  const { testAssessmentTranslations, genieLabsAssessmentTranslations } =
    useIgurooTranslations();
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const currentQuestion = useSelector((state) => currentGLQuestionValue(state));
  const selectedAnswers = useSelector((state) => answerValue(state));
  const [answers, setAnswers] = useState(selectedAnswers);
  const [selectAnswer, setSelectAnswers] = useState([]);
  const [isSubmitting, setisSubmitting] = useState(true);

  useEffect(() => {
    dispatch(loader(true));

    const ansSelect = selectedAnswers.filter(
      (ans) => ans.question_id === props?.questionId
    );
    if (ansSelect.length > 0) {
      setSelectAnswers(ansSelect[0].answer);
      props?.question?.perspectives?.map((presp) => {
        const ansPresp = ansSelect[0].answer?.find((item) => {
          if (item[`${presp.title}`] != undefined)
            return item[`${presp.title}`];
        });
        if (ansPresp) {
          setTimeout(() => {
            setValue(`answerOption_${presp.title}`, ansPresp[`${presp.title}`]);
            setisSubmitting(false);
          }, 3);
        } else {
          setisSubmitting(false);
        }
      });
    } else {
      setisSubmitting(false);
    }
    setAnswers(selectedAnswers);
    dispatch(loader(false));
  }, []);


  const previousClick = () => {
    console.log("next click", currentQuestion);
    if (currentQuestion > 0)
      dispatch(changeGLCurrentQuestion(currentQuestion - 1));
  };

  const authContext = useContext(AuthContext);
  console.log("165 name", props?.assessmentData?.title);
  const onNextClick = (data) => {
    setisSubmitting(true);
    dispatch(loader(true));
    let isEmptyAllow = false;
    let isEmpty = false;
    if (props?.assessmentData?.isSkippingAllowed) {
      isEmptyAllow = props?.assessmentData?.isSkippingAllowed;
    }
    props?.question?.perspectives?.map((presp) => {
      if (data?.[`answerOption_${presp.title}`] === undefined) isEmpty = true;
    });
    if (!isEmptyAllow && isEmpty) {
      setError("answerOption", {
        message: testAssessmentTranslations?.AssessmentOptionError,
      });
      dispatch(loader(false));
      setisSubmitting(false);
    } else {
      if (props.totalQuestion == currentQuestion + 1) {
        //setShowPopup(true);
      }
      const ansSelect = answers.filter(
        (ans) => ans.question_id === props?.questionId
      );
      let ans = [];

      var commentIndex = answers.findIndex(
        (c) => c.question_id === props?.questionId
      );
      let answer = {
        question_id: props.questionId,
        answer: [],
        isMark: false,
      };

      props?.question?.perspectives?.map((perspective) => {
        const ansData = {};
        ansData[`${perspective.title}`] =
          data?.[`answerOption_${perspective.title}`];
        answer.answer.push(ansData);
      });
      if (ansSelect.length === 0) {
        ans = [...answers, answer];
        setAnswers([...answers, answer]);
      } else {
        let updatedItems = answers;
        if (commentIndex > -1) {
          updatedItems = update(answers, {
            [commentIndex]: { $set: answer },
          });
        }
        ans = updatedItems;
      }
      dispatch(answerSelected(ans));
      const attemptData = {
        attempt_id: props?.attempid,
        status:
          currentQuestion + 1 == props?.totalQuestion
            ? "COMPLETED"
            : "INPROGRESS",
        answers: ans,
        assessment_version_id: props?.assessment_version_id,
        userId: authContext.sessionInfo.sub,
      };


      UpdateAssessmentAttemptApi(attemptData)
        .then((res) => {
          dispatch(changeGLCurrentQuestion(currentQuestion + 1));
          dispatch(loader(false));
          setisSubmitting(false);
        })
        .catch((err) => console.log(err));
      if (props?.totalQuestion <= currentQuestion + 1) {
        props.QuizMCQSubmit();
      }
    }
  };

  const navigate = useNavigate();


  console.log(errors, "answersss 165", props);

  return (
    <Container className="">
      <Row className="d-flex justify-content-center">

        <Col sm="11">


          <Row className="mt-3">
            <Col>
              <TestQuestionComponent
                text={props?.question?.question_text}
                queNo={currentQuestion + 1}
              />
            </Col>
          </Row>
          <Row className="">
            <Form onSubmit={handleSubmit(onNextClick)} className="mt-1">
              <Row className="test-answer-block p-4 mt-2 ">
                {" "}
                {props?.question?.perspectives?.map((perspective) => {
                  const ansPresps = selectAnswer?.find((item) => {
                    if (item[`${perspective.title}`] != undefined)
                      return item[`${perspective.title}`];
                  });

                  return (
                    <Col md={props?.question?.perspectives.length > 1 ? 6 : 12}>
                      <div className="mb-1">
                        <b>{perspective.title}</b>
                      </div>
                      <Controller
                        name={`answerOption_${perspective.title}`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name },
                        }) => (
                          <MutliCheckBoxRadioAnswerOptions
                            type={"radio"}
                            hookOnChange={onChange}
                            hookOnBlur={onBlur}
                            onChange={()=>clearErrors(`answerOption`)}
                            options={perspective?.options}
                            hookValue={value}
                            name={name}
                            id={perspective.title}
                            //defaultChecked ={selectAnswer.length>0 ? ansPresp[`${perspective.title}`]: false}
                            {...(ansPresps && {
                              defaultChecked: ansPresps[`${perspective.title}`],
                            })}
                          />
                        )}
                      />
                    </Col>
                  );
                })}
              </Row>
              <Row>
                <FormErrorComponent>
                  {errors?.answerOption && errors?.answerOption?.message}
                </FormErrorComponent>
              </Row>
              <Row>
                <QFooter
                  showPrevious={currentQuestion == 0 ? false : true}
                  onPreviousClick={previousClick}

                  nextText={
                    props?.totalQuestion > currentQuestion + 1
                      ? genieLabsAssessmentTranslations?.next
                      : genieLabsAssessmentTranslations?.submit
                  }
                  onNextClick={() => {
                    clearErrors("answerOption");
                    handleSubmit(onNextClick);
                  }}
                  isSubmitting={isSubmitting}
                />
              </Row>
            </Form>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default MultiPerspectivequestionCourses;
