//** Iguroo Assessment,Sortable SingleItem DNDCard Component **//
//** Currently not using this,will be implement in future enhancement**//

import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { DndTypes } from "../../enums";
import { DndCard } from "./DndCard";

export const SortableItem = ({
  id,
  text,
  name,
  index,
  moveCard,
  addCardBack,
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: DndTypes.card,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item, monitor) {
      const dragIndex = item.index;
      const hoverIndex = index;
      if (item.isListDrag) {
        moveCard(dragIndex, hoverIndex, item);
      } else {
        addCardBack(dragIndex, hoverIndex, item);
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      //moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      //item.index = hoverIndex
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DndTypes.card,
    item: () => {
      return { id, index, text, name, isListDrag: true };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  return (
    <DndCard
      drag={drag}
      handlerId={handlerId}
      text={text}
      className={`${isDragging ? "is-dragging" : ""}`}
    />
  );
};
