//** Iguroo Stripe Payment Error Ui code **//

import React, { useEffect, useState } from "react";
import { axiosStaticData } from "../../services/axios/axios.service";
import { STRIPE_DETAILS } from "../../services/urlConst";
import Spinner from "react-bootstrap/Spinner";
import { Col, Row, Table } from "react-bootstrap";
import moment from "moment";
import "./payment.scss";

const StripeError = () => {
  const [sessionInfo, setSessionInfo] = useState({});
  useEffect(() => {
    const sessionId = localStorage.getItem("stripe_checkout_id");
    axiosStaticData
      .get(STRIPE_DETAILS, {
        params: { session_id: sessionId },
      })
      .then((res) => {
        if (res.data.response) {
          setSessionInfo(res.data.response);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="container">
      {sessionInfo ? (
        <Row>
          <Col md={8} lg={6}>
            <div className="stripe_error_page">
              <h6 className="e_text">Payment is failed</h6>
              <Table className="success-table table" responsive="sm" bordered>
                <thead></thead>
                <tbody>
                  {/* <tr>
                    <th>Name</th>
                    <td>{sessionInfo?.customer_details?.name}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{sessionInfo?.customer_details?.email}</td>
                  </tr> */}
                  <tr>
                    <th>Amount</th>
                    <td>{`${sessionInfo?.currency?.toUpperCase()} ${(
                      sessionInfo?.amount_total / 100
                    ).toFixed(2)}`}</td>
                  </tr>
                  <tr>
                    <th>Transaction ID</th>
                    <td>
                      {sessionInfo?.payment_intent
                        ? sessionInfo?.payment_intent
                        : "-NA-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Payment Status</th>
                    <td className="td-data">
                      {sessionInfo?.status === "complete"
                        ? "Payment Succeeded"
                        : "Payment Failed"}
                    </td>
                  </tr>
                  <tr>
                    <th>Mentee Name</th>
                    <td className="td-data">
                      {" "}
                      {`${sessionInfo?.metadata?.menteeFirstName} ${sessionInfo?.metadata?.menteeLastName}`}
                    </td>
                  </tr>
                  <tr>
                    <th>Mentor Name</th>
                    <td className="td-data">{`${sessionInfo?.metadata?.mentorFirstName} ${sessionInfo?.metadata?.mentorLastName}`}</td>
                  </tr>
                  <tr>
                    <th>Request Title</th>
                    <td className="td-data">{sessionInfo?.metadata?.title}</td>
                  </tr>
                  <tr>
                    <th>Scheduled Start</th>
                    <td>
                      {moment
                        .unix(sessionInfo?.metadata?.startTime)
                        .format("DD-MMM-YYYY .  HH:mm ")}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      ) : (
        <div className="stripe_loader">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </div>
  );
};

export default StripeError;
