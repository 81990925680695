//** MultiSteps ProgressBar SideBar of the Onboarding stage,can use this common Component required Field **//

import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ProgressBar, Step } from "react-step-progress-bar";
import { _getImage } from "../common/utils/commonFunctions";
import { userProfileOnboardingStageValue } from "../redux-tk/slices/user.slice";
import { Link } from "react-router-dom";

const MultiStepProgressBar = ({
  page,
  active,
  typeToUse,
  className,
}) => {
  const onBoardingStage = useSelector((state) =>
    userProfileOnboardingStageValue(state)
  );
  const [stepPercentage, setStepPercentage] = useState(0);

  useEffect(() => {}, [page]);

  return (
    <div className={`${className}`}>
      <ProgressBar percent={stepPercentage}>
        {Object.keys(typeToUse.values).map((val, loopIndex) => {
          const isChecked = onBoardingStage > loopIndex;
          const value = typeToUse.values[val];
          const isActive = value.key === active.key;
          const url = onBoardingStage + 1 > loopIndex ? value.url : "";
          //console.log("val", value.key, active.key);
          const imageUrl = isChecked
            ? "step-check.svg"
            : isActive
            ? "ic_progress_av.png"
            : "ic_progress_na.png";
            
          return (
            <Step>
              {({ accomplished, index, position }) => {
                if (isActive || isChecked) {
                  setStepPercentage(position);
                }
                return (
                  <Link
                    className={`indexedStep ${
                      accomplished ? (isActive ? "null" : "accomplished") : null
                    } ${isActive ? "active" : null}`}
                    to={url}
                  >
                    {isChecked ? (
                      !isActive ? (
                        <Image resizeMode="contain" src={_getImage(imageUrl)} />
                      ) : (
                        index + 1
                      )
                    ) : (
                      index + 1
                    )}
                    {/* {console.log(
                      "step 1",
                      accomplished,
                      index,
                      transitionState,
                      position
                    )} */}
                  </Link>
                );
              }}
            </Step>
          );
        })}
      </ProgressBar>
    </div>
  );
};

export default MultiStepProgressBar;
