//** Iguroo SWOT Assessment ->Swot Popup Component,Can re-use anywhere in required Field**//
//** Currently not using this code,will be implemented in future **//

import React, { useEffect } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { _getImage } from "../../common/utils/commonFunctions";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProfileType } from "../../enums";
import { useSelector } from "react-redux";

export function SwotPopup({
  showPopup,
  modalBody,
  modalBodyTwo,
  buttonTextOne,
  buttonOneOnClick,
  buttonTwoOnClick,
  onHandleClose,
  url,
  gotIt,
  title,
  submitText,
  className,
  svgImg,
  hideSVG,
  infoText,
  children,
}) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const user = useSelector((state) => state.user.profile);
  const [menRec, setMenRec] = useState();

  useEffect(() => {
    if (user.type === ProfileType.mentee) {
      setMenRec(true);
    } else {
      setMenRec(false);
    }
  }, [user.type]);

  useEffect(() => {
    setShow(showPopup);
  }, [showPopup]);

  const handleSubmit = () => {
    setShow(false);
    if (gotIt) {
      gotIt();
    }
    if (url.length > 1) {
      navigate(url);
    }
  };

  return (
    <>
      <Modal
        className={`${className}`}
        show={show}
        onHide={onHandleClose}
        backdrop="static"
        keyboard={false}
      >
        {infoText ? (
          <Modal.Header className="text-center" closeButton>
            <Modal.Title className="w-100">{title}</Modal.Title>
          </Modal.Header>
        ) : (
          <Modal.Header className="text-center">
            <Modal.Title className="w-100">{title}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          {hideSVG ? (
            <></>
          ) : (
            <div className="text-center mt-4">
              <Image
                className="d-inline-block"
                src={_getImage(svgImg)}
                fluid={true}
              />
            </div>
          )}
          {modalBody && (
            <div className="popup-body mt-4 text-center mb-4">
              {modalBody}
              {menRec && (
                <p>
                  <br />
                  {modalBodyTwo}
                </p>
              )}
            </div>
          )}
          {children ? children : <></>}
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center">
          {menRec && (
            <>
              {buttonTextOne && (
                <Button variant="primary" onClick={buttonOneOnClick}>
                  {buttonTextOne}
                </Button>
              )}
            </>
          )}
          {submitText && (
            <Button variant="primary" onClick={buttonTwoOnClick}>
              {submitText}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
