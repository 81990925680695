//** Iguroo Admin StatusTag for AdminTables & Testimonials **//

import React, { useState } from "react";
import { useEffect } from "react";
import { _getImage } from "../../common/utils/commonFunctions";
const StatusTag = (props) => {
  const [bg, setBg] = useState("");
  const [text, setText] = useState("");
  useEffect(() => {
    if (props.data) {
      if (props.data.Status === "APPROVED") {
        setBg("success");
      } else if (props.data.Status === "PENDING") {
        setBg("secondary");
      } else if (props.data.Status === "BLOCK") {
        setBg("danger");
      } else if (props.data.Status === "REJECTED") {
        setBg("danger");
      } else if (props.data.Status === "NEW") {
        setBg("primary");
      } else if (props.data.Status === "ACTIVE") {
        setBg("success");
      } else if (props.data.Status === "INACTIVE") {
        setBg("success");
      }
    }
    setText(props.data.Status);
  }, []);

  return (
    <span
      style={{ fontWeight: "500", fontSize: "12px" }}
      class={`badge p-2 rounded-pill text-bg-${bg}`}
    >
      {text}
    </span>
  );
};

export default StatusTag;
