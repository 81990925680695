//** Iguroo RequestCard UI code **//

import React from "react";
import { ProgressBar } from "react-bootstrap";
import UserCard from "../UserCard";
import "./styles.scss";
import avatar from "../../assets/images/avatar.svg";
import { _getImage } from "../../common/utils/commonFunctions";
import { useNavigate } from "react-router-dom";
import { ProfileType } from "../../enums";
import { isMentorOrPre } from "../../helpers";

function RequestCard({
  userId,
  requestTitle,
  name,
  image,
  subtext,
  type,
  category,
  sessionsCompleted = 0,
  numberOfSessions = 0,
  hideTags = false,
  requestNumber,
}) {
  const navigate = useNavigate();

  const handleCardClick = () => {
    localStorage.setItem(requestNumber, requestTitle);
    if (isMentorOrPre(type)) {
      navigate(`/dashboard/mentor/requests/${requestNumber}`);
    } else if (type === ProfileType.mentee) {
      navigate(`/dashboard/mentee/requests/${requestNumber}`);
    }
  };
  console.log(userId, "iddd");

  return (
    <div
      className="request-card"
      onClick={() => handleCardClick()}
      data-testid="request-card"
    >
      <h3 className="mb-1">
        <img
          src={_getImage("play.svg")}
          height="15"
          className="play"
          alt="play"
        />
        {requestTitle}
      </h3>
      {!hideTags &&
        category?.map((cat) => {
          return (
            <div className="tags mb-2">
              <span className="badge rounded-pill bg-success me-2">
                {cat.value}
              </span>
            </div>
          );
        })}
      <div>
        <UserCard
          name={name}
          picture={image}
          subtext={subtext}
        />
      </div>
      <div className="progress-container">
        {/* ProgressBar would be future enhancement */}
        {/* <ProgressBar
          variant="warning"
          min={0}
          max={numberOfSessions}
          now={sessionsCompleted}
        />{" "} */}
        {/* <span>{`${sessionsCompleted}/${numberOfSessions}`}</span> */}
      </div>
    </div>
  );
}

export default RequestCard;
