import { axiosStaticData, axiosAwsIam } from "../axios.service";
import { STRIPE_CHECKOUT } from "../../urlConst";
import axios from "axios";

export const checkoutToStripe = async (data) => {
  try {
    const URL = STRIPE_CHECKOUT;
    const res = await axiosStaticData.post(URL, data);
    localStorage.setItem("stripe_checkout_id", res.data.response.id);
    return res.data.response;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};
