import { axiosStaticData } from "../axios.service";
import { IGUROO_PROFILE_DATA, IGURU_DEV_URL } from "../../config";
import {
  OPEN_SEARCH,
  LIKE_API,
  BOOKMARK_API,
  GET_TOTAL_SESSION,
  BOOK_A_SESSION,
  CREATE_REQUEST,
  CREATE_SESSION,
  BOOK_A_SLOT,
  RESCHEDULE_A_SESSION,
} from "../../urlConst";
import { ProfileType } from "../../../enums";
import axios from "axios";

export async function MentorSearched(userId, data) {
  try {
    const URL = OPEN_SEARCH;
    data.userId = userId;
    const response = await axiosStaticData.post(`${URL}`, data);
    //console.log(response.data.response.hits, "hits");
    return response.data.response.hits;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function GetMentorList(userId, data) {
  try {
    const URL = OPEN_SEARCH;
    data.userId = userId;
    const response = await axiosStaticData.post(`${URL}`, data);
    return response.data.response;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function LikeAPI(userId, mentorID) {
  try {
    const URL = LIKE_API;
    const data = {
      userId: userId,
      mentorId: mentorID,
    };
    const response = await axiosStaticData.post(URL, data);

    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function GetLikesAPI(userId, mentorID) {
  try {
    const URL = LIKE_API;
    const params = {
      userId,
    };
    const response = await axiosStaticData.get(URL, {
      params,
    });
    // console.log(response.data, "likes");
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function BookMarkAPI(userId, mentorID, bookmark) {
  try {
    const URL = BOOKMARK_API;
    const data = {
      userId: userId,
      mentorId: mentorID,
      bookmark: bookmark,
    };
    const response = await axiosStaticData.post(URL, data);
    // console.log(response.data, "BOOKMARK_API");
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function GetTotalSessions(mentorID) {
  if(!mentorID) return;
  try {
    const URL = GET_TOTAL_SESSION;
    const params = {
      mentorId: mentorID,
    };
    const response = await axiosStaticData.get(URL, { params });
    // console.log(response.data.response.sessionsHeld, "GET_TOTAL_SESSION");
    return response.data.response.sessionsHeld;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}
export async function PostBookaSession(userId, formdata) {
  try {
    console.log(formdata);

    const URL = BOOK_A_SLOT;
    const response = await axiosStaticData.post(URL, formdata);
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}
export async function CreateRequest(userId, formdata) {
  try {
    console.log(formdata);

    const URL = CREATE_REQUEST;
    const response = await axiosStaticData.post(URL, formdata);
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}
export async function CreateSession(userId, formdata) {
  try {
    console.log(formdata);

    const URL = CREATE_SESSION;
    const response = await axiosStaticData.post(URL, formdata);
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}
export async function BookingaSession(userId, formdata) {
  try {
    console.log(formdata);

    const URL = BOOK_A_SESSION;
    const response = await axiosStaticData.post(URL, formdata);
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function RescheduleaSession(userId, formdata) {
  try {
    console.log(formdata);

    const URL = RESCHEDULE_A_SESSION;
    const response = await axiosStaticData.put(URL, formdata);
    console.log(response, "final");
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}
