//** Key and Value of Onboarding Heading Translations -> Check with Value name in en.language.json **//

export function HeadingTranslations(t) {
  return {
    key: "headingTranslations",
    value: {
      wayaoe: t("headings.WAYAOE"),
      aoeScreenAlert: t("headings.aoeScreenAlert"),
      mentorEducation: t("headings.mentorEducation"),
      mentorEducationAlert: t("headings.mentorEducationAlert"),
      mentorWorkExpScreenAlert: t("headings.mentorWorkExpScreenAlert"),
      mentoringSessionAvailability: t("headings.mentoringSessionAvailability"),
      mentorRate: t("headings.mentorRate"),
      mentorAvailableAlert: t("headings.mentorAvailableAlert"),
      mentorProfile: t("headings.mentorProfile"),
      mentorProfileAlert: t("headings.mentorProfileAlert"),
      mentorWorkExpQuestion: t("headings.mentorWorkExpQuestion"),
      mentorRateAlert: t("headings.mentorRateAlert"),
      menteeSkill: t("headings.menteeSkill"),
      menteeEdu: t("headings.menteeEdu"),
      menteeWorkExp: t("headings.menteeWrk"),
      menteeProfile: t("headings.menteeProfile"),
      mentorRateScreenAlert: t("headings.mentorRateScreenAlert"),
      availabilityScreenAlert: t("headings.availabilityScreenAlert"),
      findMentor: t("headings.findMentor"),
      filter: t("headings.filter"),
      confirmMobile: t("headings.confirmMobile"),
      verificationMessage: t("headings.verificationMessage"),
      verificationMessagePhone: t("headings.verificationMessagePhone"),
      verificationMessageEmail: t("headings.verificationMessageEmail"),
      wrongNumber: t("headings.wrongNumber"),
      signIn: t("headings.signIn"),
      signUp: t("headings.signUp"),
      forgetPassword: t("headings.forgetPassword"),
      forgetPasswordMessage: t("headings.forgetPasswordMessage"),
      resetPassword: t("headings.resetPassword"),
      resetPasswordMessage: t("headings.resetPasswordMessage"),
      success: t("headings.success"),
      enterCode: t("headings.enterCode"),
      myMentor: t("headings.myMentor"),
      myMentee: t("headings.myMentee"),
      myRequests: t("headings.myRequests"),
      testPageHeading: t("headings.testPageHeading"),
      testHeading: t("headings.testHeading"),
      selfDiscovery: t("headings.selfDiscovery"),
      selfDiscoveryTestLinkStart: t("headings.selfDiscoveryTestLinkStart"),
      selfDiscoveryTestLinkContinue: t(
        "headings.selfDiscoveryTestLinkContinue"
      ),
      swotAssessmentHeading: t("headings.swotAssessmentHeading"),
      swotHeading: t("headings.swotHeading"),
      resetPasswordMessagePopup: t("headings.resetPasswordMessagePopup"),
    },
  };
}
