import * as yup from "yup";
const emailRules = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export function getSettingsFromTranslated(formErrors) {
  return yup.object().shape(
    {
      firstname: yup
        .string()
        .required(formErrors?.firstname)
        .matches(/^[a-zA-Z0-9\s&,().-]+$/, formErrors?.nameNumSplCharcError),
      lastname: yup
        .string()
        .required(formErrors?.lastname)
        .matches(/^[a-zA-Z0-9\s&,().-]+$/, formErrors?.nameNumSplCharcError),
      email: yup
        .string()
        .matches(emailRules, { message: "Enter a valid email" })
        .required("Email is required"),
      currentPassword: yup
        .string()
        .ensure()
        .when("newPasswordSecurity", {
          is: (val) => !!val,
          then: () =>
            yup
              .string()
              .required(formErrors?.currentPassword)
              .typeError(formErrors?.currentPassword),
        }),
      newPasswordSecurity: yup
        .string()
        .ensure()
        .when("currentPassword", {
          is: (val) => !!val,
          then: () =>
            yup
              .string()
              .required(formErrors?.newPasswordSecurity)
              .typeError(formErrors?.newPasswordSecurity)
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&!$])[a-zA-Z0-9!@#%&!$]+$/,
                formErrors?.passwordReq
              ),
        }),
      repeatPassword: yup.string().when("currentPassword", {
        is: (val) => !!val,
        then: () =>
          yup
            .string()
            .required(formErrors?.newPasswordSecurity)
            .typeError(formErrors?.newPasswordSecurity)
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&!$])[a-zA-Z0-9!@#%&!$]+$/,
              formErrors?.passwordReq
            )
            .oneOf(
              [yup.ref("newPasswordSecurity"), null],
              formErrors?.confirmPassword
            ),
      }),
    },
    [["currentPassword", "newPasswordSecurity", "repeatPassword`"]]
  );
}
