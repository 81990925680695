//** Iguroo & GinieHealth Common Header component ,all the header functionalities have defined here**//

import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { Container, Image, Overlay, Popover, ListGroup } from "react-bootstrap";
import self_discovery from "../../assets/images/selfDiscovery.png";
import { _getImage } from "../../common/utils/commonFunctions";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import bookmark from "../../assets/images/bookmark.svg";
import bell from "../../assets/images/bell.svg";
import header_menu from "../../assets/images/header_menu.svg";
import toggler from "../../assets/images/toggle.svg";
import search from "../../assets/images/search.svg";
import globe from "../../assets/images/globe.svg";
import box from "../../assets/images/box.svg";
import users from "../../assets/images/users.svg";
import bar_chart from "../../assets/images/bar-chart.svg";
import PublisherIcon from "../../assets/images/publisher_icon.png";
import edit from "../../assets/images/edit-2.svg";
import logout from "../../assets/images/log-out.svg";
import { AuthStatus, OnboardingSteps, ProfileType } from "../../enums";
import { Link, useLocation, useNavigate, matchPath } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import { useSelector } from "react-redux";
import { AuthContext } from "../../hooks/auth.context.provider";
import { resetUser } from "../../redux-tk/slices/user.slice";
import { useDispatch } from "react-redux";
import { AppUrlsEnum } from "../../enums";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { isMentorOrPre, openSearchBodyObject } from "../../helpers";
import MyAssessment from "../../assets/images/myassessments.svg";
import supervisor from "../../assets/images/supervisor.svg";
import BookmarkUserCard from "../bookmarkedPopover";
import { GetDataType } from "../../services/axios/staticData";
import { MentorSearched } from "../../services/axios/Dasboard";
import { languageValue } from "../../redux-tk/slices/general.info.slice";
import { showPopupflag } from "../../redux-tk/slices/showPopupflag.slice";
import { PopupUnsaved } from "../CustomInput/popupUnsaved";
import { AddUser } from "../../screens/UserManagement/AddUser";
import home from "../../assets/images/home.svg";
import discoveryImage from "../../assets/images/discoveryImage.svg";
import mentoring from "../../assets/images/mentoring.svg";
import explore_Colleges from "../../assets/images/explore_Colleges.svg";
import blogs from "../../assets/images/blogs.svg";
import forumbee from "../../assets/images/forumbee.svg";
import { getCurrentUser } from "../../services/cognito.service";
import { CustomOTPPopup } from "../CustomInput/customOTPPopup";

const Header = (props) => {
  const {
    headerFooterTranslations,
    headingTranslations,
    authorTranslations,
    genieLabsTranslations,
  } = useIgurooTranslations();
  const { data, updateList } = props;
  const [addUserPopUp, setAddUserPopUp] = useState(false);
  const user = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  const { isDesk } = useQuery();
  const authContext = useContext(AuthContext);
  const [userDetailsUpdate, setUserDetailsUpdate] = useState(false);
  const userFirstNameLetter = useMemo(() => {
    return authContext?.sessionInfo?.firstName?.slice(0, 1)?.toUpperCase();
  }, [authContext.authStatus]);
  const userType = useMemo(() => {
    return authContext?.sessionInfo?.role?.toUpperCase();
  }, [authContext.authStatus]);
  const userInfo = useMemo(() => {
    return authContext?.attrInfoDetail;
  }, [authContext, userDetailsUpdate, setUserDetailsUpdate,addUserPopUp]);
  const isSignedIn = useMemo(() => {
    if (authContext.authStatus === AuthStatus.SignedInTokensGot) {
      return true;
    }
    return false;
  }, [authContext.authStatus]);
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const [page, setPage] = useState({
    landingPage: false,
    mentor: true,
    mentie: false,
  });

  const [showSettings, setShowSettings] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const [mentorData, setMentorData] = useState([]);
  const selectedLanguage = useSelector((state) => languageValue(state));
  const [colleges, setColleges] = useState();
  const [courses, setCourses] = useState();
  const [emailVerified, setEmailVerified] = useState(false);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [verifyType, setVerifyType] = useState("");
  const [verifyMobile, setverifyMobile] = useState(data?.mobile);
  const [verifyEmail, setVerifyEmail] = useState(data?.email);
  const [userUpdate, setUserUpdate] = useState({});

  useEffect(() => {
    if (pathname === AppUrlsEnum.collegesList) {
      setColleges(true);
    } else {
      setColleges(false);
    }
  }, [pathname]);

  //Email Verification
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await getCurrentUser();
        if (user?.signInUserSession?.idToken?.payload?.email_verified) {
          setEmailVerified(true);
          console.log("Email is verified");
        } else {
          setEmailVerified(false);
          console.log("Email is not verified");
        }
        console.log(
          user?.signInUserSession?.idToken?.payload?.email_verified,
          "email is verified:"
        );
      } catch (err) {
        console.error(err);
      }
    };
    fetchUser();
  }, []);

  const sendVerificationEmail = async () => {
    const userEmail = authContext?.sessionInfo?.email;
    console.log("User Email:", userEmail);
    try {
      if (userEmail) {
        await authContext.resendEmailConfirmCode(userEmail);
        console.log("Confirmation code sent successfully");
        setShowOtpPopup(true);
      } else {
        console.error("User email not available");
      }
    } catch (error) {
      console.error("error verification email:", error);
      if (error.code === "UserNotFoundException") {
        console.error("User not found");
      } else if (error.code === "InvalidParameterException") {
        console.error(
          "Invalid parameters. Double-check the email format and other details."
        );
      } else {
        console.error("Unhandled error:", error);
      }
    }
  };

  useEffect(() => {
    const courses = matchPath(
      {
        path: AppUrlsEnum.collegesDetails,
        exact: true,
        strict: false,
      },
      pathname
    );

    if (courses?.pathname) {
      setCourses(true);
    } else {
      setCourses(false);
    }
  }, [pathname]);

  useEffect(() => {
    (async function () {
      console.log(
        "header bookmarks",
        isSignedIn,
        "type",
        userType,
        "stage",
        user.onBoardStage,
        "mystage",
        Object.keys(OnboardingSteps[ProfileType.mentee]).length
      );
      if (!isSignedIn) {
        return;
      }
      if (userType != ProfileType.mentee) {
        return;
      }
      if (
        user.onBoardStage !=
        Object.keys(OnboardingSteps[ProfileType.mentee]).length
      ) {
        return;
      }
      const bookmarkData = await GetDataType("bookmarkData", selectedLanguage);
      const bookmarkTrue = bookmarkData.filter((book) => book.value === true);
      const dataBook = { type: ProfileType.mentor };
      dataBook.bookmarked = bookmarkTrue[0];
      let finalData = openSearchBodyObject(dataBook);
      console.log(finalData, "callstart formdata");
      const searchedData = await MentorSearched(
        authContext.sessionInfo.sub,
        finalData
      );
      setMentorData(searchedData);
      console.log(searchedData, "callstart searchedData");
    })();
  }, [show, isSignedIn, userType, user.onBoardStage]);

  const handleClick = (event) => {
    console.log(show, user.type, user, "show");
    if (
      (user.type === ProfileType.mentor && user.onBoardStage === 6) ||
      (user.type === ProfileType.mentee && user.onBoardStage === 3)
    ) {
      setShow(!show);
      console.log(show, "show");
      setTarget(event.target);
    }
  };

  const popupFlag = useSelector((state) => state.showPopupflag.showPopupflag);
  const [showPopup2, setShowPopup2] = useState(false);

  useEffect(() => {
    console.log(userType, user.type, " user.type");
    if (isMentorOrPre(userType)) {
      setPage({ landingPage: false, mentor: true, mentie: false });
    } else if (userType === ProfileType.mentee) {
      setPage({ landingPage: false, mentor: false, mentie: true });
    } else if (
      userType === ProfileType.author ||
      userType === ProfileType.publisher ||
      userType === ProfileType.learner ||
      userType === ProfileType.supervisor ||
      userType === ProfileType.compliance ||
      userType === ProfileType.admin
    ) {
      setPage({ landingPage: false, mentor: false, mentie: false });
    } else {
      setPage({ landingPage: true, mentor: false, mentie: false });
    }
  }, [userType]);

  // console.log(user, "user-type");
  function handleLogout() {
    if (popupFlag) {
      setShowPopup2(true);
    } else {
      setPage({ landingPage: true, mentor: false, mentie: false });
      authContext.signOut(dispatch, navigate);
    }
  }

  const handleLeave = () => {
    setShowPopup2(false);
    (async function () {
      dispatch(showPopupflag(false));
    })();
    setPage({ landingPage: true, mentor: false, mentie: false });
    authContext.signOut(dispatch, navigate);
  };

  const handleStay = () => {
    setShowPopup2(false);
    (async function () {
      dispatch(showPopupflag(true));
    })();
  };

  const handleNavigate = (link) => {
    navigate(link);
  };

  useEffect(() => {
    if (
      (user.type === ProfileType.mentor && user.onBoardStage === 6) ||
      (user.type === ProfileType.mentee && user.onBoardStage === 3)
    ) {
      setShowSettings(true);
    } else {
      setShowSettings(false);
    }
  }, [user]);

  useEffect(() => {
    if (userType === ProfileType.admin) {
      setShowSettings(true);
    }
  }, [userType]);

  return (
    <Navbar
      expand="md"
      className={colleges || courses ? "fixed-navbar" : ""}
      sticky={colleges || courses ? "top" : "none"}
    >
      <Container className="header manrope-font" fluid>
        <Navbar.Brand ref={ref}>
          <Navbar.Toggle aria-controls="iguroo-navbar-nav">
            {toggle ? (
              <Image onClick={() => setToggle(false)} src={header_menu}></Image>
            ) : (
              <Image
                className="toggler"
                onClick={() => setToggle(true)}
                src={toggler}
              ></Image>
            )}
          </Navbar.Toggle>
          {/* Email verfication would be future enhancement */}
          {emailVerified
            ? ""
            : // <>
              //   <Row>
              //     <p>Email is not verified</p>
              //     {/* <Link onClick={() => setShowOtpPopup(true)}>Verify Email</Link> */}
              //     <Link onClick={() => sendVerificationEmail()}>
              //       Verify Email
              //     </Link>
              //   </Row>
              // </>
              ""}
          {showOtpPopup && (
            <CustomOTPPopup
              closeOnBackClick={false}
              showPopup={showOtpPopup}
              type="popup"
              modalBody={"OTP Verified"}
              onHide={() => setShowOtpPopup(false)}
              submitText={"Submit"}
              verifyType={verifyType}
              verifyText={verifyType === "email" ? verifyEmail : verifyMobile}
              userUpdate={userUpdate}
              updateList={updateList}
            />
          )}
          {page.landingPage ? (
            <>
              {userType === ProfileType.admin ? (
                <></>
              ) : (
                <a
                  href={
                    process.env.REACT_APP_IS_MENU === "false"
                      ? "#"
                      : AppUrlsEnum.landingPage
                  }
                >
                  <Image
                    className=""
                    src={_getImage(process.env.REACT_APP_SITE_LOGO)}
                  />
                </a>
              )}
            </>
          ) : (
            <a
              href={
                userType === ProfileType.author ||
                userType === ProfileType.publisher ||
                userType === ProfileType.learner ||
                userType === ProfileType.supervisor ||
                userType === ProfileType.compliance ||
                userType === ProfileType.admin
                  ? AppUrlsEnum.homepage
                  : pathname.includes(AppUrlsEnum.mentorDashboard) ||
                    (userType === ProfileType.mentor && user.onBoardStage === 6)
                  ? AppUrlsEnum.mentorDashboard
                  : pathname.includes(AppUrlsEnum.menteeDashboard) ||
                    (userType === ProfileType.mentee && user.onBoardStage === 3)
                  ? AppUrlsEnum.menteeDashboard
                  : AppUrlsEnum.landingPage
              }
              className="a-tag"
            >
              <Image
                className={
                  userType === ProfileType.author ||
                  userType === ProfileType.publisher ||
                  userType === ProfileType.learner ||
                  userType === ProfileType.supervisor ||
                  userType === ProfileType.compliance ||
                  userType === ProfileType.admin ||
                  pathname.includes(AppUrlsEnum.mentorDashboard) ||
                  pathname.includes(AppUrlsEnum.menteeDashboard)
                    ? "d-md-none"
                    : ""
                }
                src={_getImage(process.env.REACT_APP_SITE_LOGO)}
              />
            </a>
          )}
        </Navbar.Brand>
        {!page.landingPage &&
          !(
            userType === ProfileType.admin ||
            userType === ProfileType.learner ||
            userType === ProfileType.publisher ||
            userType === ProfileType.author ||
            userType === ProfileType.supervisor ||
            user.type === ProfileType.compliance
          ) && (
            <Nav.Link href="#" className="d-md-none">
              <Image className="globe-icon" src={globe} />
            </Nav.Link>
          )}
        {page.mentor && (
          <>
            {!isDesk && (
              <div>
                <Button variant="light" className="header-btn">
                  <Image className="mentor-icon" src={search} />
                </Button>
                <Button variant="light" className="header-btn">
                  <Image className="mentor-icon" src={bell} />
                </Button>
                <DropdownButton
                  className="profile-name-dropdown d-inline-block p-0 rounded-circle me-2"
                  id="dropdown-item-button"
                  title={
                    <div className="header-profile">
                      <span className="header-profile-name">
                        {userFirstNameLetter}
                      </span>
                    </div>
                  }
                >
                  {showSettings && (
                    <Dropdown.Item
                      as="button"
                      className="fw-400 fs-15 secondary-text"
                      onClick={() => {
                        handleNavigate(AppUrlsEnum.mentorSettings);
                      }}
                    >
                      <Image
                        src={edit}
                        height={15}
                        width={15}
                        className="me-2"
                      />
                      {headerFooterTranslations?.editAccount}
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    as="button"
                    className="fw-400 fs-15 secondary-text"
                    onClick={handleLogout}
                  >
                    <Image
                      src={logout}
                      height={15}
                      width={15}
                      className="me-2"
                    />
                    {headerFooterTranslations?.logOut}
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            )}
          </>
        )}
        {page.mentie && (
          <>
            {!isDesk && (
              <div className="header-icons">
                {pathname.includes(AppUrlsEnum.testAssesmentsquestion) ||
                pathname.includes(AppUrlsEnum.testAssesment) ? (
                  ""
                ) : (
                  <Button
                    variant="light"
                    className="header-btn book"
                    onClick={handleClick}
                  >
                    {/* ()=>
                  navigate(AppUrlsEnum.findMentor,
                    {state: {
                     bookmark:"true"
                    }})}> */}
                    <Image className="mentor-icon" src={bookmark} />
                  </Button>
                )}
                <Button variant="light" className="header-btn">
                  <Image className="mentor-icon" src={bell} />
                </Button>
                <DropdownButton
                  className="profile-name-dropdown d-inline-block p-0 rounded-circle me-2"
                  id="dropdown-item-button"
                  title={
                    <div className="header-profile">
                      <span className="header-profile-name">
                        {userFirstNameLetter}
                      </span>
                    </div>
                  }
                >
                  {showSettings && (
                    <Dropdown.Item
                      as="button"
                      className="fw-400 fs-15 secondary-text"
                      onClick={() => {
                        handleNavigate(AppUrlsEnum.menteeSettings);
                      }}
                    >
                      <Image
                        src={edit}
                        height={15}
                        width={15}
                        className="me-2"
                      />
                      {headerFooterTranslations?.editAccount}
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    as="button"
                    className="fw-400 fs-15 secondary-text"
                    onClick={handleLogout}
                  >
                    <Image
                      src={logout}
                      height={15}
                      width={15}
                      className="me-2"
                    />
                    {headerFooterTranslations?.logOut}
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            )}
          </>
        )}
        {(userType === ProfileType.admin ||
          userType === ProfileType.learner ||
          userType === ProfileType.publisher ||
          userType === ProfileType.author ||
          userType === ProfileType.supervisor ||
          user.type === ProfileType.compliance) &&
          !isDesk && (
            <DropdownButton
              className="profile-name-dropdown d-inline-block p-0 rounded-circle me-0"
              id="dropdown-item-button"
              title={
                <div className="header-profile">
                  <span className="header-profile-name">
                    {userFirstNameLetter}
                  </span>
                </div>
              }
            >
              {
                <Dropdown.Item
                  as="button"
                  className="fw-400 fs-15 secondary-text"
                  onClick={() => {
                    setAddUserPopUp(true);
                  }}
                >
                  <Image src={edit} height={15} width={15} className="me-2" />
                  {headerFooterTranslations?.editAccount}
                </Dropdown.Item>
              }
              <Dropdown.Item
                as="button"
                className="fw-400 fs-15 secondary-text"
                onClick={handleLogout}
              >
                <Image src={logout} height={15} width={15} className="me-2" />
                {headerFooterTranslations?.logOut}
              </Dropdown.Item>
            </DropdownButton>
          )}
        <Navbar.Collapse id="iguroo-navbar-nav">
          <Nav className="me-auto"></Nav>
          {(colleges || courses) && (
            <Nav className="landing-nav">
              <Nav.Link href={AppUrlsEnum.landingPage}>
                <Image src={home} className="me-1" />
                {headerFooterTranslations?.home}
              </Nav.Link>
              <Nav.Link href={AppUrlsEnum.selfDiscoveryColHead}>
                <Image src={discoveryImage} className="me-1" />
                {headerFooterTranslations?.selfDiscovery}
              </Nav.Link>
              <Nav.Link href={AppUrlsEnum.mentoring}>
                <Image src={mentoring} className="me-1" />
                {headerFooterTranslations?.mentoring}
              </Nav.Link>
              <Nav.Link href={AppUrlsEnum.exploreColleges}>
                <Image src={explore_Colleges} className="me-1" />
                {headerFooterTranslations?.exploreColleges}
              </Nav.Link>
              <Nav.Link href={AppUrlsEnum.blogs}>
                <Image src={blogs} className="me-1" />
                {headerFooterTranslations?.blogs}
              </Nav.Link>
              <Nav.Link href={AppUrlsEnum.forum}>
                <Image src={forumbee} className="me-1" />
                {headerFooterTranslations?.forum}
              </Nav.Link>
            </Nav>
          )}
          {page.landingPage && (
            <>
              {/* Colleges Nav link will be future enhancement */}
              {/* <Nav className="landing-nav">
                <Nav.Link href={AppUrlsEnum.landingPage}>
                  {headerFooterTranslations?.home}
                </Nav.Link>
                <Nav.Link href={AppUrlsEnum.selfDiscoveryColHead}>
                  {headerFooterTranslations?.selfDiscovery}
                </Nav.Link>
                <Nav.Link href={AppUrlsEnum.mentoring}>
                  {headerFooterTranslations?.mentoring}
                </Nav.Link>
                <Nav.Link href={AppUrlsEnum.exploreColleges}>
                  {headerFooterTranslations?.exploreColleges}
                </Nav.Link>
                <Nav.Link href={AppUrlsEnum.blogs}>
                  {headerFooterTranslations?.blogs}
                </Nav.Link>
                <Nav.Link href={AppUrlsEnum.forum}>
                  {headerFooterTranslations?.forum}
                </Nav.Link>
              </Nav> */}
              {authContext.authStatus === AuthStatus.SignedIn &&
              userType ===
                (ProfileType.mentee ||
                  ProfileType.mentor ||
                  ProfileType.preApproval) ? (
                <div className="header-profile me-2">
                  <span className="header-profile-name">M</span>
                </div>
              ) : (
                <>
                  {(colleges || courses) && (
                    <Form className="d-flex">
                      <Nav.Link>
                        <Button
                          onClick={() => handleNavigate(AppUrlsEnum.authSignin)}
                          variant="outline-success"
                          size="sm"
                          className="me-2 sign-in-btn"
                        >
                          {headerFooterTranslations?.signIn}
                        </Button>
                      </Nav.Link>
                      <Nav.Link>
                        <Button
                          onClick={() => handleNavigate(AppUrlsEnum.authSignup)}
                          variant="success"
                          size="sm"
                          className="me-2 sign-up-btn"
                        >
                          {headerFooterTranslations?.signUp}
                        </Button>
                      </Nav.Link>
                    </Form>
                  )}
                </>
              )}
            </>
          )}
          {page.mentor && (
            <>
              {!isDesk && (
                <Nav className="mentor-nav">
                  <Link
                    to={AppUrlsEnum.mentorDashboard}
                    className="mentor-link"
                  >
                    <Image src={bar_chart} className="me-2" />
                    {headerFooterTranslations?.dashboard}
                  </Link>
                  <Link to={AppUrlsEnum.mentorRequests} className="mentor-link">
                    <Image src={box} className="me-2" />
                    {headerFooterTranslations?.request}
                  </Link>
                  <Link to={AppUrlsEnum.mentorMentees} className="mentor-link">
                    <Image src={users} className="me-2" />
                    {headingTranslations?.myMentee}
                  </Link>
                  {/* Mentor & Mentee Message components will be future enhancement */}
                  {/* <Link
                    to={AppUrlsEnum.mentorMeassages}
                    className="mentor-link"
                  >
                    <Image src={message} className="me-2" />
                    {headerFooterTranslations?.message}
                    <span className="mentor-messages">10+</span>
                  </Link> */}
                </Nav>
              )}
            </>
          )}
          {page.mentor && (
            <>
              {isDesk && (
                <div>
                  <Button variant="light" className="header-btn">
                    <Image className="mentor-icon" src={search} />
                  </Button>
                  <Button variant="light" className="header-btn">
                    <Image className="mentor-icon" src={bell} />
                  </Button>
                  <DropdownButton
                    className="profile-name-dropdown d-inline-block p-0 rounded-circle me-2"
                    id="dropdown-item-button"
                    title={
                      <div className="header-profile">
                        <span className="header-profile-name">
                          {userFirstNameLetter}
                        </span>
                      </div>
                    }
                  >
                    {showSettings && (
                      <Dropdown.Item
                        as="button"
                        className="fw-400 fs-15 secondary-text"
                        onClick={() => {
                          handleNavigate(AppUrlsEnum.mentorSettings);
                        }}
                      >
                        <Image
                          src={edit}
                          height={15}
                          width={15}
                          className="me-2"
                        />
                        {headerFooterTranslations?.editAccount}
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      as="button"
                      className="fw-400 fs-15 secondary-text"
                      onClick={handleLogout}
                    >
                      <Image
                        src={logout}
                        height={15}
                        width={15}
                        className="me-2"
                      />
                      {headerFooterTranslations?.logOut}
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              )}
            </>
          )}
          {page.mentie && (
            <>
              {!isDesk && (
                <Nav className="mentor-nav">
                  <Link
                    to={AppUrlsEnum.menteeDashboard}
                    className="mentor-link"
                  >
                    <Image src={bar_chart} className="me-2" />
                    {headerFooterTranslations?.dashboard}
                  </Link>
                  <Link to={AppUrlsEnum.menteeRequests} className="mentor-link">
                    <Image src={box} className="me-2" />
                    {headerFooterTranslations?.request}
                  </Link>
                  <Link to={AppUrlsEnum.menteeMentors} className="mentor-link">
                    <Image src={users} className="me-2" />
                    {headerFooterTranslations?.myMentor}
                  </Link>
                  <Link to={AppUrlsEnum.findMentor} className="mentor-link">
                    <Image src={users} className="me-2" />
                    {headingTranslations?.findMentor}
                  </Link>
                  <Link to={AppUrlsEnum.selfDiscovery} className="mentor-link">
                    <Image src={self_discovery} className="me-2" />
                    {headerFooterTranslations?.assessmentsMsg}
                  </Link>
                </Nav>
              )}
            </>
          )}
          {page.mentie && (
            <>
              {isDesk && (
                <div>
                  {pathname.includes(AppUrlsEnum.testAssesmentsquestion) ||
                  pathname.includes(AppUrlsEnum.testAssesment) ? (
                    ""
                  ) : (
                    <Button
                      variant="light"
                      className="header-btn"
                      onClick={handleClick}
                    >
                      {/* //   ()=>
                  // navigate(AppUrlsEnum.findMentor,
                  //   {state: {
                  //    bookmark:true
                  //   }})}> */}
                      <Image className="mentor-icon" src={bookmark} />
                    </Button>
                  )}
                  <Overlay
                    show={show}
                    target={target}
                    placement="bottom"
                    container={ref}
                    trigger="click"
                    rootClose
                    onHide={() => setShow(false)}
                  >
                    <Popover id="popover-contained">
                      <Popover.Body className="p-0">
                        {/* {console.log(mentorData,'callstart')} */}
                        <ListGroup className="p-0">
                          {mentorData?.slice(0, 5).map((bookmarkMentor, i) => (
                            <BookmarkUserCard
                              bookmarkMentor={bookmarkMentor}
                              onClick={() => {
                                setShow(false);
                                navigate(
                                  `${AppUrlsEnum.menteeMentorProfile}?mentorId=${bookmarkMentor._source.userId}`
                                );
                              }}
                            />
                          ))}
                          <ListGroup.Item>
                            <div
                              className="text-center ig-bookmark-viewall"
                              role={"button"}
                              onClick={() => {
                                console.log("navigate callstart");
                                setShow(false);
                                navigate(AppUrlsEnum.findMentor, {
                                  state: {
                                    bookmark: true,
                                  },
                                });
                              }}
                            >
                              View all
                            </div>
                          </ListGroup.Item>
                        </ListGroup>
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                  <Button variant="light" className="header-btn">
                    <Image className="mentor-icon" src={bell} />
                  </Button>
                  <DropdownButton
                    className="profile-name-dropdown d-inline-block p-0 rounded-circle me-2"
                    id="dropdown-item-button"
                    title={
                      <div className="header-profile">
                        <span className="header-profile-name">
                          {userFirstNameLetter}
                        </span>
                      </div>
                    }
                  >
                    {showSettings && (
                      <Dropdown.Item
                        as="button"
                        className="fw-400 fs-15 secondary-text"
                        onClick={() => {
                          handleNavigate(AppUrlsEnum.menteeSettings);
                        }}
                      >
                        <Image
                          src={edit}
                          height={15}
                          width={15}
                          className="me-2"
                        />
                        {headerFooterTranslations?.editAccount}
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      as="button"
                      className="fw-400 fs-15 secondary-text"
                      onClick={handleLogout}
                    >
                      <Image
                        src={logout}
                        height={15}
                        width={15}
                        className="me-2"
                      />
                      {headerFooterTranslations?.logOut}
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              )}
            </>
          )}
          {isDesk && userType !== ProfileType.admin && (
            <>{/* <DropdownSelect /> */}</>
          )}
          {(userType === ProfileType.admin ||
            userType === ProfileType.learner ||
            userType === ProfileType.publisher ||
            userType === ProfileType.author ||
            userType === ProfileType.supervisor ||
            user.type === ProfileType.compliance) &&
            isDesk && (
              <div className="header-icons">
                <DropdownButton
                  className="profile-name-dropdown d-inline-block p-0 rounded-circle me-2"
                  id="dropdown-item-button"
                  title={
                    <div className="header-profile">
                      <span className="header-profile-name">
                        {userFirstNameLetter}
                      </span>
                    </div>
                  }
                >
                  {
                    <Dropdown.Item
                      as="button"
                      className="fw-400 fs-15 secondary-text"
                      onClick={() => {
                        setAddUserPopUp(true);
                      }}
                    >
                      <Image
                        src={edit}
                        height={15}
                        width={15}
                        className="me-2"
                      />
                      {headerFooterTranslations?.editAccount}
                    </Dropdown.Item>
                  }
                  <Dropdown.Item
                    as="button"
                    className="fw-400 fs-15 secondary-text"
                    onClick={handleLogout}
                  >
                    <Image
                      src={logout}
                      height={15}
                      width={15}
                      className="me-2"
                    />
                    {headerFooterTranslations?.logOut}
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            )}
          {userType === ProfileType.learner && (
            <>
              {!isDesk && (
                <Nav className="mentor-nav">
                  <Link
                    to={AppUrlsEnum.learnermyassessment}
                    className="mentor-link"
                  >
                    <Image src={MyAssessment} className="me-2" />
                    {headerFooterTranslations?.myAssessment}
                  </Link>
                </Nav>
              )}
            </>
          )}
          {userType === ProfileType.author && (
            <>
              {!isDesk && (
                <Nav className="mentor-nav">
                  <Link to={AppUrlsEnum.authorUserPage} className="mentor-link">
                    <Image src={users} className="me-2" />
                    {authorTranslations?.author}
                  </Link>
                </Nav>
              )}
            </>
          )}
          {userType === ProfileType.publisher && (
            <>
              {!isDesk && (
                <Nav className="mentor-nav">
                  <Link to={AppUrlsEnum.publisherUser} className="mentor-link">
                    <Image src={PublisherIcon} className="me-2" />
                    {headerFooterTranslations?.publisher}
                  </Link>
                </Nav>
              )}
            </>
          )}
          {userType === ProfileType.supervisor && (
            <>
              {!isDesk && (
                <Nav className="mentor-nav">
                  <Link to={AppUrlsEnum.superVisorUser} className="mentor-link">
                    <Image src={supervisor} className="me-2" />
                    {genieLabsTranslations?.supervisor}
                  </Link>
                  <Link
                    to={AppUrlsEnum.superVisorGinieUser}
                    className="mentor-link"
                  >
                    <Image src={users} className="me-2" />
                    Approval Management
                  </Link>
                </Nav>
              )}
            </>
          )}
          {userType === ProfileType.admin && (
            <>
              {!isDesk && (
                <Nav className="mentor-nav">
                  <Link to={AppUrlsEnum.superVisor} className="mentor-link">
                    <Image src={supervisor} className="me-2" />
                    {genieLabsTranslations?.supervisor}
                  </Link>
                  <Link to={AppUrlsEnum.Users} className="mentor-link">
                    <Image src={users} className="me-2" />
                    User Management
                  </Link>
                </Nav>
              )}
              {userType === ProfileType.compliance && (
                <>
                  {!isDesk && (
                    <Nav className="mentor-nav">
                      <Link
                        to={AppUrlsEnum.publisherUser}
                        className="mentor-link"
                      >
                        <Image src={PublisherIcon} className="me-2" />
                        {headerFooterTranslations?.publisher}
                      </Link>
                      <Link
                        to={AppUrlsEnum.superVisorUser}
                        className="mentor-link"
                      >
                        <Image src={supervisor} className="me-2" />
                        {genieLabsTranslations?.supervisor}
                      </Link>
                      <Link
                        to={AppUrlsEnum.complianceGinieUsers}
                        className="mentor-link"
                      >
                        <Image src={users} className="me-2" />
                        Approval Management
                      </Link>
                    </Nav>
                  )}
                </>
              )}
            </>
          )}
        </Navbar.Collapse>
        <PopupUnsaved
          closeOnBackClick={false}
          showPopup={showPopup2}
          type="popup"
          gotIt={handleLeave}
          gotIt2={handleStay}
        />
        {addUserPopUp && (
          <AddUser
            show={addUserPopUp}
            onHide={() => setAddUserPopUp(false)}
            data={{
              ...userInfo,
              mobile: userInfo?.phoneNumber,
              status: "ACTIVE",
              userId: userInfo?.sub,
              typr: userInfo?.role,
            }}
            edit
            restrictedEdit
            updateList={() => {
              setTimeout(() => setUserDetailsUpdate(!userDetailsUpdate), 300);
            }}
          />
        )}
      </Container>
    </Navbar>
  );
};
export default Header;
