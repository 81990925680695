//** Iguroo & GinieHealth Common Reset Button Component,can re-use any required field **//

import React from "react";
import { _getImage } from "../../common/utils/commonFunctions";

export function CustomResetBtn(props) {
  const { className, textClassName, onClick, text, svgName } = props;

  return (
    <div
      role="button"
      className={className ? className : "resetBtn"}
      onClick={onClick}
    >
      <div>
        <img src={_getImage(svgName ? svgName : "x.svg")} />{" "}
        <span className={`delete-text ${textClassName} `}>
          {text ? text : "Reset all"}
        </span>
      </div>
    </div>
  );
}
