//** Dashboard Find a Mentor Page **//

import React, { useEffect, useState, useContext } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
  Form,
  Row,
  Col,
  Button,
  Image,
  Container,
  InputGroup,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { Heading } from "../../components/heading.component";
import { FormLabelComponent } from "../../components/form.label.component";
import { CustomSelect } from "../../components/CustomInput/custom.select";
import Pagination from "../../components/pagination";
import {
  _getImage,
  _getCurrencySymbol,
} from "../../common/utils/commonFunctions";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { SubmitButton } from "../../components/submit.button";
import { CustomResetBtn } from "../../components/CustomInput/custom.resetbtn";
import { FormHeading } from "../../components/form.heading.component";
import { MentorCards } from "../../components/mentor.card";
import { useDispatch, useSelector } from "react-redux";
import { loader } from "../../redux-tk/slices/loader.slice";
import { GetDataType } from "../../services/axios/staticData";
import { languageValue } from "../../redux-tk/slices/general.info.slice";
import {
  BookMarkAPI,
  GetLikesAPI,
  LikeAPI,
  GetMentorList,
} from "../../services/axios/Dasboard";
import { ProfileType } from "../../enums";
import { openSearchBodyObject } from "../../helpers/api.helpers";
import { AuthContext } from "../../hooks/auth.context.provider";
import { useMentor } from "./hook/useMentor";

const intialData = {
  type: ProfileType.mentor,
};

const data = {
  hits: [],
  total: { value: 0, relation: "" },
};

export default function FindMentorScreen() {
  const { formElementLabels, headingTranslations, profileLabels } =
    useIgurooTranslations();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => languageValue(state));
  const authContext = useContext(AuthContext);

  const [sortBy, setSortBy] = useState([]);
  const [areaOfKnowledge, setareaOfKnowledge] = useState([]);
  const [language, setLanguage] = useState([]);
  const [bookmark, setBookmark] = useState([]);
  const [mentorData, setMentorData] = useState({ ...data });
  const [formData, setFormData] = useState({});
  const [loading, setloading] = useState(true);

  const size = 12;
  const onReset = async () => {
    console.log(intialData, "callstart intial");
    setFormData(intialData);
    reset({
      sortBy: "",
      search: "",
      areaOfExpertise: "",
      language: "",
      bookmarked: "",
    });
    resetPage();
  };
  const location = useLocation();
  const [pageNameD, setpageNameD] = useState("");

  const {
    currentPage,
    moveToNextPage,
    moveToPrevPage,
    setPageToLast,
    resetPage,
  } = useMentor(mentorData.total.value, size);

  useEffect(() => {
    window.scroll({
      top: 30,
      behavior: "smooth",
    });
  }, [currentPage]);

  useEffect(() => {
    (async function () {
      dispatch(loader(true));
      const bookmark = location?.state?.bookmark;
      console.log(location, "callstart location");
      console.log(intialData, "callstart intial");

      setpageNameD(bookmark);
      if (bookmark) {
        const bookmarkData = await GetDataType(
          "bookmarkData",
          selectedLanguage
        );
        const bookmarkTrue = bookmarkData.filter((book) => book.value === true);

        const dataBook = { type: ProfileType.mentor };
        dataBook.bookmarked = bookmarkTrue[0];
        console.log(dataBook, "callstart intial");
        if (bookmarkTrue.length > 0) {
          console.log(dataBook, "callstart intial");
          setFormData(dataBook);
        }
      } else {
        setFormData(intialData);
      }
      dispatch(loader(false));
    })();
  }, [location]);

  const onSubmitFilter = async (data) => {
    data.type = ProfileType.mentor;
    setFormData(data);
    resetPage();
  };

  useEffect(() => {
    (async function () {
      dispatch(loader(true));
      try {
        if (formData.type) {
          let finalData = openSearchBodyObject(formData);
          console.log(currentPage, "currentPage");
          finalData.from = (currentPage - 1) * size;
          finalData.size = size;
          const searchedData = await GetMentorList(
            authContext.sessionInfo.sub,
            finalData
          );
          setMentorData(searchedData);
          if (loading) setloading(false);
        }

        const aokItems = await GetDataType("areaOfemphasis", selectedLanguage);
        setareaOfKnowledge(aokItems);
        const langItems = await GetDataType("languages", selectedLanguage);
        setLanguage(langItems);

        const bookmarkData = await GetDataType(
          "bookmarkData",
          selectedLanguage
        );

        const sortByData = await GetDataType("sortByData", selectedLanguage);

        const bookmark = [];
        bookmarkData.map((bookmarkSearchData) => {
          let dataobj = {
            value: bookmarkSearchData.value,
            dataId: bookmarkSearchData.dataId,
            dataType: bookmarkSearchData.dataType,

            dataValue: bookmarkSearchData.value ? "Yes" : "No",
          };
          bookmark.push(dataobj);
        });
        setBookmark(bookmark);
        setSortBy(sortByData);
        dispatch(loader(false));
      } catch (error) {
        dispatch(loader(false));
      }
    })();
  }, [formData, loading, currentPage]);

  const onbookmarked = async (mentorId, isBookmarked) => {
    const form = formData;
    setloading(true);
    const searchData = await BookMarkAPI(
      authContext.sessionInfo.sub,
      mentorId,
      !isBookmarked
    );
    setloading(false);
    setFormData(form);
  };
  return (
    <Container>
      <Row>
        <Col md="12">
          <Heading className="headingDashboard">
            {headingTranslations?.findMentor}
          </Heading>
          <Row>
            <Col md="9">
              <Form onSubmit={handleSubmit(onSubmitFilter)} className="mt-5">
                <Row className="align-items-end">
                  <Col md="4" className="mt-2">
                    <Form.Group>
                      <FormLabelComponent>
                        {profileLabels?.sortBy}
                      </FormLabelComponent>
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomSelect
                            opLabel="value"
                            opValue="dataId"
                            items={sortBy}
                            onReactHookFormBlur={onBlur}
                            onReactHookFormChange={onChange}
                            value={value}
                            //  onChangeCallback={setSortBy}
                            placeholder={
                              formElementLabels?.placeHolders?.sortBy
                            }
                            sendFullValueInHook={true}
                            normalSelect={true}
                          />
                        )}
                        name="sortBy"
                      />
                    </Form.Group>
                  </Col>
                  <Col className="mt-2" md="6">
                    <Form.Group>
                      <InputGroup className="search-field">
                        <InputGroup.Text className="search-icon">
                          <img src={_getImage("searchbar.svg")} />
                        </InputGroup.Text>
                        <Controller
                          name="search"
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <CustomInputField
                              placeholder={
                                formElementLabels?.placeHolders?.searchMentor
                              }
                              hookOnChange={onChange}
                              hookOnBlur={onBlur}
                              hookValue={value}
                            />
                          )}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="2" className="mt-2 d-grid">
                    <SubmitButton
                      type="submit"
                      text={formElementLabels?.buttonSearch}
                    />
                  </Col>
                </Row>
              </Form>

              <Row className="mt-2">
                {mentorData.hits.length > 0 ? (
                  <>
                    {mentorData.hits.map((mentor, index) => {
                      const title =
                        mentor._source.firstName +
                        " " +
                        mentor._source.lastName;
                      const language = mentor._source.languages?.map(
                        (language, i) =>
                          mentor._source.languages.length === i + 1
                            ? language.value
                            : `${language.value}, `
                      );
                      const currency = mentor._source?.rates
                        ? mentor._source?.rates[0]?.curreny
                        : "";
                      let rate = "";

                      rate = mentor._source?.rates
                        ? mentor._source?.rates[0]?.rate > 0
                          ? _getCurrencySymbol(currency) +
                            mentor._source?.rates[0].rate
                          : formElementLabels?.placeHolders?.free
                        : "";
                      const duratiob = mentor._source?.rates
                        ? mentor._source?.rates[0]?.rate > 0
                          ? mentor._source?.rates[0]?.time
                          : ""
                        : "";
                      const mentorID = mentor._source?.userId;
                      // /const seesion = getSessionCount(mentorID);

                      return (
                        <MentorCards
                          key={index}
                          title={title}
                          imageUrl={mentor._source?.profilePic}
                          // locationText={mentor._source?.timeZone?.value}
                          language={language}
                          profileTitle={mentor._source?.profileTitle}
                          likes={mentor._source?.profileLikes}
                          rateText={rate}
                          duration={duratiob}
                          mentorId={mentorID}
                          bookmark={mentor._source?.isBookmarked}
                          //onLikeClick={() => onLikeClick(mentor._source.userId)}
                          onBookmarkCLick={() =>
                            onbookmarked(
                              mentor._source?.userId,
                              mentor._source?.isBookmarked
                            )
                          }
                          isVerified={mentor._source?.isApproved}
                          skills={mentor._source?.areaOfExpertise}
                        />
                      );
                    })}
                    <Pagination
                      moveToNextPage={moveToNextPage}
                      moveToPrevPage={moveToPrevPage}
                      setPageToLast={setPageToLast}
                      resetPage={resetPage}
                      currentPage={currentPage}
                      totalItems={mentorData.total.value}
                      size={size}
                    />
                  </>
                ) : loading ? (
                  <FormHeading className="text-center mt-4">
                    Loading...
                  </FormHeading>
                ) : (
                  <FormHeading className="text-center mt-4">
                    {formElementLabels?.noMatch}
                  </FormHeading>
                )}
              </Row>
            </Col>
            <Col md="3">
              <Form onSubmit={handleSubmit(onSubmitFilter)} className="mt-5">
                <FormHeading svgName="filter.svg">
                  {headingTranslations?.filter}
                </FormHeading>
                <Form.Group className="mt-3">
                  <FormLabelComponent>
                    {profileLabels?.areaofExpertise}
                  </FormLabelComponent>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomSelect
                        opLabel="value"
                        opValue="dataId"
                        items={areaOfKnowledge}
                        onReactHookFormBlur={onBlur}
                        onReactHookFormChange={onChange}
                        value={value}
                        // onChangeCallback={setareaOfKnowledge}
                        sendFullValueInHook={true}
                        normalSelect={true}
                      />
                    )}
                    name="areaOfExpertise"
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <FormLabelComponent>
                    {profileLabels?.language}
                  </FormLabelComponent>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomSelect
                        opLabel="value"
                        opValue="dataId"
                        items={language}
                        onReactHookFormBlur={onBlur}
                        onReactHookFormChange={onChange}
                        value={value}
                        //onChangeCallback={setLanguage}
                        sendFullValueInHook={true}
                        normalSelect={true}
                        isMulti={true}
                      />
                    )}
                    name="language"
                  />
                </Form.Group>

                <Form.Group className="mt-3">
                  <FormLabelComponent>
                    {profileLabels?.bookmarked}
                  </FormLabelComponent>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomSelect
                        opLabel="dataValue"
                        opValue="dataId"
                        items={bookmark}
                        onReactHookFormBlur={onBlur}
                        onReactHookFormChange={onChange}
                        value={value}
                        // onChangeCallback={setBookmark}
                        sendFullValueInHook={true}
                        normalSelect={true}
                      />
                    )}
                    name="bookmarked"
                  />
                </Form.Group>
                <Form.Group>
                  <Row className="mt-3 justify-content-between  align-items-between ">
                    <Col md="6" className="d-flex justify-content-start">
                      <CustomResetBtn onClick={onReset} />
                    </Col>
                    <Col md="6" className="d-flex justify-content-end">
                      <SubmitButton
                        type="submit"
                        text={formElementLabels?.buttonApply}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
