//** Iguroo Assessment and Report Language can change Code UI **//

import React, { useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { PopupUnsaved } from "../../components/CustomInput/popupUnsaved";
import { showPopupflag } from "../../redux-tk/slices/showPopupflag.slice";
import { SubmitButton } from "../../components/submit.button";
import { setAssessmentLanguage } from "../../services/axios/Assessment/assessment";
import { AuthContext } from "../../hooks/auth.context.provider";
import toaster from "../../components/toaster";
import { toast } from "react-toastify";
import { Languages } from "../../enums";

const AssessmentLanguageSelection = () => {
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const { menteeOnboardingLabels, formElementLabels } = useIgurooTranslations();
  const profile = useSelector((state) => state.user.profile);
  const popupFlag = useSelector((state) => state.showPopupflag.showPopupflag);
  const [selectedPage, setSelectedPage] = useState("Basic Info");
  const [categorey, setCategory] = useState("accountsettings");
  const [showPopup2, setShowPopup2] = useState(false);
  const [pageSelected, setPageSelected] = useState("");

  const userLanguage = localStorage.getItem("assessmentlanguage")
    ? localStorage.getItem("assessmentlanguage")
    : Languages.english;

  const [selectedLang, setSelectedLang] = useState(userLanguage);

  const languageList = [
    {
      value: Languages.english,
      label: menteeOnboardingLabels?.english,
      checked: userLanguage === Languages.english ? true : false,
    },
    {
      value: Languages.hindi,
      label: menteeOnboardingLabels?.hindi,
      checked: userLanguage === Languages.hindi ? true : false,
    },
    {
      value: Languages.telugu,
      label: menteeOnboardingLabels?.telugu,
      checked: userLanguage === Languages.telugu ? true : false,
    },
    {
      value: Languages.tamil,
      label: menteeOnboardingLabels?.tamil,
      checked: userLanguage === Languages.tamil ? true : false,
    },
    {
      value: Languages.kannada,
      label: menteeOnboardingLabels?.kannada,
      checked: userLanguage === Languages.kannada ? true : false,
    },
    {
      value: Languages.odiya,
      label: menteeOnboardingLabels?.odia,
      checked: userLanguage === Languages.odiya ? true : false,
    },
  ];
  console.log("LOG languageList", languageList);

  const handleLeave = () => {
    setShowPopup2(false);
    (async function () {
      dispatch(showPopupflag(false));
    })();
    if (pageSelected === "none") {
      setCategory("security");
      setSelectedPage("none");
    } else if (pageSelected === "assessment") {
      setCategory("assessmentLanguage");
      setSelectedPage("assessment");
    } else {
      setSelectedPage(pageSelected);
      setCategory("accountsettings");
    }
  };

  const onSaveCLick = () => {
    (async function () {
      setAssessmentLanguage(authContext?.sessionInfo?.sub, selectedLang)
        .then((res) => {
          const response = res?.response;
          localStorage.setItem("assessmentlanguage", selectedLang);
          localStorage.setItem("assessmentlanguageFlag", false);
          toaster(toast.success, "Language changed successfully");
        })
        .catch((err) => console.log("LOG  ERROR", err));
    })();
  };

  const handleStay = () => {
    setShowPopup2(false);
  };

  const onValueChange = (event) => {
    setSelectedLang(event.target.value);
    (async function () {
      dispatch(showPopupflag(true));
    })();
  };

  return (
    <Container className="m-0 p-0 manrope-font">
      <Row className="m-0 p-0">
        <label
          className="lang-popup-profile-title"
          style={{ minHeight: "0vh" }}
        >
          {menteeOnboardingLabels.selectLanguageForSwotProfile}
        </label>
        {languageList.map((x, i) => (
          <label
            key={i}
            className="mb-0 mt-4 align-items-top justify-content-top lang-popup-profile2 col-3"
          >
            <input
              type="radio"
              className="radio-popup-lang"
              name="lang"
              value={x.value}
              defaultChecked={x.checked === true ? true : null}
              onChange={onValueChange}
            />{" "}
            {x.label}
          </label>
        ))}
      </Row>
      <Row className="text-end mb-4 mt-5 justify-content-end save-btn-end">
        <Col sm="12" md="2" className="d-grid me-5">
          <SubmitButton
            type="submit"
            text={formElementLabels?.save}
            onClick={onSaveCLick}
          />
        </Col>
      </Row>
      <PopupUnsaved
        closeOnBackClick={false}
        showPopup={showPopup2}
        type="popup"
        gotIt={handleLeave}
        gotIt2={handleStay}
      />
    </Container>
  );
};

export default AssessmentLanguageSelection;
