import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import { useContext, useEffect, useMemo, useState, memo, useRef } from "react";
import { Spinner } from "react-bootstrap";
import { getUploadFileFromS3, getdocSignedURL } from "../../common/aws.helpers";
import { AuthContext } from "../../hooks/auth.context.provider";
import ReactPlayer from "react-player";
// import Control from "./controller";


export const CourseVideo = ({ videoLink, isPublicProfile, isExternal, onEnded, videoKey,videoEnd }) => {
  const authContext = useContext(AuthContext);
  const [vidLink, setVidLink] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const memoizedVidLink = useMemo(() => {
    console.log("memoized link updated", vidLink, videoLink)
    return vidLink;
  }, [vidLink])
  useEffect(() => {
    console.log("video key is ", videoLink);
    if (videoLink && !isExternal) {
      (async function () {
        setIsLoading(true);

        const vid = await getdocSignedURL(videoLink);
        console.log("base64img", vid);
        setIsLoading(false);
        setVidLink(vid);
      })();
    }
  }, [videoLink]);


  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const playerRef = useRef(null);

  useEffect(() => {
    const storedTime = localStorage.getItem(videoKey);
    
    console.log("Stored time", storedTime);
    if (storedTime !== null && isLoaded) {
      const parsedTime = parseFloat(storedTime);
      if (!isNaN(parsedTime)) {
        setPlayedSeconds(parsedTime);
        if (playerRef.current) {
          console.log("Stored time if", storedTime, playerRef.current);
          playerRef.current.seekTo(parsedTime, 'seconds');
        }
      }
    }
  }, [videoKey, isLoaded]);

  const handleProgress = (state) => {
    const { playedSeconds: newTime } = state;
    console.log("Current time", newTime);
    if (newTime !== playedSeconds && isLoaded) {
      setPlayedSeconds(newTime);
    }
  };

  const handleReady = () => {
    setIsLoaded(true);
  };

  const handlePause = () => {
    const video = playerRef.current;
    if (video) {
      const newTime = video.getCurrentTime();
      console.log("Paused at videoEnd", newTime);
      videoEnd(newTime.toString());
      localStorage.setItem(videoKey, newTime.toString());
    }
  };
  //   if (isLoading) {
  //     return <Spinner />;
  //   }
  const [videoState, setVideoState] = useState({
    playing: false,
    muted: false,
    volume: 0.5,
    played: 0,
    seeking: false,
    Buffer: true
  });
  const { playing, muted, volume, playbackRate, played, seeking, buffer } = videoState

  const opts = {

    playerVars: {
      autoplay: 1,
      controls: 0,
      modestbranding: 0,
      rel: 0,
      showinfo: 1
    },

  };
  const onContextMenu = (event) => {
    event.preventDefault();
  };
  const playPauseHandler = () => {
    //plays and pause the video (toggling)
    setVideoState({ ...videoState, playing: !videoState.playing });
  };
  const rewindHandler = () => {
    //Rewinds the video player reducing 5
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 5);
    console.log(playerRef.current.getCurrentTime() + 10,'fastFowardHandler')
  };
  const fastFowardHandler = () => {
    //FastFowards the video player by adding 10
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
    console.log(playerRef.current.getCurrentTime() + 10,'fastFowardHandler')
  };
  return (



    <div className="video-container player__wrapper" >
      <ReactPlayer
        url={isExternal ? videoLink : memoizedVidLink}
        className={'course-videos'}
        // controls
        width='100%'
        ref={playerRef}
        controls={true}

        onEnded={onEnded}
        onProgress={handleProgress}
        onReady={handleReady}
        onPause={handlePause}
        playing={playing}
      // height='460px'
      />
      {/* <Control
        onPlayPause={playPauseHandler}
        onRewind={rewindHandler}
        onForward={fastFowardHandler}
        playing={playing} /> */}
    </div>
  );
};

export const CourseVideoMemo = memo(CourseVideo);