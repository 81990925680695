import * as yup from "yup";

const emailRules = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const phoneNumberRules = /^\+\d{1,3}\d{10,14}$/;

const alphaNumericRules = /^[a-zA-Z ][a-zA-Z0-9 ]*$/;

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&!$])[a-zA-Z0-9!@#%&!$]+$/;

export const addNewUserValidation = (formErrors) => {
  return yup.object(
    {
      firstName: yup
        .string()
        .trim()
        .matches(alphaNumericRules, { message: "Enter a valid first name" })
        .required("First name is required"),
      lastName: yup
        .string()
        .trim()
        .matches(alphaNumericRules, { message: "Enter a valid last name" })
        .required("Last name is required"),
      type: yup.string().required("Select role"),
      status: yup.string().required("Select status"),
      mobile: yup
        .string()
        .max(13, "Valid Mobile Number is required")
        .matches(phoneNumberRules, { message: "Enter a valid phone number" })
        .required("Phone Number Required"),
      email: yup
        .string()
        .matches(emailRules, { message: "Enter a valid email" })
        .required("Email is required"),
      newPasswordSecurity: yup
        .string()
        .ensure()
        .when("repeatPassword", {
          is: (val) => !!val,
          then: () =>
            yup
              .string()
              .required("New Password is required")
              .min(
                5,
                "Password should contain at least one uppercase letter, one lowercase letter, number and special character(@,#,%,&,!,$)"
              )
              .matches(passwordRegex, {
                message:
                  "Password should contain at least one uppercase letter, one lowercase letter, number and special character(@,#,%,&,!,$)",
              })
              .typeError("New Password is required"),
        }),
      repeatPassword: yup
        .string()
        .ensure("Repeat Password is required")
        .oneOf(
          [yup.ref("newPasswordSecurity"), null],
          "Doesn't match with New Password"
        ),
      organization_id: yup
        .string()
        .ensure()
        .when("userType", {
          is: (val) => val == "igurooUser",
          then: () => yup.string().required("Select OrganizationId"),
        }),
      iguroo_role: yup
        .string()
        .ensure()
        .when("userType", {
          is: (val) => val == "igurooUser",
          then: () => yup.string().required("Select UserRole"),
        }),
    },
    [["newPasswordSecurity", "repeatPassword", "userType", "igurooUser"]]
  );
};
