import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import { _getImage } from "../../common/utils/commonFunctions";

export default function CourseCard(props) {
    const {
        title,
        type,
        text,
        number,
        index,
        currentIndex,
        onClick,
        image
    } = props;
    return (
        <>
            <Card
                className={`${index == currentIndex ? 'active' : ''} p-3 rounded-0 course-card`}
                onClick={() => onClick(index)}
                role={"button"}>
                <div className="ig-course-title">{index +1}{'. '}{title}</div>
                <div className="ig-courses-subtitle mt-1">
                    <img
                        height={20}
                        width={20}
                        src={_getImage(image)}
                        className="icon"
                    />{'  '}
                    10 seconds
                </div>
            </Card>
        </>
    )
}
