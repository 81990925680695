import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import CourseProgress from "../../components/CoursesComponent/CourseProgress";
import { CourseVideo } from "../../components/CoursesComponent/videoComponent";
import { CardTitleComponent } from "../../components/cardComponent/card.title";
import CourseCard from "../../components/CoursesComponent/coursesCard";
import CoursePDFViewer from "../../components/CoursesComponent/CoursePDFViewer";
import CoursePowerPointViewer from "../../components/CoursesComponent/CoursePowerPointViewer";
import CourseURLCard from "../../components/CoursesComponent/CoursesURLCard";
import CoursesRichtextCard from "../../components/CoursesComponent/CoursesRichtextCard";
import { SubmitButton } from "../../components/submit.button";
import { useGetAssessments } from "../../hooks/useGetAssessments";
import { GetCourseDetailApi, CreateCourseUserStateApi } from "@iguroo/ig-react-assessment-package";
import { useLocation } from "react-router-dom";
import CourseQuizMain from "../../components/CoursesComponent/courseQuizMain";
import { AuthContext } from "../../hooks/auth.context.provider";
import { organizationValue } from "../../redux-tk/slices/general.info.slice";
import {  useSelector } from "react-redux";


export default function CourseDetails(props) {
    const [currentIndex, setCurrentIndex] = useState();
    const [currentData, setCurrentData] = useState({});
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [courseID, setCourseID] = useState(100);
    const [version, setVersion] = useState(1);
    const [videoEnd, setVideoEnd] = useState('0');
    const organization_id = useSelector((state) => organizationValue(state));

    // const data = {
    //     course: {
    //         version: 1,
    //         course_id: "100",
    //         title: "Learn AWS in 24 hours"
    //     },
    //     courseContentUnitData: {
    //         Items: [
    //             {
    //                 course_version_id: "100-1",
    //                 content_id: "1",
    //                 contentUnitData: {
    //                     content_title: "AWS In 10 Minutes | AWS Tutorial For Beginners | AWS Training Video | AWS Tutorial",
    //                     content_id: "1",
    //                     content_details: {
    //                         location: "https://www.youtube.com/watch?v=r4YIdn2eTm4"
    //                     },
    //                     content_type: "YOUTUBE"
    //                 }
    //             },
    //             {
    //                 course_version_id: "100-1",
    //                 content_id: "2",
    //                 contentUnitData: {
    //                     content_title: "AWS Introduction",
    //                     content_id: "2",
    //                     content_details: {
    //                         location: "https://iguroo-data-dev.s3.amazonaws.com/sample/aws.mp4"
    //                     },
    //                     content_type: "VIDEO"
    //                 }
    //             },
    //             {
    //                 course_version_id: "100-1",
    //                 content_id: "3",
    //                 contentUnitData: {
    //                     content_title: "How to get AWS Certified?",
    //                     content_id: "3",
    //                     content_details: {
    //                         data: '<h1>Aut quis minima ut blanditiis ratione. </h1><p>Lorem ipsum dolor sit amet. In nisi quia et iusto doloremaut velit non voluptate sint aut suscipit autem. Ut iusto facilis a exercitationem voluptas <strong>Sed iure et ipsa dolorem rem velit cumque ut optio ratione</strong> aut distinctio eaque. </p><ul><li>Et earum error eum nihil galisum. </li><li>Et eveniet error quo dicta voluptatem et suscipit quis. </li><li>Non aliquam eaque ab reiciendis voluptatem et veniam suscipit qui obcaecati dolores! </li><li>Aut obcaecati fugiat est maiores rerum. </li></ul>'

    //                     },
    //                     content_type: "RICH_TEXT"
    //                 }
    //             },
    //             {
    //                 course_version_id: "100-1",
    //                 content_id: "4",
    //                 contentUnitData: {
    //                     content_title: "Automatically detect Personally Identifiable Information in Amazon Redshift using AWS Glue",
    //                     content_id: "4",
    //                     content_details: {
    //                         location: "https://aws.amazon.com/blogs/big-data/automatically-detect-personally-identifiable-information-in-amazon-redshift-using-aws-glue/"
    //                     },
    //                     content_type: "URL"
    //                 }
    //             },
    //             {
    //                 course_version_id: "100-1",
    //                 content_id: "5",
    //                 contentUnitData: {
    //                     content_title: "ChatGPT and the Future of Work",
    //                     content_id: "5",
    //                     content_details: {
    //                         location: "https://www.slideshare.net/slideshow/embed_code/key/AC8sNmsoIKbWIo?startSlide=1"
    //                     },
    //                     content_type: "SLIDESHARE"
    //                 }
    //             },
    //             {
    //                 course_version_id: "100-1",
    //                 content_id: "6",
    //                 contentUnitData: {
    //                     content_title: "AWS Overview",
    //                     content_id: "6",
    //                     content_details: {
    //                         location: "https://iguroo-data-dev.s3.amazonaws.com/sample/aws-overview.pdf"
    //                     },
    //                     content_type: "PDF"
    //                 }
    //             },
    //             {
    //                 course_version_id: "100-1",
    //                 content_id: "7",
    //                 contentUnitData: {
    //                     content_title: "AWS related Slides",
    //                     content_id: "7",
    //                     content_details: {
    //                         location: "https://iguroo-data-dev.s3.amazonaws.com/sample/aws-slides.pptx"
    //                     },
    //                     content_type: "PPT"
    //                 }
    //             }
    //         ],
    //         Count: 7,
    //         ScannedCount: 7
    //     }
    // };
    const {
        assessments,
        setAssessments,
        fetchAssessments,
        fetchInitialAssessments,
        getSearchOnChange,
        getSubmit,
        setType,
        type,
    } = useGetAssessments({
        apiFunction: GetCourseDetailApi,
        initialQuery: {
            course_id: 100, version: 1,

        },
        originalReturn: true
    });
    const authContext = useContext(AuthContext);

    useEffect(() => {
        setCurrentIndex(0);
        const searchData = location.search;
        const search = searchData.split("&");
        const course_id = search[0].split("=")[1];
        const version = search[1].split("=")[1];
        setCourseID(course_id);
        setVersion(version);
        // console.log(location,search,lastItem,searchData, 'assessments assessments')
        fetchInitialAssessments({ course_id: course_id , version: version });
    }, []);
    useEffect(() => {
        console.log(assessments?.response?.data, 'assess')
        if (assessments?.response?.data) {
            setData(assessments?.response?.data)
            setIsLoading(false);
        }
    }, [assessments]);
    // console.log(assessments,assessments?.response?.data,Object.keys(data), 'assessments assessments')
    useEffect(() => {
        if (data != null) {
            console.log(data, data?.courseContentUnitData, currentIndex, 'assess')
            setCurrentData(data?.courseContentUnitData[currentIndex])
        }
    }, [data, assessments, currentIndex])
    const nextClick = (cIDX) => {
        const progress_data = videoEnd;
        const stateData = {
            userId: authContext.sessionInfo.sub,
            course_version_id: courseID+'-'+version,
            status: 'COMPLETED',
            organization_id: organization_id,
            content_id: currentData?.content_id,
            progress_data: progress_data,
            // last_position: currentData?.content_id,
        }
        const stateCourse = CreateCourseUserStateApi(stateData);
        console.log(stateCourse, 'stateCourse assessmentw')
        if (cIDX <= data?.courseContentUnitData?.length){
            console.log(stateCourse, 'stateCourse assessmentw')
            setCurrentIndex(cIDX)}

    }
    return (<>
        <Container fluid >
            <div className="mt-3">
                {isLoading && (
                    <div className="loader-background">
                        <div className="stripe_loader">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </div>
                )}
                <Row>
                    <Col md="9">
                        <div className=" hover-overlay ">
                            {currentData?.contentUnitData?.content_type == 'VIDEO' || currentData?.contentUnitData?.content_type == 'YOUTUBE' ?
                                <CourseVideo
                                    videoLink={currentData?.contentUnitData?.content_details?.location}
                                    isExternal={currentData?.contentUnitData?.content_type == 'VIDEO' ? false : true}
                                    onEnded={() => setCurrentIndex(currentIndex + 1)}
                                    videoKey={`videoKey_${currentData?.contentUnitData?.content_details?.location}`}
                                    videoEnd={(val) => {
                                        console.log('setVideoEnd(val) ',val)
                                        setVideoEnd(val)}}
                                /> :
                                currentData?.contentUnitData?.content_type == 'PDF' ?
                                    <CoursePDFViewer
                                        pdfUrl={currentData?.contentUnitData?.content_details?.location} />
                                    :
                                    currentData?.contentUnitData?.content_type == 'PPT' || currentData?.contentUnitData?.content_type == 'SLIDESHARE' ?
                                        <CoursePowerPointViewer
                                            pptUrl={currentData?.contentUnitData?.content_details?.location}
                                            isExternal={currentData?.contentUnitData?.content_type == 'PPT' ? false : true} />
                                        : currentData?.contentUnitData?.content_type == 'URL'
                                            ? <CourseURLCard
                                                text={currentData?.contentUnitData?.content_details?.location}
                                                link={currentData?.contentUnitData?.content_details?.location}
                                                title={currentData?.contentUnitData?.content_title}
                                                number={currentIndex +1}
                                            />
                                            :
                                            currentData?.contentUnitData?.content_type == 'RICH_TEXT'
                                                ? <CoursesRichtextCard
                                                    text={currentData?.contentUnitData?.content_details?.data}
                                                    title={currentData?.contentUnitData?.content_title}
                                                    number={currentIndex +1}
                                                /> :
                                                currentData?.contentUnitData?.content_type == 'QUIZ' || currentData?.contentUnitData?.content_type == "Practice Exam" || currentData?.contentUnitData?.content_type == "PRATICE EXAM"
                                                    ?
                                                    <CourseQuizMain
                                                        assessment_version_id={currentData?.contentUnitData?.content_details?.assessment_version_id}
                                                        title={currentData?.contentUnitData?.content_title}
                                                        number={currentIndex +1}
                                                        nextClick={() => {
                                                            nextClick(currentIndex + 1)
                                                        }}
                                                    /> :
                                                    <h1>

                                                    </h1>
                            }
                            <div className="mask text-light mt-4 " >
                                <Row>
                                    <Col className="align-start">
                                        {currentIndex !== 0 ? <SubmitButton
                                            text={'Previous'}
                                            onClick={() => setCurrentIndex(currentIndex - 1)}
                                        /> : ''}
                                    </Col>
                                    <Col className="text-end">{console.log(data?.courseContentUnitData, currentIndex, data?.courseContentUnitData?.length, 'data?.courseContentUnitData?.Items?.length()')}
                                        {currentIndex + 1 >= data?.courseContentUnitData?.length ? '' : <SubmitButton
                                            text={'Next'}
                                            onClick={()=>nextClick(currentIndex + 1)}
                                        />}</Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col md="3">
                        <CourseProgress

                            total={data?.courseContentUnitData?.Count}
                            completeted={data?.courseContentUnitData?.ScannedCount}
                        />
                        <CardTitleComponent text={data?.course?.title} className={'mt-2 text-start'} />
                        <div className="mt-4">

                            {data?.courseContentUnitData?.map((item, index) => {
                                let image = 'play-icon.png';
                                const type = item?.contentUnitData?.content_type;
                                if (type === 'RICH_TEXT') {
                                    image = 'text-format.png';
                                } else if (type === 'PPT' || type == 'SLIDESHARE') {
                                    image = 'presentation.png';
                                }
                                else if (type === 'URL') {
                                    image = 'url.png';
                                }
                                else if (type === 'VIDEO') {
                                    image = 'video.png';
                                }
                                else if (type === 'PDF') {
                                    image = 'pdf-file.png';
                                } else if (type === 'YOUTUBE') {
                                    image = 'youtube-logo.png';
                                }
                                return <CourseCard
                                    title={item?.contentUnitData?.content_title}
                                    number={item?.content_id}
                                    type={item?.contentUnitData?.content_type}
                                    index={index}
                                    currentIndex={currentIndex}
                                    onClick={(ind) => nextClick(ind)}
                                    image={image}
                                />
                            })}</div>
                    </Col>
                </Row>
            </div>
        </Container>
    </>);
}