//** Cognito Function of Auth ContextProvider **//

import React, { useState, useEffect, useContext } from "react";
import { AppUrlsEnum, AuthStatus } from "../enums";
import * as AWS from "aws-sdk";
import * as cognito from "../services/cognito.service";
import {
  S3_BUCKET,
  region,
  identityPoolId,
  userPoolId,
} from "../services/config";
import jwt_decode from "jwt-decode";
import { resetUser } from "../redux-tk/slices/user.slice";
import { useLocation, matchPath } from "react-router-dom";
import { awsCredsHolder } from "../helpers/aws.creds.holder";

const defaultState = {
  sessionInfo: {
    sub: null,
    email: null,
    firstName: null,
    lastName: null,
    accessToken: null,
    refreshToken: null,
    idToken: null,
    role: null,
    phoneNumber: null,
  },
  authStatus: AuthStatus.Loading,
};

export const AuthContext = React.createContext(defaultState);

export const AuthIsSignedIn = ({ children }) => {
  const { authStatus } = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedInTokensGot ? children : null}</>;
};

export const AuthIsNotSignedIn = ({ children }) => {
  const { authStatus } = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};

export const AuthAllRoutes = ({ children }) => {
  return <>{children}</>;
};

const AuthProvider = ({ children }) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
  const [sessionInfo, setSessionInfo] = useState({});
  const [attrInfo, setAttrInfo] = useState([]);
  const [attrInfoDetail, setAttrInfoDetails] = useState({});
  const { pathname } = useLocation();

  useEffect(() => {
    if (authStatus === AuthStatus.SignedIn) {
      getSessionInfo();
    }
  }, [authStatus]);

  useEffect(() => {
    const meetingLink = matchPath(
      {
        path: AppUrlsEnum.mentorRequestDetails,
        exact: true,
        strict: false,
      },
      pathname
    );

    if (meetingLink?.pathname) {
      localStorage.setItem("meetingLink", meetingLink.pathname);
    }
  }, []);

  useEffect(() => {
    const meetingLink = matchPath(
      {
        path: AppUrlsEnum.menteeRequestDetails,
        exact: true,
        strict: false,
      },
      pathname
    );

    if (meetingLink?.pathname) {
      localStorage.setItem("meetingLink", meetingLink.pathname);
    }
  }, []);

  useEffect(() => {
    if (authStatus === AuthStatus.Loading) {
      const token = localStorage.getItem("idToken");
      if (token) {
        setAuthStatus(AuthStatus.SignedIn);
      } else {
        setAuthStatus(AuthStatus.SignedOut);
      }
    }
  }, [authStatus]);

  async function getSessionInfo() {
    try {
      let session = null;
      let attr = null;
      try {
        session = await getSession();
        attr = await getAttributes();
        setAttrInfo(attr);
      } catch (sessError) {
        console.log("sessError", sessError);
        if (!session) {
          setAuthStatus(AuthStatus.SignedOut);
          return;
        } else {
          throw sessError;
        }
      }
      // console.log("sessions userDetailsUpdate", session);
      const token = jwt_decode(session.accessToken.jwtToken);
      const idTokenRes = jwt_decode(session.idToken.jwtToken);
      setSessionInfo({
        accessToken: session.accessToken.jwtToken,
        refreshToken: session.refreshToken.token,
        idToken: session.idToken.jwtToken,
        sub: token.sub,
        username: token.username,
        role: idTokenRes.role,
        email: idTokenRes.email,
        firstName: idTokenRes.given_name,
        lastName: idTokenRes.family_name,
        phoneNumber: idTokenRes.phone_number,
      });
      var object = attr.reduce(
        (obj, item) => Object.assign(obj, { [item.Name]: item.Value }),
        {}
      );
      setAttrInfoDetails({
        accessToken: session.accessToken.jwtToken,
        refreshToken: session.refreshToken.token,
        idToken: session.idToken.jwtToken,
        sub: object.sub,
        username: object.username,
        role: object.role,
        email: object.email,
        firstName: object.given_name,
        lastName: object.family_name,
        phoneNumber: object.phone_number,
      });
      localStorage.setItem("accessToken", `${session.accessToken.jwtToken}`);
      localStorage.setItem("refreshToken", `${session.refreshToken.token}`);
      localStorage.setItem("idToken", `${session.idToken.jwtToken}`);
      localStorage.setItem("test", `${session.idToken.jwtToken}`);
      localStorage.setItem("subId", token.sub);

      AWS.config.update({
        region: region,
        credentials: new AWS.CognitoIdentityCredentials({
          region: region,
          IdentityPoolId: identityPoolId,
          Logins: {
            [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]:
              session.idToken.jwtToken,
          },
        }),
      });
      setAuthStatus(AuthStatus.SignedInTokensGot);
    } catch (err) {
      console.log("err", err);
      setAuthStatus(AuthStatus.SignedOut);
      console.log("setvar");
      //console.log("updated",authStatus,AuthStatus.SignedOut);
    }
  }

  if (authStatus === AuthStatus.Loading) {
    return null;
  }
  //console.log("aut hstatus after",authStatus);
  async function signInWithEmail(username, password) {
    try {
      console.log("caling", username, password);
      await cognito.signInWithEmail(username.toLowerCase(), password);
      setAuthStatus(AuthStatus.SignedIn);
      console.log("userId", sessionInfo.sub);
    } catch (err) {
      console.log("cognito error", err);
      setAuthStatus(AuthStatus.SignedOut);
      throw err;
    }
  }

  async function signinWithOTP(username) {
    try {
      console.log("otpuser", username);
      const data = await cognito.signinWithOTP(username.toLowerCase());
      return data;
    } catch (err) {
      console.log("usersigninerrror", err);
      throw err;
    }
  }

  async function confrimSigninOTP(username, loginOtp, session) {
    try {
      console.log("otpuser", username, loginOtp);
      const data = await cognito.confrimSigninOTP(username.toLowerCase(), loginOtp, session);
      setAuthStatus(AuthStatus.SignedIn);
    } catch (err) {
      console.log("userOTPerror", err);
      setAuthStatus(AuthStatus.SignedOut);
      throw err;
    }
  }

  async function getSignedImageFromKey(key) {
    if (!key) {
      console.log("no key", key);
      return null;
    }
    console.log("key is getting imag", key);
    AWS.config.update({
      region: region,
      credentials: new AWS.CognitoIdentityCredentials({
        region: region,
        IdentityPoolId: identityPoolId,
        Logins: {
          [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]:
            sessionInfo.idToken,
        },
      }),
    });
    await AWS.config.credentials.refreshPromise();
    const s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: {
        Bucket: S3_BUCKET,
        Key: key,
      },
    });
    console.log("fetching base64 iamge for key", key);
    const obj = await s3.getObject().promise();
    const b64Encoded = "data:image/jpeg;base64," + encodeS3ToB64(obj.Body);
    return b64Encoded;
  }

  function encodeS3ToB64(data) {
    const str = data.reduce(function (a, b) {
      return a + String.fromCharCode(b);
    }, "");
    //const buf = Buffer.from(str,'base64');
    //return buf.toString('base64');
    return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
  }

  async function uploadFileToS3({ file, folder, bucketName }) {
    AWS.config.update({
      region: region,
      credentials: new AWS.CognitoIdentityCredentials({
        region: region,
        IdentityPoolId: identityPoolId,
        Logins: {
          [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]:
            sessionInfo.idToken,
        },
      }),
    });
    await AWS.config.credentials.refreshPromise();
    const s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: {
        Bucket: bucketName,
      },
    });
    console.log("file", file.name, file.type);
    const cleanFileName = file.name
      .split(" ")
      .join("")
      .replace(/[^a-zA-Z0-9-.]/g, "");
    const retKey = `${sessionInfo.sub}/${folder}/${cleanFileName}`;
    console.log("clearn", cleanFileName, retKey);
    const res = await s3
      .putObject({
        Key: retKey,
        Bucket: bucketName,
        Body: file,
      })
      .promise();
    console.log("res'", res);
    if (res["$response"].httpResponse.statusCode === 200) {
      return retKey;
    }
    return retKey;
  }

  async function signUpWithEmail(
    username,
    email,
    password,
    mobile,
    firstName,
    lastName,
    profileType
  ) {
    try {
      const data = await cognito.signUpUserWithEmail(
        username.toLowerCase(),
        email,
        password,
        mobile,
        firstName,
        lastName,
        profileType
      );
      console.log(data, "data");
    } catch (err) {
      throw err;
    }
  }

  function signOut(dispatch, navigate) {
    localStorage.clear();
    dispatch(resetUser({}));
    cognito.signOut();
    console.log("setting state", defaultState.sessionInfo);
    setSessionInfo(defaultState.sessionInfo);
    setAuthStatus(AuthStatus.SignedOut);
    awsCredsHolder.clearTokens();
    navigate(AppUrlsEnum.authSignin);
  }

  async function verifyCode(username, code) {
    try {
      const data = await cognito.verifyCode(username.toLowerCase(), code);
      console.log(data, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }
  async function verifyEmailCode(code, name) {
    try {
      const data = await cognito.verifyEmailCode(code, name);
      await getSessionInfo();

      console.log(data, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }
  async function getSession() {
    try {
      const session = await cognito.getSession();
      return session;
    } catch (err) {
      throw err;
    }
  }

  async function refreshSession() {
    try {
      const session = await cognito.refreshSession(
        localStorage.getItem("refreshToken")
      );
      return session;
    } catch (err) {
      throw err;
    }
  }

  async function getAttributes() {
    try {
      const attr = await cognito.getAttributes();
      return attr;
    } catch (err) {
      throw err;
    }
  }

  async function setAttribute(attr) {
    try {
      const res = await cognito.setAttribute(attr);
      return res;
    } catch (err) {
      throw err;
    }
  }

  async function resendConfirmationCode(username) {
    try {
      console.log("resend confirmation");
      await cognito.resendConfirmationCode(username.toLowerCase());
    } catch (err) {
      throw err;
    }
  }

  async function signinWithGoogle(username, data) {
    try {
      console.log("sigin with google confirmation");
      await cognito.signinWithGoogle(username, data);
      setAuthStatus(AuthStatus.SignedIn);
      //await getSessionInfo();
      //await dispatch(getUserAsyncThunk({ userId: sessionInfo.sub }));
    } catch (err) {
      throw err;
    }
  }

  async function sendCode(username) {
    try {
      console.log("sendcode");
      await cognito.sendCode(username.toLowerCase());
    } catch (err) {
      throw err;
    }
  }

  async function forgotPassword(username, code, password) {
    try {
      await cognito.forgotPassword(username.toLowerCase(), code, password);
    } catch (err) {
      throw err;
    }
  }

  async function resetPassword(oldPassword, newPassword) {
    try {
      await cognito.getCurrentUser.resetPassword(oldPassword, newPassword);
      // setAuthStatus(AuthStatus.ResetPassword);
    } catch (err) {
      throw err;
    }
  }

  async function changeEmail(newEmail, currentPassword) {
    try {
      await cognito.changeEmail(newEmail, currentPassword);
    } catch (err) {
      throw err;
    }
  }

  async function changeName(firstName, lastName) {
    try {
      cognito.changeName(firstName, lastName).then(() => {
        getSessionInfo();
      });
    } catch (err) {
      throw err;
    }
  }
  async function changeEmailPhone(name, value) {
    try {
      await cognito.changeEmailPhone(name, value.toLowerCase()).then(() => {
        getSessionInfo();
      });
    } catch (err) {
      throw err;
    }
  }

  async function resendEmailConfirmCode(email) {
    try {
      console.log("resend email confirmation");
      await cognito.resendEmailConfirmCode(email.toLowerCase());
    } catch (err) {
      throw err;
    }
  }

  const state = {
    authStatus,
    sessionInfo,
    attrInfo,
    uploadFileToS3,
    getSignedImageFromKey,
    encodeS3ToB64,
    signUpWithEmail,
    signInWithEmail,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    forgotPassword,
    resetPassword,
    getAttributes,
    setAttribute,
    changeEmail,
    signinWithOTP,
    confrimSigninOTP,
    resendConfirmationCode,
    signinWithGoogle,
    changeName,
    verifyEmailCode,
    changeEmailPhone,
    resendEmailConfirmCode,
    attrInfoDetail,
  };
  //console.log("data", "done");
  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
