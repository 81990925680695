//** Dashboard Request Page **//

import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useMenteeRequestDefinitions } from "../../AgGridDefinitions/mentee.requests.grid.definitions";
import { searchRequests } from "../../services/axios/opensearch";
import { useContext } from "react";
import { AuthContext } from "../../hooks/auth.context.provider";
import { getStatusSelect } from "../../helpers/grid.helpers";
import { CustomSelect } from "../../components/CustomInput/custom.select";
import {
  AgGridFieldsEnum,
  AgGridNoRowsParams,
  AppUrlsEnum,
  RequestStatus,
} from "../../enums";
import { Col, Image, Row } from "react-bootstrap";
import { AgGridMain } from "../../components/agGridRenderers/ag.grid.main";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { SubmitButton } from "../../components/submit.button";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { FormLabelComponent } from "../../components/form.label.component";
import { _getImage } from "../../common/utils/commonFunctions";
import { useNavigate } from "react-router";
import { Heading } from "../../components/heading.component";
import { useDispatch, useSelector } from "react-redux";
import { GetDataType } from "../../services/axios/staticData";
import { loader } from "../../redux-tk/slices/loader.slice";
import { languageValue } from "../../redux-tk/slices/general.info.slice";
import { requestsCombiner } from "../../helpers/combiners/grid.combiners";

export function MenteeRequests() {
  const { formElementLabels, staticSelectTranslations, headingTranslations } =
    useIgurooTranslations();
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  const [selectCategorySearch, setSelectCategorySearch] = useState();
  const [selectStatusSearch, setSelectStatusSearch] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const requestStatusItems = useMemo(
    () => getStatusSelect(staticSelectTranslations),
    [staticSelectTranslations]
  );
  const dispatch = useDispatch();
  const [categorySearch, setCategorySearch] = useState();
  const selectedLanguage = useSelector((state) => languageValue(state));

  let isLoad = false;
  useEffect(() => {
    if (!isLoad) {
      (async function () {
        dispatch(loader(true));
        setIsLoading(true);
        const aoi = await GetDataType("requestCategory", selectedLanguage);
        aoi.unshift({
          dataId: RequestStatus.all,
          value: staticSelectTranslations.all,
        });
        setCategorySearch(aoi);
        dispatch(loader(false));
        setIsLoading(false);
      })();
    }
    return () => {
      isLoad = true;
      setCategorySearch([]);
    };
  }, []);

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const { headers, fieldsToHide } = useMenteeRequestDefinitions();

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    resizable: true,
    filter: true,
  }));

  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event);
  }, []);

  const getDataG = async (
    userId,
    category,
    searchText,
    status,
    startRow,
    endRow
  ) => {
    const d = await searchRequests({
      userId: userId,
      category: category,
      searchText: searchText,
      status: status,
      startRow: startRow,
      endRow: endRow,
      isMentor: false,
    });
    return d;
  };
  const onRowClicked = (params) => {
    // console.log(params.data, "params.data");
    const reqNumber = params.data.requestNumber;
    localStorage.setItem(reqNumber, params.data.title);
    //const param = params.data.title+"?reqId="+reqNumber;
    const url = AppUrlsEnum.menteeRequestDetails.replace(":id", reqNumber);
    navigate(`${url}`);
  };
  const onGridReadyNew = useCallback(
    (params) => {
      const paginationProxy = params.api.paginationProxy;
      const dataSource = {
        rowCount: null,
        getRows: async function (rparams) {
          try {
            const filterModel = rparams.filterModel;
            console.log("rows params", rparams);
            const categoryFilter = filterModel?.category?.filter;
            const statusFilter = filterModel?.status?.filter;
            const titleFilter =
              filterModel?.[AgGridFieldsEnum.requestNumber]?.filter;
            const endRow = rparams.endRow;
            const startRow = rparams.startRow;
            const categorySearchData = categorySearch?.filter(
              (cat) => cat.value == categoryFilter
            );
            // console.log(
            //   "filter',",
            //   categoryFilter,
            //   statusFilter,
            //   titleFilter,
            //   filterModel,
            //   selectCategorySearch,
            //   categorySearch,
            //   categorySearchData,
            //   "aaa"
            // );
            // console.log("calln,data");
            const d = await getDataG(
              authContext.sessionInfo.sub,
              categoryFilter === RequestStatus.all ? undefined : categoryFilter,
              titleFilter,
              statusFilter === RequestStatus.all ? undefined : statusFilter,
              startRow,
              endRow - startRow
            );
            // console.log("data got", d);
            const mergedRequestsAndSessions = await requestsCombiner(
              d,
              "mentorId"
            );
            console.log("data from requ", mergedRequestsAndSessions);
            if (d.total.value === 0) {
              gridRef.current.api.showNoRowsOverlay();
            } else {
              gridRef.current.api.hideOverlay();
            }
            rparams.successCallback(mergedRequestsAndSessions, d.total.value);
          } catch (err) {
            console.log("err", err);
            gridRef.current.api.showNoRowsOverlay();
            rparams.successCallback([], 0);
          }
        },
      };
      params.api.setDatasource(dataSource);
    },
    [gridRef, categorySearch, selectCategorySearch]
  );

  const applyCategoryModel = (e) => {
    //console.log("val", selectStatusSearch, selectCategorySearch, searchSearch);
    gridRef.current.api.setFilterModel({
      [AgGridFieldsEnum.status]: {
        filter: selectStatusSearch?.dataId,
        filterType: "text",
        type: "equals",
      },
      [AgGridFieldsEnum.category]: {
        filter: selectCategorySearch?.dataId,
        filterType: "text",
        type: "equals",
      },
      [AgGridFieldsEnum.requestNumber]: {
        filter: searchSearch,
        filterType: "text",
        type: "contains",
      },
    });
  };
  const [searchSearch, setSearchSearch] = useState();

  const changePage = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="ms-3 me-3">
      <Heading className="headingDashboard">
        {headingTranslations?.myRequests}
      </Heading>
      {/* Example using Grid's API */}
      {/* <button onClick={buttonListener}>Push Me</button> */}
      <Row className="d-flex align-items-end mb-3">
        <Col md={2} className="pe-0 mt-3">
          <Row className="d-flex align-items-center">
            <Col sm={2}>
              <FormLabelComponent></FormLabelComponent>

              <Image src={_getImage("filter.svg")} />
            </Col>
            <Col sm={10}>
              <FormLabelComponent>
                {formElementLabels.status}
              </FormLabelComponent>
              <CustomSelect
                opLabel="value"
                opValue="dataId"
                items={requestStatusItems}
                sendFullValueInHook={true}
                onChangeCallback={setSelectStatusSearch}
                value={selectStatusSearch}
                normalSelect={true}
              />
            </Col>
          </Row>
        </Col>
        <Col md={3} className="pe-0 mt-3">
          <FormLabelComponent>{formElementLabels.category}</FormLabelComponent>
          <CustomSelect
            opLabel="value"
            opValue="dataId"
            items={categorySearch}
            onReactHookFormChange={(e) => {
              // console.log(e, "setSelectCategorySearch");
              setSelectCategorySearch(e);
            }}
            value={selectCategorySearch}
            //  onChangeCallback={setSortBy}
            isLoading={isLoading}
            sendFullValueInHook={true}
            normalSelect={true}
          />
        </Col>
        <Col md={5} lg={6}>
          <FormLabelComponent></FormLabelComponent>
          <CustomInputField
            placeholder={formElementLabels?.placeHolders?.search}
            onChange={setSearchSearch}
            hookValue={searchSearch}
          />
        </Col>
        <Col sm={12} md={2} lg={1} className="mt-3 d-grid">
          <SubmitButton onClick={applyCategoryModel} text="Search" />
          {/* <input type={"button"} onClick={applyCategoryModel} /> */}
        </Col>
      </Row>
      <div className="mb-3">
        <AgGridMain
          gridRef={gridRef}
          columnDefs={headers} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          onCellClicked={cellClickedListener} // Optional - registering for Grid Event
          onGridReady={onGridReadyNew}
          colsToHide={fieldsToHide}
          //getLocaleText={getLocaleText}
          className={"ig-aggrid-css"}
          onRowClicked={onRowClicked}
          rowHeight={100}
          noRowsParams={{ [AgGridNoRowsParams.isMenteeRequests]: true }}
          onPaginationChanged={changePage}
        />
      </div>
    </div>
  );
}
