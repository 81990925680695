//** Iguroo Mentor & Mentee Sessions Slice **//

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetUserProfile } from "../../services/axios/MentorOnbaording";
import {
  getRequestdetails,
  getSessionBetweenDatesList,
  getSlotsBetweenDatesList,
} from "../../services/axios/requests";

export const getSessionBetweenDatesAsyncThunk = createAsyncThunk(
  "session/getSessionBetweenDatesAsnyThunk",
  async (data, thunkApi) => {
    try {
      const sessions = await getSessionBetweenDatesList(
        data.isByUser,
        data.userId,
        data.startDate,
        data.endDate
      );
      console.log("api sessions", sessions);
      return { sessions, year: data.year, month: data.month };
    } catch (err) {
      console.log("api error", err);
      thunkApi.rejectWithValue(err);
    }
  }
);

export const getSLotsBetweenDatesAsyncThunk = createAsyncThunk(
  "slots/getSlotsBetweenDatesAsnyThunk",
  async (data, thunkApi) => {
    try {
      console.log("api slot", data);
      const sessions = await getSlotsBetweenDatesList(
        data.userId,
        data.startDate,
        data.endDate
      );
      console.log("api slot", sessions);
      return { sessions, year: data.year, month: data.month };
    } catch (err) {
      console.log("api error", err);
      thunkApi.rejectWithValue(err);
    }
  }
);
export const getRequestSessoinAsyncThunk = createAsyncThunk(
  "session/getRequestSessoinAsyncThunk",
  async (data, thunkApi) => {
    try {
      const request = await getRequestdetails(data.requestNumber);
      return {
        request: request.Items[0],
        year: data.year,
        month: data.month,
        sessionNumber: data.sessionNumber,
      };
    } catch (err) {
      console.log("api error", err);
      thunkApi.rejectWithValue(err);
    }
  }
);

export const getUserProfileSessionAsyncThunk = createAsyncThunk(
  "session/getUserProfileSessionAsyncThunk",
  async (data, thunkApi) => {
    try {
      const user = await GetUserProfile(data.userId);
      console.log("user", user);
      return {
        year: data.year,
        user: user.data,
        month: data.month,
        sessionNumber: data.sessionNumber,
        isUser: data.isUser,
      };
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);

const initialState = {
  sessionsBwDates: {},
};

export const sessionsSlice = createSlice({
  name: "sessions",
  initialState,
  reducers: {
    addDataSessionBetweenDatesReducer: (state, action) => {
      let year = action.payload.year;
      let month = action.payload.month;
      let sessionNumber = action.payload.sessionNumber;
      let user = action.payload.user;
      let mentor = action.payload.mentor;
      let request = action.payload.request;
      let sessions = state.sessionsBwDates[year][month];
      for (let i = 0; i < sessions.length; i++) {
        let session = sessions[i];
        if (session.sessionNumber === sessionNumber) {
          if (user) {
            session.user = action.payload.user;
          }
          if (mentor) {
            session.mentor = action.payload.mentor;
          }
          if (request) {
            session.request = action.payload.request;
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSessionBetweenDatesAsyncThunk.fulfilled, (state, action) => {
        console.log("sessions action'", action);
        if (!action?.payload) return;
        let year = action.payload.year;
        let month = action.payload.month;
        let sessions = action.payload.sessions;
        //console.log("getSessionBetweenDatesAsyncThunk",year,month,sessions);
        if (!state.sessionsBwDates[year]) {
          state.sessionsBwDates[year] = {};
        }
        if (!state.sessionsBwDates[year][month]) {
          state.sessionsBwDates[year][month] = [];
        }
        const sess = [...sessions];
        sess.sort((a, b) => {
          if (a.startTime > b.startTime) {
            return 1;
          }
          if (b.startTime > a.startTime) {
            return -1;
          }
          return 0;
        });
        state.sessionsBwDates[year][month] = [...sess];
        //console.log("getSessionBetweenDatesAsyncThunk",state.sessions);
      })
      .addCase(getSessionBetweenDatesAsyncThunk.pending, (state) => {})
      .addCase(getSessionBetweenDatesAsyncThunk.rejected, (state) => {})
      .addCase(getUserProfileSessionAsyncThunk.fulfilled, (state, action) => {
        //console.log("sessions action'", action);
        if (!action?.payload) return;
        let year = action.payload.year;
        let month = action.payload.month;
        let sessionNumber = action.payload.sessionNumber;
        let user = action.payload.user;
        let isUser = action.payload.isUser;

        let sessions = state.sessionsBwDates[year][month];
        console.log(
          "getSessionBetweenDatesAsyncThunk",
          isUser,
          year,
          month,
          user,
          sessions
        );

        for (let i = 0; i < sessions.length; i++) {
          let session = sessions[i];
          if (session.sessionNumber === sessionNumber) {
            if (isUser) {
              session.user = user;
            } else {
              session.mentor = user;
            }
            break;
            //console.log("getSessionBetweenDatesAsyncThunk","found sessions",...session);
          }
        }
        state.sessionsBwDates[year][month] = sessions;
      })
      .addCase(getUserProfileSessionAsyncThunk.pending, (state) => {})
      .addCase(getUserProfileSessionAsyncThunk.rejected, (state) => {})
      .addCase(getRequestSessoinAsyncThunk.fulfilled, (state, action) => {
        //console.log("sessions action'", action);
        if (!action?.payload) return;
        let year = action.payload.year;
        let month = action.payload.month;
        let sessionNumber = action.payload.sessionNumber;
        let request = action.payload.request;

        let sessions = state.sessionsBwDates[year][month];
        console.log("getSessionBetweenDatesAsyncThunk", year, month, sessions);

        for (let i = 0; i < sessions.length; i++) {
          let session = sessions[i];
          if (session.sessionNumber === sessionNumber) {
            session.request = request;
            break;
          }
        }
        state.sessionsBwDates[year][month] = sessions;
      })
      .addCase(getRequestSessoinAsyncThunk.pending, (state) => {})
      .addCase(getRequestSessoinAsyncThunk.rejected, (state) => {})
      .addCase(getSLotsBetweenDatesAsyncThunk.fulfilled, (state, action) => {
        console.log("sessions action'", action, state);
        if (!action?.payload) return;
        let year = action.payload.year;
        let month = action.payload.month;
        let sessions = action.payload.sessions;
        console.log("getSessionBetweenDatesAsyncThunk", year, month, sessions);
        if (!state.sessionsBwDates[year]) {
          state.sessionsBwDates[year] = {};
        }
        if (!state.sessionsBwDates[year][month]) {
          state.sessionsBwDates[year][month] = [];
        }
        state.sessionsBwDates[year][month] = [...sessions];
        //console.log("getSessionBetweenDatesAsyncThunk",state.sessions);
      })
      .addCase(getSLotsBetweenDatesAsyncThunk.pending, (state) => {})
      .addCase(getSLotsBetweenDatesAsyncThunk.rejected, (state) => {});
  },
});

export const { addDataSessionBetweenDatesReducer } = sessionsSlice.actions;

export const getAllSessionsInDates = (state) =>
  state?.sessions?.sessionsBwDates;

export default sessionsSlice.reducer;
