//** Iguroo Mentor,Rate & DurationComponent used in Mentor Card**//

import React from "react";
import { _getImage } from "../../common/utils/commonFunctions";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";

export function RateDurationComponent(props) {
  const { formElementLabels } = useIgurooTranslations();
  const { rateText, className, classNameRate, classNameDuration, duration } =
    props;
  return (
    <div className={`ig-card-rate-duration ${className}`}>
      <span className={`ig-card-rate ${classNameRate}`}>
        {" "}
        {props.children?.length > 0 ? props.children : rateText}
      </span>
      {rateText == formElementLabels?.placeHolders?.free ? "" : "/"}
      <br />
      <span className={`ig-card-duration ${classNameDuration}`}>
        {" "}
        {duration ? `${duration}` : ""}
      </span>
    </div>
  );
}
