//**Iguroo Admin Assessment Report Popup can Search & View using the UserId UI code**//

import React, { useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./admin.scss";
import { Form, Row, Col, Card } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { SubmitButton } from "../../components/submit.button";
import { AssessmentPopup } from "../../components/assessment.popup";
import { GetExamAttemptCompleted } from "../../services/axios/Exam";
import moment from "moment";
import { FormErrorComponent } from "../../components/form.error.component";
import { GetUser } from "../../services/axios/MentorOnbaording";

const AssessmentReportAdmin = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({});
  const [attemptId, setAttemptId] = useState(null);
  const [menteeShowError, setmenteeShowError] = useState(false);
  const [userType, setUserType] = useState(null);
  const [menteeReportId, setMenteeReportId] = useState("");
  const [show, setShow] = useState(false);

  const handleShowPopupMentee = async (data) => {
    const { menteeName } = data;
    if (menteeName) {
      try {
        let attemptID = null;
        setMenteeReportId(menteeName);
        GetUser(menteeName)
          .then((responce) => {
            if (responce.data) {
              setUserType(responce.data.profile.type);
            }
          })
          .catch((err) => console.log(err, " err"));
        GetExamAttemptCompleted(menteeName)
          .then((res) => {
            const examDet = res?.data?.response?.Items;
            const result = examDet?.sort(
              (date1, date2) =>
                moment(date2.updatedAt) - moment(date1.updatedAt)
            );
            attemptID = result[0]?.attemptId;
            setAttemptId(attemptID);
            if (attemptID != undefined) {
              setmenteeShowError(false);
              setShow({ userId: menteeName, attemptID });
            } else {
              setmenteeShowError(true);
            }
          })
          .catch((err) => console.log(err));
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <>
      <h5 className="ms-4 mt-2">Assessment Report</h5>
      <div
        className="d-flex flex-column mt-4"
        style={{ fontFamily: "manrope" }}
      >
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Card.Title>
                  Mentor/Mentee Id
                  <Form onSubmit={handleSubmit(handleShowPopupMentee)}>
                    <Row>
                      <Col md={8}>
                        <Controller
                          name={`menteeName`}
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <CustomInputField
                              placeholder={"Please enter Mentor/Mentee Id"}
                              hookOnChange={onChange}
                              hookOnBlur={onBlur}
                              hookValue={value}
                              rows={6}
                            />
                          )}
                        />
                      </Col>
                      <Col md={4} className="pt-4">
                        <SubmitButton type="submit" text="Generate Report" />
                      </Col>
                    </Row>
                  </Form>
                  {attemptId && show && (
                    <AssessmentPopup
                      show={show}
                      onHide={() => setShow(false)}
                      userId={menteeReportId}
                      attemptId={attemptId}
                      userType={userType}
                    />
                  )}
                </Card.Title>
                <FormErrorComponent>
                  {menteeShowError && (
                    <>
                      Report will be availabe once user finished the assessment
                    </>
                  )}
                </FormErrorComponent>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
export default AssessmentReportAdmin;