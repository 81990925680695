import {  axiosStaticData } from "../axios.service";
import { REQUEST_DETAILS } from "../../urlConst";

export const getRequestdetails = async (requestNumber) => {
  try {
    const params = {
      requestNumber: requestNumber,
    };
    const res = await axiosStaticData.get(REQUEST_DETAILS, {
      params,
    });
    return res.data.response;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};
