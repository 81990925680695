import React from "react";
import AoeScreen from "../../mentor/aoe.screen";

const AreaOfEmphasis = () => {
  return (
    <>
      <AoeScreen settings={true} />
    </>
  );
};

export default AreaOfEmphasis;
