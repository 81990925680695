//** Iguroo Assessment Page1 Component**//

import React from "react";
import { Image } from "react-bootstrap";
import { _getImage } from "../../../common/utils/commonFunctions";
import { useIgurooTranslations } from "../../../hooks/use.iguroo.translation";
import moment from "moment";

const Page_1 = ({ name, examDate }) => {
  const { reportAssessmentTranslations } = useIgurooTranslations();
  return (
    <div className="image_container">
      <div className="a_page page_1">
        <Image
          className="ellipse_1"
          src={_getImage("Ellipse.svg")}
          height={300}
          width={300}
        />
        <Image
          className="ellipse_2"
          src={_getImage("Ellipse.svg")}
          height={100}
          width={100}
        />
        <div className="content_container justify-content-center align-items-center">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div>
              <Image
                src={_getImage(process.env.REACT_APP_SITE_LOGO)}
                height={100}
                width={200}
              />
            </div>
            <div className="mt-3">
              <h1 className="text-white fw-600">
                {reportAssessmentTranslations.assessmentReport}
              </h1>
            </div>
            <div className="mt-3">
              <p className="text-white fw-400">
                {reportAssessmentTranslations.reportDesck}
              </p>
            </div>
            <div className="mt-3">
              <p className="blue_text fw-600">
                {reportAssessmentTranslations.nameDesc} :{" "}
                <span className="text-white">
                  {name?.firstName} {name?.lastName}
                </span>
              </p>
              <p className="blue_text fw-600">
                {reportAssessmentTranslations.dateDesc} :{" "}
                <span className="text-white">
                  {examDate ? moment(examDate).format("DD/MM/YYYY") : ""}
                </span>
              </p>
            </div>
          </div>
        </div>
        <Image
          className="ellipse_3"
          src={_getImage("Ellipse.svg")}
          height={100}
          width={100}
        />
        <Image
          className="pyramid"
          src={_getImage("pyramid_2.png")}
          height={50}
          width={900}
        />
      </div>
    </div>
  );
};

export default Page_1;