//** GinieHealth Learner UI Screen  **//

import React, {
    useEffect,
    useState,
    useMemo,
    useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { useRef } from "react";
import { _getImage } from "../../common/utils/commonFunctions";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useLocation, useNavigate, } from "react-router-dom";
import { AuthContext } from "../../hooks/auth.context.provider";
import { loader } from "../../redux-tk/slices/loader.slice";
import { GetAssessmentTranscriptByUserIdApi } from "@iguroo/ig-react-assessment-package";
import {
    getMyAssessmentThunk,
} from "../../redux-tk/slices/assessment.slice";
import { Pdf } from "./CellRenderers";
import { pdfjs } from "react-pdf";
import moment from "moment";
import { Heading } from "../../components/heading.component";
import { Button, Col, Row } from "react-bootstrap";
import { AppUrlsEnum, ProfileType } from "../../enums";
import { BackButton } from "../../components/back.button";

const TranscriptList = (props) => {
    const dispatch = useDispatch();
    const [showTranscriptAttempt, setShowTranscriptAttempt] = useState(null);

    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [isGridLoading, setIsGridLoading] = useState(false);
    const authContext = useContext(AuthContext);
    const state = location.state;
    const selectedUserID = state?.selectedUserID ? state.selectedUserID : null;
    const selectedUserName = state?.selectedUserName ? state.selectedUserName : null;
    const userInfo = useMemo(() => {
        return authContext?.sessionInfo;
    }, [authContext.authStatus]);

    useEffect(() => {

    }, []);



    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        (async function () {
            console.log("transcriptURL");
            try {
                const d = await GetAssessmentTranscriptByUserIdApi({
                    user_id: selectedUserID,
                    limit: 200,
                });
                if (d.success === true) {
                    const users = d.response.data.map((user, index) => {
                        const completedDate = user.completedDate
                            ? moment(user.completedDate).format(
                                process.env.REACT_APP_DATETIME_FORMAT
                            )
                            : "";
                        if (
                            user?.transcript_url === null ||
                            user?.transcript_url === "" ||
                            user?.transcript_url === undefined
                        ) {
                            setTimeout(async () => {
                                setIsGridLoading(true);
                            }, 3000);
                            console.log(user?.transcript_url, "transcriptURL");
                        }
                        console.log(user, "transcriptURL");
                        return {
                            SI: index + 1,
                            Title: user?.assessment?.title,
                            Grade:
                                user?.assessment?.evaluation_type == "STANDARD" ||
                                    user?.assessment?.evaluation_type == "MULTI_CHOICE" ||
                                    user?.assessment?.evaluation_type == "GENIE_MULTI_CHOICE"
                                    ? `${user?.details?.total_score?.toString()}%`
                                    : user?.details?.overall_competency_score?.toString(),
                            "Due Date": moment(
                                user?.assessment_assignment?.due_datetime
                            ).format(process.env.REACT_APP_DATE_FORMAT),
                            "Completed on": completedDate,
                            Report: {
                                url: user?.transcript_url,
                                transcript_download: userInfo?.role.toUpperCase() === ProfileType.recruiter ? true : user?.assessment?.transcript_download,
                                openPopup:
                                    user?.attempt_id == showTranscriptAttempt ? "show" : "hide",
                                status: "COMPLETED",
                            },
                        };
                    });
                    setRowData(users);
                } else {
                }
            } catch (error) {
                console.log("LOG FAIL error", error);
            }
        })();

    }, [isGridLoading, setIsLoading]);

    const [columnDefs] = useState([
        {
            field: "SI",
            headerName: "S.No.",
            maxWidth: 100,
        },
        {
            field: "Title",
            filter: true,
            sortable: true,
            // maxWidth: 150,
        },
        {
            field: "Grade",
            filter: true,
            maxWidth: 150,
            // sortable: true,
        },
        {
            field: "Due Date",
            filter: true,
            sortable: true,
            maxWidth: 250,
        },
        {
            field: "Completed on",
            filter: true,
            sortable: true,
            maxWidth: 250,
        },
        {
            field: "Report",
            cellRenderer: Pdf,
            filter: false,
            unSortIcon: false,
            sortable: false,
            maxWidth: 80,
        },
    ]);

    const defaultColDef = useMemo(() => ({
        flex: 1,
        floatingFilter: true,
        unSortIcon: true,
    }));

    const rowHeight = 85;

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const navigate = useNavigate();

    return (
        <>
            <div className="m-2 p-2 publisherContainer">
                <div className="my-4 ">
                    <Row className="align-items-center">
                        <Col md={1} sm={3} className="pe-0">
                            {/* <Button onClick={()=>navigate(AppUrlsEnum.superVisorGinieUser)} 
                        variant="outline-info" className="back-btn"> Back</Button> */}
                            <BackButton
                                text="Back"
                                className="back-btn"
                                onClick={()=>navigate(AppUrlsEnum.superVisorGinieUser)} 
                            />
                        </Col>
                        <Col md={11} sm={9}>
                            <Heading>
                                Transcripts of <span className="genie-transcript-name">{selectedUserName}</span>
                            </Heading></Col>
                    </Row>

                </div>

                <div className="ag-theme-alpine" style={{ height: "500px" }}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        pagination={true}
                        paginationPageSize={10}
                        rowHeight={rowHeight}
                        suppressDragLeaveHidesColumns={true}
                        className="user-table-transcript"

                    />
                </div>


            </div>
        </>
    );
};
export default TranscriptList;
