//** Key and Value of Assessment Translations -> Check with Value name in en.language.json **//

export function AssessmentTranslations(t) {
  return {
    key: "assessmentTranslations",
    value: {
      assessmentDetails: t("onlineAssessmentsTranslations.assessmentDetails"),
      intendedLearners: t("onlineAssessmentsTranslations.intendedLearners"),
      assessmentSettings: t("onlineAssessmentsTranslations.assessmentSettings"),
      pricing: t("onlineAssessmentsTranslations.pricing"),
      logicAndReportBuilder: t(
        "onlineAssessmentsTranslations.logicAndReportBuilder"
      ),
      certification: t("onlineAssessmentsTranslations.certification"),
      author: t("onlineAssessmentsTranslations.author"),
      priceTier: t("onlineAssessmentsTranslations.priceTier"),
      priceDetails1: t("onlineAssessmentsTranslations.priceDetails1"),
      priceDetails2: t("onlineAssessmentsTranslations.priceDetails2"),
      price: t("onlineAssessmentsTranslations.price"),
      assessmentProgressView: {
        assessmentDetails: {
          key: "assessmentDetails",
          value: t("onlineAssessmentsTranslations.assessmentDetails"),
          url: "",
        },
        intendedLearners: {
          key: "intendedLearners",
          value: t("onlineAssessmentsTranslations.intendedLearners"),
          url: "",
        },
        assessmentSettings: {
          key: "assessmentSettings",
          value: t("onlineAssessmentsTranslations.assessmentSettings"),
          url: "",
        },
        pricing: {
          key: "pricing",
          value: t("onlineAssessmentsTranslations.pricing"),
          url: "",
        },
        logicAndReportBuilder: {
          key: "logicAndReportBuilder",
          value: t("onlineAssessmentsTranslations.logicAndReportBuilder"),
          url: "",
        },
        certification: {
          key: "certification",
          value: t("onlineAssessmentsTranslations.certification"),
          url: "",
        },
        author: {
          key: "author",
          value: t("onlineAssessmentsTranslations.author"),
          url: "",
        },
      },
    },
  };
}
