//**Iguroo Admin UI code**//

import React from "react";
import Header from "../../components/header";
import FooterComponent from "../../components/footer";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Outlet } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Sidebar from "../../components/sidebar/Sidebar";
import "./admin.scss";

const Admin = () => {
  return (
    <div className="d-flex flex-column" style={{ fontFamily: "manrope" }}>
      <Row className="m-0 p-0">
        <Col md={2} className="m-0 p-0 border-end">
          <Sidebar />
        </Col>
        <Col md={10} className="m-0 p-0">
          <Row className="m-0 p-0 border-bottom">
            <Col className="m-0 p-0">
              <Header isAdmin={true} />
            </Col>
          </Row>
          <Row className="m-0 p-0">
            <Col className="">
              <Outlet />
            </Col>
          </Row>
        </Col>
        <Col className="m-0 p-0">
          <FooterComponent />
        </Col>
      </Row>
    </div>
  );
};

export default Admin;
