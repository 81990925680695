//** GinieHealth TestQuestions DifferentType Component have given in the code **//

import { Container, Row } from "react-bootstrap";
import CheckboxQuestion from "../../components/questionComponent/checkBoxquestion";
import TextQuestion from "../../components/questionComponent/textquestion";
import QHeader from "../../components/questions/qHeader";
import DropDownQuestion from "../../components/questionComponent/Dropdownquestion";
import MultiPerspectivequestion from "../../components/questionComponent/multiPerspectivequestion";
import {
  AttemptIdValue,
  createAssessmentAttemptAsyncThunk,
  currentGLQuestionValue,
} from "../../redux-tk/slices/genieLabs.currentQuestion";
import { useDispatch, useSelector } from "react-redux";
import RadioButtonQuestion from "../../components/questionComponent/radioButtonquestion";
import { useContext, useEffect, useState } from "react";
import { getAssessmenById } from "../../services/axios/Assessment/assessment";
import QHeaderNew from "../../components/questions/QHeaderNew";
import { GetQuestionsAssessmentApi } from "@iguroo/ig-react-assessment-package";
import { useGetAssessments } from "../../hooks/useGetAssessments";
import { AuthContext } from "../../hooks/auth.context.provider";
import { PopupQuestion } from "../../components/questionComponent/component/popup";
import { AppUrlsEnum } from "../../enums";
import { useLocation } from "react-router-dom";

const TestQuestionScreen = () => {
  const currentQuestion = useSelector((state) => currentGLQuestionValue(state));
  const attempid = useSelector((state) => AttemptIdValue(state));
  const [assessment_version_id, setAssessment_version_id] = useState("");
  const [assessmentData, setAssessmentData] = useState({});
  const [totalTime, setTotalTime] = useState("");
  const [attempt, setAttempt] = useState("");
  const [question, setQuestion] = useState("");
  const [title, setTitle] = useState("");
  const [totalAttempt, setTotalAttempt] = useState(0);
  const [attemptLeft, setAttemptLeft] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const authContext = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    const pageState = location?.state;
    setAssessment_version_id(pageState?.assessment_version_id);
    setAssessmentData(pageState?.assessmentData);
    setTotalAttempt(pageState?.attempts);
    setAttemptLeft(pageState?.attemptLeft);
  }, []);

  const {
    assessments,
    setAssessments,
    fetchAssessments,
    fetchInitialAssessments,
    getSearchOnChange,
    getSubmit,
    setType,
    type,
  } = useGetAssessments({
    apiFunction: GetQuestionsAssessmentApi,
    initialQuery: {
      assessment_version_id: location?.state?.assessment_version_id,
    },
  });

  const attemptDetail = {
    userId: authContext.sessionInfo.sub,
    assessment_version_id: assessment_version_id,
    answers: [],
    status: "INPROGRESS",
  };
  console.log(attempid, assessment_version_id, " attempidattempid 69");
  useEffect(() => {
    if (assessment_version_id != "") fetchInitialAssessments();
  }, [assessment_version_id]);
  useEffect(() => {
    (async function () {
      if (assessment_version_id != "") {
        console.log(attempid, assessment_version_id, " attempidattempid");
        const attempt = dispatch(
          createAssessmentAttemptAsyncThunk(attemptDetail)
        );
        console.log(attempid, assessment_version_id, " attempidattempid");
        getAssessmenById(assessment_version_id)
          .then((res) => {
            console.log("LOG  attempidattempid", res.response.data[0]);
            const dataA = res.response.data[0];
            setTotalTime(dataA.duration);
            setAttempt(dataA.attempts_allowed);
            setQuestion(dataA.number_of_questions);
            setTitle(dataA.title);
          })
          .catch((err) => console.log(err));
      }
    })();
  }, [assessment_version_id]);
  const dispatch = useDispatch();
  console.log("currentQuestion click", currentQuestion);

  return (
    <>
      {/* <TextQuestion />
       */}
      <Container>
        {assessmentData?.duration ? (
          <>
            <QHeader
              totalTime={assessmentData?.duration}
              duration={assessmentData?.duration}
              attempt={totalAttempt}
              attemptLeft={attemptLeft}
              question={assessments?.length}
              currentQuestion={currentQuestion + 1}
              title={title}
              assessmentData={assessmentData}
              pauseClick={() => setShowPopup(true)}
            />
          </>
        ) : (
          <QHeaderNew
            totalTime={assessmentData?.duration}
            duration={assessmentData?.duration}
            attempt={attempt}
            question={assessments?.length}
            currentQuestion={currentQuestion + 1}
            title={title}
            pauseClick={() => setShowPopup(true)}
          />
        )}
        <PopupQuestion
          closeOnBackClick={() => setShowPopup(false)}
          showPopup={showPopup}
          type="popup"
          title={"Pause Test"}
          submitText={"OK"}
          modalBody={"Your Assessment has been paused"}
          url={AppUrlsEnum.learnermyassessment}
        />
        {/* <MultiPerspectivequestion /> */}
        {assessments?.map((question, index) => {
          if (currentQuestion == index) {
            return question.question_type == "MULTI_PERSPECTIVE" ||
              question.question_type == "MULTI_CHOICE" ||
              question.question_type == "STANDARD"
              ? (
                <MultiPerspectivequestion
                  {...question}
                  attempid={attempid}
                  totalQuestion={assessments?.length}
                  assessment_version_id={assessment_version_id}
                  assessmentData={assessmentData}
                />
              ) : question.question_type == "FREE_TEXT" ? (
                <TextQuestion
                  {...question}
                  attempid={attempid}
                  totalQuestion={assessments?.length}
                  assessment_version_id={assessment_version_id}
                  assessmentData={assessmentData}
                />
              ) : question.question_type == "DROPDOWN" ? (
                <DropDownQuestion
                  {...question}
                  totalQuestion={assessments?.length}
                  {...assessment_version_id}
                  {...attempid}
                />
              ) : question.question_type == "RADIO" ? (
                <RadioButtonQuestion
                  {...question}
                  {...attempid}
                  totalQuestion={assessments?.length}
                  {...assessment_version_id}
                />
              ) : question.question_type == "CHECKBOX" ? (
                <CheckboxQuestion
                  {...question}
                  {...attempid}
                  totalQuestion={assessments?.length}
                  {...assessment_version_id}
                />
              ) : question.question_type == "DATE" ? (
                <CheckboxQuestion
                  {...question}
                  {...attempid}
                  totalQuestion={assessments?.length}
                  {...assessment_version_id}
                />
              ) : question.question_type == "TIME" ? (
                <CheckboxQuestion
                  {...question}
                  {...attempid}
                  totalQuestion={assessments?.length}
                  {...assessment_version_id}
                />
              ) : question.question_type == 'SINGLE_CHOICE' ? (
                <RadioButtonQuestion
                  {...question}
                  attempid={attempid}
                  totalQuestion={assessments?.length}
                  assessment_version_id={assessment_version_id}
                  assessmentData={assessmentData}
                />
              ) : (
                ""
              );
          }
        })}
      </Container>
    </>
  );
};

export default TestQuestionScreen;
