import * as yup from "yup";
export function getSkillFormTranslated(formErrors) {
  return yup.object({
    skills: yup
      .array()
      .of(
        yup.object({
          value: yup.string(),
          dataId: yup.string(),
          dataType: yup.string(),
        })
      )
      .optional(),
    hobbies: yup
      .array()
      .of(
        yup.object({
          value: yup.string(),
          dataId: yup.string(),
          dataType: yup.string(),
        })
      )
      .optional(),
    describachievements: yup
      .string()
      .required(formErrors?.describachievements)
      .optional(),
  });
}
