import { useState } from "react";

export const useMentor = (data, size = 12) => {
  const [currentPage, setCurrentPage] = useState(1);

  const moveToNextPage = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo({ top:0, behavior: "smooth" });
  };
  const moveToPrevPage = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo({ top:0, behavior: "smooth" });
  };
  const setPageToLast = () => {
    setCurrentPage(Math.ceil(data / size));
  };
  const resetPage = () => {
    setCurrentPage(1);
  };
  return {
    currentPage,
    moveToNextPage,
    moveToPrevPage,
    setPageToLast,
    resetPage,
  };
};
