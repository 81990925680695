//** Iguroo Mentor SideBar Value Onboarding Stage and Added Links **//

import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { _getImage } from "../common/utils/commonFunctions";

export function MentorSetupSidebarValue(props) {
  const { value, isActive, isChecked, url } = props;
  //console.log("k",myKey,value,isActive);
  const imageUrl = isChecked
    ? "completed.svg"
    : isActive
    ? "ic_progress_av.png"
    : "ic_progress_na.png";
  //console.log("a",isChecked , isActive,url,(isChecked || isActive) ? url : "")
  let sideClassName = isChecked
    ? isActive
      ? "activeSidebar"
      : "checkedSidebar"
    : isActive
    ? "activeSidebar"
    : "notactiveSidebar";

  return (
    <div
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginTop: 30,
      }}
    >
      <Image
        resizeMode="contain"
        style={{ width: 20, height: 20, marginRight: 14 }}
        src={_getImage(imageUrl)}
      />
      <span
        style={{
          fontSize: 15,
          fontWeight: "500",
          color: "#222222",
        }}
        className={`sidebar ${sideClassName}`}
      >
        <Link to={url} className="sidebar-link">
          {value}
        </Link>
      </span>
    </div>
  );
}
