import React from "react";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";

const MentorMessages = () => {
    const { dashboardTranslations } = useIgurooTranslations();
    return (<>
        {dashboardTranslations?.mentorMessagePage}
    </>)
}

export default MentorMessages;