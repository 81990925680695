//** Onboarding Availability Page **//

import React, { useEffect, useState, useContext } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Row, Col, Button, Image, Container } from "react-bootstrap";
import { GetAllData, GetDataType } from "../../services/axios/staticData";
import {
  allDataConverter,
  availabilityResponseToRedux,
  getTimeZoneObject,
} from "../../helpers";
import { useNavigate } from "react-router-dom";
import { MentorSetupSidebar } from "../../components/mentor.setup.sidebar";
import { SubmitButton } from "../../components/submit.button";
import { CustomSelect } from "../../components/CustomInput/custom.select";
import { CustomAddMoreBtn } from "../../components/CustomInput/customAddMoreBtn";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { getMentorAvailabilityFormTranslated } from "../../FormSchemas";
import { _getImage } from "../../common/utils/commonFunctions";
import moment from "moment";
import { AvailabilityItemComponent } from "../../components/availability.item.component";
import { PostAvailability } from "../../services/axios/MentorOnbaording";
import { AuthContext } from "../../hooks/auth.context.provider";
import Toaster from "../../components/toaster";
import { toast } from "react-toastify";
import { Heading } from "../../components/heading.component";
import { AlertComponent } from "../../components/alert.component";
import { AppUrlsEnum, OnboardingSteps, ProfileType } from "../../enums";
import { FormErrorComponent } from "../../components/form.error.component";
import { useDispatch, useSelector } from "react-redux";
import {
  availabilityAction,
  availabilityValue,
  deleteAvailabilityAsyncThunk,
  getAvailabilityAsyncThunk,
} from "../../redux-tk/slices/mentor.onboarding.data";
import { loader } from "../../redux-tk/slices/loader.slice";
import { FormLabelComponent } from "../../components/form.label.component";
import { languageValue } from "../../redux-tk/slices/general.info.slice";
import {
  getUserAsyncThunk,
  postUserProfileAsyncThunk,
  updateTimeZoneAction,
  userProfileValue,
} from "../../redux-tk/slices/user.slice";
import { OnboardingHeading } from "../../components/onboarding.heading.component";
import { Popup } from "../../components/CustomInput/popup";
import { showPopupflag } from "../../redux-tk/slices/showPopupflag.slice";

export default function AvailabilityScreen({ settings }) {
  const {
    mentorOnboardingLabels,
    formElementLabels,
    formErrors,
    headingTranslations,
    headerFooterTranslations,
  } = useIgurooTranslations();
  const dispatch = useDispatch();
  const selectedValue = useSelector((state) => availabilityValue(state));
  const selectedLanguage = useSelector((state) => languageValue(state));
  const userSelectedValue = useSelector((state) => userProfileValue(state));
  //console.log("sel", selectedValue);
  const authContext = useContext(AuthContext);
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      timeZone: userSelectedValue?.timeZone,
      avails: [...selectedValue.avails],
    },
    resolver: yupResolver(getMentorAvailabilityFormTranslated(formErrors)),
  });
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "avails", // unique name for your Field Array
    shouldUnregister: true,
  });
  //  console.log("erro",errors);
  let ignore = false;
  const [timeZones, setTimeZones] = useState([]);
  const [timeZone, setTimeZone] = useState({});
  const [availabilityDays, setAvailabilityDays] = useState([]);
  const [availabilityData, setAvailabilityData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isDirty) {
      (async function () {
        dispatch(showPopupflag(true));
      })();
    }
  }, [isDirty]);

  useEffect(() => {
    if (isDirty) {
      (async function () {
        dispatch(showPopupflag(true));
      })();
    }
  }, [isDirty]);

  useEffect(() => {
    if (!ignore) {
      (async function () {
        initializeApp();
      })();
      if (selectedValue?.avails?.length === 0) {
      }
    }
    return () => {
      ignore = true;
      dispatch(loader(false));
    };
  }, [selectedLanguage]);

  const initializeApp = async () => {
    dispatch(loader(true));
    const items = await GetDataType("timezones", selectedLanguage);
    setTimeZones(items);

    const availItems = await GetDataType("availability", selectedLanguage);
    setAvailabilityDays(availItems);
    setValue("timeZone", items[0]);
    dispatch(loader(false));
  };

  useEffect(() => {
    (async function () {
      dispatch(
        getAvailabilityAsyncThunk({ userId: authContext.sessionInfo.sub })
      );
      dispatch(getUserAsyncThunk({ userId: authContext.sessionInfo.sub }));
    })();
  }, []);

  const addAvailabilityInputs = () => {
    append({
      id: -1,
      availability: "",
      timeRange: {
        startTime: moment().startOf("hour").format(),
        endTime: moment().startOf("hour").add(1, "hour").format(),
      },
    });
  };

  const removeAvailabilityInputs = (inputIndex) => {
    console.log("input index", inputIndex);
    if (fields.length > 1) {
      const value = getValues(`avails.${inputIndex}.id`);
      if (value === "-1" || value === -1) {
        remove(inputIndex);
        return;
      }
      //console.log('dele"',field,value);
      dispatch(deleteAvailabilityAsyncThunk({ id: value }));
    }
  };

  useEffect(() => {
    console.log("ae", selectedValue);
    if (selectedValue?.avails[0]?.id != -1) {
      reset({
        timeZone: userSelectedValue?.timeZone,
        avails: [...selectedValue.avails],
      });
    }
  }, [selectedValue, userSelectedValue]);

  const onSubmit = async (data) => {
    console.log("data from availability", data);
    try {
      //console.log("data'",timeZone, userSelectedValue?.timeZone)
      if (settings) {
        setShowPopup(true);
        setAvailabilityData(data);
      } else {
        dispatch(loader(true));
        await dispatch(
          postUserProfileAsyncThunk(
            getTimeZoneObject(
              timeZone?.dataId ? timeZone : userSelectedValue?.timeZone,
              OnboardingSteps[ProfileType.mentor][5].value,
              authContext.sessionInfo.sub
            )
          )
        );

        const result = await PostAvailability(
          authContext.sessionInfo.sub,
          allDataConverter.removeDefaultId(data.avails)
        );
        const converted = availabilityResponseToRedux(result.data);
        console.log("con", converted);
        const saveToRedux = {
          timeZone: timeZone?.dataId ? timeZone : selectedValue?.timeZone,
          avails: converted,
        };
        console.log("saveing", saveToRedux);
        dispatch(availabilityAction(saveToRedux));
        dispatch(
          updateTimeZoneAction(
            timeZone?.dataId ? timeZone : userSelectedValue?.timeZone
          )
        );
        dispatch(loader(false));
        Toaster(toast.success, headerFooterTranslations.toasterMsgOnboarding);
        if (!settings) {
          navigate(AppUrlsEnum.mentorRates);
        }
      }
    } catch (err) {
      setError("apiError", { message: err.message });
      Toaster(toast.error, err.message);
      dispatch(loader(false));
    }
  };

  const handleGotit = async () => {
    dispatch(loader(true));
    setShowPopup(false);
    await dispatch(
      postUserProfileAsyncThunk(
        getTimeZoneObject(
          timeZone?.dataId ? timeZone : userSelectedValue?.timeZone,
          6,
          authContext.sessionInfo.sub
        )
      )
    );

    const result = await PostAvailability(
      authContext.sessionInfo.sub,
      allDataConverter.removeDefaultId(availabilityData.avails)
    );
    const converted = availabilityResponseToRedux(result.data);
    console.log("con", converted);
    const saveToRedux = {
      timeZone: timeZone?.dataId ? timeZone : selectedValue?.timeZone,
      avails: converted,
    };
    console.log("saveing", saveToRedux);
    dispatch(availabilityAction(saveToRedux));
    dispatch(
      updateTimeZoneAction(
        timeZone?.dataId ? timeZone : userSelectedValue?.timeZone
      )
    );
    dispatch(loader(false));
    Toaster(toast.success, headerFooterTranslations.toasterMsgOnboarding);
    if (!settings) {
      navigate(AppUrlsEnum.mentorRates);
    }
  };

  return (
    <div>
      {!settings && <OnboardingHeading profileType={ProfileType.mentor} />}
      <Container className="px-3">
        <Row>
          <Col md="12" lg={settings ? 12 : 9}>
            {!settings && (
              <>
                <Heading>
                  {headingTranslations?.mentoringSessionAvailability}
                </Heading>
                <AlertComponent showIcon={true}>
                  {headingTranslations?.availabilityScreenAlert}
                </AlertComponent>
              </>
            )}
            <Form onSubmit={handleSubmit(onSubmit)} className="mt-3">
              <Form.Group>
                <FormLabelComponent>
                  {mentorOnboardingLabels?.timeZone}
                </FormLabelComponent>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CustomSelect
                      opLabel="value"
                      opValue="dataId"
                      items={timeZones}
                      onReactHookFormBlur={onBlur}
                      onReactHookFormChange={onChange}
                      value={value}
                      onChangeCallback={setTimeZone}
                      placeholder={formElementLabels?.placeHolders?.timezone}
                      sendFullValueInHook={true}
                      normalSelect={true}
                      defaultValue={timeZones}
                    />
                  )}
                  name="timeZone"
                />
                <FormErrorComponent>
                  {errors.timeZone && errors.timeZone?.message}
                </FormErrorComponent>
              </Form.Group>

              <Form.Group>
                {fields.map((field, index) => {
                  //console.log("fil'", field);
                  return (
                    <AvailabilityItemComponent
                      key={field.id}
                      items={availabilityDays}
                      removeFunction={removeAvailabilityInputs}
                      hookControl={control}
                      itemIndex={index}
                      errors={errors}
                    />
                  );
                })}
                <div className="mt-3 mb-3">
                  <FormErrorComponent>
                    {errors.avails && errors.avails?.message}
                  </FormErrorComponent>
                </div>
              </Form.Group>
              <Row className="mt-3">
                <Col md="4" className="d-grid d-md-block">
                  <CustomAddMoreBtn
                    text={formElementLabels.addAnotherTimeSlot}
                    className="mt-3"
                    onClick={addAvailabilityInputs}
                  />
                </Col>
              </Row>

              <Row className="mt-1 mb-3">
                <FormErrorComponent>
                  {errors.apiError && errors.apiError?.message}
                </FormErrorComponent>
              </Row>

              <hr />
              <Row className="text-end mb-4 justify-content-end save-btn-end">
                <Col sm="12" md="2" className="d-grid ">
                  <SubmitButton
                    type="submit"
                    text={formElementLabels?.save}
                    data-testid="Submit"
                  />
                </Col>
              </Row>
            </Form>
          </Col>
          {!settings && (
            <Col>
              {mentorOnboardingLabels?.rightSetupModule?.mentor && (
                <MentorSetupSidebar
                  typeToUse={mentorOnboardingLabels?.rightSetupModule?.mentor}
                  active={
                    mentorOnboardingLabels?.rightSetupModule?.mentor?.values
                      ?.avail
                  }
                />
              )}
            </Col>
          )}
        </Row>
      </Container>
      <Popup
        closeOnBackClick={() => {
          setShowPopup(false);
          console.log("called--");
        }}
        showPopup={showPopup}
        type="popup"
        title={"INFORMATION"}
        hideSVG={true}
        infoText={true}
        modalBody={
          <>
            <p className="text-start fw-500">
              Changing your availability doesn’t change the already scheduled
              sessions with mentees. Please inform the support team to
              reschedule the sessions if needed.
            </p>
          </>
        }
        gotIt={handleGotit}
      />
    </div>
  );
}
