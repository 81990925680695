import * as yup from "yup";

export const recommendationYup = (formErrors) => {
  return yup.object({
    currRec: yup
      .array()
      .of(
        yup.object({
          stream_1: yup.string().required(formErrors?.fieldRequired),
          stream_2: yup.string().required(formErrors?.fieldRequired),
          stream_3: yup.string().required(formErrors?.fieldRequired),
          career_1: yup.string().required(formErrors?.fieldRequired),
          career_2: yup.string().required(formErrors?.fieldRequired),
          career_3: yup.string().required(formErrors?.fieldRequired),
        })
      )
      .required()
      .test({
        message: "The error message if length === 1",
        test: (arr) => arr.length > 0,
      }),
  });
};
