import React from "react";
import MenteeEducationExperience from "../../mentee/educationExperience";

const Education = () => {
  return (
    <>
      <MenteeEducationExperience settings={true} />
    </>
  );
};
export default Education;
