import * as yup from "yup";

export const adminMentorPublicPrivate = (formErrors) => {
  return yup.object({
    mentorName: yup.string().required(formErrors?.mentorName),
  });
};

export const requiredUserId = (formErrors) => {
  return yup.object({
    userId: yup.string().required(formErrors?.userId),
  });
};
