//** GinieHealth Learner Login,MultipleChoice QuestionType UI component **//

import { useForm, Controller, useFieldArray } from "react-hook-form";
import { SvgImageContainer } from "./SvgImageContainer";
import { CustomInputField } from "../CustomInput/customInputField";

export const MultipleChoice = ({ type, icon, length, btnTitle, title }) => {
  const { control } = useForm({ defaultValues: { optArray: [{ value: "" }] } });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "optArray",
    shouldUnregister: true,
  });
  console.log("MultipleChoice", fields);

  const addoptions = () => {
    append({ value: "" });
  };

  return (
    <>
      {title && <p>{title}</p>}
      {fields.map((field, index) => (
        <div className="d-flex align-items-center pe-4" key={field.id}>
          {icon ? (
            <SvgImageContainer image={icon} width={17} height={17} />
          ) : (
            <span>{index + 1}</span>
          )}
          <Controller
            name={`optArray.${index}.value`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <CustomInputField
                hookOnBlur={onBlur}
                hookValue={value}
                hookOnChange={onChange}
                className="custom_input ms-3 text-dark"
                placeholder={"Enter an option"}
              />
            )}
          />
          <SvgImageContainer
            image="close-dark.svg"
            width={20}
            height={20}
            onClick={() => remove(index)}
          />
        </div>
      ))}
      <div
        className={`d-flex align-items-center mt-2 ${
          fields.length >= length ? "disabled-div" : ""
        }`}
        role="button"
        onClick={() => addoptions()}
        disabled={fields.length >= length}
      >
        {icon ? (
          <SvgImageContainer image={icon} width={17} height={17} />
        ) : (
          <span>
            {fields.length + 1}
            {"."}
          </span>
        )}
        <span className="ms-3">{btnTitle}</span>
      </div>
    </>
  );
};
