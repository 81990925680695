//** Iguroo Aggrid main,Category Data Field,can re-use required field **//

import { CategoryComponent } from "../category.component";

export const CategoryRenderer = ({ value }) => {
  console.log(value, "CategoryRenderer");

  return (
    <>
      {value?.map((category) => (
        <CategoryComponent className={"category"} title={category.value} />
      ))}
    </>
  );
};
