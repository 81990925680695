//** Iguroo Mentee UserProfile Data Slice **//

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetUser,
  PostUserProfile,
} from "../../services/axios/MentorOnbaording";
import { decrementLoader, incrementLoader } from "./loader.slice";

export const getUserAsyncThunk = createAsyncThunk(
  "user/getUserThunk",
  async (data, thunkApi) => {
    try {
      const user = await GetUser(data.userId);
      //  console.log("userd", user);
      return user.data;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);

export const postUserProfileAsyncThunk = createAsyncThunk(
  "user/postUserProfileThunk",
  async (data, thunkApi) => {
    try {
      thunkApi.dispatch(incrementLoader());
      const userProfile = await PostUserProfile(data);
      //  console.log("data", data);
      return data;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    } finally {
      thunkApi.dispatch(decrementLoader());
    }
  }
);

const initialState = {
  menteeProfile: {},
  menteeworkExp: {
    edu: [],
    wrk: [],
  },
  menteeSkill: [],
};

export const menteeuserSlice = createSlice({
  name: "menteeuserData",
  initialState,
  reducers: {
    updateOnboardingStage: (state, action) => {
      if (!state?.state?.onBoardStage) return;
      state.menteeProfile.onBoardStage = action.payload;
    },
    removeProfilePicAction: (state, action) => {
      state.menteeProfile.profilePic = "";
    },
    removeProfileVideoAction: (state, action) => {
      state.menteeProfile.profileVideoLink = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAsyncThunk.fulfilled, (state, action) => {
        console.log("getUserAsyncThunk'", action, state);
        if (!action?.payload) return;
        if (action?.payload?.profile) {
          state.menteeProfile = { ...action?.payload?.profile };
        }
        if (action?.payload?.educationHistory) {
          state.menteeworkExp.edu = [...action?.payload?.educationHistory];
        }
        if (action?.payload?.workHistory) {
          state.menteeworkExp.wrk = [...action?.payload?.workHistory];
        }
        if (action?.payload?.profile) {
          state.menteeSkill = { ...action?.payload?.profile };
        }
      })
      .addCase(getUserAsyncThunk.pending, (state) => {})
      .addCase(getUserAsyncThunk.rejected, (state) => {})
      .addCase(postUserProfileAsyncThunk.fulfilled, (state, action) => {
        //  console.log("ful", action,state);
        Object.keys(action.payload).map((val) => {
          if (val === "userId") {
            return;
          }
          if (!state?.menteeProfile) {
            return;
          }
          return (state.menteeProfile[val] = action.payload[val]);
        });
      })
      .addCase(postUserProfileAsyncThunk.pending, (state) => {})
      .addCase(postUserProfileAsyncThunk.rejected, (state) => {});
  },
});

export const {
  updateOnboardingStage,
  removeProfilePicAction,
  removeProfileVideoAction,
} = menteeuserSlice.actions;

export const userValue = (state) => state?.menteeuser?.menteeProfile;

export const userProfileValue = (state) => state?.menteeuser.menteeProfile;
export const menteeProfileValue = (state) => state?.menteeuser.menteeProfile;
export const userProfileOnboardingStageValue = (state) => {
  return state?.menteeuser?.menteeProfile?.onBoardStage
    ? state?.menteeuser?.menteeProfile?.onBoardStage
    : 1;
};

export default menteeuserSlice.reducer;
