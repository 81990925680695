import React, { useEffect, useCallback, useState, useMemo } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { FormLabelComponent } from "../../components/form.label.component";
import { AgGridReact } from "ag-grid-react";
import { SubmitButton } from "../../components/submit.button";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { useRef } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import ProfileLink from "./ProfileLink";
import "./admin.scss";
import { Action } from "../UserManagement/CellRenderers";
import { AddUser } from "../UserManagement/AddUser";
import { getUserManagement } from "../../services/axios/UserManagement";
import StatusTag from "./StatusTag";

const AdminUserManagement = () => {
  const gridRef = useRef();
  const [searchText, setSearchText] = useState("");
  const [update, setUpdate] = useState(false);
  useEffect(() => {}, []);
  const [showPasswordFields, setShowPasswordFields] = useState(true);
  const [addUserPopUp, setAddUserPopUp] = useState(false);
  const [rowData, setRowData] = useState([]);
  const refreshTable = useCallback((params) => {
    console.log("checking addnew user");
    setSearchText("");
    console.log("Refresh checking", update, params);
    setUpdate(!update);
    gridRef.current.api.refreshInfiniteCache();
  }, []);

  const [columnDefs] = useState([
    {
      field: "UserName",
      cellRenderer: ProfileLink,
      resizable: true,
    },
    { field: "mobile", resizable: true },
    {
      field: "email",
      sortable: true,
      resizable: true,
    },
    {
      field: "User Role",
      sortable: true,
      resizable: true,
    },
    { field: "Status", cellRenderer: StatusTag, sortable: false },
    // {
    //   field: "Referral Code",
    //   sortable: true,
    //   resizable: true,
    // },
    {
      field: "Edit User",
      cellRenderer: Action,
      filter: false,
      unSortIcon: false,
      sortable: false,
      showPasswordFields: true,
      resizable: true,
      cellRendererParams: {
        updateList: refreshTable,
        igurooUser: true,
      },
    },
  ]);

  const defaultColDef = useMemo(() => ({
    flex: 1,
    unSortIcon: true,
  }));

  const components = useMemo(() => {
    return {
      StatusTag: StatusTag,
      ProfileLink: ProfileLink,
    };
  }, []);

  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event);
  }, []);

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    document.querySelector("#selectedRows").innerHTML =
      selectedRows.length === 1 ? selectedRows[0].athlete : "";
  }, []);

  const isRowSelectable = useMemo(() => {
    return (params) => {
      return !!params.data;
    };
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.forEachNode((node) => node.setSelected(false));
  }, []);

  function handleGetUsers() {
    getUserManagement(0, 20)
      .then((res) => {
        console.log(res, "Refresh checking");
        if (res?.data?.data) {
          const users = res?.data?.data?.map((user) => {
            return {
              userId: user.userId,
              User: `${user?.firstName} ${user.lastName}`,
              email: user?.email ? user?.email : "----",
              firstName: user?.firstName,
              lastName: user?.lastName,
              mobile: user?.mobile ? user?.mobile : "----",
              "User Role": user?.type,
              type: user?.type,
              Status: user?.isApproved,
              status: user?.isApproved,
              "Profile action": user?.isApproved,
            };
          });

          setRowData(users);
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    handleGetUsers();
  }, []);

  const search = () => {
    getUserManagement(0, 20, searchText)
      .then((res) => {
        // console.log(res,"Refresh checking")
        if (res?.data?.data) {
          const users = res?.data?.data?.map((user) => {
            return {
              userId: user.userId,
              User: `${user?.firstName} ${user.lastName}`,
              email: user?.email ? user?.email : "----",
              firstName: user?.firstName,
              lastName: user?.lastName,
              mobile: user?.mobile ? user?.mobile : "----",
              "User Role": user?.type,
              type: user?.type,
              Status: user?.isApproved,
              status: user?.isApproved,
              "Profile action": user?.isApproved,
            };
          });

          setRowData(users);
        }
      })
      .catch((err) => console.log(err));
  };
  const rowHeight = 80;

  return (
    <div className="px-4">
      <Row className="d-flex justify-content-between align-items-center mb-1">
        <Col xs={12} md={4}>
          <h5>USER MANAGEMENT</h5>
        </Col>
        <Col xs={12} md={8} className="d-flex flex-row-reverse">
          {" "}
          <div>
            <Button
              className="me-3"
              style={{ backgroundColor: "#2270F7" }}
              onClick={() => setAddUserPopUp(true)}
            >
              New User
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="d-flex align-items-end mb-3">
        <Col></Col>
        <Col sm={12} md={6} lg={5}>
          <FormLabelComponent></FormLabelComponent>
          <CustomInputField
            placeholder={"search"}
            onChange={(e) => setSearchText(e)}
            hookValue={searchText}
            name="search"
          />
        </Col>
        <Col sm={12} md={2} lg={1} className="mt-3 d-grid">
          <SubmitButton onClick={search} text="Search" type="button" />
        </Col>
      </Row>

      <div className="ag-theme-alpine px-4 py-3" style={{ height: "400px" }}>
        <div className="ag-theme-alpine" style={{ height: "500px" }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowSelection="multiple"
            onCellClicked={cellClickedListener}
            onSelectionChanged={onSelectionChanged}
            suppressRowClickSelection={true}
            isRowSelectable={isRowSelectable}
            onFirstDataRendered={onFirstDataRendered}
            components={components}
            suppressDragLeaveHidesColumns={true}
            pagination={true}
            paginationPageSize={10}
            rowHeight={rowHeight}
          />
        </div>
      </div>

      {addUserPopUp && (
        <AddUser
          show={addUserPopUp}
          onHide={() => setAddUserPopUp(false)}
          data={{}}
          iSAddPopup={true}
          showPasswordFields={showPasswordFields}
          igurooUser={true}
          updateList={refreshTable}
        />
      )}
    </div>
  );
};

export default AdminUserManagement;
