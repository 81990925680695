//** GinieHealth UserManagement of AgGrid Ui code **//

import { useRef, useMemo, useState, useCallback, useContext } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { BulkUpload } from "./BulkUpload";
import { AddUser } from "./AddUser";
import { StatusTag, Name, Action } from "./CellRenderers";
import { _getImage } from "../../common/utils/commonFunctions";
import { GetUsersListApi } from "@iguroo/ig-react-assessment-package";
import { AgGridMain } from "../../components/agGridRenderers/ag.grid.main";
import "./styles.css";
import { AuthContext } from "../../hooks/auth.context.provider";
import FeedbackInvite from "../../components/FeedbackInvitePopup/FeedbackInvite";
import { SortColumnsAggrid } from "../../components/agGridRenderers/aggrid.sort.columns";
import SearchButton from "../../components/PublisherComponent/SearchButton";

const Users = () => {
  const gridRef = useRef();
  const [searchText, setSearchText] = useState("");
  const [searchSortText, setSearchSortText] = useState("");

  const [update, setUpdate] = useState(false);
  const authContext = useContext(AuthContext);
  const userType = useMemo(() => {
    return authContext?.sessionInfo?.role?.toUpperCase();
  }, [authContext.authStatus]);
  const [sortData, setSortData] = useState(["Name", "Type", "Status"]);
  const [resetedCols, setResetCols] = useState([]);

  const mapper = (data) => {
    return data.map((item) => {
      return { ...item, name: item.firstName + " " + item.lastName };
    });
  };

  // const sortBy = (displayName, sortType, sortField) => { 
  //   const filterModel = {
  //     [sortField]: { 
  //       filter: sortType,
  //       filterType: "text",
  //       type: "equals",
  //     },
  //   };
  //   gridRef.current.api.setFilterModel(filterModel);
  //   const dataSorting = sortData.filter((item) => item !== displayName);
  //   setResetCols(dataSorting);
  // };
   const sortBy = (displayName, sortType, sortField) => {
    setTimeout(()=>{
      gridRef.current.api.setFilterModel({
        [sortField]: {
          filter: sortType,
          filterType: "text",
          type: "equals",
        },
        search: {
          filter: searchSortText,
          filterType: "text",
          type: "equals",
        },
      });
      const dataSorting = sortData.filter((item) => item !== displayName);
      setResetCols(dataSorting);
    },700)
   
  };

  const refreshTable = useCallback((params) => {
    console.log("checking addnew user");
    setSearchText("");
    console.log("Refresh checking", update, params);
    setUpdate(!update);
    setTimeout(()=> gridRef.current.api.refreshInfiniteCache(),2000);
  }, []);

  const columnDefs = useMemo(
    () => [
      {
        headerName: "firstNamesortType",
        colId: "firstNamesortType",
        suppressColumnsToolPanel: true,
        sortable: false,
        hide: true,
        filter: true,
      },
      {
        field: "name",
        cellRenderer: Name,
        minWidth: 300,
        cellStyle: { "word-break": "break-word" },
        headerComponent: SortColumnsAggrid,
        headerComponentParams: {
          sortByFunction: (displayName, sortType, sortField) => sortBy(displayName, sortType, sortField),
          sortData: resetedCols,
          sortField: "firstNamesortType",
        },
      },
      {
        headerName: "search",
        colId: "search",
        suppressColumnsToolPanel: true,
        sortable: false,
        hide: true,
        filter: true,
      },
      { field: "email", minWidth: 250, sortable: false },
      { field: "mobile", sortable: false },
      {
        headerName: "typesortType",
        colId: "typesortType",
        suppressColumnsToolPanel: true,
        sortable: false,
        hide: true,
        filter: true,
      },
      {
        field: "type",
        header: "role",
        cellRenderer: (params) => {
          if (params.value) {
            const lowerCaseValue = params.value.toLowerCase();
            return lowerCaseValue === "supervisor" ? "RECRUITER" : params.value;
          }
          return params.value;
        },
        headerComponent: SortColumnsAggrid,
        headerComponentParams: {
          sortByFunction: sortBy,
          sortData: resetedCols,
          sortField: "typesortType",
        },
      },
      {
        headerName: "statussortType",
        colId: "statussortType",
        suppressColumnsToolPanel: true,
        sortable: false,
        hide: true,
        filter: true,
      },
      {
        field: "status",
        cellRenderer: StatusTag,
        headerComponent: SortColumnsAggrid,
        headerComponentParams: {
          sortByFunction: sortBy,
          sortData: resetedCols,
          sortField: "statussortType",
        },
      },
      {
        field: "action",
        cellRenderer: Action,
        filter: false,
        unSortIcon: false,
        sortable: false,
        showPasswordFields: true,
        cellRendererParams: {
          updateList: refreshTable,
        },
      },
    ],
    [resetedCols,searchText,searchSortText]
  );

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      flex: 1,
      unSortIcon: true,
      minWidth: 150,
      wrapText: true,
      autoHeight: true,
      sortable: false,
    }),
    []
  );

  const onGridReadyNew = useCallback(
    (params) => {
      console.log("Refresh");
      const dataSource = {
        rowCount: null,
        getRows: async function (rparams) {
          try {
            gridRef.current?.api?.showLoadingOverlay();
            const filterModel = rparams.filterModel;
            const endRow = rparams.endRow;
            const startRow = rparams.startRow;
            const inputQuery = {
              from: startRow,
              limit: endRow - startRow,
            };
            if (filterModel.hasOwnProperty("search")) {
              console.log(filterModel.hasOwnProperty("search"),filterModel?.search,"filterModel?.search")
              inputQuery.search = filterModel?.search?.filter;
            }
            if (filterModel.hasOwnProperty("firstNamesortType")) {
              inputQuery.firstNamesortType =
                filterModel?.firstNamesortType?.filter;
            }
            if (filterModel.hasOwnProperty("typesortType")) {
              inputQuery.typesortType = filterModel?.typesortType?.filter;
            }
            if (filterModel.hasOwnProperty("statussortType")) {
              inputQuery.statussortType = filterModel?.statussortType?.filter;
            }
            console.log("Refresh");
            const d = await GetUsersListApi(inputQuery);
            console.log("Refresh call", d);
            if (d.total === 0) {
              gridRef.current?.api?.showNoRowsOverlay();
            } else {
              gridRef.current?.api?.hideOverlay();
            }
            const dataAfterSortingAndFiltering = sortingData(
              rparams.sortModel,
              mapper(d.data)
            );
            rparams.successCallback(dataAfterSortingAndFiltering, d.total);
          } catch (err) {
            console.log("params", err);
            gridRef?.current?.api?.showNoRowsOverlay();
          }
        },
      };
      params?.api?.setDatasource(dataSource);
    },
    [gridRef, setUpdate]
  );

  function sortingData(sortModel, data) {
    const sortPresent = sortModel && sortModel.length > 0;
    console.log(sortPresent, sortModel, "callback callstart");
    if (!sortPresent) {
      return data;
    }
    console.log(sortPresent, sortModel, "callback callstart");
    // do an in memory sort of the data, across all the fields
    const resultOfSort = data.slice();
    resultOfSort.sort(function (a, b) {
      for (let k = 0; k < sortModel.length; k++) {
        const sortColModel = sortModel[k];
        const valueA = a[sortColModel.colId];
        const valueB = b[sortColModel.colId];
        console.log(valueA, valueB, "callstart sort");
        // this filter didn't find a difference, move onto the next one
        if (valueA == valueB) {
          continue;
        }
        const sortDirection = sortColModel.sort === "asc" ? 1 : -1;
        if (valueA > valueB) {
          return sortDirection;
        } else {
          return sortDirection * -1;
        }
      }
      // no filters found a difference
      return 0;
    });
    return resultOfSort;
  }

  const applySearch = () => {
    setSearchSortText(searchText);
    gridRef.current.api.setFilterModel({
      search: {
        filter: searchText,
        filterType: "text",
        type: "equals",
      },
    });
    setResetCols(sortData);
  };

  const onPageChange = useCallback((params) => {
    window.scroll({
      top: 30,
      behavior: "smooth",
    });
  }, []);

  const [addUserPopUp, setAddUserPopUp] = useState(false);
  const [importUserPopUp, setImportUserPopUp] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(true);
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackRes, setFeedbackRes] = useState([]);

  return (
    <div className="px-4">
      <Row className="d-flex justify-content-between align-items-center mb-1">
        <Col xs={12} md={4}>
          <h5>USERS</h5>
        </Col>
        {feedbackRes.length > 0 && showFeedback && (
          <FeedbackInvite
            title="Import Status"
            feedbacks={feedbackRes}
            show={showFeedback}
            onHide={() => {
              setShowFeedback(false)
              refreshTable();}}
          />
        )}
      </Row>
      <Row className="d-flex align-items-end mb-3">
        <Col className="d-flex align-items-end justify-content-end">
          {" "}
          <Button
            className="me-3 btn-user-management"
            style={{ backgroundColor: "#2270F7" }}
            onClick={() => setAddUserPopUp(true)}
          >
            <img src={_getImage("plus.svg")} className="me-1" /> New User
          </Button>
          <Button
            className="me-3 btn-user-management"
            style={{ backgroundColor: "#2270F7" }}
            onClick={() => setImportUserPopUp(true)}
          >
            Import Users
          </Button>
        </Col>
        <Col sm={12} md={6} lg={5} xl={4} xxl={4} className="">
          <SearchButton
            submit={applySearch}
            handleOnChange={(e) => setSearchText(e)}
          />
        </Col>
      </Row>
      <div className="user-table mb-3">
        <AgGridMain
          gridRef={gridRef}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReadyNew}
          rowHeight={70}
          onPaginationChanged={onPageChange}
          pageObj={{ pageSize: 20 }}
          className="user-table"
        />
      </div>
      {addUserPopUp && (
        <AddUser
          show={addUserPopUp}
          onHide={() => setAddUserPopUp(false)}
          data={{}}
          updateList={refreshTable}
          iSAddPopup={true}
          showPasswordFields={showPasswordFields}
        />
      )}
      {importUserPopUp && (
        <BulkUpload
        bulkUploadgenie={true}
          show={importUserPopUp}
          onHide={() => setImportUserPopUp(false)}
          updateList={refreshTable}
          feedbackResponse={(res) => {
            setShowFeedback(true);
            setFeedbackRes(res);
          }}
        />
      )}
    </div>
  );
};

export default Users;
