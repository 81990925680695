import * as yup from "yup";

export function getBookSessionTranslated(formErrors) {
  return yup.object({
    numberOfSessions: yup
      .string()
      .required(formErrors?.numberOfSessions)
      .default("1"),
    slot: yup.string().required(formErrors?.slot),
    title: yup
      .string()
      .required(formErrors?.reqtitle)
      .min(30, formErrors?.minreqtitle)
      .max(50, formErrors?.maxreqtitle),
    areasOfInterest: yup
      .array()
      .of(
        yup.object({
          value: yup.string(),
          dataId: yup.string(),
          dataType: yup.string(),
        })
      )
      .required(formErrors?.areasOfInterest)
      .min(1, formErrors?.areasOfInterest)
      .typeError(formErrors?.areasOfInterest),
    description: yup
      .string()
      .required(formErrors?.description)
      .min(30, formErrors?.mindescription)
      .max(255, formErrors?.maxdescription),
  });
}
