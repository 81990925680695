//** GinieHealth Learner UI Screen  **//

import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext,
} from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { useRef } from "react";
import { _getImage } from "../../../common/utils/commonFunctions";
import "./myAssessment.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import CardAsses from "./CardAsses";
import { AppUrlsEnum } from "../../../enums";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AssessmentDetailsCards from "../../../components/assessmentDetails/assessmentDetailsCards";
import { AuthContext } from "../../../hooks/auth.context.provider";
import { loader } from "../../../redux-tk/slices/loader.slice";
import { GetAssessmentTranscriptByUserIdApi } from "@iguroo/ig-react-assessment-package";
import {
  getMyAssessmentThunk,
  myAssessmentData,
} from "../../../redux-tk/slices/assessment.slice";
import { getDateFromTimestamp } from "../../../helpers";
import { Pdf } from "../../UserManagement/CellRenderers";
import { Document, Page, pdfjs } from "react-pdf";
import moment from "moment";
import { FormHeading } from "../../../components/form.heading.component";

const mathScoring = {
  activeTitle: "Math scoring",
  cardPic: _getImage("mathScoring.svg"),
  percentageChange: "2.4%",
  incrementDecrement: "increase",
  graphdetails: {
    labels: [1, 2, 3, 4, 5],
    datasets: [
      {
        data: [40, 20, 60, 30, 40, 80],
        borderColor: "#b52dc8",
        backgroundColor: "none",
      },
    ],
  },
};

const calculas = {
  activeTitle: "Calculas progress",
  cardPic: _getImage("calculas.svg"),
  // description: "Calculas progress",
  percentageChange: "2.4%",
  incrementDecrement: "decrease",
  graphdetails: {
    labels: [1, 2, 3, 4, 5],
    datasets: [
      {
        data: [60, 20, 50, 30, 40, 80],
        borderColor: "#6182e7",
        backgroundColor: "none",
      },
    ],
  },
};

const MyAssessment = (props) => {
  const dispatch = useDispatch();
  const assessments = useSelector((state) => myAssessmentData(state));
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [showTranscript, setShowTranscript] = useState(false);
  const [showTranscriptAttempt, setShowTranscriptAttempt] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isGridLoading, setIsGridLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const state = location.state;
  const [type, setType] = useState(!state?.completed ? "pending" : "completed");
  const userInfo = useMemo(() => {
    return authContext?.sessionInfo;
  }, [authContext.authStatus]);

  useEffect(() => {
    const thePath = location;
    const lastItem = thePath.search.split("=");
    const search = new URLSearchParams(thePath.search);
    const transcript = search.get("tab");
    const transcriptattempt = search.get("attempt_id");
    console.log("transcript:", transcript);
    console.log("transcriptattempt:", transcriptattempt);
    const tab = localStorage.getItem("tab");
    const openTranscript = localStorage.getItem("transcriptattempt_id");
    if (transcript == "transcript" || tab == "transcript") {
      setShowTranscript(true);
      setShowAnalytics(false);
      setType("transcript");
      //localStorage.removeItem("tab");
      if (openTranscript != null) {
        setShowTranscriptAttempt(openTranscript);
        localStorage.removeItem("attempt_id");
      } else if (transcriptattempt != null) {
        setShowTranscriptAttempt(transcriptattempt);
      }
    } else {
      const pageState = thePath?.state;
      const completed = pageState?.completed;
      if (completed == "true") {
        setShowTranscript(false);
        setShowAnalytics(false);
        setType("completed");
      } else {
        setShowTranscript(false);
        setShowAnalytics(false);
        setType("pending");
      }
    }
  }, []);

  useEffect(() => {
    (async function () {
      try {
        const data = {
          userId: userInfo.sub,
          status: type === "pending" ? "ACTIVE" : "COMPLETED",
          pagesize: itemsPerPage,
          pagenumber: currentPage,
        };
        dispatch(loader(true));
        const response = await dispatch(getMyAssessmentThunk(data));
        dispatch(loader(false));
        if (response.success) {
          setRowData(response.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log("LOG FAIL error", error);
        setIsLoading(false);
      }
    })();
  }, [type, itemsPerPage, currentPage, showTranscript]);
  const buttonColor = (assessType) => {
    if (assessType === type) {
      return { backgroundColor: "#5B2AEF", color: "white" };
    } else {
      return { backgroundColor: "#F4F8FB", color: "#A3A3A3" };
    }
  };

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    if (showTranscript) {
      (async function () {
        console.log("transcriptURL");
        try {
          const d = await GetAssessmentTranscriptByUserIdApi({
            user_id: userInfo.sub,
            limit: 200,
          });
          if (d.success === true) {
            const users = d.response.data.map((user, index) => {
              const completedDate = user.completedDate
                ? moment(user.completedDate).format(
                    process.env.REACT_APP_DATETIME_FORMAT
                  )
                : "";
              if (
                user?.transcript_url === null ||
                user?.transcript_url === "" ||
                user?.transcript_url === undefined
              ) {
                setTimeout(async () => {
                  setIsGridLoading(true);
                }, 3000);
                console.log(user?.transcript_url, "transcriptURL");
              }
              console.log(user?.transcript_url, "transcriptURL");
              return {
                SI: index + 1,
                Title: user?.assessment?.title,
                Grade:
                  user?.assessment?.evaluation_type == "STANDARD" ||
                  user?.assessment?.evaluation_type == "MULTI_CHOICE" ||
                  user?.assessment?.evaluation_type == "GENIE_MULTI_CHOICE"
                    ? `${user?.details?.total_score?.toString()}%`
                    : user?.details?.overall_competency_score?.toString(),
                "Due Date": moment(
                  user?.assessment_assignment?.due_datetime
                ).format(process.env.REACT_APP_DATE_FORMAT),
                "Completed on": completedDate,
                Report: {
                  url: user?.transcript_url,
                  transcript_download: user?.assessment?.transcript_download,
                  openPopup:
                    user?.attempt_id == showTranscriptAttempt ? "show" : "hide",
                  status: "COMPLETED",
                },
              };
            });
            setRowData(users);
          } else {
          }
        } catch (error) {
          console.log("LOG FAIL error", error);
        }
      })();
    }
  }, [showTranscript, isGridLoading, setIsLoading]);

  const [columnDefs] = useState([
    {
      field: "SI",
      headerName: "S.No.",
      maxWidth: 100,
    },
    {
      field: "Title",
      filter: true,
      sortable: true,
    },
    {
      field: "Grade",
      filter: true,
      // sortable: true,
    },
    {
      field: "Due Date",
      filter: true,
      sortable: true,
    },
    {
      field: "Completed on",
      filter: true,
      sortable: true,
    },
    {
      field: "Report",
      cellRenderer: Pdf,
      filter: false,
      unSortIcon: false,
      sortable: false,
      maxWidth: 80,
    },
  ]);

  const defaultColDef = useMemo(() => ({
    flex: 1,
    floatingFilter: true,
    unSortIcon: true,
  }));

  const rowHeight = 85;

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  return (
    <>
      <div className="m-2 p-2 publisherContainer">
        <div className="mt-4 status-button-myassessment">
          <Row>
            <Col>
              <Button
                onClick={() => {
                  setIsLoading(true);
                  setType("pending");
                  setShowAnalytics(false);
                  setShowTranscript(false);
                  localStorage.removeItem("tab");
                }}
                style={buttonColor("pending")}
              >
                Pending
              </Button>
              <Button
                onClick={() => {
                  setIsLoading(true);
                  setType("completed");
                  setShowAnalytics(false);
                  setShowTranscript(false);
                  localStorage.removeItem("tab");
                }}
                style={buttonColor("completed")}
              >
                Completed
              </Button>
              {/* Analytics Tab will be implement in future */}
              {/* <Button
                onClick={() => {
                  setShowAnalytics(true);
                  setType("active");
                  setShowTranscript(false);
                  console.log(type, showAnalytics, "console");
                }}
                style={buttonColor("active")}
              >
                Analytics
              </Button> */}
              <Button
                onClick={() => {
                  setShowTranscript(true);
                  setShowAnalytics(false);
                  setType("transcript");
                  localStorage.setItem("tab", "transcript");
                }}
                style={buttonColor("transcript")}
              >
                Transcript
              </Button>
            </Col>
            {/* Search new assessments Tab will be implement in future */}
            {/* <Col className="d-flex justify-content-end">
              <Link to={AppUrlsEnum.searchAssessment}>
                <Button>
                  <img src={_getImage("searchAssessments.svg")} /> search new
                  assessments
                </Button>
              </Link>
            </Col> */}
          </Row>
        </div>
        {showTranscript ? (
          <>
            <div className="ag-theme-alpine" style={{ height: "500px" }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
                rowHeight={rowHeight}
                suppressDragLeaveHidesColumns={true}
                className="user-table-transcript"

              />
            </div>
          </>
        ) : (
          <></>
        )}

        {showAnalytics && (
          <>
            <Row className="d-flex">
              <Col sm={4} className="mt-4">
                <AssessmentDetailsCards
                  activeTitle={mathScoring.activeTitle}
                  cardPic={mathScoring.cardPic}
                  percentageChange={mathScoring.percentageChange}
                  className="totalUSer"
                  incrementDecrementPic={
                    mathScoring.incrementDecrement == "increase"
                      ? _getImage("userIncrement.svg")
                      : _getImage("userIncrement.svg")
                  }
                  graphdetails={mathScoring.graphdetails}
                />
              </Col>

              <Col sm={4} className="mt-4">
                <AssessmentDetailsCards
                  activeTitle={calculas.activeTitle}
                  cardPic={calculas.cardPic}
                  percentageChange={calculas.percentageChange}
                  className="totalEarnings"
                  incrementDecrementPic={
                    calculas.incrementDecrement == "increase"
                      ? _getImage("earningDecrease.svg")
                      : _getImage("earningDecrease.svg")
                  }
                  graphdetails={calculas.graphdetails}
                />
              </Col>
            </Row>
          </>
        )}
        {!showAnalytics && !showTranscript && (
          <>
            {!isLoading ? (
              assessments?.data?.length > 0 &&
              (type === "pending" || type === "completed") ? (
                <CardAsses
                  data={assessments?.data}
                  itemsPerPage={itemsPerPage}
                  totalRecords={assessments?.totalAssessmentCount}
                  setCurrentPage={setCurrentPage}
                  setItemsPerPage={setItemsPerPage}
                  currentPage={currentPage}
                />
              ) : (
                <FormHeading className="text-center mt-4">
                  No Data Found
                </FormHeading>
              )
            ) : (
              <FormHeading className="text-center mt-4">Loading...</FormHeading>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default MyAssessment;
