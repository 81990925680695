//** Iguroo & GinieHealth,IconLabel Component,can re-use & add any type of icon over using this component **//

import React from "react";
import { _getImage } from "../../common/utils/commonFunctions";

export function IconLabelComponent(props) {
  const { text, text_2, className, svgName, onClick } = props;
  return (
    <div
      role="button"
      className={
        className
          ? className
          : "ig-icon-label text-center d-flex justify-content-center align-items-end mx-auto"
      }
      onClick={onClick}
    >
      <div className="d-inline">
        {text_2 ? text_2 : ""}
        {svgName ? (
          <>
            <img
              className="ig-icon-label-img"
              src={_getImage(svgName ? svgName : "")}
            />{" "}
          </>
        ) : (
          ""
        )}
        {props.children?.length > 0 ? props.children : text}
      </div>
    </div>
  );
}
