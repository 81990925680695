import { axiosAwsIam, axiosStaticData } from "../axios.service";
import { IGUROO_PROFILE_DATA, IGURU_DEV_URL } from "../../config";
import {
  GET_USER_ALL,
  POST_MENTOR_EDU,
  POST_MENTOR_WORK_EXP,
  POST_USER,
  POST_USER_AVAILABILITY,
  POST_USER_RATES,
  GET_USER_AVAILABILITY,
  STATIC_ALL_DATA,
  STATIC_DATA_DATA_TYPE,
  GET_USER_PROFILE,
  MENTOR_PUBLIC_PROFILE,
  GET_LIST_USER_PROFILE,
} from "../../urlConst";
import { getProfileObject } from "../../../helpers/api.helpers";

export async function PostAvailability(userId, avails) {
  try {
    const body = avails.map((val) => {
      const av = {
        userId,
        availability: val.availability,
        from: val.timeRange.startTime,
        to: val.timeRange.endTime,
      };
      if (val.id) av.id = val.id;
      return av;
    });
    const URL = POST_USER_AVAILABILITY;
    const response = await axiosStaticData.post(URL, body);
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function PostWorkExp(userId, workExp) {
  try {
    const body = workExp.map((val) => {
      const av = {
        userId,
        role: val.role,
        company: val.company,
        year: val.year,
      };
      if (val.id) av.id = val.id;
      return av;
    });
    const URL = POST_MENTOR_WORK_EXP;
    const response = await axiosStaticData.post(URL, body);
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function GetUser(userId, getFullResponse = false) {
  try {
    console.log("fetching for user ID", userId);
    if (!userId) {
      throw new Error("User id is null");
    }
    const URL = GET_USER_ALL;
    const params = {
      userId: userId,
    };
    const response = await axiosStaticData.get(URL, {
      params: params,
    });

    if (response?.data?.data?.profile?.assessmentlanguage) {
      console.log("LOG AL inside", response.data);

      localStorage.setItem(
        "assessmentlanguage",
        response.data.data.profile.assessmentlanguage
      );
      localStorage.setItem("assessmentlanguageFlag", false);
    } else {
      localStorage.setItem("assessmentlanguageFlag", true);
    }
    //console.log("LOG AL selected", localStorage.getItem("assessmentlanguage"));
    if (getFullResponse) return response;
    return response.data;
  } catch (err) {
    console.log("fetching for user err", err);
    throw err;
  }
}

export async function GetUserProfile(userId) {
  try {
    const URL = GET_USER_PROFILE;
    const params = {
      userId,
    };
    const response = await axiosStaticData.get(URL, {
      params,
    });
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function GetListOfUserProfiles(userIds) {
  try {
    const URL = GET_LIST_USER_PROFILE;
    const body = {
      userIds,
    };
    const response = await axiosStaticData.post(URL, body);
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function GetUserAvailability(userId) {
  try {
    const URL = GET_USER_AVAILABILITY;
    const params = {
      userId,
    };
    const response = await axiosStaticData.get(URL, {
      params,
    });
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function GetWorkExp(userId) {
  try {
    const URL = POST_MENTOR_WORK_EXP;
    const params = {
      userId,
    };
    const response = await axiosStaticData.get(URL, {
      params,
    });
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function GetEducation(userId) {
  try {
    const URL = POST_MENTOR_EDU;
    const params = {
      userId,
    };
    console.log(POST_MENTOR_EDU,'call')
    const response = await axiosStaticData.get(URL, {
      params,
    });
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function GetAvailability(userId) {
  try {
    const URL = POST_USER_AVAILABILITY;
    const params = {
      userId: userId,
    };
    const response = await axiosStaticData.get(URL, {
      params: params,
    });
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function GetRates(userId) {
  try {
    const URL = POST_USER_RATES;
    const params = {
      userId,
    };
    const response = await axiosStaticData.get(URL, {
      params,
    });
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function DeleteWorkExp(id) {
  try {
    const URL = POST_MENTOR_WORK_EXP;
    const params = {
      id,
    };
    const response = await axiosStaticData.delete(URL, {
      params,
    });
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function DeleteEducation(id) {
  try {
    const URL = POST_MENTOR_EDU;
    const params = {
      id,
    };
    const response = await axiosStaticData.delete(URL, {
      params,
    });
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function DeleteAvailability(id) {
  try {
    const URL = POST_USER_AVAILABILITY;
    const params = {
      id,
    };
    const response = await axiosStaticData.delete(URL, {
      params,
    });
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function PostEduExp(userId, edu) {
  try {
    const body = edu.map((val) => {
      const av = {
        userId,
        instituteName: val.instituteName,
        courseName: val.courseName ? val.courseName : {},
        specialization: val.specialization,
        year: val.year,
      };
      if (val.id) av.id = val.id;
      return av;
    });
    const URL = POST_MENTOR_EDU;
    const response = await axiosStaticData.post(URL, body);
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function PostUserProfile(profile, userId) {
  try {
    const body = getProfileObject(profile, userId);
    const URL = POST_USER;
    console.log(POST_USER, "response call start");

    const response = await axiosStaticData.post(URL, profile);
    console.log(response, "response call start");
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function PostRates(rates) {
  try {
    const URL = POST_USER_RATES;
    const response = await axiosStaticData.post(URL, rates);
    return response.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function GetPublicMentorAsync(mentorId) {
  try {
    const URL = MENTOR_PUBLIC_PROFILE;
    const response = await axiosAwsIam.get(URL, {
      params: {
        userId: mentorId,
      },
      isPublicProfile: true,
    });
    //console.log("public mentor api response pre",response.data);
    return response.data?.data;
  } catch (err) {
    throw err;
  }
}
