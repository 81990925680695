//** Iguroo Assessment Rating Component**//

import React from "react";

const Rating = ({ text, subText, className, hideRate, value, total }) => {
  const values = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div className={`m-0 p-0 d-flex w-100 a_rating_main_div ${className}`}>
      <div className="flex-grow-1">
        {text ? <span className="a_rating_text m-2">{text} : </span> : <></>}
        <span className="a_rating_text m-2">{subText}</span>
      </div>
      {!hideRate && (
        <div className="a_rating d-flex">
          {values.map((val) =>
            val == Math.ceil(value / (total ? total : 10)) ? (
              <div className={`a_rating_div ${"a_rating_div_2"}`}>{val}</div>
            ) : (
              <div className={`a_rating_div`}>{val}</div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default Rating;
