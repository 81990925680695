//** GinieHealth Extend Action assessment Popup in Aggrid  **//

import React, { useEffect, useState } from "react";
import "./supervisor.scss";
import { Modal, ModalTitle, Row, Col, Form } from "react-bootstrap";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { SubmitButton } from "../../components/submit.button";
import { useIgurooTranslations } from "../../hooks";
import { _getImage } from "../../common/utils/commonFunctions";
import { FormErrorComponent } from "../../components/form.error.component";
import { DatePicker } from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import moment from "moment";
import {
  ExtendedDue_dateApi,
  GetAssessmentsApi,
} from "@iguroo/ig-react-assessment-package";
import { useGetAssessments } from "../../hooks/useGetAssessments";
import Toaster from "../../components/toaster";
import { toast } from "react-toastify";

const ExtendPopup = (props) => {
  const { genieLabsTranslations } = useIgurooTranslations();
  const [test, setTest] = useSearchParams();
  const assessment_id = test.get("assessmentId");
  const version = test.get("versionId");
  const { assessments, fetchInitialAssessments } = useGetAssessments({
    apiFunction: GetAssessmentsApi,
    initialQuery: { assessment_id, version },
  });

  useEffect(() => {
    fetchInitialAssessments({ assessment_id, version });
  }, []);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});

  const [dueDate, setDueDate] = useState(false);
  const [datechecking1, setDatechecking1] = useState();
  const [newValue, setNewValue] = useState(new Date());
  const [wrongDate, setWrongDate] = useState(false);
  const [invalidDate, setInvalidDate] = useState(false);
  const [dueDateTime, setDueDateTime] = useState("");
  const [dueDateDefault, setdueDateDefault] = useState();
  const [availableBeforeDueDate, setAvailableBeforeDueDate] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [days, setDays] = useState("");
  const [validDueDateFlag, setValidDueDateFlag] = useState(false);
  const [assessment_version_id, setassessment_version_id] = useState("");
  const [btnClickedFlag, setBtnClickedFlag] = useState(false);
  const currentTime = Math.floor(Date.now() / 1000);
  const today = new Date().getTime();

  useEffect(() => {
    console.log(assessments, assessment_id, version, props, "validTillDate");
    const validTillDate = assessments.map((item, index) => {
      console.log(item, "validTillDate");
      const datechecking = item.valid_till;
      setdueDateDefault(datechecking);
      setassessment_version_id(item.assessment_version_id);
      const tomorrow = new Date(moment.unix(datechecking));
      setDatechecking1(tomorrow);
      setNewValue(tomorrow);
      console.log(
        "LOGGG13 setDatechecking1",
        newValue,
        datechecking1,
        tomorrow
      );

      const convertingData = moment.unix(datechecking).format("YYYY-MM-DD");
      setValue("dateField", convertingData);
      setValue("timeField", "23:59");

      var currDate = new Date(new Date().getTime());
      var currDate2 = new Date((datechecking + 86340) * 1000);

      var time_st = moment.unix(currDate2);
      var v = time_st.diff(currDate, "months");

      //console.log("LOGGG12 datechecking = ", v,  currDate, currDate2);
      const diffMain = Math.ceil((currDate2 - currDate) / (1000 * 3600 * 24));
      console.log("LOGGG12  CURR DATE DIFF", diffMain);

      if (diffMain < 2) {
        setInvalidDate(true);
        setIsSubmitting(false);
      } else {
        setInvalidDate(false);
      }
      if (currentTime < datechecking) {
        setDueDate(false);
      } else {
        setDueDate(true);
      }
    });
  }, [assessments]);

  const { show, onHide } = props;
  const [showPopup, setShowPopup] = useState(false);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate());
  const formattedTomorrow = tomorrow.toISOString().split("T")[0];

  const handleClosePopup = () => {
    setShowPopup(false);
    onHide(true);
    props?.updateList();
    // console.log("updateList")
  };

  useEffect(() => {
    setShowPopup(show);
  }, [show]);

  useEffect(() => {
    (async () => {
      if (validDueDateFlag === true) {
        if (wrongDate === false && invalidDate === false) {
          callApi();
        } else {
          setIsSubmitting(false);
        }
      } else {
        setIsSubmitting(false);
      }
    })();
  }, [validDueDateFlag, wrongDate]);

  const submit = (data) => {
    setIsSubmitting(true);
    const dd = ""
      .concat(control._fields.dateField._f.value)
      .concat(" ")
      .concat(control._fields.timeField._f.value);
    const datee = new Date(dd).getFullYear();
    console.log(
      "LOG setWrongDate CreateUserAssignmentApi ",
      newValue,
      "dddd" + dd,
      "datee" + datee,
      data
    );
    if (Number.isNaN(datee) || datee == "" || datee === null || datee < 2023) {
      console.log("LOG setWrongDate IN");
      setWrongDate(true);
      setIsSubmitting(false);
    } else {
      setWrongDate(false);
      const endOfDay = moment(newValue).endOf("day");
      setDueDateTime(endOfDay.valueOf());
      console.log(
        "LOG CURR DATE DIFF INSIDE CreateUserAssignmentApi ",
        validDueDateFlag,
        wrongDate,
        invalidDate
      );
      setAvailableBeforeDueDate(days.trim());
      var myDiffDate = new Date(
        new Date(dd).getTime() - days * 24 * 60 * 60 * 1000
      );
      var currDate = new Date(new Date().getTime());
      console.log(
        "LOG CreateUserAssignmentApi =>>",
        dd,
        newValue,
        new Date(newValue).getTime(),
        moment(newValue.getTime()).format("mm/dd/yyyy")
      );
      let timeDifference = myDiffDate.getTime() - currDate;
      const diffMain = Math.ceil(timeDifference / (1000 * 3600 * 24));
      if (diffMain > 0) {
        // Valid Due Date
        console.log(
          "LOG CURR DATE DIFF INSIDE",
          diffMain,
          days,
          validDueDateFlag,
          wrongDate,
          invalidDate
        );
        setValidDueDateFlag(true);
        setBtnClickedFlag(false);
      } else {
        console.log("LOG CURR DATE DIFF OUTSIDE", diffMain);
        setBtnClickedFlag(true);
        setValidDueDateFlag(false);
      }
      props?.updateList();
      // console.log("updateList")
    }
  };

  const callApi = () => {
    (async () => {
      try {
        console.log("LOG Api Call =>> CreateUserAssignmentApi", props);
        const d = await ExtendedDue_dateApi({
          assessment_version_id: props?.assessment_version_id,
          due_datetime: props?.validTill,
          new_duedate: moment(newValue).endOf("day").valueOf(),
          userId: props?.assessments,
        });
        if (d.success === true) {
          setValue("timeField", "23:59");
          setAvailableBeforeDueDate(0);
          setValidDueDateFlag(false);
          setBtnClickedFlag(false);
          setDueDateTime("");
          setIsSubmitting(false);
          props?.updateList();

          handleClosePopup();
          props.LoadPage();
          Toaster(toast.success, "Extendend Successfully");
          // console.log("updateList")
        } else {
          setAvailableBeforeDueDate(0);
          setValidDueDateFlag(false);
          props?.updateList();

          props.LoadPage();
          setIsSubmitting(false);
          Toaster(toast.success, "Extendend Successfully");
        }
      } catch (error) {
        Toaster(toast.error, "Could not update");
        console.log("LOG FAIL error 165", error);
        setAvailableBeforeDueDate(0);
        setValidDueDateFlag(false);
        props?.updateList();

        handleClosePopup();
        setIsSubmitting(false);
      }
    })();
  };
  return (
    <>
      <Modal size={"lg"} show={showPopup} onHide={handleClosePopup}>
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <ModalTitle className="modal-title w-100">
            {genieLabsTranslations?.extendAssessment}
          </ModalTitle>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <Form onSubmit={handleSubmit(submit)}>
            <Row>
              <Col>
                <div className="email-heading mt-3">
                  {genieLabsTranslations?.expireDate}
                </div>
                <Row className="d-flex align-items-center">
                  <Col xs={8} sm={9} md={5}>
                    <Controller
                      name={`dateField`}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name },
                      }) => (
                        <DatePicker
                          calendarClassName="react-calendar"
                          className="mt-4"
                          name="date"
                          format={process.env.REACT_APP_DATEPICKER_FORMAT}
                          onChange={(e) => {
                            if (e != null) setNewValue(e);
                          }}
                          value={newValue}
                          minDate={tomorrow}
                          maxDate={datechecking1}
                          clearIcon={null}
                        />
                      )}
                    />
                    <FormErrorComponent>
                      {errors.dateField && "Enter the correct date"}
                    </FormErrorComponent>
                    {wrongDate || invalidDate ? (
                      <FormErrorComponent>
                        Date should be greater than today's date
                      </FormErrorComponent>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col xs={4} sm={3} className="mt-4">
                    <img src={_getImage("date_logo.svg")} alt="Date" />
                  </Col>
                </Row>
              </Col>
              <Col>
                <div className="email-heading mt-3">
                  {genieLabsTranslations?.endDate}
                </div>
                <Row className="d-flex align-items-center">
                  <Col xs={8} sm={9}>
                    <Controller
                      name={`timeField`}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name },
                      }) => (
                        <CustomInputField
                          className="timeFieldPopup"
                          type="time"
                          hookOnChange={onChange}
                          hookOnBlur={onBlur}
                          hookValue={value}
                          name={name}
                          disabled={true}
                        />
                      )}
                    />
                    <FormErrorComponent>
                      {errors?.timeField && errors.timeField?.message}
                    </FormErrorComponent>
                  </Col>
                  <Col xs={4} sm={3} className="mt-3">
                    <img src={_getImage("info_icon.svg")} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="mb-4 mt-3 d-flex align-items-center justify-content-center">
              <SubmitButton
                variant="primary"
                type="submit"
                text={genieLabsTranslations?.saveBtn}
                disabled={isSubmitting}
              />
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ExtendPopup;
