//** GinieHealth Learner Login,Question Header New UI component**//

import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Heading } from "../heading.component";
import { _getImage, _getVideoUrl } from "../../common/utils/commonFunctions";
import { useDispatch } from "react-redux";
import "./styles.css";
import "plyr-react/plyr.css";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { changeGLTime } from "../../redux-tk/slices/genieLabs.currentQuestion";

const QHeaderNew = (props) => {
  const dispatch = useDispatch();
  const { genieLabsAssessmentTranslations } = useIgurooTranslations();
  const Ref = useRef(null);
  // The state for our timer
  const [timer, setTimer] = useState();
  const [timerElapsed, setTimerElapsed] = useState("00:00:00");
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    console.log("LOG getTimeRemaining 1655", total, hours, minutes, seconds);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    console.log("answersss 16555", seconds, props.duration);
    if (total >= 0) {
      if (props.duration <= 60) {
        dispatch(changeGLTime(total));
        setTimer(minutes + 1 + " mins " + seconds + " sec ");
        setTimerElapsed(props.duration - minutes + " mins");
      } else {
        dispatch(changeGLTime(total));
        setTimer(hours * 60 + (minutes + 1) + " mins");
        setTimerElapsed(props.duration - (hours * 60 + minutes) + " mins");
      }
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next

    dispatch(changeGLTime(0));
    setTimer("0 mins");
    setTimerElapsed("0 mins");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + props.duration * 60);
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);
  console.log("answersss 1655", props);

  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  );

  console.log(props, "questions");
  return (
    <Container>
      <Row>
        <Col md="12" className="mt-4 text-center">
          <Heading className="headingDashboard textcolor_primary">
            {props.title}
          </Heading>
        </Col>
      </Row>

      <Row className="text-center mt-4 mb-4 justify-content-center">
        <Col sm="12" md="1" className="d-grid ">
          <Row className="mb-2">
            {
              <img
                height={20}
                width={20}
                src={_getImage("questCount.svg")}
                className="icon"
              />
            }
          </Row>
        </Col>

        <Col sm="12" md="2" className="d-grid ">
          <Row className="mb-2 textcolor_primary weight_add">
            {props.currentQuestion}/{props.question}
          </Row>
        </Col>

        <Col sm="12" md="1" className="d-grid ">
          <Row className="mb-2">
            {
              <img
                height={20}
                width={20}
                src={_getImage("section.svg")}
                className="icon"
              />
            }
          </Row>
        </Col>

        <Col sm="12" md="1" className="d-grid ">
          <Row className="mb-2 textcolor_primary">Section</Row>
        </Col>
        <Col sm="12" md="3" className="d-grid " role={"button"}>
          <Row className="mb-2 textcolor_primary weight_add">01/01</Row>
        </Col>
        <Col sm="12" md="1" className="d-grid ">
          <Row className="mb-2">
            {
              <img
                height={20}
                width={20}
                src={_getImage("pause_.svg")}
                className="icon"
              />
            }
          </Row>
        </Col>

        <Col sm="12" md="2" className="d-grid " role={"button"}>
          <Row className="mb-2 textcolor_red" onClick={props?.pauseClick}>
            {/* {props?.duration ? timer : ""} */}
            {genieLabsAssessmentTranslations?.pause}
          </Row>
        </Col>
        <Row className="mt-3 mb-4">
          <ColoredLine color="#6c757d" />
        </Row>
      </Row>
    </Container>
  );
};

export default QHeaderNew;
