//** Iguroo Creating User Slice **//

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createdata: {},
};

export const CreateUserSlice = createSlice({
  name: "createUserData",
  initialState,
  reducers: {
    updateCreateUser: (state, action) => {
      state.createdata = action.payload;
    },
  },
});

export const { updateCreateUser } = CreateUserSlice.actions;

export const createUserValue = (state) => state.createdata;

export default CreateUserSlice.reducer;
