//** GinieHealth Assessment File or Video or Image can Upload,can re-use this common Component required Field **//

import React, { useState } from "react";
import { ActivityIndicator } from "react-native-web";
import { useFileUploader } from "../hooks/useFileUploader";
require(`../css/${process.env.REACT_APP_CSS_INPUT}`);

function AssessmentFileUploader(props) {
  const { uploading, uploadedFiles, deleteFile, setFileToUpload } =
    useFileUploader(props);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const fileHandler = async (e) => {
    console.log("uploadedFiles", uploadedFiles);
    if (uploadedFiles.length > 0) {
      console.log("deleting file");
      await deleteFile(uploadedFiles[0]);
      console.log("file deleted");
    }
    console.log("uploadedFiles after if", uploadedFiles);
    setSelectedVideo(e.target.files[0]);
    setFileToUpload(e);
  };

  return (
    <>
      <div className="u-video-name pt-2 ps-3">
        {selectedVideo ? selectedVideo?.name : "No Selected File"}
      </div>
      <input
        type="file"
        accept={props.uploadFileTypes ? props.uploadFileTypes : "video/*"}
        onChange={fileHandler}
        id={props.inputId ? props.inputId : "file-uploader"}
        style={{ display: "none" }}
      />
      <div className="u-video-btn py-2 px-4">
        <label
          htmlFor={props.inputId ? props.inputId : "file-uploader"}
          className="fs-16"
        >
          Upload
          {uploading && (
            <ActivityIndicator
              animating={uploading}
              color={"rgb(74, 0, 144)"}
            />
          )}
        </label>
      </div>
    </>
  );
}

export default AssessmentFileUploader;
