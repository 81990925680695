//** Onboarding RateScreen Page **//

import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Row, Col, Button, Alert, Container } from "react-bootstrap";
import { getRatesObject, getStepsObject } from "../../helpers";
import { useNavigate } from "react-router-dom";
import { getRateFormTranslated } from "../../FormSchemas";
import { MentorSetupSidebar } from "../../components/mentor.setup.sidebar";
import { MentorOnboardingLabels } from "../../res/localization";
import { SubmitButton } from "../../components/submit.button";
import { _getImage } from "../../common/utils/commonFunctions";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { CustomSelect } from "../../components/CustomInput/custom.select";
import { FormErrorComponent } from "../../components/form.error.component";
import { Heading } from "../../components/heading.component";
import { AlertComponent } from "../../components/alert.component";
import { MentorRate } from "../../components/mentor.rate.component";
import { AuthContext } from "../../hooks/auth.context.provider";
import { PostRates } from "../../services/axios/MentorOnbaording";
import { useDispatch, useSelector } from "react-redux";
import {
  rateDataAction,
  rateDataValue,
} from "../../redux-tk/slices/mentor.onboarding.data";
import { loader } from "../../redux-tk/slices/loader.slice";
import { GetDataType } from "../../services/axios/staticData";
import Toaster from "../../components/toaster";
import { toast } from "react-toastify";
import {
  getUserAsyncThunk,
  postUserProfileAsyncThunk,
  userProfileValue,
} from "../../redux-tk/slices/user.slice";
import { OnboardingSteps, ProfileType, RatesTimeFrame } from "../../enums";
import { Popup } from "../../components/CustomInput/popup";
import { OnboardingHeading } from "../../components/onboarding.heading.component";
import { AppUrlsEnum } from "../../enums";
import { showPopupflag } from "../../redux-tk/slices/showPopupflag.slice";
require(`../../css/${process.env.REACT_APP_CSS_MENTOR}`);

export default function MentorRateScreen({ settings }) {
  const {
    mentorOnboardingLabels,
    formElementLabels,
    formErrors,
    headingTranslations,
    headerFooterTranslations,
  } = useIgurooTranslations();
  const authContext = useContext(AuthContext);
  const dispatch = useDispatch();
  const selectedValue = useSelector((state) => rateDataValue(state));
  const userSelectedValue = useSelector((state) => userProfileValue(state));

  console.log("rateDataValue", userSelectedValue);
  const {
    control,
    handleSubmit,
    watch,
    setError,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: { ...userSelectedValue },
    resolver: yupResolver(getRateFormTranslated(formErrors)),
  });

  const sessionCharge = "sessionCharge";
  const monthlyCharge = "monthlyCharge";
  const monthlyChargeFree = "monthlyChargeFree";
  const quaterlyChargeFree = "quarterlyChargeFree";
  const yearlyChargeFree = "yearlyChargeFree";
  const quarterlyCharge = "quarterlyCharge";
  const yearlyCharge = "yearCharge";
  const switchEnableStr = "SwitchEnable";
  const [monthlySwitch, setMontlySwitch] = useState(false);
  const [yearlySwitch, setYearlySwitch] = useState(false);
  const [quarterlySwitch, setQuarterlySwitch] = useState(false);

  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      dispatch(loader(true));
      console.log("data", data);
      const finalData = getRatesObject(data, authContext.sessionInfo.sub);
      console.log("final", finalData);
      const result = await PostRates(finalData);
      console.log("result", result);
      dispatch(rateDataAction(data));
      dispatch(loader(false));
      dispatch(getUserAsyncThunk({ userId: authContext.sessionInfo.sub }));
      Toaster(toast.success, headerFooterTranslations.toasterMsgOnboarding);
      if (!settings) {
        dispatch(
          postUserProfileAsyncThunk(
            getStepsObject(
              OnboardingSteps[ProfileType.mentor][6].value,
              authContext.sessionInfo.sub
            )
          )
        );
        setShowPopup(true);
      }
    } catch (err) {
      console.log("err", err);
      setError("apiError", { message: err.message });
      Toaster(toast.error, err?.response?.data?.message);
      dispatch(loader(false));
    }
  };
  const [sessionInterval, setSessionInterval] = useState([]);
  const [currencyDetail, SetCurrencyDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (isDirty) {
      (async function () {
        dispatch(showPopupflag(true));
      })();
    }
  }, [isDirty]);

  useEffect(() => {
    (async function () {
      dispatch(loader(true));
      setIsLoading(true);
      const interval = await GetDataType("preferredtimeinterval", "english");
      const currency = await GetDataType("currency", "english");
      setSessionInterval(interval);
      SetCurrencyDetail(currency);
      dispatch(loader(false));
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async function () {
      if (userSelectedValue?.rates) {
        setValue("time", userSelectedValue?.rates[0].time);
        setValue("currency", userSelectedValue?.rates[0].curreny);
        const rateperSession = userSelectedValue?.rates.filter(
          (value) => value.timeFrame === RatesTimeFrame.perSession
        );
        const ratemonthly = userSelectedValue?.rates.filter(
          (value) => value.timeFrame === RatesTimeFrame.monthly
        );
        const ratequarterly = userSelectedValue?.rates.filter(
          (value) => value.timeFrame === RatesTimeFrame.quarterly
        );
        const rateyearly = userSelectedValue?.rates.filter(
          (value) => value.timeFrame === RatesTimeFrame.yearly
        );

        const sessionCharge = rateperSession ? rateperSession[0]?.rate : 0;
        const monthlyCharge = ratemonthly ? ratemonthly[0]?.rate : 0;
        const yearCharge = rateyearly ? rateyearly[0]?.rate : 0;
        const quarterlyCharge = ratequarterly ? ratequarterly[0]?.rate : 0;
        const monthlySwitches = ratemonthly[0]?.rate ? true : false;
        const yearlySwitches = rateyearly[0]?.rate ? true : false;
        const quarterlySwitches = ratequarterly[0]?.rate ? true : false;
        console.log(
          "console monthlySwitches",
          monthlySwitches,
          yearlySwitches,
          quarterlySwitches
        );
        setMontlySwitch(monthlySwitches);
        setQuarterlySwitch(quarterlySwitches);
        setYearlySwitch(yearlySwitches);
        setValue("monthlyChargeSwitchEnable", monthlySwitches);
        setValue("quarterlyChargeSwitchEnable", quarterlySwitches);
        setValue("yearChargeSwitchEnable", yearlySwitches);
        setValue("sessionCharge", sessionCharge);
        setValue("monthlyCharge", monthlyCharge);
        setValue("yearCharge", yearCharge);
        setValue("quarterlyCharge", quarterlyCharge);
        setValue("monthlyChargeFree", ratemonthly[0]?.isFirstFree);
        setValue("quarterlyChargeFree", ratequarterly[0]?.isFirstFree);
        setValue("quarterlyCharge", ratequarterly[0]?.rate);
        setValue("monthlyChargeFree", ratemonthly[0]?.isFirstFree);
        setValue("quarterlyChargeFree", ratequarterly[0]?.isFirstFree);
        setValue("quarterlyCharge", ratequarterly[0]?.rate);
        setValue("yearlyChargeFree", rateyearly[0]?.isFirstFree);
      } else {
        setValue("currency", currencyDetail[0]?.value);
        setValue("sessionCharge", 0);
        setValue("monthlyCharge", 0);
        setValue("yearCharge", 0);
        setValue("quarterlyCharge", 0);
      }
    })();
  }, [currencyDetail]);
  //console.log("f",formElementLabels);
  function handleGotIt() {
    authContext.signOut(dispatch, navigate);
  }
  return (
    <div>
      {!settings && <OnboardingHeading profileType={ProfileType.mentor} />}
      <Container className="px-3">
        <Row>
          <Col md="12" lg={settings ? 12 : 9}>
            {!settings && (
              <>
                <Heading>{headingTranslations?.mentorRate}</Heading>
                <AlertComponent showIcon={true}>
                  {headingTranslations?.mentorRateScreenAlert}
                </AlertComponent>
              </>
            )}
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md="12">
                  <Form.Group>
                    <Row>
                      <Col md="6" className="mt-3">
                        <Form.Label className="formLabel">
                          {MentorOnboardingLabels.sessionInterval}
                        </Form.Label>
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <CustomSelect
                              className="selectStyle"
                              opLabel="value"
                              opValue="value"
                              items={sessionInterval}
                              onReactHookFormBlur={onBlur}
                              onReactHookFormChange={onChange}
                              value={value}
                              isLoading={isLoading}
                              placeholder={
                                formElementLabels?.placeHolders
                                  ?.selectSessionInterval
                              }
                            />
                          )}
                          name="time"
                        />
                        <FormErrorComponent>
                          {errors.time && errors.time?.message}
                        </FormErrorComponent>
                      </Col>

                      <Col className="mt-3">
                        <Form.Label className="formLabel">
                          {MentorOnboardingLabels.currency}
                        </Form.Label>
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <CustomSelect
                              className="selectStyle"
                              opLabel="value"
                              opValue="value"
                              items={currencyDetail}
                              onReactHookFormBlur={onBlur}
                              onReactHookFormChange={onChange}
                              value={value}
                              isLoading={isLoading}
                              placeholder={
                                formElementLabels?.placeHolders?.selectCurrency
                              }
                              defaultValue={"INR"}
                            />
                          )}
                          name="currency"
                        />
                        <FormErrorComponent>
                          {errors.currency && errors.currency?.message}
                        </FormErrorComponent>
                      </Col>
                    </Row>
                    <MentorRate
                      control={control}
                      hookWatch={watch}
                      inputLabel={MentorOnboardingLabels.sessionCharge}
                      inputName={sessionCharge}
                      inputPlaceholder={
                        formElementLabels?.placeHolders?.enterOneSessionRate
                      }
                      freesSessionNote={
                        mentorOnboardingLabels?.oneSessionLeaveZero
                      }
                      isFirstFreeEnabled={false}
                      isSwitchEnabled={false}
                      errors={errors}
                      placeholder={formElementLabels?.placeHolders?.language}
                    />

                    <MentorRate
                      control={control}
                      hookWatch={watch}
                      inputLabel={MentorOnboardingLabels.monthlyRate}
                      inputName={monthlyCharge}
                      inputPlaceholder={
                        formElementLabels?.placeHolders?.enterMonthlySessionRate
                      }
                      isFirstFreeEnabled={true}
                      freeFirstName={monthlyChargeFree}
                      freeFirstLabel={MentorOnboardingLabels.freeSession}
                      isSwitchEnabled={true}
                      switchEnabledName={`${monthlyCharge}${switchEnableStr}`}
                      switchDefaultValue={monthlySwitch}
                      errors={errors}
                      placeholder={formElementLabels?.placeHolders?.language}
                    />

                    <MentorRate
                      control={control}
                      hookWatch={watch}
                      inputLabel={MentorOnboardingLabels.quaterlyRate}
                      inputName={quarterlyCharge}
                      inputPlaceholder={
                        formElementLabels?.placeHolders
                          ?.enterQuarterlySessionRate
                      }
                      isFirstFreeEnabled={true}
                      freeFirstName={quaterlyChargeFree}
                      freeFirstLabel={MentorOnboardingLabels.freeSession}
                      isSwitchEnabled={true}
                      switchEnabledName={`${quarterlyCharge}${switchEnableStr}`}
                      switchDefaultValue={quarterlySwitch}
                      errors={errors}
                      placeholder={formElementLabels?.placeHolders?.language}
                    />

                    <MentorRate
                      control={control}
                      hookWatch={watch}
                      inputLabel={MentorOnboardingLabels.anualRate}
                      inputName={yearlyCharge}
                      inputPlaceholder={
                        formElementLabels?.placeHolders?.enterYearlySessionRate
                      }
                      isFirstFreeEnabled={true}
                      freeFirstName={yearlyChargeFree}
                      freeFirstLabel={MentorOnboardingLabels.freeSession}
                      isSwitchEnabled={true}
                      switchEnabledName={`${yearlyCharge}${switchEnableStr}`}
                      switchDefaultValue={yearlySwitch}
                      errors={errors}
                      placeholder={formElementLabels?.placeHolders?.language}
                    />
                  </Form.Group>

                  <Row className="mt-1 mb-3">
                    <FormErrorComponent>
                      {errors.apiError && errors.apiError?.message}
                    </FormErrorComponent>
                  </Row>

                  <hr />
                  <Row className="text-end mb-4 justify-content-end save-btn-end">
                    <Col sm="12" md="2" className="d-grid ">
                      <SubmitButton text={formElementLabels?.save} />
                      <Popup
                        closeOnBackClick={false}
                        showPopup={showPopup}
                        type="popup"
                        text={formElementLabels?.buttonSave}
                        url={AppUrlsEnum.mentorDashboard}
                        gotIt={handleGotIt}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
          {!settings && (
            <Col>
              {mentorOnboardingLabels?.rightSetupModule?.mentor && (
                <MentorSetupSidebar
                  typeToUse={mentorOnboardingLabels?.rightSetupModule?.mentor}
                  active={
                    mentorOnboardingLabels?.rightSetupModule?.mentor?.values
                      ?.rates
                  }
                />
              )}
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}
