//** Iguroo Mentor Login can view this Assessment Report Popup,can re-use this common Component required Field **//

import React from "react";
import { Modal } from "react-bootstrap";
import AssessmentReports from "../screens/AssessmentReports/AssessmentReports";
import { useIgurooTranslations } from "../hooks/use.iguroo.translation";
import { _getImage } from "../common/utils/commonFunctions";
import { useState, useEffect } from "react";
import { SubmitButton } from "./submit.button";
import "./category.component.scss";
import { useTranslation } from "react-i18next";
import { Languages } from "../enums";

export const AssessmentPopup = (props) => {
  const { reportAssessmentTranslations, formElementLabels } =
    useIgurooTranslations();
  const [showPopup, setShowPopup] = useState(false);
  const handleClosePopup = () => {
    setShowPopup(false);
    onHide();
  };
  const { show, onHide, userId, attemptId, userType } = props;
  console.log("props:", props);
  console.log("attemptId:", attemptId);

  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(Languages.english);
  });
  console.log("checking for usertype in popup", userType);

  return (
    <>
      <Modal
        size={"lg"}
        show={show}
        onHide={() => {
          return onHide(false);
        }}
        className="assess-popup"
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {reportAssessmentTranslations.selfAssessment}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="assess-body custom-body no-padding">
          <AssessmentReports
            userId={userId}
            attemptId={attemptId}
            languageFlag={true}
            userType={userType}
          />
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton
            text={formElementLabels.close}
            onClick={handleClosePopup}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};
