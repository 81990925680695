//** Mentee Basic Profile Page **//

import React, { useContext, useEffect, useState } from "react";
import { _getImage } from "../../../common/utils/commonFunctions";
import "react-toastify/dist/ReactToastify.css";
import "../../../assets/fonts/Manrope.ttf";
import {
  getCities,
  getCountryInfo,
  getState,
} from "../../../services/menteeServices";
import FileUploader from "../../../components/file.upload";
import Toaster from "../../../components/toaster";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import UserProfileModal from "../../../components/userProfile/user.profile.modal";
import { useIgurooTranslations } from "../../../hooks/use.iguroo.translation";
import { MentorSetupSidebar } from "../../../components/mentor.setup.sidebar";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loader } from "../../../redux-tk/slices/loader.slice";
import { GetDataType, getTestData } from "../../../services/axios/staticData";
import { CustomInputField } from "../../../components/CustomInput/customInputField";
import { getmenteeBasicFormTranslated } from "../../../FormSchemas";
import { SubmitButton } from "../../../components/submit.button";
import { AuthContext } from "../../../hooks/auth.context.provider";
import { AppUrlsEnum, ProfileType } from "../../../enums";
import { getGenderFromId, getProfileObject } from "../../../helpers";
import { FormHeading } from "../../../components/form.heading.component";
import CustomRadioOptions from "../../../components/CustomRadioOptions";
import { CustomSelect } from "../../../components/CustomInput/custom.select";
import "plyr-react/plyr.css";
import { Heading } from "../../../components/heading.component";
import { profileDataAction } from "../../../redux-tk/slices/mentee.onboarding.data";
import { userDataToMenteeProfile } from "../../../helpers/form.data.converters";
import {
  getUserAsyncThunk,
  userProfileValue,
  updateOnboardingStage,
} from "../../../redux-tk/slices/user.slice";
import { OnboardingSteps } from "../../../enums/setup.steps.enums";
import { FormLabelComponent } from "../../../components/form.label.component";
import { FormErrorComponent } from "../../../components/form.error.component";
import { PostUserProfile } from "../../../services/axios/MentorOnbaording";
import { CustomDeleteBtn } from "../../../components/CustomInput/customDeleteBtn";
import { OnboardingHeading } from "../../../components/onboarding.heading.component";
import { showPopupflag } from "../../../redux-tk/slices/showPopupflag.slice";
import { CustomVideo } from "../../../components/Media/CustomVideo";
import { Popup } from "../../../components/CustomInput/popup";

const INITIALS_USER_INPUTS = {
  dob: "",
  gender: "",
  bio: "",
  language: "",
  country: "",
  state: "",
  city: "",
  profileVideoLink: "",
  profilePic: "",
};
require(`../../../css/${process.env.REACT_APP_CSS_MENTOR}`);

export default function MenteeProfile({ settings }) {
  const {
    menteeOnboardingLabels,
    formElementLabels,
    formErrors,
    headingTranslations,
    headerFooterTranslations,
  } = useIgurooTranslations();
  const selectedValue = useSelector((state) => userProfileValue(state));
  //const popupFlag = useSelector((state) => state.showPopupflag);
  const authContext = useContext(AuthContext);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    reset,
    setFocus,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      ...userDataToMenteeProfile(selectedValue),
      type: ProfileType.mentee,
    },
    resolver: yupResolver(getmenteeBasicFormTranslated(formErrors)),
  });

  const [showPopup2, setShowPopup2] = useState(false);

  useEffect(() => {
    if (isDirty) {
      (async function () {
        dispatch(showPopupflag(true));
      })();
    }
  }, [isDirty]);

  React.useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      console.log(field, a, "field, a");
      return !!errors[field] ? field : a;
    }, null);
    console.log(firstError);
    if (firstError) {
      setFocus(firstError);
      const input = document.getElementsByName(firstError)[0];
      console.log(input);
      input?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  }, [errors, setFocus]);
  let isSelLoaded = false;
  useEffect(() => {
    //console.log("Mentee-selectedValue", selectedValue);
    if (isSelLoaded) return;
    reset({ ...userDataToMenteeProfile(selectedValue) });
    const videoLink =
      selectedValue.profileVideoLink &&
      selectedValue.profileVideoLink != "dummy"
        ? selectedValue.profileVideoLink
        : "";
    setVideoLink(videoLink);
    setValue("profileVideoLink", videoLink);
    const userNewVideoLink = selectedValue?.newProfileVideoLink;
    setNewVideoLink(userNewVideoLink);
    setValue("newProfileVideoLink", userNewVideoLink);
    const profilePic =
      selectedValue.profilePic && selectedValue.profilePic != "dummy"
        ? selectedValue.profilePic
        : "";
    setProfilePic(profilePic);
    setValue("profilePic", profilePic);
    setNewProfilePic(selectedValue?.newProfilePic);
    setValue("newProfilePic", selectedValue?.newProfilePic);
    loadSelectedCityAndState();

    return () => {
      isSelLoaded = true;
    };
  }, [selectedValue]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [stateIsLoading, setStateIsLoading] = useState(false);
  const [cityIsLoading, setCityIsLoading] = useState(false);
  const [description, setdescription] = useState([]);
  const [genderValue, setGender] = useState([{}]);
  const [language, setlang] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [currentCountry, setCurrentCountry] = useState();
  const [stateOptions, setStateOptions] = useState([]);
  const [currentState, setCurrentState] = useState();
  const [cityOptions, setCityOptions] = useState([]);
  const [currentCity, setCurrentCity] = useState();
  const [videoLink, setVideoLink] = useState("");
  const [newVideoLink, setNewVideoLink] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [newProfilePic, setNewProfilePic] = useState("");
  //console.log("profile errors", errors);
  const getFileUrls = (fileUrls, deleted) => {
    if (deleted) {
      const removeName = newVideoLink
        ? "newProfileVideoLink"
        : "profileVideoLink";
      if (newVideoLink) {
        setNewVideoLink("");
        (async function () {
          dispatch(showPopupflag(true));
        })();
      } else {
        setVideoLink("");
      }
      setValue(removeName, "");
    }
    if (fileUrls?.length === 0) return;
    setNewVideoLink(fileUrls[0].url);
    setValue("newProfileVideoLink", fileUrls[0].url);
    if (!deleted) {
      setShowPopup2(true);
      (async function () {
        dispatch(showPopupflag(true));
      })();
    }
  };

  let isLoad = false;

  useEffect(() => {
    if (!isLoad) {
      (async function () {
        dispatch(loader(true));
        dispatch(getUserAsyncThunk({ userId: authContext.sessionInfo.sub }));
        setIsLoading(true);
        const gItems = await GetDataType("gender", "english");
        if (settings) {
          const selectedGender = gItems.filter(
            (value) => value.dataId === selectedValue?.gender?.dataId
          );

          if (selectedValue?.gender) {
            setGender(selectedGender);
          }
        } else {
          setGender(gItems);
        }
        const desItems = await GetDataType("description", "english");
        setdescription(desItems);

        const languageItems = await GetDataType("languages", "english");
        setlang(languageItems);

        const countries = await getCountryInfo();
        setCountryOptions(countries.Items);

        loadSelectedCityAndState();

        dispatch(loader(false));
        setIsLoading(false);
      })();
    } // Pass each of the pages to function. Check flag for changed data in Settings.
    return () => {
      isLoad = true;
    };
  }, []);

  const loadSelectedCityAndState = async () => {
    // console.log(selectedValue?.gender?.value,"seselectedValue?.gender?.value)");
    if (selectedValue?.gender?.value) {
      if (settings) {
        const gen = [];
        gen.push(selectedValue?.gender);
        setGender(gen);
      }
      setValue("gender", selectedValue?.gender?.dataId);
    }
    if (selectedValue?.location?.country) {
      getStateFunc(selectedValue?.location?.country.country_code);
    }
    if (selectedValue?.location?.state) {
      getCity(selectedValue?.location?.state.state_code);
    }
  };

  useEffect(() => {
    (async function () {
      if (!currentCountry) return;
      getStateFunc(currentCountry.country_code);
      setValue("state", {});
    })();
  }, [currentCountry]);

  const getStateFunc = async (countryCode) => {
    setStateIsLoading(true);
    const states = await getState(countryCode);
    setStateOptions(states.Items);
    setStateIsLoading(false);
  };

  useEffect(() => {
    (async function () {
      if (!currentState) return;
      getCity(currentState.state_code);
      setValue("city", {});
    })();
  }, [currentState]);

  const getCity = async (stateCode) => {
    setCityIsLoading(true);
    const cities = await getCities(stateCode);
    setCityOptions(cities.Items);
    setCityIsLoading(false);
  };

  const getProfileImageUrl = (pfp) => {
    if (pfp) {
      setNewProfilePic(pfp);
      setValue("newProfilePic", pfp, { shouldValidate: true });

      (async function () {
        dispatch(showPopupflag(true));
      })();

      setShowPopup2(true);
    }
  };
  //console.log("Profile errors",errors);
  const submitForm = async (data) => {
    console.log("Profile errors data", data);
    try {
      dispatch(loader(true));
      if (!settings) {
        data.onBoardStage = OnboardingSteps[ProfileType.mentee][1].value;
        const referencecode = localStorage.getItem("referencecode");
        if (referencecode != null) {
          data.referenceCode = referencecode;
          localStorage.removeItem("referencecode");
        } else {
          data.referenceCode = " ";
        }
      }
      //  console.log(data)
      console.log("profile errors phot", newProfilePic, data.newProfilePic);
      data.gender = getGenderFromId(data.gender, genderValue);
      data.firstName = authContext.sessionInfo.firstName;
      data.lastName = authContext.sessionInfo.lastName;
      data.email = authContext.sessionInfo.email;
      data.mobile = authContext.sessionInfo.phoneNumber;
      const finalData = getProfileObject(data, authContext.sessionInfo.sub);
      finalData.newProfileVideoLink =
        newVideoLink === undefined ? "" : newVideoLink;
      finalData.newProfilePic =
        newProfilePic === undefined ? "" : newProfilePic;
      finalData.isNewProfileVidRejected =
        newVideoLink === (undefined || "") ? true : false;
      finalData.isNewProfilePicRejected =
        newProfilePic === (undefined || "") ? true : false;
      finalData.profileVideoLink = videoLink;
      finalData.profilePic = profilePic;

      data.gender = data.gender.dataId;
      const result = await PostUserProfile(
        finalData,
        authContext.sessionInfo.sub
      );
      console.log("activeIndex", data);
      dispatch(profileDataAction(data));
      //console.log("res", result);
      dispatch(loader(false));
      Toaster(toast.success, headerFooterTranslations.toasterMsgOnboarding);
      if (!settings) {
        dispatch(updateOnboardingStage(data.onBoardStage));
        navigate(AppUrlsEnum.menteeEduAndWork);
      }
    } catch (err) {
      console.log("profile errors save", err);
      setError("apiError", { message: err.message });
      dispatch(loader(false));
    }
  };
  const deleteProfilePic = () => {
    //console.log("profilePic");
    const removeName = newProfilePic ? "newProfilePic" : "profilePic";
    if (newProfilePic) {
      console.log("LOG newProfilePic");
      setNewProfilePic("");
    } else {
      console.log("LOG ProfilePic");
      setProfilePic("");
    }
    setValue(removeName, "");
    (async function () {
      dispatch(showPopupflag(true));
    })();
  };

  const [value, setInputValue] = useState("");
  const [error, setErrors] = useState(false);
  const handleChange = (event) => {
    if (event.target.value.length <= 999) {
      setInputValue(event.target.value);
      setErrors(false);
    } else {
      setErrors(true);
    }
  };

  const handleClose = () => {
    setShowPopup2(false);
  };

  const genderDobField = () => {
    return (
      <>
        <div>
          <Controller
            name={`type`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="hidden"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              ></Form.Control>
            )}
          />
          <Controller
            name={`newProfilePic`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="hidden"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              ></Form.Control>
            )}
          />
          <Controller
            name={`newProfileVideoLink`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="hidden"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              ></Form.Control>
            )}
          />
          <Controller
            name={`newProfilePic`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="hidden"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              ></Form.Control>
            )}
          />
          <Controller
            name={`newProfileVideoLink`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="hidden"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              ></Form.Control>
            )}
          />
        </div>
        <Form.Group>
          <Row>
            <Col md="4" lg="4" className={`mt-3 `}>
              <label>
                <FormLabelComponent>
                  {menteeOnboardingLabels.dateOfBirth}
                </FormLabelComponent>
              </label>
              <Controller
                name={`dateofBirth`}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomInputField
                    placeholder="Date Of Birth"
                    hookOnChange={onChange}
                    type="date"
                    hookOnBlur={onBlur}
                    hookValue={value}
                    maxLength={8}
                    max="9999-12-31"
                  />
                )}
              />
              <FormErrorComponent>
                {errors.dateofBirth && errors.dateofBirth?.message}
              </FormErrorComponent>
            </Col>

            <Col
              md="8"
              lg="8"
              className={`mt-3 ${
                settings
                  ? "d-flex  justify-content-center ig-radio-disable"
                  : ""
              }`}
            >
              <Controller
                name={"gender"}
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <CustomRadioOptions
                    label={menteeOnboardingLabels.gender}
                    options={genderValue}
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    name={name}
                  />
                )}
              />

              <FormErrorComponent>
                {errors.gender && errors.gender?.message}
              </FormErrorComponent>
            </Col>
          </Row>
        </Form.Group>
      </>
    );
  };
  const restFormField = () => {
    return (
      <>
        <Form.Group>
          <Row className="mt-4">
            <Col>
              <label>
                <FormHeading>{menteeOnboardingLabels.profdesc}</FormHeading>
              </label>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <Controller
                control={control}
                name="profileCategory"
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomSelect
                    opLabel="value"
                    opValue="dataId"
                    items={description}
                    onReactHookFormBlur={onBlur}
                    onReactHookFormChange={onChange}
                    value={value}
                    isLoading={isLoading}
                    sendFullValueInHook={true}
                    normalSelect={true}
                    placeholder={
                      formElementLabels?.placeHolders?.educationLevel
                    }
                  />
                )}
              />
              <FormErrorComponent>
                {errors.profileCategory &&
                  errors.profileCategory?.value.message}
              </FormErrorComponent>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <label>
                {" "}
                <FormHeading>{menteeOnboardingLabels.language}</FormHeading>
              </label>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomSelect
                    opLabel="value"
                    opValue="dataId"
                    items={language}
                    onReactHookFormBlur={onBlur}
                    onReactHookFormChange={onChange}
                    value={value}
                    hideSelectedOptions={false}
                    sendFullValueInHook={true}
                    normalSelect={true}
                    isLoading={isLoading}
                    isMulti={true}
                    placeholder={formElementLabels?.placeHolders?.language}
                  />
                )}
                name="languages"
              />
              <FormErrorComponent>
                {errors.languages && errors.languages?.message}
              </FormErrorComponent>
            </Col>
          </Row>
          <Row>
            <Col className="mt-4">
              <FormHeading>{menteeOnboardingLabels.location}</FormHeading>
            </Col>
          </Row>
          <Row>
            <Col md="4" lg="4" className="mt-3">
              <label>
                <FormLabelComponent>
                  {menteeOnboardingLabels.country}
                </FormLabelComponent>
              </label>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomSelect
                    opLabel="country"
                    opValue="country_code"
                    items={countryOptions}
                    onReactHookFormBlur={onBlur}
                    onReactHookFormChange={onChange}
                    value={value}
                    sendFullValueInHook={true}
                    normalSelect={true}
                    isLoading={isLoading}
                    placeholder={formElementLabels?.placeHolders?.country}
                    onChangeCallback={(obj) => {
                      setCurrentCountry(obj);
                      setCurrentState(null);
                      setCurrentCity(null);
                      setStateOptions([]);
                      setCityOptions([]);
                      return setCountryOptions;
                    }}
                  />
                )}
                name="country"
              />
              <FormErrorComponent>
                {errors?.country?.country && errors?.country?.country?.message}
              </FormErrorComponent>
            </Col>
            <Col md="4" lg="4" className="mt-3">
              <label>
                <FormLabelComponent>
                  {menteeOnboardingLabels.state}
                </FormLabelComponent>
              </label>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomSelect
                    opLabel="state"
                    opValue="state_code"
                    items={stateOptions}
                    onReactHookFormBlur={onBlur}
                    onReactHookFormChange={onChange}
                    value={value}
                    sendFullValueInHook={true}
                    normalSelect={true}
                    isLoading={stateIsLoading}
                    onChangeCallback={(obj) => {
                      setCurrentState(obj);
                      setCurrentCity(null);
                      setCityOptions([]);
                      return setStateOptions;
                    }}
                  />
                )}
                name="state"
              />
              <FormErrorComponent>
                {errors.state?.state && errors.state?.state?.message}
              </FormErrorComponent>
            </Col>
            <Col md="4" lg="4" className="mt-3">
              <label>
                <FormLabelComponent>
                  {menteeOnboardingLabels.city}
                </FormLabelComponent>
              </label>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomSelect
                    opLabel="city"
                    opValue="city_code"
                    items={cityOptions}
                    onReactHookFormBlur={onBlur}
                    onReactHookFormChange={onChange}
                    value={value}
                    sendFullValueInHook={true}
                    normalSelect={true}
                    isLoading={cityIsLoading}
                    placeholder={formElementLabels?.placeHolders?.city}
                  />
                )}
                name="city"
              />
              <label>
                <FormErrorComponent>
                  {errors.city?.city && errors.city?.city?.message}
                </FormErrorComponent>
              </label>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <label>
                <FormLabelComponent>
                  {menteeOnboardingLabels.description}
                </FormLabelComponent>
              </label>
              <Controller
                name={`bio`}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomInputField
                    placeholder={menteeOnboardingLabels.placeHoldersdesc}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    formAs={"textarea"}
                    rows={6}
                    hookOnChange={(event) => {
                      handleChange(event);
                      onChange(event);
                    }}
                    maxLength={1000}
                  />
                )}
              />
              {error && (
                <FormErrorComponent>
                  {formErrors.describeTitle}
                </FormErrorComponent>
              )}
              <FormErrorComponent>
                {errors.bio && errors.bio?.message}
              </FormErrorComponent>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="mt-3">
              <div
                className={`${
                  newVideoLink || videoLink ? "d-block" : "d-none"
                }`}
              >
                <CustomVideo
                  videoLink={newVideoLink ? newVideoLink : videoLink}
                />
              </div>
              <FileUploader
                maxCount={1}
                fileUrls={getFileUrls}
                uploadOnSelect={true}
                uploadFileTypes="video/*"
                inputClass={"video-file-input"}
                labelImage={_getImage("ic_upload.png")}
                uploadInputLabelText={menteeOnboardingLabels.uploadProfileVideo}
                uploadedtext={menteeOnboardingLabels.uploadedProfileVideo}
                deleteFileImage={_getImage("Delete.svg")}
                uploadedFileUrls={
                  newVideoLink ? [newVideoLink] : videoLink ? [videoLink] : []
                }
              />
            </Col>
          </Row>
        </Form.Group>
        <Row className="mt-1 mb-3">
          <FormErrorComponent>
            {errors.apiError && errors.apiError?.message}
          </FormErrorComponent>
        </Row>

        <hr />
        <Row className="text-end mb-4 justify-content-end save-btn-end">
          <Col sm="12" md="2" className="d-grid ">
            <SubmitButton type="submit" text={formElementLabels?.save} />
          </Col>
        </Row>
      </>
    );
  };
  const profileImageField = () => {
    return (
      <>
        <Col
          sm="12"
          md="2"
          className="mt-3 d-flex flex-column align-items-center "
        >
          <Row className="ig-form-label">
            <label>
              <FormLabelComponent>
                {menteeOnboardingLabels?.profilePhoto}
              </FormLabelComponent>
            </label>
          </Row>
          <UserProfileModal
            label={menteeOnboardingLabels.profile}
            getImageUrl={getProfileImageUrl}
            aspect={1 / 1}
            imgUrl={newProfilePic ? newProfilePic : profilePic}
          />
          <Row className="mt-2 d-grid" xs="12" sm="12" md="12">
            <div>
              <FormErrorComponent>
                {errors.profilePic && errors.profilePic?.message}
              </FormErrorComponent>
              <FormErrorComponent>
                {errors.newProfilePic && errors.newProfilePic?.message}
              </FormErrorComponent>
            </div>
            {profilePic || newProfilePic ? (
              <CustomDeleteBtn
                noChngIconToText={true}
                textClassName="profile-delete-text"
                svgName="trash_profile.svg"
                text="Remove"
                onClick={deleteProfilePic}
              ></CustomDeleteBtn>
            ) : (
              ""
            )}
          </Row>
        </Col>
      </>
    );
  };
  return (
    <Container>
      {!settings && <OnboardingHeading />}
      <Container>
        <Row>
          <Col md="12" lg={settings ? 12 : 9}>
            {!settings && (
              <Heading>{headingTranslations?.menteeProfile}</Heading>
            )}
            <Form onSubmit={handleSubmit(submitForm)} className="mt-3">
              {settings ? (
                <>
                  <Row className="setting-page">
                    {profileImageField()}
                    <Col md="10">{genderDobField()}</Col>
                  </Row>
                  <Row>
                    <Col md="12">{restFormField()}</Col>
                  </Row>
                </>
              ) : (
                <Row>
                  {profileImageField()}
                  <Col md="10">
                    {genderDobField()}
                    {restFormField()}
                  </Col>
                </Row>
              )}
            </Form>
          </Col>
          {!settings && (
            <Col>
              {menteeOnboardingLabels?.rightSetupModule?.mentee && (
                <MentorSetupSidebar
                  typeToUse={menteeOnboardingLabels?.rightSetupModule?.mentee}
                  active={
                    menteeOnboardingLabels?.rightSetupModule?.mentee?.values
                      ?.basicInfo
                  }
                />
              )}
            </Col>
          )}
        </Row>
      </Container>
      <Popup
        closeOnBackClick={false}
        showPopup={showPopup2}
        type="popup"
        text={formElementLabels?.buttonSave}
        modalBody={formElementLabels?.placeHolders?.adminApproval}
        gotIt={handleClose}
      />
    </Container>
  );
}
