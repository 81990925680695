//** Iguroo SWOT Assessment ->Swot SemiCircle Component,Can re-use anywhere in required Field**//
//** Currently not using this code,will be implemented in future **//

export const SwotSemiCircle = ({
  makeRight,
  text,
  comPClassname,
  comTextClassName,
}) => {
  return (
    <div className={`${makeRight ? "text-right" : ""}`}>
      <div className={`d-inline-block my-semi-circle ${comPClassname}`}>
        <div className={`text-center ${comTextClassName}`}>{text}</div>
      </div>
    </div>
  );
};
