//** Iguroo Mentor & Mentee Profile Info Component UI code,it is re-used in Dashboard,Book & Reschedule Session **//

import React, { useContext, useState } from "react";
import { Stack, Button, Image, Container, Row, Col } from "react-bootstrap";
import { _getImage } from "../../common/utils/commonFunctions";
import "./styles.scss";
import { Link, useNavigate } from "react-router-dom";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { AppUrlsEnum, ProfileType } from "../../enums";
import white_message from "../../assets/images/white_message.svg";
import { ProfileImage } from "../Media";
import { isMentorOrPre } from "../../helpers";
import { useMemo } from "react";
import { AuthContext } from "../../hooks/auth.context.provider";
import Rating from "react-rating";
import { ImgSrcEnums } from "../../enums";

function ProfileInfo(props) {
  const { headerFooterTranslations } = useIgurooTranslations();
  const navigate = useNavigate();
  const {
    showVerified,
    hideBtn,
    firstName,
    lastName,
    languages = [],
    profilePic,
    newProfilePic,
    isTrusted,
    userId,
    requestNumber,
    reqName,
    showSendMsgBtn,
    pageNavigate,
    timeZone: { value: timeZone } = { value: "" },
    rating,
    sessionsHeld,
  } = props;
  console.log(props, "props--");

  const authContext = useContext(AuthContext);
  const userType = useMemo(() => {
    return authContext?.sessionInfo?.role?.toUpperCase();
  }, [authContext.sessionInfo.role]);
  const profileUrl = useMemo(() => {
    if (!requestNumber && userId && !pageNavigate) {
      if (isMentorOrPre(userType)) {
        return `${AppUrlsEnum.mentorMenteeProfile}?menteeId=${userId}`;
      } else if (userType === ProfileType.mentee) {
        return `${AppUrlsEnum.menteeMentorProfile}?mentorId=${userId}`;
      }
    }
  }, [userType, userId]);
  const requestUrl = useMemo(() => {
    localStorage.setItem(requestNumber, reqName);
    if (isMentorOrPre(userType)) {
      return AppUrlsEnum.mentorRequestDetails.replace(":id", requestNumber);
    } else if (userType === ProfileType.mentee) {
      return AppUrlsEnum.menteeRequestDetails.replace(":id", requestNumber);
    }
  }, [userType, requestNumber, reqName]);

  const [ht, setHt] = useState(20);

  return (
    <Container>
      <div>
        <Stack direction="horizontal" className="profile-info" gap={4}>
          <Link
            to={requestNumber ? requestUrl : profileUrl}
            className="text-decoration-none"
          >
            <div className="profile-img" style={{ cursor: "pointer" }}>
              {console.log(
                newProfilePic,
                profilePic,
                profilePic,
                "profile finalData"
              )}
              <ProfileImage
                src={showVerified ? profilePic : newProfilePic}
                alt="Profile Pic"
                height={88}
                width={88}
              />
            </div>
          </Link>
          <div className="info mt-2">
            <Link
              to={requestNumber ? requestUrl : profileUrl}
              className="text-decoration-none"
            >
              <h1
                className="name-firstletter"
                style={{ cursor: "pointer", color: "var(--black)" }}
              >
                {firstName} {lastName}{" "}
                {isTrusted && (
                  <img
                    src={_getImage("tick_mark.svg")}
                    alt="tick"
                    height={18}
                  />
                )}
              </h1>
            </Link>
            {/* <p className='time-zone'>{timeZone}</p> */}
            <p className="languages">
              <img
                src={_getImage("globe.svg")}
                alt="language"
                height={13.33}
                className="me-1"
              />
              <span>{languages.map(({ value }) => value).join(", ")}</span>
            </p>
            {!hideBtn && (
              <Button
                variant="outline-secondary mb-4"
                onClick={() => navigate(AppUrlsEnum.mentorSettings)}
              >
                <img src={_getImage("edit.svg")} alt="edit" />
                {headerFooterTranslations?.editAccount}
              </Button>
            )}
            {/* Send Message will be used in future enhancement */}
            {/* {showSendMsgBtn && (
          <Button className="mentee-profile-info-btn fs-15" size="sm">
            <Image height={15} width={15} src={white_message} />
            <span className="ms-2">{profileLabels?.sentmsg}</span>
          </Button>
        )} */}
          </div>
        </Stack>
      </div>
      <row>
        <div>
          <img
            src={_getImage("clock.svg")}
            alt="language"
            height={20}
            className="me-2 mt-1"
          />
          <sub className="fontM me-1">{sessionsHeld}</sub>
          <sub className="fontS mt-2">Sessions were held </sub>
          <span className="cent ms-2 mt-0 mb-0">
            <Rating
              placeholderRating={rating ? rating : 0}
              readonly={true}
              emptySymbol={
                <img
                  height={ht}
                  width={ht}
                  src={_getImage("empty_star.svg")}
                  className="icon"
                />
              }
              placeholderSymbol={
                <img
                  height={ht}
                  width={ht}
                  src={_getImage("full_star.svg")}
                  className="icon"
                />
              }
              fullSymbol={
                <img
                  height={ht}
                  width={ht}
                  src={_getImage("full_star.svg")}
                  className="icon"
                />
              }
            />
          </span>
        </div>
      </row>
    </Container>
  );
}

export default ProfileInfo;
