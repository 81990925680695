import { axiosStaticData } from "../axios.service";
import { ASSESSMENT_REPORT, ASSESSMENT_LANG_USER, GET_ASSESSMENT_DATA } from "../../urlConst";
import axios from "axios";
import { GetAssessmentsApi } from "@iguroo/ig-react-assessment-package";

export async function getAssessmentReport(userId, attemptId) {
  try {
    const URL = ASSESSMENT_REPORT;
    const params = {
      userId,
      attemptId,
    };
    const response = await axiosStaticData.get(URL, { params });
    return response.data.response;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export const setAssessmentLanguage = async (userId, assessmentLanguage) => {
  try {
    const params = {
      userId: userId,
      assessmentlanguage: assessmentLanguage,
    };
    const URL = ASSESSMENT_LANG_USER;
    const res = await axiosStaticData.post(URL, {}, { params });
    return res.data.response;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export async function getAssessmenById(assessment_id) {
  try {
    const params = {
      assessment_id: assessment_id,
      version: 1,
    };
    const response = await GetAssessmentsApi(params);
    return response;
  } catch (err) {
    console.log("LOG GetAssessmentsApi err", err);
    throw err;
  }
}


export async function getAssessmentData(data) {
  try {
    const URL = GET_ASSESSMENT_DATA;
    console.log('getAssessmentData',data)
    const params ={
      pagenumber : data.pagenumber,
      pagesize: data.pagesize,
      status: data.status,
      userId: data.userId
    }
    const response = await axiosStaticData.get(URL,  {params} );
    return response;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}