//** GinieHealth Custom OTP Component for email,mobNo changing,can re-use any required field **//

import React, { useCallback, useContext, useEffect, useRef } from "react";
import { Button, Row, Modal, Col, Form } from "react-bootstrap";
import { _getImage } from "../../common/utils/commonFunctions";
import { useState } from "react";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { useNavigate } from "react-router-dom";
import { AppUrlsEnum, StrConstants } from "../../enums";
import { VerifyMessageComponent } from "../cardComponent/verification.message";
import { Controller, useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { FormErrorComponent } from "../form.error.component";
import { SubmitButton } from "../submit.button";
import { Popup } from "./popup";
import { AuthContext } from "../../hooks/auth.context.provider";
import { useDispatch } from "react-redux";
import { loader } from "../../redux-tk/slices/loader.slice";
import { addUser } from "../../redux-tk/slices/user.management.slice";

export function CustomOTPPopup(props) {
  const {
    showPopup,
    closeOnBackClick,
    gotIt,
    title,
    className,
    onHide,
    updateList,
  } = props;
  const { formErrors, headingTranslations } = useIgurooTranslations();

  const authContext = useContext(AuthContext);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    // mode:"onBlur",
    // resolver: yupResolver(getVerifiyOtpForm(formErrors)),
  });
  const [timer, setTimer] = useState(60);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  const userOtp = useRef();
  const [showSuccsessPopup, setshowSuccsessPopup] = useState(false);
  const [OTP, setOTP] = useState("");

  const onSubmit = async (data) => {
    const loginOtp = data.otp;
    console.log(OTP, loginOtp);
    const username = localStorage.getItem(StrConstants.username);
    console.log(username, "username", "otp", OTP?.length);

    try {
      dispatch(loader(true));
      if (OTP?.length !== 6) {
        setError("otp", {
          type: "manual",
          message: formErrors.otpError,
        });
      } else {
        clearErrors("otp");

        await authContext
          .verifyEmailCode(OTP, props?.verifyType)
          .then((res) => {
            setshowSuccsessPopup(true);
            dispatch(addUser({ ...props?.userUpdate }))
              .then((res) => {
                if (res.hasOwnProperty("error")) {
                  // Toaster(toast.error, res?.payload?.response?.data?.message);
                } else {
                  // Toaster(toast.success, res?.payload?.message);
                }
                updateList();
              })
              .catch((err) => {
                // Toaster(
                //   toast.error,
                //   "Something went wrong. Please try again after sometime."
                // );
              });
            setTimeout(() => {
              //handleClose();
            }, 3000);
          });
      }
      dispatch(loader(false));
      console.log("made call");
    } catch (err) {
      console.log(err, "api-error");
      const message = err?.message
        ? err.message
        : JSON.parse(String.fromCharCode.apply(null, err?.httpResponse?.body))
            ?.message;
      if (err.code === "UserNotConfirmedException") {
      } else {
        setError("apiError", { message: "Invalid OTP entered" });
      }
      dispatch(loader(false));
    }
  };
  //console.log("err", errors);
  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  // console.log(timer);
  const resetTimer = function () {
    if (!timer) {
      setTimer(60);
    }
  };
  // const itemsRef = useRef([]);
  // state = { otp: '' };
  const resendPasswordClick = async () => {
    try {
      // const val = localStorage.getItem(StrConstants.username);
      console.log(props?.verifyText, props?.verifyType, "username resend");
      authContext
        .changeEmailPhone(props?.verifyType, props?.verifyText)
        .then((res) => {
          console.log("change email resend", res);
        });
      resetTimer();
    } catch (err) {
      setError("apiError", { message: err.message });
    }
  };
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    updateList();
    setshowSuccsessPopup(false);
    onHide();
  };

  useEffect(() => {
    setOTP("");
    setShow(showPopup);
  }, [showPopup]);

  const handleSubmitPopup = () => {
    setShow(false);
    if (gotIt) {
      gotIt();
    }
  };
  return (
    <>
      <Modal
        className={`completed-popup ${className}`}
        show={show}
        onHide={closeOnBackClick ? handleClose : null}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="text-center">
          <Modal.Title className="w-100">
            {title ? title : headingTranslations?.enterCode}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <VerifyMessageComponent
            text={
              props?.verifyType == "email"
                ? headingTranslations?.verificationMessageEmail
                : headingTranslations?.verificationMessagePhone
            }
          />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="my-3 ig-otp-row">
              <Controller
                name={`otp`}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <OtpInput
                    value={OTP}
                    onChange={(value) => {
                      setOTP(value);
                      clearErrors("apiError");
                    }}
                    numInputs={6}
                    className="ig-OTP-field col p-1"
                    shouldAutoFocus={true}
                    // separator={<span>-</span>}
                  />
                )}
              />
            </Row>

            {/* <CardSecondaryComponent>
                    {formElementLabels?.resendCode}{' '}
                    {timer > 0 ? (
                      formElementLabels?.resendIn
                    ) : (
                      <TextLink url="" onClick={resendPasswordClick}>
                        {formElementLabels?.resend}
                      </TextLink>
                    )}{' '}
                    {timer > 0 ? timer : ""}
                    {timer > 0 ? formElementLabels?.sec : ""}
                  </CardSecondaryComponent> */}
            <br />

            <FormErrorComponent>
              {errors.otp && errors.otp?.message}
            </FormErrorComponent>

            <FormErrorComponent>
              {errors.apiError && errors.apiError?.message}
            </FormErrorComponent>

            <Col lg="12" xs="12" md="12" xl="12" className="d-grid mt-2">
              <SubmitButton type="submit" text={"Submit"} />
              <Popup
                showPopup={showSuccsessPopup}
                type="popup"
                modalBody={"OTP Verified"}
                onHide={() => handleClose()}
                submitText={"OK"}
              />
            </Col>
            {/* <div
                      className="d-grid mt-3"
                    >
                      <SubmitButton text={"Confirm"} />{" "}
                      </div>
                   */}
          </Form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleSubmit} required={true}>
            {submitText ? submitText : mentorOnboardingLabels.popupDone}
          </Button>

          {homeLink ? (
            <Col className="d-grid sigin-pwd text-center mt-3 justify-content-center">
              <a href={AppUrlsEnum.landingPage}>{homeLink}</a>
            </Col>
          ) : (
            ""
          )}
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
