//** Key and Value of FormLabelsTranslations -> Check with Value name in en.language.json **//

export function FormElementLabelsTranslations(t) {
  return {
    key: "formElementLabels",
    value: {
      save: t("formElements.buttonSave"),
      submit: t("formElements.buttonSubmit"),
      addAnother: t("formElements.addAnother"),
      popup: t("formElements.buttonPopup"),
      addAnotherTimeSlot: t("formElements.addAnotherTimeSlot"),
      buttonApply: t("formElements.buttonApply"),
      buttonSearch: t("formElements.buttonSearch"),
      bookSession: t("formElements.bookSession"),
      resendCode: t("formElements.resendCode"),
      resendIn: t("formElements.resendIn"),
      resend: t("formElements.resend"),
      sec: t("formElements.sec"),
      cancel: t("formElements.cancel"),
      apply: t("formElements.buttonApply"),
      upload: t("formElements.buttonUpload"),
      close: t("formElements.buttonClose"),
      buttonCancel: t("formElements.buttonCancel"),
      cancel: t("formElements.buttonCancel"),
      profilePic: t("formElements.buttonProfile"),
      capturePic: t("formElements.buttonCapture"),
      scale: t("formElements.popScale"),
      rotate: t("formElements.picRotate"),
      forgetPassword: t("formElements.forgetPassword"),
      contiueBtn: t("formElements.contiueBtn"),
      userOtp: t("formElements.userOtp"),
      otpError: t("formElements.otpError"),
      accept: t("formElements.accept"),
      reject: t("formElements.reject"),
      join: t("formElements.join"),
      addSession: t("formElements.addSession"),
      upcoming: t("formElements.upcoming"),
      completed: t("formElements.completed"),
      status: t("formElements.status"),
      category: t("formElements.category"),
      homePage: t("formElements.homePage"),
      signIn: t("formElements.signIn"),
      usePassword: t("formElements.usePassword"),
      reschedule: t("formElements.reschedule"),
      selectGoals: t("formElements.selectGoals"),
      nameRequest: t("formElements.nameRequest"),
      firstNameSignup: t("formElements.firstNameSignup"),
      lastNameSignup: t("formElements.lastNameSignup"),
      mobileNum: t("formElements.mobileNum"),
      userName: t("formElements.userName"),
      emailSigup: t("formElements.emailSigup"),
      passwordSign: t("formElements.passwordSign"),
      newPassword: t("formElements.newPassword"),
      confirmPassword: t("formElements.confirmPassword"),
      passwordSignin: t("formElements.passwordSignin"),
      currentPassword: t("formElements.currentPassword"),
      newPasswordSecurity: t("formElements.newPasswordSecurity"),
      repeatPassword: t("formElements.repeatPassword"),
      resendUserNotConfirmed: t("formElements.resendUserNotConfirmed"),
      minCharacter: t("formElements.minCharacter"),
      maxdescription: t("formElements.maxdescription"),
      reqstatus: t("formElements.reqstatus"),
      noMatch: t("formElements.noMatch"),
      emailSigupRequired: t("formElements.emailSigupRequired"),
      messageTitle: t("formElements.messageTitle"),
      buttonContinue: t("formElements.buttonContinue"),
      backTest: t("formElements.backTest"),
      scheduleSession: t("formElements.scheduleSession"),
      onTestSubmission: t("formElements.onTestSubmission"),
      reportBtn: t("formElements.reportBtn"),
      swotReportBtn: t("formElements.swotReportBtn"),
      previousBtn: t("formElements.previousBtn"),
      nextBtn: t("formElements.nextBtn"),
      resettBtn: t("formElements.resettBtn"),
      publishBtn: t("formElements.publishBtn"),
      yesResetAssessment: t("formElements.yesResetAssessment"),
      noResetAssessment: t("formElements.noResetAssessment"),
      emailLogin: t("formElements.emailLogin"),
      mobileLogin: t("formElements.mobileLogin"),
      usernameLogin: t("formElements.usernameLogin"),
      supportMobile: t("formElements.supportMobile"),
      placeHolders: {
        select: t("placeHolders.select"),
        enterYearlySessionRate: t("placeHolders.enterYearlySessionRate"),
        enterQuarterlySessionRate: t("placeHolders.enterQuarterlySessionRate"),
        enterMonthlySessionRate: t("placeHolders.enterMonthlySessionRate"),
        enterOneSessionRate: t("placeHolders.enterOneSessionRate"),
        selectCurrency: t("placeHolders.selectCurrency"),
        selectSessionInterval: t("placeHolders.selectSessionInterval"),
        profileTitle: t("placeHolders.profileTitle"),
        description: t("placeHolders.description"),
        aoe: t("placeHolders.aoe"),
        degree: t("placeHolders.degree"),
        fieldofStudy: t("placeHolders.fieldofStudy"),
        school: t("placeHolders.school"),
        year: t("placeHolders.year"),
        country: t("placeHolders.country"),
        city: t("placeHolders.city"),
        state: t("placeHolders.state"),
        mentoringYear: t("placeHolders.mentoringYear"),
        language: t("placeHolders.language"),
        sessionTime: t("placeHolders.sessionTime"),
        currencyType: t("placeHolders.currencyType"),
        timezone: t("placeHolders.timezone"),
        availability: t("placeHolders.availability"),
        educationLevel: t("placeHolders.educationLevel"),
        skills: t("placeHolders.skills"),
        hobbies: t("placeHolders.hobbies"),
        specialization: t("placeHolders.specialization"),
        sortBy: t("placeHolders.sortBy"),
        searchMentor: t("placeHolders.searchMentor"),
        username: t("placeHolders.username"),
        password: t("placeHolders.password"),
        email: t("placeHolders.email"),
        firstName: t("placeHolders.firstName"),
        lastName: t("placeHolders.lastName"),
        search: t("placeHolders.search"),
        years: t("placeHolders.years"),
        company: t("placeHolders.company"),
        role: t("placeHolders.role"),
        mobileNo: t("placeHolders.mobileNo"),
        resetPassword: t("placeHolders.resetPassword"),
        cardYear: t("placeHolders.cardYear"),
        cardMonth: t("placeHolders.cardMonth"),
        cardCvv: t("placeHolders.cardCvv"),
        cardNumber: t("placeHolders.cardNumber"),
        selectGoals: t("placeHolders.selectGoals"),
        title: t("placeHolders.title"),
        bookDescription: t("placeHolders.bookDescription"),
        leaveFeedback: t("placeHolders.leaveFeedback"),
        yourFirstName: t("placeHolders.yourFirstName"),
        yourLastName: t("placeHolders.yourLastName"),
        serchMentee: t("placeHolders.serchMentee"),
        searchmyMentor: t("placeHolders.searchmyMentor"),
        free: t("placeHolders.free"),
        aboutUs: t("placeHolders.aboutUs"),
        adminApproval: t("placeHolders.adminApproval"),
        collegeSearch: t("placeHolders.collegeSearch"),
        testimonialsPH: t("placeHolders.testimonialsPH"),
        swotTypes: {
          opportunities: t("placeHolders.swotTypes.opportunities"),
          strength: t("placeHolders.swotTypes.strength"),
          threats: t("placeHolders.swotTypes.threats"),
          weakness: t("placeHolders.swotTypes.weakness"),
          opportunitiesPlan: t("placeHolders.swotTypes.opportunitiesPlan"),
          strengthPlan: t("placeHolders.swotTypes.strengthPlan"),
          threatsPlan: t("placeHolders.swotTypes.threatsPlan"),
          weaknessPlan: t("placeHolders.swotTypes.weaknessPlan"),
          addStrength: t("placeHolders.swotTypes.addStrength"),
          addWeakness: t("placeHolders.swotTypes.addWeakness"),
          addOpportunities: t("placeHolders.swotTypes.addOpportunities"),
          addThreat: t("placeHolders.swotTypes.addThreat"),
          addStrengthPlan: t("placeHolders.swotTypes.addStrengthPlan"),
          addWeaknessPlan: t("placeHolders.swotTypes.addWeaknessPlan"),
          addOpportunitiesPlan: t(
            "placeHolders.swotTypes.addOpportunitiesPlan"
          ),
          addThreatPlan: t("placeHolders.swotTypes.addThreatPlan"),
          swotEndPopupHeader: t("placeHolders.swotTypes.swotEndPopupHeader"),
          swotEndPopupBodyOne: t("placeHolders.swotTypes.swotEndPopupBodyOne"),
          swotEndPopupBodyTwo: t("placeHolders.swotTypes.swotEndPopupBodyTwo"),
          swotEndPopupButtonOne: t(
            "placeHolders.swotTypes.swotEndPopupButtonOne"
          ),
          swotEndPopupButtonTwo: t(
            "placeHolders.swotTypes.swotEndPopupButtonTwo"
          ),
          swotStartPopupHeader: t(
            "placeHolders.swotTypes.swotStartPopupHeader"
          ),
          swotStartPopupBody: t("placeHolders.swotTypes.swotStartPopupBody"),
          swotStartPopupButtonOne: t(
            "placeHolders.swotTypes.swotStartPopupButtonOne"
          ),
          swotExampleHeader: t("placeHolders.swotTypes.swotExampleHeader"),
          swotExampleButton: t("placeHolders.swotTypes.swotExampleButton"),
          swotExamples: {
            strengths: t("placeHolders.swotExamples.strengths", {
              returnObjects: true,
            }),
            weakness: t("placeHolders.swotExamples.weakness", {
              returnObjects: true,
            }),
            opportunities: t("placeHolders.swotExamples.opportunities", {
              returnObjects: true,
            }),
            threats: t("placeHolders.swotExamples.threats", {
              returnObjects: true,
            }),
          },
        },
      },
    },
  };
}
