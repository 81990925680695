//** Iguroo Mentee can book session with Mentor,Can re-use the component for booking a session**//

import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Modal,
  Button,
  Container,
  Col,
  Row,
  Form,
  Spinner,
} from "react-bootstrap";
// Calendar component - https://natscale.github.io/react-calendar/api/props/
import moment from "moment";
import "./styles.scss";
import ProfileInfo from "../../components/ProfileInfo";
import CardRadioBtn from "../../components/CardRadioBtn";
import FileUploader from "../../components/file.upload";
import {
  _getCurrencySymbol,
  _getImage,
} from "../../common/utils/commonFunctions";
import { FormErrorComponent } from "../../components/form.error.component";
import { FormLabelComponent } from "../../components/form.label.component";
import { useForm, Controller } from "react-hook-form";
import { CustomSelect } from "../../components/CustomInput/custom.select";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import {
  decrementLoader,
  incrementLoader,
  loader,
} from "../../redux-tk/slices/loader.slice";
import { GetDataType } from "../../services/axios/staticData";
import { useDispatch, useSelector } from "react-redux";
import { languageValue } from "../../redux-tk/slices/general.info.slice";
import { BookingaSession } from "../../services/axios/Dasboard";
import { AuthContext } from "../../hooks/auth.context.provider";
import { SubmitButton } from "../submit.button";
import {
  createBookaSession,
  getSessionType,
  StipeBookaSession,
} from "../../helpers";
import { useNavigate } from "react-router-dom";
import CustomCalendar from "../CustomCalendar";
import { getAllSessionsInDates } from "../../redux-tk/slices/session.slice";
import useavailableSlots from "../../hooks/use.availableSlots";
import { AppUrlsEnum, RatesTimeFrame } from "../../enums";
import { getBookSessionTranslated } from "../../FormSchemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { Popup } from "../CustomInput/popup";
import { getUserAvailabilityAsyncThunk } from "../../redux-tk/slices/user.slice";
import { checkoutToStripe } from "../../services/axios/Payment/payment";
require(`../../css/${process.env.REACT_APP_CSS_COMMON}`);

function BookSession(props) {
  const { dashboardTranslations, formElementLabels, formErrors } =
    useIgurooTranslations();
  const {
    control,
    handleSubmit,
    setValue,
    setFocus,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(getBookSessionTranslated(formErrors)),
  });

  React.useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      console.log(field, a, "field, a");
      return !!errors[field] ? field : a;
    }, null);
    console.log(firstError);
    if (firstError) {
      setFocus(firstError);
      const input = document.getElementsByName(firstError)[0];
      console.log(input);
      input?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  }, [errors, setFocus]);

  const sessionsBetweenDates = useSelector((state) =>
    getAllSessionsInDates(state)
  );

  const {
    avail,
    primaryBtn,
    secondaryBtn,
    show,
    onHide,
    rates,
    userId,
    firstName,
    lastName,
    mentorRatePerSession,
    handleDefaultRate,
  } = props;

  const dispatch = useDispatch();
  let [value, setDateValue] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [areaOfInterest, setAreaOfInterest] = useState([]);
  const [titleCount, setTitleCount] = useState(0);
  const [descCount, setDescCount] = useState(0);
  const [defaultRate, setDefaultRate] = useState("");

  const selectedLanguage = useSelector((state) => languageValue(state));

  let isLoad = false;
  const getFileUrls = (fileUrls, deleted) => {
    if (deleted) {
      setValue("files", "");
    }
    if (fileUrls?.length === 0) return;
    const files = [];
    fileUrls?.map((file) => {
      const data = {
        fileName: file?.fileName,
        fileUrl: file?.url,
      };
      files.push(data);
    });
    setValue("files", files);
  };

  useEffect(() => {
    if (!isLoad) {
      (async function () {
        dispatch(incrementLoader());
        setIsLoading(true);
        // dispatch(
        //   getUserAvailabilityAsyncThunk({ userId })
        // );
        const aoi = await GetDataType("requestCategory", selectedLanguage);
        setAreaOfInterest(aoi);
        dispatch(decrementLoader());
        setIsLoading(false);
      })();
    }
    return () => {
      isLoad = true;
    };
  }, []);

  const onChange = useCallback(
    (val) => {
      setDateValue(val);
    },
    [setDateValue]
  );

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [activeDate, setActiveDate] = useState(new Date());
  const isDisabled = useCallback(
    (date) => {
      let days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      let availDays = [];
      let everyDay = false;
      avail?.map((avails) =>
        days[date.getDay()] == avails.availability
          ? availDays.push(date)
          : avails.availability == "Everyday"
          ? (everyDay = true)
          : ""
      );
      let act = activeDate;
      const current = moment(new Date()).format("yy-MM-DD");
      // console.log(availDays.includes(date)  && moment(date, "day").isAfter(moment(new Date())) && moment(activeDate, "day").isSame(moment(new Date())))
      // console.log( moment(date, "day").isAfter(moment(new Date())) , moment(activeDate, "day").isSame(moment(new Date(), "day")), moment(activeDate, "date"),moment(new Date(), "day"))
      // console.log(availDays.includes(activeDate)  , moment(date, "day").isAfter(moment(new Date())) ,  moment(act).isSame( moment(current)),act,current)
      if (!availDays.includes(act)) {
        // console.log(activeDate,'activeDate',date,act);
        act = date;
        setActiveDate(date);
      }
      if (moment(date, "day").isBefore(moment(new Date()), "day")) {
        return true;
      } else if (!availDays.includes(date) && !everyDay) {
        return true;
      }
    },
    [avail]
  );
  const {
    currentSession,
    setCurrentSessions,
    onDateChange,
    onMonthChange,
    isHighlighted,
    availableSlots,
  } = useavailableSlots({
    isByUser: false,
    userId: userId,
    rates: rates,
    sessionAvailable: avail,
  });

  const [showPopup, setShowPopup] = useState(false);

  const onSubmit = async (data) => {
    dispatch(incrementLoader());
    try {
      setIsSubmitLoading(true);
      const endTime = availableSlots.filter(
        (availableSlot) => availableSlot.startTime == data.slot
      );
      const endSlot = endTime ? endTime[0].endTime : "";
      const startTime = endTime ? endTime[0].startTime : "";
      const rate = rates.filter(
        (r) => r.timeFramePerSession == data.numberOfSessions
      );
      console.log(data, rates, rate, "finalData");
      if (rate[0]?.rate === 0) {
        const finalData = createBookaSession(
          authContext.sessionInfo.sub,
          data,
          userId,
          endSlot,
          startTime,
          authContext.sessionInfo.firstName,
          authContext.sessionInfo.lastName,
          firstName,
          lastName,
          rates[0]?.time
        );
        const book = await BookingaSession(
          authContext.sessionInfo.sub,
          finalData
        )
          .then((res) => {
            dispatch(loader(false));
            setIsSubmitLoading(false);
            setShowPopup(true);
          })
          .catch((err) => console.log(err));
      } else {
        const finalData = StipeBookaSession(
          authContext.sessionInfo.sub,
          data,
          userId,
          endSlot,
          startTime,
          authContext.sessionInfo.firstName,
          authContext.sessionInfo.lastName,
          firstName,
          lastName,
          rates[0]?.time
        );
        const stripeData = {
          curreny: rates[0]?.curreny,
          slot: {
            ...finalData,
          },
          rate: rate[0]?.rate,
        };
        checkoutToStripe(stripeData)
          .then((res) => {
            setIsSubmitLoading(false);
            if (res.url) {
              window.location.href = res.url;
            }
          })
          .catch((err) => console.log(err));
      }
    } catch (err) {
      console.log(err);
      dispatch(loader(false));
    } finally {
      dispatch(decrementLoader());
    }
  };

  useEffect(() => {
    if (show) {
      const value = localStorage.getItem("defaultRate");
      setDefaultRate(value);
      if (!value) {
        setDefaultRate(RatesTimeFrame.perSession);
      }
    }
  }, [show]);

  return (
    <>
      <Popup
        closeOnBackClick={false}
        showPopup={showPopup}
        onHide={() => setShowPopup(false)}
        type="popup"
        text={formElementLabels?.buttonSave}
        modalBody={dashboardTranslations?.bookingPopUp}
        url={AppUrlsEnum.menteeDashboard}
      />
      {isSubmitLoading && (
        <div className="loader-background">
          <div className="stripe_loader">
            <Spinner animation="border" variant="primary" />
          </div>
        </div>
      )}
      <Modal
        size={"lg"}
        show={show}
        onHide={() => {
          clearErrors();
          return onHide(false);
        }}
        className="book-session"
      >
        <Modal.Header closeButton>
          <Modal.Title>{dashboardTranslations?.bookSession}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body scrollable={true}>
            <Container fluid>
              <ProfileInfo showVerified={true} {...props} hideBtn={true} />
              <h2>{dashboardTranslations?.mentorOption}</h2>
              <section className="select-plans d-flex mb-4">
                <Controller
                  name={`time`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <Form.Control
                      type="hidden"
                      value={rates ? rates[0]?.time : ""}
                      onChange={onChange}
                      onBlur={onBlur}
                      name={name}
                    ></Form.Control>
                  )}
                />
                <Col>
                  {rates?.map((rate) => {
                    // console.log(rate ,'rate')
                    let title = "";
                    if (rate?.timeFrame == RatesTimeFrame.perSession)
                      title =
                        getSessionType(rate?.timeFrame) +
                        " Session (" +
                        rate?.time +
                        ")";
                    else
                      title =
                        getSessionType(rate?.timeFrame) +
                        " (" +
                        rate?.timeFramePerSession +
                        " Session)";
                    title = title.charAt(0).toUpperCase() + title.slice(1);
                    const subtitle =
                      rate?.rate > 0
                        ? `${_getCurrencySymbol(rate?.curreny)} ${rate?.rate}`
                        : `${formElementLabels?.placeHolders?.free}`;
                    return (
                      <Controller
                        name={`numberOfSessions`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name },
                        }) => (
                          <CardRadioBtn
                            id={rate?.timeFrame}
                            title={title}
                            subtitle={subtitle}
                            name={name}
                            value={rate?.timeFramePerSession}
                            onBlur={onBlur}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            defaultChecked={
                              rate?.timeFrame === defaultRate ? true : false
                            }
                            className="mt-2 me-4"
                          />
                        )}
                      />
                    );
                  })}
                </Col>
              </section>
              <FormErrorComponent>
                {errors.numberOfSessions && errors.numberOfSessions?.message}
              </FormErrorComponent>
              <Row className="calendar-section">
                <Col>
                  <h4>{dashboardTranslations?.selectDate}</h4>
                  <CustomCalendar
                    onDateChange={onDateChange}
                    sessionDates={sessionsBetweenDates}
                    onDateMonthChange={onMonthChange}
                    isHighlighted={isHighlighted}
                    isDisable={isDisabled}
                    activeDate={activeDate}
                  />
                </Col>
                <Col className="select-times">
                  <h4>{dashboardTranslations?.selectTime}</h4>
                  <div className="time-select">
                    {availableSlots?.map((availableSlot) => (
                      <Controller
                        name={`slot`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name },
                        }) => (
                          <CardRadioBtn
                            id={availableSlot.startTime}
                            title={moment
                              .unix(availableSlot.startTime)
                              .format("hh:mm a")}
                            value={availableSlot.startTime}
                            className="me-3"
                            onBlur={onBlur}
                            onChange={onChange}
                            name={name}
                          />
                        )}
                      />
                    ))}
                    {availableSlots?.length == 0 ? (
                      <>
                        <FormLabelComponent>
                          No Slots Available
                        </FormLabelComponent>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                <FormErrorComponent>
                  {errors.slot && errors.slot?.message}
                </FormErrorComponent>
              </Row>
              <h2>{dashboardTranslations?.describeRequest}</h2>
              <section className="request-form">
                <Form.Group className="mb-3" controlId="formmentorId">
                  <Controller
                    name={`mentorId`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Form.Control
                        type="hidden"
                        value={userId}
                        onChange={onChange}
                        onBlur={onBlur}
                      ></Form.Control>
                    )}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formTitle">
                  <FormLabelComponent>
                    {dashboardTranslations?.nameRequest}
                  </FormLabelComponent>

                  <Controller
                    name={`title`}
                    control={control}
                    render={({ field: { onChange, onBlur, value, name } }) => (
                      <CustomInputField
                        placeholder={formElementLabels?.placeHolders?.title}
                        hookOnChange={onChange}
                        onChange={(e) => {
                          setTitleCount(e.length);
                        }}
                        hookOnBlur={onBlur}
                        hookValue={value}
                        min={30}
                        max={50}
                        name={name}
                      />
                    )}
                  />
                  <Row>
                    <Col xs={9}>
                      {" "}
                      <FormErrorComponent>
                        {errors.title && errors.title?.message}
                      </FormErrorComponent>
                    </Col>
                    <Col xs={3}>
                      <div>
                        <span className="ig-name-length float-end">
                          {titleCount} {formElementLabels?.minCharacter}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3 aoi">
                  <Form.Label>
                    <FormLabelComponent>
                      {dashboardTranslations?.describeInterest}
                    </FormLabelComponent>
                  </Form.Label>
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <CustomSelect
                        opLabel="value"
                        opValue="dataId"
                        items={areaOfInterest}
                        onReactHookFormBlur={onBlur}
                        sendFullValueInHook={true}
                        onReactHookFormChange={onChange}
                        value={value}
                        normalSelect={true}
                        isMulti={true}
                        placeholder={dashboardTranslations?.selectInterest}
                        ref={ref}
                      />
                    )}
                    name="areasOfInterest"
                  />
                  <FormErrorComponent>
                    {errors.areasOfInterest && errors.areasOfInterest?.message}
                  </FormErrorComponent>
                </Form.Group>

                <Form.Group className="mb-3">
                  <FormLabelComponent>
                    {dashboardTranslations?.describeDescription}
                  </FormLabelComponent>
                  <Controller
                    name={`description`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInputField
                        placeholder={
                          formElementLabels?.placeHolders?.bookDescription
                        }
                        onChange={(e) => {
                          setDescCount(e.length);
                        }}
                        hookOnChange={onChange}
                        hookOnBlur={onBlur}
                        hookValue={value}
                        formAs={"textarea"}
                        rows={5}
                        min={30}
                        max={255}
                      />
                    )}
                  />
                  <Row>
                    <Col xs={9}>
                      {" "}
                      <FormErrorComponent>
                        {errors.description && errors.description?.message}
                      </FormErrorComponent>
                    </Col>
                    <Col xs={3}>
                      <div>
                        <span className="ig-name-length float-end">
                          {descCount} {formElementLabels?.minCharacter}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                  <span className="file-upload">
                    <FileUploader
                      maxCount={3}
                      fileUrls={getFileUrls}
                      uploadOnSelect={true}
                      uploadFileTypes={"image/*,.txt,.pdf,.xls"}
                      inputClass={"video-file-input"}
                      labelImage={_getImage("file.svg")}
                      uploadInputLabelText={"Upload files"}
                      uploadedtext={"Upload files"}
                      deleteFileImage={_getImage("Delete.svg")}
                    />
                  </span>
                </Form.Group>
              </section>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={() => {
                clearErrors();
                return onHide(false);
              }}
            >
              {secondaryBtn}
            </Button>
            <SubmitButton text={primaryBtn} disabled={isSubmitting} />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default BookSession;
