//** Iguroo Custom Badge Component,can re-use with required field **//

import React from "react";
import Badge from "react-bootstrap/Badge";

const CustomBadge = (props) => {
  const { text, type } = props;
  return (
    <>
      <Badge className={`me-2 ${type}-badge`} pill>
        {text}
      </Badge>
    </>
  );
};
export default CustomBadge;
