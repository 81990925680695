import { axiosStaticData } from "../axios.service";
import axios from "axios";
import {
  CREATE_TESTIMONIALS,
  STATUS_TESTIMONIALS,
  APPROVE_TESTIMONIALS,
  UPDATE_TESTIMONIALS,
  GET_UPDATE_TESTIMONIALS,
  UPDATE_TESTIMONIALS_ID,
  RESET_TESTIMONIALS,
} from "../../urlConst";
import { userPoolId } from "../../config";
import { IGURU_DEV_URL } from "../../config";

export const postTestimonials = async (data) => {
  const payload = {
    mentorId: data.mentorId,
    menteeId: data.userId,
    testimonialText: data.testimonialText,
  };
  console.log("payload-testimonials", payload);
  console.log("data-testi", data);
  const res = await axiosStaticData.post(CREATE_TESTIMONIALS, payload);
  console.log("testimonials", res.data);
  return res.data;
};

export const getTestimonials = async () => {
  const URL = `${STATUS_TESTIMONIALS}`;
  const res = await axiosStaticData.get(URL);
  console.log("get-testimonilas", res.data.response.Items);
  return res.data.response.Items;
};

export const postApproveTestimonials = async (data) => {
  const payload = {
    mentorId: data.mentorId,
    status: data.status,
    testimonialId: data.testimonialId,
    testimonialText: data.testimonialText,
  };
  console.log("approve-testimonials", payload);
  console.log("data-testi-approve", data);
  const res = await axiosStaticData.post(APPROVE_TESTIMONIALS, payload);
  // console.log("approve-testimonials", res.data);
  return res.data;
};
export async function getUpdateMentorTestimonials(mentor_id) {
  try {
    const URL = IGURU_DEV_URL + UPDATE_TESTIMONIALS + `?mentor_id=${mentor_id}`;
    const response = await axiosStaticData.get(URL);
    console.log(response.data.response);
    return response.data.response;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}
export async function getMentorMenteeTestimonials(mentor_id, mentee_id) {
  try {
    const URL =
      IGURU_DEV_URL +
      RESET_TESTIMONIALS +
      `?mentor_id=${mentor_id}&mentee_id=${mentee_id}`;
    const response = await axiosStaticData.get(URL);
    console.log(response.data.response, "API Response");
    return response.data.response;
  } catch (err) {
    console.log("API Res err", err);
    throw err;
  }
}
export async function getUpdateTestimonialsId(testimonial_id) {
  try {
    const URL =
      IGURU_DEV_URL +
      UPDATE_TESTIMONIALS_ID +
      `?testimonial_id=${testimonial_id}`;
    const response = await axiosStaticData.get(URL);
    console.log(response);
    return response.data.response;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}
export async function getMentorTestimonials(mentor_id) {
  try {
    const URL =
      IGURU_DEV_URL + GET_UPDATE_TESTIMONIALS + `?mentor_id=${mentor_id}`;
    const response = await axiosStaticData.get(URL);
    console.log(response.data.response, "gdjjjjjjjjjfingdfijvndfijgf");
    console.log("API URL:", URL);
    return response.data.response;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export const postUpdateTestimonials = async (data) => {
  const payload = {
    mentorId: data.mentorId,
    menteeId: data.menteeId,
    status: data.status,
    testimonialText: data.testimonialText,
    testimonialId: data.testimonialId,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
  };
  console.log("updateee-testimonials", payload);
  console.log("updatedata-testi-approve", data);
  const res = await axiosStaticData.post(UPDATE_TESTIMONIALS, payload);
  // console.log("approve-testimonials", res.data);
  return res.data;
};
