//** S3 FileUploader & DeleteFile Hooks **//

import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../hooks/auth.context.provider";
import { S3Folders } from "../enums";
import { toast } from "react-toastify";
import { S3_BUCKET } from "../services/config";
import Toaster from "../components/toaster";
require(`../css/${process.env.REACT_APP_CSS_INPUT}`);

export const useFileUploader = (props) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [urls, setUrls] = useState([]);
  const authContext = useContext(AuthContext);
  const [fileToUpload, setFileToUpload] = useState();
  const MAX_COUNT = props.maxCount;
  console.log(
    "uploaded props",
    props?.uploadedFileUrls,
    props?.uploadedFileUrls?.length > 0 ? true : false
  );
  const [fileLimit, setFileLimit] = useState(
    props?.uploadedFileUrls?.length > 0 ? true : false
  );
  console.log("uploaded props", props?.uploadedFileUrls, fileLimit);

  const uploadFile = useCallback(
    (uploaded) => {
      console.log("setting file limit to false", fileLimit);
      if (fileLimit) {
        Toaster(
          toast.error,
          "Please delete an already uploaded file to upload a new one."
        );
        return;
      }
      const numberOfFiles = uploaded ? uploaded.length : uploadedFiles.length;
      const files = uploaded ? uploaded : uploadedFiles;
      setUploading(true);
      let fNumber = 0;
      const fUrls = [];
      console.log("no of file", numberOfFiles);
      files.forEach(async (file) => {
        const url = await authContext.uploadFileToS3({
          file: file,
          folder: S3Folders.images,
          bucketName: props.bucketName ? props.bucketName : S3_BUCKET,
        });
        //uploadFileToS3(file,authContext.sessionInfo.sub, S3Folders.video);
        //const furl = await getUploadFileFromS3(url.key);
        //console.log("uploaded url",furl);
        if (url) fUrls.push({ fileName: file.name, url: url });
        setProgress((fNumber / numberOfFiles) * 100);
        fNumber++;
        console.log("fn", fNumber, numberOfFiles);
        if (fNumber === numberOfFiles) {
          setUploading(false);
          setUrls(fUrls);
          props.fileUrls(fUrls);
        }
      });
    },
    [fileLimit, uploadedFiles, setUploading, setProgress, setUrls]
  );

  const deleteFile = async (file) => {
    console.log(file, "u-file");
    const updatedFiles = uploadedFiles.filter((f) => f.name != file.name);
    const updatedUrls = urls.filter((f) => f.fileName != file.name);
    setUploadedFiles(updatedFiles);
    setUrls(updatedUrls);
    props.fileUrls(updatedUrls, true);
    console.log("setting file limit to false");
    setFileLimit(false);
  };

  const deleteFileUrl = async (url) => {
    console.log("setting file limit to false");

    props.fileUrls(url, true);
    setFileLimit(false);
  };

  console.log("file limit", fileLimit);
  const handleUploadFiles = useCallback(
    (files, fileType) => {
      console.log("setting file limit to false", fileLimit);

      if (fileLimit) {
        Toaster(
          toast.error,
          "Please delete an already uploaded file to upload a new one."
        );
        return;
      }
      const uploaded = [...uploadedFiles];
      console.log("up", uploaded, files);
      let limitExceeded = false;
      files.some((file) => {
        if (uploaded.findIndex((f) => f.name === file.name) === -1) {
          uploaded.push(file);
          if (uploaded.length === MAX_COUNT) setFileLimit(true);
          if (uploaded.length > MAX_COUNT) {
            alert(`You can only add a maximum of ${MAX_COUNT} files`);
            setFileLimit(false);
            limitExceeded = true;
            return true;
          }
          if (!fileType.includes("video") && file.size > 100000) {
            Toaster(toast.error, "Please upload files less than 100KB");
            setFileLimit(false);
            limitExceeded = true;
            return true;
          }
        }
      });
      console.log(uploadedFiles.length, "uploadedFiles");
      if (!limitExceeded) setUploadedFiles(uploaded);
      if (props.uploadOnSelect) {
        uploadFile(uploaded);
      }
    },
    [fileLimit, uploadedFiles, setFileLimit, uploadFile]
  );

  const handleFileEvent = useCallback(
    (e) => {
      console.log(
        "Please upload  Format",
        e.target.attributes.accept.value,
        fileLimit,
        uploadedFiles
      );
      const fileType = e.target.attributes.accept.value;
      if (
        !e.target.files[0].type.includes("video") &&
        fileType.includes("video")
      ) {
        console.log("Please upload only Video Format");
        Toaster(toast.error, "Please upload only Video Format");
      } else {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadFiles(chosenFiles, fileType);
      }
    },
    [handleUploadFiles]
  );

  useEffect(() => {
    (async function () {
      console.log(props?.uploadedFileUrls, "$$$$$$$");
      if (props?.uploadedFileUrls?.length > 0) {
        setFileLimit(true);
      } else {
        setFileLimit(false);
      }
    })();
  }, [props]);

  useEffect(() => {
    if (fileToUpload) {
      handleFileEvent(fileToUpload);
    }
  }, [fileToUpload]);

  return {
    uploading,
    uploadedFiles,
    fileLimit,
    handleFileEvent,
    uploadFile,
    deleteFile,
    deleteFileUrl,
    setFileToUpload,
  };
};
