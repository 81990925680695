//** GinieHealth Learner UI Screen  **//

import React, {
    useEffect,
    useState,
    useMemo,
    useContext,
    useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { useRef } from "react";
import { _getImage } from "../../common/utils/commonFunctions";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useLocation, useNavigate, } from "react-router-dom";
import { AuthContext } from "../../hooks/auth.context.provider";
import { loader } from "../../redux-tk/slices/loader.slice";
import { GetAssesmentAssignmentByUserIdApi, GetAssessmentTranscriptByUserIdApi } from "@iguroo/ig-react-assessment-package";
import {
    getMyAssessmentThunk,
} from "../../redux-tk/slices/assessment.slice";
import { AssessmentName, Name, Pdf, StatusTag } from "./CellRenderers";
import { pdfjs } from "react-pdf";
import moment from "moment";
import { Heading } from "../../components/heading.component";
import { Button, Col, Row } from "react-bootstrap";
import { AgGridNoRowsParams, AppUrlsEnum, ProfileType } from "../../enums";
import { BackButton } from "../../components/back.button";
import TodoStatus from "../SuperVisor/Status.Todo";
import { formatDate } from "../SuperVisor/SuperVisorTags";
import { AgGridMain } from "../../components/agGridRenderers/ag.grid.main";

const UserAssessmentList = (props) => {
    const dispattch = useDispatch();
    const [showTranscriptAttempt, setShowTranscriptAttempt] = useState(null);

    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [isGridLoading, setIsGridLoading] = useState(false);
    const authContext = useContext(AuthContext);
    const state = location.state;
    const selectedUserID = state?.selectedUserID ? state.selectedUserID : null;
    const selectedUserName = state?.selectedUserName ? state.selectedUserName : null;
    const userInfo = useMemo(() => {
        return authContext?.sessionInfo;
    }, [authContext.authStatus]);

    useEffect(() => {

    }, []);



    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const dispatch = useDispatch();
    const onGridReadyNew = useCallback(
        (params) => {
            console.log("Refresh");
            const dataSource = {
                rowCount: null,
                getRows: async function (rparams) {
                    try {
                        gridRef.current?.api?.showLoadingOverlay();
                        const filterModel = rparams.filterModel;
                        const endRow = rparams.endRow;
                        const startRow = rparams.startRow;
                        const inputQuery = {
                            from: startRow,
                            limit: endRow - startRow,
                        };


                        const assessmentData = {
                            userId: selectedUserID,
                            from: startRow,
                            limit: endRow - startRow,

                        };
                        console.log("GetAssesmentAssignmentByUserIdApi transcriptURL", assessmentData)


                        const d = await GetAssesmentAssignmentByUserIdApi(assessmentData);

                        if (d.success === true) {
                            const users = d.response.data.map((user, index) => {
                                const completedDate = user.completedDate
                                    ? moment(user.completedDate).format(
                                        process.env.REACT_APP_DATE_FORMAT
                                    )
                                    : "";
                                if (
                                    user?.transcript_url === null ||
                                    user?.transcript_url === "" ||
                                    user?.transcript_url === undefined
                                ) {
                                    setTimeout(async () => {
                                        setIsGridLoading(true);
                                    }, 3000);
                                    console.log(user, "transcriptURL");
                                }
                                console.log(user, "transcriptURL");
                                return {
                                    SI: index + 1,
                                    "Assessment Title": user?.assessmentData[0]?.title,
                                    Grade:
                                        user?.evaluation_type == "STANDARD" ||
                                            user?.evaluation_type == "MULTI_CHOICE" ||
                                            user?.evaluation_type == "GENIE_MULTI_CHOICE"
                                            ? `${user?.total_score?.toString()}`
                                            : user?.total_score?.toString(),
                                    "due_datetime": user?.due_datetime,
                                    "Completed Date": completedDate ? completedDate : "Not Completed",
                                    status: user?.status,
                                    Report: {
                                        url: user?.transcript_url,
                                        transcript_download: userInfo?.role.toUpperCase() === ProfileType.recruiter ? true : user?.assessment?.transcript_download,
                                        openPopup: "hide",
                                        status: user?.status,
                                    },
                                    start_datetime: user?.start_datetime,
                                };
                            });
                       

                        console.log("Refresh call", d);
                        if (d?.response?.total === 0) {
                            gridRef.current?.api?.showNoRowsOverlay("No Assessment Assignments");
                        } else {
                            gridRef.current?.api?.hideOverlay();
                        }
                      
                        rparams.successCallback(users, d?.response?.total);
                    } else {
                    }
                    } catch (err) {
                        console.log("params", err);
                        gridRef?.current?.api?.showNoRowsOverlay("No Assessment Assignments");
                    }
                },
            };
            params?.api?.setDatasource(dataSource);
        },
        [isGridLoading, setIsLoading]
    );



    const [columnDefs] = useState([
        {
            field: "SI",
            headerName: "S.No.",
            maxWidth: 100,
        },
        {
            field: "Assessment Title",
            cellRenderer: AssessmentName,
            filter: true,
            sortable: true,
            cellStyle: { "word-break": "break-word" },
            minWidth: 200,
            maxWidth: 250,
        },

        {
            field: "due_datetime",
            headerName: "Due Date",
            filter: true,
            sortable: true,
            maxWidth: 250,
            cellRenderer: formatDate,
        },
        {
            field: "status",
            headerName: "Status",
            cellRenderer: TodoStatus,
            filter: true,
            sortable: true,
            maxWidth: 150,
        },
        {
            field: "Completed Date",
            filter: true,
            sortable: true,
            maxWidth: 250,
        },
        {
            field: "Grade",
            filter: true,
            maxWidth: 150,
            // sortable: true,
        },
        {
            field: "Report",
            cellRenderer: Pdf,
            filter: false,
            unSortIcon: false,
            sortable: false,
            maxWidth: 80,
        },
    ]);

    const defaultColDef = useMemo(() => ({
        flex: 1,
        floatingFilter: true,
        unSortIcon: true,
    }));

    const rowHeight = 85;

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const navigate = useNavigate();
    const onPageChange = useCallback((params) => {
        window.scroll({
            top: 30,
            behavior: "smooth",
        });
    }, []);
    return (
        <>
            <div className="m-2 p-2 publisherContainer">
                <div className="my-4 ">
                    <Row className="align-items-center">
                        <Col md={1} sm={3} className="pe-0">
                            {/* <Button onClick={()=>navigate(AppUrlsEnum.superVisorGinieUser)} 
                        variant="outline-info" className="back-btn"> Back</Button> */}
                            <BackButton
                                text="Back"
                                className="back-btn"
                                onClick={() => navigate(AppUrlsEnum.superVisorGinieUser)}
                            />
                        </Col>
                        <Col md={11} sm={9}>
                            <Heading>
                                List of Assessment Assignments for <span className="genie-transcript-name">{selectedUserName}</span>
                            </Heading></Col>
                    </Row>

                </div>

                <div className="ag-theme-alpine" >
                    {/* <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        pagination={true}
                        paginationPageSize={10}
                        rowHeight={rowHeight}
                        suppressDragLeaveHidesColumns={true}
                        className="user-table-transcript"

                    /> */}
                    <AgGridMain
                        gridRef={gridRef}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReadyNew}
                        onPaginationChanged={onPageChange}
                        pageObj={{ pageSize: 20,cacheBlockSize:20,maxBlocksInCache: 20 }}
                         className="user-table"
                        noRowsParams={{ [AgGridNoRowsParams.isAssessmentUsers]: true }}
                    />
                </div>


            </div>
        </>
    );
};
export default UserAssessmentList;
