import { _getImage } from "../../common/utils/commonFunctions";
import { ProfileImage } from "../Media";
import "./user.renderer.scss";

//** Mentee & Mentor Request Data **//
export const UserRenderer = ({ value }) => {
  if (!value) {
    return <></>;
  }
  console.log("user value", value);
  return (
    <>
      <ProfileImage
        resizeMode="contain"
        className="img-fluid profile-pic-table"
        src={value?.profilePic?value?.profilePic:''}
      />
      <span className="ig-agGrid-username ps-1">
        {value?.firstName} {value?.lastName}
      </span>
    </>
  );
};
