//** GinieHealth TodoSuperVisor Aggrid Status Ui code**//

import React, { useState } from "react";
import { useEffect } from "react";

const TodoStatus = (props) => {
  const [bg, setBg] = useState("");
  const [text, setText] = useState("");
  console.log(props, "s PLANNED");
  useEffect(() => {
    if (props.data) {
      const currentTime = new Date().getTime();
      const startDateTime = props.data.start_datetime;
      const dueDateTime = props.data.due_datetime;

      if (props.data.status === "PLANNED") {
        if (currentTime < startDateTime) {
         
          setBg("#EE7E0E"); // Planned
          setText("ASSIGNED");
        } else if (startDateTime <= currentTime && currentTime <= dueDateTime) {
          setBg("#0d6efd"); // Active
          setText("PENDING");
        } else {
          setBg("#D73D3D"); //Expired
          setText("EXPIRED");
        }
      } else if (props.data.status === "ACTIVE") {
        if (currentTime <= dueDateTime) {
          setBg("#0d6efd"); // Active
          setText("PENDING");
        } else {
          setBg("#D73D3D"); // Expired
          setText("EXPIRED");
        }
      } else if (props.data.status === "COMPLETED") {
        setBg("#2FAA6F"); //Completed
        setText("COMPLETED");
      } else if (props.data.status === "REVOKED") {
        setBg("#ffa91e"); //REVOKED
        setText("REVOKED");
      } else if (props.data.status === "PENDING") {
        if (currentTime <= dueDateTime) {
          setBg("#0d6efd"); // Active
          setText("PENDING");
        } else {
          setBg("#D73D3D"); // Expired
          setText("EXPIRED");
        }

      }else if (props.data.status === "EXPIRED") {
        setBg("#D73D3D"); //Expired
        setText("EXPIRED");
      }
    }
  }, [props.data]);

  return (
    <>
      <div
        style={{
          fontWeight: "500",
          fontSize: "13px",
          backgroundColor: bg,
          border: `1px solid ${bg}`,
          borderRadius: "4px",
          padding: "4px 8px",
          color: "white",
          height: "30px",
          width: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5px",
        }}
        className={`text-bg-${bg}`}
      >
        {text}
      </div>
    </>
  );
};

export default TodoStatus;
