import * as yup from "yup";
export function getEducationFormTranslated(formErrors) {
  return yup.object({
    edu: yup.array().of(
      yup.object({
        id: yup.string().optional(),
        instituteName: yup
          .string()
          .required(formErrors?.instituteNameIsRequired)
          .matches(/^[a-zA-Z0-9\s&,().-]+$/, formErrors?.nameNumSplCharcError),
        courseName: yup
          .object({
            value: yup.string(),
            dataId: yup.string(),
            dataType: yup.string(),
          })
          .required(formErrors?.courseNameIsRequired)
          .typeError(formErrors?.courseNameIsRequired),
        specialization: yup
          .object({
            value: yup.string(),
            dataId: yup.string(),
            dataType: yup.string(),
          })
          .required(formErrors?.fieldofStudyIsRequired)
          .typeError(formErrors?.fieldofStudyIsRequired),
        year: yup
          .object({
            value: yup.string(),
            dataId: yup.string(),
            dataType: yup.string(),
          })
          .required(formErrors?.educationYearIsRequired)
          .typeError(formErrors?.educationYearIsRequired),
      })
    ),
  });
}
