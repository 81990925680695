//** Key and Value ofMentorOnboarding Translations -> Check with Value name in en.language.json **//

import { AppUrlsEnum } from "../../../enums";

export function MentorOnboardingTranslations(t) {
  return {
    key: "mentorOnboardingLabels",
    value: {
      createMentorProfile: t("mentorOnboardingLabels.createMentorProfile"),
      areaOfEmphasis: t("mentorOnboardingLabels.areaOfEmphasis"),
      availability: t("mentorOnboardingLabels.availability"),
      startTime: t("mentorOnboardingLabels.startTime"),
      endTime: t("mentorOnboardingLabels.endTime"),
      timeZone: t("mentorOnboardingLabels.timeZone"),
      menitoringSince: t("mentorOnboardingLabels.menitoringSince"),
      gender: t("mentorOnboardingLabels.gender"),
      profile: t("mentorOnboardingLabels.profile"),
      language: t("mentorOnboardingLabels.language"),
      location: t("mentorOnboardingLabels.location"),
      country: t("mentorOnboardingLabels.country"),
      state: t("mentorOnboardingLabels.state"),
      city: t("mentorOnboardingLabels.city"),
      profileTitle: t("mentorOnboardingLabels.profileTitle"),
      profileTitleNote: t("mentorOnboardingLabels.profileTitleNote"),
      description: t("mentorOnboardingLabels.description"),
      uploadProfileVideo: t("mentorOnboardingLabels.uploadProfileVideo"),
      fieldofStudy: t("mentorOnboardingLabels.fieldofStudy"),
      endofYear: t("mentorOnboardingLabels.endofYear"),
      role: t("mentorOnboardingLabels.role"),
      company: t("mentorOnboardingLabels.company"),
      yearofexp: t("mentorOnboardingLabels.yearofexp"),
      endYear: t("mentorOnboardingLabels.endYear"),
      field: t("mentorOnboardingLabels.field"),
      degree: t("mentorOnboardingLabels.degree"),
      school: t("mentorOnboardingLabels.school"),
      profilePhoto: t("mentorOnboardingLabels.profilePhoto"),
      oneSessionLeaveZero: t("mentorOnboardingLabels.oneSessionLeaveZero"),
      onboardingSuccess: t("mentorOnboardingLabels.onboardingSuccess"),
      unsavedWarning: t("menteeOnboardingLabels.unsavedWarning"),
      warning: t("menteeOnboardingLabels.warning"),
      done: t("menteeOnboardingLabels.done"),
      popupDone: t("menteeOnboardingLabels.gotIt"),
      yes: t("menteeOnboardingLabels.yes"),
      cancel: t("menteeOnboardingLabels.cancel"),
      uploadedProfileVideo: t("mentorOnboardingLabels.uploadedProfileVideo"),
      successMessage: t("menteeOnboardingLabels.successMessage"),
      rightSetupModule: {
        mentor: {
          title: t("rightSetupModule.title"),
          values: {
            basicInfo: {
              key: "basicInfo",
              value: t("rightSetupModule.basicInfo"),
              url: AppUrlsEnum.mentorProfile,
            },
            aoe: {
              key: "aoe",
              value: t("rightSetupModule.aoe"),
              url: AppUrlsEnum.mentorAreaOfExperience,
            },
            we: {
              key: "we",
              value: t("rightSetupModule.we"),
              url: AppUrlsEnum.mentorWorkExperience,
            },
            edu: {
              key: "edu",
              value: t("rightSetupModule.edu"),
              url: AppUrlsEnum.mentorEducation,
            },
            avail: {
              key: "avail",
              value: t("rightSetupModule.avail"),
              url: AppUrlsEnum.mentorAvailability,
            },

            rates: {
              key: "rates",
              value: t("rightSetupModule.rates"),
              url: AppUrlsEnum.mentorRates,
            },
          },
        },
      },
    },
  };
}
