//** Iguroo Mentee Onboarding Data Slice **//

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  WORK_EXPERIENCE_TEMPLATE,
  EDUCATION_TEMPLATE,
} from "../../screens/mentee/educationExperience";
import {
  DeleteEducation,
  DeleteWorkExp,
  GetEducation,
  GetWorkExp,
} from "../../services/axios/MentorOnbaording";
import { incrementLoader, decrementLoader } from "./loader.slice";

export const getWorkExpAsyncThunk = createAsyncThunk(
  "mentee/getWorkExpThunk",
  async (data, thunkApi) => {
    try {
      const workExp = await GetWorkExp(data.userId);
      return workExp.data;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);

function getFromPath(obj, path) {
  let r = obj;
  path.forEach((key) => {
    r = r[key];
  });
  return r;
}

function sortObjectsArr(objectsArray, ...path) {
  objectsArray.sort((a, b) => getFromPath(b, path) - getFromPath(a, path));
}

export const getEducationAsyncThunk = createAsyncThunk(
  "mentee/getEducationThunk",
  async (data, thunkApi) => {
    try {
      const workExp = await GetEducation(data.userId);
      sortObjectsArr(workExp.data, "year", "value");
      return workExp.data;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);

export const deletWorkExpAsyncThunk = createAsyncThunk(
  "mentee/deleteWorkExpThunk",
  async (data, thunkApi) => {
    try {
      thunkApi.dispatch(incrementLoader());
      const workExp = await DeleteWorkExp(data.id);
      return data.id;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    } finally {
      thunkApi.dispatch(decrementLoader());
    }
  }
);

export const deleteEducationAsyncThunk = createAsyncThunk(
  "mentee/deleteEducationThunk",
  async (data, thunkApi) => {
    try {
      thunkApi.dispatch(incrementLoader());
      const workExp = await DeleteEducation(data.id);
      return data.id;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    } finally {
      thunkApi.dispatch(decrementLoader());
    }
  }
);

const initialState = {
  workExp: {
    edu: [EDUCATION_TEMPLATE],
    wrk: [WORK_EXPERIENCE_TEMPLATE],
  },

  profileData: {},
  skillData: {},
};

export const menteeOnboardingSlice = createSlice({
  name: "menteeOnboardingFormData",
  initialState,
  reducers: {
    workExpAndEDUAction: (state, action) => {
      // console.log(state,action,'fdafaf')
      state.workExp.edu = [...action.payload.edu];
      state.workExp.wrk = [...action.payload.wrk];
    },

    profileDataAction: (state, action) => {
      if (!action.payload) return;
      state.profileData = { ...action.payload };
    },
    skillDataAction: (state, action) => {
      console.log(state, action, "skillDataAction");
      if (!action.payload) return;
      state.skillData = { ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkExpAsyncThunk.fulfilled, (state, action) => {
        //console.log("action)", action);
        state.workExp.wrk = [...action.payload];
      })
      .addCase(getWorkExpAsyncThunk.pending, (state) => {})
      .addCase(getWorkExpAsyncThunk.rejected, (state) => {})
      .addCase(deletWorkExpAsyncThunk.fulfilled, (state, action) => {
        //console.log("action)", action);
        let indexFound = -1;
        for (let i = 0; i < state.workExp.wrk.length; i++) {
          const ele = state.workExp.wrk[i];
          if (ele.id === action.payload) {
            indexFound = i;
            break;
          }
        }
        if (indexFound != -1) {
          console.log("deleting", indexFound, state.workExp.wrk[indexFound]);
          state.workExp.wrk.splice(indexFound, 1);
        }
      })
      .addCase(deletWorkExpAsyncThunk.pending, (state) => {})
      .addCase(deletWorkExpAsyncThunk.rejected, (state) => {})
      .addCase(getEducationAsyncThunk.fulfilled, (state, action) => {
        //console.log("action)", action);
        state.workExp.edu = [...action.payload];
      })
      .addCase(getEducationAsyncThunk.pending, (state) => {})
      .addCase(getEducationAsyncThunk.rejected, (state) => {})

      .addCase(deleteEducationAsyncThunk.fulfilled, (state, action) => {
        //console.log("action)", action);
        let indexFound = -1;
        for (let i = 0; i < state.workExp.edu.length; i++) {
          const ele = state.workExp.edu[i];
          if (ele.id === action.payload) {
            indexFound = i;
            break;
          }
        }
        if (indexFound != -1) {
          console.log("deleting", indexFound, state.workExp.edu[indexFound]);
          state.workExp.edu.splice(indexFound, 1);
        }
      })
      .addCase(deleteEducationAsyncThunk.pending, (state) => {})
      .addCase(deleteEducationAsyncThunk.rejected, (state) => {});
  },
});

export const {
  workExpAndEDUAction,

  profileDataAction,
  skillDataAction,
} = menteeOnboardingSlice.actions;

export const workExpAndEduValue = (state) => state.menteeOnboarding.workExp;
export const profileDataValue = (state) => state.menteeOnboarding.profileData;
export const skillDataValue = (state) => {
  console.log(state, "statementee");
  return state.menteeOnboarding.skillData;
};

export default menteeOnboardingSlice.reducer;
