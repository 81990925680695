import { axiosStaticData } from "@iguroo/ig-react-common-package";
import {
  GET_ORGANIZATION_ID,
  GET_USERROLE_ID,
  POST_IGUROO_NEWUSER,
  USER_MANAGEMENT,
} from "../../urlConst";

export const getUserManagement = async (from, limit, search) => {
  const params = {
    from: from,
    limit: limit,
    search: search,
  };
  const URL = USER_MANAGEMENT;
  const res = await axiosStaticData.get(URL, { params });
  // console.log("usermanent", res);
  return res;
};

export const addIgurooUser = async (userData) => {
  try {
    const payload = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      type: userData.iguroo_role,
      status: userData.status,
      mobile: userData.mobile,
      organization_id: userData.organization_id,
    };
    console.log("Payload:", payload);
    const res = await axiosStaticData.post(POST_IGUROO_NEWUSER, payload);
    // console.log(res, "iguroodata");
    return res.data;
  } catch (error) {
    console.error("Error in addIgurooUser:", error);
    throw error;
  }
};

export const getOrganizationId = async () => {
  try {
    const URL = GET_ORGANIZATION_ID;
    const res = await axiosStaticData.get(URL);
    console.log(res, "dataiguroo");
    console.log(res.data, "dataiguroo");
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getUserRoleId = async (organization_id) => {
  try {
    const URL = GET_USERROLE_ID + `?organization_id=${organization_id}`;
    const res = await axiosStaticData.get(URL);
    // console.log(res, "datauseriguroo");
    console.log(res.data, "datauseriguroo");
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
