//** GinieHealth Action MailPopup for Suject & Message Component **//

import React, { useCallback, useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./supervisor.scss";
import { Col, Form, Image, Row } from "react-bootstrap";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import MessageIcon from "../../assets/images/MessageIcon.svg";
import { Controller, useForm } from "react-hook-form";
import {
  EditorState,
  RichUtils,
  convertFromRaw,
  convertToRaw,
  ContentState,
  convertFromHTML,
  convertToHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { AuthContext } from "../../hooks/auth.context.provider";
import { CustomEmailSendApi } from "@iguroo/ig-react-assessment-package/dist/ig-react-assessment-package.cjs.production.min";
import { useDispatch } from "react-redux";
import { loader } from "../../redux-tk/slices/loader.slice";
import { yupResolver } from "@hookform/resolvers/yup";
import { actionMailPopupForm } from "../../FormSchemas/supervisor.email";
import { FormErrorComponent } from "../../components/form.error.component";
import "./supervisor.scss";

function ActionMailPopup(props) {
  const {
    control,
    getValues,
    setValue,
    reset,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(actionMailPopupForm()),
    defaultValues: { subject: "", messageMail: "" },
  });
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const [editorFocus, setEditorFocus] = useState(false);
  const [editorState, setEditorState] = useState();
  const onEditorStateChange = (newState) => setEditorState(newState);
  const [show, setShow] = useState(false);
  const [subject, setSubject] = useState(false);

  const handleClose = () => {
    setEditorState(EditorState.createEmpty());
    setShow(false);
    reset();
  };

  const handleShow = () => setShow(true);
  const onSubmit = async () => {
    dispatch(loader(true));
    // const editorContent = editorState.getCurrentContent();
    // const editorHtml = draftToHtml(convertToRaw(editorContent));
    // const rawHtml = editorHtml.replace(/^<p>/, "").replace(/<\/p>$/, "");
    const subject = getValues("subject");
    const message = getValues("messageMail");
    const data = {
      userId: props?.assessments,
      // userId: authContext.sessionInfo.sub,
      subject,
      message: message,
    };
    console.log(props?.assessments, "editorhtml");
    try {
      const res = await CustomEmailSendApi(data);
      console.log(res, "editorhtml");
      if (res.ok) {
        console.log("Email sent successfully");
      } else {
        console.error("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
    dispatch(loader(false));
    // console.log(subject, "editorhtml");
    // console.log(message, "editorhtml");
    setValue("subject", "");
    setValue("messageMail", "");
    setEditorState(EditorState.createEmpty());
    handleClose();
  };

  const onReset = useCallback(() => {
    clearErrors();
    setValue("subject", "");
    setValue("messageMail", "");
  }, [clearErrors, setValue]);

  return (
    <>
      <div className="tooltip-container">
        <Image
          src={MessageIcon}
          onClick={handleShow}
          role="button"
          height="20"
          width="20"
          alt="mail-icon"
          className="mail-icon"
        />
        <span className="tooltip-text">Email</span>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title className="modal-title w-100">
            Send Email to: {props.userName}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
          <Modal.Body>
            <Row>
              <Col xs={12} md={2} className="mt-4">
                Subject:
              </Col>
              <Col xs={12} md={10}>
                <Controller
                  name="subject"
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <CustomInputField
                      name={name}
                      onBlur={onBlur}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
                <FormErrorComponent>
                  {errors.subject && errors.subject?.message}
                </FormErrorComponent>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={2} className="mt-4">
                Message:
              </Col>
              <Col xs={12} md={10} className="">
                <Controller
                  name="messageMail"
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <CustomInputField
                      name={name}
                      onBlur={onBlur}
                      formAs={"textarea"}
                      rows={6}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
                {/* <Editor
                  wrapperClassName="notes-wrapper"
                  editorClassName={`notes-editor ${
                    editorFocus ? "editor-focus" : ""
                  }`}
                  editorState={editorState}
                  onFocus={(_) => setEditorFocus(true)}
                  onBlur={(_) => setEditorFocus(false)}
                  placeholder="Take your notes here..."
                  onEditorStateChange={onEditorStateChange}
                  toolbar={EditorToolBar}
                  name="messageMail"
                  spellCheck
                /> */}
                <FormErrorComponent>
                  {errors.messageMail && errors.messageMail?.message}
                </FormErrorComponent>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer
            className="d-flex justify-content-center"
            style={{ borderTop: "none" }}
          >
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="danger" type="reset">
              Clear
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Send
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default ActionMailPopup;
