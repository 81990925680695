//** Onboarding Heading Text **//

import "./Manrope.ttf";
import "./static/Manrope-Light.ttf";
export const HEADER_TEXT_8 = {
  fontFamily: "Manrope, sans-serif",
  fontWeight: "800",
  fontStyle: "normal",
  fontSize: 24,
};

export const HEADER_TEXT_7 = {
  fontFamily: "Manrope,sans-serif",
  fontWeight: "700",
  fontStyle: "normal",
  fontSize: 18,
};

export const HEADER_TEXT_6 = {
  fontFamily: "Monrope Light,sans-serif",
  fontWeight: "600",
  fontSize: 14,
};

export const HEADER_TEXT_5 = {
  fontFamily: "Monrope Light,sans-serif",
  fontWeight: "500",
  fontSize: 14,
};
