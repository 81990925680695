//** Slice file Function imported in the RootReducer **//

import { combineReducers } from "@reduxjs/toolkit";
import mentorOnboardingReducer from "../slices/mentor.onboarding.data";
import menteeOnboardingReducer from "../slices/mentee.onboarding.data";
import loaderSlice from "../slices/loader.slice";
import generalInfoSlice from "../slices/general.info.slice";
import { userApi } from "../queries/user.queries";
import userSlice from "../slices/user.slice";
import menteeuserSlice from "../slices/menteeuser.slice";
import CreateUserSlice from "../slices/create.user.slice";
import sessionSlice from "../slices/session.slice";
import requestsSlice from "../slices/requests.slice";
import imagesSlice from "../slices/images.slice";
import examInfoSlice from "../slices/exam.currentQuestion";
import showPopupflagSlice from "../slices/showPopupflag.slice";
import swotSlice from "../slices/swot.slice";
import glexamInfoSlice from "../slices/genieLabs.currentQuestion";
import assessmentSlice from "../slices/assessment.slice";
import userManagementSlice from "../slices/user.management.slice";

/**
 * Creates the root reducer for the site.
 * @returns Combined reducer
 */

export const createRootReducer = () =>
  combineReducers({
    loader: loaderSlice,
    mentorOnboarding: mentorOnboardingReducer,
    general: generalInfoSlice,
    menteeOnboarding: menteeOnboardingReducer,
    user: userSlice,
    menteeuser: menteeuserSlice,
    createuser: CreateUserSlice,
    sessions: sessionSlice,
    requests: requestsSlice,
    images: imagesSlice,
    exam: examInfoSlice,
    showPopupflag: showPopupflagSlice,
    swot: swotSlice,
    glexam: glexamInfoSlice,
    assessment: assessmentSlice,
    [userApi.reducerPath]: userApi.reducer,
    orgUsers: userManagementSlice,
  });
