import * as yup from "yup";
export function getBasicFormTranslated(formErrors) {
  return yup.object({
    gender: yup.string().required(formErrors?.genderError),
    mentoringFrom: yup
      .object({
        value: yup.string(),
        dataId: yup.string(),
        dataType: yup.string(),
      })
      .required(formErrors?.since)
      .typeError(formErrors?.since),
    languages: yup
      .array()
      .of(
        yup.object({
          value: yup.string(),
          dataId: yup.string(),
          dataType: yup.string(),
        })
      )
      .required(formErrors?.language)
      .min(1, formErrors?.language)
      .typeError(formErrors?.language),
    country: yup
      .object({
        value: yup.string(),
        dataId: yup.string(),
        dataType: yup.string(),
        country: yup.string().required(formErrors?.country),
      })
      .required(formErrors?.country)
      .typeError(formErrors?.country),
    state: yup
      .object({
        value: yup.string(),
        dataId: yup.string(),
        dataType: yup.string(),
        state: yup.string().required(formErrors?.state),
      })
      .required(formErrors?.state)
      .typeError(formErrors?.state),
    city: yup
      .object({
        value: yup.string(),
        dataId: yup.string(),
        dataType: yup.string(),
        city: yup.string().required(formErrors?.city),
      })
      .required(formErrors?.city)
      .typeError(formErrors?.city),
    profileTitle: yup.string().required(formErrors?.title),
    bio: yup.string().required(formErrors?.describErrormsg),
    profilePic: yup.string(),
    newProfilePic: yup.string().when("profilePic", {
      is: "",
      then: () => yup.string().required(formErrors?.profilePicRequired),
    }),
    profileVideoLink: yup.string().optional(),
    newProfileVideoLink: yup.string().optional(),
  });
}
