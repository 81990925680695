import * as yup from "yup";

export function signatureFormValidation(formErrors) {
  return yup.object({
    signaure_name: yup.string().required("*Full Name is required"),
    signed_date: yup.string().required("*Date is required"),
    agree: yup
      .bool()
      .oneOf([true], "*I Agree is required")
      .required("*I Agree is required"),
  });
}
