//** Iguroo Admin RecommendationPage search of using userId for SelfDiscovery AssessmentReport**//

import React, { useState, useContext, useMemo } from "react";
import Card from "react-bootstrap/Card";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormErrorComponent } from "../../components/form.error.component";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { SubmitButton } from "../../components/submit.button";
import { requiredUserId } from "../../FormSchemas/admin.mentor.management";
import { GetLatestAttempt } from "../../services/axios/Recommendation";
import Recommendation from "../../components/Recommendation";
import { AuthContext } from "../../hooks/auth.context.provider";

const RecommendationPage = () => {
  const { formErrors } = useIgurooTranslations();
  const [attempts, setAttempts] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const authContext = useContext(AuthContext);
  const userInfo = useMemo(() => {
    return authContext?.sessionInfo;
  }, [authContext.authStatus]);

  const handleClose = () => setShow(false);

  const handleOpen = () => setShow(true);

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { userId: "", publicOrPrivate: false },
    resolver: yupResolver(requiredUserId(formErrors)),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    setApiCalled(false);
    const res = await GetLatestAttempt(data.userId);
    setAttempts(res);
    setLoading(false);
    setApiCalled(true);
  };

  return (
    <div className="px-3 pt-5">
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6}>
                <Controller
                  name={`userId`}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CustomInputField
                      placeholder={"Please enter user id"}
                      hookOnChange={onChange}
                      hookOnBlur={onBlur}
                      hookValue={value}
                      rows={6}
                    />
                  )}
                />
                <FormErrorComponent>
                  {errors.userId && errors.userId?.message}
                </FormErrorComponent>
              </Col>
              <Col md={3} className="pt-4">
                <SubmitButton type="submit" text="Submit" />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <ul style={{ listStyleType: "none", padding: 0 }} className="mt-3">
        {attempts.map((item) => (
          <li onClick={() => handleOpen()} key={item}>
            <Button variant="link">{item.attemptId}</Button>
          </li>
        ))}
      </ul>
      {show && (
        <Recommendation
          show={show}
          onHide={handleClose}
          userId={userInfo.sub}
          menteeId={getValues("userId")}
          examId={attempts[0]?.examId}
          attemptId={attempts[0]?.attemptId}
        />
      )}
      {loading && (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />;
        </div>
      )}
      {attempts.length < 1 && apiCalled && (
        <div className="d-flex justify-content-center">
          <span>There are no attempts</span>
        </div>
      )}
    </div>
  );
};

export default RecommendationPage;
