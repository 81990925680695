//** GinieHealth Learner Login,TextQuestionAnswer Question UI component code **//
//** Used in Text Question Component **//

import React from "react";
import "./styles.scss";
import { CustomInputField } from "../../CustomInput/customInputField";

export function TextQuestionAnswerComponent(props) {
  const {
    textPlaceHolder,
    hookOnBlur,
    hookValue,
    hookOnChange,
    className,
    maxLength,
    name,
  } = props;
  console.log(textPlaceHolder, "console");

  return (
    <CustomInputField
      placeholder={textPlaceHolder}
      hookOnBlur={hookOnBlur}
      hookValue={hookValue}
      className={`${className} text-question-answer`}
      hookOnChange={hookOnChange}
      maxLength={maxLength}
      name={name}
    />
  );
}
