//** Iguroo Aggrid main, Mymentee & Mymentor Name Data,can re-use required field **//

import { useEffect } from "react";
import { useState } from "react";
import { _getImage } from "../../common/utils/commonFunctions";
import { GetUserProfile } from "../../services/axios/MentorOnbaording";
import UserCard from "../UserCard";
import "./user.renderer.scss";
import avatar from "../../assets/images/avatar.svg";

export const UserCardRenderer = ({ value }) => {
  const [userProfile, setUserProfile] = useState();
  let active = false;
  useEffect(() => {
    if (!active && value) {
      (async function () {
        try {
          console.log("user id", value);
          GetUserProfile(value)
            .then((res) => {
              console.log("user.data", res);
              setUserProfile(res.data);
            })
            .catch((err) => console.log(err));
          // const user = await GetUserProfile(value);
        } catch (err) {
          console.log("err getting profile", err);
        }
      })();
    }
    return () => {
      active = true;
    };
  }, [value]);

  if (!userProfile) {
    return <></>;
  }

  return (
    <>
      <UserCard
        name={`${userProfile?.firstName} ${userProfile?.lastName}`}
        picture={userProfile?.profilePic}
        userId={value}
        className={"ig-agGrid-username"}
      />
    </>
  );
};
