//** Iguroo Assessment,Sortable SingleItemDrop DNDCard Component **//
//** Currently not using this,will be implement in future enhancement**//

import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { DndTypes } from "../../enums";
import { DndCard } from "./DndCard";

export const SingleItemDropTarget = (props) => {
  const [{ isOver, handlerId }, drop] = useDrop({
    accept: DndTypes.card,
    drop: (item) => {
      props.onItemDropped(item);
    },
    canDrop: (item, monitor) => {
      return !props.item || props.item.id === item.id;
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: DndTypes.card,
    item: () => {
      return {
        id: props.item ? props.item.id : null,
        name: props?.item?.name,
        text: props?.item?.text,
        //setItem: props?.setItem,
        //isSingleItemCard: true
      };
    },

    canDrag: () => !!props.item,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drop}
      className={`single-item-drop ${isDragging ? "is-dragging" : ""}`}
    >
      <DndCard
        drag={drag}
        handlerId={handlerId}
        text={props.item ? props.item.name : ""}
        className={"dnd-card"}
      />
    </div>
  );
};
