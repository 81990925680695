//** Iguroo SelfAssessment,Question Text Component,can re-use required field **//

import React from "react";

export function QuestionRateComponent(props) {
  const { text, className } = props;
  const questText = props.children?.length > 0 ? props.children : text;
  const ques = questText?.split("\\n").map((line, index) => (
    <p
      key={index}
      className={index != 0 ? "ig-rate-hint" : "ig-rate-abovehead"}
    >
      {line}
    </p>
  ));
  console.log(ques, "console");

  return <div className={`ig-test-Ratequestion ${className}`}>{ques}</div>;
}
