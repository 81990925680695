import { CategoryRenderer } from "../components/agGridRenderers/category.renderer";
import { RequestDetailRenderer } from "../components/agGridRenderers/request.details";
import { RequestRenderer } from "../components/agGridRenderers/request.sessions.renderer";
import { StatusRenderer } from "../components/agGridRenderers/status.renderer";
import { UserCardRenderer } from "../components/agGridRenderers/user.card";
import { UserRenderer } from "../components/agGridRenderers/user.renderer";
import { AgGridFieldsEnum, RequestStatus } from "../enums";
import { RequestTitleRenderer } from "../components/agGridRenderers/request.title.renderer";

//** Mentor Login --> Can view Mentee Details Aggrid of Requests **//
export const getMentorGridDefinitions = (gridColumnDefinitions) => {
  return [
    {
      headerName: gridColumnDefinitions.request, //Heading
      field: AgGridFieldsEnum.requestNumber, //Enums
      cellRenderer: RequestTitleRenderer, //Component
      sortable: false, //Ascending & Descending Order
      suppressMenu: true, //Column MenuTab
      filter: "agTextColumnFilter", //Filtering
    },
    {
      headerName: gridColumnDefinitions.category,
      field: AgGridFieldsEnum.category,
      cellRenderer: CategoryRenderer,
      sortable: false,
      suppressMenu: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: gridColumnDefinitions.mentee,
      field: AgGridFieldsEnum.userData,
      cellRenderer: UserRenderer,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: gridColumnDefinitions.status,
      field: AgGridFieldsEnum.status,
      cellRenderer: StatusRenderer,
      sortable: false,
      filter: "agTextColumnFilter",
      minWidth: 80,
      maxWidth: 120,
    },
    {
      headerName: gridColumnDefinitions.upcomingSessions,
      field: AgGridFieldsEnum.requestNumber,
      cellRenderer: RequestRenderer,
      sortable: false,
      suppressMenu: true,
    },
    //** Infuture can maybe add these Action Buttons **//
    // {
    //   headerName: "",
    //   field: AgGridFieldsEnum.actions,
    //   cellRenderer: ActionRenderer,
    //   sortable: false,
    //   suppressMenu: true,
    // },
  ];
};

//** Mentee Login --> Can view Mentor Details Aggrid of Requests **//
export const getMenteeGridDefinitions = (gridColumnDefinitions) => {
  return [
    {
      headerName: gridColumnDefinitions.request,
      field: AgGridFieldsEnum.requestNumber,
      cellRenderer: RequestTitleRenderer,
      sortable: false,
      filter: "agTextColumnFilter",
    },
    {
      headerName: gridColumnDefinitions.category,
      field: AgGridFieldsEnum.category,
      cellRenderer: CategoryRenderer,
      sortable: false,
      suppressMenu: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: gridColumnDefinitions.mentor,
      field: AgGridFieldsEnum.userData,
      cellRenderer: UserRenderer,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: gridColumnDefinitions.status,
      field: AgGridFieldsEnum.status,
      cellRenderer: StatusRenderer,
      sortable: false,
      filter: "agTextColumnFilter",
      minWidth: 80,
      maxWidth: 120,
    },
    {
      headerName: gridColumnDefinitions.upcomingSessions,
      field: AgGridFieldsEnum.requestNumber,
      cellRenderer: RequestRenderer,
      sortable: false,
      suppressMenu: true,
    },
    //** Infuture can maybe add these Action Buttons **//
    // {
    //   headerName: "",
    //   field: AgGridFieldsEnum.actions,
    //   cellRenderer: ActionRenderer,
    //   sortable: false,
    //   suppressMenu: true,
    //   minWidth: 50
    // },
  ];
};

export const extractDataFromOpensearch = (hits, userIdToFetch) => {
  const requestNumbers = [];
  const userIds = [];
  const processedSearches = hits.map((val) => {
    const source = val ? val["_source"] : {};
    if (requestNumbers.indexOf(source?.requestNumber) === -1) {
      requestNumbers.push(source?.requestNumber);
    }
    if (userIds.indexOf(source[userIdToFetch]) === -1) {
      userIds.push(source[userIdToFetch]);
    }

    return source;
  });
  return { userIds, requestNumbers, data: processedSearches };
};

export const getCompletedSessions = (sessions) => {
  return sessions?.filter((val) => {
    if (val.status.toUpperCase() === RequestStatus.completed) {
      return val;
    } else {
      return null;
    }
  })?.length;
};

// ** Status of Aggrid ** //
export const getStatusSelect = (staticSelectTranslations) => {
  return [
    {
      dataId: RequestStatus.all,
      value: staticSelectTranslations.all,
    },
    {
      dataId: RequestStatus.active,
      value: staticSelectTranslations.active,
    },
    {
      dataId: RequestStatus.cancelled,
      value: staticSelectTranslations.cancelled,
    },
    {
      dataId: RequestStatus.completed,
      value: staticSelectTranslations.completed,
    },
    {
      dataId: RequestStatus.created,
      value: staticSelectTranslations.created,
    },
    {
      dataId: RequestStatus.pending,
      value: staticSelectTranslations.pending,
    },
  ];
};

//** Mentee Login --> Can view Mentor Details Aggrid of My Mentees **//
export const getMyMentorGridDefinitions = (gridColumnDefinitions) => {
  return [
    {
      headerName: gridColumnDefinitions.mentor,
      field: AgGridFieldsEnum.mentorId,
      cellRenderer: UserCardRenderer,
      sortable: true,
      suppressMenu: true,
      filter: "agTextColumnFilter",
      minWidth: 60,
      maxWidth: 250,
    },
    {
      headerName: gridColumnDefinitions.status,
      field: AgGridFieldsEnum.status,
      cellRenderer: StatusRenderer,
      sortable: false,
      suppressMenu: true,
      filter: "agTextColumnFilter",
      minWidth: 80,
      maxWidth: 120,
    },
    {
      headerName: gridColumnDefinitions.request,
      field: AgGridFieldsEnum.requestNumber,
      cellRenderer: RequestDetailRenderer,
      sortable: false,
      suppressMenu: true,
      filter: "agTextColumnFilter",
      minWidth: 50,
      maxWidth: 1000,
    },

    {
      headerName: gridColumnDefinitions.upcomingSessions,
      field: AgGridFieldsEnum.requestNumber,
      cellRenderer: RequestRenderer,
      sortable: false,
      suppressMenu: true,
      filter: "agTextColumnFilter",
      minWidth: 50,
      maxWidth: 200,
    },
  ];
};

//** Mentor Login --> Can view Mentee Details Aggrid of My Mentees **//
export const getMyMenteeGridDefinitions = (gridColumnDefinitions) => {
  return [
    {
      headerName: gridColumnDefinitions.mentee,
      field: AgGridFieldsEnum.userId,
      cellRenderer: UserCardRenderer,
      sortable: true,
      suppressMenu: true,
      filter: "agTextColumnFilter",
      minWidth: 60,
      maxWidth: 250,
    },
    {
      headerName: gridColumnDefinitions.status,
      field: AgGridFieldsEnum.status,
      cellRenderer: StatusRenderer,
      sortable: false,
      filter: "agTextColumnFilter",
      minWidth: 80,
      maxWidth: 120,
    },
    {
      headerName: gridColumnDefinitions.request,
      field: AgGridFieldsEnum.requestNumber,
      cellRenderer: RequestDetailRenderer,
      sortable: false,
      filter: "agTextColumnFilter",
      minWidth: 50,
      maxWidth: 1000,
    },

    {
      headerName: gridColumnDefinitions.upcomingSessions,
      field: AgGridFieldsEnum.requestNumber,
      cellRenderer: RequestRenderer,
      sortable: false,
      filter: "agTextColumnFilter",
      minWidth: 50,
      maxWidth: 200,
    },
  ];
};
