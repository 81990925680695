import React, { useEffect, useState } from "react";

export const SortColumnsAggrid = (props) => {
  const [sort, setSort] = useState("");
  console.log(props, "agGrid Data Header:");

  const onSortAsc = () => {
    if (sort === "") {
      props.sortByFunction(props.displayName, "asc", props.sortField);
      setSort("asc");
    } else if (sort === "asc") {
      props.sortByFunction(props.displayName, "desc", props.sortField);
      setSort("desc");
    } else {
      props.sortByFunction(props.displayName, "", props.sortField);
      setSort("");
    }
  };

  useEffect(() => {
    const columnSort = props.sortData.find(
      (items) => items == props.displayName
    );
    if (columnSort) {
      setSort("");
    }
  }, [props.sortData]);

  console.log(props.displayName + " : " + sort, "sorting Aggrid");
  return (
    <div
      role="button"
      onClick={() => {
        onSortAsc();
      }}
    >
      <span>{props.displayName}</span>
      <span className="m-2">{sort === "asc" ? "↑" : sort === "desc" ? "↓" : "↑↓"}</span>
    </div>
  );
};
