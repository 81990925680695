//** GinieHealth Learner Login,Custom Dropdown QuestionType UI component **//

import { Dropdown } from "react-bootstrap";
import { SvgImageContainer } from "./SvgImageContainer";
import { dropdownItems } from "./constant";

export const CustomDropdown = ({ icon, title, onChangeQuestionType }) => {
  return (
    <Dropdown
      onSelect={(e, f) => {
        onChangeQuestionType(e);
      }}
    >
      <Dropdown.Toggle id="dropdown-autoclose-true" variant="secondary">
        <SvgImageContainer
          image={icon}
          className="ms-2 me-5"
          height={17}
          width={17}
        />
        <span className="me-5">{title}</span>
        <SvgImageContainer
          image="arrow-down-filled.svg"
          width={12}
          height={7}
          className="ms-1"
        />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {dropdownItems.map((item) => (
          <>
            <Dropdown.Item eventKey={item.eventKey} className="mb-2">
              <SvgImageContainer
                image={item.icon}
                className="me-5"
                height={17}
                width={17}
              />
              <span>{item.title}</span>
            </Dropdown.Item>
            {item.divider && <Dropdown.Divider />}
          </>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
