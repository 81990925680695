import React, { useContext, useEffect } from "react";
import { Stack, Button, Image, Container, Row, Col } from "react-bootstrap";
import { _getImage } from "../../common/utils/commonFunctions";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
// import "./styles.scss";
import Rating from "react-rating";
import { FormHeading } from "../form.heading.component";
import { CustomImage } from "../Media";
import { ImgSrcEnums } from "@iguroo/ig-react-common-package";
import { Link } from "react-router-dom";
import { BackButton } from "../back.button";
import { AppUrlsEnum } from "../../enums";
import {
  
  card_rate_display,
  card_price_display,
} from "../../services/config";

function CourseDetailsHeader(props) {
  const { headerFooterTranslations, profileLabels } = useIgurooTranslations();

  const {
    courseDetails,
    name,
    profilePic,
    description,
    details,
    rating,
    price,
    className,
    style,
    assessment_id,
    version,
  } = props;
  const assessments=[{
    
    courses_version_id: "bd558529-ee63-442c-b195-aadd182b13c2-1",
    attempts_allowed: 1,
    // banner_image: "9ebd098d-fea7-4fa8-8d75-4d572fc69719/images/genie-health-logo-new.jpeg",

    due_datetime: 1704047399999,
    extended_attempts: 0,
    isExtended: false,
    percentageCompleted: 0,
    start_datetime: 1702035207772,
    status: "ACTIVE",
    subtitle: "Learn AWS in 24 hours",
    title: "Learn AWS in 24 hours",
    userId: "5faab0ac-0b1b-4762-bb89-3ca76a611f2c"
  }]

  console.log(profilePic,courseDetails, "key profileImage console");
  return (
    <>
    
        <Container className={`assessment-info p-4 ${className}`} style={style}>
          <Row>
            {/* {assessments.map((data) => { */}
            
                <>
                  <Col>
                    <Link to={AppUrlsEnum.coursesUrladmin}>
                      <BackButton
                        svgName="back_btn1.svg"
                        className="author-back-btn"
                      />
                    </Link>
                  </Col>
                  <Col sm={2}>
                    <div className="profile-img d-flex justify-content-center">
                      {courseDetails?.banner_image ? (
                        <CustomImage
                          src={{
                            src: `${courseDetails?.banner_image}`,
                            type: ImgSrcEnums.props,
                          }}
                          height="100px"
                          width="100px"
                          roundedCircle={true}
                        />
                      ) : (
                        <img
                          height="100px"
                          width="100px"
                          src={_getImage("coursesBanner.jpg")}
                          className="icon rounded-circle"
                        />
                      )}
                    </div>
                  </Col>
                  <Col sm={9}>
                    <div className="info mt-2">
                      <h1
                        className="name-firstletter"
                        style={{ cursor: "pointer", color: "var(--black)" }}
                      >
                        {courseDetails?.title}
                      </h1>
                      <div className="description my-1">{courseDetails?.course_description}</div>
                      <p className="my-2">{courseDetails?.details}</p>
                      {/*  <p>
                        {data.duration}({data.number_of_questions} Questions)
                      </p> 
                      <p>
                        {data.duration}(
                        {data.number_of_questions
                          ? data.number_of_questions
                          : "0"}{" "}
                        Question{data.number_of_questions === 1 ? "" : "s"})
                      </p> */}
                      {card_rate_display ? (
                        <Rating
                          placeholderRating={courseDetails.rating ? courseDetails.rating : 0}
                          readonly={true}
                          emptySymbol={
                            <img
                              height={20}
                              width={20}
                              src={_getImage("emptyBlueStar.svg")}
                              className="icon"
                            />
                          }
                          placeholderSymbol={
                            <img
                              height={20}
                              width={20}
                              src={_getImage("bluestar.svg")}
                              className="icon"
                            />
                          }
                          fullSymbol={
                            <img
                              height={20}
                              width={20}
                              src={_getImage("bluestar.svg")}
                              className="icon"
                            />
                          }
                        />
                      ) : (
                        " "
                      )}
                      {card_price_display ? (
                        <div className="price my-2">{courseDetails?.price}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </>
              
            {/* })} */}
          </Row>
        </Container>
     
    </>
  );
}

export default CourseDetailsHeader;
