import { StorageHelper } from '@iguroo/ig-react-common-package';

class StorageHelperMy extends StorageHelper {
    async clear() {
        localStorage.clear();
    }

    async getValue(field) {
        return localStorage.getItem(field);
    }

    getValueSync(field) {
        return localStorage.getItem(field);
    }

    async setValue(field,value){
        localStorage.setItem(field,value);
    }

    async removeItem(key){
        localStorage.removeItem(key);
    }
}

export const StorageService = new StorageHelperMy();