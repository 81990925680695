//** GinieHealth AddUser,GetUserList,UserManagement Slice **//

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  UsermanagementUsersApi,
  GetUsersListApi,
} from "@iguroo/ig-react-assessment-package";

export const addUser = createAsyncThunk(
  "users/addUsers",
  async (data, thunkApi) => {
    try {
      const res = await UsermanagementUsersApi(data);
      return res;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getUserList = createAsyncThunk(
  "users/getUsers",
  async (data, thunkApi) => {
    try {
      const res = await GetUsersListApi(data);
      return res.data;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);

const initialState = {
  userList: [],
};

export const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    deleteUser: (state, action) => {
      const index = state.userList.findIndex((x) => x.id == action.payload.id);
      if (index > -1) {
        state.userList.splice(index, 1);
      }
    },
    editUser: (state, action) => {
      const index = state.userList.findIndex((x) => x.id == action.payload.id);
      if (index > -1) {
        state.userList[index] = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUser.fulfilled, (state, action) => {})
      .addCase(addUser.pending, (state) => {})
      .addCase(addUser.rejected, (state, action) => {})
      .addCase(getUserList.fulfilled, (state, action) => {
        state.userList = action.payload;
      })
      .addCase(getUserList.pending, (state) => {})
      .addCase(getUserList.rejected, (state, action) => {});
  },
});

export const { deleteUser, editUser } = userManagementSlice.actions;

export const getUsers = (state) => state?.orgUsers?.userList;

export default userManagementSlice.reducer;
