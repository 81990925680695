//** GinieHealth Invite Email Popup,Currently not using this ,Infurture we would implementing this code **//

import React, { useState } from "react";
import "./supervisor.scss";
import { Button, Modal, ModalTitle, Row, Col, Form } from "react-bootstrap";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { Controller, useForm } from "react-hook-form";
import { SubmitButton } from "../../components/submit.button";
import { useIgurooTranslations } from "../../hooks";
import { _getImage } from "../../common/utils/commonFunctions";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { EmailPopupSV } from "../../FormSchemas/supervisor.email";

const EmailUsersPopup = (props) => {
  const { genieLabsTranslations, formErrors } = useIgurooTranslations();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(EmailPopupSV(formErrors)),
  });
  const [emails, setEmails] = useState([]);
  const [enableInput, setEnableInput] = useState(false);
  const [emailList, setEmailList] = useState([
    "example1@example.com",
    "example2@example.com",
    "example3@example.com",
    "example4@example.com",
    "example5@example.com",
  ]);
  const handleRadioClick = (option) => {
    if (option === "dueDate") {
      setEnableInput(true);
    } else {
      setEnableInput(false);
    }
  };

  const [showPopup, setShowPopup] = useState(false);
  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleInviteByEmail = () => {
    setShowPopup(true);
  };
  const handleClosePopup = () => {
    setShowPopup(false);
    // onHide();
  };

  const submit = () => {
    console.log("supervior-tag");
  };
  return (
    <>
      <Button onClick={handleInviteByEmail} className="m-2 email-popup">
        <img src={_getImage("SV_user.svg")} />{" "}
        {genieLabsTranslations?.inviteEmail}
      </Button>
      <Modal size={"lg"} show={showPopup} onHide={() => setShowPopup(false)}>
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <ModalTitle className="modal-title w-100">
            {genieLabsTranslations?.userEmail}
          </ModalTitle>
        </Modal.Header>
        <Form onSubmit={handleSubmit(submit)}>
          <Modal.Body className="modal-body">
            <Row>
              <Col className="mt-3" xs={12} md={9} lg={10}>
                <Controller
                  name={`emailAdding`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <ReactMultiEmail
                      className="email-user"
                      placeholder="Enter Email Address"
                      emails={value}
                      onChange={(newEmails) => {
                        onChange(newEmails);
                      }}
                      name={name}
                      getLabel={(email, index, removeEmail) => (
                        <div data-tag key={index}>
                          {email}
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      )}
                    />
                  )}
                />
              </Col>
              <Col
                xs={12}
                md={3}
                lg={2}
                className="d-flex align-items-end justify-content-end mb-2 mt-3"
              >
                <Button className="w-100">
                  {genieLabsTranslations?.addBtn}
                </Button>
              </Col>
              {/* <FormErrorComponent>
                  {errors.emailAdding && errors.emailAdding?.message}
                </FormErrorComponent> */}
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <div className="email-heading mt-3">
                  {genieLabsTranslations?.dueDate}
                </div>
                <Row className="d-flex align-items-center">
                  <Col xs={8} sm={9}>
                    <Controller
                      name={`dateField`}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name },
                      }) => (
                        <CustomInputField
                          type="date"
                          hookOnChange={onChange}
                          hookOnBlur={onBlur}
                          hookValue={value}
                          name={name}
                        />
                      )}
                    />
                    {/* <FormErrorComponent>
                      {errors.dateField && errors.dateField?.message}
                    </FormErrorComponent> */}
                  </Col>
                  <Col xs={4} sm={3} className="mt-3">
                    <img src={_getImage("date_logo.svg")} alt="Date" />
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <div className="email-heading mt-3">
                  {genieLabsTranslations?.endDate}
                </div>
                <Row className="d-flex align-items-center">
                  <Col xs={8} sm={9}>
                    <Controller
                      name={`timeField`}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name },
                      }) => (
                        <CustomInputField
                          type="time"
                          hookOnChange={onChange}
                          hookOnBlur={onBlur}
                          hookValue={value}
                          name={name}
                        />
                      )}
                    />

                    {/* <FormErrorComponent>
                      {errors.timeField && errors.timeField?.message}
                    </FormErrorComponent> */}
                    {/* <TimePickerComponent /> */}
                  </Col>
                  <Col xs={4} sm={3} className="mt-3">
                    <img src={_getImage("info_icon.svg")} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <div className="email-heading mt-3">
                {genieLabsTranslations?.availabilitySV}
              </div>
              <Col>
                <Controller
                  name={`availability`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <label mt-3 d-flex align-items-center>
                      <input
                        type="radio"
                        name="availability"
                        label="Option 2"
                        className="mt-3"
                        onClick={() => handleRadioClick("rightNow")}
                      ></input>
                      <span className="availability-desc m-3">
                        {genieLabsTranslations?.rightNowSV}
                      </span>
                      <Col className="d-flex">
                        <input
                          type="radio"
                          name="availability"
                          label="Option 2"
                          className="mt-3"
                          onClick={() => handleRadioClick("dueDate")}
                        ></input>
                        <Col className="m-2 col-2">
                          <CustomInputField disabled={!enableInput} />
                        </Col>
                        <span className="availability-desc mt-4">
                          {genieLabsTranslations?.dueDateSV}
                          <img
                            src={_getImage("info_icon.svg")}
                            className="m-2"
                          />{" "}
                        </span>
                      </Col>
                      <input
                        type="radio"
                        name="availability"
                        label="Option 2"
                        className="mt-3"
                        onClick={() => handleRadioClick("unlockDate")}
                      ></input>
                      <span className="availability-desc m-3">
                        {" "}
                        {genieLabsTranslations?.unlockDate}
                      </span>
                      <img src={_getImage("info_icon.svg")} />
                    </label>
                  )}
                />
              </Col>
              {/* <FormErrorComponent>
                {errors.availability && errors.availability?.message}
              </FormErrorComponent> */}
            </Row>
            <Row className="mt-3">
              <Col className="d-flex align-items-end justify-content-start justify-content-sm-end">
                <Button
                  className="cancel-button"
                  onClick={handleClosePopup}
                  style={{ border: "2px solid #EE4C4C" }}
                >
                  {genieLabsTranslations?.cancelBtn}
                </Button>
              </Col>
              <Col className="d-flex align-items-end justify-content-end mt-3 mt-sm-0 justify-content-lg-start">
                <SubmitButton
                  variant="primary"
                  type="submit"
                  text={genieLabsTranslations?.assignBtn}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
};
export default EmailUsersPopup;
