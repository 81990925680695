//** Iguroo Admin Update Testimonial AgGrid UI can search TestimonialId & MentorId,Update the text Too **//

import React, {
  useCallback,
  useRef,
  useMemo,
  useState,
  useContext,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Approve from "./Approve";
import Block from "./Block";
import StatusTag from "./StatusTag";
import ProfileLink from "./ProfileLink";
import { useEffect } from "react";
import { AuthContext } from "../../hooks/auth.context.provider";
import "./admin.scss";
import { Modal, Button, Form, Row, Col, Card } from "react-bootstrap";
import { FormLabelComponent } from "../../components/form.label.component";
import { Controller, useForm } from "react-hook-form";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { SubmitButton } from "../../components/submit.button";
import {
  getUpdateMentorTestimonials,
  getUpdateTestimonialsId,
  getMentorTestimonials,
  postUpdateTestimonials,
} from "../../services/axios/Testimonials";
import Toaster from "../../components/toaster";
import { toast } from "react-toastify";
import { useParams, useSearchParams } from "react-router-dom";

const UpdateTestimonials = () => {
  const gridRef = useRef();
  const authContext = useContext(AuthContext);
  const userInfo = useMemo(() => {
    return authContext?.sessionInfo;
  }, [authContext.authStatus]);
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({});
  useEffect(() => {}, []);
  const { mentorId } = useParams();
  const menteeId = useMemo(() => {
    return authContext?.sessionInfo?.sub;
  }, [authContext.authStatus]);
  const [rowData, setRowData] = useState([]);
  const [selectedTestimonial, setSelectedTestimonial] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentTestimonials, setCurrenttestimonials] = useState({});
  const [updatedTestimonial, setUpdatedTestimonial] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  console.log(filteredRows);

  const handleTestimonialClick = (data) => {
    setSelectedTestimonial(data.Testimonials);
    setUpdatedTestimonial(data.Testimonials);
    setCurrenttestimonials(data);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleTestimonialUpdate = async (data) => {
    console.log(data, "data-update");
    const updatedTestimonialText = `${updatedTestimonial}`;
    const updatedRowData = rowData.map((rowData) => {
      console.log(rowData, "rowdata-update");
      if (rowData.Testimonials === selectedTestimonial) {
        return {
          ...rowData,
          Testimonials: updatedTestimonialText,
          testimonialText: updatedTestimonialText,
        };
      } else {
        return rowData;
      }
    });
    try {
      const testimonialData = {
        mentorId: currentTestimonials?.mentorId,
        menteeId: currentTestimonials?.menteeId,
        status: "APPROVED",
        testimonialText: updatedTestimonialText,
        testimonialId: currentTestimonials?.testimonialId,
        createdAt: currentTestimonials?.createdAt,
        updatedAt: currentTestimonials?.updatedAt,
      };
      await postUpdateTestimonials(testimonialData);
      setRowData(updatedRowData);
      setSelectedTestimonial(updatedTestimonialText);
      closeModal();
      Toaster(toast.success, "Testimonial updated successfully");
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    console.log("Filtered Rows:", filteredRows);
  }, [filteredRows]);

  const onSubmitMentorId = async (data) => {
    const { mentorName } = data;
    console.log(data, "update-test");
    if (mentorName) {
      try {
        const testimonials = await getMentorTestimonials(mentorName);
        // console.log("API Response:", testimonials);
        if (testimonials && testimonials.Items.length > 0) {
          const filteredData = testimonials.Items.map((item) => {
            const mentorName =
              item?.mentorDetail?.[0]?.firstName +
              " " +
              item?.mentorDetail?.[0]?.lastName;
            const menteeName =
              item?.menteeDetail?.[0]?.firstName +
              " " +
              item?.menteeDetail?.[0]?.lastName;

            return {
              "Mentor Name": mentorName,
              "Mentee Name": menteeName,
              Status: item?.status,
              Testimonials: item?.testimonialText,
              testimonialId: item?.testimonialId,
              mentorId: item?.mentorId,
              menteeId: item?.menteeId,
              testimonialText: item?.testimonialText,
              createdAt: item?.createdAt,
              updatedAt: item?.updatedAt,
            };
          });
          // console.log("filter data", filteredData);
          setRowData(filteredData);
        } else {
          setRowData([]);
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      setRowData([]);
    }
  };

  const onSubmitTestimonialId = async (data) => {
    const { testimonialName } = data;
    console.log(data, "update-test-id");
    if (testimonialName) {
      try {
        const testimonials = await getUpdateTestimonialsId(testimonialName);
        // console.log("API Response-id:", testimonials);
        if (testimonials && testimonials.Items.length > 0) {
          const filteredData = testimonials.Items.map((item) => {
            const mentorName =
              item?.mentorDetail[0]?.firstName +
              " " +
              item?.mentorDetail[0]?.lastName;

            const menteeName =
              item?.menteeDetail[0]?.firstName +
              " " +
              item?.menteeDetail[0]?.lastName;
            return {
              "Mentor Name": mentorName,
              "Mentee Name": menteeName,
              Status: item?.status,
              Testimonials: item?.testimonialText,
              testimonialId: item?.testimonialId,
              mentorId: item?.mentorId,
              menteeId: item?.menteeId,
              testimonialText: item?.testimonialText,
              createdAt: item?.createdAt,
              updatedAt: item?.updatedAt,
            };
          });
          console.log("filter-data-id", filteredData);
          setRowData(filteredData);
        } else {
          setRowData([]);
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      setRowData([]);
    }
  };

  const TestimonialCellRenderer = ({ value, data }) => {
    const handleUpdate = () => {
      handleTestimonialClick(data);
    };

    return (
      <div>
        <Button
          className="button-text"
          variant="primary"
          size="sm"
          onClick={handleUpdate}
        >
          View / Update
        </Button>
      </div>
    );
  };

  const [columnDefs] = useState([
    {
      field: "Mentor Name",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      resizable: true,
    },
    {
      field: "Mentee Name",
      resizable: true,
    },
    {
      field: "Testimonials",
      cellRenderer: "testimonialCellRenderer",
      cellRendererFramework: TestimonialCellRenderer,
      cellRendererParams: {
        onUpdate: handleTestimonialClick,
      },
    },
    { field: "Status", cellRenderer: StatusTag },
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
  }));

  const cellClickedListener = useCallback((event) => {}, []);

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    document.querySelector("#selectedRows").innerHTML =
      selectedRows.length === 1 ? selectedRows[0].athlete : "";
  }, []);

  const isRowSelectable = useMemo(() => {
    return (params) => {
      return !!params.data;
    };
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.forEachNode((node) => node.setSelected(false));
  }, []);

  const components = useMemo(() => {
    return {
      Approve: Approve,
      Block: Block,
      StatusTag: StatusTag,
    };
  }, []);

  return (
    <>
      <h5 className="ms-4 mt-2">Update Testimonials</h5>
      <div
        className="d-flex flex-column mt-4"
        style={{ fontFamily: "manrope" }}
      >
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Card.Title>
                  Mentor Id
                  <Form onSubmit={handleSubmit(onSubmitMentorId)}>
                    <Row>
                      <Col md={8}>
                        <Controller
                          name={`mentorName`}
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <CustomInputField
                              placeholder={"Please enter Mentor Id"}
                              hookOnChange={onChange}
                              hookOnBlur={onBlur}
                              hookValue={value}
                              rows={6}
                            />
                          )}
                        />
                      </Col>
                      <Col md={2} className="pt-4">
                        <SubmitButton type="submit" text="Submit" />
                      </Col>
                    </Row>
                  </Form>
                </Card.Title>
              </Col>
              <Col>
                <Card.Title>
                  Testimonial Id
                  <Form onSubmit={handleSubmit(onSubmitTestimonialId)}>
                    <Row>
                      <Col md={8}>
                        <Controller
                          name={`testimonialName`}
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <CustomInputField
                              placeholder={"Please enter Testimonial Id"}
                              hookOnChange={onChange}
                              hookOnBlur={onBlur}
                              hookValue={value}
                              rows={6}
                            />
                          )}
                        />
                      </Col>
                      <Col md={2} className="pt-4">
                        <SubmitButton type="submit" text="Submit" />
                      </Col>
                    </Row>
                  </Form>
                </Card.Title>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <div className="ag-theme-alpine px-4 py-3" style={{ height: "400px" }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowSelection="multiple"
            suppressDragLeaveHidesColumns={true}
            onCellClicked={cellClickedListener}
            onSelectionChanged={onSelectionChanged}
            suppressRowClickSelection={true}
            isRowSelectable={isRowSelectable}
            onFirstDataRendered={onFirstDataRendered}
            components={components}
            frameworkComponents={{
              testimonialCellRenderer: TestimonialCellRenderer,
            }}
          />
          <Modal show={modalIsOpen} onHide={closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Update Testimonial</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Update Testimonial Text</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={10}
                  value={updatedTestimonial}
                  onChange={(e) => setUpdatedTestimonial(e.target.value)}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal}>
                Close
              </Button>
              <Button variant="primary" onClick={handleTestimonialUpdate}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default UpdateTestimonials;