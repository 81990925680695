//** Iguroo ZegoCloud Video Calling and Notes code UI **//

import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import "./styles.scss";
import { _getImage } from "../../common/utils/commonFunctions";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, RichUtils } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ToolbarConfig from "./EditorConfig";
import { code, blockquote } from "../../assets/images";
import RateMeeting from "../../components/RateMeeting";
import ZegoCloud from "../zegoCloud/index";
import { postSessionNotes } from "../../services/axios/session";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ProfileType } from "../../enums";
function CustomOption(props) {
  const { editorState, onChange } = props;
  let [bqActive, setBqActive] = useState(false);
  let [codeActive, setCodeActive] = useState(false);

  const toggleBlock = () => {
    codeActive && setCodeActive(bqActive);
    setBqActive(!bqActive);
    const newState = RichUtils.toggleBlockType(editorState, "blockquote");
    if (newState) {
      onChange(newState);
    }
  };
  const toggleCode = () => {
    bqActive && setBqActive(codeActive);
    setCodeActive(!codeActive);
    const newState = RichUtils.toggleBlockType(editorState, "code-block");
    if (newState) {
      onChange(newState);
    }
  };

  return (
    <div className="rdw-custom-wrapper">
      <div
        className={`rdw-option-wrapper ${
          codeActive ? "rdw-option-active" : ""
        }`}
        title="Code"
      >
        <img
          src={code}
          alt="code"
          className="rdw-storybook-custom-code"
          onClick={toggleCode}
        />
      </div>
      <div
        className={`rdw-option-wrapper ${bqActive ? "rdw-option-active" : ""}`}
        title="Blockquote"
      >
        <img
          src={blockquote}
          alt="blockquote"
          className="rdw-storybook-custom-blockquote"
          onClick={toggleBlock}
        />
      </div>
    </div>
  );
}

function LiveSession() {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorFocus, setEditorFocus] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [userType, setUserType] = useState(false);
  const { requestId, sessionId } = useParams();
  const userr = useSelector((state) => state.user.profile);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    localStorage.setItem(
      "sessionText",
      editorState.getCurrentContent().getPlainText()
    );
  };

  const handleNotes = (value, condition = false) => {
    const text = localStorage.getItem("sessionText");
    setShowNotes(value);

    if (condition && text !== null && text.length > 1) {
      postSessionNotes(
        text,
        requestId,
        sessionId,
        userr.type === ProfileType.mentor ? true : false
      )
        .then((res) => {
          if (res) {
            localStorage.setItem("sessionText", "");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="live-session">
      <Row className="g-0">
        <Col className="mx-4">
          <div className="video-call">
            <ZegoCloud handleNotes={handleNotes} />
          </div>
          {showNotes && (
            <div className="notes">
              <h3>
                <img src={_getImage("notes.svg")} alt="notes" height={22} />{" "}
                Notes
              </h3>
              <Editor
                wrapperClassName="notes-wrapper"
                editorClassName={`notes-editor ${
                  editorFocus ? "editor-focus" : ""
                }`}
                editorState={editorState}
                onFocus={(_) => setEditorFocus(true)}
                onBlur={(_) => setEditorFocus(false)}
                placeholder="Take your notes here..."
                onEditorStateChange={onEditorStateChange}
                toolbarCustomButtons={[<CustomOption />]}
                toolbar={ToolbarConfig}
              />
            </div>
          )}
        </Col>
        {/* This ChatBox would be used in future enhancement */}
        {/* <Col md={3} className="chat-box g-0">
          <div className="d-flex justify-content-center">
            <div className="wrapper">
              <div className='chat-header'>
                <div className='profile d-flex'>
                  <div className='me-2'>
                    <img src={_getImage('avatar.svg')} height="42" alt="avtar" />
                  </div>
                  <div>
                    <h3>Mark Markov <img src={_getImage('tick_mark.svg')} alt="verified" height={16} /></h3>
                    <p><small>Online</small></p>
                  </div>
                </div>
                <div className='search'>
                  <img src={_getImage('searchbar.svg')} alt="search" height={20} />
                </div>
              </div>
              <div className="chat-body">
                <div className="px-3 scroll">
                  <div className="text-center between">Yesterday</div>
                  <div className="d-flex align-items-center">
                    <div className="pr-2 pl-1 mb-2">
                      <p className="msg">Hello!</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="pr-2 pl-1 mb-2">
                      <p className="msg">I think it's a great idea</p>
                      <div className="name">10:04 AM, Mark Markov</div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center text-right justify-content-end ">
                    <div className="pr-2 mb-2">
                      <p className="msg">Let's jump on a video call</p>
                      <div className="name">10:06 AM <img src={_getImage('check-mark.svg')} alt="verified" /></div>
                    </div>
                  </div>
                  <div className="text-center between">Today</div>
                  <div className="d-flex align-items-center">

                    <div className="pr-2 mb-2 pl-1">
                      <p className="msg">Yes, It will decrease the loading time</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center text-right justify-content-end ">
                    <div className="pr-2 mb-2">
                      <p className="msg">Let's jump on a video call</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">

                    <div className="pr-2 mb-2 pl-1">
                      <p className="msg">Hello</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="pr-2 mb-2 pl-1">
                      <p className="msg">I'm available now</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="pr-2 mb-2 pl-1">
                      <p className="msg">I have some doubts</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="pr-2 mb-2 pl-1">
                      <p className="msg">How often should i take this?</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="pr-2 mb-2 pl-1">
                      <p className="msg">I took the free class liked it!</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center text-right justify-content-end ">
                    <div className="pr-2 mb-2">
                      <p className="msg">Oh great!</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center text-right justify-content-end ">
                    <div className="pr-2 mb-2">
                      <p className="msg">Let's get started then</p>
                    </div>
                  </div>
                </div>
                <nav className="navbar d-flex flex-nowrap justify-content-between">
                  <div className="input-group me-2">
                    <input type="text number" name="text" className="form-control" placeholder="Write a message here..." />
                    <div className="input-group-text">
                      <img src={_getImage('smiley.svg')} alt="send" height={18} />
                    </div>
                  </div>
                  <Button variant="primary" onClick={() => setShowRating(true)}>
                    <img src={_getImage('send.svg')} alt="send" height={18} />
                  </Button>
                </nav>
              </div>
            </div>
          </div>
        </Col> */}
      </Row>
    </div>
  );
}

export default LiveSession;
