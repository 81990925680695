//** Loader ProgressBar,can use this common Component required Field **//

import ProgressBar from "react-bootstrap/ProgressBar";
import { useSelector } from "react-redux";
import {
  loaderNumberValue,
  loaderValue,
} from "../redux-tk/slices/loader.slice";

function IgLoader() {
  const selectedValue = useSelector((state) => loaderValue(state));
  const selectedValueNumber = useSelector((state) => loaderNumberValue(state));
  console.log("selectedValueNumber", selectedValueNumber);
  return selectedValueNumber <= 0 ? (
    <></>
  ) : (
    <div className="loader-div position-fixed top-0 w-100">
      <span></span>
      <ProgressBar
        className="ig-loader"
        visuallyHidden={selectedValue}
        animated
        now={100}
      />
    </div>
  );
}

export default IgLoader;
