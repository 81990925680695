//** GinieHealth AddingUsers Details,Like Name,MobNo,Email,Password,Status,Type are defined Ui code of UserManagement**//

import { useState, useMemo, useContext, useEffect } from "react";
import { Button, Modal, Form, Spinner, Row, Col } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { addNewUserValidation } from "../../FormSchemas/add.new.user";
import { addUser } from "../../redux-tk/slices/user.management.slice";
import { FormLabelComponent } from "../../components/form.label.component";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { CustomSelect } from "../../components/CustomInput/custom.select";
import { FormErrorComponent } from "../../components/form.error.component";
import Toaster from "../../components/toaster";
import { AuthContext } from "../../hooks/auth.context.provider";
import { CustomOTPPopup } from "../../components/CustomInput/customOTPPopup";
import { ProfileType } from "../../enums";
import {
  ChangeEmailApi,
  ChangeMobileApi,
} from "@iguroo/ig-react-assessment-package";
import { Popup } from "../../components/CustomInput/popup";
import { _getImage } from "../../common/utils/commonFunctions";
import { EmailMobileCheckApi } from "@iguroo/ig-react-assessment-package/dist/ig-react-assessment-package.cjs.production.min";
import { CustomPasswordInputField } from "../../components/customPasswordInputField";
import {
  addIgurooUser,
  getOrganizationId,
  getUserRoleId,
} from "../../services/axios/UserManagement";

export const AddUser = (props) => {
  const {
    show,
    onHide,
    data,
    edit,
    updateList,
    restrictedEdit,
    showPasswordFields,
    iSAddPopup,
    igurooUser,
  } = props;
  console.log("properties", props);
  const [loading, setLoading] = useState(false);
  const [showOTPPopup, setShowOTPPopup] = useState(false);
  const [update, setUpdate] = useState(false);
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const [email, setEmail] = useState(data.email);
  const [phoneNumber, setPhoneNumber] = useState(data.mobile);
  const [enableEdit, setEnableEdit] = useState("");
  const [verifyType, setVerifyType] = useState("");
  const [verifyMobile, setverifyMobile] = useState(data.mobile);
  const [verifyEmail, setVerifyEmail] = useState(data.email);
  const [userUpdate, setUserUpdate] = useState({});
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [igurooRoleOptions, setIgurooRoleOptions] = useState([]);

  const currentUser = useMemo(() => {
    console.log(authContext?.sessionInfo?.role, "authContext");
    return authContext?.sessionInfo;
  }, [authContext.authStatus]);
  const [showSuccsessPopup, setshowSuccsessPopup] = useState(false);
  const userType = useMemo(() => {
    return authContext?.sessionInfo?.role?.toUpperCase();
  }, [authContext.authStatus]);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(addNewUserValidation(edit, userType)),
    defaultValues: {
      type:
        (currentUser?.role.toUpperCase() === ProfileType.supervisor ||
          currentUser?.role.toUpperCase() === "RECRUITER" ||
          currentUser?.role.toUpperCase() === "COMPLIANCE" ||
          currentUser?.role.toUpperCase() === "ADMIN") &&
        !restrictedEdit
          ? ProfileType.learner
          : currentUser?.role.toUpperCase(),
      ...data,
    },
  });
  console.log(data, "data.names");

  useEffect(() => {
    if (userType === ProfileType.admin && igurooUser) {
      getallOrganization();
      setValue("userType", "igurooUser");
    } else {
    }
  }, [userType, igurooUser]);

  async function getallOrganization() {
    try {
      const res = await getOrganizationId();
      // console.log(res, "datauseriguroo");
      if (res.response.data) {
        const options = res.response.data.map((data) => ({
          label: data.organzation_id,
          value: data.organzation_id,
        }));
        setOrganizationOptions(options);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleUserRoleChange = async (data) => {
    try {
      const res = await getUserRoleId(data);
      // console.log(data, "datauseriguroo");
      if (res.response.data) {
        const roles = res.response.data.map((role) => ({
          label: role.role_name,
          value: role.role_id,
        }));
        // console.log(roles, "datauseriguroo");
        setIgurooRoleOptions(roles);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const submit = async (userData, addUserPopUp) => {
    try {
      setLoading(true);
      const updatedUser = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        type: userData.type,
        status: userData.status.toUpperCase(),
        mobile: userData.mobile,
      };
      if (userData.organization_id) {
        updatedUser.organization_id = userData.organization_id;
      }
      if (userData.iguroo_role) {
        updatedUser.iguroo_role = userData.iguroo_role;
      }
      if (userData.repeatPassword) {
        updatedUser.password = userData.repeatPassword;
      }
      if (
        userType === ProfileType.compliance &&
        userType === ProfileType.admin &&
        userType === ProfileType.supervisor &&
        userType === ProfileType.author &&
        userType === ProfileType.publisher &&
        userType === ProfileType.learner &&
        !addUserPopUp
      ) {
        updatedUser.password = userData.repeatPassword;
        console.log("Repeat Password:", userData.repeatPassword);
      }
      if (edit) {
        updatedUser.userId = userData.userId;
        delete updatedUser.mobile;
        delete updatedUser.email;
        delete updatedUser.repeatPassword;
      }
      if (restrictedEdit) {
        authContext.changeName(updatedUser.firstName, updatedUser.lastName);
        delete updatedUser.repeatPassword;
      }
      console.log("userType: ", userType);
      console.log("showPasswordFields:", showPasswordFields);
      console.log("Updated User:", updatedUser);

      if (props.igurooUser) {
        try {
          const res = await addIgurooUser(userData);
          console.log(res, "iguroodata");
          if (res) {
            delete userData.userType;
            Toaster(toast.success, "Iguroo User saved successfully");
            updateList();
            onHide();
          } else {
            Toaster(
              toast.error,
              "Something went wrong. Please try again after sometime."
            );
          }
        } catch (err) {
          console.log("err", err);
        }
      } else {
        console.log("ginieuser");
        dispatch(addUser({ ...updatedUser }))
          .then((res) => {
            setLoading(false);
            console.log("checking ", res);
            updateList();
            if (res.hasOwnProperty("error")) {
              Toaster(toast.error, res?.payload?.response?.data?.message);
            } else {
              updateList();
              onHide();
              Toaster(toast.success, res?.payload?.message);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("checking err", err);
            updateList();
            onHide();
            Toaster(
              toast.error,
              "Something went wrong. Please try again after sometime."
            );
          });
      }
    } catch (err) {}
  };

  const EmailOrPhoneChange = (name) => {
    setUpdate(true);
    setLoading(true);
    const verifyClick = update;
    const val = name == "email" ? email : phoneNumber;
    console.log("emailOrPhone out", val, name);
    let emailCheck = {};
    if (name == "email") {
      emailCheck.email = val;
    } else {
      emailCheck.mobile = val;
    }
    if (Object.keys(errors).length === 0 && !verifyClick) {
      setUpdate(true);
      EmailMobileCheckApi(emailCheck)
        .then((res) => {
          console.log(res);
          setLoading(false);
          if (val != "") {
            console.log("emailOrPhone", val, name);
            if (restrictedEdit) {
              authContext
                .changeEmailPhone(name, val)
                .then(() => {
                  let setUser = {
                    userId: data.userId,
                  };
                  if (name == "email") {
                    setVerifyEmail(val);
                    setUser.email = val;
                  } else {
                    setverifyMobile(val);
                    setUser.mobile = val;
                  }
                  setUserUpdate(setUser);
                  setShowOTPPopup(true);
                  setUpdate(false);
                  updateList();
                })
                .catch((err) => {
                  Toaster(
                    toast.error,
                    "Something went wrong. Please try again after sometime."
                  );
                  setUpdate(false);
                });
            } else if (
              authContext?.sessionInfo?.role == ProfileType.supervisor ||
              authContext?.sessionInfo?.role == ProfileType.admin ||
              authContext?.sessionInfo?.role == ProfileType.compliance
            ) {
              if (name == "email") {
                const dataValue = {
                  userId: data.userId,
                  oldEmail: verifyEmail,
                  newEmail: val,
                };
                ChangeEmailApi(dataValue)
                  .then((res) => {
                    setVerifyEmail(val);
                    dispatch(
                      addUser({
                        userId: data.userId,
                        email: val,
                      })
                    )
                      .then((res) => {
                        setLoading(false);
                        if (res.hasOwnProperty("error")) {
                          Toaster(
                            toast.error,
                            res?.payload?.response?.data?.message
                          );
                        } else {
                          Toaster(toast.success, "User Email Updated");
                        }
                      })
                      .catch((err) => {
                        Toaster(
                          toast.error,
                          "Something went wrong. Please try again after sometime."
                        );
                        setUpdate(false);
                        setLoading(false);
                      });
                  })
                  .catch((e) => {
                    Toaster(
                      toast.error,
                      "Something went wrong. Please try again after sometime."
                    );
                    setUpdate(false);
                  });
              } else if (name == "phone_number") {
                const dataValue = {
                  userId: data.userId,
                  oldphonenumber: verifyMobile,
                  newphonenumber: val,
                };
                ChangeMobileApi(dataValue).then((res) => {
                  setverifyMobile(val);
                  dispatch(addUser({ userId: data.userId, mobile: val }))
                    .then((res) => {
                      setLoading(false);
                      if (res.hasOwnProperty("error")) {
                        Toaster(
                          toast.error,
                          res?.payload?.response?.data?.message
                        );
                      } else {
                        Toaster(toast.success, "User Mobile Updated");
                      }
                    })
                    .catch((err) => {
                      Toaster(
                        toast.error,
                        "Something went wrong. Please try again after sometime."
                      );
                      setUpdate(false);
                      setLoading(false);
                    });
                });
              }
            }
            setVerifyType(name);
            setUpdate(false);
          }
          setEnableEdit("");
          setUpdate(false);
          setLoading(false);
        })
        .catch((err) => {
          Toaster(toast.error, err?.response?.data?.message);
          console.log(err?.response?.data?.message);
          setUpdate(false);
          setLoading(false);
        });
    }
  };
  console.log(
    "currentUser?.role.toUpperCase()",
    currentUser?.role.toUpperCase()
  );

  const roleOptions = [
    { value: ProfileType.learner, label: ProfileType.learner },
  ];
  if (
    currentUser?.role.toUpperCase() !== ProfileType.learner &&
    edit &&
    restrictedEdit
  ) {
    roleOptions.push({
      value:
        currentUser?.role.toUpperCase() === ProfileType.supervisor ||
        currentUser?.role.toUpperCase() === "RECRUITER"
          ? ProfileType.recruiter
          : currentUser?.role.toUpperCase(),
      label:
        currentUser?.role.toUpperCase() === ProfileType.supervisor ||
        currentUser?.role.toUpperCase() === "RECRUITER"
          ? "RECRUITER"
          : currentUser?.role.toUpperCase(),
    });
  }
  if (currentUser?.role.toUpperCase() === "ADMIN") {
    if (currentUser?.role.toUpperCase() !== "ADMIN")
      roleOptions.push({ value: "ADMIN", label: "ADMIN" });
    if (currentUser?.role.toUpperCase() !== ProfileType.recruiter)
      roleOptions.push({ value: ProfileType.recruiter, label: "RECRUITER" });
    roleOptions.push({ value: "PUBLISHER", label: "PUBLISHER" });
    roleOptions.push({ value: "AUTHOR", label: "AUTHOR" });
    roleOptions.push({ value: "COMPLIANCE", label: "COMPLIANCE" });
  }

  const statusOptions = [{ value: "ACTIVE" }, { value: "INACTIVE" }];

  console.log("roleoptions", roleOptions);
  console.log("statusoptions", statusOptions);
  const userData = [
    {
      label: "First Name",
      name: "firstName",
      fieldName: "firstName",
      disabled: false,
      showVarification: false,
    },
    {
      label: "Last Name",
      name: "lastName",
      disabled: false,
      fieldName: "lastName",
      showVarification: false,
    },
    {
      label: "Email",
      name: "email",
      disabled: edit ? true : false,
      fieldName: "email",
      showVarification: edit ? true : false,
    },
    {
      label: "Mobile Number",
      name: "mobile",
      disabled: edit ? true : false,
      fieldName: "phone_number",
      showVarification: edit ? true : false,
    },
  ];

  return (
    <div style={{ backgroundColor: "green", position: "relative" }}>
      <Modal
        show={show}
        onHide={() => {
          updateList();
          onHide();
        }}
        backdrop="static"
        className="pop-up"
        size="lg"
      >
        <Modal.Header closeButton className="text-center">
          <Modal.Title className="w-100">
            {edit ? "Update" : "Add New"} User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(submit)}>
            {userData.map((item, index) => (
              <Row>
                {console.log("userdata", item)}
                <Col sm={item.showVarification && edit ? 10 : 12}>
                  <Form.Group
                    className={`mb-2 ${
                      item.disabled && enableEdit !== item.name
                        ? "disabled-input"
                        : ""
                    }`}
                  >
                    <FormLabelComponent>{item.label}</FormLabelComponent>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <CustomInputField
                            placeholder={`Enter ${item.label.toLowerCase()}`}
                            hookOnBlur={(val) => {
                              console.log(value);
                            }}
                            onChange={(val) => {
                              console.log("emailOrPhone", val);
                              if (item.fieldName == "email") setEmail(val);
                              else if (item.fieldName == "phone_number")
                                setPhoneNumber(val);
                              console.log(value);
                            }}
                            hookOnChange={onChange}
                            hookValue={value}
                            disabled={
                              item.disabled
                                ? enableEdit == item.name
                                  ? false
                                  : true
                                : false
                            }
                          />
                        );
                      }}
                      name={item.name}
                    />

                    <FormErrorComponent>
                      {errors[item.name]?.message}
                    </FormErrorComponent>
                  </Form.Group>
                </Col>

                {item.showVarification &&
                ((item.name == "email" && email != verifyEmail) ||
                  (item.name == "mobile" && phoneNumber != verifyMobile) ||
                  enableEdit == item.name) ? (
                  <Col sm={item.showVarification ? 2 : 12}>
                    <FormLabelComponent></FormLabelComponent>
                    <FormLabelComponent></FormLabelComponent>
                    <Row className="d-flex align-items-center mt-2">
                      <Col sm={4}>
                        <div>
                          {" "}
                          <img
                            src={_getImage("close-dark.svg")}
                            className="my-auto"
                            height="20"
                            width="20"
                            alt="cancel"
                            role="button"
                            onClick={() => {
                              if (item.fieldName == "email") {
                                setEmail(verifyEmail);
                                setValue("email", verifyEmail);
                              } else if (item.fieldName == "phone_number") {
                                setPhoneNumber(verifyMobile);
                                setValue("mobile", verifyMobile);
                              }
                              setEnableEdit("");
                            }}
                          />
                        </div>
                      </Col>
                      {(item.name == "email" && email != verifyEmail) ||
                      (item.name == "mobile" && phoneNumber != verifyMobile) ? (
                        <Col sm={8}>
                          <div>
                            <img
                              src={_getImage("tick-100.svg")}
                              className="my-auto"
                              height="20"
                              width="20"
                              alt="cancel"
                              role="button"
                              onClick={() => EmailOrPhoneChange(item.fieldName)}
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  </Col>
                ) : item.showVarification && enableEdit !== item.name ? (
                  <Col sm={item.showVarification ? 2 : 12}>
                    <FormLabelComponent> </FormLabelComponent>
                    <div>
                      {" "}
                      <img
                        src={_getImage("edit.svg")}
                        className="ms-2 mt-2"
                        height="20"
                        width="20"
                        alt="edit"
                        role="button"
                        onClick={() => {
                          console.log(
                            "edit click 165",
                            item.name,
                            item.disabled,
                            enableEdit == item.name
                          );
                          setEmail(verifyEmail);
                          setValue("email", verifyEmail);
                          setPhoneNumber(verifyMobile);
                          setValue("mobile", verifyMobile);
                          setEnableEdit(item.name);
                        }}
                      />
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            ))}
            <Controller
              name={`userType`}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Control
                  type="hidden"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                ></Form.Control>
              )}
            />
            {userType === ProfileType.admin && igurooUser && (
              <>
                <Row>
                  <Form.Group
                    className={`mb-2 ${restrictedEdit ? "disabled-input" : ""}`}
                  >
                    <FormLabelComponent>Organization</FormLabelComponent>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <CustomSelect
                            opLabel="label"
                            opValue="value"
                            items={organizationOptions}
                            onReactHookFormBlur={onBlur}
                            onReactHookFormChange={(data) => {
                              onChange(data);
                              handleUserRoleChange(data);
                            }}
                            value={value}
                            isDisabled={restrictedEdit}
                          />
                        );
                      }}
                      name={"organization_id"}
                    />
                    <FormErrorComponent>
                      {errors?.organization_id?.message}
                    </FormErrorComponent>
                  </Form.Group>
                </Row>
                <Row>
                  {/* {edit ? (
                    <h1>{data.type}</h1>
                  ) : ( */}
                  <Form.Group
                    className={`mb-2 ${restrictedEdit ? "disabled-input" : ""}`}
                  >
                    <FormLabelComponent>UserRole Options</FormLabelComponent>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <CustomSelect
                            opLabel="label"
                            opValue="value"
                            items={igurooRoleOptions}
                            onReactHookFormBlur={onBlur}
                            onReactHookFormChange={onChange}
                            value={value}
                            isDisabled={restrictedEdit}
                          />
                        );
                      }}
                      name={"iguroo_role"}
                    />
                    <FormErrorComponent>
                      {errors?.iguroo_role?.message}
                    </FormErrorComponent>
                  </Form.Group>
                  {/* )} */}
                </Row>
              </>
            )}
            {(userType === ProfileType.supervisor &&
              userType === ProfileType.compliance &&
              userType !== ProfileType.admin) ||
              (!igurooUser && (
                <>
                  <Form.Group
                    className={`mb-2 ${restrictedEdit ? "disabled-input" : ""}`}
                  >
                    <FormLabelComponent>User Role</FormLabelComponent>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <CustomSelect
                            opLabel="label"
                            opValue="value"
                            items={roleOptions}
                            onReactHookFormBlur={onBlur}
                            onReactHookFormChange={onChange}
                            value={value}
                            isDisabled={restrictedEdit}
                          />
                        );
                      }}
                      name={"type"}
                    />
                    <FormErrorComponent>
                      {errors?.type?.message}
                    </FormErrorComponent>
                  </Form.Group>
                </>
              ))}
            <Form.Group
              className={`mb-2 ${restrictedEdit ? "disabled-input" : ""}`}
            >
              <FormLabelComponent>Status</FormLabelComponent>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <CustomSelect
                      opLabel="value"
                      opValue="value"
                      items={statusOptions}
                      onReactHookFormBlur={onBlur}
                      onReactHookFormChange={onChange}
                      value={value}
                      isDisabled={restrictedEdit}
                    />
                  );
                }}
                name={"status"}
              />
              <FormErrorComponent>{errors?.status?.message}</FormErrorComponent>
            </Form.Group>
            {!iSAddPopup &&
              (!showPasswordFields || userType === ProfileType.compliance) && (
                <Form.Group>
                  <FormLabelComponent className="reset-nepwd">
                    Reset Password
                  </FormLabelComponent>
                  <Row>
                    <Col>
                      <FormLabelComponent>New Password</FormLabelComponent>
                      <Controller
                        name={`newPasswordSecurity`}
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomPasswordInputField
                            type="password"
                            className="user-password"
                            hookOnBlur={onBlur}
                            hookValue={value}
                            hookOnChange={onChange}
                          />
                        )}
                      />
                      <FormErrorComponent>
                        {errors.newPasswordSecurity &&
                          errors.newPasswordSecurity.message}
                      </FormErrorComponent>
                    </Col>
                    <Col>
                      <FormLabelComponent>
                        Confirm New Password
                      </FormLabelComponent>
                      <Controller
                        name={`repeatPassword`}
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomPasswordInputField
                            type="password"
                            className="user-password"
                            hookOnChange={onChange}
                            hookOnBlur={onBlur}
                            hookValue={value}
                          />
                        )}
                      />
                      <FormErrorComponent>
                        {errors.repeatPassword && errors.repeatPassword.message}
                      </FormErrorComponent>
                    </Col>
                  </Row>
                </Form.Group>
              )}
            <div className="d-flex justify-content-end">
              <Button
                variant="primary"
                type="submit"
                className="mt-3"
                disabled={
                  (email != verifyEmail || phoneNumber != verifyMobile) && edit
                    ? true
                    : false
                }
              >
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
        {loading && (
          <div className="spinner">
            <Spinner animation="border" />
          </div>
        )}
      </Modal>
      <CustomOTPPopup
        closeOnBackClick={false}
        showPopup={showOTPPopup}
        type="popup"
        verifyType={verifyType}
        modalBody={"OTP Verified"}
        onHide={() => setShowOTPPopup(false)}
        submitText={"Submit"}
        verifyText={verifyType == "email" ? verifyEmail : verifyMobile}
        userUpdate={userUpdate}
        updateList={updateList}
      />
      <Popup
        showPopup={showSuccsessPopup}
        type="popup"
        modalBody={"Details Verified"}
        onHide={() => {
          setshowSuccsessPopup(false);
          updateList();
        }}
        submitText={"OK"}
      />
    </div>
  );
};
