//** Iguroo Mentor Rate Component UI code **//

import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useWatch } from "react-hook-form";
import { CustomCheckButton } from "./CustomInput/custom.switch";
import { CustomInputField } from "./CustomInput/customInputField";
import { FormErrorComponent } from "./form.error.component";
import { FormLabelComponent } from "./form.label.component";

export function MentorRate(props) {
  const {
    control,
    inputLabel,
    inputName,
    inputPlaceholder,
    isFirstFreeEnabled,
    freeFirstName,
    freeFirstLabel,
    isSwitchEnabled,
    switchEnabledName,
    switchDefaultValue,
    freesSessionNote,
    errors,
  } = props;

  const enableInput = useWatch({
    control,
    name: switchEnabledName,
    defaultValue: switchDefaultValue,
  });
  //console.log("err",errors);
  console.log(
    "sw console",
    switchEnabledName,
    isSwitchEnabled,
    "def",
    switchDefaultValue,
    "fina",
    enableInput,
    isSwitchEnabled ? (enableInput === undefined ? false : !enableInput) : false
  );
  return (
    <Row className="mt-3">
      <Col md={isSwitchEnabled ? 11 : 12} xs={isSwitchEnabled ? 10 : 12}>
        <label>
          <FormLabelComponent className="formLabel form-label">
            {inputLabel}
          </FormLabelComponent>
        </label>
        <Controller
          //rules={{ disabled: isSwitchEnabled ? (enableInput === undefined) ? false : !enableInput : false }}
          name={inputName}
          control={control}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <CustomInputField
              type="number"
              placeholder={inputPlaceholder}
              defaultValue={`0`}
              hookOnChange={onChange}
              hookOnBlur={onBlur}
              hookValue={value}
              name={name}
              min={`0`}
              className="rateinput"
              disabled={isSwitchEnabled ? !enableInput : false}
            />
          )}
        />
        {freesSessionNote ? (
          <Form.Label className="formLabelNote">{freesSessionNote}</Form.Label>
        ) : (
          ""
        )}
        <div>
          <FormErrorComponent>
            {errors[inputName] && errors[inputName]?.message}
          </FormErrorComponent>
        </div>
        {isFirstFreeEnabled && (
          <div className="mt-1">
            <Controller
              name={freeFirstName}
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <CustomCheckButton
                  hookOnChange={onChange}
                  hookOnBlur={onBlur}
                  hookValue={value}
                  name={name}
                  type="checkbox"
                  label={freeFirstLabel}
                  defaultChecked={false}
                  checked={value}
                  disabled={isSwitchEnabled ? !enableInput : false}
                />
              )}
            />
          </div>
        )}
      </Col>
      {isSwitchEnabled && (
        <Col
          md="1"
          xs="2"
          className={`d-flex justify-content-end align-items-center ${
            isFirstFreeEnabled ? "" : "mentor-onboarding-rates-checkbox-margin"
          }`}
        >
          <Controller
            name={switchEnabledName}
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <CustomCheckButton
                hookOnChange={onChange}
                hookOnBlur={onBlur}
                hookValue={value}
                name={name}
                defaultChecked={switchDefaultValue}
                checked={value}
                className={"d-flex justify-content-end "}
              />
            )}
          />
        </Col>
      )}
    </Row>
  );
}
