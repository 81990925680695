import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
// import "./component/styles.scss";
import update from "immutability-helper";
import { useForm, Controller } from "react-hook-form";
import {
  TimeTakenValue,
  TimeleftValue,
  answerSelected,
  answerValue,
  changeGLCurrentQuestion,
  changeTimeTaken,
  createAssessmentAttemptAsyncThunk,
  currentGLQuestionValue,
} from "../../../redux-tk/slices/genieLabs.currentQuestion";
import { useDispatch, useSelector } from "react-redux";
import QFooter from "../../questions/qFooter";
import { AuthContext } from "../../../hooks/auth.context.provider";
import { FormErrorComponent } from "../../form.error.component";
import { useIgurooTranslations } from "../../../hooks/use.iguroo.translation";
import { loader } from "../../../redux-tk/slices/loader.slice";
import { useNavigate } from "react-router-dom";
import { UpdateAssessmentAttemptApi } from "@iguroo/ig-react-assessment-package";
import { MultiMultiTest } from "../../questionComponent/component/heading.component";
import { TestQuestionComponent } from "../../questionComponent/component/question.component";
import CheckBoxRadioAnswerOptions from "../../questionComponent/component/customradioOption";

const QuizMCQ = (props) => {
  const {
    mentorOnboardingLabels,

    formErrors,
    headingTranslations,
    testAssessmentTranslations,
    genieLabsAssessmentTranslations,
  } = useIgurooTranslations();
  const {
    control,
    register,
    reset,
    handleSubmit,
    placeHolders,
    watch,
    setError,
    setValue,
    getValues,
    clearErrors,
    formState: { errors},
  } = useForm();
  console.log('assessmentw t,',props);
  const dispatch = useDispatch();
  const currentQuestion = useSelector((state) => currentGLQuestionValue(state));
  const selectedAnswers = useSelector((state) => answerValue(state));
  const [answers, setAnswers] = useState(selectedAnswers);
  const [selectAnswer, setSelectAnswers] = useState();
  const [isSubmitting, setisSubmitting] = useState(false);
  const options = [
    { value: "Yes" },
    { value: "No" },
    
  ];

  useEffect(() => {
    // (async function () {
    dispatch(loader(true));
    const ansSelect = selectedAnswers.filter(
      (ans) => ans.question_id === props?.questionId
    );
    if (ansSelect.length > 0) {
     console.log(ansSelect[0].answer[0], "console assessmentw answw");
      setSelectAnswers(ansSelect[0].answer[0]);
     
      setValue(`answerOption`, ansSelect[0].answer[0]);
      setisSubmitting(false);
    } else {
      setisSubmitting(false);
    }
    setAnswers(selectedAnswers);
    dispatch(loader(false));

    // })();
  }, []);
 
  const previousClick = () => {
    console.log("next click", currentQuestion);
    if (currentQuestion > 0)
      dispatch(changeGLCurrentQuestion(currentQuestion - 1));
  };

  const authContext = useContext(AuthContext);
  console.log("165 name", props?.assessmentData?.title);
  const onNextClick = (data) => {
    setisSubmitting(true);
    dispatch(loader(true));
    let isEmptyAllow = false;
    let isEmpty = false;
    

    if (!isEmptyAllow && isEmpty) {
      setError("answerOption", {
        message: testAssessmentTranslations?.AssessmentOptionError,
      });
      dispatch(loader(false));
      setisSubmitting(false);
    } else {
      
      const ansSelect = answers.filter(
        (ans) => ans.question_id === props?.questionId
      );
      let ans = [];
      

      var commentIndex = answers.findIndex(
        (c) => c.question_id === props?.questionId
      );

      let answer = {
        question_id: props.questionId,
        answer: [data?.answerOption],
        isMark: false,
      };
      

      if (ansSelect.length === 0) {
        ans = [...answers, answer];
        setAnswers([...answers, answer]);
      } else {
        let updatedItems = answers;
        if (commentIndex > -1) {
          updatedItems = update(answers, {
            [commentIndex]: { $set: answer },
          });
        }
        ans = updatedItems;
      }
      console.log(ans,props.questionId,'data assessmentw Que')

      dispatch(answerSelected(ans));
      const attemptData = {
        attempt_id: props?.attempid,
        status:
          currentQuestion + 1 >= props?.totalQuestion
            ? "COMPLETED"
            : "INPROGRESS",
        answers: ans,
        assessment_version_id: props?.assessment_version_id,
        userId: authContext.sessionInfo.sub,
      };
      
      
        UpdateAssessmentAttemptApi(attemptData)
          .then((res) => {
            if( props?.totalQuestion > currentQuestion + 1){
              dispatch(changeGLCurrentQuestion(currentQuestion + 1));
            }
            
            dispatch(loader(false));
            setisSubmitting(false);
          })
         .catch((err) => console.log(err));
      
         if( props?.totalQuestion <= currentQuestion + 1){
          props.QuizMCQSubmit();
         }
    }
  };
  const navigate = useNavigate();
 
  
  console.log(errors, "answersss 165", props);
  return (
    <Container className="">
      <Row className="d-flex justify-content-center">
       
        
        <Col sm="11">
          
          <Row className="mt-3">
            <Col>
              <TestQuestionComponent
                text={props?.question?.question_text}
                queNo={currentQuestion + 1}
              />
            </Col>
          </Row>
          <Row className="">
            <Form onSubmit={handleSubmit(onNextClick)} className="mt-1">
              <Row className="test-answer-block p-4 mt-2 ">
                {" "}
               

                      
                      <Col className="select-option d-flex mb-4" md={6}>
                  <Controller
                    name={"answerOption"}
                    control={control}
                    render={({ field: { onChange, onBlur, value, name } }) => (
                      <CheckBoxRadioAnswerOptions
                      type={'radio'}
                    options={options}
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    name={name}
                    defaultChecked={selectAnswer}/>
                    )}
                  />
                </Col>
                  
                 
              </Row>
              <Row>
                <FormErrorComponent>
                  {errors?.answerOption && errors?.answerOption?.message}
                </FormErrorComponent>
              </Row>
              <Row>
                <QFooter
                  showPrevious={  currentQuestion ==0?false:true}
                  onPreviousClick={previousClick}
                 
                  nextText={
                    props?.totalQuestion > currentQuestion + 1
                      ? genieLabsAssessmentTranslations?.next
                      : genieLabsAssessmentTranslations?.submit
                  }
                  onNextClick={() => {
                    clearErrors("answerOption");
                    handleSubmit(onNextClick);
                  }}
                  isSubmitting={isSubmitting}
                />
              </Row>
            </Form>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default QuizMCQ;
