//** CustomInput Password Field,can re-use this common Component required Field **//

import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import eyeoff from "../../src/assets/images/eyeoff.svg";
import eyeopen from "../../src/assets/images/eyeopen.svg";

export function CustomPasswordInputField(props) {
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => {
    setShowPassword(showPassword ? false : true);
  };
  const {
    placeholder,
    name,
    hookOnChange,
    onChange,
    hookOnBlur,
    hookValue,
    className,
    defaultValue,
    disabled,
    formAs,
    rows,
    min,
    max,
    inputStyle,
    maxLength,
  } = props;
  return (
    <div className="custom-password-with-icon-div d-flex">
      <InputGroup>
        <Form.Control
          {...(formAs && { as: formAs })}
          {...(rows && { rows: rows })}
          type={showPassword ? "text" : "password"}
          style={{ ...inputStyle }}
          className={`custom-password-with-icon custom-input ${className}`}
          placeholder={placeholder}
          name={name}
          onChange={(val) => {
            if (hookOnChange) hookOnChange(val);
            if (onChange) onChange(val.target.value);
          }}
          {...(hookValue && { value: hookValue })}
          onBlur={(val) => {
            if (hookOnBlur) hookOnBlur(val);
          }}
          autocomplete="new-password"
          {...(defaultValue && { defaultValue: defaultValue })}
          disabled={disabled}
          min={min}
          max={max}
          {...(maxLength && { maxLength: maxLength })}
        />
        <InputGroup.Text className="security-password">
          <img onClick={togglePassword} src={showPassword ? eyeopen : eyeoff} />
        </InputGroup.Text>
      </InputGroup>
    </div>
  );
}
