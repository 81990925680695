//** GinieHealth Publisher,CreateNewAssessment of Author of the Assessments Created UI code**//

import { Button, Card, Row, Col, Form } from "react-bootstrap";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { authorValidation } from "../../FormSchemas/publisher.form";
import CustomAnswerOptions from "../assessment/components/customradioOption";
import { CustomSelect } from "../CustomInput/custom.select";
import { FormErrorComponent } from "../form.error.component";
import {
  AssessmentDetailsDataSelector,
  addDataToAssessmentDetails,
  createAssessmentAsyncThunk,
  tempCreateAssessmentSelector,
} from "../../redux-tk/slices/assessment.slice";
import { getAssessmentObject } from "../../common/DTOS/assessment.dtos";
import { useDispatch, useSelector } from "react-redux";
import AssessmentSideBar from "./AssessmentSideBar";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./styles.css";
import { AppUrlsEnum } from "../../enums";
import { useAssessmentDetailFetch } from "../../hooks/useAssessmentDetailFetch";

const options = [{ value: "Publicly searchable" }, { value: "On invitation" }];

const authors = [{ value: "Genie health care" }];

const Author = () => {
  const { formElementLabels, assessmentsTranslations, formErrors } =
    useIgurooTranslations();
  const tempCreateDataValue = useSelector((state) =>
    tempCreateAssessmentSelector(state)
  );
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      ...tempCreateDataValue.apiData,
      author_name: tempCreateDataValue.apiData?.author_name
        ? tempCreateDataValue.apiData?.author_name
        : "Genie health care",
    },
    resolver: yupResolver(authorValidation(formErrors)),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const assessmentDetailsData = useSelector((state) =>
    AssessmentDetailsDataSelector(state)
  );
  const params = useAssessmentDetailFetch({
    assessmentId: searchParams?.get("assessmentId"),
    versionId: searchParams?.get("versionId"),
  });
  const submit = async (data) => {
    let finalData = { ...assessmentDetailsData };
    const ids = {
      versionId: searchParams.get("versionId"),
      assessmentId: searchParams.get("assessmentId"),
    };
    try {
      const assessmentObj = getAssessmentObject(data);
      finalData = { ...finalData, ...assessmentObj };
      const res = await dispatch(createAssessmentAsyncThunk(finalData));
      dispatch(addDataToAssessmentDetails(finalData));
      if (res?.payload) {
        navigate(AppUrlsEnum.publisherUser);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (tempCreateDataValue?.apiData?.assessment_id) {
      reset({ ...tempCreateDataValue?.apiData });
      setValue(
        "author_name",
        tempCreateDataValue.apiData?.author_name
          ? tempCreateDataValue.apiData?.author_name
          : "Genie health care"
      );
    }
  }, [tempCreateDataValue?.apiData?.assessment_id]);

  return (
    <div className="p-2">
      <h4 className="text-center p-2">{assessmentsTranslations?.author}</h4>
      <Row className="d-flex justify-content-center">
        <Col md={6} className="">
          <Card className="p-4 border-0 shadow">
            <Form onSubmit={handleSubmit(submit)}>
              <p className="subHeader mb-2">Author of this Assessment</p>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomSelect
                    className="dropdowns"
                    opLabel="value"
                    opValue="value"
                    items={authors}
                    onReactHookFormBlur={onBlur}
                    onReactHookFormChange={onChange}
                    value={value}
                    isLoading={false}
                    placeholder={"Select name"}
                  />
                )}
                name="author_name"
              />
              <FormErrorComponent>
                {errors.author_name && errors.author_name?.message}
              </FormErrorComponent>
              <div className="mt-5">
                <Controller
                  name={"access_type"}
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <CustomAnswerOptions
                      type={"radio"}
                      options={options}
                      hookOnChange={onChange}
                      hookOnBlur={onBlur}
                      hookValue={value}
                      name={name}
                    />
                  )}
                />
                <FormErrorComponent>
                  {errors.access_type && errors.access_type?.message}
                </FormErrorComponent>
              </div>

              <div className="d-flex justify-content-end">
                <Button type="submit" className="nextBtn">
                  {formElementLabels?.save}
                </Button>
              </div>
            </Form>
          </Card>
        </Col>
        <AssessmentSideBar
          data={assessmentsTranslations?.assessmentProgressView}
          stage={6}
          title={assessmentsTranslations?.assessmentDetails}
          btnValue={formElementLabels?.publishBtn}
          currentTab={assessmentsTranslations?.assessmentProgressView?.author}
        />
      </Row>
    </div>
  );
};

export default Author;
