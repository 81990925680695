//** Iguroo CollegesList Card Details,Currently not using this ,Infurture we would implementing this code **//

import React, { useEffect, useState } from "react";
import "./styles.scss";
import Header from "../../../components/header";
import FooterComponent from "../../../components/footer";
import {
  getCollegesData,
  getSearchData,
} from "../../../services/axios/Colleges";
import {
  Container,
  Row,
  Col,
  Accordion,
  ListGroup,
  ToggleButton,
  Form,
  Card,
  Badge,
  Image,
  InputGroup,
} from "react-bootstrap";
import {
  _getImage,
  _getCurrencySymbol,
} from "../../../common/utils/commonFunctions";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { CustomInputField } from "../../../components/CustomInput/customInputField";
import { useIgurooTranslations } from "../../../hooks/use.iguroo.translation";
import { SubmitButton } from "../../../components/submit.button";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { AppUrlsEnum } from "../../../enums";

const CollegesList = () => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm();
  const {
    mentorOnboardingLabels,
    formElementLabels,
    headingTranslations,
    profileLabels,
  } = useIgurooTranslations();
  const [checked, setChecked] = useState(false);
  const [collegesListObj, setCollegesListObj] = useState();
  const [streamsList, setStreamList] = useState();
  const [streamsListorg, setStreamListorg] = useState();
  const [locationList, setLocationList] = useState();
  const [locationListOrg, setLocationListOrg] = useState();
  const [collegeList, setCollegeList] = useState();
  const [streamInputVal, setStreamInputVal] = useState();
  const [factsList, setFacetsList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  let [searchopen, setSearchOpen] = useState(false);
  const [collegesListFilterObj, setCollegesListFilterObj] = useState({
    collegeName: "",
    streams: [],
    locations: [],
  });

  useEffect(() => {
    let collegesFilterTemp;
    fetchData(collegesListFilterObj);
  }, []);

  const searchSubmitChange = () => {
    fetchData(collegesListFilterObj);
    setSearchOpen(false);
  };

  const fetchData = async (collegesListFilterObjtemp) => {
    getCollegesData(collegesListFilterObjtemp).then((json) => {
      // console.log(" Colleges json Narasimha",json)
      setCollegesListObj(json);
      //  collegesFilterTemp=json;
      setStreamList(json && json.filters[0].Streams);
      setStreamListorg(json && json.filters[0].Streams);
      setLocationList(json && json.filters[0].Location);
      setLocationListOrg(json && json.filters[0].Location);
      const checking = json.colleges
        .slice()
        .sort((a, b) => a.college_name.localeCompare(b.college_name));
      // console.log("json.colleges", json.colleges)
      // setCollegeList(json && json.colleges);
      setCollegeList(json && json.colleges && checking);
    });
  };

  const fetchColleges = async (searchWord) => {
    let searchjson = { input: searchWord };
    let collegesListFilterObjTemp = collegesListFilterObj;
    collegesListFilterObjTemp.collegeName = searchWord;
    setCollegesListFilterObj(collegesListFilterObjTemp);
    getSearchData(searchjson).then((json) => {
      console.log("json is", json.data.response);
      if (searchWord === "") {
        setFilteredData([]);
        setSearchOpen(false);
      } else {
        setSearchOpen(true);
        setFilteredData(json.data.response);
      }
    });
  };

  document.addEventListener("click", function (evt) {
    setSearchOpen(false);
  });

  const onSearchChange = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    fetchColleges(searchWord);
  };

  const onStreamChange = (event) => {
    console.log("coming to change Event", event.target.value, streamsList);
    let streamsListtemp = [...streamsListorg];
    streamsListtemp = streamsListtemp.filter((name) =>
      name.name.toLowerCase().includes(event.target.value)
    );
    console.log("coming to change Event", streamsListtemp);
    setStreamList(streamsListtemp);
    setStreamInputVal(event.target.value);
    if (event.target.value === "") {
      setStreamList(streamsListorg);
    }
  };

  const onLocationChange = (event) => {
    console.log("coming to change Event", event.target.value, locationList);
    let locationListtemp = [...locationListOrg];
    locationListtemp = locationListtemp.filter((name) =>
      name.name.toLowerCase().includes(event.target.value)
    );
    console.log("coming to change Event", locationListtemp);
    setLocationList(locationListtemp);
    setStreamInputVal(event.target.value);
    if (event.target.value === "") {
      setLocationList(locationListOrg);
    }
  };

  const handleCkeckbox = (value, fiterCatefory) => {
    console.log("Checkbox value will be", value, fiterCatefory);
    let factListTemp = [...factsList];
    let isCheckbox = true;
    if (factListTemp.includes(value)) {
      // delete factsList(value);
      const idxObj = factListTemp.findIndex((object) => {
        return object === value;
      });
      isCheckbox = false;
      factListTemp.splice(idxObj, 1);
    } else {
      factListTemp.push(value);
    }
    console.log("Checkbox value will be", value, factListTemp);
    setFacetsList(factListTemp);
    let collegesListFilterObjTemp = collegesListFilterObj;
    if (fiterCatefory === "Streams") {
      let streamsArr = [];
      factListTemp.forEach((obj) => {
        streamsListorg.forEach((obj1) => {
          if (obj === obj1.name) {
            streamsArr.push(obj);
          }
        });
      });
      collegesListFilterObjTemp.streams = streamsArr;
    } else {
      let locationsArr = [];
      factListTemp.forEach((obj) => {
        locationListOrg.forEach((obj1) => {
          if (obj === obj1.name) {
            locationsArr.push(obj);
          }
        });
      });
      collegesListFilterObjTemp.locations = locationsArr;
    }
    console.log("Checkbox value will be1234567", collegesListFilterObjTemp);
    setCollegesListFilterObj(collegesListFilterObjTemp);
    fetchData(collegesListFilterObjTemp);
  };

  const hadleCrossClick = (value) => {
    let factListTemp = [...factsList];
    let streamArrTemp = [...collegesListFilterObj.streams];
    let locationArrTemp = [...collegesListFilterObj.locations];
    let collegesListFilterObjTemp = collegesListFilterObj;
    const idxObj = factListTemp.findIndex((object) => {
      return object === value;
    });

    factListTemp.splice(idxObj, 1);
    const streamObj = streamArrTemp.findIndex((object) => {
      return object === value;
    });
    streamArrTemp.splice(idxObj, 1);
    const locationObj = locationArrTemp.findIndex((object) => {
      return object === value;
    });
    locationArrTemp.splice(idxObj, 1);
    collegesListFilterObjTemp.locations = locationArrTemp;
    collegesListFilterObjTemp.streams = streamArrTemp;
    setCollegesListFilterObj(collegesListFilterObjTemp);
    fetchData(collegesListFilterObjTemp);
    setFacetsList(factListTemp);
  };

  const onClearFilters = () => {
    let factListTemp = [...factsList];
    factListTemp = [];
    setFacetsList(factListTemp);
    let collegesListFilterObjTemp = collegesListFilterObj;
    collegesListFilterObjTemp.streams = [];
    collegesListFilterObjTemp.locations = [];
    setCollegesListFilterObj(collegesListFilterObjTemp);
    fetchData(collegesListFilterObjTemp);
  };

  return (
    <>
      <Header />
      <Container fluid className="college-list-container">
        <Row className="search-field-list">
          <Col md="9">
            <Form className="mt-5">
              <Row className="align-items-end">
                <Col className="mt-2" md="6">
                  <Form.Group>
                    <InputGroup id="searchinput">
                      <InputGroup.Text className="search-icon">
                        <img src={_getImage("searchbar.svg")} />
                      </InputGroup.Text>
                      <Controller
                        name="search"
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInputField
                            placeholder={
                              formElementLabels?.placeHolders?.collegeSearch
                            }
                            hookOnChange={onSearchChange}
                            hookOnBlur={onBlur}
                            hookValue={value}
                          />
                        )}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col sm="12" md="2" className="mt-2 d-grid">
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={searchSubmitChange}
                    className={`btn btn-primary`}
                  >
                    Search
                  </div>
                </Col>
                <Col md="6">
                  {filteredData.length !== 0 && searchopen && (
                    <Col className="dataResult md-6">
                      {filteredData.map((value, index) => {
                        return (
                          <Col className="optVal md-6">
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "#4A4A4A",
                                fontSize: "14px",
                              }}
                              to={`/colleges/courses/${value.iguroo_institute_id}`}
                            >
                              {value.collegeName}
                            </Link>
                          </Col>
                        );
                      })}
                    </Col>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row className="filters-header">
          <Col sm={3}>
            <div>
              <Image src={_getImage("filter.svg")} />
              <span>All Filters</span>
              <div onClick={onClearFilters}>Clear All</div>
            </div>
          </Col>
          <Col sm={6}>
            <div>
              {collegeList && collegeList.length} results | Colleges in India
            </div>
          </Col>
          <Col sm={3}></Col>
        </Row>
        <Row>
          <Col sm={3} className="filters-list-block">
            <Row className="filterTile">
              <Col sm={12} className="filter-list-col">
                <div>
                  {factsList.length > 0 &&
                    factsList.map((prop, key) => {
                      return (
                        <div className="filter-style-div">
                          <span>{prop}</span>
                          <span
                            className="crossmark"
                            onClick={() => hadleCrossClick(prop)}
                          ></span>
                        </div>
                      );
                    })}
                </div>
              </Col>
            </Row>
            <Accordion
              defaultActiveKey={["0", "1"]}
              alwaysOpen
              className="sideBar"
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header>Streams</Accordion.Header>
                <Accordion.Body>
                  <Card>
                    <Card.Body>
                      <InputGroup>
                        {/* <InputGroup.Prepend>*/}
                        <InputGroup.Text className="search-icon">
                          <img src={_getImage("searchbar.svg")} />
                        </InputGroup.Text>
                        <Controller
                          name="search"
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <CustomInputField
                              placeholder={"Streams"}
                              hookOnChange={onStreamChange}
                              hookOnBlur={onBlur}
                              hookValue={value}
                            />
                          )}
                        />
                      </InputGroup>
                      <Card>
                        <Card.Body>
                          {streamsList &&
                            streamsList.map((prop, key) => {
                              return (
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicCheckbox1"
                                >
                                  <Form.Check
                                    type="checkbox"
                                    //label={`${prop.name}(${prop.count})`}
                                    label={
                                      <div style={{ width: "100%" }}>
                                        <span
                                          onClick={() =>
                                            handleCkeckbox(prop.name, "Streams")
                                          }
                                        >
                                          {prop.name}
                                        </span>{" "}
                                        <span style={{ float: "right" }}>
                                          ({prop.count})
                                        </span>
                                      </div>
                                    }
                                    checked={factsList.includes(prop.name)}
                                    // checked={true}
                                  />
                                </Form.Group>
                              );
                            })}
                        </Card.Body>
                      </Card>
                    </Card.Body>
                  </Card>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" alwaysOpen>
                <Accordion.Header>Location</Accordion.Header>
                <Accordion.Body>
                  <Card>
                    <Card.Body>
                      <InputGroup>
                        {/* <InputGroup.Prepend>*/}
                        <InputGroup.Text className="search-icon">
                          <img src={_getImage("searchbar.svg")} />
                        </InputGroup.Text>
                        <Controller
                          name="search"
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <CustomInputField
                              placeholder={"Location"}
                              hookOnChange={onLocationChange}
                              hookOnBlur={onBlur}
                              //hookValue={value}
                            />
                          )}
                        />
                      </InputGroup>
                      <Card>
                        <Card.Body>
                          {locationList &&
                            locationList.map((prop, key) => {
                              return (
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicCheckbox"
                                >
                                  <Form.Check
                                    type="checkbox"
                                    //label={`${prop.name}(${prop.count})`}
                                    label={
                                      <div style={{ width: "100%" }}>
                                        <span
                                          onClick={() =>
                                            handleCkeckbox(
                                              prop.name,
                                              "Location"
                                            )
                                          }
                                        >
                                          {prop.name}
                                        </span>{" "}
                                        <span style={{ float: "right" }}>
                                          ({prop.count})
                                        </span>
                                      </div>
                                    }
                                    checked={factsList.includes(prop.name)}
                                  />
                                </Form.Group>
                              );
                            })}
                        </Card.Body>
                      </Card>
                    </Card.Body>
                  </Card>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col sm={6}>
            <ListGroup as="ol" numbered>
              {collegeList &&
                collegeList.map((Item, key) => {
                  return (
                    <>
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                      >
                        <div
                          className="ms-2 me-auto list"
                          key={Item.iguroo_institute_id}
                        >
                          <Link
                            style={{ textDecoration: "none" }}
                            to={`/colleges/courses/${Item.iguroo_institute_id}`}
                          >
                            <div className="fw-bold list-header">
                              <div>
                                {/* <Image thumbnail></Image> */}
                                {/* <Image
                                  style={{ height: "30px", width: "30px" }}
                                  src={Item.logo_image_url}
                                  roundedCircle
                                /> */}
                              </div>
                              <div className="list-header-text">
                                <div className="fw-bold">
                                  {Item.college_name}
                                </div>
                                <div className="fw-light">{Item.location}</div>
                              </div>
                            </div>

                            <div className="list-content">
                              <Card>
                                <Card.Body>
                                  <Container fluid>
                                    <Row>
                                      <Col
                                        sm={6}
                                        className="content-text-group row"
                                      >
                                        <div className="list-col">
                                          <div className="col-group-1 course-offered-tilte">
                                            Course Offered
                                          </div>
                                          <div className="col-group-2 course-offered-text">
                                            {Item.course_count}
                                          </div>
                                        </div>
                                        <div className="list-col">
                                          <div className="col-group-1">
                                            Total Fees Range
                                          </div>
                                          <div className="col-group-2">
                                            {Item.fees}
                                          </div>
                                        </div>
                                      </Col>
                                      <Col
                                        sm={6}
                                        className="content-text-group row"
                                      >
                                        <div className="list-col">
                                          <div className="col-group-1">
                                            Exams Accepted
                                          </div>
                                          <div
                                            className="col-group-2"
                                            title={Item.exam_accepted}
                                          >
                                            {Item.finalExam
                                              .slice(0, 3)
                                              .toString()}
                                            , +{Item.finalExam.length}
                                          </div>
                                        </div>
                                        <div className="list-col">
                                          <div className="col-group-1">
                                            Rating
                                          </div>
                                          <div className="col-group-2">
                                            {Item.rating}
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Card.Body>
                              </Card>
                            </div>
                          </Link>
                        </div>
                      </ListGroup.Item>
                    </>
                  );
                })}
            </ListGroup>
          </Col>
          <Col className="img-college">
            <Link to={AppUrlsEnum.findMentor}>
              <SubmitButton
                className="college-button"
                text={formElementLabels?.bookSession}
              />
            </Link>
          </Col>
          <Col sm={3}></Col>
        </Row>
      </Container>
      <FooterComponent />
    </>
  );
};

export default CollegesList;
