//** GinieHealth QuestionBank QuestionTypeUI code **//

import { Col, Row } from "react-bootstrap";
import {
  LinearScale,
  MultipleChoice,
  TextQuestion,
  DateTime,
} from "../../components/QuestionBuilder";

const QuestionType = ({ type, control, index }) => {
  switch (type) {
    case "mcq":
      return (
        <MultipleChoice
          index={index}
          type={type}
          icon={"ellipse-empty.svg"}
          name={type}
          length={4}
          btnTitle={"Add Option"}
        />
      );
    case "checkbox":
      return (
        <MultipleChoice
          index={index}
          type={type}
          icon={"checkbox-dark.svg"}
          name={type}
          length={4}
          btnTitle={`Add Option or add "other"`}
        />
      );
    case "dropdown":
      return (
        <MultipleChoice
          index={index}
          type={type}
          name={type}
          length={4}
          btnTitle={`Add Option or add "other"`}
        />
      );
    case "short_answer":
      return (
        <TextQuestion
          control={control}
          type={type}
          name={`quesArray.${index}`}
        />
      );
    case "long_answer":
      return (
        <TextQuestion
          control={control}
          type={type}
          name={`quesArray.${index}`}
        />
      );
    case "mcq_grid":
      return (
        <Row>
          <Col>
            <MultipleChoice
              index={index}
              name={"rows"}
              length={4}
              btnTitle={"Add Row"}
              title={"Rows"}
            />
          </Col>
          <Col>
            <MultipleChoice
              index={index}
              name={"column"}
              length={4}
              btnTitle={"Add Column"}
              title={"Columns"}
            />
          </Col>
        </Row>
      );
    case "checkbox_grid":
      return (
        <Row>
          <Col>
            <MultipleChoice
              index={index}
              name={"rows"}
              length={4}
              btnTitle={"Add Row"}
              title={"Rows"}
            />
          </Col>
          <Col>
            <MultipleChoice
              index={index}
              name={"column"}
              length={4}
              btnTitle={"Add Column"}
              title={"Columns"}
              icon={"checkbox-dark.svg"}
            />
          </Col>
        </Row>
      );
    case "date":
      return (
        <DateTime
          name={type}
          placeholder={"Month, day, year"}
          control={control}
          icon="date.svg"
        />
      );
    case "time":
      return (
        <DateTime
          name={type}
          placeholder={"Time"}
          control={control}
          icon="time.svg"
        />
      );
    case "linear_scale":
      return <LinearScale />;
    case "drag_drop":
      return (
        <MultipleChoice
          index={index}
          type={type}
          icon={"dropdown.svg"}
          name={type}
          length={4}
          btnTitle={`Add Option or add "other"`}
        />
      );
    default:
      return null;
  }
};

export default QuestionType;
