//** GinieHealth Learner Login,Multi CheckBox Radio Options QuestionType UI component ,used in MutliPrespective component**//

import React from "react";
import { Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./styles.scss";

const MutliCheckBoxRadioAnswerOptions = (props) => {
  const {
    options,
    hookOnBlur,
    hookOnChange,
    name,
    defaultChecked,
    id,
    onChange,  
     disabled
  } = props;

  if (options?.length === 0) {
    return (
      <Spinner animation="border" variant="primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  return (
    <div key={""} className="custom_drop_down_container">
      {options.map((data, index) => {
       
        if(data?.[`option${index + 1}`] !=="" && data?.[`option${index + 1}`] !== null && data?.[`option${index + 1}`] !==" ")
        return (
          <Form.Check type="radio"   id={`${id}-${index}`} className={"test_radio_option_row mx-3"}>
        <Form.Check.Input
          type={"radio"}
          // isValid1
          key={`${id}-${index}`}
         // inline={true}
         autoFocus
          className=" "
          name={name}
          // label={data.value}
          value={data?.[`option${index + 1}`]}
          onBlur={hookOnBlur}
          onChange={(e) => {
            if (hookOnChange) hookOnChange(e);
            if (onChange) onChange(e.target.value);
          }}
         // checked={value == hookValue}
         {...( defaultChecked == data?.[`option${index + 1}`] && { defaultChecked: defaultChecked })}
         // {...data?.[`option${index + 1}`] == defaultChecked &&  defaultChecked: false}
         {...( disabled &&  {disabled :disabled})}
          
        />
        <Form.Check.Label for={`${id}-${index}`} className="">
          {data?.[`option${index + 1}`]}
        </Form.Check.Label>
      </Form.Check>
        );
      })}
    </div>
  );
};

export default MutliCheckBoxRadioAnswerOptions;
