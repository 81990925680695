//** Iguroo ->can Reschedule Session **//

import React, { useCallback, useContext, useEffect, useState } from "react";
import { Modal, Button, Container, Col, Row, Form } from "react-bootstrap";
// Calendar component - https://natscale.github.io/react-calendar/api/props/
import moment from "moment";
import "./styles.scss";
import ProfileInfo from "../../components/ProfileInfo";
import CardRadioBtn from "../../components/CardRadioBtn";
import {
  _getCurrencySymbol,
  _getImage,
} from "../../common/utils/commonFunctions";
import { FormErrorComponent } from "../../components/form.error.component";
import { FormLabelComponent } from "../../components/form.label.component";
import { useForm, Controller } from "react-hook-form";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import {
  decrementLoader,
  incrementLoader,
} from "../../redux-tk/slices/loader.slice";
import { useDispatch, useSelector } from "react-redux";
import { RescheduleaSession } from "../../services/axios/Dasboard";
import { AuthContext } from "../../hooks/auth.context.provider";
import { SubmitButton } from "../submit.button";
import { reschedule } from "../../helpers";
import CustomCalendar from "../CustomCalendar";
import { getAllSessionsInDates } from "../../redux-tk/slices/session.slice";
import useavailableSlots from "../../hooks/use.availableSlots";
import { AppUrlsEnum } from "../../enums";
import { Popup } from "../CustomInput/popup";
require(`../../css/${process.env.REACT_APP_CSS_COMMON}`);

function RescheduleSession(props) {
  const { dashboardTranslations, formElementLabels } = useIgurooTranslations();
  const {
    control,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onTouched",
  });

  React.useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      console.log(field, a, "field, a");
      return !!errors[field] ? field : a;
    }, null);
    console.log(firstError);
    if (firstError) {
      setFocus(firstError);
      const input = document.getElementsByName(firstError)[0];
      console.log(input);
      input?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  }, [errors, setFocus]);
  const sessionsBetweenDates = useSelector((state) =>
    getAllSessionsInDates(state)
  );

  const {
    avail,
    primaryBtn,
    secondaryBtn,
    show,
    onHide,
    rates,
    userId,
    firstName,
    sessionNum,
    requestNum,
  } = props;

  const dispatch = useDispatch();
  let [value, setDateValue] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const authContext = useContext(AuthContext);
  const [activeDate, setActiveDate] = useState(new Date());
  const isDisabled = useCallback(
    (date) => {
      let days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      let availDays = [];
      let everyDay = false;
      avail?.map((avails) =>
        days[date.getDay()] == avails.availability
          ? availDays.push(date)
          : avails.availability == "Everyday"
          ? (everyDay = true)
          : ""
      );
      let act = activeDate;
      const current = moment(new Date()).format("yy-MM-DD");
      // console.log(availDays.includes(date)  && moment(date, "day").isAfter(moment(new Date())) && moment(activeDate, "day").isSame(moment(new Date())))
      // console.log( moment(date, "day").isAfter(moment(new Date())) , moment(activeDate, "day").isSame(moment(new Date(), "day")), moment(activeDate, "date"),moment(new Date(), "day"))
      // console.log(availDays.includes(activeDate)  , moment(date, "day").isAfter(moment(new Date())) ,  moment(act).isSame( moment(current)),act,current)
      if (!availDays.includes(act)) {
        // console.log(activeDate,'activeDate',date,act);
        act = date;
        setActiveDate(date);
      }
      if (moment(date, "day").isBefore(moment(new Date()), "day")) {
        return true;
      } else if (!availDays.includes(date) && !everyDay) {
        return true;
      }
    },
    [avail]
  );

  const { onDateChange, onMonthChange, isHighlighted, availableSlots } =
    useavailableSlots({
      isByUser: false,
      userId: userId,
      rates: rates,
      sessionAvailable: avail,
    });

  const [showPopup, setShowPopup] = useState(false);

  const onSubmit = async (data) => {
    dispatch(incrementLoader());
    setIsLoading(true);
    try {
      const endTime = availableSlots.filter(
        (availableSlot) => availableSlot.startTime == data.slot
      );
      const endSlot = endTime ? endTime[0].endTime : "";
      const startTime = endTime ? endTime[0].startTime : "";
      console.log(props, data, "reschedule");
      const finalData = reschedule(
        authContext.sessionInfo.sub,
        sessionNum,
        requestNum,
        data,
        userId,
        endSlot,
        startTime,
        firstName,
        rates[0]?.time
      );
      console.log(finalData, "finalData");
      const book = await RescheduleaSession(
        authContext.sessionInfo.sub,
        finalData
      );
      console.log(book, "finalData");
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(decrementLoader());
      setShowPopup(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (show) {
      const value = localStorage.getItem("defaultRate");
    }
  }, [show]);

  return (
    <>
      <Popup
        closeOnBackClick={false}
        showPopup={showPopup}
        type="popup"
        text={formElementLabels?.buttonSave}
        modalBody={"Your Slot is Rescheduled"}
        url={AppUrlsEnum.menteeRequests}
      />
      <Modal
        size={"lg"}
        show={show}
        onHide={() => onHide(false)}
        className="book-session"
      >
        <Modal.Header closeButton>
          <Modal.Title>{dashboardTranslations?.bookSession}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body scrollable={true}>
            <Container fluid>
              <ProfileInfo showVerified={true} {...props} hideBtn={true} />
              <Row className="calendar-section">
                <Col>
                  <h4>{dashboardTranslations?.selectDate}</h4>
                  <CustomCalendar
                    onDateChange={onDateChange}
                    sessionDates={sessionsBetweenDates}
                    onDateMonthChange={onMonthChange}
                    isHighlighted={isHighlighted}
                    isDisable={isDisabled}
                    activeDate={activeDate}
                  />
                </Col>
                <Col className="select-times">
                  <h4>{dashboardTranslations?.selectTime}</h4>
                  <div className="time-select">
                    {availableSlots?.map((availableSlot) => (
                      <Controller
                        name={`slot`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name },
                        }) => (
                          <CardRadioBtn
                            id={availableSlot.startTime}
                            title={moment
                              .unix(availableSlot.startTime)
                              .format("hh:mm a")}
                            value={availableSlot.startTime}
                            className="me-3"
                            onBlur={onBlur}
                            onChange={onChange}
                            name={name}
                          />
                        )}
                      />
                    ))}
                    {availableSlots?.length == 0 ? (
                      <>
                        <FormLabelComponent>
                          No Slots Available
                        </FormLabelComponent>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                <FormErrorComponent>
                  {errors.slot && errors.slot?.message}
                </FormErrorComponent>
              </Row>
              <section className="request-form">
                <Form.Group className="mb-3" controlId="formmentorId">
                  <Controller
                    name={`mentorId`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Form.Control
                        type="hidden"
                        value={userId}
                        onChange={onChange}
                        onBlur={onBlur}
                      ></Form.Control>
                    )}
                  />
                </Form.Group>
              </section>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={() => onHide(false)}>
              {secondaryBtn}
            </Button>
            <SubmitButton text={primaryBtn} disabled={isSubmitting} />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default RescheduleSession;
