//** Common Loader Page Slice **//

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
  loaderNumber: 0,
};

export const loaderSlice = createSlice({
  name: "loadingBar",
  initialState,
  reducers: {
    loader: (state, action) => {
      if (action?.payload) {
        state.loaderNumber += 1;
      } else {
        if (state.loaderNumber > 0) {
          state.loaderNumber -= 1;
        }
      }
    },
    incrementLoader: (state, action) => {
      state.loaderNumber += 1;
    },
    decrementLoader: (state, action) => {
      if (state.loaderNumber > 0) {
        state.loaderNumber -= 1;
      }
    },
  },
});

export const { loader, incrementLoader, decrementLoader } = loaderSlice.actions;

export const loaderValue = (state) => state.loader.loader;
export const loaderNumberValue = (state) => state.loader.loaderNumber;

export default loaderSlice.reducer;
