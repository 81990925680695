import { axiosAwsIam,axiosStaticData } from "../axios.service";
import axios from "axios";
import { IGURU_DEV_URL } from "../../config";
import { APPROVE_USERS, APPROVE_MEDIA, MENTOR_PUBLIC_PRIVATE, GET_USER_ALLSTATS, REJECT_MEDIA } from "../../urlConst";
import moment from "moment";
// import { APPROVE_MEDIA,APPROVE_USERS } from "../../urlConst";
import {UrlConst} from "@iguroo/ig-react-common-package";
export const approveUser = async (userId, approverId) => {
  const payload = {
    userId: userId,
    isApproved: "APPROVED",
    approver: {
      id: approverId,
      datetime: moment().format("x"),
      reason: "qualified",
    },
  };
  const res = await axiosStaticData.post(APPROVE_USERS, payload);
  return res.data.response;
};

export const approveVideo = async (userId, approverId) => {
  const payload = {
    userId: userId,
    isProfileVidApproved: true,
    videoApprover: {
      id: approverId,
      datetime: moment().format("x"),
      reason: "text",
    },
  };
  const res = await axiosStaticData.post(APPROVE_MEDIA, payload);
  return res.data.response;
};


export const rejectVideo = async (userId) => {
  const payload = {
    userId: userId,
    isNewProfileVidRejected: true
  };
  const res = await axiosStaticData.post(REJECT_MEDIA, payload);
  return res.data.response;
};


export const approvePic = async (userId, approverId) => {
  const payload = {
    userId: userId,
    isProfilePicApproved: true,
    picApprover: {
      id: approverId,
      datetime: moment().format("x"),
      reason: "text",
    },
  };
  const res = await axiosStaticData.post(APPROVE_MEDIA, payload);
  return res.data.response;
};


export const rejectPic = async (userId) => {
  const payload = {
    userId: userId,
    isNewProfilePicRejected : true
  };
  const res = await axiosStaticData.post(REJECT_MEDIA, payload);
  return res.data.response;
};

export const mentorPublicPrivate = async (data) => {
  const payload = {
    userId: data.mentorName,
    isPublic: data.publicOrPrivate,
  };
  const res = await axiosStaticData.post(MENTOR_PUBLIC_PRIVATE, payload);
  return res.data;
};

export async function getAllStats() {
  try {
    const URL = GET_USER_ALLSTATS;
    
    const response = await axiosStaticData.get(URL);
    console.log('getAllStats console', response)
    return response.data.response;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export const bulkUploadCSV = async (file) => {
  try {
  const res = await axiosStaticData.post(UrlConst.BULK_USERMANAGEMENT,  file);
    console.log("LOG bulkUploadCSV", res);
    return res;
  }
  catch(err){ 
    console.log("err", err);
    throw err;
  }
};