//** Key and Value of GinieHealth Translations -> Check with Value name in en.language.json **//

export function GenieLabsTranslations(t) {
  return {
    key: "genieLabsTranslations",
    value: {
      userEmail: t("genieLabs.userEmail"),
      dueDate: t("genieLabs.dueDate"),
      endDate: t("genieLabs.endDate"),
      availabilitySV: t("genieLabs.availabilitySV"),
      rightNowSV: t("genieLabs.rightNowSV"),
      dueDateSV: t("genieLabs.dueDateSV"),
      unlockDate: t("genieLabs.unlockDate"),
      assignBtn: t("genieLabs.assignBtn"),
      cancelBtn: t("genieLabs.cancelBtn"),
      inviteuser: t("genieLabs.inviteuser"),
      inviteEmail: t("genieLabs.inviteEmail"),
      addBtn: t("genieLabs.addBtn"),
      saveBtn: t("genieLabs.saveBtn"),
      extendAssessment: t("genieLabs.extendAssessment"),
      expireDate: t("genieLabs.expireDate"),
      extendAttempt: t("genieLabs.extendAttempt"),
      supervisor: t("genieLabs.supervisor"),
      yesBtn: t("genieLabs.yesBtn"),
      noBtn: t("genieLabs.noBtn"),
      deleteConfirm: t("genieLabs.deleteConfirm"),
      confrimHead: t("genieLabs.confrimHead"),
      revokeConfirm: t("genieLabs.revokeConfirm"),
    },
  };
}
