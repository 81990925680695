//** PageNotFound,can use this common Component required Field **//

import React from "react";
import { useIgurooTranslations } from "../hooks/use.iguroo.translation";

const PageNotFound = () => {
  const { dashboardTranslations } = useIgurooTranslations();
  return (
    <h3 className="text-center mt-5">{dashboardTranslations?.pageNotFound}</h3>
  );
};

export default PageNotFound;
