//** Iguroo & GinieHealth Common Delete Button Component,can re-use any required field **//

import React from "react";
import { _getImage } from "../../common/utils/commonFunctions";

export function CustomDeleteBtn(props) {
  const {
    className,
    textClassName,
    onClick,
    text,
    svgName,
    noChngIconToText,
    classNameImage,
    addMt = true,
  } = props;

  return (
    <div
      role="button"
      className={className ? className : "mentorDeleteBtn text-center"}
      onClick={onClick}
    >
      {!noChngIconToText && (
        <div className="text-btn-delete d-md-none mb-4">
          <img src={_getImage("trash.svg")} className={classNameImage} />{" "}
        </div>
      )}
      <div
        className={`${
          !noChngIconToText
            ? `d-none d-md-inline-block ${addMt ? "mt-3" : ""}`
            : "d-inline-block"
        }`}
      >
        <img
          src={_getImage(svgName ? svgName : "trash.svg")}
          className={classNameImage}
        />{" "}
        <span
          className={`delete-text ${textClassName} ${text ? "" : "d-none"}`}
        >
          {text}
        </span>
      </div>
    </div>
  );
}
