import React, { useEffect, useState } from "react";
import { Button, Modal, ModalTitle, Row, Col, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { SubmitButton } from "../../components/submit.button";
import { useIgurooTranslations } from "../../hooks";
import { _getImage } from "../../common/utils/commonFunctions";
import { FormErrorComponent } from "../../components/form.error.component";
import AutoCompleteCourseUsers from "./AutoCompleteUsers";
import { CreateCourseUserAssignmentApi } from "@iguroo/ig-react-assessment-package";
import { organizationValue } from "../../redux-tk/slices/general.info.slice";
import {  useSelector } from "react-redux";


const InviteCourseUserPopup = (props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { genieLabsTranslations, formErrors } = useIgurooTranslations();
  const [uIds, setUIds] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [usernames, setUsernames] = useState([]);
  const [userData, setUserData] = useState([]);
  const [currentUsername, setCurrentUsername] = useState("");
  const organization_id = useSelector((state) => organizationValue(state));

  const { show } = props;
  useEffect(() => {
    setShowPopup(show);
  }, [show]);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({

  });
  const submit = (data) => {
    setIsSubmitting(true);
    const assignData = {
      userIds: uIds,
      course_version_id: props?.course_version_id,
      organization_id: organization_id,
      assignment_type: "ASSIGNED",
      endDateType: "LIFETIME"
    }

    CreateCourseUserAssignmentApi(assignData).then((res)=>{
      console.log(res,'create courseuser assignment,');
      setShowPopup(false);
      props?.setShow(false);
      setIsSubmitting(false);
      setUsersList([]);
      setUIds([]);
      setUsernames([]);
      setUserData([]);
      props?.handleGetAssignedUserList(true);

    })
    .catch((e)=> setIsSubmitting(false));
  }
  const handleClosePopup = () => {

    setShowPopup(false);

  };

  const handleUsernameDelete = (index) => {
    const updatedUsernames = [...usernames];
    updatedUsernames.splice(index, 1);
    setUsernames(updatedUsernames);
    const updatedUsernamesData = [...userData];
    updatedUsernamesData.splice(index, 1);
    setUserData(updatedUsernamesData);
    const updatedUsernamesDataID = [...uIds];
    updatedUsernamesDataID.splice(index, 1);
    setUIds(updatedUsernamesDataID);
  };

  const addUsername = (data) => {
    if (data) {
      setUsernames([...usernames, data]);
      setCurrentUsername("");
    }
  };

  const onItemClick = (data) => {
    console.log("LOG onItemClick", data);
    setCurrentUsername(data.firstName + " " + data.lastName);
    console.log("LOG currentUsername", currentUsername);
    addUsername(data.firstName + " " + data.lastName);
    setUserData([...userData, data]);
    setUIds([...uIds, data.userId]);
    clearErrors();
  };
  return (
    <div>
      <Modal
        size={"lg"}
        show={showPopup}
        onHide={() => {
          handleClosePopup();
        }}
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <ModalTitle className="modal-title w-100">
            {genieLabsTranslations?.inviteuser}
          </ModalTitle>
        </Modal.Header>
        <Form onSubmit={handleSubmit(submit)}>
          <Modal.Body className="modal-body">
            <Row>
              <Col className="mt-3" xs={12} md={12} lg={12}>
                <Controller
                  name={`userAdding`}
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name },
                    fieldState: { error },
                  }) => (
                    <div className="user-input">
                      {usernames.map((username, index) => (
                        <div className="user-tag-input" key={index}>
                          <span>{username}</span>
                          <button
                            className="user-delete-btn"
                            onClick={() => handleUsernameDelete(index)}
                          >
                            ×
                          </button>
                        </div>
                      ))}
                      <AutoCompleteCourseUsers
                        data={usersList}
                        onItemClick={(data) => onItemClick(data)}
                        userData={userData}
                      />
                    </div>
                  )}
                />
              </Col>
              <div>
                <FormErrorComponent>
                  {errors.userAdding && errors.userAdding?.message}
                </FormErrorComponent>
              </div>
            </Row>

            <Row className="mt-4 mb-4">
              <Col className="d-flex align-items-end justify-content-start justify-content-sm-end">
                <Button
                  className="cancel-button"
                  onClick={handleClosePopup}
                  style={{ border: "2px solid #EE4C4C" }}
                >
                  {genieLabsTranslations?.cancelBtn}
                </Button>
              </Col>
              <Col className="d-flex align-items-end justify-content-end mt-3 mt-sm-0 justify-content-lg-start">
                <SubmitButton
                  variant="primary"
                  type="submit"
                  text={"Assign Course"}
                  onClick={handleSubmit(submit)}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Form>
      </Modal>
    </div>
  )
}
export default InviteCourseUserPopup;