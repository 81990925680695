//** Iguroo Mentor & Mentee,EmptyCardList Component for Dashboard **//

import { useMemo } from "react";
import { CardTypeEnums } from "../../enums";
import { useIgurooTranslations } from "../../hooks";

export const EmptyCardList = ({ cardType }) => {
  const { dashboardTranslations } = useIgurooTranslations();
  const message = useMemo(() => {
    if (cardType === CardTypeEnums.session) {
      return dashboardTranslations.emptyCalendarSessions;
    } else {
      return "";
    }
  }, [cardType, dashboardTranslations.emptyCalendarSessions]);
  return <p>{message}</p>;
};
