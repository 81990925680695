import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useIgurooTranslations } from "../../hooks";
import { RetakeAssignmentApi } from "@iguroo/ig-react-assessment-package";
import Toaster from "../../components/toaster";
import { toast } from "react-toastify";

function RetakeAssessmentPopup(props) {
  const { genieLabsTranslations, formElementLabels } = useIgurooTranslations();
  const { show, onHide } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
    onHide(true);
    props?.updateList();
  };

  useEffect(() => {
    setIsModalOpen(show);
  }, [show]);

  const onSubmit = () => {
    setIsSubmitting(true);
    const d = RetakeAssignmentApi({
      assessment_version_id: props?.assessment_version_id,
      userId: props?.assessments,
    })
      .then(() => {
        props?.updateList();
        Toaster(toast.success, "Retake Successfully");
        props.LoadPage();
        handleClose();

        setIsSubmitting(false);
      })
      .catch((err) => {
        Toaster(toast.error, "Could not update");
        setIsSubmitting(false);
      });
  };

  return (
    <>
      {isModalOpen && (
        <Modal show={isModalOpen} onHide={handleClose}>
          <Modal.Header closeButton style={{ borderBottom: "none" }}>
            <Modal.Title className="modal-title w-100">
              Retake Assessment
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Do you want this learner to retake this assessment
          </Modal.Body>
          <Modal.Footer
            className="d-flex justify-content-center"
            style={{ borderTop: "none" }}
          >
            <Button
              className="cancel-button"
              onClick={handleClose}
              style={{ border: "2px solid #EE4C4C" }}
            >
              {genieLabsTranslations?.noBtn}
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={onSubmit}
              disabled={isSubmitting}
            >
              {formElementLabels?.yesResetAssessment}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default RetakeAssessmentPopup;
