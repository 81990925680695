//** Common Language Translation Hooks **//

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  MentorOnboardingTranslations,
  FormElementLabelsTranslations,
  FormErrorsTranslations,
  HeadingTranslations,
  MenteeOnboardingTranslations,
  GridColumnDefinitions,
  ProfileLabelsTranslations,
  StaticSelectTranslations,
  HeaderFooterTranslations,
  DashboardTranslations,
  ReportAssessmentTranslations,
  CognitoTranslations,
  AssessmentTranslations,
} from "../res/localization/translations";
import { TestAssessmentTranslations } from "../res/localization/translations/test.assessments";
import { GenieLabsTranslations } from "../res/localization/translations/GenieLabs.translations";
import { AuthorTranslations } from "../res/localization/translations/author.translations";
import { GenieLabsAssessmentTranslations } from "../res/localization/translations/genie.labs.assessment";

/**
 * Custom hook to fethch all translations for the app.
 * @returns {Object} Tanslation data
 */

export function useIgurooTranslations() {
  const { t } = useTranslation();
  const memoTranslations = useMemo(() => {
    const mentorOnboardingLabels = MentorOnboardingTranslations(t);
    const formElementLabels = FormElementLabelsTranslations(t);
    const formErrorsTranslations = FormErrorsTranslations(t);
    const headingTranslations = HeadingTranslations(t);
    const menteeOnboardingLabels = MenteeOnboardingTranslations(t);
    const profileLabels = ProfileLabelsTranslations(t);
    const gridColumnDefinitions = GridColumnDefinitions(t);
    const staticSelectTranslations = StaticSelectTranslations(t);
    const headerFooterTranslations = HeaderFooterTranslations(t);
    const dashboardTranslations = DashboardTranslations(t);
    const reportAssessmentTranslations = ReportAssessmentTranslations(t);
    const testAssessmentTranslations = TestAssessmentTranslations(t);
    const genieLabsAssessmentTranslations = GenieLabsAssessmentTranslations(t);
    const authorTranslations = AuthorTranslations(t);
    const cognitoTranslations = CognitoTranslations(t);
    const genieLabsTranslations = GenieLabsTranslations(t);
    const assessmentsTranslations = AssessmentTranslations(t);
    const trans = {
      mentorOnboardingLabels: mentorOnboardingLabels.value,
      formElementLabels: formElementLabels.value,
      formErrors: formErrorsTranslations.value,
      headingTranslations: headingTranslations.value,
      menteeOnboardingLabels: menteeOnboardingLabels.value,
      profileLabels: profileLabels.value,
      gridColumnDefinitions: gridColumnDefinitions.value,
      staticSelectTranslations: staticSelectTranslations.value,
      headerFooterTranslations: headerFooterTranslations.value,
      dashboardTranslations: dashboardTranslations.value,
      reportAssessmentTranslations: reportAssessmentTranslations.value,
      cognitoTranslations: cognitoTranslations.value,
      testAssessmentTranslations: testAssessmentTranslations.value,
      genieLabsTranslations: genieLabsTranslations.value,
      authorTranslations: authorTranslations.value,
      assessmentsTranslations: assessmentsTranslations.value,
      authorTranslations: authorTranslations.value,
      genieLabsAssessmentTranslations: genieLabsAssessmentTranslations.value,
    };
    return trans;
  }, [t]);
  return memoTranslations;
}
