//** FormError Component,Error Message of Input Field,can use this common Component required Field **//

import React from "react";
import { Form } from "react-bootstrap";
import { _getImage } from "../common/utils/commonFunctions";

export function FormErrorComponent(props) {
  if (!props?.children || props?.children?.length === 0) {
    return <></>;
  }
  return (
    <Form.Text>
      <img src={_getImage("alert-triangle.svg")} />{" "}
      <span className="ig-form-error-text">{props.children}</span>
    </Form.Text>
  );
}
