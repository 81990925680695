import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { _getImage } from "../../common/utils/commonFunctions";
import { Link } from "react-router-dom";
import { GetQuestionsAssessmentApi } from "@iguroo/ig-react-assessment-package";
import { useGetAssessments } from "../../hooks/useGetAssessments";
import { SubmitButton } from "../submit.button";
import { AuthContext } from "../../hooks/auth.context.provider";
import { GetAssessmentAttemptStatusApi } from "@iguroo/ig-react-assessment-package";
import { Heading } from "../heading.component";
import QuizMCQ from "./assessment/QuizMCQ";
import { AttemptIdValue, createAssessmentAttemptAsyncThunk, currentGLQuestionValue } from "../../redux-tk/slices/genieLabs.currentQuestion";
import { getAssessmenById } from "../../services/axios/Assessment/assessment";
import MultiPerspectivequestionCourses from "./assessment/multiPerspectivequestion";


export default function CourseQuiz(props) {
  const {
    
    assessment_version_id,
    submitQuiz,
    setIsLoading,
    link,

  } = props;

  
  const attempid = useSelector((state) => AttemptIdValue(state));
  const dispatch = useDispatch();
  const [assessmentData, setAssessmentData] = useState({});

  const authContext = useContext(AuthContext);
  const {
    assessments,
    setAssessments,
    fetchAssessments,
    fetchInitialAssessments,
    getSearchOnChange,
    getSubmit,
    setType,
    type,
  } = useGetAssessments({
    apiFunction: GetQuestionsAssessmentApi,
    initialQuery: {
      assessment_version_id: assessment_version_id,
    },
  });
  const attemptDetail = {
    userId: authContext.sessionInfo.sub,
    assessment_version_id: assessment_version_id,
    answers: [],
    status: "INPROGRESS",
  };
  const currentQuestion = useSelector((state) => currentGLQuestionValue(state));

  useEffect(() => {
    fetchInitialAssessments({assessment_version_id: assessment_version_id});
   
  }, [assessment_version_id]);
  useEffect(() => {
    (async function () {
      if (assessment_version_id != "") {
        console.log(attempid, assessment_version_id, " attempidattempid");
        const attempt = dispatch(
          createAssessmentAttemptAsyncThunk(attemptDetail)
        );
        console.log(attempid, assessment_version_id,attempt, " attempidattempid");
        setIsLoading(false);
      }
    })();
  }, [assessment_version_id]);
  console.log('asssessmentw, text',assessment_version_id, assessments,currentQuestion);
 
  return (
    <>
      <Card
        className={` p-3  course-url-card my-4 w-80`}
      > 
      
      {assessments?.map((question, index) => {
         console.log(currentQuestion,index,'assessments w')
        if (currentQuestion == index) {
          console.log(currentQuestion,index,'assessments w')
         return <>
         {question.question_type == "MULTI_PERSPECTIVE" ||
              question.question_type == "MULTI_CHOICE" ||
              question.question_type == "STANDARD" ? 
              <MultiPerspectivequestionCourses
              {...question}
        attempid={attempid}
        totalQuestion={assessments?.length}
        assessment_version_id={assessment_version_id}
        assessmentData={assessmentData}
        QuizMCQSubmit={()=>submitQuiz()}
        />

              :
          <QuizMCQ
        {...question}
        attempid={attempid}
        totalQuestion={assessments?.length}
        assessment_version_id={assessment_version_id}
        assessmentData={assessmentData}
        QuizMCQSubmit={()=>submitQuiz()}
        />}
        </>}})}
         
      </Card>
    </>
  )
}
