//** Iguroo ZegoCloud Editor Notes code UI and Component use anywhere**//

import {
  undo,
  redo,
  bold,
  italic,
  underline,
  striked,
  imageRTE,
  justifyCenter,
  justifyLeft,
  link,
  justifyRight,
  unordered,
  ordered,
} from "../../assets/images";

export const config = {
  options: [
    "history",
    "blockType",
    "fontSize",
    "inline",
    "textAlign",
    "list",
    "link",
    "image",
  ],
  history: {
    inDropdown: false,
    className: undefined,
    dropdownClassName: undefined,
    options: ["undo", "redo"],
    undo: { icon: undo, className: undefined },
    redo: { icon: redo, className: undefined },
  },

  blockType: {
    inDropdown: true,
    options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
    className: undefined,
    dropdownClassName: undefined,
  },

  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["bold", "italic", "underline", "strikethrough"],
    bold: {
      icon: bold,
      className: undefined,
    },
    italic: {
      icon: italic,
      className: undefined,
    },
    underline: {
      icon: underline,
      className: undefined,
    },
    strikethrough: {
      icon: striked,
      className: undefined,
    },
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["unordered", "ordered"],
    unordered: {
      icon: unordered,
      className: undefined,
    },
    ordered: {
      icon: ordered,
      className: undefined,
    },
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["left", "center", "right"],
    left: {
      icon: justifyLeft,
      className: undefined,
    },
    center: {
      icon: justifyCenter,
      className: undefined,
    },
    right: {
      icon: justifyRight,
      className: undefined,
    },
  },

  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: "_self",
    options: ["link"],
    link: {
      icon: link,
      className: undefined,
    },
  },
  embedded: {
    // icon: embedded,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    embedCallback: undefined,
    defaultSize: {
      height: "auto",
      width: "auto",
    },
  },
  image: {
    icon: imageRTE,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    urlEnabled: true,
    uploadEnabled: true,
    alignmentEnabled: true,
    uploadCallback: undefined,
    previewImage: false,
    inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
    alt: { present: false, mandatory: false },
    defaultSize: {
      height: "auto",
      width: "auto",
    },
  },
};

export default config;
