//** GinieHealth GetAssessments Hooks **//

import { useCallback, useState } from "react";

export const useGetAssessments = ({
  apiFunction,
  initialQuery,
  originalReturn,
  itemsPerPage,
  currentPage,
  
}) => {
  const [assessments, setAssessments] = useState([]);
  const [type, setType] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);

  const getSubmit = useCallback(
    async (data, apiSearchParams) => {
      setType("");
      console.log(data, apiSearchParams, "submit");
      if (data.search) {
        const seAuth = await fetchAssessments({ apiSearchParams });
        setAssessments(seAuth);
      } else {
        fetchInitialAssessments(initialQuery);
      }
    },
    [setAssessments, setType]
  );

  const getSearchOnChange = useCallback(async (val) => {
    if (!val) {
      fetchInitialAssessments(initialQuery);
    }
  }, []);

  const fetchInitialAssessments = useCallback(async (query) => {
    const auth = await fetchAssessments({
      apiSearchParams: query ? query : initialQuery,
      originalReturn: originalReturn ? originalReturn: false
    });
    console.log("fetched assessments", auth);
    setAssessments(auth);
  }, []);

  const fetchAssessments = useCallback(
    async ({ apiSearchParams, originalReturn = false }) => {
      const apiData = await apiFunction(apiSearchParams);
      if (originalReturn) {
        return apiData;
      }
      const data = apiData.response.data.map((mval) => {
        if (mval["_source"]) return mval["_source"];
        else return mval;
      });
      setTotalRecords(apiData.response.totalRecords);
      return data;
    },
    [setAssessments, setTotalRecords]
  );

  return {
    assessments,
    setAssessments,
    type,
    setType,
    getSearchOnChange,
    getSubmit,
    fetchAssessments,
    fetchInitialAssessments,
    totalRecords,
  };
};
