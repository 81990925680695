//** Iguroo & GinieHealth,CardTitle Component,can re-use & add any TitleText using this component **//

import React from "react";
import { _getImage } from "../../common/utils/commonFunctions";

export function CardTitleComponent(props) {
  const { text, className, svgName } = props;
  return (
    <div className={`ig-card-title ${className}`}>
      {props.children?.length > 0 ? props.children : text}{" "}
      {svgName ? (
        <>
          {" "}
          <img src={_getImage(svgName ? svgName : "")} />
        </>
      ) : (
        ""
      )}
    </div>
  );
}
