import axios from "axios";
import getEnvUrl, {
  IGURU_DEV_URL,
  IGUROO_PROFILE_DATA,
  IGUROO_PROFILE_MENTEE_DATA,
  IGUROO_PROFILE_DATA_EDUCATION,
  IGUROO_PROFILE_DATA_EXPERIENCE,
} from "../config";
import {
  STATIC_ALL_DATA,
  STATIC_CITY,
  STATIC_COUNTRY,
  STATIC_STATE,
} from "../urlConst";
import { requestInterceptor } from "./interceptors/request.interceptors";
import { iamRequestInterceptor } from "./interceptors/iam.request.interceptors";
import { iamResponseInterceptor } from "./interceptors/iam.response.interceptor";
// import {
//   requestInterceptor,
//   responseAuthInterceptor,
// } from './axios.interceptors';
// import {
//   requestInterceptorLog,
//   responseAuthInterceptorLog,
// } from './axios.log.interceptors';

const axiosStaticData = axios.create({
  baseURL: IGURU_DEV_URL,
});

const axiosAwsIam = axios.create({
  baseURL: IGURU_DEV_URL,
  retry: 6
});

const axiosVideoData = axios.create({
  baseURL: 'https://yatpzvlqc3.execute-api.us-east-1.amazonaws.com/sb/video',
});

requestInterceptor(axiosStaticData);
requestInterceptor(axiosVideoData);
iamRequestInterceptor(axiosAwsIam);
iamResponseInterceptor(axiosAwsIam);
//responseAuthInterceptor(axiosLogin);
//requestInterceptorLog(axiosLogin);
//responseAuthInterceptorLog(axiosLogin);

export { axiosStaticData, axiosVideoData, axiosAwsIam };
