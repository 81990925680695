//** Mentor Login --> Can view Mentee Details Aggrid of My Mentees **//
//** AgGrid Data in helpers-->gird.helpers.js File**//

import { useMemo } from "react";
import { getMyMenteeGridDefinitions } from "../helpers/grid.helpers";
import { useIgurooTranslations } from "../hooks/use.iguroo.translation";

export function useMyMenteeDefinitions() {
  const { gridColumnDefinitions } = useIgurooTranslations();
  const headers = useMemo(() => {
    return getMyMenteeGridDefinitions(gridColumnDefinitions);
  }, [gridColumnDefinitions]);

  return headers;
}