//** Category Value Component Field,can re-use this common Component required Field **//

import { Image } from "react-bootstrap";
import "./category.component.scss";

export const CategoryComponent = ({ title, iconSrc, className }) => {
  return (
    <span className={`badge rounded-pill me-2 ${className}`}>
      {iconSrc ? (
        <>
          <Image src={iconSrc} /> <span>{title}</span>
        </>
      ) : (
        <span>{title}</span>
      )}
    </span>
  );
};
