import * as yup from "yup";
export function YesNoQuestionError(formErrors) {
  console.log(formErrors, "formErrors console 111");
  return yup.object({
    answerOption: yup.string().required(formErrors?.AssessmentOptionError),
  });
}

export function RateQuestionError(formErrors) {
  return yup.object({
    answerOption: yup.number().required(formErrors?.AssessmentOptionError),
  });
}

export function TextQuestionError(formErrors) {
  return yup.object({
    textAns: yup
      .string()
      .required(formErrors?.AssessmentOptionError)
      .max(500, formErrors?.maxrAssessmentText),
  });
}

export function DropdownQuestionError(formErrors) {
  return yup.object({
    answerOption: yup
      .array()
      .of(
        yup.object({
          value: yup.string(),
          name: yup.string(),
        })
      )
      .required(formErrors.AssessmentOptionError)
      .typeError(formErrors?.AssessmentOptionError)
      .min(3, formErrors?.minAnswer)
      .max(3, formErrors?.maxAnswer),
  });
}
