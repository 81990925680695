//** Iguroo Mentor & Mentee -> Onboarding & EditAccount,Education Field component like school,Fieldname,EOY,etc. code UI**//

import React from "react";
import { useIgurooTranslations } from "../hooks/use.iguroo.translation";
import { Controller } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import { CustomDeleteBtn } from "./CustomInput/customDeleteBtn";
import { FormErrorComponent } from "./form.error.component";
import { FormLabelComponent } from "./form.label.component";
import { CustomInputField } from "./CustomInput/customInputField";
import { CustomSelect } from "./CustomInput/custom.select";
import {
  MentorOnboardingLabels,
  MenteeOnboardingLabels,
} from "../res/localization";
import { isMentorOrPre } from "../helpers/commons.helpers";
import { useState } from "react";

export function EducationComponent(props) {
  const {
    itemIndex,
    certificateItems,
    fieldOfStudyItems,
    endOfYearItems,
    hookControl,
    removeFunction,
    errors,
    profileType,
  } = props;
  const { mentorOnboardingLabels, formElementLabels, formErrors } =
    useIgurooTranslations();
  const removeRow = () => {
    removeFunction(itemIndex);
  };
  let colDiv = isMentorOrPre(profileType) ? 3 : 4;
  const [value, setInputValue] = useState("");
  const [error, setErrors] = useState(false);
  const handleChange = (event) => {
    if (event.target.value.length <= 254) {
      setInputValue(event.target.value);
      setErrors(false);
    } else {
      setErrors(true);
    }
  };

  return (
    <Row className="mt-2 d-flex">
      <Controller
        name={`edu.${itemIndex}.id`}
        control={hookControl}
        render={({ field: { onChange, onBlur, value } }) => (
          <Form.Control
            type="hidden"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          ></Form.Control>
        )}
      />
      <Col md={colDiv} className="mt-3">
        <label>
          <FormLabelComponent className="formLabel">
            {MentorOnboardingLabels.school}
          </FormLabelComponent>
        </label>
        <Controller
          name={`edu.${itemIndex}.instituteName`}
          control={hookControl}
          render={({ field: { onChange, onBlur, value } }) => (
            <CustomInputField
              placeholder={formElementLabels?.placeHolders?.school}
              hookOnBlur={onBlur}
              hookValue={value}
              hookOnChange={(event) => {
                handleChange(event);
                onChange(event);
              }}
              maxLength={255}
            />
          )}
        />
        {error && (
          <FormErrorComponent>{formErrors.maxdescription}</FormErrorComponent>
        )}
        <FormErrorComponent>
          {errors?.edu?.length > 0 &&
            errors?.edu[itemIndex]?.instituteName &&
            errors?.edu[itemIndex].instituteName?.message}
        </FormErrorComponent>
      </Col>
      {isMentorOrPre(profileType) ? (
        <Col md={colDiv} className="mt-3">
          <label>
            {" "}
            <FormLabelComponent className="formLabel">
              {MentorOnboardingLabels.degree}
            </FormLabelComponent>
          </label>
          <Controller
            control={hookControl}
            render={({ field: { onChange, onBlur, value } }) => (
              <CustomSelect
                opLabel="value"
                opValue="dataId"
                items={certificateItems}
                sendFullValueInHook={true}
                normalSelect={true}
                onReactHookFormBlur={onBlur}
                onReactHookFormChange={onChange}
                value={value}
                placeholder={formElementLabels?.placeHolders?.degree}
              />
            )}
            name={`edu.${itemIndex}.courseName`}
          />
          <FormErrorComponent>
            {errors?.edu?.length > 0 &&
              errors?.edu[itemIndex]?.courseName &&
              errors?.edu[itemIndex].courseName?.message}
          </FormErrorComponent>
        </Col>
      ) : (
        ""
      )}
      <Col md={colDiv} className="mt-3">
        <label>
          {" "}
          <FormLabelComponent className="formLabel">
            {isMentorOrPre(profileType)
              ? MentorOnboardingLabels.field
              : MenteeOnboardingLabels.field}
          </FormLabelComponent>
        </label>
        <Controller
          control={hookControl}
          render={({ field: { onChange, onBlur, value } }) => (
            <CustomSelect
              opLabel="value"
              opValue="dataId"
              items={fieldOfStudyItems}
              sendFullValueInHook={true}
              normalSelect={true}
              onReactHookFormBlur={onBlur}
              onReactHookFormChange={onChange}
              value={value}
              placeholder={
                isMentorOrPre(profileType)
                  ? formElementLabels?.placeHolders?.fieldofStudy
                  : formElementLabels?.placeHolders?.specialization
              }
            />
          )}
          name={`edu.${itemIndex}.specialization`}
        />
        <FormErrorComponent>
          {errors?.edu?.length > 0 &&
            errors?.edu[itemIndex]?.specialization &&
            errors?.edu[itemIndex].specialization?.message}
        </FormErrorComponent>
      </Col>
      <Col md={colDiv} className="mt-3">
        <Row>
          <Col md="10">
            <label>
              {" "}
              <FormLabelComponent className="formLabel">
                {mentorOnboardingLabels.endofYear}
              </FormLabelComponent>
            </label>
            <Controller
              control={hookControl}
              render={({ field: { onChange, onBlur, value } }) => (
                <CustomSelect
                  opLabel="value"
                  opValue="dataId"
                  items={endOfYearItems}
                  sendFullValueInHook={true}
                  normalSelect={true}
                  onReactHookFormBlur={onBlur}
                  onReactHookFormChange={onChange}
                  value={value}
                  placeholder={formElementLabels?.placeHolders?.year}
                />
              )}
              name={`edu.${itemIndex}.year`}
            />
            <FormErrorComponent>
              {errors?.edu?.length > 0 &&
                errors?.edu[itemIndex]?.year &&
                errors?.edu[itemIndex].year?.message}
            </FormErrorComponent>
          </Col>
          <Col
            md="2"
            sm="12"
            className="d-flex align-items-center  justify-content-center mt-4"
          >
            {itemIndex != 0 && <CustomDeleteBtn onClick={() => removeRow()} />}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
