import React from "react";
import MenteeSkillsAndProficiency from "../../mentee/skillProficiency";

const SkillsAndProficiency = () => {
  return (
    <>
      <MenteeSkillsAndProficiency settings={true} />
    </>
  );
};

export default SkillsAndProficiency;
