//** Iguroo Assessment PeriodicTable Component**//

import React from "react";
import { Row, Col } from "react-bootstrap";
import { useIgurooTranslations } from "../../../hooks/use.iguroo.translation";

const PeriodicTable = () => {
  const { reportAssessmentTranslations } = useIgurooTranslations();
  return (
    <div className="periodic_table">
      <Row>
        <Col md={2}>
          <div className="table_chip cyan_chip">
            {reportAssessmentTranslations.periodTab2}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip blue_chip">
            {reportAssessmentTranslations.periodTab3}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip maroon_chip">
            {reportAssessmentTranslations.periodTab9}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip amber_chip">
            {reportAssessmentTranslations.periodTab4}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip dark_blue_chip">
            {reportAssessmentTranslations.periodTab5}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip purple_chip">
            {reportAssessmentTranslations.periodTab1}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab7}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab8}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab14}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab10}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab11}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip">
            {" "}
            {reportAssessmentTranslations.periodTab6}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab12}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab13}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab20}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab15}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab16}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab17}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab18}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab19}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab26}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab21}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab22}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab23}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab24}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab25}
          </div>
        </Col>
        <Col md={2}></Col>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab27}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab28}
          </div>
        </Col>
        <Col md={2}>
          <div className="table_chip">
            {reportAssessmentTranslations.periodTab29}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PeriodicTable;
