//** Iguroo Settings of the Mentee and Mentor EditAccount **//

import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import light_arrow from "../../assets/images/light_arrow.svg";
import thick_arrow from "../../assets/images/thick_arrow.svg";
import Image from "react-bootstrap/Image";
import MainInfo from "./mentor/MainInfo";
import MenteeMainInfo from "./mentee/MainInfo";
import AreaOfEmphasis from "./mentor/AreaOfEmphasis";
import Education from "./mentor/Education";
import MenteeEducation from "./mentee/Education";
import Availability from "./mentor/Availability";
import Rates from "./mentor/Rates";
import SkillsAndProficiency from "./mentee/SkillsAndProficiency";
import WorkExperience from "./mentor/WorkExperience";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Security from "./security/Security";
import Payments from "./payments/Payments";
import { useSelector, useDispatch } from "react-redux";
import { ProfileType } from "../../enums";
import Breadcrum from "../../components/breadcrumb/Breadcrumb";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { Heading } from "../../components/heading.component";
import { isMentorOrPre } from "../../helpers";
import { PopupUnsaved } from "../../components/CustomInput/popupUnsaved";
import { showPopupflag } from "../../redux-tk/slices/showPopupflag.slice";
import { useLocation } from "react-router-dom";
import AssessmentLanguageSelection from "./AssessmentLanguageSelection";

const Settings = () => {
  const dispatch = useDispatch();
  const { dashboardTranslations } = useIgurooTranslations();
  const profile = useSelector((state) => state.user.profile);
  const popupFlag = useSelector((state) => state.showPopupflag.showPopupflag);
  const MentorPageNames = [
    "Basic Info",
    "Areas of Expertise",
    "Work Experience",
    "Education",
    "Availability",
    "Rates",
  ];
  const MenteepageNames = [
    "Basic Info",
    "Education and Work Experience",
    "Skills and Proficiency",
  ];
  const [selectedPage, setSelectedPage] = useState("Basic Info");
  const [categorey, setCategory] = useState("accountsettings");
  const [showPopup2, setShowPopup2] = useState(false);
  const [pageSelected, setPageSelected] = useState("");

  const handleLeave = () => {
    setShowPopup2(false);
    (async function () {
      dispatch(showPopupflag(false));
    })();
    console.log("LOG pageSelected", pageSelected);
    if (pageSelected === "none") {
      setCategory("security");
      setSelectedPage("none");
    } else if (pageSelected === "assessmentLanguage") {
      setCategory("assessmentLanguage");
      setSelectedPage("assessment");
    } else {
      setSelectedPage(pageSelected);
      setCategory("accountsettings");
    }
  };

  const handleStay = () => {
    setShowPopup2(false);
  };

  const setLocalValues = (mainPage, subPage) => {
    localStorage.setItem("mainPage", mainPage);
    localStorage.setItem("subPage", subPage);
  };

  const handlePageChange = (page) => {
    setPageSelected(page);
    if (popupFlag) {
      setShowPopup2(true);
    } else {
      setSelectedPage(page);
      setCategory("accountsettings");
      setLocalValues("accountsettings", "Basic Info");
      setShowPopup2(false);
    }
  };

  const handleSecurity = () => {
    setPageSelected("none");
    if (popupFlag) {
      setShowPopup2(true);
    } else {
      setCategory("security");
      setSelectedPage("none");
      setLocalValues("accountsettings", "Basic Info");
      setShowPopup2(false);
    }
  };

  const handleAssessmentLanguage = () => {
    setPageSelected("assessmentLanguage");
    if (popupFlag) {
      setShowPopup2(true);
    } else {
      setCategory("assessmentLanguage");
      setSelectedPage("assessment");
      setLocalValues("accountsettings", "Basic Info");
      setShowPopup2(false);
    }
  };

  useEffect(() => {
    const mainPage = localStorage.getItem("mainPage");
    const subPage = localStorage.getItem("subPage");
    if (mainPage) {
      setPageSelected(mainPage);
      setCategory(mainPage);
    }
    if (subPage) {
      setSelectedPage(subPage);
    }
  }, []);

  return (
    <Container className="m-0 p-0 manrope-font">
      <Row className="m-0 p-0" style={{ minHeight: "90vh" }}>
        <Col md={3} className="m-0 p-0 pt-4 border-end">
          <p
            className={`fs-15 fw-700 py-2 my-0 ps-3 ${
              categorey === "accountsettings"
                ? "account-settings"
                : "categorey-item"
            }`}
            onClick={() => {
              if (popupFlag && categorey === "security") {
                setShowPopup2(true);
                setPageSelected("Basic Info");
              } else {
                if (categorey === "security") {
                  setSelectedPage("Basic Info");
                  setCategory("accountsettings");
                  setShowPopup2(false);
                }
              }
            }}
          >
            {dashboardTranslations?.myProfile}
          </p>
          {isMentorOrPre(profile?.type) && (
            <ul className="settings-menu-list ps-3">
              {MentorPageNames.map((page) => (
                <li
                  className={`py-2 fs-14 ${
                    selectedPage === page
                      ? "fw-500 dark-text"
                      : "secondary-text"
                  }`}
                  onClick={() => handlePageChange(page)}
                >
                  {selectedPage === page ? (
                    <Image
                      height={15}
                      width={15}
                      src={thick_arrow}
                      className="ms-1 me-2"
                    />
                  ) : (
                    <Image
                      height={10}
                      width={10}
                      src={light_arrow}
                      className="ms-1 me-2"
                    />
                  )}{" "}
                  {page}
                </li>
              ))}
            </ul>
          )}
          {profile.type === ProfileType.mentee && (
            <ul className="settings-menu-list ps-3">
              {MenteepageNames.map((page) => (
                <li
                  className={`py-2 fs-14 ${
                    selectedPage === page
                      ? "fw-500 dark-text"
                      : "secondary-text"
                  }`}
                  onClick={() => handlePageChange(page)}
                >
                  {selectedPage === page ? (
                    <Image
                      height={15}
                      width={15}
                      src={thick_arrow}
                      className="ms-1 me-2"
                    />
                  ) : (
                    <Image
                      height={10}
                      width={10}
                      src={light_arrow}
                      className="ms-1 me-2"
                    />
                  )}{" "}
                  {page}
                </li>
              ))}
            </ul>
          )}
          <p
            className={`m-0 p-0 fs-15 fw-500 px-1 py-2 my-2 ps-3 ${
              categorey === `security` ? `account-settings` : `categorey-item`
            }`}
            onClick={handleSecurity}
          >
            {dashboardTranslations?.security}
          </p>

          <p
            className={`m-0 p-0 fs-15 fw-500 px-1 py-2 my-2 ps-3 ${
              categorey === `assessmentLanguage`
                ? `account-settings`
                : `categorey-item`
            }`}
            onClick={handleAssessmentLanguage}
          >
            {dashboardTranslations?.assessmentLanguage}
          </p>
          {/* <p
            className={`m-0 p-0 fs-15 fw-500 px-1 py-2 my-2 ps-3 ${
              categorey === "payments" ? "account-settings" : `categorey-item`
            }`}
            onClick={() => setCategory("payments")}
          >
            {dashboardTranslations?.payments}
          </p> */}
        </Col>
        <Col md={9} className="m-0 p-0">
          <Breadcrum classes="fs-14 px-3 pt-3" gotIt={handlePageChange} />
          {categorey === "accountsettings" && (
            <Heading className="headingDashboard px-3">
              {dashboardTranslations?.myProfile}
            </Heading>
          )}
          {categorey === "security" && (
            <Heading className="headingDashboard px-3">
              {dashboardTranslations?.security}
            </Heading>
          )}
          {categorey === "assessmentLanguage" && (
            <Heading className="headingDashboard px-3">
              {dashboardTranslations?.assessmentLanguage}
            </Heading>
          )}
          {/* {categorey === "payments" && <Heading className="headingDashboard px-3">{dashboardTranslations?.payments}</Heading>} */}
          {isMentorOrPre(profile?.type) && (
            <>
              {categorey === "accountsettings" &&
                selectedPage === "Basic Info" && <MainInfo />}
              {categorey === "accountsettings" &&
                selectedPage === "Areas of Expertise" && <AreaOfEmphasis />}
              {categorey === "accountsettings" &&
                selectedPage === "Work Experience" && <WorkExperience />}
              {categorey === "accountsettings" &&
                selectedPage === "Education" && <Education />}
              {categorey === "accountsettings" &&
                selectedPage === "Availability" && <Availability />}
              {categorey === "accountsettings" && selectedPage === "Rates" && (
                <Rates />
              )}
            </>
          )}
          {profile.type === ProfileType.mentee && (
            <>
              {categorey === "accountsettings" &&
                selectedPage === "Basic Info" && <MenteeMainInfo />}
              {categorey === "accountsettings" &&
                selectedPage === "Education and Work Experience" && (
                  <MenteeEducation />
                )}
              {categorey === "accountsettings" &&
                selectedPage === "Skills and Proficiency" && (
                  <SkillsAndProficiency />
                )}
            </>
          )}
          {categorey === "security" && <Security />}

          {categorey === "assessmentLanguage" && (
            <AssessmentLanguageSelection />
          )}
          {/* {categorey === "payments" && <Payments />} */}
        </Col>
      </Row>
      <PopupUnsaved
        closeOnBackClick={false}
        showPopup={showPopup2}
        type="popup"
        gotIt={handleLeave}
        gotIt2={handleStay}
      />
    </Container>
  );
};

export default Settings;
