//** GinieHealth Author Assessment Card UI **//

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import AssessmentCard from "../../components/PublisherComponent/AssessmentCard";
import SearchButton from "../../components/PublisherComponent/SearchButton";
import { AppUrlsEnum, ProfileType } from "../../enums";
import { GetAssesmentByAuthorSearchApi } from "@iguroo/ig-react-assessment-package";
import { useGetAssessments } from "../../hooks/useGetAssessments";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../hooks/auth.context.provider";
import { useDispatch } from "react-redux";
import { loader } from "../../redux-tk/slices/loader.slice";
import { FormHeading } from "../../components/form.heading.component";

const author_id = 1;
const AuthorPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [searchData, setSearchData] = useState(null);
  
  const {
    assessments,
    setAssessments,
    fetchAssessments,
    getSearchOnChange,
    getSubmit,
    setType,
    type,
    totalRecords,
  } = useGetAssessments({
    apiFunction: GetAssesmentByAuthorSearchApi,
    initialQuery: {
      author_id,
      from: currentPage,
      limit: itemsPerPage,
    },
    currentPage,
    itemsPerPage,
  });

  const submit = useCallback(
    async (data) => {
      try {
        dispatch(loader(true));
        setSearchData(data?.search);
        await getSubmit(data, {
          author_id,
          search: data.search,
          from: currentPage,
          limit: itemsPerPage,
        });
      } catch (error) {
        console.error("Error submitting data:", error);
        setSearchError(true);
      } finally {
        dispatch(loader(false));
      }
    },
    [setAssessments]
  );
  const searchOnChange = useCallback((val) => {
    getSearchOnChange(val);
  }, []);

  const fetchInitialAssessments = useCallback(
    async (query) => {
      const auth = await fetchAssessments({
        apiSearchParams: {
          ...query,
          ...(searchData && { search: searchData }),
          from: currentPage * itemsPerPage,
          limit: itemsPerPage,
        },
      });
      console.log("fetched assessments", auth);
      setAssessments(auth);
    },
    [currentPage, itemsPerPage]
  );

  useEffect(() => {
    if (type) {
      const filteredData = assessments.filter(
        (x) => x.status.toLowerCase() === type.toLowerCase()
      );
      setAssessments([...filteredData]);
    }
  }, [type, assessments]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        dispatch(loader(true));
        setLoading(true);
        await fetchInitialAssessments({
          author_id,
          from: currentPage,
          limit: itemsPerPage,
        });
        dispatch(loader(false));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching initial assessments:", error);
        setSearchError(true);
        setLoading(false);
      }
    };
    fetchInitialData();
  }, [currentPage, itemsPerPage]);
  
  const authContext = useContext(AuthContext);

  const userType = useMemo(() => {
    return authContext?.sessionInfo?.role?.toUpperCase();
  }, [authContext.authStatus]);
  const handleClicked = (assessment_id, version) => {
    if (userType === ProfileType.admin) {
      navigate(
        `${AppUrlsEnum.authorAssessmentDetailPage}?versionId=${version}&assessmentId=${assessment_id}`
      );
    } else if (userType === ProfileType.author) {
      navigate(
        `${AppUrlsEnum.authorUserAssessmentDetailPage}?versionId=${version}&assessmentId=${assessment_id}`
      );
    }
  };

  return (
    <div className="m-2 p-2 publisherContainer">
      <Row className="d-flex align-items-end ">
        <Col></Col>
        <Col sm={12} md={6} lg={5} xl={4} xxl={4} className="">
          <SearchButton submit={submit} handleOnChange={searchOnChange} />
        </Col>
      </Row>
      <hr />
      {assessments.length > 0 ? (
        <AssessmentCard
          data={assessments}
          itemsPerPage={itemsPerPage}
          totalRecords={totalRecords}
          setCurrentPage={setCurrentPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          onClick={handleClicked}
        />
      ) : loading ? (
        <FormHeading className="text-center mt-4">Loading...</FormHeading>
      ) : (
        <FormHeading className="text-center mt-4">No Data Found</FormHeading>
      )}
    </div>
  );
};

export default AuthorPage;