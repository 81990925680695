//** Iguroo SWOT Assessment ->Swot Example SinglePopup,Currently not using this code,will be implemented in future **//

import { SwotExamplePopupSingle } from "./SwotExamplePopupSingle";

export const SwotExamplePopupListView = ({
  swotType,
  swotPlanType,
  swotName,
  swotField,
  isPlan,
}) => {
  return (
    <>
      <div className="my-tab-example-content">
        {swotField?.map((field, index) => {
          //console.log("fil'", field);
          return (
            <SwotExamplePopupSingle
              key={field.id}
              field={field}
              itemIndex={index}
              swotType={swotType}
              swotPlanType={swotPlanType}
              swotName={swotName}
              //swotFieldName={swotFieldName}
              isPlan={isPlan}
            />
          );
        })}
      </div>
    </>
  );
};
