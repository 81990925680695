//** Key and Value of Author Translations -> Check with Value name in en.language.json **//

export function AuthorTranslations(t) {
  return {
    key: "authorTranslations",
    value: {
      author: t("authorTranslations.author"),
    },
  };
}
