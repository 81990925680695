//** Iguroo SelfDiscovery Quick and Deatiled Assessment Report,Some more Components and Pages Used in this UI code **//

import React from "react";
import "./styles.scss";
import { Row, Image, Col } from "react-bootstrap";
import InfoCard from "./components/Card";
import Rating from "./components/Rating";
import { AppUrlsEnum, Languages, ProfileType } from "../../enums";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import InputCard from "./components/Input.card";
import ImageCard from "./components/Image.card";
import Progress from "./components/Progress";
import Page_1 from "./components/Page_1";
import Page_2 from "./components/Page_2";
import Page_30 from "./components/Page_30";
import PeriodicTable from "./components/PeriodicTable";
import SpeedoMeter from "./components/SpeedoMeter";
import { SubmitButton } from "../../components/submit.button";
import pie_chart from "../../assets/images/pie_chart.svg";
import aptitude from "../../assets/images/aptitude.svg";
import interset from "../../assets/images/interset.svg";
import personality from "../../assets/images/personality.svg";
import value from "../../assets/images/value.svg";
import studyValue from "../../assets/images/studyValue.svg";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getAssessmentReport } from "../../services/axios/Assessment/assessment";
import { useState } from "react";
import PageContainer from "./components/PageContainer";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { _getImage } from "../../common/utils/commonFunctions";
import { useTranslation } from "react-i18next";
import { GetRecommendation } from "../../services/axios/Recommendation";

const examQuestion = {
  1: {
    raisec: {
      r: 10,
      a: 10,
      i: 10,
      s: 10,
      e: 10,
      c: 10,
    },
    stepc: {
      s: 5,
      t: 5,
      e: 5,
      p: 5,
      c: 5,
    },
  },
  2: {
    raisec: {
      r: 2,
      a: 2,
      i: 1,
      s: 3,
      e: 2,
      c: 2,
    },
    stepc: {
      s: 3,
      t: 3,
      e: 3,
      p: 3,
      c: 3,
    },
  },
};

const AssessmentReports = ({ userId, attemptId, languageFlag, userType }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { reportAssessmentTranslations } = useIgurooTranslations();
  const user = useSelector((state) => state.user.profile);
  const location = useLocation();
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [language, setLanguage] = useState(false);
  const [recommendations, setRecommendations] = useState(null);
  const [data, setData] = useState({});
  const [menRec, setMenRec] = useState();

  useEffect(() => {
    if (
      user.type === ProfileType.mentee ||
      (userId && userType != ProfileType.mentor)
    ) {
      setMenRec(true);
    } else {
      setMenRec(false);
    }
  }, [user.type, userId, userType]);

  const [intrests, setIntrests] = useState([
    {
      A: 0,
      type: "A",
      value: reportAssessmentTranslations.interestDesc4,
      img: _getImage("Artistic.png"),
    },
    {
      C: 0,
      type: "C",
      value: reportAssessmentTranslations.interestDesc7,
      img: _getImage("conventional.png"),
    },
    {
      E: 0,
      type: "E",
      value: reportAssessmentTranslations.interestDesc6,
      img: _getImage("enterprising.png"),
    },
    {
      I: 0,
      type: "I",
      value: reportAssessmentTranslations.interestDesc3,
      img: _getImage("investigative.png"),
    },
    {
      R: 0,
      type: "R",
      value: reportAssessmentTranslations.interestDesc2,
      img: _getImage("Realistic.png"),
    },
    {
      S: 0,
      type: "S",
      value: reportAssessmentTranslations.interestDesc5,
      img: _getImage("social.png"),
    },
  ]);

  const [personalites, setPersonalities] = useState([
    {
      L1: 0,
      type: "L1",
      value: reportAssessmentTranslations.l1Heading,
      img: _getImage("L1.png"),
    },
    {
      L2: 0,
      type: "L2",
      value: reportAssessmentTranslations.l2Heading,
      img: _getImage("L2.png"),
    },
    {
      R1: 0,
      type: "R1",
      value: reportAssessmentTranslations.r1Heading,
      img: _getImage("R1.png"),
    },
    {
      R2: 0,
      type: "R2",
      value: reportAssessmentTranslations.r2Heading,
      img: _getImage("R2.png"),
    },
  ]);

  const [aptitudes, setAptitude] = useState([
    {
      C: 0,
      type: "C",
      value: reportAssessmentTranslations.speakingHeading,
      img: _getImage("Communications.png"),
    },
    {
      E: 0,
      type: "E",
      value: reportAssessmentTranslations.enterPriHeading,
      img: _getImage("Enterprising_2.png"),
    },
    {
      P: 0,
      type: "P",
      value: reportAssessmentTranslations.probSolveHeading,
      img: _getImage("ProblemSolving.png"),
    },
    {
      S: 0,
      type: "S",
      value: reportAssessmentTranslations.selfMgHeading,
      img: _getImage("SelfManagement.png"),
    },
    {
      T: 0,
      type: "T",
      value: reportAssessmentTranslations.teamwrkHeading,
      img: _getImage("teamWork.png"),
    },
  ]);

  const fetchReport = (userId, attemptId) => {
    getAssessmentReport(userId, attemptId)
      .then((res) => {
        if (res) {
          setData(res?.Items[0]);
          const int1 = res?.Items[0]?.details?.raisecScore;
          const int2 = [];
          for (let t in int1) {
            if (Number.isInteger(int1[t])) {
              const res = intrests.filter((value) => value.type === t);
              if (Object.values(res[0]).includes("A")) {
                res[0].A = int1[t];
                res[0].order = int1[t];
              } else if (Object.values(res[0]).includes("C")) {
                res[0].C = int1[t];
                res[0].order = int1[t];
              } else if (Object.values(res[0]).includes("E")) {
                res[0].E = int1[t];
                res[0].order = int1[t];
              } else if (Object.values(res[0]).includes("I")) {
                res[0].I = int1[t];
                res[0].order = int1[t];
              } else if (Object.values(res[0]).includes("R")) {
                res[0].R = int1[t];
                res[0].order = int1[t];
              } else if (Object.values(res[0]).includes("S")) {
                res[0].S = int1[t];
                res[0].order = int1[t];
              }
              int2.push(res[0]);
            }
          }
          const int3 = int2.sort((a, b) => b.order - a.order);
          const pers1 = res?.Items[0]?.details?.brainProfilingScore;
          const pers2 = [];
          for (let t in pers1) {
            if (Number.isInteger(pers1[t])) {
              const res = personalites.filter((value) => value.type === t);
              if (Object.values(res[0]).includes("L1")) {
                res[0].L1 = pers1[t];
                res[0].order = pers1[t];
              } else if (Object.values(res[0]).includes("L2")) {
                res[0].L2 = pers1[t];
                res[0].order = pers1[t];
              } else if (Object.values(res[0]).includes("R1")) {
                res[0].R1 = pers1[t];
                res[0].order = pers1[t];
              } else if (Object.values(res[0]).includes("R2")) {
                res[0].R2 = pers1[t];
                res[0].order = pers1[t];
              }
              pers2.push(res[0]);
            }
          }
          const pers3 = pers2.sort((a, b) => b.order - a.order);
          setPersonalities(pers3.slice(0, 3));
          setIntrests(int3.slice(0, 3));
          const apt1 = res?.Items[0]?.details?.aptitudeScore;
          const apt2 = [];
          for (let t in apt1) {
            if (Number.isInteger(apt1[t])) {
              const res = aptitudes.filter((value) => value.type === t);
              if (Object.values(res[0]).includes("C")) {
                res[0].C = apt1[t];
                res[0].order = apt1[t];
              } else if (Object.values(res[0]).includes("E")) {
                res[0].E = apt1[t];
                res[0].order = apt1[t];
              } else if (Object.values(res[0]).includes("P")) {
                res[0].P = apt1[t];
                res[0].order = apt1[t];
              } else if (Object.values(res[0]).includes("S")) {
                res[0].S = apt1[t];
                res[0].order = apt1[t];
              } else if (Object.values(res[0]).includes("T")) {
                res[0].T = apt1[t];
                res[0].order = apt1[t];
              }
              apt2.push(res[0]);
            }
          }
          const apt3 = apt2.sort((a, b) => b.order - a.order);
          setAptitude(apt3.slice(0, 3));
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const attemptId2 = location.state?.attemptId;
    console.log(attemptId, userId, "attemptid2Passed");
    if (attemptId2 && user.userId) {
      fetchReport(user.userId, attemptId2);
    } else if (userId && attemptId) {
      fetchReport(userId, attemptId);
    }
  }, [user.userId, location.state?.attemptId]);

  const getPercentage = (value, totalValue) => {
    const res = Math.round((value / totalValue) * 100);
    if (res) {
      return res;
    } else {
      return 0;
    }
  };

  const handleNavigation = () => {
    navigate(AppUrlsEnum.findMentor);
  };

  useEffect(() => {
    const lang = localStorage.getItem("assessmentlanguage");
    if (languageFlag) {
      setSelectedLanguage(Languages.english);
    } else {
      setSelectedLanguage(lang);
    }
    if (lang?.length > 1 && !languageFlag) {
      console.log(lang, userId, "attemptid2Passed");
      i18n.changeLanguage(lang.toLocaleLowerCase());
      setLanguage(true);
    } else {
      i18n.changeLanguage(Languages.english);
      setLanguage(false);
    }
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem("assessmentlanguage");
    if (languageFlag) {
      setSelectedLanguage(Languages.english);
    } else {
      setSelectedLanguage(lang);
    }
    if (lang?.length > 1 && !languageFlag) {
      i18n.changeLanguage(lang.toLocaleLowerCase());
      setLanguage(true);
    } else {
      i18n.changeLanguage(Languages.english);
      setLanguage(false);
    }
    const intData = intrests.map((int) => {
      return { ...int, value: int.value };
    });
    setIntrests(intData);
    if (user.type === ProfileType.mentee) {
      GetRecommendation(user?.userId)
        .then((res) => {
          if (res[0]?.recommendations[0]) {
            setRecommendations(res[0]?.recommendations[0]);
          }
        })
        .catch((err) => console.log(err));
    }
    if (userId) {
      GetRecommendation(userId)
        .then((res) => {
          if (res[0]?.recommendations[0]) {
            setRecommendations(res[0]?.recommendations[0]);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [user, data]);

  useEffect(() => {
    if (user.type === ProfileType.mentee) {
      GetRecommendation(user?.userId)
        .then((res) => {
          if (res[0]?.recommendations[0]) {
            setRecommendations(res[0]?.recommendations[0]);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  return (
    <div className="page_container">
      <SubmitButton
        onClick={() => {
          if (user.type === ProfileType.mentee)
            navigate(AppUrlsEnum.selfDiscovery);
          else if (user.type === ProfileType.mentor) {
            navigate(AppUrlsEnum.selfDiscoveryMentor);
          }
        }}
        type="submit"
        className="d-inline back-button"
        text="Back to App"
      />
      <PageContainer>
        <Page_1 name={data?.name} examDate={data?.examAttemptedDate} />
      </PageContainer>
      <PageContainer className="py-3 my-3 main_bg">
        <h2 className="disclaimer-head p-2">
          {reportAssessmentTranslations.disclaimerHeading}
        </h2>
        <p className="complete-assessment1 m-2">
          {reportAssessmentTranslations.disclaimerDesc}
        </p>
        <h1 className="framework-desc m-2 p-2 mt-4">
          {reportAssessmentTranslations.reportFramework}
        </h1>
        <p className="complete-assessment m-2">
          {reportAssessmentTranslations.completeAssessment}
        </p>
        <p className="complete-assessment1 m-2">
          {reportAssessmentTranslations.completeAssessment1}
        </p>
        <p className="complete-assessment1 m-2">
          {reportAssessmentTranslations.completeAssessment2}
        </p>
        <p className="complete-assessment1 m-2">
          {reportAssessmentTranslations.completeAssessment3}
        </p>
        <p className="complete-assessment1 m-2">
          {reportAssessmentTranslations.completeAssessment4}
        </p>
        <Row style={{ textAlign: "center" }} className="mt-3">
          <Col>
            <ImageCard imageSrc={interset} width={100} />
          </Col>
          <Col>
            <ImageCard imageSrc={aptitude} width={200} />
          </Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col className="mt-5">
            <ImageCard imageSrc={personality} width={125} />
          </Col>
          <Col>
            <ImageCard imageSrc={studyValue} width={275} />
          </Col>
          <Col className="mt-5">
            <ImageCard imageSrc={value} width={100} />
          </Col>
        </Row>
      </PageContainer>
      <PageContainer className="pt-3 my-2 main_bg">
        <Page_2 condition={true} navigateTo={handleNavigation} />
      </PageContainer>
      <PageContainer className="py-3 my-3 blue_bg">
        <h1 className="assess-summary m-2 p-2 mt-4">
          {reportAssessmentTranslations.assessSummary}
        </h1>
        {language ? (
          <>
            {" "}
            <Row className="summaryAssess-bg" style={{ textAlign: "center" }}>
              <p className="summary-heading">
                {reportAssessmentTranslations.assess1Summary}
              </p>
              {intrests.map((value) => (
                <Col>
                  <ImageCard
                    textClassName="image-vw"
                    imageSrc={value.img}
                    belowImgText={value.value}
                    width={200}
                    height={200}
                  />
                </Col>
              ))}
            </Row>
            <Row className="mt-3"></Row>
            <Row className="summaryAssess-bg" style={{ textAlign: "center" }}>
              <p className="summary-heading">
                {reportAssessmentTranslations.assess2Summary}
              </p>
              {personalites.map((value) => (
                <Col>
                  <ImageCard
                    textClassName="image-vw"
                    imageSrc={value.img}
                    belowImgText={value.value}
                    width={200}
                    height={200}
                  />
                </Col>
              ))}
            </Row>
            <Row className="mt-3"></Row>
            <Row className="summaryAssess-bg" style={{ textAlign: "center" }}>
              <p className="summary-heading">
                {reportAssessmentTranslations.assess3Summary}
              </p>
              {aptitudes.map((value) => (
                <Col>
                  <ImageCard
                    textClassName="image-vw"
                    imageSrc={value.img}
                    belowImgText={value.value}
                    width={200}
                    height={200}
                  />
                </Col>
              ))}
            </Row>
          </>
        ) : (
          <>
            <Row className="summaryAssess-bg" style={{ textAlign: "center" }}>
              <p className="summary-heading">
                {reportAssessmentTranslations.assess1Summary}
              </p>
              {intrests.map((value) => (
                <Col>
                  <ImageCard
                    textClassName="image-vw"
                    imageSrc={value.img}
                    belowImgText={value.value}
                    width={200}
                    height={200}
                  />
                </Col>
              ))}
            </Row>
            <Row className="mt-3"></Row>
            <Row className="summaryAssess-bg" style={{ textAlign: "center" }}>
              <p className="summary-heading">
                {reportAssessmentTranslations.assess2Summary}
              </p>
              {personalites.map((value) => (
                <Col>
                  <ImageCard
                    textClassName="image-vw"
                    imageSrc={value.img}
                    belowImgText={value.value}
                    width={200}
                    height={200}
                  />
                </Col>
              ))}
            </Row>
            <Row className="mt-3"></Row>
            <Row className="summaryAssess-bg" style={{ textAlign: "center" }}>
              <p className="summary-heading">
                {reportAssessmentTranslations.assess3Summary}
              </p>
              {aptitudes.map((value) => (
                <Col>
                  <ImageCard
                    textClassName="image-vw"
                    imageSrc={value.img}
                    belowImgText={value.value}
                    width={200}
                    height={200}
                  />
                </Col>
              ))}
            </Row>
          </>
        )}
        <Row className="mt-3"></Row>
      </PageContainer>
      <PageContainer className="py-3 my-3 main_bg">
        <h2 className="your-score">{reportAssessmentTranslations.yourScore}</h2>
        <h4 className="ps-2 blue_text">
          {reportAssessmentTranslations.assessmentScorePersonality}
        </h4>
        <Progress
          className="blue_progress"
          text={reportAssessmentTranslations.l1Heading}
          value={getPercentage(
            data?.details?.brainProfilingScore?.L1,
            data?.details?.brainProfilingScore?.L1 +
              data?.details?.brainProfilingScore?.L2 +
              data?.details?.brainProfilingScore?.R1 +
              data?.details?.brainProfilingScore?.R2
          )}
        />
        <Progress
          className="blue_progress"
          text={reportAssessmentTranslations.l2Heading}
          value={getPercentage(
            data?.details?.brainProfilingScore?.L2,
            data?.details?.brainProfilingScore?.L1 +
              data?.details?.brainProfilingScore?.L2 +
              data?.details?.brainProfilingScore?.R1 +
              data?.details?.brainProfilingScore?.R2
          )}
        />
        <Progress
          className="blue_progress"
          text={reportAssessmentTranslations.r1Heading}
          value={getPercentage(
            data?.details?.brainProfilingScore?.R1,
            data?.details?.brainProfilingScore?.L1 +
              data?.details?.brainProfilingScore?.L2 +
              data?.details?.brainProfilingScore?.R1 +
              data?.details?.brainProfilingScore?.R2
          )}
        />
        <Progress
          className="blue_progress"
          text={reportAssessmentTranslations.r2Heading}
          value={getPercentage(
            data?.details?.brainProfilingScore?.R2,
            data?.details?.brainProfilingScore?.L1 +
              data?.details?.brainProfilingScore?.L2 +
              data?.details?.brainProfilingScore?.R1 +
              data?.details?.brainProfilingScore?.R2
          )}
        />
        <h4 className="ps-2 pink_text">
          {reportAssessmentTranslations.assessmentScoreInterests}
        </h4>
        <Progress
          className="purple_progress"
          text={reportAssessmentTranslations.realisticDesc}
          value={getPercentage(
            data?.details?.raisecScore?.R,
            examQuestion[data?.examId]?.raisec?.r
          )}
        />
        <Progress
          className="purple_progress"
          text={reportAssessmentTranslations.investigateDesc}
          value={getPercentage(
            data?.details?.raisecScore?.I,
            examQuestion[data?.examId]?.raisec?.i
          )}
        />
        <Progress
          className="purple_progress"
          text={reportAssessmentTranslations.atristicDesc}
          value={getPercentage(
            data?.details?.raisecScore?.A,
            examQuestion[data?.examId]?.raisec?.a
          )}
        />
        <Progress
          className="purple_progress"
          text={reportAssessmentTranslations.socialDesc}
          value={getPercentage(
            data?.details?.raisecScore?.S,
            examQuestion[data?.examId]?.raisec?.s
          )}
        />
        <Progress
          className="purple_progress"
          text={reportAssessmentTranslations.enterpriDesc}
          value={getPercentage(
            data?.details?.raisecScore?.E,
            examQuestion[data?.examId]?.raisec?.e
          )}
        />
        <Progress
          className="purple_progress"
          text={reportAssessmentTranslations.conventionDesc}
          value={getPercentage(
            data?.details?.raisecScore?.C,
            examQuestion[data?.examId]?.raisec?.c
          )}
        />
        <h4 className="ps-2 orange_text">
          {reportAssessmentTranslations.assessmentScoreAptitude}
        </h4>
        <Progress
          className="orange_progress"
          text={reportAssessmentTranslations.selfMgHeading}
          value={getPercentage(
            data?.details?.aptitudeScore?.S,
            examQuestion[data?.examId]?.stepc?.s * 5
          )}
        />
        <Progress
          className="orange_progress"
          text={reportAssessmentTranslations.teamwrkHeading}
          value={getPercentage(
            data?.details?.aptitudeScore?.T,
            examQuestion[data?.examId]?.stepc?.t * 5
          )}
        />
        <Progress
          className="orange_progress"
          text={reportAssessmentTranslations.enterPriHeading}
          value={getPercentage(
            data?.details?.aptitudeScore?.E,
            examQuestion[data?.examId]?.stepc?.e * 5
          )}
        />
        <Progress
          className="orange_progress"
          text={reportAssessmentTranslations.probSolveHeading}
          value={getPercentage(
            data?.details?.aptitudeScore?.P,
            examQuestion[data?.examId]?.stepc?.p * 5
          )}
        />
        <Progress
          className="orange_progress"
          text={reportAssessmentTranslations.speakingHeading}
          value={getPercentage(
            data?.details?.aptitudeScore?.C,
            examQuestion[data?.examId]?.stepc?.c * 5
          )}
        />
      </PageContainer>
      <PageContainer className="py-3 my-3 main_bg">
        <h2 className="ps-2 chiffon_text">
          {reportAssessmentTranslations.assessmentScoreEmployabilityQuotient}
        </h2>
        {reportAssessmentTranslations.bad &&
          reportAssessmentTranslations.good &&
          reportAssessmentTranslations.excellent && (
            <div className="d-flex justify-content-center">
              <SpeedoMeter
                value={data?.details?.aptitudeScore?.employabilityQuotient}
                bad={reportAssessmentTranslations.bad}
                good={reportAssessmentTranslations.good}
                excellent={reportAssessmentTranslations.excellent}
              />
            </div>
          )}
        {data?.details?.sectionD[0]?.answer?.length > 0 ? (
          <>
            <h4 className="char-text ps-2">
              {reportAssessmentTranslations.topCharecterStrengths}
            </h4>
            {data?.details?.sectionD[0]?.answer?.map((val) =>
              val !== "" ? (
                <>
                  {console.log(
                    val,
                    selectedLanguage,
                    val[selectedLanguage?.toLowerCase()]?.name,
                    "val----"
                  )}
                  <InputCard
                    className="strength-card m-2 p-2 mt-3"
                    contentText={
                      selectedLanguage
                        ? val[selectedLanguage?.toLowerCase()]?.name
                        : val?.english?.name
                    }
                  />
                </>
              ) : (
                <></>
              )
            )}
          </>
        ) : (
          <></>
        )}
        {data?.details?.sectionD[1]?.answer?.length > 0 ? (
          <>
            <h4 className="values-text ps-2 mt-3">
              {reportAssessmentTranslations.topValuesInLife}{" "}
            </h4>
            {data?.details?.sectionD[1]?.answer?.map((val) =>
              val !== "" ? (
                <InputCard
                  className="value-card m-2 mt-3"
                  contentText={
                    selectedLanguage
                      ? val[selectedLanguage?.toLowerCase()]?.name
                      : val?.english?.name
                  }
                />
              ) : (
                <></>
              )
            )}
          </>
        ) : (
          <></>
        )}
      </PageContainer>
      {data?.details?.openEnded?.length > 0 ? (
        <PageContainer className="py-3 my-3 sky_bg">
          <h2 className="openques-head mt-4 text-start mb-3 ps-2">
            {reportAssessmentTranslations.openquesHeading}
          </h2>
          <p className="complete-assessment1 m-2">
            {reportAssessmentTranslations.openquesDesc}
          </p>
          <p className="complete-assessment1 m-2">
            {reportAssessmentTranslations.openquesDesc1}
          </p>
          {data?.details?.openEnded?.length > 0 ? (
            <>
              {data?.details?.openEnded?.map((val) => (
                <InfoCard
                  className="info_card info_card_dark_cyan m-2 mt-3"
                  headerText={
                    localStorage.getItem("assessmentlanguage")
                      ? val?.question[selectedLanguage]?.toLowerCase()
                      : val?.question?.english
                  }
                  bodyText={val?.answer}
                />
              ))}
            </>
          ) : (
            <></>
          )}
        </PageContainer>
      ) : (
        <></>
      )}
      {menRec && (
        <>
          <PageContainer className="py-3 my-3 maroon_bg">
            <h2 className="topstream-head mt-4">
              {reportAssessmentTranslations.topstreamHeading}
            </h2>
            {recommendations ? (
              <>
                <div>
                  <p className="complete-assessment m-2">
                    {reportAssessmentTranslations.topstreamDesc}
                  </p>
                  <InfoCard
                    className="info_card info_card_maroon m-2 mt-3"
                    headerText={reportAssessmentTranslations.recommendHeader}
                    bodyText={recommendations?.stream_1}
                  />
                </div>
                <div>
                  <InfoCard
                    className="info_card info_card_maroon m-2 mt-3"
                    headerText={reportAssessmentTranslations.recommend1Header}
                    bodyText={recommendations?.stream_2}
                  />
                </div>
                <div>
                  <InfoCard
                    className="info_card info_card_maroon m-2 mt-3"
                    headerText={reportAssessmentTranslations.recommend2Header}
                    bodyText={recommendations?.stream_3}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="top-streams">
                  <p className="complete-assessment m-2">
                    {reportAssessmentTranslations.topstreamDesc}
                  </p>
                  <InfoCard
                    className="info_card info_card_maroon m-2 mt-3"
                    headerText={reportAssessmentTranslations.recommendHeader}
                    bodyText={reportAssessmentTranslations.recommendDesc}
                  />
                </div>
                <div className="top-streams">
                  <InfoCard
                    className="info_card info_card_maroon m-2 mt-3"
                    headerText={reportAssessmentTranslations.recommend1Header}
                    bodyText={reportAssessmentTranslations.recommend1Desc}
                  />
                  <InfoCard
                    className="info_card info_card_maroon m-2 mt-3"
                    headerText={reportAssessmentTranslations.recommenint2Header}
                    bodyText={reportAssessmentTranslations.recommenint1Desc}
                  />
                </div>
              </>
            )}
            <div className="d-flex justify-content-center pt-5 session-button">
              <SubmitButton
                onClick={() => navigate(AppUrlsEnum.findMentor)}
                type="submit"
                className="d-inline mt-5"
                text={
                  selectedLanguage === Languages.english
                    ? "Schedule a session with our career counsellor to understand the recommendations"
                    : reportAssessmentTranslations.scheduleSession
                }
              />
            </div>
          </PageContainer>
          <PageContainer className="py-3 my-3 maroon_bg">
            <h2 className="career-head mt-4">
              {reportAssessmentTranslations.topCareerHeading}
            </h2>
            {recommendations ? (
              <>
                <div>
                  <p className="complete-assessment m-2">
                    {reportAssessmentTranslations.topstreamDesc}
                  </p>
                  <InfoCard
                    className="info_card info_card_brown m-2 mt-3"
                    headerText={reportAssessmentTranslations.recommendHeader}
                    bodyText={recommendations?.career_1}
                  />
                </div>
                <div>
                  <InfoCard
                    className="info_card info_card_brown m-2 mt-3"
                    headerText={reportAssessmentTranslations.recommend1Header}
                    bodyText={recommendations?.career_2}
                  />
                  <InfoCard
                    className="info_card info_card_brown m-2 mt-3"
                    headerText={reportAssessmentTranslations.recommend2Header}
                    bodyText={recommendations?.career_3}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="top-streams">
                  <p className="complete-assessment m-2">
                    {reportAssessmentTranslations.topstreamDesc}
                  </p>
                  <InfoCard
                    className="info_card info_card_brown m-2 mt-3"
                    headerText={reportAssessmentTranslations.recommendHeader}
                    bodyText={reportAssessmentTranslations.recommendDesc}
                  />
                </div>
                <div className="top-streams">
                  <InfoCard
                    className="info_card info_card_brown m-2 mt-3"
                    headerText={reportAssessmentTranslations.recommend1Header}
                    bodyText={reportAssessmentTranslations.recommend1Desc}
                  />
                  <InfoCard
                    className="info_card info_card_brown m-2 mt-3"
                    headerText={reportAssessmentTranslations.recommenint2Header}
                    bodyText={reportAssessmentTranslations.recommenint2Desc}
                  />
                </div>
              </>
            )}
            <div className="d-flex justify-content-center pt-5 session-button">
              <SubmitButton
                onClick={() => navigate(AppUrlsEnum.findMentor)}
                type="submit"
                className="d-inline mt-5"
                text={
                  selectedLanguage === Languages.english
                    ? "Schedule a session with our career counsellor to understand the recommendations"
                    : reportAssessmentTranslations.scheduleSession
                }
              />
            </div>
          </PageContainer>
        </>
      )}
      <PageContainer className="pt-3 my-2 main_bg">
        <Page_2 navigateTo={handleNavigation} />
      </PageContainer>
      <PageContainer className="py-3 my-3 blue_bg">
        <h1 className="personality-head m-2 p-2 mt-4">
          {reportAssessmentTranslations.personalityHeading}
        </h1>
        <p className="complete-assessment1 m-2">
          {reportAssessmentTranslations.personalitydesc}
        </p>
        <p className="complete-assessment1 m-2">
          {reportAssessmentTranslations.personalitydesc1}
        </p>
        <p className="complete-assessment1 m-2">
          {reportAssessmentTranslations.personalitydesc2}
        </p>
        <p className="complete-assessment1 m-2">
          {reportAssessmentTranslations.personalitydesc3}
        </p>
        <h2 className="heading-content m-2 p-2">
          {reportAssessmentTranslations.personalitydesc4}
        </h2>
        <InputCard
          className="input-card m-2 p-2 mt-3"
          contentText={reportAssessmentTranslations.analystReport}
        />
        <InputCard
          className="input-card m-2 p-2 mt-3"
          contentText={reportAssessmentTranslations.conservativeReport}
        />
        <InputCard
          className="input-card m-2 p-2 mt-3"
          contentText={reportAssessmentTranslations.strategistReport}
        />
        <InputCard
          className="input-card m-2 p-2 mt-3"
          contentText={reportAssessmentTranslations.socializerReport}
        />
      </PageContainer>
      <PageContainer className="py-3 my-3 blue_bg">
        <Row style={{ textAlign: "center" }}>
          <Col>
            <ImageCard
              textClassName="image-vw"
              imageSrc={_getImage("L1.png")}
              belowImgText={reportAssessmentTranslations.l1Heading}
              width={200}
              height={200}
            />
          </Col>
          <Col>
            <ImageCard
              textClassName="image-vw"
              imageSrc={_getImage("L2.png")}
              belowImgText={reportAssessmentTranslations.l2Heading}
              width={200}
              height={200}
            />
          </Col>
          <Col>
            <ImageCard
              textClassName="image-vw"
              imageSrc={_getImage("R1.png")}
              belowImgText={reportAssessmentTranslations.r1Heading}
              width={200}
              height={200}
            />
          </Col>
          <Col>
            <ImageCard
              textClassName="image-vw"
              imageSrc={_getImage("R2.png")}
              belowImgText={reportAssessmentTranslations.r2Heading}
              width={200}
              height={200}
            />
          </Col>
        </Row>
        <Rating
          className="mt-4"
          subText={reportAssessmentTranslations.analystReport}
          value={getPercentage(
            data?.details?.brainProfilingScore?.L1,
            data?.details?.brainProfilingScore?.L1 +
              data?.details?.brainProfilingScore?.L2 +
              data?.details?.brainProfilingScore?.R1 +
              data?.details?.brainProfilingScore?.R2
          )}
        />
        <InfoCard
          className="info_card info_card_blue m-2 mt-2"
          headerText={reportAssessmentTranslations.meaningHeader}
          bodyText={reportAssessmentTranslations.meaningdesc}
        />
        <InfoCard
          className="info_card info_card_blue m-2 mt-3"
          headerText={reportAssessmentTranslations.suitableFields}
          bodyText={
            <ul>
              <li>{reportAssessmentTranslations.suitableFieldsdesc}</li>
              <li>{reportAssessmentTranslations.suitableFieldsdesc1}</li>
              <li>{reportAssessmentTranslations.suitableFieldsdesc2}</li>
              <li>{reportAssessmentTranslations.suitableFieldsdesc3}</li>
            </ul>
          }
        />
        <div className="input-line"></div>
        <Rating
          subText={reportAssessmentTranslations.conservativeReport}
          value={getPercentage(
            data?.details?.brainProfilingScore?.L2,
            data?.details?.brainProfilingScore?.L1 +
              data?.details?.brainProfilingScore?.L2 +
              data?.details?.brainProfilingScore?.R1 +
              data?.details?.brainProfilingScore?.R2
          )}
        />
        <InfoCard
          className="info_card info_card_blue m-2 mt-2"
          headerText={reportAssessmentTranslations.meaningHeader}
          bodyText={reportAssessmentTranslations.conservativeMeaning}
        />
        <InfoCard
          className="info_card info_card_blue m-2 mt-3"
          headerText={reportAssessmentTranslations.suitableFields}
          bodyText={
            <ul>
              <li>{reportAssessmentTranslations.suitableFieldsdesc4}</li>
              <li>{reportAssessmentTranslations.suitableFieldsdesc5}</li>
            </ul>
          }
        />
        <InfoCard
          className="info_card info_card_blue m-2 mt-3"
          headerText={reportAssessmentTranslations.suitableCareer}
          bodyText={
            <ul>
              <li>{reportAssessmentTranslations.suitableCareerdesc}</li>
              <li>{reportAssessmentTranslations.suitableCareerdesc1}</li>
              <li>{reportAssessmentTranslations.suitableCareerdesc2}</li>
              <li>{reportAssessmentTranslations.suitableCareerdesc3}</li>
            </ul>
          }
        />
        <div className="input-line"></div>
        <Rating
          subText={reportAssessmentTranslations.strategistReport}
          value={getPercentage(
            data?.details?.brainProfilingScore?.R1,
            data?.details?.brainProfilingScore?.L1 +
              data?.details?.brainProfilingScore?.L2 +
              data?.details?.brainProfilingScore?.R1 +
              data?.details?.brainProfilingScore?.R2
          )}
        />
        <InfoCard
          className="info_card info_card_blue m-2 mt-2"
          headerText={reportAssessmentTranslations.meaningHeader}
          bodyText={reportAssessmentTranslations.strategistMeaning}
        />
        <InfoCard
          className="info_card info_card_blue m-2 mt-3"
          headerText={reportAssessmentTranslations.suitableFields}
          bodyText={
            <ul>
              <li>{reportAssessmentTranslations.suitableFieldsdesc6}</li>
              <li>{reportAssessmentTranslations.suitableFieldsdesc7}</li>
              <li>{reportAssessmentTranslations.suitableFieldsdesc8}</li>
              <li>{reportAssessmentTranslations.suitableFieldsdesc9}</li>
            </ul>
          }
        />
      </PageContainer>
      <PageContainer className="py-3 my-3 blue_bg">
        <InfoCard
          className="info_card info_card_blue m-2 mt-3"
          headerText={reportAssessmentTranslations.suitableCareer}
          bodyText={
            <ul>
              <li>{reportAssessmentTranslations.suitableCareerdesc4}</li>
              <li>{reportAssessmentTranslations.suitableCareerdesc5}</li>
              <li>{reportAssessmentTranslations.suitableCareerdesc6}</li>
              <li>{reportAssessmentTranslations.suitableCareerdesc7}</li>
            </ul>
          }
        />
        <div className="input-line"></div>
        <Rating
          subText={reportAssessmentTranslations.socializerReport}
          value={getPercentage(
            data?.details?.brainProfilingScore?.R2,
            data?.details?.brainProfilingScore?.L1 +
              data?.details?.brainProfilingScore?.L2 +
              data?.details?.brainProfilingScore?.R1 +
              data?.details?.brainProfilingScore?.R2
          )}
        />
        <InfoCard
          className="info_card info_card_blue m-2 mt-2"
          headerText={reportAssessmentTranslations.meaningHeader}
          bodyText={reportAssessmentTranslations.scoializerMeaning}
        />
        <InfoCard
          className="info_card info_card_blue m-2 mt-3"
          headerText={reportAssessmentTranslations.suitableFields}
          bodyText={
            <ul>
              <li>{reportAssessmentTranslations.suitableFieldsdesc10}</li>
              <li>{reportAssessmentTranslations.suitableFieldsdesc11}</li>
            </ul>
          }
        />
        <InfoCard
          className="info_card info_card_blue m-2 mt-3"
          headerText={reportAssessmentTranslations.suitableCareer}
          bodyText={
            <ul>
              <li>{reportAssessmentTranslations.suitableCareerdesc8}</li>
              <li>{reportAssessmentTranslations.suitableCareerdesc9}</li>
              <li>{reportAssessmentTranslations.suitableCareerdesc10}</li>
              <li>{reportAssessmentTranslations.suitableCareerdesc11}</li>
              <li>{reportAssessmentTranslations.suitableCareerdesc12}</li>
            </ul>
          }
        />
      </PageContainer>
      <PageContainer className="py-3 my-3 main_bg">
        <div className="white_bg">
          <p className="complete-assessment1 m-2">
            {reportAssessmentTranslations.personalitydesc5}
          </p>
          <p className="complete-assessment1 m-2">
            {reportAssessmentTranslations.personalitydesc6}
          </p>
          <h2 className="interest-head mt-4">
            {reportAssessmentTranslations.interestHeading}
          </h2>
          <p className="complete-assessment1 m-2">
            {reportAssessmentTranslations.interestDesc}
          </p>
          <p className="complete-assessment1 m-2">
            {reportAssessmentTranslations.interestDesc1}
          </p>
          <ol>
            <li className="num-list">
              {reportAssessmentTranslations.interestDesc2}
            </li>
            <li className="num-list">
              {reportAssessmentTranslations.interestDesc3}
            </li>
            <li className="num-list">
              {reportAssessmentTranslations.interestDesc4}
            </li>
            <li className="num-list">
              {reportAssessmentTranslations.interestDesc5}
            </li>
            <li className="num-list">
              {reportAssessmentTranslations.interestDesc6}
            </li>
            <li className="num-list">
              {reportAssessmentTranslations.interestDesc7}
            </li>
          </ol>
          <p className="complete-assessment1 m-2">
            {reportAssessmentTranslations.interestDesc8}
          </p>
          <p className="complete-assessment1 m-2">
            {reportAssessmentTranslations.interestDesc9}
          </p>
          <div className="d-flex justify-content-center my-5">
            <Image src={pie_chart} height={200} width={200} />
          </div>
        </div>
      </PageContainer>
      <PageContainer className="py-3 my-3 main_bg">
        <div className="white_bg">
          <p className="complete-assessment m-2">
            {reportAssessmentTranslations.sixDimensions}
          </p>
          <Row className="mt-4" style={{ textAlign: "center" }}>
            <Col>
              <ImageCard
                textClassName="image-vw"
                imageSrc={_getImage("Realistic.png")}
                belowImgText={reportAssessmentTranslations.interestDesc2}
                width={200}
                height={200}
              />
            </Col>
            <Col>
              <ImageCard
                textClassName="image-vw"
                imageSrc={_getImage("investigative.png")}
                belowImgText={reportAssessmentTranslations.interestDesc3}
                width={200}
                height={200}
              />
            </Col>
            <Col>
              <ImageCard
                textClassName="image-vw"
                imageSrc={_getImage("Artistic.png")}
                belowImgText={reportAssessmentTranslations.interestDesc4}
                width={200}
                height={200}
              />
            </Col>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col>
              <ImageCard
                textClassName="image-vw"
                imageSrc={_getImage("social.png")}
                belowImgText={reportAssessmentTranslations.interestDesc5}
                width={200}
                height={200}
              />
            </Col>
            <Col>
              <ImageCard
                textClassName="image-vw"
                imageSrc={_getImage("enterprising.png")}
                belowImgText={reportAssessmentTranslations.interestDesc6}
                width={200}
                height={200}
              />
            </Col>
            <Col>
              <ImageCard
                textClassName="image-vw"
                imageSrc={_getImage("conventional.png")}
                belowImgText={reportAssessmentTranslations.interestDesc7}
                width={200}
                height={200}
              />
            </Col>
          </Row>
        </div>
      </PageContainer>
      <PageContainer className="py-3 my-3 main_bg">
        <Rating
          className="mt-4"
          subText={reportAssessmentTranslations.realisticHeading}
          value={getPercentage(
            data?.details?.raisecScore?.R,
            examQuestion[data?.examId]?.raisec?.r
          )}
        />
        <InfoCard
          className="info_card info_card_purple m-2"
          headerText={reportAssessmentTranslations.meaningHeader}
          bodyText={reportAssessmentTranslations.realisticMeaning}
        />
        <div className="input-line"></div>
        <Rating
          subText={reportAssessmentTranslations.investiveHeading}
          value={getPercentage(
            data?.details?.raisecScore?.I,
            examQuestion[data?.examId]?.raisec?.i
          )}
        />
        <InfoCard
          className="info_card info_card_purple m-2"
          headerText={reportAssessmentTranslations.meaningHeader}
          bodyText={reportAssessmentTranslations.investiveMeaning}
        />
        <div className="input-line"></div>
        <Rating
          subText={reportAssessmentTranslations.artisticHeading}
          value={getPercentage(
            data?.details?.raisecScore?.A,
            examQuestion[data?.examId]?.raisec?.a
          )}
        />
        <InfoCard
          className="info_card info_card_purple m-2"
          headerText={reportAssessmentTranslations.meaningHeader}
          bodyText={reportAssessmentTranslations.artisticMeaning}
        />
      </PageContainer>
      <PageContainer className="py-3 my-3 main_bg">
        <Rating
          subText={reportAssessmentTranslations.socialHeading}
          value={getPercentage(
            data?.details?.raisecScore?.S,
            examQuestion[data?.examId]?.raisec?.s
          )}
        />
        <InfoCard
          className="info_card info_card_purple m-2"
          headerText={reportAssessmentTranslations.meaningHeader}
          bodyText={reportAssessmentTranslations.socialMeaning}
        />
        <div className="input-line"></div>
        <Rating
          subText={reportAssessmentTranslations.enterpriceHeading}
          value={getPercentage(
            data?.details?.raisecScore?.E,
            examQuestion[data?.examId]?.raisec?.e
          )}
        />
        <InfoCard
          className="info_card info_card_purple m-2"
          headerText={reportAssessmentTranslations.meaningHeader}
          bodyText={reportAssessmentTranslations.enterpriceMeaning}
        />
        <div className="input-line"></div>
        <Rating
          subText={reportAssessmentTranslations.conventionHeading}
          value={getPercentage(
            data?.details?.raisecScore?.C,
            examQuestion[data?.examId]?.raisec?.c
          )}
        />
        <InfoCard
          className="info_card info_card_purple m-2"
          headerText={reportAssessmentTranslations.meaningHeader}
          bodyText={reportAssessmentTranslations.conventionMeaning}
        />
      </PageContainer>
      <PageContainer className="py-3 my-3 orange_bg">
        <h2 className="apti-head mt-4">
          {reportAssessmentTranslations.aptiHeading}
        </h2>
        <p className="complete-assessment1 m-2">
          {reportAssessmentTranslations.aptiDesc}
        </p>
        <p className="complete-assessment1 m-2">
          {reportAssessmentTranslations.aptiDesc1}
        </p>
        <p className="complete-assessment1 m-2">
          {reportAssessmentTranslations.aptiDesc2}
        </p>
        <p className="complete-assessment1 m-2">
          {reportAssessmentTranslations.aptiDesc3}
        </p>
        <p className="complete-assessment1 m-2">
          {reportAssessmentTranslations.aptiDesc4}
        </p>
      </PageContainer>
      <PageContainer className="py-3 my-3 orange_bg">
        <Row className="mt-4" style={{ textAlign: "center" }}>
          <Col>
            <ImageCard
              textClassName="image-vw"
              imageSrc={_getImage("SelfManagement.png")}
              belowImgText={reportAssessmentTranslations.selfMgHeading}
              width={200}
              height={200}
            />
          </Col>
          <Col>
            <ImageCard
              textClassName="image-vw"
              imageSrc={_getImage("teamWork.png")}
              belowImgText={reportAssessmentTranslations.teamwrkHeading}
              width={200}
              height={200}
            />
          </Col>
          <Col>
            <ImageCard
              textClassName="image-vw"
              imageSrc={_getImage("Enterprising_2.png")}
              belowImgText={reportAssessmentTranslations.enterPriHeading}
              width={200}
              height={200}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="apti_img">
            <ImageCard
              textClassName="image-vw"
              imageSrc={_getImage("ProblemSolving.png")}
              belowImgText={reportAssessmentTranslations.probSolveHeading}
              width={200}
              height={200}
            />
          </Col>
          <Col className="apti_img_2">
            <ImageCard
              textClassName="image-vw"
              imageSrc={_getImage("Communications.png")}
              belowImgText={reportAssessmentTranslations.speakingHeading}
              width={200}
              height={200}
            />
          </Col>
        </Row>
        <InfoCard
          className="info_card info_card_orange m-2 mt-3"
          headerText={reportAssessmentTranslations.selfMgHeading}
          bodyText={reportAssessmentTranslations.selfMgDesc}
        />
        <InfoCard
          className="info_card info_card_orange m-2 mt-3"
          headerText={reportAssessmentTranslations.teamwrkHeading}
          bodyText={reportAssessmentTranslations.teamwrkDesc}
        />
      </PageContainer>
      <PageContainer className="py-3 my-3 orange_bg">
        <InfoCard
          className="info_card info_card_orange m-2 mt-3"
          headerText={reportAssessmentTranslations.enterPriHeading}
          bodyText={reportAssessmentTranslations.enterPriDesc}
        />
        <InfoCard
          className="info_card info_card_orange m-2 mt-3"
          headerText={reportAssessmentTranslations.probSolveHeading}
          bodyText={reportAssessmentTranslations.probSolveDesc}
        />
        <InfoCard
          className="info_card info_card_orange m-2 mt-3"
          headerText={reportAssessmentTranslations.speakingHeading}
          bodyText={reportAssessmentTranslations.speakingDesc}
        />
        <InfoCard
          className="info_card info_card_orange m-2 mt-3"
          headerText={reportAssessmentTranslations.developSkillHeading}
          bodyText={reportAssessmentTranslations.developSkillDesc}
        />
        <InfoCard
          className="info_card info_card_orange m-2 mt-3"
          headerText={reportAssessmentTranslations.scoringSkillHeading}
          bodyText={reportAssessmentTranslations.scoringSkillDesc}
        />
      </PageContainer>
      <PageContainer className="py-3 my-3 sky_bg">
        <h2 className="character-head mt-4">
          {reportAssessmentTranslations.strengthHeading}
        </h2>
        <p className="complete-assessment1 m-2">
          {reportAssessmentTranslations.strengthDesc}
        </p>
        <p className="complete-assessment1 m-2">
          {reportAssessmentTranslations.strengthDesc1}
        </p>
        <Row className="mt-4" style={{ textAlign: "center" }}>
          <Col>
            <ImageCard
              textClassName="image-vw"
              imageSrc={_getImage("wisdom.png")}
              belowImgText={reportAssessmentTranslations.wisdomHeading}
              width={200}
              height={200}
            />
          </Col>
          <Col>
            <ImageCard
              textClassName="image-vw"
              imageSrc={_getImage("courage.png")}
              belowImgText={reportAssessmentTranslations.courageHeading}
              width={200}
              height={200}
            />
          </Col>
          <Col>
            <ImageCard
              textClassName="image-vw"
              imageSrc={_getImage("humanity.png")}
              belowImgText={reportAssessmentTranslations.humanityHeading}
              width={200}
              height={200}
            />
          </Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col>
            <ImageCard
              textClassName="image-vw"
              imageSrc={_getImage("justice.png")}
              belowImgText={reportAssessmentTranslations.justiceHeading}
              width={200}
              height={200}
            />
          </Col>
          <Col>
            <ImageCard
              textClassName="image-vw"
              imageSrc={_getImage("Temperance.png")}
              belowImgText={reportAssessmentTranslations.moderationHeading}
              width={200}
              height={200}
            />
          </Col>
          <Col>
            <ImageCard
              textClassName="image-vw"
              imageSrc={_getImage("transcendence.png")}
              belowImgText={reportAssessmentTranslations.transcenHeading}
              width={200}
              height={200}
            />
          </Col>
        </Row>
      </PageContainer>
      <PageContainer className="py-3 my-3 sky_bg">
        <h2 className="periodic-table mt-3 m-2">
          {reportAssessmentTranslations.periodicTable}
        </h2>
        <div>
          <PeriodicTable />
        </div>
        <Rating
          className="mt-4"
          subText={reportAssessmentTranslations.wisdomHeading}
          hideRate={true}
        />
        <InfoCard
          className="info_card info_card_cyan m-2"
          headerText={reportAssessmentTranslations.meaningHeader}
          bodyText={reportAssessmentTranslations.wisdomDesc}
        />
        <div className="input-line"></div>
        <Rating
          subText={reportAssessmentTranslations.courageHeading}
          hideRate={true}
        />
        <InfoCard
          className="info_card info_card_cyan m-2"
          headerText={reportAssessmentTranslations.meaningHeader}
          bodyText={reportAssessmentTranslations.courageDesc}
        />
      </PageContainer>
      <PageContainer className="py-3 my-3 sky_bg">
        <Rating
          subText={reportAssessmentTranslations.humanityHeading}
          hideRate={true}
        />
        <InfoCard
          className="info_card info_card_cyan m-2"
          headerText={reportAssessmentTranslations.meaningHeader}
          bodyText={reportAssessmentTranslations.humanityDesc}
        />
        <div className="input-line"></div>
        <Rating
          subText={reportAssessmentTranslations.transcenHeading}
          hideRate={true}
        />
        <InfoCard
          className="info_card info_card_cyan m-2"
          headerText={reportAssessmentTranslations.meaningHeader}
          bodyText={reportAssessmentTranslations.transcenDesc}
        />
        <div className="input-line"></div>
        <Rating
          subText={reportAssessmentTranslations.justiceHeading}
          hideRate={true}
        />
        <InfoCard
          className="info_card info_card_cyan m-2"
          headerText={reportAssessmentTranslations.meaningHeader}
          bodyText={reportAssessmentTranslations.justiceDesc}
        />
        <div className="input-line"></div>
        <Rating
          subText={reportAssessmentTranslations.moderationHeading}
          hideRate={true}
        />
        <InfoCard
          className="info_card info_card_cyan m-2"
          headerText={reportAssessmentTranslations.meaningHeader}
          bodyText={reportAssessmentTranslations.moderationDesc}
        />
      </PageContainer>
      <PageContainer className="py-3 my-3 sky_bg">
        <InfoCard
          className="info_card info_card_cyan m-2 mt-3"
          headerText={reportAssessmentTranslations.balanceSkills}
          bodyText={reportAssessmentTranslations.balanceSkillsDesc}
        />
        <InfoCard
          className="info_card info_card_cyan m-2 mt-3"
          headerText={reportAssessmentTranslations.valueChange}
          bodyText={reportAssessmentTranslations.valueChangeDesc}
        />
      </PageContainer>
      {menRec && (
        <PageContainer className="py-3 my-3 main_bg">
          <h2 className="steps-head mt-3 m-2">
            {reportAssessmentTranslations.stepsHeading}
          </h2>
          <ol className="steps_list">
            <li className="steps-method mt-3">
              {reportAssessmentTranslations.step1}
              <ol>
                <li type="a" className="steps-desc mt-3">
                  {reportAssessmentTranslations.step1Desc}
                </li>
              </ol>
            </li>
            <li className="steps-method mt-3">
              {reportAssessmentTranslations.step2}
              <ol>
                <li type="a" className="steps-desc mt-3">
                  {reportAssessmentTranslations.step2Desc}
                </li>
                <li type="a" className="steps-desc mt-1">
                  {reportAssessmentTranslations.step2Desc1}
                </li>
                <li type="a" className="steps-desc mt-1">
                  {reportAssessmentTranslations.step2Desc2}
                </li>
              </ol>
            </li>
            <li className="steps-method mt-3">
              {reportAssessmentTranslations.step3}
              <ol>
                <li type="a" className="steps-desc mt-3">
                  {reportAssessmentTranslations.step3Desc}
                </li>
                <li type="a" className="steps-desc mt-1">
                  {reportAssessmentTranslations.step3Desc1}
                </li>
                <li type="a" className="steps-desc mt-1">
                  {reportAssessmentTranslations.step3Desc2}
                </li>
              </ol>
            </li>
            <li className="steps-method mt-3">
              {reportAssessmentTranslations.step4}
              <ol>
                <li type="a" className="steps-desc mt-3">
                  {reportAssessmentTranslations.step4Desc}
                </li>
                <li type="a" className="steps-desc mt-1">
                  {reportAssessmentTranslations.step4Desc1}
                </li>
              </ol>
            </li>
          </ol>

          <>
            <div className="d-flex justify-content-center pt-3 session-button">
              <Link to={AppUrlsEnum.findMentor} target="_blank" rel="noopener">
                <SubmitButton
                  // onClick={handleNavigation}
                  type="submit"
                  className="d-inline"
                  text={reportAssessmentTranslations.scheduleSession}
                />
              </Link>
            </div>
          </>
        </PageContainer>
      )}
      <PageContainer className="py-3 my-3 main_bg">
        <Page_30 />
      </PageContainer>
    </div>
  );
};

export default AssessmentReports;
