//** Iguroo & GinieHealth ForgotPassword UI code**//

import React, { useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Row, Col, Card } from "react-bootstrap";
import { AuthContext } from "../../hooks/auth.context.provider";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { SubmitButton } from "../../components/submit.button";
import { useDispatch } from "react-redux";
import { loader } from "../../redux-tk/slices/loader.slice";
import { useNavigate } from "react-router-dom";
import { Heading } from "../../components/heading.component";
import { Link } from "react-router-dom";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { FormErrorComponent } from "../../components/form.error.component";
import { AppUrlsEnum, StrConstants, VerificationCodeEnums } from "../../enums";
import { BackButton } from "../../components/back.button";
import { useRef } from "react";
import { getUserOtpLoginForm } from "../../FormSchemas";

export const ForgetPassword = () => {
  const {
    formElementLabels,
    formErrors,
    headingTranslations,
    headerFooterTranslations,
  } = useIgurooTranslations();
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getUserOtpLoginForm(formErrors)),
  });

  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const usernameRef = useRef();

  const onSubmit = async (data) => {
    console.log(usernameRef);
    try {
      dispatch(loader(true));
      console.log("username", data);
      const returnData = await authContext.sendCode(data.username?.toLowerCase());
      console.log("forgot password return", returnData);
      localStorage.setItem(StrConstants.username, data.username.toLowerCase());
      navigate(AppUrlsEnum.verifyMobile, {
        state: {
          pageName: VerificationCodeEnums.forgotPassword,
          session: data.Session,
        },
      });
      dispatch(loader(false));
      console.log("made call");
    } catch (err) {
      //console.log("err", err);
      if (err.code === "UserNotConfirmedException") {
      } else {
        setError("apiError", { message: err.message });
      }
      dispatch(loader(false));
    }
  };

  return (
    <Row className="sign-head ig-auth-class  forget-password justify-content-center align-items-center  mt-4 ">
      <Col lg="5" md="8" className="main-container">
        <Card className="verify-page">
          <Card.Body>
            <Row className="mb-4 justify-content-center">
              <Col sm="10" lg="9">
                <Card.Title>
                  <Row>
                    <Col xs="2" className="px-0 mt-2">
                      <Link to={AppUrlsEnum.authSignin}>
                        <BackButton svgName="arrow-left.svg" />
                      </Link>
                    </Col>
                    <Col className="mt-3">
                      <Heading className="ig-auth-heading">
                        {headingTranslations?.forgetPassword}
                      </Heading>
                    </Col>
                  </Row>
                </Card.Title>
                <div className="text-center">
                  {headingTranslations?.forgetPasswordMessage}
                </div>
                <br />
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group>
                    <Form.Label>
                      {formElementLabels?.placeHolders?.mobileNo}
                    </Form.Label>
                    <Controller
                      name={`username`}
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomInputField
                          placeholder={
                            formElementLabels?.placeHolders?.mobileNo
                          }
                          hookOnChange={onChange}
                          onChange={(val) => {
                            //console.log("value", val, "  ", value);
                            localStorage.setItem(StrConstants.username, val);
                          }}
                          hookOnBlur={onBlur}
                          hookValue={value}
                          className={`sign-input ${
                            errors.username ? "ig-error-signin" : ""
                          }`}
                          ref={usernameRef}
                        />
                      )}
                    />
                    <FormErrorComponent>
                      {errors.username && errors.username?.message}
                    </FormErrorComponent>
                  </Form.Group>
                  <br />
                  <Row className="mb-2">
                    <FormErrorComponent>
                      {errors.apiError && errors.apiError?.message}
                    </FormErrorComponent>
                  </Row>
                  <Col lg="12" xs="12" md="12" xl="12" className="d-grid">
                    <SubmitButton
                      type="submit"
                      text={headerFooterTranslations?.btnSendcode}
                      onClick={() => {
                        clearErrors("apiError");
                      }}
                    />
                  </Col>
                  <br />
                </Form>
                <Row className="sigin-pwd text-center  justify-content-center">
                  <Link to={AppUrlsEnum.authSignin}>
                    {" "}
                    {formElementLabels?.cancel}
                  </Link>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
