//** Iguroo & GinieHealth Common Radio Options Check,can re-use any required field **//

import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FormLabelComponent } from "../form.label.component";
import "./custom.radio.css";

const CustomRadioOptions = (props) => {
  const { options, label, hookValue, hookOnBlur, hookOnChange, name } = props;
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  if (options?.length === 0) {
    return (
      <Spinner animation="border" variant="primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  let isMobile = width <= 668;
  //console.log(isMobile ? 'mobile' : 'not')
  let inlineValue = isMobile ? `default` : `inline`;
  //console.log(inlineValue)

  return (
    <div key={`inline-${"radio"}`} className="custom_drop_down_container">
      <div>
        <label>
          <FormLabelComponent>{label}</FormLabelComponent>
        </label>
      </div>
      {options.map((data, index) => {
        return (
          <Form.Check
            key={index}
            inline={!isMobile}
            className="radio_option_row"
            label={data.value}
            value={data.dataId}
            checked={data.dataId === hookValue}
            onBlur={hookOnBlur}
            onChange={hookOnChange}
            name={name}
            type={"radio"}
            //id={`inline-radio-1`}
          />
        );
      })}
    </div>
  );
};

export default CustomRadioOptions;
