//** SumbitButton can change the text inside Button ,can use this common Component required Field **//

import React from "react";
import { Button } from "react-bootstrap";
import { showPopupflag } from "../redux-tk/slices/showPopupflag.slice";
import { useDispatch } from "react-redux";

export function SubmitButton(props) {
  const { isDisabled, text, className, disabled, type } = props;
  const dispatch = useDispatch();

  return (
    <Button
      className={`submit-btn btn btn-block ${className}`}
      style={{
        paddingVertical: 10,
        paddingHorizontal: 18,
        borderWidth: 1,
        borderColor: isDisabled ? `rgb(74, 0, 144)` : "#5b2aef",
        borderRadius: 4,
        backgroundColor: isDisabled ? `rgb(74, 0, 144)` : "#5b2aef",
      }}
      {...(disabled && { disabled: disabled })}
      variant="primary"
      type={type ? type : "submit"}
      onClick={
        props.onClick
          ? () => {
              (async function () {
                dispatch(showPopupflag(false));
              })();
              return props.onClick();
            }
          : () => {
              (async function () {
                dispatch(showPopupflag(false));
              })();
            }
      }
    >
      {text ? text : "Submit"}
    </Button>
  );
}
