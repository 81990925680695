//** Iguroo SelfAssessment,CustomText RateRadio Options Component,can re-use required field **//

import React from "react";
import { Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const CustomAnswerRateOptions = (props) => {
  const {
    options,
    label,
    hookValue,
    hookOnBlur,
    hookOnChange,
    name,
    defaultValue,
  } = props;

  if (options?.length === 0) {
    return (
      <Spinner animation="border" variant="primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  return (
    <div key={`inline-${"radio"}`} className="ig_custom_rate_container d-flex">
      {options.map((data, index) => {
        console.log(
          data.value === "1",
          data.value == 1,
          data.value,
          data.value == 1 ? true : false,
          "console"
        );
        return (
          <Form.Check
            type="radio"
            id={index}
            className={"radio_option_row mx-3"}
          >
            <Form.Check.Input
              type={"radio"}
              // isValid1
              key={index}
              inline={true}
              className=" "
              name={name}
              // label={data.value}
              value={data.value}
              onBlur={hookOnBlur}
              onChange={hookOnChange}
              //  checked={value == hookValue}
              defaultChecked={data.value == defaultValue ? true : false}
            />
            <Form.Check.Label for={index} className="">
              {data.value}
            </Form.Check.Label>
          </Form.Check>
        );
      })}
    </div>
  );
};

export default CustomAnswerRateOptions;
