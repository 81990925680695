//**Iguroo Admin Mentor Approve Testimonails UI code**//

import React from "react";
import Toaster from "../../components/toaster";
import { toast } from "react-toastify";
import { postApproveTestimonials } from "../../services/axios/Testimonials";

const ApproveApiTestimonials = (props) => {
  const handleApprove = () => {
    // Approve testimonials
    if (props.data.testimonialId || props.data.mentorId) {
      const data = {
        mentorId: props.data.mentorId,
        testimonialId: props.data.testimonialId,
        status: "APPROVED",
        testimonialText: props.data.testimonialText,
      };
      console.log(data, "approve-testi");
      postApproveTestimonials(data)
        .then((res) => {
          props.getTestimonials();
          Toaster(toast.success, "Testimonial approved");
          console.log(data, "approve-testi");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      {props.Status ? (
        <button
          disabled={true}
          onClick={handleApprove}
          class="btn btn-sm primary-button rounded-5"
        >
          Approved
        </button>
      ) : (
        <button onClick={handleApprove} class="btn btn-sm primary-button">
          Approve
        </button>
      )}
    </>
  );
};

export default ApproveApiTestimonials;
