//** Dashboard My Mentor Page **//

import React, { useState, useRef, useMemo, useCallback } from "react";
import { searchRequests } from "../../services/axios/opensearch";
import {
  extractDataFromOpensearch,
  getStatusSelect,
} from "../../helpers/grid.helpers";
import { CustomSelect } from "../../components/CustomInput/custom.select";
import { AgGridNoRowsParams, RequestStatus } from "../../enums";
import { Col, Image, Row } from "react-bootstrap";
import { AgGridMain } from "../../components/agGridRenderers/ag.grid.main";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { SubmitButton } from "../../components/submit.button";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { FormLabelComponent } from "../../components/form.label.component";
import { _getImage } from "../../common/utils/commonFunctions";
import { useMyMentorDefinitions } from "../../AgGridDefinitions/mentee.mymentor.grid.definitions";
import { Heading } from "../../components/heading.component";

export function MyMentorsList() {
  const { formElementLabels, staticSelectTranslations, headingTranslations } =
    useIgurooTranslations();
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  const [selectStatusSearch, setSelectStatusSearch] = useState();
  const requestStatusItems = useMemo(
    () => getStatusSelect(staticSelectTranslations),
    [staticSelectTranslations]
  );

  const columnDefs = useMyMentorDefinitions();

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    resizable: true,
    filter: true,
  }));

  const getDataG = async (userId, searchText, status, startRow, endRow) => {
    const d = await searchRequests({
      userId: userId,
      searchText: searchText,
      status: status,
      startRow: startRow,
      endRow: endRow,
      unique: true,
      isMentor: false,
    });
    return d;
  };

  const onGridReadyNew = useCallback((params) => {
    console.log("params", params, gridRef);
    // params.api.sizeColumnsToFit();
    const paginationProxy = params.api.paginationProxy;
    const dataSource = {
      rowCount: null,
      getRows: async function (rparams) {
        try {
          const filterModel = rparams.filterModel;
          const statusFilter = filterModel?.status?.filter;
          const titleFilter = filterModel?.mentorId?.filter;
          const endRow = rparams.endRow;
          const startRow = rparams.startRow;
          const d = await getDataG(
            localStorage.getItem("subId"),
            titleFilter,
            statusFilter === RequestStatus.all ? undefined : statusFilter,
            startRow,
            endRow - startRow
          );
          const data = extractDataFromOpensearch(d.hits).data;
          if (d.total.value === 0) {
            gridRef.current.api.showNoRowsOverlay();
          } else {
            gridRef.current.api.hideOverlay();
          }
          rparams.successCallback(data, d.total.value);
        } catch (err) {
          console.log("err", err);
          gridRef.current.api.showNoRowsOverlay();
          rparams.successCallback([], 0);
        }
      },
    };
    params.api.setDatasource(dataSource);
  }, []);

  const applyCategoryModel = () => {
    console.log("val", selectStatusSearch, searchSearch);
    gridRef.current.api.setFilterModel({
      status: {
        filter: selectStatusSearch?.dataId,
        filterType: "text",
        type: "equals",
      },

      mentorId: {
        filter: searchSearch,
        filterType: "text",
        type: "contains",
      },
    });
  };
  const [searchSearch, setSearchSearch] = useState();
  return (
    <div className="ms-3 me-3">
      <Heading className="headingDashboard">
        {headingTranslations?.myMentor}
      </Heading>
      <Row className="d-flex align-items-end mb-3">
        <Col md={3} className="pe-0 mt-3">
          <Row className="d-flex align-items-center">
            <Col sm={1}>
              <FormLabelComponent> </FormLabelComponent>
              <Image src={_getImage("filter.svg")} />
            </Col>
            <Col sm={11}>
              <FormLabelComponent>
                {formElementLabels.status}
              </FormLabelComponent>
              <CustomSelect
                opLabel="value"
                opValue="dataId"
                items={requestStatusItems}
                sendFullValueInHook={true}
                onChangeCallback={setSelectStatusSearch}
                value={selectStatusSearch}
                normalSelect={true}
              />
            </Col>
          </Row>
        </Col>
        <Col md={7} lg={8}>
          <CustomInputField
            placeholder={formElementLabels?.placeHolders?.search}
            onChange={setSearchSearch}
            hookValue={searchSearch}
          />
        </Col>
        <Col sm={12} md={2} lg={1} className="mt-3 d-grid">
          <SubmitButton onClick={applyCategoryModel} text="Search" />
          {/* <input type={"button"} onClick={applyCategoryModel} /> */}
        </Col>
      </Row>
      <div className="mb-3">
        <AgGridMain
          gridRef={gridRef}
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          onGridReady={onGridReadyNew}
          //getLocaleText={getLocaleText}
          rowHeight={100}
          className={"ig-aggrid-css"}
          noRowsParams={{ [AgGridNoRowsParams.isMentorUsers]: true }}
        />
      </div>
    </div>
  );
}
