//** GinieHealth Learner Login,Text Question UI component code **//

import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "./component/styles.scss";
import update from "immutability-helper";
import { useForm, Controller } from "react-hook-form";
import { TextQuestionAnswerComponent } from "./component/text.question.component";
import { TestQuestionComponent } from "./component/question.component";
import QFooter from "../questions/qFooter";
import {
  TimeTakenValue,
  TimeleftValue,
  answerSelected,
  answerValue,
  changeGLCurrentQuestion,
  changeTimeTaken,
  currentGLQuestionValue,
} from "../../redux-tk/slices/genieLabs.currentQuestion";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { useDispatch, useSelector } from "react-redux";
import { loader } from "../../redux-tk/slices/loader.slice";
import { UpdateAssessmentAttemptApi } from "@iguroo/ig-react-assessment-package";
import { AuthContext } from "../../hooks/auth.context.provider";
import { useNavigate } from "react-router-dom";
import { AppUrlsEnum } from "../../enums";
import { FormErrorComponent } from "../form.error.component";
import { PopupQuestion } from "./component/popup";
import Signature from "../Signature";
import moment from "moment";

const TextQuestion = (props) => {
  const { testAssessmentTranslations, genieLabsAssessmentTranslations } =
    useIgurooTranslations();
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();
  const authContext = useContext(AuthContext);
  const selectedAnswers = useSelector((state) => answerValue(state));
  const [answers, setAnswers] = useState(selectedAnswers);
  const [selectAnswer, setSelectAnswers] = useState([]);
  const [attemptdata, setAttemptData] = useState({});
  const [isSubmitting, setisSubmitting] = useState(true);
  const timeLeft = useSelector((state) => TimeleftValue(state));
  const timeTaken = useSelector((state) => TimeTakenValue(state));
  const dispatch = useDispatch();
  const currentQuestion = useSelector((state) => currentGLQuestionValue(state));
  const [showPopup, setShowPopup] = useState(false);
  const [showTimerPopup, setShowTimerPopup] = useState(false);
  const [meta_data, setmeta_data] = useState(null);
  useEffect(() => {
    dispatch(loader(true));
    // var meta_data = "";
    // console.log(props,'1655 console',meta_data?.signature_description? meta_data?.signature_description : genieLabsAssessmentTranslations?.signaturSubHeader)
    props?.assessmentData?.assessment_metadata?.forEach((val) => {
      if (val?.metadata_section === "signature_info") {
        console.log(val, "1655 console");
        setmeta_data(val);
      }
    });
    const ansSelect = selectedAnswers.filter(
      (ans) => ans.question_id === props?.questionId
    );
    if (ansSelect.length > 0) {
      // console.log(ansSelect[0].answer, "console answw");
      setSelectAnswers(ansSelect[0].answer[0]);
      setTimeout(() => {
        setValue(`answerOption`, ansSelect[0].answer[0]);
        setisSubmitting(false);
      }, 3);
      setisSubmitting(false);
    } else {
      setisSubmitting(false);
    }
    setAnswers(selectedAnswers);
    dispatch(loader(false));
  }, []);

  const previousClick = () => {
    console.log("next click", currentQuestion);
    if (currentQuestion > 0)
      dispatch(changeGLCurrentQuestion(currentQuestion - 1));
  };
  const checkKeyDown = (e) => {
    if (e.key === 'Enter') handleSubmit(onNextClick)();
  };
  const onMarkClick = () => {
    const ansSelect = answers.filter(
      (ans) => ans.question_id === props?.questionId
    );
    let ans = [];
    const answer = {
      question_id: props.questionId,
      answer: [],
      isMark: true,
    };
    if (ansSelect.length === 0) {
      ans = [...answers, answer];
      setAnswers([...answers, answer]);
    } else {
      var commentIndex = answers.findIndex(
        (c) => c.question_id === props?.questionId
      );
      let updatedItems = answers;
      if (commentIndex > -1) {
        updatedItems = update(answers, {
          [commentIndex]: { $set: answer },
        });
      }
      ans = updatedItems;
    }
    dispatch(answerSelected(ans));
    const attemptData = {
      attempt_id: props?.attempid,
      status: "INPROGRESS",
      answers: ans,
      assessment_version_id: props?.assessment_version_id,
      userId: authContext.sessionInfo.sub,
    };
    //console.log(data, attempt, "signature console 165");
    UpdateAssessmentAttemptApi(attemptData).then((res) =>
      dispatch(changeGLCurrentQuestion(currentQuestion + 1))
    );
  };

  const onNextClick = (data) => {
    setisSubmitting(true);
    dispatch(loader(true));
    let isEmptyAllow = false;
    let isEmpty = false;
    console.log(isEmptyAllow, data, " console  log 165 console  log 165");
    if (props?.assessmentData?.isSkippingAllowed) {
      isEmptyAllow = props?.assessmentData?.isSkippingAllowed;
    } else {
      if (
        data.answerOption == undefined ||
        data.answerOption == null ||
        data.answerOption == ""
      )
        isEmpty = true;
    }
    if (!isEmptyAllow && isEmpty) {
      setError("answerOption", {
        message: testAssessmentTranslations?.AssessmentOptionError,
      });
      dispatch(loader(false));
      setisSubmitting(false);
    } else {
      if (props.totalQuestion == currentQuestion + 1) {
        setShowPopup(true);
      }
      const ansSelect = answers.filter(
        (ans) => ans.question_id === props?.questionId
      );
      let ans = [];
      const seconds = Math.floor((timeLeft / 1000) % 60);
      const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
      const hours = Math.floor((timeLeft / 1000 / 60 / 60) % 24);
      let timeleftDetails = 0;
      if (timeLeft <= 60) {
        timeleftDetails = minutes + 1 + " mins " + seconds + " sec ";
      } else {
        timeleftDetails =
          hours * 60 + (minutes + 1) + " mins " + seconds + " sec ";
      }
      var commentIndex = answers.findIndex(
        (c) => c.question_id === props?.questionId
      );
      let answer = {
        question_id: props.questionId,
        question_type: "FREE_TEXT",
        answer: [data?.answerOption],
        isMark: false,
      };
      if (props?.assessmentData?.duration) {
        let timeTaken1 = 0;
        if (timeTaken === 0) {
          const total =
            Date.parse(props?.assessmentData.duration) - Date.parse(new Date());
          timeTaken1 = total - timeLeft;
        } else {
          timeTaken1 = timeTaken - timeLeft;
        }
        timeTaken1 = Math.floor((timeTaken1 / 1000) % 60);
        let time = 0;
        if (ansSelect.length !== 0) time = answers[commentIndex].timeTaken;
        console.log("commentIndex 165", time);
        answer.totaltimeleft = timeLeft;
        answer.timeleft = timeleftDetails;
        answer.timeTaken = timeTaken1 + time;
        console.log("console 165", answer, timeTaken);
      }
      if (ansSelect.length === 0) {
        ans = [...answers, answer];
        setAnswers([...answers, answer]);
      } else {
        let updatedItems = answers;
        if (commentIndex > -1) {
          updatedItems = update(answers, {
            [commentIndex]: { $set: answer },
          });
        }
        ans = updatedItems;
      }
      dispatch(answerSelected(ans));
      const attemptData = {
        attempt_id: props?.attempid,
        status:
          currentQuestion + 1 == props?.totalQuestion
            ? "COMPLETED"
            : "INPROGRESS",
        answers: ans,
        assessment_version_id: props?.assessment_version_id,
        userId: authContext.sessionInfo.sub,
      };
      if (props?.assessmentData?.duration) {
      }
      setAttemptData(attemptData);
      if (
        props.totalQuestion == currentQuestion + 1 &&
        (!props?.assessmentData?.show_assessment_signature ||
          props?.assessmentData?.show_assessment_signature == undefined)
      ) {
        UpdateAssessmentAttemptApi(attemptData);
        dispatch(changeTimeTaken(timeLeft));
      }
      if (props.totalQuestion > currentQuestion + 1) {
        UpdateAssessmentAttemptApi(attemptData)
          .then((res) => {
            dispatch(changeGLCurrentQuestion(currentQuestion + 1));
            dispatch(changeTimeTaken(timeLeft));
            dispatch(loader(false));
            setisSubmitting(false);
          })
          .catch((err) => console.log(err));
      } else {
        dispatch(loader(false));
        setisSubmitting(false);
      }
    }
  };

  useEffect(() => {
    console.log("end test 1655", timeLeft, props);
    if (
      timeLeft == 0 &&
      props?.assessmentData?.isTimeBoxed &&
      timeLeft != null
    ) {
      console.log("end test 1655", props?.assessmentData);
      setShowTimerPopup(true);
      setTimeout(() => {
        SignatureSubmit();
      }, 10000);
    }
    // console.log(timeLeft, "end test 1655", props?.assessmentData);
  }, [timeLeft]);

  const navigate = useNavigate();
  const SignatureSubmit = (data) => {
    let attempt = attemptdata;
    if (Object.keys(attemptdata).length === 0) {
      console.log("Object is empty");
      attempt = {
        attempt_id: props?.attempid,
        status: "INPROGRESS",
        answers: answers,
        assessment_version_id: props?.assessment_version_id,
        userId: authContext.sessionInfo.sub,
      };
    }
    if (data !== undefined) attempt.user_signature = data;
    console.log(data, attempt, "signature console 165");
    UpdateAssessmentAttemptApi(attempt).then((res) => {
      console.log(showTimerPopup, "end 1655");
      navigate(AppUrlsEnum.learnermyassessment, {
        state: { completed: "true" },
      });
    });
  };

  return (
    <Container>
      <Row className="d-flex justify-content-center">
        {props?.assessmentData?.show_assessment_signature ? (
          <Signature
            show={showPopup}
            onHide={() => setShowPopup(false)}
            // header="Electronic Signature"
            // subHeader={props?.assessmentData?.title}
            header={
              meta_data?.signature_title
                ? meta_data?.signature_title
                : "Electronic Signature"
            }
            subHeader={
              meta_data?.signature_description
                ? meta_data?.signature_description
                : genieLabsAssessmentTranslations?.signaturSubHeader
            }
            signature={`${authContext.sessionInfo.firstName} ${authContext.sessionInfo.lastName}`}
            date={moment(new Date()).format("MM/DD/YYYY")}
            signatureDisabled={false}
            dateDisabled={false}
            submit={SignatureSubmit}
          />
        ) : (
          <PopupQuestion
            closeOnBackClick={false}
            showPopup={showPopup}
            type="popup"
            title={"Assessment Completed"}
            submitText={"OK"}
            modalBody={"Assessment Submited Successfully"}
            gotIt={() =>
              navigate(AppUrlsEnum.learnermyassessment, {
                state: { completed: "true" },
              })
            }
          />
        )}
        <PopupQuestion
          closeOnBackClick={() =>
            navigate(AppUrlsEnum.learnermyassessment, {
              state: { completed: "true" },
            })
          }
          showPopup={showTimerPopup}
          type="popup"
          title={"TIme is Up"}
          submitText={"OK"}
          hideSubmitButton={true}
          modalBody={"Assessment Submited Successfully "}
          gotIt={() =>
            navigate(AppUrlsEnum.learnermyassessment, {
              state: { completed: "true" },
            })
          }
        />
        <Col md={11}>
          <Row>
            <Col>
              <TestQuestionComponent
                text={props?.question?.question_text}
                queNo={currentQuestion + 1}
              />
            </Col>
          </Row>
          <Row>
            <Form onSubmit={handleSubmit(onNextClick)} onKeyDown={(e) => checkKeyDown(e)}  className="mb-3">
              <Row className="test-answer-block p-4 mt-2">
                <Col className="select-option d-flex mb-4" md={5}>
                  <Controller
                    name={"answerOption"}
                    control={control}
                    render={({ field: { onChange, onBlur, value, name } }) => (
                      <TextQuestionAnswerComponent
                        hookOnBlur={onBlur}
                        hookValue={value}
                        name={name}
                        hookOnChange={onChange}
                        textPlaceHolder="text"
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <FormErrorComponent>
                  {errors?.answerOption && errors?.answerOption?.message}
                </FormErrorComponent>
              </Row>
              <Row>
                <QFooter
                  showPrevious={
                    props?.assessmentData?.isReverseAllowed !== undefined
                      ? currentQuestion == 0
                        ? false
                        : props?.assessmentData?.isReverseAllowed
                      : currentQuestion == 0
                      ? false
                      : true
                  }
                  onPreviousClick={previousClick}
                  onCheckClick={console.log("Check Btn click")}
                  onMarkClick={onMarkClick}
                  // showMark={ props?.assessmentData?.isMarketingAllowed == undefined || !props?.assessmentData?.isMarketingAllowed ? false:true}
                  nextText={
                    props?.totalQuestion !== currentQuestion + 1
                      ? genieLabsAssessmentTranslations?.next
                      : genieLabsAssessmentTranslations?.submit
                  }
                  onNextClick={() => {
                    clearErrors("answerOption");
                    handleSubmit(onNextClick);
                  }}
                  isSubmitting={isSubmitting}
                />
              </Row>
            </Form>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default TextQuestion;
