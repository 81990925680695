import * as yup from "yup";

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&!$])[a-zA-Z0-9!@#%&!$]+$/;

export function authResetPasswordForm(formErrors) {
  return yup.object({
    newPassword: yup
      .string()
      .required(formErrors?.newPasswordSecurity)
      .min(5, formErrors?.passwordReq)
      .matches(passwordRegex, formErrors?.passwordReq),
    repeatPassword: yup
      .string()
      .required(formErrors?.repeatPassword)
      .oneOf([yup.ref("newPassword"), null], formErrors?.confirmPassword),
  });
}
