import * as yup from "yup";
const phoneNumberRegEx =
  /^((\+[1-9]{1,4})(([0-9]{2,3})[-]*)([0-9]{2,4})[-]*)*?[0-9]{3,4}?[-]*[0-9]{3,4}?$/;
export function getLoginFormTranslated(formErrors) {
  return yup.object({
    username: yup
      .string()
      .required("Mobile or Email is required")
      .test("username", "Mobile or Email is invalid", (value) => {
        return validateEmail(value) || validatePhone(value ?? "0");
      }),
    password: yup
      .string()
      .required(formErrors?.password)
      .typeError(formErrors?.password),
  });
}

const validateEmail = (email) => {
  return yup.string().email().isValidSync(email);
};

const validatePhone = (phone) => {
  return yup
    .string()
    .min(4, "required")
    .max(16, "required")
    .required("required")
    .matches(phoneNumberRegEx, "Mobile number Required")
    .isValidSync(phone);
};
