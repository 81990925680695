//** Iguroo SelfAssessment,Yes or No TypeRadio Button QuestionComponent,can re-use required field **//

import React from "react";
import { Form } from "react-bootstrap";
import "./styles.scss";

function YesNoRadioBtn({
  title,
  id,
  name,
  value,
  className,
  onBlur,
  onChange,
  defaultChecked,
  hookValue,
  ref,
}) {
  return (
    <label key={`inline-radio`} className={"ig-yesno-radio " + className}>
      <Form.Check
        type="radio"
        id={id}
        className={"d-flex align-items-center ms-1"}
      >
        <Form.Check.Input
          type={"radio"}
          // isValid
          className=" mt-0"
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          //  checked={value == hookValue}
          {...(defaultChecked && { defaultChecked: defaultChecked })}
          ref={ref}
        />
        <Form.Check.Label
          for={id}
          className="flex-fill ps-2 mb-0 d-flex align-items-center"
        >
          <h3>{title}</h3>
        </Form.Check.Label>
      </Form.Check>
    </label>
  );
}

export default YesNoRadioBtn;
