//** GinieHealth Publisher,CreateNewAssessment of Logic&ReportBuilder component UI code **//

import { Button, Col, Card, Row, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { AppUrlsEnum } from "../../enums/app.url.enums";
import { logicBuilderValidation } from "../../FormSchemas/publisher.form";
import CustomAnswerOptions from "../assessment/components/customradioOption";
import { CustomInputField } from "../CustomInput/customInputField";
import { FormErrorComponent } from "../form.error.component";
import { CustomSelect } from "../CustomInput/custom.select";
import AssessmentSideBar from "./AssessmentSideBar";
import "./styles.css";
import {
  AssessmentDetailsDataSelector,
  addDataToAssessmentDetails,
  createAssessmentAsyncThunk,
  tempCreateAssessmentSelector,
} from "../../redux-tk/slices/assessment.slice";
import { getAssessmentObject } from "../../common/DTOS/assessment.dtos";
import { useAssessmentDetailFetch } from "../../hooks/useAssessmentDetailFetch";
import { CustomCheckButton } from "../CustomInput/custom.switch";

const options1 = [
  {
    text: "Assessment Template",
    value: "Assessment Template",
  },
  {
    text: "Custom Assessment Logic",
    value: "Custom Assessment Logic",
  },
];

const options2 = [
  {
    text: "Transcript Template",
    value: "Transcript Template",
  },
  {
    text: "Custom Report Logic",
    value: "Custom Report Logic",
  },
];

const templates = [
  {
    value: "Multiple Choice",
    data: "STANDARD",
  },
  {
    value: "Multi Perspective",
    data: "GENIE_MULTI_PERSPECTIVE",
  },
];

const LogicAndReportBuilder = () => {
  const { formElementLabels, assessmentsTranslations, formErrors } =
    useIgurooTranslations();
  const tempCreateDataValue = useSelector((state) =>
    tempCreateAssessmentSelector(state)
  );
  const {
    control,
    handleSubmit,
    getValues,
    watch,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      ...tempCreateDataValue.apiData,
    },
    mode: "onSubmit",
    resolver: yupResolver(logicBuilderValidation(formErrors)),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const assessmentDetailsData = useSelector((state) =>
    AssessmentDetailsDataSelector(state)
  );
  const params = useAssessmentDetailFetch({
    assessmentId: searchParams?.get("assessmentId"),
    versionId: searchParams?.get("versionId"),
  });
  const submit = async (data) => {
    let finalData = { ...assessmentDetailsData, transcript_download: false };

    const ids = {
      versionId: searchParams.get("versionId"),
      assessmentId: searchParams.get("assessmentId"),
    };
    try {
      const assessmentObj = getAssessmentObject(data);
      finalData = { ...finalData, ...assessmentObj };
      const res = await dispatch(createAssessmentAsyncThunk(finalData));
      dispatch(addDataToAssessmentDetails(finalData));
      if (ids.assessmentId && ids.versionId) {
        if (res?.payload) {
          navigate(
            `${AppUrlsEnum.certification}?versionId=${ids.versionId}&assessmentId=${ids.assessmentId}`
          );
        }
      } else {
        if (res?.payload) {
          navigate(AppUrlsEnum.certification);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const customlogic = watch("custom_logic");
  const customreport = watch("custom_report");
  const transcript = watch("transcript");
  const assessment = watch("assessment_template");

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (tempCreateDataValue?.apiData?.assessment_id) {
      reset({ ...tempCreateDataValue?.apiData });
      if (tempCreateDataValue?.apiData?.evaluation_type) {
        setValue("assessment_template", "Assessment Template");
      }
      if (tempCreateDataValue?.apiData?.transcript_template) {
        setValue("transcript", "Transcript Template");
      }
    }
  }, [tempCreateDataValue?.apiData?.assessment_id]);

  return (
    <div className="p-2 logicContainer">
      <h4 className="text-center p-2">
        {assessmentsTranslations?.logicAndReportBuilder}
      </h4>
      <Row className="d-flex justify-content-center">
        <Col md={6}>
          <Card className="p-4 border-0 shadow">
            <Form onSubmit={handleSubmit(submit)}>
              <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                Assessment Logic & Report Builder
              </p>
              <Row>
                <Col className="radio-container">
                  <Template
                    name1={"assessment_template"}
                    name2={"evaluation_type"}
                    error={errors?.assessment_template?.message}
                    error2={errors?.evaluation_type?.message}
                    options={[{ ...options1[0] }]}
                    selectData={templates}
                    control={control}
                    disabled={
                      getValues("assessment_template") !== "Assessment Template"
                    }
                  />
                  <CustomLogic
                    name1={"custom_logic"}
                    name2={"custom_logic_value"}
                    error={errors?.custom_logic?.message}
                    error2={errors?.custom_logic_value?.message}
                    options={[{ ...options1[1] }]}
                    selectData={templates}
                    control={control}
                    disabled={
                      getValues("custom_logic") !== "Custom Assessment Logic"
                    }
                  />
                </Col>
                <Col md={2}></Col>
                <Col className="radio-container">
                  <Template
                    name1={"transcript"}
                    name2={"transcript_template"}
                    error={errors?.transcript?.message}
                    error2={errors?.transcript_template?.message}
                    options={[{ ...options2[0] }]}
                    selectData={templates}
                    control={control}
                    disabled={getValues("transcript") !== "Transcript Template"}
                  />
                  <CustomLogic
                    name1={"custom_report"}
                    name2={"custom_report_value"}
                    error={errors?.custom_report?.message}
                    error2={errors?.custom_report_value?.message}
                    options={[{ ...options2[1] }]}
                    selectData={templates}
                    control={control}
                    disabled={
                      getValues("custom_report") !== "Custom Report Logic"
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="radio-container"></Col>
                <Col md={2}></Col>
                <Col className="radio-container">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value, name } }) => {
                      return (
                        <CustomCheckButton
                          hookOnChange={onChange}
                          hookOnBlur={onBlur}
                          hookValue={value}
                          name={name}
                          type="checkbox"
                          label="Enable Transcript Download"
                          defaultChecked={false}
                          checked={value}
                        />
                      );
                    }}
                    name={"transcript_download"}
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Button type="submit" className="nextBtn">
                  {formElementLabels?.nextBtn}
                </Button>
              </div>
            </Form>
          </Card>
        </Col>
        <AssessmentSideBar
          data={assessmentsTranslations?.assessmentProgressView}
          stage={4}
          title={assessmentsTranslations?.assessmentDetails}
          btnValue={formElementLabels?.publishBtn}
          currentTab={
            assessmentsTranslations?.assessmentProgressView
              ?.logicAndReportBuilder
          }
        />
      </Row>
    </div>
  );
};

export default LogicAndReportBuilder;

const Template = ({
  name1,
  name2,
  error,
  options,
  selectData,
  control,
  disabled,
  error2,
}) => {
  console.log(error, "error");
  return (
    <>
      <Controller
        name={name1}
        control={control}
        render={({ field: { onChange, onBlur, value, name } }) => (
          <CustomAnswerOptions
            type={"radio"}
            options={options}
            hookOnChange={onChange}
            hookOnBlur={onBlur}
            hookValue={value}
            name={name}
          />
        )}
      />

      <div className="input-container mt-1">
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <CustomSelect
              className="selectStyle"
              opLabel="value"
              opValue="data"
              items={selectData}
              onReactHookFormBlur={onBlur}
              onReactHookFormChange={onChange}
              value={value}
              isLoading={false}
              placeholder={"Select"}
              isDisabled={disabled}
              name={name}
            />
          )}
          name={name2}
        />
      </div>
      {error && <FormErrorComponent>{error}</FormErrorComponent>}
      {!error && error2 && <FormErrorComponent>{error2}</FormErrorComponent>}
    </>
  );
};

const CustomLogic = ({
  name1,
  name2,
  error,
  options,
  control,
  disabled,
  error2,
}) => {
  return (
    <>
      <Controller
        name={name1}
        control={control}
        render={({ field: { onChange, onBlur, value, name } }) => (
          <CustomAnswerOptions
            type={"radio"}
            options={options}
            hookOnChange={onChange}
            hookOnBlur={onBlur}
            hookValue={value}
            name={name}
          />
        )}
      />

      <div className="input-container mt-1">
        <Controller
          name={name2}
          control={control}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <CustomInputField
              hookOnBlur={onBlur}
              hookValue={value}
              disabled={disabled}
              hookOnChange={onChange}
              name={name}
            />
          )}
        />
        {error && <FormErrorComponent>{error}</FormErrorComponent>}
        {!error && error2 && <FormErrorComponent>{error2}</FormErrorComponent>}
      </div>
    </>
  );
};
