import * as yup from "yup";
export function getWorkExpFormTranslated(formErrors) {
  return yup.object({
    workExp: yup.array().of(
      yup.object({
        id: yup.string().optional(),
        role: yup
          .string()
          .required(formErrors?.roleIsRequired)
          .matches(/^[a-zA-Z0-9\s&,().-]+$/, formErrors?.nameNumSplCharcError),
        company: yup
          .string()
          .required(formErrors?.companyIsRequired)
          .matches(/^[a-zA-Z0-9\s&,().-]+$/, formErrors?.nameNumSplCharcError),
        year: yup
          .number(formErrors?.yearIsRequired)
          .typeError(formErrors?.yearIsRequired)
          .positive(formErrors?.cannotBeNegative)
          .required(formErrors?.yearIsRequired)
          .max(99, "Years of experience should be less than 100"),
      })
    ),
  });
}
