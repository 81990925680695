//** Iguroo Mentee & Mentor,BookMark UserCard Popover,can re-use in Header or in Component**//

import React, { useEffect, useState } from "react";
import { _getImage } from "../../common/utils/commonFunctions";
import { ImgSrcEnums } from "../../enums";
import { CustomImage } from "../Media";
import { Col, ListGroup, Row } from "react-bootstrap";
import { GetTotalSessions } from "../../services/axios/Dasboard";
import Rating from "react-rating";
import { getRating } from "../../services/axios/session";
import { IconLabelComponent } from "../cardComponent/icon.label";

function BookmarkUserCard({ className, bookmarkMentor, onClick }) {
  const [session, setSession] = useState(0);
  const [rating, setRating] = useState(0);
  const [title, setTitle] = useState("");

  useEffect(() => {
    (async function () {
      if (bookmarkMentor) {
        await GetTotalSessions(bookmarkMentor._source.userId)
          .then((resp) => {
            setSession(resp);
          })
          .catch((err) => console.log(err, "callstart session err"));
        await getRating(bookmarkMentor._source.userId)
          .then((resp) => {
            setRating(resp?.data?.response);
          })
          .catch((err) => console.log(err, "callstart rate err"));
        const titleName =
          bookmarkMentor._source.firstName +
          " " +
          bookmarkMentor._source.lastName;
        setTitle(titleName);
      }
    })();
  }, [bookmarkMentor]);

  return (
    <ListGroup.Item className="m-1 py-0 px-1">
      <div onClick={onClick}>
        <Row className="p-1">
          <Col sm={4} className="">
            {" "}
            <CustomImage
              width={"100%"}
              roundedCircle={false}
              resizeMode={"contain"}
              className={className ? className : ""}
              crossOrigin="anonymous"
              src={{
                src: bookmarkMentor._source?.profilePic
                  ? bookmarkMentor._source?.profilePic
                  : _getImage("avatar.svg"),
                type: bookmarkMentor._source?.profilePic
                  ? ImgSrcEnums.props
                  : ImgSrcEnums.normal,
              }}
            />
          </Col>

          <Col className="info">
            <h4 className={`${className ? className : ""} ig-bookmark-name`}>
              {title}
            </h4>

            <Row className="mb-3 mx-auto">
              <Col className="px-0 mt-2 d-flex justify-content-between">
                <Rating
                  placeholderRating={rating ? rating : 0}
                  readonly={true}
                  emptySymbol={
                    <img
                      height={20}
                      width={20}
                      src={_getImage("empty_star.svg")}
                      className="icon"
                    />
                  }
                  placeholderSymbol={
                    <img
                      height={20}
                      width={20}
                      src={_getImage("full_star.svg")}
                      className="icon"
                    />
                  }
                  fullSymbol={
                    <img
                      height={20}
                      width={20}
                      src={_getImage("full_star.svg")}
                      className="icon"
                    />
                  }
                />
                <IconLabelComponent
                  svgName={"hours.svg"}
                  className="rating-hours"
                >
                  {session ? `${session}` : "0"}
                </IconLabelComponent>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </ListGroup.Item>
  );
}

export default BookmarkUserCard;
