//** Iguroo Assessment,Sortable SingleItem Example Component **//
//** Currently not using this,will be implement in future enhancement**//

import React, { useState } from "react";
import { SingleItemDropTarget } from "./SingleItemDrop";

export const SingleItemExample = ({
  itemDropped,
  itemIndex,
  initialItem = null,
}) => {
  const [item, setItem] = useState(initialItem);

  const handleItemDropped = (droppedItem) => {
    console.log(
      "SortableQuestion singledrop items is ",
      droppedItem,
      "current item is",
      item
    );
    if (droppedItem?.id === item?.id) {
      return;
    }
    setItem(droppedItem);
    if (typeof itemDropped === "function") {
      itemDropped(droppedItem, itemIndex);
    }
  };

  return (
    <div>
      <SingleItemDropTarget
        item={item}
        setItem={setItem}
        onItemDropped={handleItemDropped}
      />
    </div>
  );
};
