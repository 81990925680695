import {axiosStaticData } from "../axios.service";
import { SEARCH_REQUESTS } from "../../urlConst";

export const searchRequests = async ({
  category,
  status,
  searchText,
  mentorId,
  userId,
  startRow,
  endRow,
  unique,
  isMentor
}) => {
  console.log("err", isMentor)
  try {
    const obj = {
      ...(category && { category: category }),
      ...(status && { status: status }),
      ...(searchText && { searchText: searchText }),
      ...(mentorId && { mentorId: mentorId }),
      ...(userId && { userId: userId }),
      ...(startRow && { from: startRow }),
      ...(endRow && { size: endRow }),
      ...(unique&& {unique: unique}),
      isMentor:  isMentor ?  isMentor:false
};
    const res = await axiosStaticData.post(SEARCH_REQUESTS, obj);
    return res.data.response;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

