//** Iguroo Assessment Slice **//

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CreateOrUpdateAssessmentApi,
  GetAssessmentsApi,
  GetAssessmentByUserIdApi,
} from "@iguroo/ig-react-assessment-package";
import { getAssessmentData } from "../../services/axios/Assessment/assessment";

export const createAssessmentAsyncThunk = createAsyncThunk(
  "assessment/createAssessmentAsyncThunk",
  async (data, thunkApi) => {
    try {
      //console.log("fetching image thunk",data);
      const assessment = await CreateOrUpdateAssessmentApi(data);
      console.log("createAssessmentAsyncThunk", assessment);
      return {
        apiData: assessment.response.data[0],
        editorState:
          data?.assessment_metadata?.basic_details?.description?.details,
      };
    } catch (err) {
      console.log("api error", err);
      thunkApi.rejectWithValue(err);
    }
  }
);

export const getMyAssessmentByIdThunk = createAsyncThunk(
  "assessment/getMyAssessmentByIdThunk",
  async (data, thunkApi) => {
    try {
      const myAssessment = await GetAssessmentsApi(data);
      console.log("my Assessment by id", myAssessment.response.data[0]);
      return myAssessment.response.data[0];
    } catch (err) {
      console.log(err);
      thunkApi.rejectWithValue(err);
    }
  }
);

export const getMyAssessmentThunk = createAsyncThunk(
  "assessment/getMyAssessmentThunk",
  async (data, thunkApi) => {
    try {

      const myAssessment = await getAssessmentData(data);
      console.log("myAssessment",myAssessment)
      return myAssessment.data.response;
    } catch (err) {
      console.log(err);
      thunkApi.rejectWithValue(err);
    }
  }
);

const initialState = {
  tempCreatingAssessment: {},
  assessmentDetailsData: {},
  tempAssesmentDescription: null,
  tempAssesmentInfo: `{\"content\":\"<p>1) This test has limited attempts.</p>\\n<p>2) There is no negative marking in this test.</p>\\n<p>3) The test would automatically submit once the time is up.</p>\\n<p>4) The test will pause if you navigate elsewhere.</p>\\n<p>5) The answer will auto-save periodically.</p>\\n<p></p>\\n\"}`,
  tempAssesmentIntendedLearners: null,
  myAssessment: {},
  assessmentApiData: {},
};

export const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    addDataToAssessmentDetails: (state, action) => {
      state.assessmentDetailsData = { ...action.payload };
      state.tempCreatingAssessment = { ...action.payload };
      if (action.payload?.assessment_metadata?.basic_details) {
        state.tempAssesmentDescription = action.payload?.assessment_metadata
          ?.basic_details?.description?.details
          ? action.payload?.assessment_metadata?.basic_details?.description
              ?.details
          : null;
      }
      if (action.payload?.assessment_metadata?.intended_learners) {
        state.tempAssesmentIntendedLearners = {
          what_will_you_learn:
            action.payload?.assessment_metadata?.intended_learners
              ?.what_will_you_learn?.details,
          assessment_for:
            action.payload?.assessment_metadata?.intended_learners
              ?.assessment_for?.details,
          prerequisite:
            action.payload?.assessment_metadata?.intended_learners?.prerequisite
              ?.details,
          table_of_content:
            action.payload?.assessment_metadata?.intended_learners
              ?.table_of_content?.details,
        };
      }
    },
    removeDataFromAssessmentDetails: (state, action) => {
      state.assessmentDetailsData = {};
      state.tempCreatingAssessment = {};
      state.tempAssesmentDescription = null;
      state.tempAssesmentIntendedLearners = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAssessmentAsyncThunk.fulfilled, (state, action) => {
        console.log("ac us'", action);
        if (!action?.payload) {
          return;
        }
        state.tempCreatingAssessment = action.payload.apiData;
        state.tempAssesmentDescription = action.payload.editorState;
      })
      .addCase(createAssessmentAsyncThunk.pending, (state) => {})
      .addCase(createAssessmentAsyncThunk.rejected, (state) => {})
      .addCase(getMyAssessmentThunk.fulfilled, (state, action) => {
        if (!action?.payload) {
          return;
        }
        state.myAssessment = action.payload;
      })
      .addCase(getMyAssessmentThunk.pending, (state) => {})
      .addCase(getMyAssessmentThunk.rejected, (state) => {})
      .addCase(getMyAssessmentByIdThunk.fulfilled, (state, action) => {
        //console.log("ac us'", action);
        if (!action?.payload) {
          return;
        }
        let apiRes = action.payload;
        delete apiRes?.createdAt;
        delete apiRes?.updatedAt;
        delete apiRes?.supervisor_id;
        delete apiRes?.created_by;
        delete apiRes?.created_by;
        delete apiRes?.isEnable;
        delete apiRes?.author_id;
        delete apiRes?.updated_by;
        delete apiRes?.publisher_id;
        delete apiRes?.assessment_template;
        delete apiRes?.assessment_template_value;
        delete apiRes?.transcript_template_value;
        state.assessmentApiData = apiRes;
        state.tempAssesmentIntendedLearners = {
          what_will_you_learn:
            action.payload?.assessment_metadata[1]?.what_will_you_learn
              ?.details,
          assessment_for:
            action.payload?.assessment_metadata[1]?.assessment_for?.details,
          prerequisite:
            action.payload?.assessment_metadata[1]?.prerequisite?.details,
          table_of_content:
            action.payload?.assessment_metadata[1]?.table_of_content?.details,
        };
        state.tempCreatingAssessment = apiRes;
        state.tempAssesmentDescription =
          action.payload?.assessment_metadata[0]?.description?.details;
        state.tempAssesmentInfo = action.payload?.assessment_info;
        apiRes.assessment_metadata = {
          basic_details: {
            description: {
              details:
                action.payload?.assessment_metadata[0]?.description?.details,
            },
          },
          intended_learners: {
            what_will_you_learn:
              action.payload?.assessment_metadata[1]?.what_will_you_learn
                ?.details,
            assessment_for:
              action.payload?.assessment_metadata[1]?.assessment_for?.details,
            prerequisite:
              action.payload?.assessment_metadata[1]?.prerequisite?.details,
            table_of_content:
              action.payload?.assessment_metadata[1]?.table_of_content?.details,
          },
        };
        state.assessmentDetailsData = apiRes;
      })
      .addCase(getMyAssessmentByIdThunk.pending, (state) => {})
      .addCase(getMyAssessmentByIdThunk.rejected, (state) => {});
  },
});

export const { addDataToAssessmentDetails, removeDataFromAssessmentDetails } =
  assessmentSlice.actions;

export const AssessmentDetailsDataSelector = (state) =>
  state.assessment.assessmentDetailsData;
export const tempCreateAssessmentSelector = (state) => {
  return {
    apiData: {
      ...state.assessment.tempCreatingAssessment,
    },
    editorState: state.assessment.tempAssesmentDescription,
    assessmentInfo: state.assessment.tempAssesmentInfo,
    intendedLearners: state.assessment.tempAssesmentIntendedLearners,
  };
};

export const myAssessmentData = (state) => state.assessment.myAssessment;
export const assessmentApiDataSelector = (state) =>
  state.assessment.assessmentApiData;

export default assessmentSlice.reducer;
