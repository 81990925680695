//** Iguroo Assessment,Sortable Example Component **//
//** Currently not using this,will be implement in future enhancement**//

import { useCallback, useState } from "react";
import { SortableList } from "./SortableList";
import { SortableItem } from "./SortableItem";

export const SortableExample = () => {
  const [cards, setCards] = useState([
    {
      id: 1,
      text: "Write a cool JS library",
      name: "Write a cool JS library",
    },
    {
      id: 2,
      text: "Make it generic enough",
      name: "Make it generic enough",
    },
    {
      id: 3,
      text: "Write README",
      name: "Write README",
    },
    {
      id: 4,
      text: "Create some examples",
      name: "Create some examples",
    },
    {
      id: 5,
      text: "Spam in Twitter and IRC to promote it (note that this element is taller than the others)",
      name: "Spam in Twitter and IRC to promote it (note that this element is taller than the others)",
    },
    {
      id: 6,
      text: "???",
      name: "???",
    },
    {
      id: 7,
      text: "PROFIT",
      name: "PROFIT",
    },
  ]);
  const dndFinished = useCallback((updatedCards) => {
    console.log("dnd done", updatedCards);
  }, []);
  return (
    <SortableList
      items={cards}
      setItems={setCards}
      dndDone={dndFinished}
      SortableItem={SortableItem}
    />
  );
};
