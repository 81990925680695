//** GinieHealth ExamAttempt,ExamInfo Slice **//

import { UpdateAssessmentAttemptApi } from "@iguroo/ig-react-assessment-package";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const createAssessmentAttemptAsyncThunk = createAsyncThunk(
  "assessment/createAssessmentAttemptAsyncThunk",
  async (data, thunkApi) => {
    try {
      // console.log("fetching image thunk", data);
      const assessment = await UpdateAssessmentAttemptApi(data);
      // console.log("createAssessmentAttemptAsyncThunk", assessment);
      return assessment.response.data;
    } catch (err) {
      console.log("api error", err);
      thunkApi.rejectWithValue(err);
    }
  }
);

const initialState = {
  answers: [],
  attempt_id: "",
  timeLeft: "",
  timeTaken: 0,
};

export const glexamInfoSlice = createSlice({
  name: "GLExamInfo",
  initialState,
  reducers: {
    changeGLCurrentQuestion: (state, action) => {
      // console.log(
      //   "console question submit action changeGLCurrentQuestion",
      //   state,
      //   action
      // );
      state.currentQuestion = action.payload;
    },
    changeTimeTaken: (state, action) => {
      // console.log(
      //   "console question submit action changeGLCurrentQuestion",
      //   state,
      //   action
      // );
      state.timeTaken = action.payload;
    },
    changeGLTime: (state, action) => {
      // console.log(
      //   "console question submit action changeGLCurrentQuestion",
      //   state,
      //   action
      // );
      state.timeLeft = action.payload;
    },
    answerSelected: (state, action) => {
      // console.log("console question submit action ", state, action);
      state.answers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAssessmentAttemptAsyncThunk.fulfilled, (state, action) => {
        console.log("ac us' attempt", action);
        const data = action?.payload;
        // console.log(
        //   "ac us createAssessmentAttemptAsyncThunk 204",
        //   action,
        //   state,
        //   data
        // );
        const status = action?.payload?.status;
        const current = action?.payload?.answers.length;
        const currentQues = state?.glexam?.currentQuestion;
        const attempt_id = state?.glexam?.attempt_id;
         console.log("ac us saving in 204 console currentQuestion 204 assessment attempidattempid", state, data,current,currentQues,attempt_id);
        if (status === "INPROGRESS" && currentQues == undefined) {
          state.currentQuestion = 0;
          state.answers = [];
        }
        if (!data) return;
        if (data.answers.length > 0) {
          const ItemsLength = data?.answers?.length;
          const last = data?.answers[ItemsLength - 1];
          const answer = last?.answer;
          const ansLen = answer?.length;
          const lastAns = answer[ansLen - 1];
          const completeExam = action?.payload?.status;
          // console.log("ac us saving in 204 console currentQuestion 204", state, current,currentQues,attempt_id);
          if (completeExam === "INPROGRESS") {
            state.currentQuestion = ItemsLength;
            state.attempt_id = action.payload.attempt_id;
            state.answers = action.payload.answers;
          }
        }
        if (!action?.payload) {
          return;
        }
        state.attempt_id = action.payload.attempt_id;
      })
      .addCase(createAssessmentAttemptAsyncThunk.pending, (state) => {})
      .addCase(createAssessmentAttemptAsyncThunk.rejected, (state) => {});
  },
});

export const {
  changeGLCurrentQuestion,
  answerSelected,
  changeGLTime,
  changeTimeTaken,
} = glexamInfoSlice.actions;

export const answerValue = (state) => state.glexam.answers;

export default glexamInfoSlice.reducer;

export const currentGLQuestionValue = (state) => {
  console.log(state, "console state   204");
  return state?.glexam?.currentQuestion != undefined
    ? state?.glexam?.currentQuestion
    : 0;
};
export const AttemptIdValue = (state) => {
  console.log(state, "console state 204");
  return state?.glexam?.attempt_id ? state?.glexam?.attempt_id : "0";
};

export const TimeleftValue = (state) => {
  console.log(state, "console state 204");
  return state?.glexam?.timeLeft != "" ? state?.glexam?.timeLeft : "0";
};
export const TimeTakenValue = (state) => {
  // console.log(state, "console state 204");
  return state?.glexam?.timeTaken ? state?.glexam?.timeTaken : 0;
};
