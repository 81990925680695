//** Key and Value of Dashboard Translations -> Check with Value name in en.language.json **//

export function DashboardTranslations(t) {
  return {
    key: "dashboardTranslations",
    value: {
      bookSession: t("dashboardTranslations.bookSession"),
      mentorOption: t("dashboardTranslations.mentorOption"),
      selectDate: t("dashboardTranslations.selectDate"),
      selectTime: t("dashboardTranslations.selectTime"),
      describeRequest: t("dashboardTranslations.describeRequest"),
      nameRequest: t("dashboardTranslations.nameRequest"),
      describeInterest: t("dashboardTranslations.describeInterest"),
      selectInterest: t("dashboardTranslations.selectInterest"),
      describeGoals: t("dashboardTranslations.describeGoals"),
      selectGoals: t("dashboardTranslations.selectGoals"),
      describeDescription: t("dashboardTranslations.describeDescription"),
      myRequest: t("dashboardTranslations.myRequest"),
      createRequest: t("dashboardTranslations.createRequest"),
      menteeDbPage: t("dashboardTranslations.menteeDbPage"),
      mentorDbPage: t("dashboardTranslations.mentorDbPage"),
      mentorMessagePage: t("dashboardTranslations.mentorMessagePage"),
      mentorMentees: t("dashboardTranslations.mentorMentees"),
      noPayments: t("dashboardTranslations.noPayments"),
      addPayments: t("dashboardTranslations.addPayments"),
      cardNumber: t("dashboardTranslations.cardNumber"),
      securityCode: t("dashboardTranslations.securityCode"),
      addCode: t("dashboardTranslations.addCode"),
      expiresOn: t("dashboardTranslations.expiresOn"),
      firstName: t("dashboardTranslations.firstName"),
      lastName: t("dashboardTranslations.lastName"),
      accountSettings: t("dashboardTranslations.accountSettings"),
      security: t("dashboardTranslations.security"),
      assessmentLanguage: t("dashboardTranslations.assessmentLanguage"),
      payments: t("dashboardTranslations.payments"),
      billing: t("dashboardTranslations.billing"),
      youtubeVideo: t("dashboardTranslations.youtubeVideo"),
      myAvailability: t("dashboardTranslations.myAvailability"),
      workExp: t("dashboardTranslations.workExp"),
      working: t("dashboardTranslations.working"),
      education: t("dashboardTranslations.education"),
      bookMark: t("dashboardTranslations.bookMark"),
      bookmarked: t("dashboardTranslations.bookmarked"),
      share: t("dashboardTranslations.share"),
      sendMessage: t("dashboardTranslations.sendMessage"),
      bookNow: t("dashboardTranslations.bookNow"),
      about: t("dashboardTranslations.about"),
      yearsMentoring: t("dashboardTranslations.yearsMentoring"),
      sessionHeld: t("dashboardTranslations.sessionHeld"),
      likes: t("dashboardTranslations.likes"),
      skills: t("dashboardTranslations.skills"),
      additionalComments: t("dashboardTranslations.additionalComments"),
      test: t("dashboardTranslations.test"),
      testList: t("dashboardTranslations.testList"),
      testName: t("dashboardTranslations.testName"),
      testCard: t("dashboardTranslations.testCard"),
      startTest: t("dashboardTranslations.startTest"),
      feedBack: t("dashboardTranslations.feedBack"),
      rateMentor: t("dashboardTranslations.rateMentor"),
      rateSession: t("dashboardTranslations.rateSession"),
      howItRate: t("dashboardTranslations.howItRate"),
      pageNotFound: t("dashboardTranslations.pageNotFound"),
      myRates: t("dashboardTranslations.myRates"),
      bookingPopUp: t("dashboardTranslations.bookingPopUp"),
      myProfile: t("dashboardTranslations.myProfile"),
      joinDelay: t("dashboardTranslations.joinDelay"),
      emptyCalendarSessions: t("dashboardTranslations.emptyCalendarSessions"),
      contactPopup: t("dashboardTranslations.contactPopup"),
      testimonialDesc: t("dashboardTranslations.testimonialDesc"),
      testimonialHeading: t("dashboardTranslations.testimonialHeading"),
    },
  };
}
