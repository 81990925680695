import { axiosAwsIam } from "../axios.service";
import axios from "axios";
import { CONTACT_US } from "../../urlConst";

export const contactUs = async (data) => {
  const payload = {
    firstName: data.firstname,
    lastName: data.lastname,
    mobile: data.mobileNumber,
    email: data.email,
    message: data.aboutMessage,
  };
  const res = await axiosAwsIam.post(CONTACT_US, payload, {
    isPublicProfile: true,
  });
  return res.data;
};
