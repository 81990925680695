//** Iguroo -> Completed Live Session,SessionSummmary Rating Notes Popup **//

import React, { useEffect, useState } from "react";
import { Modal, Button, Container, Form, Row } from "react-bootstrap";
import "./styles.scss";
import { FormLabelComponent } from "../form.label.component";
import { useForm, Controller } from "react-hook-form";
import { CustomInputField } from "../CustomInput/customInputField";
import { _getImage } from "../../common/utils/commonFunctions";
import { useSelector } from "react-redux";
import { ProfileType } from "../../enums";
require(`../../css/${process.env.REACT_APP_CSS_COMMON}`);

function SessionSummaryNotes(props) {
  const [questions, setQuestions] = useState({
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    q5: 0,
  });
  const user = useSelector((state) => state.user.profile);
  const { show, onHide, menteeNote, mentorNote, prbStatement, recommGuidance } =
    props;
  const { control } = useForm({});

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    // console.log(questions, "callstart");
    const isEmpty = Object.values(questions).some((x) => {
      // console.log(x, "callstart questions");
      return x == "" || x == 0;
    });
    // console.log(isEmpty, checkProperties(questions), "callstart");
    if (!isEmpty) setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
  }, [questions]);

  const handleClose = (e) => {
    onHide(false);
  };

  return (
    <Modal
      size={"lg"}
      show={show}
      onHide={() => onHide(true)}
      className="rate-meeting"
    >
      <Form>
        <Modal.Header>
          <Modal.Title className="w-100">
            <div>
              <p>Session Summary and Notes </p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body scrollable={true}>
          {user.type === ProfileType.mentee && (
            <Container fluid>
              <section className="feedback-form">
                <Form.Group className="mb-3">
                  <FormLabelComponent>Session Notes</FormLabelComponent>
                  <Controller
                    name={`description`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInputField
                        defaultValue={menteeNote}
                        hookOnChange={onChange}
                        hookOnBlur={onBlur}
                        hookValue={value}
                        formAs={"textarea"}
                        rows={5}
                        disabled={true}
                      />
                    )}
                  />
                </Form.Group>
              </section>
            </Container>
          )}
          {user.type === ProfileType.mentor && (
            <Container fluid>
              <Row className="mt-4">
                <Form.Group className="mb-3">
                  <FormLabelComponent>
                    Problem Statement Summary
                  </FormLabelComponent>
                  <Controller
                    name={`Q1`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInputField
                        defaultValue={prbStatement}
                        hookOnChange={onChange}
                        hookOnBlur={onBlur}
                        hookValue={value}
                        formAs={"textarea"}
                        rows={5}
                        disabled={true}
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <FormLabelComponent>
                    Recommendations and Guidance Summary
                  </FormLabelComponent>
                  <Controller
                    name={`Q2`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInputField
                        defaultValue={recommGuidance}
                        hookOnChange={onChange}
                        hookOnBlur={onBlur}
                        hookValue={value}
                        formAs={"textarea"}
                        rows={5}
                        disabled={true}
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <FormLabelComponent>Session Notes</FormLabelComponent>
                  <Controller
                    name={`Q3`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInputField
                        defaultValue={mentorNote}
                        hookOnChange={onChange}
                        hookOnBlur={onBlur}
                        hookValue={value}
                        formAs={"textarea"}
                        rows={5}
                        disabled={true}
                      />
                    )}
                  />
                </Form.Group>
              </Row>
            </Container>
          )}
        </Modal.Body>
        <Modal.Footer className={"mentor-footer"}>
          {
            <Button
              className="submit-btn"
              onClick={handleClose}
              variant="primary"
            >
              Close
            </Button>
          }
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default SessionSummaryNotes;
