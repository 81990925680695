//** Key and Value of StaticSelect Translations -> Check with Value name in en.language.json **//

export function StaticSelectTranslations(t) {
  return {
    key: "staticSelectTranslations",
    value: {
      all: t("staticSelect.all"),
      active: t("staticSelect.active"),
      cancelled: t("staticSelect.cancelled"),
      completed: t("staticSelect.completed"),
      created: t("staticSelect.created"),
      pending: t("staticSelect.pending"),
    },
  };
}
