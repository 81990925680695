//** GinieHealth Learner completed Assessment Test,Allow Learner to fill in the Signature Tab **//

import { Modal, Form, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormErrorComponent } from "../form.error.component";
import { CustomInputField } from "../CustomInput/customInputField";
import { CustomCheckButton } from "../CustomInput/custom.switch";
import { signatureFormValidation } from "../../FormSchemas";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-date-picker";
import "./styles.css";
import moment from "moment";
import { useState } from "react";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { getDynamicENV } from "../../common/utils/commonFunctions";
import { organizationValue } from "../../redux-tk/slices/general.info.slice";
import { useSelector } from "react-redux";

const Signature = (props) => {
  const {
    show,
    onHide,
    signatureDisabled,
    header,
    subHeader,
    submit,
  } = props;
  const { genieLabsAssessmentTranslations } = useIgurooTranslations();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { agree: false },
    resolver: yupResolver(signatureFormValidation()),
  });
  const [newValue, setNewValue] = useState(new Date());
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate());
  const [isSubmitting,setisSubmitting]=useState(false)
  // const formattedTomorrow = tomorrow.toISOString().split("T")[0];
  // const validTillDate = assessments.map((item, index) => {
  //   const datechecking = item.valid_till;
  //   const tomorrow = new Date(moment.unix(datechecking));
  //   tomorrow.setDate(tomorrow.getDate());
  //   setDatechecking1(tomorrow);
  //   console.log("LOGGG12 setDatechecking1", setDatechecking1, tomorrow);
  // });

  console.log("***** errors", errors);
  const isSubmit = (data)=>{
    setisSubmitting(true);
    submit(data);
  }
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      className="pop-up"
      size="xl"
    >
      <div className="px-4 py-2">
        <Modal.Header closeButton className="modal-head">
          <Modal.Title>{header.split("_").join(" ")}</Modal.Title>
        </Modal.Header>
        <div className="body-container">
          <p className="declaration-note">
            {/* The electronic signature below and its related fields are treated like a handwritten signature on a paper. */}
            {genieLabsAssessmentTranslations?.signaturSubHeaedrNote}
          </p>
          <p className="declaration">
            <b>
              {subHeader
                ? subHeader
                : genieLabsAssessmentTranslations?.signaturSubHeader}
            </b>
          </p>
          <Form className="sig-form" onSubmit={handleSubmit(isSubmit)}>
            <Form.Group className="mb-4">
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => {
                  return (
                    <CustomCheckButton
                      hookOnChange={onChange}
                      hookOnBlur={onBlur}
                      hookValue={value}
                      name={name}
                      type="checkbox"
                      label="I Agree"
                      defaultChecked={false}
                      checked={value}
                    />
                  );
                }}
                name={"agree"}
              />
              <FormErrorComponent>
                {errors["agree"]?.message}
              </FormErrorComponent>
            </Form.Group>
            <Form.Group className="mb-4">
              <p className="head">
                Electronic Signature <span className="box">*</span>
              </p>
              <p className="sig-label">Type name</p>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <CustomInputField
                      placeholder="Full Name"
                      hookOnChange={onChange}
                      hookOnBlur={onBlur}
                      hookValue={value}
                      className="my-2 w-50"
                      disabled={signatureDisabled}
                    />
                  );
                }}
                name={"signaure_name"}
              />
              {/* { "agree" : "yes",     "signaure_name": 'user typed information',     "signed_date" : "calendar_date" } */}
              <FormErrorComponent>
                {errors["signaure_name"]?.message}
              </FormErrorComponent>
            </Form.Group>
            <Form.Group className="d-flex flex-column mb-4">
              <p className="head">
                Date <span className="box">*</span>
              </p>
              <p className="sig-label">mm/dd/yyyy</p>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => {
                  console.log("*****", value, typeof value);
                  return (
                    <DatePicker
                      className="mt-4  w-50"
                      name={name}
                      format={process.env.REACT_APP_DATEPICKER_FORMAT}
                      onChange={onChange}
                      value={value}
                      monthPlaceholder="MM"
                      dayPlaceholder="DD"
                      yearPlaceholder="YYYY"
                      minDate={tomorrow}
                      maxDate={tomorrow}
                      clearIcon={null}
                    />
                  );
                }}
                name={"signed_date"}
              />
              <FormErrorComponent>
                {errors["signed_date"]?.message}
              </FormErrorComponent>
            </Form.Group>
            <Button type="submit" className="" disabled={isSubmitting}>
              Save
            </Button>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default Signature;

