//** Iguroo & GinieHealth,CardText Component,can re-use & add any Text using this component **//

import React from "react";
import { _getImage } from "../../common/utils/commonFunctions";

export function CardTextComponent(props) {
  const { text, className, svglang } = props;
  return (
    <div className={`ig-card-text ${className}`}>
      {svglang ? (
        <>
          <img src={_getImage(svglang ? svglang : "")} />{" "}
        </>
      ) : (
        ""
      )}
      {props.children?.length > 0 ? props.children : text}
    </div>
  );
}
