//** SwotScreen Assessment for Iguroo,Will be use these component in future **//

import { Controller } from "react-hook-form";
import { SwotComponent } from "../../components/SwotComponents/SwotComponent";
import { swotAssessmentYup } from "../../FormSchemas/swot.assessment";
import { Col, Form, Row } from "react-bootstrap";
import { FormErrorComponent } from "../../components/form.error.component";
import { CustomAddMoreBtn } from "../../components/CustomInput/customAddMoreBtn";
import { SubmitButton } from "../../components/submit.button";
import { useIgurooTranslations } from "../../hooks";
import { SwotTypesEnum } from "../../enums";
import { useMemo } from "react";
import { SwotSemiCircle } from "../../components/SwotComponents/SwotSemiCircle";
import { SwotScreenIcons } from "../../components/SwotComponents/SwotScreenIcons";
import { _getImage } from "../../common/utils/commonFunctions";
import {
  swotopportunity,
  swotstrength,
  swotthreat,
  swotweakness,
} from "../../assets/images";
import { AlertComponent } from "../../components/alert.component";
import { SwotButton } from "../../components/SwotComponents/SwotButton";

export const SwotScreen = ({
  addSwotData,
  deleteSwotData,
  saveSwotData,
  swotDataValue,
  updateSwotData,
  userId,
  handleSubmit,
  onSubmit,
  getValues,
  errors,
  control,
  onCancelClick,
  onExampleClick,
}) => {
  const { formElementLabels, headingTranslations } = useIgurooTranslations();

  const strData = useMemo(() => {
    return {
      plans: swotDataValue.strPlans,
      swotData: swotDataValue.strengths,
      userId,
    };
  }, [swotDataValue.strPlans, swotDataValue.strengths, userId]);

  const wekData = useMemo(() => {
    return {
      plans: swotDataValue.wekPlans,
      swotData: swotDataValue.weaknesses,
      userId,
    };
  }, [swotDataValue.wekPlans, swotDataValue.weaknesses, userId]);

  const opoData = useMemo(() => {
    return {
      plans: swotDataValue.opoPlans,
      swotData: swotDataValue.opportunities,
      userId,
    };
  }, [swotDataValue.opoPlans, swotDataValue.opportunities, userId]);

  const thrData = useMemo(() => {
    return {
      plans: swotDataValue.thrPlans,
      swotData: swotDataValue.threats,
      userId,
    };
  }, [swotDataValue.thrPlans, swotDataValue.threats, userId]);

  return (
    <>
      <AlertComponent showIcon={true}>
        {headingTranslations?.swotHeading}
      </AlertComponent>
      <div className="text-right">
        <SwotButton
          type="submit"
          text={formElementLabels?.placeHolders?.swotTypes?.swotExampleButton}
          className={"swot-example-button"}
          onClick={onExampleClick}
          // disabled={ !isValid}
        />
      </div>
      <Form onSubmit={handleSubmit(onSubmit)} className="mt-3">
        <div>
          <Row className="m-0 p-0">
            <Col className="m-0 p-0 swot-strength" xs={12} md={6}>
              <Form.Group className="p-2">
                <Controller
                  name={"swotId"}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Form.Control
                      type="hidden"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    ></Form.Control>
                  )}
                />
                <div>
                  {/* {console.debug("image",_getImage("swotstrength.svg"))} */}
                  <SwotScreenIcons makeRight={true} svgName={swotstrength} />
                </div>
                <SwotComponent
                  swotName={"Strength"}
                  swotType={SwotTypesEnum.strength}
                  swotPlanType={SwotTypesEnum.strPlans}
                  control={control}
                  errors={errors}
                  getValues={getValues}
                  data={strData}
                  updateSwotData={updateSwotData}
                  addSwotData={addSwotData}
                  deleteSwotData={deleteSwotData}
                />
                {/* <div className="mt-3 mb-3">
                <FormErrorComponent>
                  {errors.avails && errors.avails?.message}
                </FormErrorComponent>
              </div> */}
              </Form.Group>
              {/* <SwotSemiCircle
              comPClassname={"my-semi-circle-left-top"}
              comTextClassName={"msc-left-top"}
              makeRight={true}
              text={"S"}
            /> */}
              {/* <div className="text-right">
              <div className="d-inline-block my-semi-circle my-semi-circle-left-top float-end">S</div>
            </div> */}
            </Col>
            <Col className="m-0 p-0 swot-weakness" xs={12} md={6}>
              <Form.Group className="p-2">
                <div>
                  <SwotScreenIcons makeRight={true} svgName={swotweakness} />
                </div>
                <SwotComponent
                  swotName={"Weakness"}
                  swotType={SwotTypesEnum.weakness}
                  swotPlanType={SwotTypesEnum.wekPlans}
                  control={control}
                  errors={errors}
                  getValues={getValues}
                  data={wekData}
                  updateSwotData={updateSwotData}
                  addSwotData={addSwotData}
                  deleteSwotData={deleteSwotData}
                />
                {/* <div className="mt-3 mb-3">
                <FormErrorComponent>
                  {errors.avails && errors.avails?.message}
                </FormErrorComponent>
              </div> */}
              </Form.Group>

              {/* <div className="my-semi-circle my-semi-circle-right-top">W</div> */}
              {/* <SwotSemiCircle
              comPClassname={"my-semi-circle-right-top"}
              comTextClassName={"msc-right-top"}
              makeRight={false}
              text={"W"}
            /> */}
            </Col>
          </Row>
          <Row className="m-0 p-0">
            <Col className="m-0 p-0 swot-opportunity" xs={12} md={6}>
              {/* <SwotSemiCircle
              comPClassname={"my-semi-circle-left-bottom"}
              comTextClassName={"msc-left-bottom"}
              makeRight={true}
              text={"O"}
            /> */}
              {/* <div className="text-right">
              <div className="d-inline-block my-semi-circle my-semi-circle-left-bottom">O</div>
            </div> */}

              <Form.Group className="p-2">
                <div>
                  <SwotScreenIcons makeRight={true} svgName={swotopportunity} />
                </div>
                <SwotComponent
                  swotName={"Opportunities"}
                  swotType={SwotTypesEnum.opportunities}
                  swotPlanType={SwotTypesEnum.opoPlans}
                  control={control}
                  errors={errors}
                  getValues={getValues}
                  data={opoData}
                  updateSwotData={updateSwotData}
                  addSwotData={addSwotData}
                  deleteSwotData={deleteSwotData}
                />
                {/* <div className="mt-3 mb-3">
                <FormErrorComponent>
                  {errors.avails && errors.avails?.message}
                </FormErrorComponent>
              </div> */}
              </Form.Group>
            </Col>
            <Col className="m-0 p-0 swot-threat" xs={12} md={6}>
              {/* <SwotSemiCircle
              comPClassname={"my-semi-circle-right-bottom"}
              comTextClassName={"msc-right-bottom"}
              makeRight={false}
              text={"T"}
            /> */}
              {/* <div className="my-semi-circle my-semi-circle-right-bottom">T</div> */}
              <Form.Group className="p-2">
                <div>
                  <SwotScreenIcons makeRight={true} svgName={swotthreat} />
                </div>
                <SwotComponent
                  swotName={"Threats"}
                  swotType={SwotTypesEnum.threats}
                  swotPlanType={SwotTypesEnum.thrPlans}
                  control={control}
                  errors={errors}
                  getValues={getValues}
                  data={thrData}
                  updateSwotData={updateSwotData}
                  addSwotData={addSwotData}
                  deleteSwotData={deleteSwotData}
                />
                {/* <div className="mt-3 mb-3">
                <FormErrorComponent>
                  {errors.avails && errors.avails?.message}
                </FormErrorComponent>
              </div> */}
              </Form.Group>
            </Col>
          </Row>
        </div>

        {/* <Row className="mt-3">
        <Col md="4" className="d-grid d-md-block">
          <CustomAddMoreBtn
            text={formElementLabels.addAnotherTimeSlot}
            className="mt-3"
            onClick={addAvailabilityInputs}
          />
        </Col>
      </Row> */}

        {/* <Row className="mt-1 mb-3">
        <FormErrorComponent>
          {errors.apiError && errors.apiError?.message}
        </FormErrorComponent>
      </Row> */}

        <Row className="text-end mb-4 justify-content-end save-btn-end mt-2">
          <Col sm="12" md="2" className="d-grid ">
            <SwotButton
              type="button"
              text={formElementLabels?.cancel}
              className={"swot-example-button"}
              onClick={onCancelClick}
              // disabled={ !isValid}
            />
            {/* <SubmitButton
              type="button"
              text={formElementLabels?.cancel}
              onClick={onCancelClick}
              // disabled={ !isValid}
            /> */}
          </Col>

          <Col sm="12" md="1" className="d-grid ">
            <SubmitButton
              type="submit"
              text={formElementLabels?.save}
              // disabled={ !isValid}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};
