//** Iguroo Mentor & Mentee Request Data Slice **//

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { getRequestKeySession } from "../../helpers";
import { getCompletedSessions } from "../../helpers/grid.helpers";
import {
  GetListOfUserProfiles,
  GetUserProfile,
} from "../../services/axios/MentorOnbaording";
import {
  getListFromSessionList,
  getSessionList,
} from "../../services/axios/requests";
import { menteesRequests } from "../../services/mentorServices";

export const getRequestSessionsAsyncThunk = createAsyncThunk(
  "requests/getRequestSessionsAsyncThunk",
  async (data, thunkApi) => {
    try {
      const sessions = await getSessionList(data.requestNumber);
      return { sessions, requestNumber: data.requestNumber };
    } catch (err) {
      console.log("api error", err);
      thunkApi.rejectWithValue(err);
    }
  }
);

export const getDashboardRequestsAsyncThunk = createAsyncThunk(
  "requests/getDashboardRequestsAsyncThunk",
  async (data, thunkApi) => {
    try {
      const request = await menteesRequests(data.req);
      return {
        requests: request?.hits,
        totalRequests: request?.total.value,
        isMentor: data.isMentor,
      };
    } catch (err) {
      console.log("api error", err);
      thunkApi.rejectWithValue(err);
    }
  }
);

export const getUserProfileSessionAsyncThunk = createAsyncThunk(
  "requests/getUserProfileSessionAsyncThunk",
  async (data, thunkApi) => {
    try {
      const user = await GetUserProfile(data.userId);
      console.log("user", user);
      return {
        user: user.data,
        requestNumber: data.requestNumber,
      };
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);

export const getRequestUsersListAsyncThunk = createAsyncThunk(
  "requests/getRequestUsersListAsyncThunk",
  async (data, thunkApi) => {
    try {
      const user = await GetListOfUserProfiles(data.userIds);
      console.log("list user", user);
      return {
        users: user.data,
      };
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);

export const getRequestSessionsListAsyncThunk = createAsyncThunk(
  "requests/getRequestSessionsListAsyncThunk",
  async (data, thunkApi) => {
    try {
      const sessions = await getListFromSessionList(data.requestNumbers);
      console.log("list sessions", sessions);
      return {
        sessions: sessions.response,
        requestNumber: data.requestNumbers,
      };
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);

const initialState = {
  dashboardRequests: [],
  totalRequests: 0,
};

export const requestsSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRequestSessionsAsyncThunk.fulfilled, (state, action) => {
        //console.log("sessions action'", action);
        if (!action?.payload) return;
        const sessions = action.payload?.sessions;
        for (let i = 0; i < state.dashboardRequests?.length; i++) {
          const req = state.dashboardRequests[i];
          if (req.requestNumber === action.payload.requestNumber) {
            state.dashboardRequests[i].sessionsCompleted = getCompletedSessions(
              sessions?.Items
            );
            console.log("sessoins request", sessions);
            const subtext =
              moment
                .unix(sessions?.Items[0]?.startTime)
                .format("DD/MM/YYYY - hh:mm A - ") +
              moment.unix(sessions?.Items[0]?.endTime).format("hh:mm A");
            console.log(
              sessions?.startTime,
              sessions?.endTime,
              sessions,
              "sessions action ####"
            );
            state.dashboardRequests[i].subtext = `${subtext}`;
            break;
          }
        }
      })
      .addCase(getRequestSessionsAsyncThunk.pending, (state) => {})
      .addCase(getRequestSessionsAsyncThunk.rejected, (state) => {})
      .addCase(getRequestSessionsListAsyncThunk.fulfilled, (state, action) => {
        //console.log("sessions action'", action);
        if (!action?.payload) return;
        const requests = getRequestKeySession(action.payload?.sessions);
        for (let i = 0; i < state.dashboardRequests?.length; i++) {
          const req = state.dashboardRequests[i];
          const request = requests[req?.requestNumber];
          if (request) {
            const requestSessions = request?.sessions;
            state.dashboardRequests[i].sessionsCompleted =
              getCompletedSessions(requestSessions);
            console.log("sessoins request", requestSessions);
            const subtext =
              moment
                .unix(requestSessions[0]?.startTime)
                .format("DD/MM/YYYY - hh:mm A - ") +
              moment.unix(requestSessions[0]?.endTime).format("hh:mm A");
            //console.log(requestSessions?.startTime, requestSessions?.endTime, requestSessions, 'sessions action ####')
            state.dashboardRequests[i].subtext = `${subtext}`;
          }
        }
      })
      .addCase(getRequestSessionsListAsyncThunk.pending, (state) => {})
      .addCase(getRequestSessionsListAsyncThunk.rejected, (state) => {})
      .addCase(getUserProfileSessionAsyncThunk.fulfilled, (state, action) => {
        //console.log("sessions action'", action);
        if (!action?.payload) return;
        const user = action.payload?.user;
        for (let i = 0; i < state.dashboardRequests?.length; i++) {
          const req = state.dashboardRequests[i];
          if (req.requestNumber === action.payload.requestNumber) {
            state.dashboardRequests[
              i
            ].name = `${user?.firstName} ${user?.lastName}`;
            state.dashboardRequests[i].image = user?.profilePic;
            break;
          }
        }
      })
      .addCase(getUserProfileSessionAsyncThunk.pending, (state) => {})
      .addCase(getUserProfileSessionAsyncThunk.rejected, (state) => {})
      .addCase(getRequestUsersListAsyncThunk.fulfilled, (state, action) => {
        //console.log("sessions action'", action);
        if (!action?.payload) return;
        const users = action.payload?.users;
        console.log("list state", state.dashboardRequests[0], users);
        for (let j = 0; j < users?.length; j++) {
          const user = users[j];
          for (let i = 0; i < state.dashboardRequests?.length; i++) {
            const req = state.dashboardRequests[i];
            if (
              (req && user && req?.userId === user?.userId) ||
              req?.mentorId === user?.userId
            ) {
              console.log("list request", req, user);
              state.dashboardRequests[
                i
              ].name = `${user?.firstName} ${user?.lastName}`;
              state.dashboardRequests[i].image = user?.profilePic;
              console.log("list user updated", user?.firstName);
            }
          }
        }
      })
      .addCase(getRequestUsersListAsyncThunk.pending, (state) => {})
      .addCase(getRequestUsersListAsyncThunk.rejected, (state) => {})
      .addCase(getDashboardRequestsAsyncThunk.fulfilled, (state, action) => {
        console.log("sessions action'", action);
        if (!action?.payload) return;
        const requestsGot = action.payload?.requests?.map((req) => {
          console.log(
            "sessions action'",
            action,
            req?._source?.startTime,
            moment
              .unix(req?._source?.startTime)
              .format("DD/MM/YYYY - hh:mm A - ") +
              moment.unix(req?._source?.endTime).format("hh:mm A")
          );
          return {
            requestTitle: req?._source?.title,
            userId: req?._source?.userId,
            mentorId: req?._source?.mentorId,
            numberOfSessions: req?._source?.numberOfSessions,
            category: req?._source?.category,
            requestNumber: req?._source?.requestNumber,
          };
        });
        state.dashboardRequests = requestsGot;
        state.totalRequests = action.payload.totalRequests;
      })
      .addCase(getDashboardRequestsAsyncThunk.pending, (state) => {})
      .addCase(getDashboardRequestsAsyncThunk.rejected, (state) => {});
  },
});

export const {} = requestsSlice.actions;

export const getDashboardRequestsValue = (state) =>
  state?.requests?.dashboardRequests;

export const getDashboardRequestsCount = (state) =>
  state?.requests?.totalRequests;

export default requestsSlice.reducer;
