//** Iguroo Assessment ExamAttempt,ExamInfo Slice **//

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetExamAttempt } from "../../services/axios/Exam";

export const getExamAttemptAsyncThunk = createAsyncThunk(
  "user/getExamAttemptThunk",
  async (data, thunkApi) => {
    try {
      const examAttempt = await GetExamAttempt(data);
      console.log("console user", examAttempt);
      return examAttempt;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);
const initialState = {
  answers: [],
};

export const examInfoSlice = createSlice({
  name: "ExamInfo",
  initialState,
  reducers: {
    changeCurrentQuestion: (state, action) => {
      console.log("console question submit action ", state, action);
      state.currentQuestion = action.payload;
    },
    answerSelected: (state, action) => {
      console.log("console question submit action ", state, action);
      state.answers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExamAttemptAsyncThunk.fulfilled, (state, action) => {
        console.log("ac us'", action, state);
        const data = action?.payload?.data;
        const status = action?.payload?.status;
        const current = state?.exam?.currentQuestion;
        console.log("ac us saving in 204 console", status, current);
        if (status === 200 && current === undefined) {
          console.log("ac us saving in 204", status, current);
          state.currentQuestion = 1;
          state.answers = [];
        }
        if (!data) return;
        if (data?.response?.Items.length > 0) {
          const ItemsLength = data?.response?.Items?.length;
          const last = data?.response?.Items[ItemsLength - 1];
          const answers = last?.answers;
          const ansLen = answers?.length;
          const lastAns = answers[ansLen - 1];
          const completeExam = last?.completeExam;
          if (!completeExam) {
            state.currentQuestion = lastAns?.question_id;
            state.answers = [...answers];
          }
          console.log(
            ItemsLength,
            last,
            answers,
            ansLen,
            lastAns,
            state,
            "console question_id"
          );
        }
      })
      .addCase(getExamAttemptAsyncThunk.pending, (state) => {})
      .addCase(getExamAttemptAsyncThunk.rejected, (state) => {});
  },
});

export const { changeCurrentQuestion, answerSelected } = examInfoSlice.actions;

export const answerValue = (state) => state.exam.answers;

export default examInfoSlice.reducer;

export const currentQuestionValue = (state) => {
  console.log(state, "console state");
  return state?.exam?.currentQuestion ? state?.exam?.currentQuestion : 1;
};
