import * as crypto from "crypto-js";
import * as AWS from "aws-sdk";
import * as querystring from "querystring";
import { identityPoolId, region, userPoolId } from "../services/config";
import * as cognito from "../services/cognito.service";
import { isTokenValid } from "./commons.helpers";
import { awsCredsHolder } from "./aws.creds.holder";
import { AWSCredStatus } from "../enums";

const apiGateway =
  "https://your-api-id.execute-api.us-west-2.amazonaws.com/prod/";

const getSignatureKey = (key, dateStamp, regionName, serviceName) => {
  const kDate = crypto.HmacSHA256(dateStamp, `AWS4${key}`);

  const kRegion = crypto.HmacSHA256(regionName, kDate);

  const kService = crypto.HmacSHA256(serviceName, kRegion);

  const kSigning = crypto.HmacSHA256("aws4_request", kService);

  return kSigning;
};

const sign = (key, msg) => {
  return crypto.HmacSHA256(msg, key).toString(crypto.enc.Hex);
};

export const getAwsCreds = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const normalCreds = AWS.config.credentials;
      const awsSigningTokens = awsCredsHolder.getSigningTokens();
      const awsSigningTokensExpired = awsCredsHolder.isSigningTokenExpired();
      console.log(
        "tokens credholder normal creds are",
        normalCreds,
        "normalcreds",
        awsSigningTokens,
        "expired",
        awsSigningTokensExpired
      );
      console.log("mytokennew1");
      if (awsSigningTokens && !awsSigningTokensExpired) {
        console.log("tokens cred used existing tokens", awsSigningTokens);
        return resolve(awsSigningTokens);
      }
      const signTokenStatus = awsCredsHolder.getSignTokenStatus();
      console.log("tokens credholder checking token status", signTokenStatus);
      if (signTokenStatus === AWSCredStatus.refreshing) {
        console.log("tokens credholder are refreshing", signTokenStatus);
        awsCredsHolder.registerResolveForTokenCallback(resolve);
      } else {
        if (awsSigningTokensExpired) {
          awsCredsHolder.setSignTokensRefreshing();
          console.log("tokens credholder for refreshing", signTokenStatus);
          if (!isTokenValid(localStorage["idToken"])) {
            console.log("token was invalid, refreshed it");
            await cognito.refreshSession(localStorage["refreshToken"]);
          }
          AWS.config.update({
            region: region,
            credentials: new AWS.CognitoIdentityCredentials({
              region: region,
              IdentityPoolId: identityPoolId,
              Logins: {
                [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]:
                  localStorage["idToken"],
              },
            }),
          });
          await AWS.config.credentials.getPromise();
          console.log("refreshed credentials");
        }
        AWS.config.getCredentials(async (err, creds) => {
          if (err) {
            console.log("tokens credholder credential error", err);
            return reject(err);
          }
          console.log("tokens credholder sign", creds);
          awsCredsHolder.updateSigningTokens(creds);
          awsCredsHolder.setSignTokensGot();
          awsCredsHolder.callResolves();
          console.log(
            "tokens credholder calling refreshing of resolves",
            awsCredsHolder.getSignTokenStatus()
          );
          return resolve(creds);
        });
      }
    } catch (err) {
      console.log("tokens credholder err", err);
      if (awsCredsHolder.getSignTokenStatus === AWSCredStatus.refreshing) {
        awsCredsHolder.setSignTokensGot();
      }
    }
  });
};

export async function getPublicCredsAsync() {
  return new Promise(async (resolve, reject) => {
    const normalCreds = AWS?.config?.credentials;
    if (normalCreds && !normalCreds?.expired) {
      return resolve(normalCreds);
    }
    AWS.config.region = region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: identityPoolId,
    });
    AWS.config.getCredentials(async (err, creds) => {
      if (err) {
        console.log("tokens cred credential error", err);
        return reject(err);
      }
      console.log("tokens cred sign", creds);
      return resolve(creds);
    });
  });
}

export const getSignedUrl = async (
  operation,
  path,
  queryParams = {},
  headers = {},
  payload = "",
  host,
  region = "us-east-1",
  isPublicProfile = false,
  service = "execute-api"
) => {
  try {
    const headersToSign = {
      "x-amz-security-token": true,
      "x-amz-date": true,
      host: true,
    };
    console.log("tokens cred starting", isPublicProfile);
    let creds = null;
    if (isPublicProfile) {
      creds = await getPublicCredsAsync();
    } else {
      creds = await getAwsCreds();
    }
    console.log("tokens cred", creds);
    const accessKey = creds?.accessKeyId;
    const expireTime = creds?.expireTime;
    const expired = creds?.expired;
    const secretKey = creds?.secretAccessKey;
    const sessionToken = creds?.sessionToken;
    const method = "GET";
    const serviceName = service;
    const algorithm = "AWS4-HMAC-SHA256";

    const t = new Date();
    const amzDate = t.toISOString().replace(/[:\-]|\.\d{3}/g, "");
    const dateStamp = amzDate.substring(0, 8); //amzDate.substr(0, 8);
    headers.host = host;
    headers["x-amz-date"] = amzDate;
    if (sessionToken) {
      headers["x-amz-security-token"] = sessionToken;
    }
    console.log("tokens cred dates done");
    const canonicalUri = path;
    const sortedQueryParams = Object.keys(queryParams)?.sort();
    const sortedQueryObj = {};
    sortedQueryParams?.forEach((val) => {
      sortedQueryObj[val] = queryParams[val];
    });
    const canonicalQueryString = querystring?.stringify(sortedQueryObj);
    console.log("tokens cred canonical urls done");
    const signedHeaders = Object.keys(headers)
      ?.filter((value) => headersToSign[value?.toLowerCase()])
      ?.sort()
      ?.map((key) => key?.toLowerCase())
      ?.join(";");
    console.log("tokens cred headers", headers);
    const canonicalHeaders = Object.keys(headers)
      ?.sort()
      ?.filter((value) => headersToSign[value.toLowerCase()])
      ?.map((key) => `${key.toLowerCase()}:${headers[key]?.trim()}`)
      ?.join("\n");

    const payloadHash = crypto.SHA256(payload).toString(crypto.enc.Hex); //SHA256(payload).toString(crypto.enc.Hex); //crypto.createHash("sha256").update(payload).digest("hex");

    const canonicalRequest = [
      operation,
      canonicalUri,
      canonicalQueryString,
      canonicalHeaders,
      "",
      signedHeaders,
      payloadHash,
    ].join("\n");
    console.log("tokens cred canonical request", canonicalRequest);
    const stringToSign = [
      algorithm,
      amzDate,
      `${dateStamp}/${region}/${serviceName}/aws4_request`,
      crypto.SHA256(canonicalRequest).toString(crypto.enc.Hex),
    ].join("\n");
    console.log("tokens cred string to sign", stringToSign);
    const signingKey = getSignatureKey(
      secretKey,
      dateStamp,
      region,
      serviceName
    );
    const signature = sign(signingKey, stringToSign);
    console.log("tokens cred signing key", signingKey, "signature", signature);
    headers.Authorization = `${algorithm} Credential=${accessKey}/${dateStamp}/${region}/${serviceName}/aws4_request, SignedHeaders=${signedHeaders}, Signature=${signature}`;

    const signedUrl =
      apiGateway +
      path +
      "?" +
      querystring.stringify(sortedQueryObj) +
      "&X-Amz-Signature=" +
      signature;

    return signedUrl;
  } catch (err) {
    console.log("signing error", err);
    throw err;
  }
};
