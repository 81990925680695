//** Iguroo & GinieHealth Uploading Picture can view and re-use required Image View Field **//

import React from "react";
import { _getImage } from "../../common/utils/commonFunctions";
import { ImgSrcEnums } from "../../enums";
import { CustomImage } from "./CustomImage";

export const ProfileImage = ({
  src,
  resizeMode,
  className,
  width,
  style,
  height,
  isPublicProfile,
}) => {
  console.log(src, "src final dta");
  return (
    <CustomImage
      {...(isPublicProfile ? { isPublicProfile: isPublicProfile } : {})}
      {...(width ? { width: width } : {})}
      {...(height ? { height: height } : {})}
      {...(style ? { style: style } : {})}
      roundedCircle={true}
      resizeMode={resizeMode ? resizeMode : "contain"}
      className={className ? className : ""}
      crossOrigin="anonymous"
      src={{
        src: src ? src : _getImage("avatar.svg"),
        type: src ? ImgSrcEnums.props : ImgSrcEnums.normal,
      }}
    />
  );
};
