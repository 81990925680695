//** Iguroo SWOT Assessment ->Swot SingleList Component,Currently not using this code,will be implemented in future **//

import { Tab, Tabs } from "react-bootstrap";
import { SwotSingleListComponent } from "./SwotSingleListComponent";
import "./swotcomponent.css";

export const SwotComponent = ({
  swotType,
  swotPlanType,
  swotName,
  control,
  errors,
  getValues,
  data,
  updateSwotData,
  addSwotData,
  deleteSwotData,
}) => {

  return (
    <>
      <Tabs
        className={`myClass ${
          errors[swotType]?.length > 0 ? "first-tab-error" : ""
        } ${errors[swotPlanType]?.length > 0 ? "second-tab-error" : ""}`}
        defaultActiveKey={swotType}
        id={`uncontrolled-tab-example${swotType}`}
      >
        <Tab eventKey={swotType} title={swotName}>
          <SwotSingleListComponent
            control={control}
            isPlan={false}
            swotName={swotName}
            swotType={swotType}
            swotPlanType={swotPlanType}
            errors={errors}
            getValues={getValues}
            data={data.swotData}
            updateSwotData={updateSwotData}
            addSwotData={addSwotData}
            deleteSwotData={deleteSwotData}
            swotFieldName={swotType}
          />
        </Tab>
        <Tab eventKey="plan" title={"Plan"} className="tabclass">
          <SwotSingleListComponent
            control={control}
            isPlan={true}
            swotName={swotName}
            swotType={swotType}
            swotPlanType={swotPlanType}
            errors={errors}
            getValues={getValues}
            data={data.plans}
            updateSwotData={updateSwotData}
            addSwotData={addSwotData}
            deleteSwotData={deleteSwotData}
            swotFieldName={swotPlanType}
          />
        </Tab>
      </Tabs>
    </>
  );
};
