//** GinieHealth Author AssessmentDetailsHeader & Graph Status Static Data **//

import React from "react";
import AssessmentDetailsHeader from "../../components/assessmentDetails/assessmentDetailsHeader";
import { _getImage } from "../../common/utils/commonFunctions";
import { Col, Container, Row } from "react-bootstrap";
import { Heading } from "../../components/heading.component";
import AssessmentDetailsCards from "../../components/assessmentDetails/assessmentDetailsCards";
import { useSearchParams } from "react-router-dom";

const AssessmentDetailsPage = () => {
  const [test, setTest] = useSearchParams();
  const assessment_id = test.get("assessmentId");
  const version = test.get("versionId");
  const totalUsers = {
    activeTitle: "7543K",
    cardPic: _getImage("usersAssessment.svg"),
    description: "Total users",
    percentageChange: "2.4%",
    incrementDecrement: "increase",
    graphdetails: {
      labels: [1, 2, 3, 4, 5],
      datasets: [
        {
          data: [40, 20, 60, 30, 40, 80],
          borderColor: "#b52dc8",
          backgroundColor: "none",
        },
      ],
    },
  };

  const totalEarnings = {
    activeTitle: "7553K",
    cardPic: _getImage("earning.svg"),
    description: "Total Earnings",
    percentageChange: "2.4%",
    incrementDecrement: "decrease",
    graphdetails: {
      labels: [1, 2, 3, 4, 5],
      datasets: [
        {
          data: [60, 20, 50, 30, 40, 80],
          borderColor: "#6182e7",
          backgroundColor: "none",
        },
      ],
    },
  };
  const totalCompletion = {
    activeTitle: "7543K",
    cardPic: _getImage("completion.svg"),
    description: "Total Completion",
    percentageChange: "2.4%",
    incrementDecrement: "decrease",
    graphdetails: {
      labels: [1, 2, 3, 4, 5],
      datasets: [
        {
          cubicInterpolationMode: "monotone",
          data: [30, 20, 50, 30, 30, 50],
          borderColor: "#be5dfa",
          backgroundColor: "none",
        },
      ],
    },
  };
  const totalRatings = {
    activeTitle: "7543K",
    cardPic: _getImage("ratings.svg"),
    description: "Total Ratings",
    percentageChange: "2.4%",
    incrementDecrement: "increase",
    graphdetails: {
      labels: [1, 2, 3, 4, 5],
      datasets: [
        {
          data: [10, 40, 30, 30, 60, 50],
          borderColor: "#687ae3",
          backgroundColor: "none",
        },
      ],
    },
  };
  return (
    <>
      <AssessmentDetailsHeader
        assessment_id={assessment_id}
        version={version}
        // name="Basic Calculus: 100 Questions"
        // description="Daniel walter Scott"
        // details="3 Hr (100 Questions)"
        // rating={3.5}
        // price="₹499"
        // profilePic={_getImage("avatar.svg")}
      />
      <div className=" p-2">
        <Container className="border p-4">
          <Heading>Basic Calculus: 100 Questions</Heading>

          <Row className="d-flex">
            <Col sm={6} md={6} lg={4} className="mt-4">
              <AssessmentDetailsCards
                activeTitle={totalUsers.activeTitle}
                cardPic={totalUsers.cardPic}
                description={totalUsers.description}
                percentageChange={totalUsers.percentageChange}
                className="totalUSer"
                incrementDecrementPic={
                  totalUsers.incrementDecrement == "increase"
                    ? _getImage("userIncrement.svg")
                    : _getImage("userIncrement.svg")
                }
                graphdetails={totalUsers.graphdetails}
              />
            </Col>

            <Col sm={6} md={6} lg={4} className="mt-4">
              <AssessmentDetailsCards
                activeTitle={totalEarnings.activeTitle}
                cardPic={totalEarnings.cardPic}
                description={totalEarnings.description}
                percentageChange={totalEarnings.percentageChange}
                className="totalEarnings"
                incrementDecrementPic={
                  totalEarnings.incrementDecrement == "increase"
                    ? _getImage("earningDecrease.svg")
                    : _getImage("earningDecrease.svg")
                }
                graphdetails={totalEarnings.graphdetails}
              />
            </Col>
            <Col sm={6} md={6} lg={4} className="mt-4">
              <AssessmentDetailsCards
                activeTitle={totalCompletion.activeTitle}
                cardPic={totalCompletion.cardPic}
                description={totalCompletion.description}
                percentageChange={totalCompletion.percentageChange}
                className="totalCompletion"
                incrementDecrementPic={
                  totalCompletion.incrementDecrement == "increase"
                    ? _getImage("completionDecrease.svg")
                    : _getImage("completionDecrease.svg")
                }
                graphdetails={totalCompletion.graphdetails}
              />
            </Col>
            <Col sm={6} md={6} lg={4} className="mt-4">
              <AssessmentDetailsCards
                activeTitle={totalRatings.activeTitle}
                cardPic={totalRatings.cardPic}
                description={totalRatings.description}
                percentageChange={totalRatings.percentageChange}
                className="totalRatings"
                incrementDecrementPic={
                  totalRatings.incrementDecrement == "increase"
                    ? _getImage("ratingIncrease.svg")
                    : _getImage("ratingIncrease.svg")
                }
                graphdetails={totalRatings.graphdetails}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AssessmentDetailsPage;
