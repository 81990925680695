//** Key and Value of MenteeOnboarding Translations -> Check with Value name in en.language.json **//

import { AppUrlsEnum } from "../../../enums";

export function MenteeOnboardingTranslations(t) {
  return {
    key: "menteeOnboardingLabels",
    value: {
      createMenteeProfile: t("menteeOnboardingLabels.createMenteeProfile"),
      gender: t("menteeOnboardingLabels.gender"),
      profile: t("menteeOnboardingLabels.profile"),
      language: t("menteeOnboardingLabels.language"),
      selectLanguage: t("menteeOnboardingLabels.selectLanguage"),
      english: t("menteeOnboardingLabels.english"),
      hindi: t("menteeOnboardingLabels.hindi"),
      telugu: t("menteeOnboardingLabels.telugu"),
      tamil: t("menteeOnboardingLabels.tamil"),
      kannada: t("menteeOnboardingLabels.kannada"),
      odia: t("menteeOnboardingLabels.odia"),
      selectLanguageForSwot: t(
        "Select Language for Self Discovery Assessment and SWOT analysis. You can change the language by editing the selection on your profile page."
      ),
      selectLanguageForSwotProfile: t(
        "Select Language for Self Discovery Assessment and SWOT analysis."
      ),
      location: t("menteeOnboardingLabels.location"),
      country: t("menteeOnboardingLabels.country"),
      state: t("menteeOnboardingLabels.state"),
      city: t("menteeOnboardingLabels.city"),
      profileTitle: t("menteeOnboardingLabels.profileTitle"),
      placeHoldersdesc: t("menteeOnboardingLabels.placeHoldersdesc"),
      description: t("menteeOnboardingLabels.description"),
      uploadProfileVideo: t("menteeOnboardingLabels.uploadProfileVideo"),
      endYear: t("menteeOnboardingLabels.endYear"),
      field: t("menteeOnboardingLabels.field"),
      degree: t("menteeOnboardingLabels.degree"),
      school: t("menteeOnboardingLabels.school"),
      yearofexp: t("menteeOnboardingLabels.yearofexp"),
      company: t("menteeOnboardingLabels.company"),
      role: t("menteeOnboardingLabels.role"),
      dateOfBirth: t("menteeOnboardingLabels.dateOfBirth"),
      skillPosses: t("menteeOnboardingLabels.skillPosses"),
      hobbies: t("menteeOnboardingLabels.hobbies"),
      profdesc: t("menteeOnboardingLabels.profdesc"),
      describachievements: t("menteeOnboardingLabels.describachievements"),
      onboardingSuccess: t("menteeOnboardingLabels.onboardingSuccess"),
      unsavedWarning: t("menteeOnboardingLabels.unsavedWarning"),
      warning: t("menteeOnboardingLabels.warning"),
      assessmentReset: t("menteeOnboardingLabels.assessmentReset"),
      profilePhoto: t("menteeOnboardingLabels.profilePhoto"),
      uploadedProfileVideo: t("menteeOnboardingLabels.uploadedProfileVideo"),
      resetPasswordMessage: t("menteeOnboardingLabels.resetPasswordMessage"),
      yes: t("menteeOnboardingLabels.yes"),
      cancel: t("menteeOnboardingLabels.cancel"),
      rightSetupModule: {
        mentee: {
          title: t("rightSetupModule.menteeTitle"),
          values: {
            basicInfo: {
              key: "basicInfo",
              value: t("rightSetupModule.basicInfo"),
              url: AppUrlsEnum.menteeProfile,
            },
            eduAndExp: {
              key: "eduAndExp",
              value: t("rightSetupModule.eduAndExp"),
              url: AppUrlsEnum.menteeEduAndWork,
            },
            skillsAndProf: {
              key: "skillsAndProf",
              value: t("rightSetupModule.skillsAndProf"),
              url: AppUrlsEnum.menteeSkill,
            },
          },
        },
      },
    },
  };
}
