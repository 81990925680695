import * as yup from "yup";

const phoneNumberRegEx =
  /^((\+[1-9]{1,4})(([0-9]{2,3})[-]*)([0-9]{2,4})[-]*)*?[0-9]{3,4}?[-]*[0-9]{3,4}?$/;

export function getSignUPFormTranslated(formErrors) {
  return yup.object({
    firstname: yup
      .string()
      .required(formErrors?.firstname)
      .matches(/^[a-zA-Z0-9\s&,().-]+$/, formErrors?.nameNumSplCharcError),
    lastname: yup
      .string()
      .required(formErrors?.lastname)
      .matches(/^[a-zA-Z0-9\s&,().-]+$/, formErrors?.nameNumSplCharcError),
    mobileNumber: yup
      .string()
      .min(4, formErrors?.mobileNumber)
      .max(16, formErrors?.mobileNumber)
      .required(formErrors?.mobileNumber)
      .matches(phoneNumberRegEx, formErrors?.enterOnlyNumber),
    username: yup.string().optional(),
    email: yup
      .string()
      .email("Enter valid email address")
      .required(formErrors?.email),

    password: yup
      .string()
      .required(formErrors?.password)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&!$])[a-zA-Z0-9!@#%&!$]+$/,
        formErrors?.passwordReq
      ),
    termAndPolicy: yup
      .bool()
      .oneOf([true], formErrors?.termAndPolicy)
      .required(formErrors?.termAndPolicy),
  });
}
