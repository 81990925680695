//** Iguroo Mentor & Mentee,CardList Component for Dashboard **//

import React from "react";
import { Card, ListGroup, Badge } from "react-bootstrap";
import { _getImage } from "../../common/utils/commonFunctions";
import "./styles.scss";
import { Link, useNavigate } from "react-router-dom";
import { AppUrlsEnum, ProfileType } from "../../enums";
import { useSelector } from "react-redux";
import { EmptyCardList } from "./empty.card.list";

function CardList({
  title,
  listTitle,
  listTitleClass,
  className,
  badge,
  data,
  view: Component,
  footerProps,
  headerIcon,
  type,
  cardType,
}) {
  console.log(footerProps, "footer-props");
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.profile);
  const handleCardHeader = () => {
    console.log(title, "title", user.type);
    if (user.type === ProfileType.mentor && title === "Requests") {
      navigate(AppUrlsEnum.mentorRequests);
    } else if (user.type === ProfileType.mentee && title === "Requests") {
      navigate(AppUrlsEnum.menteeRequests);
    }

    if (user.type === ProfileType.mentor && title === "My Mentees") {
      navigate(AppUrlsEnum.mentorMentees);
    } else if (user.type === ProfileType.mentee && title === "My Mentors") {
      navigate(AppUrlsEnum.menteeMentors);
    }
  };

  return (
    <Card className={className}>
      <Card.Header onClick={handleCardHeader} style={{ cursor: "pointer" }}>
        {headerIcon && (
          <img
            src={_getImage(headerIcon)}
            height="20"
            className="me-2"
            alt="headphones"
          />
        )}
        {title}
        {badge !== undefined && (
          <Badge pill className="float-end" bg="success">
            {badge}
          </Badge>
        )}
      </Card.Header>
      <ListGroup
        variant="flush"
        style={{ cursor: "pointer" }}
        id="ig-session-card"
      >
        {listTitle && <div className={`${listTitleClass}`}>{listTitle}</div>}
        {data.map((item, i) =>
          i <= 4 ? (
            <ListGroup.Item>
              <Component {...item} type={type} />
            </ListGroup.Item>
          ) : (
            <></>
          )
        )}
      </ListGroup>
      <div className="ps-3 pe-3">
        {(!data || data?.length === 0) && <EmptyCardList cardType={cardType} />}
      </div>
      {footerProps && (
        <Card.Footer>
          <Card.Link href="#">
            <Link to={footerProps.url ? footerProps.url : "#"}>
              {" "}
              {footerProps.text}
              <img
                src={_getImage("arrow.svg")}
                height="16"
                className="ms-1"
                alt="arrow"
              />
            </Link>
          </Card.Link>
        </Card.Footer>
      )}
    </Card>
  );
}

export default CardList;
