//** Mentee Dashboard Page **//

import PropTypes from "prop-types";
import { Container, Row, Col, Card, Button, Badge } from "react-bootstrap";
import CardList from "../../../components/CardList";
import UserCard from "../../../components/UserCard";
import RequestCard from "../../../components/RequestCard";
import SessionCard from "../../../components/SessionCard";
import "./styles.scss";
import { _getImage } from "../../../common/utils/commonFunctions";
import { useSelector } from "react-redux";
import { useGetMentorsMentees } from "../../../hooks/use.get.mentor.mentee";
import { useContext } from "react";
import { AuthContext } from "../../../hooks/auth.context.provider";
import useRequests from "../../../hooks/useRequests";
import { useIgurooTranslations } from "../../../hooks/use.iguroo.translation";
import { Link, useNavigate } from "react-router-dom";
import { AppUrlsEnum, CardTypeEnums } from "../../../enums";
import CustomCalendar from "../../../components/CustomCalendar";
import { getAllSessionsInDates } from "../../../redux-tk/slices/session.slice";
import useGetCalendarSessions from "../../../hooks/useGetCalendarSessions";

const MenteeDashboard = (props) => {
  const navigate = useNavigate();
  const { dashboardTranslations } = useIgurooTranslations();
  const profile = useSelector((state) => state.user.profile);
  console.log("LOG profile", profile);

  const sessionsBetweenDates = useSelector((state) =>
    getAllSessionsInDates(state)
  );
  const authContext = useContext(AuthContext);
  const {
    currentSession,
    setCurrentSessions,
    onDateChange,
    onMonthChange,
    isHighlighted,
    isGreyed,
  } = useGetCalendarSessions({
    isByUser: true,
    userId: authContext.sessionInfo.sub,
  });
  const { users, totalRequestsCount } = useRequests({
    id: profile.userId,
    isMentor: false,
  });

  const menteesMentors = useGetMentorsMentees({
    getMentors: false,
    userId: authContext.sessionInfo.sub,
    limit: 4,
  });

  return (
    <Container className="mentee-dashboard">
      <Row>
        <Col lg={12} className="my-requests mt-2 mb-2 ">
          <Row>
            <div class="rrow mb-4">
              <div className="item1">
                <h1>
                  <Link
                    className="float-start float-center mt-1"
                    to={AppUrlsEnum.menteeRequests}
                  >
                    <Link className="titleSmall">
                      {dashboardTranslations?.myRequest}
                    </Link>
                    <Badge className="ms-2" pill bg="success">
                      {totalRequestsCount}
                    </Badge>
                  </Link>
                </h1>
              </div>

              <div className="title item2">
                Welcome {profile.firstName} {profile.lastName}{" "}
              </div>

              <div className="item1">
                <h1>
                  <Link
                    className="float-end mt-1"
                    to={AppUrlsEnum.menteeRequests}
                  >
                    {"All Requests"}
                    <img
                      src={_getImage("arrow.svg")}
                      height="16"
                      className="ms-1"
                      alt="arrow"
                    />
                  </Link>
                </h1>
              </div>
            </div>
            <Row>
              {users.map((req, i) =>
                i <= 2 ? (
                  <>
                    <Col lg={3} className=" d-flex">
                      <Card className="p-3 mb-3 w-100">
                        <RequestCard
                          {...req}
                          type={profile?.type}
                          hideTags={true}
                        />
                      </Card>
                    </Col>
                  </>
                ) : (
                  <></>
                )
              )}

              <Col lg={3} className="d-flex">
                <Card className="p-3 mb-3 new-request">
                  <Button
                    variant="primary"
                    onClick={() => navigate(AppUrlsEnum.findMentor)}
                  >
                    <img
                      src={_getImage("box-white.svg")}
                      className="me-2"
                      height="18"
                      alt="box"
                    />
                    {dashboardTranslations?.createRequest}
                  </Button>
                </Card>
              </Col>
            </Row>
          </Row>
        </Col>
        <Col lg={12}>
          <Row>
            <Col sm={12} md={6} lg={8} className="mb-4">
              <CardList
                title="My Mentors"
                badge={menteesMentors?.length}
                footerProps={{
                  text: "All Mentors",
                  icon: "",
                  url: AppUrlsEnum.menteeMentors,
                }}
                data={menteesMentors}
                view={UserCard}
              />
            </Col>
            <Col sm={12} md={6} lg={4} className="mb-4">
              <CustomCalendar
                onDateChange={onDateChange}
                sessionDates={sessionsBetweenDates}
                onDateMonthChange={onMonthChange}
                isHighlighted={isHighlighted}
                isGreyed={isGreyed}
              />
              <CardList
                title="Sessions"
                headerIcon="headphone.svg"
                listTitle={currentSession.listTitle}
                listTitleClass={"date"}
                data={currentSession.sessions}
                className="no-borders"
                view={SessionCard}
                cardType={CardTypeEnums.session}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default MenteeDashboard;
MenteeDashboard.propTypes = {
  title: PropTypes.string,
};
