//** Iguroo Stripe Payment Success code UI **//

import React, { useEffect, useState } from "react";
import { axiosStaticData } from "../../services/axios/axios.service";
import {
  STRIPE_DETAILS,
  STRIPE_INVOICE,
  STRIPE_MAIL_INVOICE,
} from "../../services/urlConst";
import Spinner from "react-bootstrap/Spinner";
import { Button, Col, Row, Table } from "react-bootstrap";
import moment from "moment";
import toaster from "../../components/toaster";
import { toast } from "react-toastify";
import "./payment.scss";


const StripeSuccess = () => {
  const [sessionInfo, setSessionInfo] = useState(null);
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);
  const [isInvoiceMailLoading, setIsInvoiceMailLoading] = useState(false);

  useEffect(() => {
    const sessionId = localStorage.getItem("stripe_checkout_id");
    axiosStaticData
      .get(STRIPE_DETAILS, {
        params: { session_id: sessionId },
      })
      .then((res) => {
        if (res.data.response) {
          setSessionInfo(res.data.response);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (isInvoiceLoading) {
      console.log("response call isInvoiceLoading", isInvoiceLoading);
      axiosStaticData
        .get(STRIPE_INVOICE, {
          params: { payment_id: sessionInfo?.payment_intent },
        })
        .then((res) => {
          setIsInvoiceLoading(false);
          if (res?.data?.response?.s3_location) {
            console.log("response call invoice", res);
            //  console.log('response call ',res)
            window.open(res?.data?.response?.s3_location, "_blank");
          } else {
            setIsInvoiceLoading(true);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [isInvoiceLoading]);

  useEffect(() => {
    if (isInvoiceMailLoading) {
      console.log("response call isInvoiceLoading", isInvoiceLoading);
      axiosStaticData
        .get(STRIPE_MAIL_INVOICE, {
          params: { payment_id: sessionInfo?.payment_intent },
        })
        .then((res) => {
          setIsInvoiceMailLoading(false);
          if (res?.data?.response) {
            console.log("response call invoice", res);
            //  console.log('response call ',res)
            toaster(toast.success, "Invoice Email Sent");
          } else {
          }
        })
        .catch((err) => console.log(err));
      setIsInvoiceMailLoading(false);
    }
  }, [isInvoiceMailLoading]);

  const invoiveDownload = () => {
    setIsInvoiceLoading(true);
  };
  const invoiveMail = () => {
    setIsInvoiceMailLoading(true);
  };
  console.log(sessionInfo, "s-info");

  return (
    <div className="container">
      {sessionInfo || isInvoiceLoading || isInvoiceMailLoading ? (
        <Row>
          <Col md={8} lg={6}>
            {isInvoiceLoading && (
              <div className="loader-background">
                <div className="stripe_loader">
                  <Spinner animation="border" variant="primary" />
                </div>
              </div>
            )}
            <div className="stripe_success_page w-60">
              <h6 className={`${sessionInfo?.status === "complete" ? "s_text" : "e_test"}`}>{sessionInfo?.status === "complete" ? 'Payment is successful' : 'Payment is failed'}</h6>
              <Table className="success-table table" responsive="sm" bordered>
                <thead></thead>
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td className="td-data">
                      {sessionInfo?.customer_details?.name}
                    </td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td className="td-dataDetail">
                      {sessionInfo?.customer_details?.email}
                    </td>
                  </tr>
                  <tr>
                    <th>Amount</th>
                    <td>{`${sessionInfo?.currency?.toUpperCase()} ${(
                      sessionInfo?.amount_total / 100
                    ).toFixed(2)}`}</td>
                  </tr>
                  <tr>
                    <th>Transaction ID</th>
                    <td className="td-dataDetail">
                      {sessionInfo?.payment_intent
                        ? sessionInfo?.payment_intent
                        : "-NA-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Payment Status</th>
                    <td className="td-data">
                      {sessionInfo?.status === "complete"
                        ? "Payment Succeeded"
                        : "Payment Failed"}
                    </td>
                  </tr>
                  <tr>
                    <th>Mentee Name</th>
                    <td className="td-data">
                      {" "}
                      {`${sessionInfo?.metadata?.menteeFirstName} ${sessionInfo?.metadata?.menteeLastName}`}
                    </td>
                  </tr>
                  <tr>
                    <th>Mentor Name</th>
                    <td className="td-data">{`${sessionInfo?.metadata?.mentorFirstName} ${sessionInfo?.metadata?.mentorLastName}`}</td>
                  </tr>
                  <tr>
                    <th>Request Title</th>
                    <td className="td-data">{sessionInfo?.metadata?.title}</td>
                  </tr>
                  <tr>
                    <th>Scheduled Start</th>
                    <td>
                      {moment
                        .unix(sessionInfo?.metadata?.startTime)
                        .format("DD-MMM-YYYY .  HH:mm ")}
                    </td>
                  </tr>
                </tbody>
                {sessionInfo?.payment_intent ? (
                  <tfoot>
                    <tr>
                      <th colSpan={2}>
                        <Row className="d-flex justify-content-end">
                          <Col>
                            <Button onClick={invoiveDownload}>
                              Download
                            </Button>
                            <Button className="ms-2" onClick={invoiveMail}>
                              Email Invoice
                            </Button>
                          </Col>
                        </Row>
                      </th>
                    </tr>
                  </tfoot>
                ) : (
                  <></>
                )}
              </Table>
            </div>
          </Col>
        </Row>
      ) : (
        <div className="stripe_loader">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </div>
  );
};

export default StripeSuccess;
