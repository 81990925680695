//** Iguroo Assessment,DNDCard Component ,can re-use required Field**//
//** Currently not using this,will be implement in future enhancement**//

import { Card } from "react-bootstrap";

export const DndCard = ({ drag, text, style, className }) => {
  return (
    <>
      <Card
        className={`${className} cursor-move mb-2`}
        style={style}
        ref={drag}
      >
        <Card.Body>{text}</Card.Body>
      </Card>
    </>
  );
};
