export const _getImage = (logName) => {
  return require(`../../assets/images/${logName}`);
};

export const _getCurrencySymbol = (currency) => {
  const symbols = {
    USD: "$",
    INR: "₹",
  };
  return symbols[currency];
};
export const _getVideoUrl = (logName) => {
  return require(`../../assets${logName}`);
};
export const _getquestionImageUrl = (path, logName) => {
  return require(`../../assets${path}${logName}`);
};

export const getSwotFieldName = (swotType, swotPlanType, isPlan) => {
  return isPlan ? swotPlanType : swotType;
};
export function extractOrganization(url) {
  if (url.includes('geniehealth')) {
    return "geniehealth";
  }else if (url.includes('localhost')) {
    return "geniehealth";
  
  } else if (url.includes('ig-web-channel') || url.includes('app')|| url.includes('localhost')) {
    return "iguroo";
  } else {
    return extractGeneralOrganization(url);
  }
}

export function extractGeneralOrganization(url) {
    const regex = /^([^.]+)\./;
    const match = url.match(regex);
  
    if (match && match[1]) {
      return match[1];
    }
  
    // Default to 'default' if no match
    return 'default';
}
