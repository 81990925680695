import AWS from "aws-sdk";
import {
  CreateBucketCommand,
  DeleteObjectCommand,
  PutObjectCommand,
  DeleteBucketCommand,
  UploadPartCommand,
  GetObjectCommand,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { S3Client } from "@aws-sdk/client-s3";
import {
  S3_BUCKET,
  REGION,
  region,
  userPoolId,
  identityPoolId,
} from "../services/config";
import { getAwsCreds, getPublicCredsAsync } from "../helpers/aws.sign.helpers";

// AWS.config.update({
//   region: region,
//   credentials: new AWS.CognitoIdentityCredentials({
//     region: region,
//     IdentityPoolId: identityPoolId,
//     Logins: {
//       [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]:
//         localStorage["idToken"],
//     },
//   }),
// });

// const myBucket = new AWS.S3({
//   params: { Bucket: S3_BUCKET },
//   region: REGION,
// });

export const bucketParams = {
  Bucket: S3_BUCKET,
  Key: `test-object-${Math.ceil(Math.random() * 10 ** 10)}`,
  Body: "BODY",
};

export async function uploadFileToS3(file, folder, userId) {
  const creds = await getAwsCreds();
  if (!creds) {
    return null;
  }
  const s3Client = new S3Client({
    region: REGION,
    apiVersion: "2006-03-01",
    credentials: {
      accessKeyId: creds.accessKeyId,
      secretAccessKey: creds.secretAccessKey,
      sessionToken: creds.sessionToken,
    },
    params: { Bucket: S3_BUCKET },
  });
  const params = {
    //ACL: "public-read",
    Body: file,
    Bucket: S3_BUCKET,
    Key: file.name,
  };
  //https://iguroo-dev.s3.amazonaws.com/good%2520menatal.pdf-4368384665
  //https://iguroo-dev.s3.amazonaws.com/good%20menatal.pdf-4368384665
  //signed url code - not returning url
  const cleanFileName = file.name
    .split(" ")
    .join("")
    .replace(/[^a-zA-Z0-9-.]/g, "");
  bucketParams.Body = file;
  bucketParams.Key = `${userId}/${folder}/${cleanFileName}-${Math.ceil(
    Math.random() * 10 ** 10
  )}`;
  console.log("updated bucker',", bucketParams);
  bucketParams.ACL = "public-read";
  // const get = await getUploadFileFromS3(
  //   "video/6dabbe62-1748-41ec-8d7a-37803b1aeef3/Y2Mate.is-InTheEndOfficialHDMusicVideo-LinkinPark-eVTXPUF4Oz4-480p-1654808918024.mp4-5934920588"
  // );
  // console.log("already uploaded", get);
  const command = new PutObjectCommand(bucketParams);
  const signedUrl = await getSignedUrl(s3Client, command, {
    expiresIn: 3600,
  });
  console.log("got signed url", signedUrl);
  const response = await fetch(signedUrl, {
    method: "PUT",
    body: bucketParams.Body,
    headers: {
      "Content-Type": "application/octet-stream",
    },
  });
  console.log("resp satus", response.status);
  if (response.status === 200) {
    const url = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${bucketParams.Key}`;
    console.log("url", url);
    return {
      url: url,
      key: bucketParams.Key,
    };
  }
  return null;
}

export async function getUploadFileFromS3(key, isPublicProfile) {
  let creds = null;
  if (isPublicProfile) {
    creds = await getPublicCredsAsync();
  } else {
    creds = await getAwsCreds();
  }
  if (!creds) {
    return null;
  }
  console.log("creds are", creds);
  const s3Client = new S3Client({
    region: REGION,
    apiVersion: "2006-03-01",
    credentials: {
      accessKeyId: creds.accessKeyId,
      secretAccessKey: creds.secretAccessKey,
      sessionToken: creds.sessionToken,
    },
    params: { Bucket: S3_BUCKET },
  });
  const getParams = {
    Bucket: S3_BUCKET,
    Key: key,
  };
  const ts = Date.now();
  const expiresIn = 3600;
  const expires = ts + expiresIn;
  const command = new GetObjectCommand(getParams);
  const signedUrl = await getSignedUrl(s3Client, command, {
    expiresIn: expiresIn,
  });
  console.log("signed URL", signedUrl);
  return { url: signedUrl, expires: expires, expiresIn };
}



// export async function getPublicUploadFileFromS3(key) {
//   try {

//     const creds = await getPublicCredsAsync();
//     const s3 = new AWS.S3();
//     const signedUrl = await s3.getSignedUrlPromise('getObject', {
//       Bucket: S3_BUCKET,
//       Key: key,
//     });
//     const ts = Date.now();
//     const expiresIn = 3600;
//     const expires = ts + expiresIn;
//     console.log("s3 public signed url",signedUrl);
//     return { url: signedUrl, expires: expires, expiresIn };
//   } catch (err) {
//     console.log("s3 public error",err);
//     return null;
//   }
// }

export const getdocSignedURL = async (url) => {
  try {
    console.log("Generated Presigned URL PowerPoint data", url);
    AWS.config.update({
      accessKeyId: process.env.AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
      region: "us-east-1",
    });

    // const s3 = new AWS.S3();
    // const signedUrlExpireSeconds = 60 * 60;
    // const urlDetails = url.split("s3://");
    // const urlSplit = urlDetails[1].split("/");
    // const myBucket = urlSplit[0];
    // const myKey = urlSplit[1]+'/'+urlSplit[2];
    const s3 = new AWS.S3();
    const signedUrlExpireSeconds = 60 * 60;
    const urlDetails = url.split(".s3.amazonaws.com/");
    const myBucket = urlDetails[0].split("https://")[1];
    const myKey = urlDetails[1];
    console.log("Generated Presigned URL PowerPoint data", urlDetails, myBucket, myKey);
    const params = {
      Bucket: myBucket,
      Key: myKey,
    };

    const presignedUrl = await s3.getSignedUrlPromise("getObject", {
      ...params,
      Expires: signedUrlExpireSeconds,
    });

    console.log("Generated Presigned URL PowerPoint data", presignedUrl);
    return presignedUrl;
  } catch (error) {
    console.error("Error fetching PowerPoint data", error);
  }
};