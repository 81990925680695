//** Iguroo Dashboard UserData,UserProfile,Availability Slice **//

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetUser,
  GetUserProfile,
  PostUserProfile,
  GetUserAvailability,
} from "../../services/axios/MentorOnbaording";
import { decrementLoader, incrementLoader } from "./loader.slice";

export const getUserAsyncThunk = createAsyncThunk(
  "user/getUserThunk",
  async (data, thunkApi) => {
    if (!data.userId) {
      return thunkApi.rejectWithValue(new Error("Invalid user"));
    }
    try {
      const user = await GetUser(data.userId, true);
      console.log("getUserAsyncThunk user", user);
      return { data: user.data.data, status: user.status };
    } catch (err) {
      console.log("api error", err);
      thunkApi.rejectWithValue(err);
    }
  }
);

export const getUserProfileAsyncThunk = createAsyncThunk(
  "user/getUserProfileThunk",
  async (data, thunkApi) => {
    try {
      const user = await GetUserProfile(data.userId);
      console.log("user", user);
      return user.data;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);

export const getUserAvailabilityAsyncThunk = createAsyncThunk(
  "user/getUserAvailabilityThunk",
  async (data, thunkApi) => {
    try {
      thunkApi.dispatch(incrementLoader());
      const userAvailability = await GetUserAvailability(data.userId);
      return userAvailability.data;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    } finally {
      thunkApi.dispatch(decrementLoader());
    }
  }
);

export const postUserProfileAsyncThunk = createAsyncThunk(
  "user/postUserProfileThunk",
  async (data, thunkApi) => {
    try {
      thunkApi.dispatch(incrementLoader());
      const userProfile = await PostUserProfile(data);
      console.log("data", data);
      return data;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    } finally {
      thunkApi.dispatch(decrementLoader());
    }
  }
);

const initialState = {
  assessmentCompleted: false,
  assessmentPending: false,
  profile: {},
  educationHistory: [],
  workHistory: [],
  userRates: [],
  userAvailability: [],
};

export const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    updateTimeZoneAction: (state, action) => {
      if (!state?.profile) return;
      state.profile.timeZone = action.payload;
    },
    updateOnboardingStage: (state, action) => {
      if (!state?.profile?.onBoardStage) return;
      state.profile.onBoardStage = action.payload;
    },
    removeProfilePicAction: (state, action) => {
      console.log("removing image", action.payload);
      if (action?.payload?.removeName) {
        state.profile[action?.payload?.removeName] = "";
      }
    },
    removeProfileVideoAction: (state, action) => {
      if (action?.payload?.removeName) {
        state.profile[action?.payload?.removeName] = "";
      }
    },
    resetUser: (state, action) => {
      state.assessmentCompleted = false;
      state.assessmentPending = false;
      state.profile = {};
      state.educationHistory = [];
      state.workHistory = [];
      state.userRates = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAsyncThunk.fulfilled, (state, action) => {
        console.log("ac us'", action);
        const data = action?.payload?.data;
        const status = action?.payload?.status;
        const onBoardStage = state?.profile["onBoardStage"];
        if (status === 204 && onBoardStage === undefined) {
          console.log("ac us saving in 204", status, onBoardStage);
          state.profile = {
            onBoardStage: 0,
          };
        }
        if (!data) return;
        if (data?.profile) {
          state.profile = { ...data.profile };
          localStorage.setItem('role', data.profile.type)
        }
        if (data?.educationHistory) {
          state.educationHistory = [...data?.educationHistory];
        }
        if (data?.workHistory) {
          state.workHistory = [...data?.workHistory];
        }
        if (data?.userRates) {
          state.userRates = [...data?.userRates];
        }
        if (data?.assessmentCompleted) {
          state.assessmentCompleted = data?.assessmentCompleted;
        }
        if (data?.assessmentPending) {
          state.assessmentPending = data?.assessmentPending;
        }
      })
      .addCase(getUserAsyncThunk.pending, (state) => {})
      .addCase(getUserAsyncThunk.rejected, (state) => {})
      .addCase(getUserAvailabilityAsyncThunk.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.userAvailability = [...action.payload];
        state.userAvailabilityLoaded = true;
      })
      .addCase(getUserAvailabilityAsyncThunk.pending, (state) => {
        state.userAvailabilityLoaded = false;
      })
      .addCase(getUserAvailabilityAsyncThunk.rejected, (state) => {
        state.userAvailabilityLoaded = "error";
      })
      .addCase(postUserProfileAsyncThunk.fulfilled, (state, action) => {
        console.log("ful", action);
        Object.keys(action.payload).forEach((val) => {
          if (val === "userId") {
            return;
          }
          if (!state?.profile) {
            return;
          }
          console.log("val", val);
          return (state.profile[val] = action.payload[val]);
        });
      })
      .addCase(postUserProfileAsyncThunk.pending, (state) => {})
      .addCase(postUserProfileAsyncThunk.rejected, (state) => {});
  },
});

export const {
  updateTimeZoneAction,
  updateOnboardingStage,
  removeProfilePicAction,
  removeProfileVideoAction,
  resetUser,
} = userSlice.actions;

export const userValue = (state) => state?.user?.profile;

export const userProfileValue = (state) => state?.user?.profile;
export const userProfileAoeValue = (state) =>
  state?.user?.profile?.areaOfExpertise;

export const userProfileOnboardingStageValue = (state) => {
  return state?.user?.profile?.onBoardStage
    ? state?.user?.profile?.onBoardStage
    : 0;
};

export const userProfileOnboardingStageInitialValue = (state) => {
  const onBoardStage = state?.user?.profile["onBoardStage"];
  console.log(
    "using user redux",
    state?.user?.profile,
    state?.user?.profile?.onBoardStage,
    "on",
    onBoardStage
  );

  if (onBoardStage !== undefined) {
    return state.user.profile.onBoardStage;
  } else {
    return null;
  }
};

export default userSlice.reducer;
