import * as yup from "yup";
export function swotAssessmentYup(formErrors) {
  return yup.object({
    strengths: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required(),
          data: yup
            .string()
            .required(formErrors?.swotErrors?.pleaseFillOrDelete)
            .matches(
              /^\s*\S[\s\S]*$/,
              formErrors?.swotErrors?.cannotContainOnlySpaces
            ),
        })
      )
      .required()
      .test({
        message: "The error message if length === 1",
        test: (arr) => arr.length > 0,
      }),
    weaknesses: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required(),
          data: yup
            .string()
            .required(formErrors?.swotErrors?.pleaseFillOrDelete)
            .matches(
              /^\s*\S[\s\S]*$/,
              formErrors?.swotErrors?.cannotContainOnlySpaces
            ),
        })
      )
      .required()
      .test({
        message: "The error message if length === 1",
        test: (arr) => arr.length > 0,
      }),
    opportunities: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required(),
          data: yup
            .string()
            .required(formErrors?.swotErrors?.pleaseFillOrDelete)
            .matches(
              /^\s*\S[\s\S]*$/,
              formErrors?.swotErrors?.cannotContainOnlySpaces
            ),
        })
      )
      .required()
      .test({
        message: "The error message if length === 1",
        test: (arr) => arr.length > 0,
      }),
    threats: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required(),
          data: yup
            .string()
            .required(formErrors?.swotErrors?.pleaseFillOrDelete)
            .matches(
              /^\s*\S[\s\S]*$/,
              formErrors?.swotErrors?.cannotContainOnlySpaces
            ),
        })
      )
      .required()
      .test({
        message: "The error message if length === 1",
        test: (arr) => arr.length > 0,
      }),
    strPlans: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required(),
          data: yup
            .string()
            .required(formErrors?.swotErrors?.pleaseFillOrDelete)
            .matches(
              /^\s*\S[\s\S]*$/,
              formErrors?.swotErrors?.cannotContainOnlySpaces
            ),
        })
      )
      .required()
      .test({
        message: "The error message if length === 1",
        test: (arr) => arr.length > 0,
      }),
    wekPlans: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required(),
          data: yup
            .string()
            .required(formErrors?.swotErrors?.pleaseFillOrDelete)
            .matches(
              /^\s*\S[\s\S]*$/,
              formErrors?.swotErrors?.cannotContainOnlySpaces
            ),
        })
      )
      .required()
      .test({
        message: "The error message if length === 1",
        test: (arr) => arr.length > 0,
      }),
    opoPlans: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required(),
          data: yup
            .string()
            .required(formErrors?.swotErrors?.pleaseFillOrDelete)
            .matches(
              /^\s*\S[\s\S]*$/,
              formErrors?.swotErrors?.cannotContainOnlySpaces
            ),
        })
      )
      .required()
      .test({
        message: "The error message if length === 1",
        test: (arr) => arr.length > 0,
      }),
    thrPlans: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required(),
          data: yup
            .string()
            .required(formErrors?.swotErrors?.pleaseFillOrDelete)
            .matches(
              /^\s*\S[\s\S]*$/,
              formErrors?.swotErrors?.cannotContainOnlySpaces
            ),
        })
      )
      .required()
      .test({
        message: "The error message if length === 1",
        test: (arr) => arr.length > 0,
      }),
  });
}
