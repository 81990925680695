import getEnvUrl, {
  IGUROO_PROFILE_DATA,
  IGUROO_PROFILE_MENTEE_DATA,
  IGUROO_PROFILE_DATA_EDUCATION,
  IGUROO_PROFILE_DATA_EXPERIENCE,
} from "./config";
import {
  FIND_MENTEE_MENTORS,
  FIND_MENTOR_MENTEE,
  STATIC_ALL_DATA,
  STATIC_CITY,
  STATIC_COUNTRY,
  STATIC_STATE,
} from "./urlConst";
import {axiosStaticData } from "./axios/axios.service";

export async function getCountryInfo() {
  try {
    const URL = getEnvUrl() + STATIC_COUNTRY;
    const response = await fetch(URL, {
      method: "GET",
    });

    return (await response.json()).response;
  } catch (err) {}
}

export async function getState(countryCode) {
  const URL = getEnvUrl() + STATIC_STATE + `?country_code=${countryCode}`;
  const response = await fetch(URL, {
    method: "GET",
  });

  return (await response.json()).response;
}

export async function getCities(stateCode) {
  const URL = getEnvUrl() + STATIC_CITY + `?state_code=${stateCode}`;
  const response = await fetch(URL, {
    method: "GET",
  });

  return (await response.json()).response;
}

export async function getAllData() {
  const URL = getEnvUrl() + STATIC_ALL_DATA;
  const response = await fetch(URL, {
    method: "GET",
  });

  return (await response.json()).response;
}

export async function saveMenteeProfile(form) {
  const URL = IGUROO_PROFILE_DATA;
  const resp = await fetch(URL, {
    method: "POST",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });
  console.log("resp", resp);
  return (await resp.json()).message;
}

export async function getAllMenteeUserData(userId = 1667995067784) {
  const URL = IGUROO_PROFILE_MENTEE_DATA + userId;
  const resp = await fetch(URL, {
    method: "GET",
  });
  return await resp.json();
}

export async function saveMenteeEducationDetails(form) {
  const URL = IGUROO_PROFILE_DATA;
  const resp = await fetch(URL, {
    method: "POST",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });
  console.log("resp", resp);
  return (await resp.json()).message;
}

export async function saveEducation(form) {
  const URL = IGUROO_PROFILE_DATA_EDUCATION;
  const resp = await fetch(URL, {
    method: "POST",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });
  console.log("resp", resp);
  return (await resp.json()).message;
}

export async function saveWorkExperience(form) {
  const URL = IGUROO_PROFILE_DATA_EXPERIENCE;
  const resp = await fetch(URL, {
    method: "POST",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });
  console.log("resp", resp);
  return (await resp.json()).message;
}

export async function getMenteesMentors(userId) {
  try {
    const URL = FIND_MENTEE_MENTORS;
    const params = {
      userId: userId,
    };
    const response = await axiosStaticData.get(URL, { params });
    return response.data.response;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}
