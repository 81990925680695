//** Toaster Popup common for all Pages,can use this common Component required Field **//

const toaster = (event, message) => {
  return event(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
export default toaster;
