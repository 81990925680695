//** Iguroo Recommendations for self-Assessment Report**//

import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Col,
  Modal,
  Tab,
  Tabs,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "./recommendation.css";
import { CustomInputField } from "../CustomInput/customInputField";
import { Popup } from "../CustomInput/popup";
import {
  GetRecommendation,
  UpdateRecommendation,
} from "../../services/axios/Recommendation";
import { recommendationYup } from "../../FormSchemas/recommendation.form";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { FormErrorComponent } from "../form.error.component";

const initialRecData = [
  {
    stream_1: "",
    stream_2: "",
    stream_3: "",
    career_1: "",
    career_2: "",
    career_3: "",
  },
];

const strMapping = {
  stream_1: "Stream 1",
  stream_2: "Stream 2",
  stream_3: "Stream 3",
};

const carrMapping = {
  career_1: "Career 1",
  career_2: "Career 2",
  career_3: "Career 3",
};

const Recommendation = (props) => {
  const { formErrors } = useIgurooTranslations();
  const { show, onHide, menteeId, userId, examId, attemptId } = props;
  const [recommendation, setRecommendation] = useState([...initialRecData]);
  const [selectedTab, setSeletedtab] = useState("stream");
  const [showPopup, setShowPopup] = useState(false);
  const handlePopUp = () => {
    setShowPopup(false);
    onHide();
  };
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { currRec: recommendation },
    resolver: yupResolver(recommendationYup(formErrors)),
  });
  
  useEffect(() => {
    (async function () {
      const rec = await GetRecommendation(menteeId);
      if (rec.length > 0) {
        setRecommendation(rec[0].recommendations);
      }
    })();
  }, []);

  useEffect(() => {
    if (recommendation) {
      reset({
        currRec: recommendation,
      });
    }
  }, [recommendation]);

  const onSubmit = async (data) => {
    try {
      const updatedData = {
        userId: menteeId,
        attemptId: attemptId,
        examId: examId,
        modifiedBy: userId,
        attemptStatus: "COMPLETED",
        recommendations: [
          {
            stream_1: data[`currRec`][0]?.stream_1,
            stream_2: data[`currRec`][0]?.stream_2,
            stream_3: data[`currRec`][0]?.stream_3,
            career_1: data[`currRec`][0]?.career_1,
            career_2: data[`currRec`][0]?.career_2,
            career_3: data[`currRec`][0]?.career_3,
          },
        ],
      };
      await UpdateRecommendation(updatedData);
      setShowPopup(true);
    } catch (err) {
      console.log(err);
    }
  };

  const checkIfTabHasError = (tab) => {
    if (Object.keys(errors).length > 0) {
      return Object.keys(tab).some((item) => item in errors[`currRec`][0]);
    }
    return false;
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      backdrop="static"
      className="pop-up"
    >
      <Modal.Header closeButton className="text-center">
        <Modal.Title className="w-100">Recommendations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="m-0 p-0">
          <Col
            className={`m-0 p-3 ${
              selectedTab == "career" ? "rec-career" : "rec-stream"
            }`}
            xs={12}
          >
            <Form onSubmit={handleSubmit(onSubmit)} className="p-2">
              <Tabs
                defaultActiveKey="stream"
                id="uncontrolled-tab-example"
                className={`myClass ${
                  checkIfTabHasError(strMapping) ? "first-tab-error" : ""
                } ${checkIfTabHasError(carrMapping) ? "second-tab-error" : ""}`}
                onSelect={(k) => {
                  setSeletedtab(k);
                }}
              >
                <Tab eventKey="stream" title="Stream Recommendation">
                  <TabComponent
                    control={control}
                    data={strMapping}
                    errors={errors}
                  />
                </Tab>
                <Tab
                  eventKey="career"
                  title="Career Recommendation"
                  className="tabclass"
                >
                  <TabComponent
                    control={control}
                    data={carrMapping}
                    errors={errors}
                  />
                </Tab>
              </Tabs>
              <Col className="text-right mt-2">
                <Button variant="primary" type="submit" className="text-right">
                  Submit
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
      <Popup
        closeOnBackClick={false}
        showPopup={showPopup}
        submitText={`OK`}
        modalBody={`Thank you for providing recommendations`}
        url={""}
        gotIt={handlePopUp}
      />
    </Modal>
  );
};

const TabComponent = ({ control, data, errors }) => {
  return (
    <div className="tab-content">
      {Object.keys(data).map((item, index) => (
        <Form.Group className="m-2">
          <InputGroup>
            <InputGroup.Text>{data[item]}: </InputGroup.Text>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <CustomInputField
                    placeholder={"Provide the recommendation"}
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    rows={2}
                    formAs={"textarea"}
                  />
                );
              }}
              name={`currRec.${0}.${item}`}
            />
          </InputGroup>
          <FormErrorComponent>
            {errors[`currRec`]?.length > 0 &&
              errors[`currRec`][0]?.[`${item}`] &&
              errors[`currRec`][0]?.[`${item}`]?.message}
          </FormErrorComponent>
        </Form.Group>
      ))}
    </div>
  );
};

export default Recommendation;
