//** GinieHealth AutoSuggestion Invite username Popup input field **//

import React, { useState, useEffect } from "react";
import { GetSearchUserOrganizationApi } from "@iguroo/ig-react-assessment-package";
import { organizationValue } from "../../redux-tk/slices/general.info.slice";
import {  useSelector } from "react-redux";

const AutoCompleteCourseUsers = (props) => {
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionIndex, setSuggestionIndex] = useState(0);
  const [suggestionsActive, setSuggestionsActive] = useState(false);
  const [value, setValue] = useState("");
  const [searchedUser, setSearchedUser] = useState([]);
  const organization_id = useSelector((state) => organizationValue(state));


  useEffect(() => {
    setSearchedUser([...props.userData]);
  }, [props.userData]);

  useEffect(() => {
    if (value.length < 3) {
      setSuggestions([]);
    }
  }, [value]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const query = value.toLowerCase();
      if (query.length > 2) {
        (async function () {
          const userData = await GetSearchUserOrganizationApi({
            search: query,
            from: 0,
            size: 10,
            organization_id: organization_id,
          });
          const filterSuggestions = userData?.response?.data?.filter(
            (suggestion) => {
              const fullName = (
                suggestion.firstName + suggestion.lastName
              ).toLowerCase();
              console.log(
                fullName,
                " ////// ",
                query,
                fullName.includes(query) ||
                  fullName.includes(query.trim().split(" ").join("")),
                "////// ",
                query.trim().split(" ").join(""),
                "filterSuggestions"
              );
              return fullName
                .trim()
                .split(" ")
                .join("")
                .includes(query.trim().split(" ").join(""));
            }
          );
          if (searchedUser.length > 0) {
            for (var i = filterSuggestions.length - 1; i >= 0; i--) {
              for (var j = 0; j < searchedUser.length; j++) {
                if (
                  filterSuggestions[i]?.userId ===
                  searchedUser[j]?.userId
                ) {
                  filterSuggestions.splice(i, 1);
                }
              }
            }
          }
          setSuggestions(filterSuggestions);
          setSuggestionsActive(true);
        })();
      } else {
        setSuggestionsActive(false);
      }
    }, 100);
    return () => clearTimeout(delayDebounceFn);
  }, [value]);

  const handleChange = async (e) => {
    setValue(e.target.value);
  };

  const handleClick = (e) => {
    console.log("LOG handleClick", e);
    setSuggestions([]);
    setValue("");
    setSuggestionsActive(false);
  };

  const handleKeyDown = (e) => {
    // UP ARROW
    if (e.keyCode === 38) {
      if (suggestionIndex === 0) {
        return;
      }
      setSuggestionIndex(suggestionIndex - 1);
    }
    // DOWN ARROW
    else if (e.keyCode === 40) {
      if (suggestionIndex - 1 === suggestions.length) {
        return;
      }
      setSuggestionIndex(suggestionIndex + 1);
    }
    // ENTER
    else if (e.keyCode === 13) {
      setValue(suggestions[suggestionIndex]);
      setSuggestionIndex(0);
      setSuggestionsActive(false);
    }
  };

  const Suggestions = () => {
    return (
      <ul className="filterTitle">
        {suggestions.map((suggestion, index) => {
          return (
            <div
              className={index === suggestionIndex ? "active" : ""}
              key={index}
              onClick={() => {
                console.log("LOG click sggg", suggestion);
                setSuggestions([]);
                setValue("");
                setSuggestionsActive(false);
                setSearchedUser([...searchedUser, suggestion]);
                {
                  props.onItemClick(suggestion);
                }
              }}
            >
              {suggestion.firstName} {suggestion.lastName}
            </div>
          );
        })}
      </ul>
    );
  };

  return (
    <div>
      <input
        className="uinput"
        type="text"
        value={value}
        placeholder={"Enter Username"}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      {value && suggestionsActive && <Suggestions />}
    </div>
  );
};

export default AutoCompleteCourseUsers;
