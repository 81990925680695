//** Iguroo Dashboard Request SessionCard **//

import React from "react";
import UserCard from "../UserCard";
import "./styles.scss";

function SessionCard(props) {
  const { session, mentors } = props;
  //console.log("mentors sessions ",mentors);
  return (
    <div className="session-card">
      <div className="date mb-2">{session.date}</div>
      {mentors?.map((mentor) => (
        <div className={`sessions d-flex ${session.grey ? "grey" : ""}`}>
          <div className={`time me-3 ${session.grey ? "grey" : ""}`}>
            <h3>{session.time}</h3>
            <p>{session.timeLeft} min</p>
          </div>
          <UserCard {...mentor} />
        </div>
      ))}
    </div>
  );
}

export default SessionCard;