//** Iguroo & GinieHealth Common Unsaved Popup Component,can re-use any required field **//
//** if it is reusable can modfiy the text as our convinient **//

import React, { useEffect } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { _getImage } from "../../common/utils/commonFunctions";
import { useState } from "react";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { useNavigate } from "react-router-dom";

export function PopupUnsaved(props) {
  const {
text,
    showPopup,
    closeOnBackClick,
    modalBody,
    url,
    gotIt,
    gotIt2,
    title,
    className,
    svgImg,
    hideSVG,
    yes,
    cancel,
  } = props;

  const navigate = useNavigate();
  const { mentorOnboardingLabels } = useIgurooTranslations();
  const [show, setShow] = useState(false);

  const handleStay = () => {
    console.log("LOG POPUP handleStay");
    if (gotIt2) {
      console.log("LOG POPUP gotIt");
      gotIt2();
    }
    setShow(false);
  };

  const handleLeave = () => {
    console.log("LOG POPUP handleLeave");
    if (gotIt) {
      console.log("LOG POPUP gotIt");
      gotIt();
    }
    setShow(false);
    // closeOnBackClick();
    if (url !== undefined && url.length > 1) {
      console.log("LOG POPUP handleLeave navigate", url);
      navigate(url);
    }
  };

  useEffect(() => {
    setShow(showPopup);
  }, [showPopup]);


  return (
    <>
      <Modal
        className={`completed-popup ${className}`}
        show={show}
        onHide={closeOnBackClick ? handleStay : null}
        backdrop="static"
        keyboard={false}
      >
        {
          <Modal.Header className="text-center">
            <Modal.Title className="w-100">
              {title ? title : mentorOnboardingLabels.warning}
            </Modal.Title>
          </Modal.Header>
        }

        <Modal.Body>
          {hideSVG ? (
            <></>
          ) : (
            <div className="text-center mt-4">
              <Image
                className="d-inline-block"
                src={_getImage(svgImg ? svgImg : "green_completed.svg")}
              />
            </div>
          )}
          <div className="popup-body mt-4 text-center mb-4">
            {modalBody ? modalBody : mentorOnboardingLabels?.unsavedWarning}
          </div>
        </Modal.Body>
        <div className="button-container ">
          <Button //Stay on Page
            className="my-button"
            variant="secondary"
            onClick={handleStay}
            required={true}
          >
            {yes ? yes : mentorOnboardingLabels.yes}
          </Button>

          <Button //Leave
            className="my-button1"
            variant="secondary"
            onClick={handleLeave}
            required={true}
          >
            {cancel ? cancel : mentorOnboardingLabels.cancel}
          </Button>
        </div>
      </Modal>
    </>
  );
}
