//** GinieHealth Learner Login,Date&Time QuestionType UI component **//

import { Col } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { SvgImageContainer } from "./SvgImageContainer";
import { CustomInputField } from "../CustomInput/customInputField";

export const DateTime = ({ name, placeholder, control, icon }) => {
  return (
    <Col className="d-flex align-items-center date-time-container" md={3}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <CustomInputField
            hookOnBlur={onBlur}
            hookValue={value}
            hookOnChange={onChange}
            className="custom_input"
            placeholder={placeholder}
          />
        )}
      />
      <SvgImageContainer image={icon} width={17} height={17} />
    </Col>
  );
};
