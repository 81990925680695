import { axiosStaticData } from "../axios.service";
import { IGURU_DEV_URL } from "../../config";
import {
  APPROVE_MEDIA,
  APPROVE_USERS,
  FETCH_ZEGO_TOKEN,
  POST_RATING,
  SESSION_NOTES,
  UPDATE_SESSION,
  USER_APPROVE,
} from "../../urlConst";

export const JoinRoom = async (sessionNumber, requestNumber, userId) => {
  const params = {
    sessionNumber: sessionNumber,
    requestNumber: requestNumber,
    userId: userId,
  };
  const URL = FETCH_ZEGO_TOKEN;
  const res = await axiosStaticData.get(URL, { params });
  return res.data.response;
};

export const updateSession = async (data) => {
  const URL = UPDATE_SESSION;
  const res = await axiosStaticData.put(URL, data);
  return res.data.response;
};

export const postSessionNotes = async (
  text,
  requestId,
  sessionId,
  fromMentor
) => {
  const params = {
    requestNumber: requestId,
  };
  const URL = SESSION_NOTES;
  const data = {
    sessionNumber: sessionId,
    note: text,
    status: "open",
    fromMentor: fromMentor,
  };
  const res = await axiosStaticData.post(URL, data, { params });
  return res.data.response;
};

export const getRating = async (mentorId) => {
  if(!mentorId) return;
  const URL = POST_RATING;
  const params = {
    mentorId: mentorId,
  };
  const res = axiosStaticData.get(URL, { params });

  return res;
};

export const getApproveUsers = async () => {
  const URL = `${APPROVE_USERS}`;
  const res = axiosStaticData.get(URL);
  return res;
};

export const getApproveMedia = async () => {
  const URL = `${APPROVE_MEDIA}`;
  const res = axiosStaticData.get(URL);
  return res;
};

export const downloadSessionNotesFromApi = (data) => {
  const params = {
    sessionNumber: data.sessionNumber,
    // requestNumber: data.requestNumber,
    // sessionNoteId: data.sessionNumber,
  };
  const URL = SESSION_NOTES;
  const res = axiosStaticData.get(URL, { params });
  return res;
};
