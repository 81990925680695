//** GinieHealth Learner Login,ImageContainer QuestionType UI component **//

import { _getImage } from "../../common/utils/commonFunctions";

export const SvgImageContainer = ({
  onClick,
  image,
  className,
  height = 22,
  width = 22,
}) => {
  return (
    <img
      src={_getImage(image)}
      height={height}
      width={width}
      className={`img-btn ${className ? className : ""}`}
      role="button"
      onClick={onClick ? onClick : null}
    />
  );
};
