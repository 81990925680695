//** Iguroo Security Details page Code Ui of name,email,password,MobNo **//

import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Form, Row, Col } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { CustomInputField } from "../../../components/CustomInput/customInputField";
import { getSettingsFromTranslated } from "../../../FormSchemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormErrorComponent } from "../../../components/form.error.component";
import { SubmitButton } from "../../../components/submit.button";
import {
  changeEmail,
  changeName,
  changePassword,
  getAttributes,
} from "../../../services/cognito.service";
import { useSelector, useDispatch } from "react-redux";
import Toaster from "../../../components/toaster";
import { toast } from "react-toastify";
import { CustomPasswordInputField } from "../../../components/customPasswordInputField";
import { useIgurooTranslations } from "../../../hooks/use.iguroo.translation";
import { getProfileObject } from "../../../helpers";
import { PostUserProfile } from "../../../services/axios/MentorOnbaording";
import { AuthContext } from "../../../hooks/auth.context.provider";
import { showPopupflag } from "../../../redux-tk/slices/showPopupflag.slice";

const Security = () => {
  const dispatch = useDispatch();
  const {
    profileLabels,
    formElementLabels,
    formErrors,
    dashboardTranslations,
  } = useIgurooTranslations();
  const authContext = useContext(AuthContext);

  const user = useSelector((state) => state.user.profile);
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isDirty, dirtyFields, touchedFields },
    setValue,
  } = useForm({
    resolver: yupResolver(getSettingsFromTranslated(formErrors)),
    defaultValues: { firstname: "", email: "", mobileNumber: "", lastname: "" },
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    getAttributes()
      .then((res) => {
        if (res) {
          res.map((user) => {
            const data = JSON.parse(user);
            if (data.Name === "phone_number") {
              setValue("mobileNumber", data.Value);
            }
            if (data.Name === "email") {
              setEmail(data?.Value);
              setValue("email", data?.Value);
            }
            if (data.Name === "given_name") {
              setFirstName(data?.Value);
              setValue("firstname", data?.Value);
            }
            if (data.Name === "family_name") {
              setLastName(data?.Value);
              setValue("lastname", data?.Value);
            }
          });
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const onChanged = () => {
    if (isDirty) {
      (async function () {
        dispatch(showPopupflag(true));
      })();
    }
  };

  const onSubmit = async (data) => {
    const requests = [];
    let passwordChanged = false;
    let emailChanged = false;
    let nameChanged = false;
    if (data.currentPassword && data.newPasswordSecurity) {
      requests.push(
        changePassword(data.currentPassword, data.newPasswordSecurity)
          .then((res) => {
            passwordChanged = true;
          })
          .catch((err) => {
            if (err.code === "UserNotConfirmedException") {
              setError("userNotConfirmedError", { message: err.message });
            } else {
              setError("apiError", { message: "Incorrect Password" });
            }
          })
      );
    }
    // console.log(firstName,lastName,data.firstname,data.lastname)
    if (firstName !== data.firstname || lastName !== data.lastname) {
      requests.push(
        changeName(data.firstname, data.lastname)
          .then(async (res) => {
            nameChanged = true;
            const dataFinal = {};
            dataFinal.firstName = data.firstname;
            dataFinal.lastName = data.lastname;
            const finalData = getProfileObject(
              dataFinal,
              authContext.sessionInfo.sub
            );
            // console.log(finalData,'callstart');
            const result = await PostUserProfile(
              finalData,
              authContext.sessionInfo.sub
            );
          })
          .catch((err) => {
            if (err.code === "UserNotConfirmedException") {
              setError("userNotConfirmedError", { message: err.message });
            } else {
              setError("apiError", { message: err.message });
            }
          })
      );
    }

    if (!(email === data.email)) {
      requests.push(
        changeEmail(data.email, data.currentPassword)
          .then(async (res) => {
            emailChanged = true;
            const dataFinal = {};
            dataFinal.email = data.email;
            const finalData = getProfileObject(
              dataFinal,
              authContext.sessionInfo.sub
            );
            // console.log(finalData,'callstart');
            const result = await PostUserProfile(
              finalData,
              authContext.sessionInfo.sub
            );
          })
          .catch((err) => {
            if (err.code === "UserNotConfirmedException") {
              setError("userNotConfirmedError", { message: err.message });
            } else {
              setError("apiError", { message: err.message });
            }
          })
      );
    }
    await Promise.all(requests);

    console.log("Password changed:", passwordChanged);
    console.log("Name changed:", nameChanged);

    if (passwordChanged && nameChanged) {
      Toaster(toast.success, "Name and Password changed successfully");
    } else if (passwordChanged && !nameChanged) {
      Toaster(toast.success, "Password changed successfully");
    } else if (!passwordChanged && nameChanged) {
      Toaster(toast.success, "Name changed successfully");
    }
    if (emailChanged) {
      Toaster(toast.success, "Email changed successfully");
    }
  };

  return (
    <Container className="m-0 p-0 manrope-font px-2">
      <Form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <p className="fs-18 fw-700 ps-2 id-security-head">
            {profileLabels?.personalInfo}
          </p>
          <Row className="m-0 p-0">
            <Col md={6} className="m-0 p-0 p-2">
              <Form.Label>{dashboardTranslations?.firstName}</Form.Label>
              <Controller
                name="firstname"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomInputField
                    type="text"
                    className="sign-input w-100"
                    placeholder={formElementLabels?.placeHolders?.firstName}
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    onChange={onChanged}
                  />
                )}
              />
              <FormErrorComponent>
                {errors.firstname && errors.firstname?.message}
              </FormErrorComponent>
            </Col>
            <Col md={6} className="m-0 p-0 p-2">
              <Form.Label>{dashboardTranslations?.lastName}</Form.Label>
              <Controller
                name={`lastname`}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomInputField
                    type="text"
                    className="sign-input w-100"
                    placeholder={formElementLabels?.placeHolders?.lastName}
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    onChange={onChanged}
                  />
                )}
              />
              <FormErrorComponent>
                {errors.lastname && errors.lastname?.message}
              </FormErrorComponent>
            </Col>
          </Row>
          <p className="fs-18 fw-700 mt-4 ps-2 id-security-head">
            {profileLabels?.contactDetails}
          </p>
          <Row className="m-0 p-0">
            <Col md={6} className="m-0 p-0 p-2">
              <Form.Label>
                {formElementLabels?.placeHolders?.mobileNo}
              </Form.Label>
              <Controller
                name="mobileNumber"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomInputField
                    type="text"
                    className="sign-input w-100"
                    placeholder={formElementLabels?.placeHolders?.mobileNo}
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    disabled={true}
                    hookValue={value}
                    onChange={onChanged}
                  />
                )}
              />
              <FormErrorComponent>
                {errors.mobileNumber && errors.mobileNumber?.message}
              </FormErrorComponent>
            </Col>
            <Col md={6} className="m-0 p-0 p-2">
              <Form.Label>{formElementLabels.emailSigup}</Form.Label>
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomInputField
                    type="text"
                    className="sign-input w-100"
                    placeholder={formElementLabels?.placeHolders?.email}
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    onChange={onChanged}
                  />
                )}
              />
              <FormErrorComponent>
                {errors.email && errors.email?.message}
              </FormErrorComponent>
            </Col>
          </Row>
          <p className="fs-18 fw-700 mt-4 ps-2 id-security-head">
            {profileLabels?.changePassword}
          </p>
          <Row className="m-0 p-0">
            <Col md={4} className="m-0 p-0 p-2">
              <Form.Label>{formElementLabels?.currentPassword}</Form.Label>
              <Controller
                name={`currentPassword`}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomPasswordInputField
                    type="password"
                    className="security-password"
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                  />
                )}
              />
              <FormErrorComponent>
                {errors.currentPassword && errors.currentPassword?.message}
              </FormErrorComponent>
            </Col>
            <Col md={4} className="m-0 p-0 p-2">
              <Form.Label>{formElementLabels?.newPasswordSecurity}</Form.Label>
              <Controller
                name={`newPasswordSecurity`}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomPasswordInputField
                    type="password"
                    className="security-password"
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                  />
                )}
              />
              <FormErrorComponent>
                {errors.newPasswordSecurity &&
                  errors.newPasswordSecurity?.message}
              </FormErrorComponent>
            </Col>
            <Col md={4} className="m-0 p-0 p-2">
              <Form.Label>{formElementLabels?.repeatPassword}</Form.Label>
              <Controller
                name={`repeatPassword`}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomPasswordInputField
                    type="password"
                    className="security-password"
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                  />
                )}
              />
              <FormErrorComponent>
                {errors.repeatPassword && errors.repeatPassword?.message}
              </FormErrorComponent>
            </Col>
          </Row>
          <Row className="mt-1 mb-3">
            <FormErrorComponent>
              {errors.apiError && errors.apiError?.message}
            </FormErrorComponent>
          </Row>
        </Form.Group>
        <Row className="m-0 p-0">
          <Col className="m-0 p-0 p-2">
            <span className="float-end mt-3">
              <SubmitButton type="submit" text={formElementLabels?.save} />
            </span>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default Security;
