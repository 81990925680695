//** GinieHealth Learner Login,TestQuestion Question UI component code **//
//** used as Text Heading Question **//

import React from "react";
import "./styles.scss";

export function TestQuestionComponent(props) {
  const { text, className, svgName, queNo } = props;
  const questionText = props.children?.length > 0 ? props.children : text;
  const questionSplit = questionText?.split("\n");
  console.log(questionSplit, "console");
  return (
    <div className={`ig-text-question ${className}`}>
      <span>
        {queNo} {". "}
      </span>
      {text}
    </div>
  );
}
