//** GinieHealth Learner Login,DropDown Question UI component code **//

import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "./component/styles.scss";
import { useForm, Controller } from "react-hook-form";
import { TestQuestionComponent } from "./component/question.component";
import { CustomSelect } from "../CustomInput/custom.select";

const DropDownQuestion = (props) => {
  const { control, handleSubmit } = useForm();
  const options = [
    { value: "Blue" },
    { value: "Red" },
    { value: "Black" },
    { value: "Orange" },
    { value: "Green" },
  ];

  const onSubmit = () => {};

  return (
    <Container className="">
      <Row className="d-flex justify-content-center">
        <Col sm="11">
          <Row>
            <Col>
              <TestQuestionComponent text="What is your name?" queNo="02" />
            </Col>
          </Row>
          <Row className="test-answer-block p-4 mt-2">
            <Form onSubmit={handleSubmit(onSubmit)} className="mb-3">
              <Row className="mb-3">
                <Col md={5}>
                  {" "}
                  <Controller
                    name={"answerOption"}
                    control={control}
                    render={({ field: { onChange, onBlur, value, name } }) => (
                      <CustomSelect
                        opLabel="value"
                        opValue="value"
                        items={options}
                        onReactHookFormBlur={onBlur}
                        onReactHookFormChange={onChange}
                        value={value}
                        hideSelectedOptions={false}
                        sendFullValueInHook={true}
                        normalSelect={true}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Form>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default DropDownQuestion;
