//** Iguroo & GinieHealth Common CustomSelect InputField Component,can re-use any required field **//

import React, { useState } from "react";
import Select from "react-select";
require(`../../css/${process.env.REACT_APP_CSS_INPUT}`);

export function CustomSelect(props) {
  const {
    controlStyle,
    valueContainerStyle,
    clearIndicatorStyle,
    dropdownIndicatorStyle,
    inputStyle,
    opLabel,
    opValue,
    items,
    onReactHookFormChange,
    onReactHookFormBlur,
    onChangeCallback,
    value,
    normalSelect,
    placeholder,
    className,
    isMulti,
    ref,
    sendFullValueInHook,
    isLoading,
    defaultValue,
    hideSelectedOptions,
    isDisabled,
    isOptionDisabled,
  } = props;
  const [cssClassNamePrefix, setCssClassNamePrefix] = useState("custom-select");

  const styles = {
    control: (base, state) => ({
      ...base,
      ...controlStyle,
      // minHeight: 'initial',
      // marginRight: "1.1em"
    }),
    valueContainer: (base, state) => ({
      ...base,
      ...valueContainerStyle,
      backgroundColor: state.isSelected ? "" : "#ffffff",
      // height: `${targetHeight - 1 - 1}px`,
      // padding: '0 8px',
      // fontSize: "0.8em"
    }),
    clearIndicator: (base, state) => ({
      ...base,
      ...clearIndicatorStyle,
      backgroundColor: state.isSelected ? "" : "#ffffff",
      //padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      ...dropdownIndicatorStyle,
      backgroundColor: state.isSelected ? "" : "#ffffff",
      //padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
    }),
    input: (base, state) => ({
      ...base,
      ...inputStyle,
    }),
  };

  return (
    <Select
      {...(placeholder ? { placeholder: placeholder } : {})}
      {...(ref ? { ref: ref } : {})}
      className={className}
      isLoading={isLoading}
      styles={styles}
      isMulti={isMulti ? isMulti : false}
      //  unstyled={true}
      hideSelectedOptions={hideSelectedOptions ? hideSelectedOptions : true}
      classNamePrefix={cssClassNamePrefix}
      getOptionLabel={(op) => op[opLabel]}
      getOptionValue={(op) => op[opValue]}
      value={normalSelect ? value : items?.find((c) => c[opValue] === value)}
      options={items}
      onBlur={onReactHookFormBlur ? onReactHookFormBlur : () => {}}
      onChange={(object) => {
        setCssClassNamePrefix("custom-select-with-value");
        if (onReactHookFormChange)
          onReactHookFormChange(sendFullValueInHook ? object : object[opValue]);
        //console.log("ob", object);
        //value={options.find((c) => c.value === value)}
        if (onChangeCallback)
          onChangeCallback(sendFullValueInHook ? object : object[opValue]);
      }}
      {...(defaultValue ? { defaultValue: defaultValue } : {})}
      isDisabled={isDisabled ? true : false}
      noOptionsMessage={() => null}
      {...(isOptionDisabled && { isOptionDisabled: isOptionDisabled })}
    />
  );
}
