//** Iguroo SWOT Assessment ->Swot SingleList Item Component,Currently not using this code,will be implemented in future **//

import { Col, Row } from "react-bootstrap";
import { CustomAddMoreBtn } from "../CustomInput/customAddMoreBtn";
import { useFieldArray } from "react-hook-form";
import { useIgurooTranslations } from "../../hooks";
import { SwotSingleItem } from "./SwotSingleItem";
import { useMemo } from "react";
import { SwotTypesEnum } from "../../enums";
import uuid from "react-uuid";
import { getSwotFieldName } from "../../common/utils/commonFunctions";

export const SwotSingleListComponent = ({
  swotType,
  swotPlanType,
  swotName,
  control,
  isPlan,
  errors,
  getValues,
  data,
  updateSwotData,
  addSwotData,
  deleteSwotData,
  swotFieldName,
}) => {
  const { formElementLabels } = useIgurooTranslations();
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: getSwotFieldName(swotType, swotPlanType, isPlan), // unique name for your Field Array
      shouldUnregister: true,
    }
  );

  // const swotFieldName = useMemo(() => {
  //   return getSwotFieldName(swotType, swotPlanType, isPlan);
  // }, [swotType, swotPlanType, isPlan]);

  const addAvailabilityInputs = () => {
    console.debug("adding to ", swotFieldName);
    append({
      id: uuid(),
      data: "",
    });
  };

  const removeAvailabilityInputs = (inputIndex) => {
    console.log("input index", inputIndex);
    if (fields.length > 1) {
      const value = getValues(
        `${isPlan ? swotPlanType : swotType}.${inputIndex}.id`
      );
      //console.debug("value found for deletin",value,"data",swotPlanType,swotType,isPlan,"str",`${isPlan ? swotPlanType : swotType}.${inputIndex}.id`)
      if (value) {
        remove(inputIndex);
        return;
      }
      //console.log('dele"',field,value);
      //dispatch(deleteAvailabilityAsyncThunk({ id: value }));
    }
  };

  const addPlaceHolder = useMemo(() => {
    let placeHolder = "";
    switch (swotType) {
      case SwotTypesEnum.opportunities:
        placeHolder =
          formElementLabels?.placeHolders?.swotTypes[
            `addOpportunities${isPlan ? "Plan" : ""}`
          ];
        break;
      case SwotTypesEnum.strength:
        placeHolder =
          formElementLabels?.placeHolders?.swotTypes[
            `addStrength${isPlan ? "Plan" : ""}`
          ];
        break;
      case SwotTypesEnum.threats:
        placeHolder =
          formElementLabels?.placeHolders?.swotTypes[
            `addThreat${isPlan ? "Plan" : ""}`
          ];
        break;
      case SwotTypesEnum.weakness:
        placeHolder =
          formElementLabels?.placeHolders?.swotTypes[
            `addWeakness${isPlan ? "Plan" : ""}`
          ];
        break;
      default:
        break;
    }
    return placeHolder;
  }, [swotType, formElementLabels]);

  return (
    <>
      <div className="my-tab-content">
        {fields.map((field, index) => {
          //console.log("fil'", field);
          return (
            <SwotSingleItem
              key={field.id}
              //items={availabilityDays}
              removeFunction={removeAvailabilityInputs}
              hookControl={control}
              itemIndex={index}
              errors={errors}
              swotType={swotType}
              swotPlanType={swotPlanType}
              swotName={swotName}
              swotFieldName={swotFieldName}
              isPlan={isPlan}
            />
          );
        })}
      </div>
      <Row className="mt-3">
        <Col className="d-flex justify-content-center">
          <CustomAddMoreBtn
            text={addPlaceHolder}
            //className="float-end"
            onClick={addAvailabilityInputs}
          />
        </Col>
      </Row>
    </>
  );
};
