//** Iguroo Admin ProfileLink for Mentee and Mentor for AdminTables**//

import React from "react";
import { Link } from "react-router-dom";
import { ProfileType } from "../../enums";

const ProfileLink = (props) => {
  console.log("l-props", props);
  return (
    <Link
      style={{ textDecoration: "none", color: "black" }}
      to={
        props.data["User type"] === ProfileType.mentor
          ? `/admin/mentordetails/${props.data.userId}`
          : props.data["User type"] === ProfileType.mentee
          ? `/admin/menteedetails/${props.data.userId}`
          : `/admin/${props.data.userId}`
      }
    >
      {props.data.User}
    </Link>
  );
};

export default ProfileLink;
