//** AssessmentLists like Exam Title & Description for Iguroo **//

import React, { useEffect, useState } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import Breadcrum from "../../components/breadcrumb/Breadcrumb";
import { Heading } from "../../components/heading.component";
import { CardSecondaryComponent } from "../../components/cardComponent/card.secondary.text";
import { _getImage } from "../../common/utils/commonFunctions";
import { useDispatch } from "react-redux";
import { GetExamDetails } from "../../services/axios/Exam";
import { loader } from "../../redux-tk/slices/loader.slice";
import { SubmitButton } from "../../components/submit.button";
import { AppUrlsEnum } from "../../enums";
import { useNavigate } from "react-router-dom";

const TestAssessmentsLists = () => {
  const [examDetails, setExamDetails] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    (async function () {
      dispatch(loader(true));
      GetExamDetails("IGUROO_ASSESSMENT")
        .then((res) => {
          const examDet = res?.response.Items;
          console.log(res, examDet, "console");
          setExamDetails(examDet);
        })
        .catch((err) => console.log(err));

      dispatch(loader(false));
    })();
  }, []);
  const navigate = useNavigate();
  
  return (
    <Container>
      <Row>
        <Col md="12" className="mt-4 d-flex justify-content-center">
          <Breadcrum />
        </Col>
      </Row>
      <Row>
        <Col md="12" className="mt-4 text-center">
          <Heading className="headingDashboard">TEST</Heading>
        </Col>
      </Row>
      <Row>
        <Col md="12" className="mt-4 text-center">
          <CardSecondaryComponent>
            All mentors have at least 5 years of experience in their
            professional fields.
          </CardSecondaryComponent>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center my-4">
        {examDetails?.map((details, index) => (
          <Card key={index} className="p-2 col-sm-10 ">
            <Row>
              <Col md={4}>
                <img src={_getImage("self-discovery.png")} width={"100%"} />
              </Col>
              <Col>
                <div className="fw-700 fs-18">
                  <img src={_getImage("fileTick.svg")} />
                  <b> {details?.exam_name}</b>
                </div>
                <p className="border-bottom fw-400 fs-15 py-4">
                  {details?.exam_about_test}
                </p>
                <Row>
                  <Col md={3}>
                    <SubmitButton
                      text="Start Test"
                      className="mt-1 fw-500"
                      onClick={() =>
                        navigate(AppUrlsEnum.testAssesment, {
                          state: {
                            examType: details?.examType,
                            examId: details?.examId,
                          },
                        })
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        ))}
      </Row>
    </Container>
  );
};

export default TestAssessmentsLists;
