import React from "react";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { MyMenteesList } from "./mentor.monteeList";

const MentorMentees = () => {
    const { dashboardTranslations } = useIgurooTranslations();
    return (<>
    <MyMenteesList/>
    </>)
}

export default MentorMentees;