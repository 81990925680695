//** GinieHealth Learner Login,Questions Type **//

export const dropdownItems = [
  {
    title: "Short answer",
    icon: "short-answer.svg",
    eventKey: "1",
    divider: false,
    type: "short_answer",
  },
  {
    title: "Long answer",
    icon: "long-answer.svg",
    eventKey: "2",
    divider: true,
    type: "long_answer",
  },
  {
    title: "Multiple choice",
    icon: "multiple-choice.svg",
    eventKey: "3",
    divider: false,
    type: "mcq",
  },
  {
    title: "Checkboxes",
    icon: "checkboxes.svg",
    eventKey: "4",
    divider: false,
    type: "checkbox",
  },
  {
    title: "Dropdown",
    icon: "dropdown.svg",
    eventKey: "5",
    divider: false,
    type: "dropdown",
  },
  {
    title: "Linear Scale",
    icon: "linear-scale.svg",
    eventKey: "6",
    divider: false,
    type: "linear_scale",
  },
  {
    title: "Multiple choice grid",
    icon: "multiple-choice-grid.svg",
    eventKey: "7",
    divider: false,
    type: "mcq_grid",
  },
  {
    title: "Checkbox grid",
    icon: "checkbox-grid.svg",
    eventKey: "8",
    divider: true,
    type: "checkbox_grid",
  },
  {
    title: "Date",
    icon: "date.svg",
    eventKey: "9",
    divider: false,
    type: "date",
  },
  {
    title: "Time",
    icon: "time.svg",
    eventKey: "10",
    divider: false,
    type: "time",
  },
  {
    title: "Drag & Drop",
    icon: "dropdown.svg",
    eventKey: "11",
    divider: false,
    type: "drag_drop",
  },
];
