import * as yup from "yup";
export function getEduWorkExpFormTranslated(formErrors) {
  return yup.object({
    wrk: yup.array().of(
      yup.object().shape(
        {
          id: yup.string().optional(),
          role: yup
            .string()
            .ensure()
            .when(["company", "year"], {
              is: (company, year) => !!company || !!year,
              then: () =>
                yup
                  .string()
                  .required(formErrors?.roleIsRequired)
                  .typeError(formErrors?.roleIsRequired)
                  .matches(
                    /^[a-zA-Z0-9\s&,().-]+$/,
                    formErrors?.nameNumSplCharcError
                  ),
            }),
          company: yup.string().when(["role", "year"], {
            is: (role, year) => !!role || !!year,
            then: () =>
              yup
                .string()
                .required(formErrors?.companyIsRequired)
                .typeError(formErrors?.companyIsRequired)
                .matches(
                  /^[a-zA-Z0-9\s&,().-]+$/,
                  formErrors?.nameNumSplCharcError
                ),
          }),
          year: yup
            .number()
            .integer()
            .transform((_, val) => {
              console.log(val, "callstart");
              return val !== "" ? Number(val) : null;
            })
            .when(["role", "company"], {
              is: (role, company) => !!role || !!company,
              then: () =>
                yup
                  .number()
                  .typeError(formErrors?.yearIsRequired)
                  .required(formErrors?.yearIsRequired)
                  .positive(formErrors?.yearIsRequired)
                  .integer()
                  .max(99, formErrors?.yearodExpLimit),
              otherwise: () =>
                yup
                  .number()
                  .nullable()
                  .optional()
                  .transform((_, val) => {
                    console.log(val, "callstart");
                    return val !== "" || val !== 0 ? Number(val) : null;
                  }),
            }),
        },
        [
          ["role", "company"],
          ["role", "year"],
          ["year", "company"],
        ]
      )
    ),
    edu: yup.array().of(
      yup.object({
        id: yup.string().optional(),
        instituteName: yup
          .string()
          .required(formErrors?.instituteNameIsRequired)
          .matches(/^[a-zA-Z0-9\s&,().-]+$/, formErrors?.nameNumSplCharcError),
        specialization: yup
          .object({
            value: yup.string(),
            dataId: yup.string(),
            dataType: yup.string(),
          })
          .required(formErrors?.specializationIsRequired)
          .typeError(formErrors?.specializationIsRequired),
        year: yup
          .object({
            value: yup.string(),
            dataId: yup.string(),
            dataType: yup.string(),
          })
          .required(formErrors?.educationYearIsRequired)
          .typeError(formErrors?.educationYearIsRequired),
      })
    ),
  });
}
