//** Key and Value of GridColumn Translations -> Check with Value name in en.language.json **//

export function GridColumnDefinitions(t) {
  return {
    key: "gridColumnsDefinitions",
    value: {
      request: t("gridColumnDefinitations.request"),
      category: t("gridColumnDefinitations.category"),
      mentee: t("gridColumnDefinitations.mentee"),
      mentor: t("gridColumnDefinitations.mentor"),
      status: t("gridColumnDefinitations.status"),
      upcomingSessions: t("gridColumnDefinitations.upcomingSessions"),
      noDataToShow: t("gridColumnDefinitations.noDataToShow"),
      noDataRequests: t("gridColumnDefinitations.noDataRequests"),
      noDataMenteeUsers: t("gridColumnDefinitations.noDataMenteeUsers"),
      noDataMentorUsers: t("gridColumnDefinitations.noDataMentorUsers"),
      noAssessments: t("gridColumnDefinitations.noAssessments")
    },
  };
}
