//** After Selection Popup,Displayed the video,continue Page contnet for Iguroo **//

import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Heading } from "../../components/heading.component";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { CardSecondaryComponent } from "../../components/cardComponent/card.secondary.text";
import { _getImage, _getVideoUrl } from "../../common/utils/commonFunctions";
import { ButtonFooter } from "../../components/assessment/components/buttonfooter";
import { useDispatch } from "react-redux";
import { loader } from "../../redux-tk/slices/loader.slice";
import { GetExamDetails } from "../../services/axios/Exam";
import { useLocation, useNavigate } from "react-router-dom";
import { AppUrlsEnum, Languages, ProfileType } from "../../enums";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import { AuthContext } from "../../hooks/auth.context.provider";
import { getExamAttemptAsyncThunk } from "../../redux-tk/slices/exam.currentQuestion";
import { QuestionAboutComponent } from "../../components/assessment/components/aboutus.question";
import { useTranslation } from "react-i18next";

const TestAssessments = () => {
  const { testAssessmentTranslations } = useIgurooTranslations();
  const { i18n } = useTranslation();
  const [examDetails, setExamDetails] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const [lang, setLang] = useState(Languages.english);

  useEffect(() => {
    const langStatus = localStorage.getItem("assessmentlanguage");
    if (langStatus?.length > 1) {
      i18n.changeLanguage(langStatus?.toLowerCase());
      setLang(langStatus.toLowerCase());
    } else {
      i18n.changeLanguage(Languages.english);
    }
  }, []);

  useEffect(() => {
    (async function () {
      dispatch(loader(true));
      const pageState = location?.state;
      GetExamDetails(pageState?.examType)
        .then((res) => {
          const examDet = res?.response?.Items[0];

          setExamDetails(examDet, res);
        })
        .catch((err) => console.log(err));
      const examData = {
        examId: pageState?.examId,
        userId: authContext.sessionInfo.sub,
      };
      dispatch(getExamAttemptAsyncThunk(examData));
      dispatch(loader(false));
    })();
  }, []);

  return (
    <Container>
      <Row></Row>
      <Row>
        <Col md="12" className="mt-4 text-center">
          <Heading className="headingDashboard">
            {console.log(`exam_name_${lang}`, "console 80")}
            {lang == Languages.english
              ? examDetails?.exam_name?.toUpperCase()
              : examDetails?.[`exam_name_${lang}`]?.toUpperCase()}
          </Heading>
        </Col>
      </Row>
      <Row>
        <Col md="12" className="mt-4 text-center">
          <CardSecondaryComponent>
            {lang == Languages.english
              ? examDetails?.exam_description
              : examDetails?.[`exam_description_${lang}`]}
          </CardSecondaryComponent>
        </Col>
      </Row>
      <Row>
        <Col lg="7" md={8} className="py-4 mx-auto ig-exam-video">
          {/* //{console.log(examDetails?.video_location,'console',examDetails?.video_location !=="" , examDetails?.video_location != undefined)} */}
          {examDetails?.video_location !== "" &&
          examDetails?.video_location != undefined ? (
            <Plyr
              source={{
                type: "video",
                sources: [
                  {
                    src: _getVideoUrl(examDetails?.video_location),
                  },
                ],
              }}
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Image
            height={20}
            width={20}
            src={_getImage("infoIcon.svg")}
            className=""
          />{" "}
          <b>{testAssessmentTranslations?.aboutTest}</b>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="mt-2">
            <QuestionAboutComponent>
              {lang == Languages.english
                ? examDetails?.exam_about_test
                : examDetails?.[`exam_about_test_${lang}`]}
            </QuestionAboutComponent>
          </p>
        </Col>
      </Row>
      <Row>
        <Col></Col>
      </Row>
      <Row className="mb-4 mt-auto">
        <ButtonFooter
          firstButton={testAssessmentTranslations?.backTest}
          onFirstBtnClick={() => {
            if (authContext.sessionInfo.role === ProfileType.mentee)
              navigate(AppUrlsEnum.selfDiscovery);
            else if (authContext.sessionInfo.role === ProfileType.mentor) {
              navigate(AppUrlsEnum.selfDiscoveryMentor);
            }
          }}
          svgFirst={"arrow-left-primary.svg"}
          secondBtn={testAssessmentTranslations?.buttonContinue}
          onSecondBtnClick={() =>
            navigate(AppUrlsEnum.testAssesmentsquestion, {
              state: {
                examType: examDetails?.examType,
                examId: examDetails?.examId,
              },
            })
          }
          svgSecond={"arrow-right.svg"}
        />
      </Row>
    </Container>
  );
};

export default TestAssessments;
