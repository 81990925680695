//** Iguroo SelfAssessment,CustomText QuestionComponent,can re-use required field **//

import React from "react";

export function QuestionComponent(props) {
  const { text, className, svgName } = props;
  return (
    <div className={`ig-test-question ${className}`}>
      {props.children?.length > 0 ? props.children : text}
    </div>
  );
}
