//** Iguroo & GinieHealth Video Uploading Plyr Field with Required Data storage,can re-use required filed**//

import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import { useEffect, useMemo, useState, memo } from "react";
import { getUploadFileFromS3 } from "../../common/aws.helpers";

export const CustomVideo = ({ videoLink, isPublicProfile }) => {
  const [vidLink, setVidLink] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const memoizedVidLink = useMemo(() => {
    console.log("memoized link updated", vidLink, videoLink);
    return vidLink?.url;
  }, [vidLink?.url]);

  useEffect(() => {
    console.log("video key is ", videoLink);
    if (videoLink) {
      (async function () {
        setIsLoading(true);
        const vid = await getUploadFileFromS3(videoLink, isPublicProfile);
        console.log("base64img", vid);
        setIsLoading(false);
        setVidLink(vid);
      })();
    }
  }, [videoLink]);

  return (
    <Plyr
      source={{
        type: "video",
        sources: [
          {
            src: memoizedVidLink,
          },
        ],
      }}
    />
  );
};

export const CustomVideoMemo = memo(CustomVideo);
