import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import { getdocSignedURL } from "../../common/aws.helpers";

const CoursePowerPointViewer = ({ pptUrl, isExternal }) => {
  const [pptxData, setPptxData] = useState(null);


  useEffect(() => {
    (async function () {
      if (isExternal) {
        setPptxData(pptUrl);
      } else {
        let url = await getdocSignedURL(pptUrl)
        url = 'https://view.officeapps.live.com/op/embed.aspx?src=' + url;
        console.log(url, 'url');
        setPptxData(url);
      }
    })();
    }, [pptUrl]);

    return (
      <div>
        {/* <h1>PowerPoint Presentation</h1> */}
        <iframe
          title="PowerPoint Presentation"
          // src={`https://www.slideshare.net/slideshow/embed_code/key/${pptKey}?startSlide=1`}
          src={`${pptxData}`}
          width="100%"
          height="486"
          frameBorder="0"
          marginWidth="0"
          marginHeight="0"
          scrolling="no"
          style={{
            border: "1px solid #CCC",
            borderWidth: "1px",
            marginBottom: "5px",
            maxWidth: "100%",
          }}
          allowFullScreen
        />
        <div style={{ marginBottom: "5px" }}>
        </div>
      </div>
    );
  };

  export default CoursePowerPointViewer;

  {
    /* <iframe
  title="PowerPoint Presentation"
  src="https://www.slideshare.net/RajivJayarajah/how-to-have-difficult-conversations"
  // src="https://ginie-transcript-sb.s3.amazonaws.com/iguroo-transcripts/141874c8-0021-70b8-ae15-942472ef54e1/transcripts/Dickinson_Sample_Slides.ppt"
  // src={pptxData}
  width="600"
  height="400"
  frameBorder="0"
  allowFullScreen
  /> */
  }
