//** Key and Value of GinieHealthAssessment Translations -> Check with Value name in en.language.json **//

export function GenieLabsAssessmentTranslations(t) {
  return {
    key: "genieLabsAssessmentTranslations",
    value: {
      title: t("genieLabsAssessmentTranslations.title"),
      maxDuration: t("genieLabsAssessmentTranslations.maxDuration"),
      elapseTime: t("genieLabsAssessmentTranslations.elapseTime"),
      remainingTime: t("genieLabsAssessmentTranslations.remainingTime"),
      hint: t("genieLabsAssessmentTranslations.hint"),
      explanation: t("genieLabsAssessmentTranslations.explanation"),
      marked: t("genieLabsAssessmentTranslations.marked"),
      attempt: t("genieLabsAssessmentTranslations.attempt"),
      pause: t("genieLabsAssessmentTranslations.pause"),
      previous: t("genieLabsAssessmentTranslations.previous"),
      check: t("genieLabsAssessmentTranslations.check"),
      mark: t("genieLabsAssessmentTranslations.mark"),
      next: t("genieLabsAssessmentTranslations.next"),
      submit: t("genieLabsAssessmentTranslations.submit"),
      signaturSubHeaedrNote: t(
        "genieLabsAssessmentTranslations.signaturSubHeaedrNote"
      ),
      signaturSubHeader: t("genieLabsAssessmentTranslations.signaturSubHeader"),
    },
  };
}
