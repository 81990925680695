import { AWSCredStatus } from "../enums";

let loginTokens = null;
let localTokens = localStorage.getItem("awsSigningTokens");
let signingTokens = localTokens ? JSON.parse(localTokens) : null;
let signTokenStatus = AWSCredStatus.none;
let resolveQueue = [];

const getLoginTokens = () => {
  return loginTokens;
};

const getSigningTokens = () => {
  return signingTokens;
};

const getSignTokenStatus = () => {
  console.log("tokens credholder internal 2", signTokenStatus);
  return signTokenStatus;
};

const updateLoginTokens = (lgTokens) => {
  loginTokens = lgTokens;
};

const updateSigningTokens = (sgTokens) => {
  signingTokens = sgTokens;
  const jsonToken = {
    accessKeyId: sgTokens?.accessKeyId,
    expireTime: sgTokens?.expireTime,
    expired: sgTokens?.expired,
    secretAccessKey: sgTokens?.secretAccessKey,
    sessionToken: sgTokens?.sessionToken,
  };
  console.log("sgTokens", sgTokens, jsonToken);
  localStorage.setItem("awsSigningTokens", JSON.stringify(jsonToken));
};

const setSignTokensRefreshing = () => {
  console.log("tokens credholder internal 3 token set to refreshing");
  signTokenStatus = AWSCredStatus.refreshing;
};

const setSignTokensGot = () => {
  console.log("tokens credholder internal 4 token finished refreshing");
  signTokenStatus = AWSCredStatus.exist;
};

const registerResolveForTokenCallback = (resolve) => {
  resolveQueue.unshift(resolve);
};

const callResolves = () => {
  while (resolveQueue.length > 0) {
    const resolve = resolveQueue.pop();
    if (resolve) {
      resolve(signingTokens);
    }
  }
};

const isSigningTokenExpired = () => {
  console.log("this.signingTokens", signingTokens);
  if (!signingTokens) {
    return true;
  }
  if (signingTokens?.expired) {
    return true;
  }
  let expireTime = signingTokens?.expireTime;
  if (typeof expireTime === "string" || expireTime instanceof String) {
    expireTime = new Date(expireTime);
  }
  console.log("expiredTime", expireTime, typeof expireTime, signingTokens);
  if (Date.now() >= expireTime?.getTime()) {
    return true;
  }
  return false;
};

const clearTokens = () => {
  loginTokens = null;
  signingTokens = null;
  signTokenStatus = AWSCredStatus.none;
  resolveQueue = [];
};

export const awsCredsHolder = {
  isSigningTokenExpired,
  callResolves,
  registerResolveForTokenCallback,
  setSignTokensGot,
  setSignTokensRefreshing,
  updateSigningTokens,
  updateLoginTokens,
  getSignTokenStatus,
  getSigningTokens,
  getLoginTokens,
  clearTokens,
}; //new AWSCredsHolder();
