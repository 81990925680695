//** Iguroo & GinieHealth Signin Login UI **//

import React, { useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getLoginFormTranslated } from "../../FormSchemas";
import { Form, Row, Col, Button, Image, Container } from "react-bootstrap";
import { AuthContext } from "../../hooks/auth.context.provider";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { SubmitButton } from "../../components/submit.button";
import { StrConstants, VerificationCodeEnums } from "../../enums";
import { useDispatch } from "react-redux";
import { getUserAsyncThunk } from "../../redux-tk/slices/user.slice";
import { loader } from "../../redux-tk/slices/loader.slice";
import { useLocation, useNavigate } from "react-router-dom";
import { Heading } from "../../components/heading.component";
import { Link } from "react-router-dom";
import google from "../../assets/images/google.svg";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import eyeoff from "../../assets/images/eyeoff.svg";
import eyeopen from "../../assets/images/eyeopen.svg";
import { FormErrorComponent } from "../../components/form.error.component";
import { ConfirmMobileScreen } from "./auth.verification.code";
import { AppUrlsEnum } from "../../enums";
import InputGroup from "react-bootstrap/InputGroup";
import { signInWithEmail } from "../../services/cognito.service";
import { MainHeadingComponent } from "../../components/main.heading.component";
import { useRef } from "react";
import { _getImage } from "../../common/utils/commonFunctions";
import "./styles.scss";
import * as AWS from "aws-sdk";
import { CardSecondaryComponent } from "../../components/cardComponent/card.secondary.text";
import { TextLink } from "../../components/text.link.component";
import { ResetPasswordPopup } from "../genieLabs/reset.password.popup";

export const SignInScreen = () => {
  const usernameRef = useRef();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    profileLabels,
    formElementLabels,
    formErrors,
    headingTranslations,
    headerFooterTranslations,
    cognitoTranslations,
  } = useIgurooTranslations();
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [loginType, setLoginType] = useState("mobile");
  const [passwordShown, setPasswordShown] = useState(false);
  const [showResendCode, setShowResendCode] = useState(false);
  const togglePassword = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const location = useLocation();

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getLoginFormTranslated(formErrors)),
  });
  const [ginieUrl, setGinieUrl] = useState(false);

  useEffect(() => {
    (async function () {
      console.log(process.env.REACT_APP_IS_SIGNUP, "console165 session");
      if (process.env.REACT_APP_IS_SIGNUP === "false") {
        setGinieUrl(true);
      } else {
        setGinieUrl(false);
      }
    })();
  }, []);

  const onSubmit = async (data) => {
    console.log(data, "signin");
    console.log(data.password, "signin");
    try {
      dispatch(loader(true));
      console.log("otpsuccess");
      await authContext.signInWithEmail(data.username.trim().toLowerCase(), data.password);
      dispatch(loader(false));
    } catch (err) {
      console.log("signinerror", err, "err code", err.code);
      if (err.code === "UserNotConfirmedException") {
        setApiErrorMessage(err.message);
        setShowResendCode(true);
        console.log("showing resend code");
      } else if (err.code === "UserNotFoundException") {
        setApiErrorMessage(err.message);
        setShowResendCode(true);
      } else {
        setApiErrorMessage(err.message);
      }
    } finally {
      dispatch(loader(false));
    }
  };

  const resendPasswordClick = async () => {
    try {
      dispatch(loader(true));
      const rusername = getValues("username");
      const uname = `${StrConstants.signupStr}${rusername}`;
      await authContext.resendConfirmationCode(rusername.toLowerCase());
      dispatch(loader(false));
      localStorage.setItem(StrConstants.username, uname);
      navigate(AppUrlsEnum.verifyMobile, {
        state: { pageName: VerificationCodeEnums.signup, session: "" },
      });
    } catch (err) {
      console.log("signinerror", err, "err code", err.code);
      if (err.code === "InvalidParameterException") {
        setShowResendCode(false);
      } else if (err.code === "UserNotFoundException") {
        setShowResendCode(false);
        setError("apiError", {
          message: cognitoTranslations.userNotFoundException,
        });
        return;
      }
      setError("apiError", { message: err.message });
    } finally {
      dispatch(loader(false));
    }
  };

  const [show, setShow] = useState(false);

  return (
    <Row className="sign-head signin ig-auth-class  justify-content-center align-items-center m-0 p-0">
      <Col md={6} lg={4} className="px-4 py-2 mt-4 main-container">
        <Form className="signin-top w-100" onSubmit={handleSubmit(onSubmit)}>
          <Heading className="ig-sign-heading">
            {headingTranslations?.signIn}
          </Heading>
          <Container>
            <Form.Group>
              {/* <Controller
                name={`loginType`}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CustomInputField
                    hookOnChange={onChange}
                    hookOnBlur={onBlur}
                    hookValue={value}
                    type={'hidden'}
                  />
                )}
              /> */}
              <Col>
                <Form.Label>
                  {formElementLabels?.placeHolders?.mobileNo}
                </Form.Label>
                <Controller
                  name={`username`}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CustomInputField
                      className={`sign-input ${
                        errors.username ? "ig-error-signin" : ""
                      }`}
                      placeholder={formElementLabels?.placeHolders?.mobileNo}
                      hookOnChange={onChange}
                      onChange={(val) => {
                        console.log("value", val, "  ", value);
                        localStorage.setItem(StrConstants.username, val);
                        clearErrors("apiError");
                      }}
                      hookOnBlur={onBlur}
                      hookValue={value}
                    />
                  )}
                />
                <FormErrorComponent>
                  {errors.username && errors.username?.message}
                </FormErrorComponent>
              </Col>
              <Col className="mt-2">
                <Form.Label>{formElementLabels.passwordSignin}</Form.Label>
                <InputGroup className="ig-password-field">
                  <Controller
                    name={`password`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInputField
                        type={passwordShown ? "text" : "password"}
                        placeholder={formElementLabels?.placeHolders?.password}
                        hookOnChange={onChange}
                        onChange={(val) => {}}
                        hookOnBlur={onBlur}
                        hookValue={value}
                        className={`sign-input ${
                          errors.password ? "ig-error-signin" : ""
                        }`}
                      />
                    )}
                  />
                  <InputGroup.Text
                    className={`password-icon ${
                      errors.password ? "ig-error-signin" : ""
                    }`}
                  >
                    <img
                      onClick={togglePassword}
                      src={passwordShown ? eyeopen : eyeoff}
                    />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              <Col>
                <FormErrorComponent>
                  {errors.password && errors.password?.message}
                </FormErrorComponent>
              </Col>
              <Col className="sigin-pwd text-end mt-2 justify-content-end">
                <Link to={AppUrlsEnum.authForgetPassword}>
                  {formElementLabels?.forgetPassword}
                </Link>
              </Col>
            </Form.Group>
            <Row className="mt-1 mb-3">
              {process.env.REACT_APP_IS_SIGNUP === "true" && showResendCode && (
                <TextLink url="" onClick={resendPasswordClick}>
                  {formElementLabels?.resendUserNotConfirmed}
                </TextLink>
              )}
            </Row>
            <Row className="mb-2">
              <FormErrorComponent>
                {apiErrorMessage ||
                  (errors.apiError && errors.apiError?.message)}
              </FormErrorComponent>
            </Row>
            <Row>
              {" "}
              <Col className="d-grid">
                <SubmitButton
                  type="submit"
                  text={headerFooterTranslations?.signIn}
                  onClick={() => {
                    clearErrors("apiError");
                  }}
                />
                {/* <Button text="checking" onClick={onBookCLick}
                  // disabled={!isDirty || !isValid}
                />
                 <ResetPasswordPopup
                  show={show}
                  onHide={(show) => setShow(false)}
                  // avail={avail}
                /> */}
              </Col>
            </Row>
          </Container>

          <Col className="sigin-pwd mt-1">
            <Link to={AppUrlsEnum.authUserOtp}>
              {formElementLabels?.userOtp}
            </Link>
          </Col>
          {/* {loginType !== 'email' ?
            <Col className="sigin-pwd mt-1">
              <Link to={'#'} onClick={() => onLoginTypeCLick('email')}>
                {formElementLabels?.emailLogin}
              </Link>
            </Col> : ''}
          {loginType !== 'mobile' ?
            <Col className="sigin-pwd mt-1">
              <Link to={'#'} onClick={() => onLoginTypeCLick('mobile')}>
                {formElementLabels?.mobileLogin}
              </Link>
            </Col> : ''}
          {loginType !== 'username' ?
            <Col className="sigin-pwd mt-1">
              <Link to={'#'} onClick={() => onLoginTypeCLick('username')}>
                {formElementLabels?.usernameLogin}
              </Link>
            </Col> : ''} */}
          <Container>
            {/* Google Login UI,currently not in use,Infuture will be implement this code */}
            {/* <Row>
              <Col>
                <hr className="hr ig-hr-tag" />
              </Col>
              <Col xs={"auto"} className="or-tag-name">
                {profileLabels?.or}
              </Col>
              <Col>
                <hr className="hr ig-hr-tag" />
              </Col>
            </Row> */}
            {/* <Row>
              <Col className="mt-2">
                <Link to="#">
                <Button className="signin-btn">
                  <Image src={google}></Image>
                  {formElementLabels?.contiueBtn}
                </Button>
                </Link>
              </Col>
            </Row> */}
          </Container>
          {!ginieUrl ? (
            <Col className="mt-2">
              <div className="signin-link">
                {profileLabels?.signinAccount}{" "}
                <Link to={AppUrlsEnum.authSignup}>
                  {headerFooterTranslations?.signUp}
                </Link>
              </div>
            </Col>
          ) : (
            ""
          )}
        </Form>
      </Col>
    </Row>
  );
};
