//** Iguroo SelfAssessment,Sorting Question & Answers ApiComponent **//

import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { CardSecondaryComponent } from "../../components/cardComponent/card.secondary.text";
import {
  _getImage,
  _getquestionImageUrl,
} from "../../common/utils/commonFunctions";
import { ButtonFooter } from "./components/buttonfooter";
import { QuestionProgressBar } from "./components/questionProgressBar";
import { useForm } from "react-hook-form";
import { AppUrlsEnum, Languages } from "../../enums";
import { useDispatch, useSelector } from "react-redux";
import { loader } from "../../redux-tk/slices/loader.slice";
import {
  answerSelected,
  answerValue,
  changeCurrentQuestion,
} from "../../redux-tk/slices/exam.currentQuestion";
import { useCallback } from "react";
import { AuthContext } from "../../hooks/auth.context.provider";
import { ExamAttempt } from "../../services/axios/Exam";
import { QuestionRateComponent } from "./components/questionTestRate";
import { Popup } from "../CustomInput/popup";
import { SortableQuestion } from "../DND/SortableQuestion";
import { FormErrorComponent } from "../form.error.component";
import update from "immutability-helper";

const AssessmentSortQues = (props) => {
  const selectedAnswers = useSelector((state) => answerValue(state));
  const [answers, setAnswers] = useState(selectedAnswers);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isAnswer, setIsAnswer] = useState(false);
  const { testAssessmentTranslations } = useIgurooTranslations();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm();
  const [cards, setCards] = useState([]);
  const [cardsequence, setCardsequence] = useState([]);
  const [assessmentlanguage, setAssessmentlanguage] = useState();
  const authContext = useContext(AuthContext);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    (async function () {
      dispatch(loader(true));
      setAnswers(selectedAnswers);
      let option = [];
      let assmtlanguage = localStorage.getItem("assessmentlanguage");
      console.log(
        assessmentlanguage,
        "console assessmentlanguage",
        props?.question_text[assmtlanguage]
      );
      if (assmtlanguage == null) {
        assmtlanguage = "english";
      }
      const ansSelect = selectedAnswers.filter(
        (ans) => ans.question_id === props?.question_id
      );
      console.log("console 86", ansSelect);
      if (ansSelect.length > 0) {
        console.log("console 86", ansSelect, ansSelect.length);
        const ansOpt = ansSelect[0].answers?.map((anss) => {
          console.log(
            "console 86",
            ansSelect,
            ansSelect.length,
            ansSelect[0].answers,
            anss,
            props?.question_textprops?.question_text[assmtlanguage][anss]
          );
          const op = {
            id: anss,
            text: props?.question_text[assmtlanguage][anss],
            name: props?.question_text[assmtlanguage][anss],
          };
          console.log("console question_text 114", op);
          return op;
        });
        console.log("console 86", ansSelect, ansSelect.length, ansOpt);
        setCardsequence(ansOpt);
        setCards(ansOpt);
        setIsAnswer(true);
      } else {
        const result = Object.keys(props?.question_text[assmtlanguage]).map(
          (key, index) => {
            const op = {
              id: key,
              text: props?.question_text[assmtlanguage][key],
              name: props?.question_text[assmtlanguage][key],
            };
            return op;
          }
        );
        setCardsequence(result);
        setIsAnswer(false);
        setCards(result);
      }
      setAssessmentlanguage(assmtlanguage);
      dispatch(loader(false));
    })();
  }, []);

  const dndFinished = useCallback((updatedCards) => {
    setCardsequence(updatedCards);
  }, []);

  const options = [{ value: "Yes" }, { value: "No" }];

  const onSubmit = async (data) => {
    if (isEmpty) {
      console.log(
        testAssessmentTranslations,
        "console testAssessmentTranslations"
      );
      setError("sortedAns", {
        message: testAssessmentTranslations?.AssessmentOptionError,
      });
    } else {
      try {
        dispatch(loader(true));
        let ansObj = [];
        let ans = [];
        cardsequence.forEach((element, index) => {
          ansObj.push(element?.id);
        });
        console.log(
          cardsequence,
          ansObj,
          "console result onAnswerListChange",
          props?.question_text[assessmentlanguage]
        );
        const answer = {
          question_id: props.question_id,
          answers: ansObj,
        };
        const ansSelect = answers.filter(
          (ans) => ans.question_id === props?.question_id
        );

        if (ansSelect.length === 0) {
          ans = [...answers, answer];
          setAnswers([...answers, answer]);
        } else {
          console.log(answer, "console 118");
          var commentIndex = answers.findIndex(
            (c) => c.question_id === props?.question_id
          );
          let updatedItems = answers;
          if (commentIndex > -1) {
            console.log(answer, commentIndex, updatedItems, "console 118");
            updatedItems = update(answers, {
              [commentIndex]: { $set: answer },
            });
          }
          ans = updatedItems;
        }
        dispatch(answerSelected(ans));
        let completeExam =
          props?.question_id === props?.totalQuestions ? true : false;
        await ExamAttempt(
          props?.examId,
          authContext.sessionInfo.sub,
          completeExam,
          ans
        );
        if (completeExam == true) {
          setShowPopup(true);
        } else {
          dispatch(changeCurrentQuestion(props.question_id + 1));
        }
        console.log("console question submit", answers);
        console.log(answers, "console");
        dispatch(loader(false));
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onPreviousClick = async (data) => {
    try {
      data.preventDefault();
      console.log("console question submit", data);
      dispatch(loader(true));
      dispatch(changeCurrentQuestion(props.question_id - 1));
      dispatch(loader(false));
    } catch (err) {
      console.log(err);
    }
  };

  const onAnswerListChange = (data, bool) => {
    console.log("console question onAnswerListChange", data, bool);
    setIsEmpty(bool);
    clearErrors("sortedAns");
    setCardsequence(data);
  };

  return (
    <>
      <Popup
        closeOnBackClick={false}
        showPopup={showPopup}
        type="popup"
        submitText={testAssessmentTranslations?.reportBtn}
        modalBody={testAssessmentTranslations?.onTestSubmission}
        url={AppUrlsEnum.assessmentReports}
      />
      <Row key={props.question_id}>
        <Row>
          <Col md="12" className="mt-4 text-center">
            <b>
              {" "}
              {testAssessmentTranslations?.section} {props?.section_name}
            </b>
          </Col>
        </Row>
        <Row>
          <QuestionProgressBar
            now={props.question_id}
            max={props?.totalQuestions}
          ></QuestionProgressBar>
        </Row>
      </Row>
      <Row className="d-flex  ig-exam-ques mb-3 mt-4">
        <Col
          md="6"
          className="my-auto pe-3 border-right mx-auto ig-exam-qImag d-flex justify-content-center"
        >
          <img
            src={_getquestionImageUrl(
              props?.image_directory,
              `question${props.question_number}.png`
            )}
            max-width={"100%"}
          />
        </Col>
        <Col md={6} className="py-4 ps-3  border-left ig-exam-qSection">
          <div className="ig-box d-flex flex-column justify-content-end">
            <Row>
              <Col className="mt-2 text-center">
                <CardSecondaryComponent>
                  {testAssessmentTranslations?.question}
                </CardSecondaryComponent>
              </Col>
            </Row>
            <Row>
              <Col className="mt-2 text-center">
                <QuestionRateComponent>
                  {assessmentlanguage == Languages.english
                    ? props?.sectionB?.sectionDescription
                    : props[`sectionB_${assessmentlanguage}`]
                        ?.sectionDescription}
                </QuestionRateComponent>
              </Col>
            </Row>

            <Form onSubmit={handleSubmit(onSubmit)} className="mt-3 p-2">
              <Row>
                <div className="select-option  mb-4">
                  {/* {console.log(cards, "console 205")} */}
                  <SortableQuestion
                    isAnswers={isAnswer}
                    items={cards}
                    onAnswerListChange={onAnswerListChange}
                  />
                </div>
              </Row>
              <Row className="mb-4">
                <FormErrorComponent>
                  {errors.sortedAns && errors.sortedAns?.message}
                </FormErrorComponent>
              </Row>
              <ButtonFooter
                className="firstButtonReset"
                svgPrevious={"arrow-left-primary.svg"}
                {...(props.question_id > 1 && {
                  previousButton: testAssessmentTranslations?.previousBtn,
                })}
                onprevBtnClick={onPreviousClick}
                secondBtn={
                  props?.question_id === props?.totalQuestions
                    ? testAssessmentTranslations?.submit
                    : testAssessmentTranslations?.nextBtn
                }
                svgSecond={"arrow-right.svg"}
                onSecondBtnClick={handleSubmit(onSubmit)}
                disabled={isSubmitting}
              />
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AssessmentSortQues;
