import { ApiStagesEnums } from "../../../enums";
import { getSignedUrl } from "../../../helpers/aws.sign.helpers";
import { IGURU_MAIN_URL, region } from "../../config";

export const iamRequestInterceptor = (instance) => {
  instance.interceptors.request.use(
    async (config) => {
      console.log("tokens cred axiosconfig", config);
      const acceptHeader = config.headers["Accept"];
      delete config.headers["Accept"];
      if (!config.headers["Content-Type"]) {
        config.headers["Content-Type"] = "application/json";
      }
      const prefix = getUrlPrefix(config.baseURL);
      const data = config.data ? JSON.stringify(config.data) : "";
      const params = config.params ? { ...config.params } : {};
      //for(let i = 0;i<Object.keys(params))
      const sanitizedParams = {};
      const paramKeys = Object.keys(params);
      for (let i = 0; i < paramKeys.length; i++) {
        const paramKey = paramKeys[i];
        if(params[paramKey]){
          sanitizedParams[paramKey] = params[paramKey];
        }
      }
      console.log(
        "tokens cred params were",
        config.params,
        "are",
        sanitizedParams
      );
      try {
        const signedUrl = await getSignedUrl(
          config.method.toUpperCase(),
          `/${prefix}${config.url}`,
          sanitizedParams,
          config.headers,
          data,
          IGURU_MAIN_URL,
          region,
          config.isPublicProfile
        );
        config.headers["Accept"] = acceptHeader;
        console.log(
          "tokens cred axiosconfig headers",
          config.headers,
          signedUrl
        );
      } catch (err) {
        console.log("signing error", err);
      }

      return config;
    },
    (error) => {
      console.log("error in first req interceptor", error);
      Promise.reject(error);
    }
  );
};

const getUrlPrefix = (baseUrl) => {
  const splitUrl = baseUrl.split("/");
  const lastUrl = splitUrl[splitUrl.length - 1];
  const prefix = ApiStagesEnums[lastUrl];
  if (prefix) return prefix;
  return ApiStagesEnums.sandbox;
};
