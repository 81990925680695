//** Iguroo SelfAssessment,Rating Question & Answers ApiComponent **//

import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { CardSecondaryComponent } from "../../components/cardComponent/card.secondary.text";
import {
  _getImage,
  _getquestionImageUrl,
} from "../../common/utils/commonFunctions";
import { ButtonFooter } from "./components/buttonfooter";
import { QuestionProgressBar } from "./components/questionProgressBar";
import { useForm, Controller } from "react-hook-form";
import { AppUrlsEnum } from "../../enums";
import CustomAnswerRateOptions from "./components/customradioRate";
import { useDispatch, useSelector } from "react-redux";
import { loader } from "../../redux-tk/slices/loader.slice";
import {
  answerSelected,
  answerValue,
  changeCurrentQuestion,
} from "../../redux-tk/slices/exam.currentQuestion";
import { QuestionRateComponent } from "./components/questionTestRate";
import { AuthContext } from "../../hooks/auth.context.provider";
import { ExamAttempt } from "../../services/axios/Exam";
import { FormErrorComponent } from "../form.error.component";
import { RateQuestionError } from "../../FormSchemas/test.assessments.form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Popup } from "../CustomInput/popup";
import update from "immutability-helper";

const AssessmentRateQues = (props) => {
  const { testAssessmentTranslations } = useIgurooTranslations();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(RateQuestionError(testAssessmentTranslations)),
  });
  const {} = props;
  const selectedAnswers = useSelector((state) => answerValue(state));
  const [answers, setAnswers] = useState(selectedAnswers);
  const [assessmentlanguage, setAssessmentlanguage] = useState();
  const [options, setOption] = useState([]);
  const [selectAnswer, setSelectAnswers] = useState();
  const authContext = useContext(AuthContext);
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    (async function () {
      dispatch(loader(true));
      let assmtlanguage = localStorage.getItem("assessmentlanguage");
      console.log(assessmentlanguage, "console assessmentlanguage");
      if (assmtlanguage == null) {
        assmtlanguage = "english";
      }
      setAssessmentlanguage(assmtlanguage);
      const op = [];
      for (let i = 1; i <= 5; i++) {
        op.push({ value: i });
      }
      const ansSelect = selectedAnswers.filter(
        (ans) => ans.question_id === props?.question_id
      );
      if (ansSelect.length > 0) {
        console.log(ansSelect, "console ans");
        setSelectAnswers(ansSelect[0].answers[0]);
        setValue("answerOption", ansSelect[0].answers[0]);
      }
      setAnswers(selectedAnswers);
      setOption(op);
      dispatch(loader(false));
    })();
  }, []);

  const onSubmit = async (data) => {
    try {
      dispatch(loader(true));
      let ans = [];
      const answer = {
        question_id: props.question_id,
        answers: [data.answerOption],
      };
      const ansSelect = answers.filter(
        (ans) => ans.question_id === props?.question_id
      );

      if (ansSelect.length === 0) {
        ans = [...answers, answer];
        setAnswers([...answers, answer]);
      } else {
        console.log(answer, "console 118");
        var commentIndex = answers.findIndex(
          (c) => c.question_id === props?.question_id
        );
        let updatedItems = answers;
        if (commentIndex > -1) {
          console.log(answer, commentIndex, updatedItems, "console 118");
          updatedItems = update(answers, {
            [commentIndex]: { $set: answer },
          });
        }
        ans = updatedItems;
      }
      dispatch(answerSelected(ans));
      let completeExam =
        props?.question_id === props?.totalQuestions ? true : false;
      await ExamAttempt(
        props?.examId,
        authContext.sessionInfo.sub,
        completeExam,
        ans
      );
      if (completeExam == true) {
        setShowPopup(true);
      } else {
        dispatch(changeCurrentQuestion(props.question_id + 1));
      }
      dispatch(loader(false));
    } catch (err) {
      console.log(err);
    }
  };

  const onPreviousClick = async (data) => {
    try {
      data.preventDefault();
      console.log("console question submit", data);
      dispatch(loader(true));
      dispatch(changeCurrentQuestion(props.question_id - 1));
      dispatch(loader(false));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Popup
        closeOnBackClick={false}
        showPopup={showPopup}
        type="popup"
        submitText={testAssessmentTranslations?.reportBtn}
        modalBody={testAssessmentTranslations?.onTestSubmission}
        url={AppUrlsEnum.assessmentReports}
      />
      <Row key={props?.question_id}>
        <Row>
          <Col md="12" className="mt-4 text-center">
            <b>
              {" "}
              {testAssessmentTranslations?.section} {props?.section_name}
            </b>
          </Col>
        </Row>
        <Row>
          <QuestionProgressBar
            now={props?.question_id}
            max={props?.totalQuestions}
          ></QuestionProgressBar>
        </Row>
      </Row>
      <Row className="d-flex ig-exam-ques mb-3 mt-4">
        <Col
          md="6"
          className="my-auto pe-3 border-right mx-auto ig-exam-qImag d-flex justify-content-center"
        >
          <img
            src={_getquestionImageUrl(
              props?.image_directory,
              `question${props.question_number}.png`
            )}
            max-width={"100%"}
          />
        </Col>
        <Col md={6} className="py-4 ps-3  border-left ig-exam-qSection">
          <div className="ig-box d-flex flex-column justify-content-end">
            <Row>
              <Col className="mt-2 text-center">
                {console.log("log", testAssessmentTranslations)}
                <CardSecondaryComponent>
                  {testAssessmentTranslations?.question}
                </CardSecondaryComponent>
              </Col>
            </Row>
            <Row>
              <Col className="mt-2 text-center">
                <QuestionRateComponent>
                  {props?.question_text[assessmentlanguage]}
                </QuestionRateComponent>
              </Col>
            </Row>
            <Form onSubmit={handleSubmit(onSubmit)} className="mt-3 p-2">
              <Row>
                <div className=" mb-1">
                  <Controller
                    name={"answerOption"}
                    control={control}
                    render={({ field: { onChange, onBlur, value, name } }) => (
                      <CustomAnswerRateOptions
                        type={"radio"}
                        options={options}
                        hookOnChange={onChange}
                        hookOnBlur={onBlur}
                        hookValue={value}
                        name={name}
                        defaultValue={selectAnswer}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row className="mb-4">
                <FormErrorComponent>
                  {errors.answerOption && errors.answerOption?.message}
                </FormErrorComponent>
              </Row>
              <ButtonFooter
                className="firstButtonReset"
                svgPrevious={"arrow-left-primary.svg"}
                {...(props.question_id > 1 && {
                  previousButton: testAssessmentTranslations?.previousBtn,
                })}
                onprevBtnClick={onPreviousClick}
                secondBtn={
                  props?.question_id === props?.totalQuestions
                    ? testAssessmentTranslations?.submit
                    : testAssessmentTranslations?.nextBtn
                }
                svgSecond={"arrow-right.svg"}
                onSecondBtnClick={handleSubmit(onSubmit)}
                disabled={isSubmitting}
              />
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AssessmentRateQues;
