import getEnvUrl, {
  IGUROO_PROFILE_DATA_EDUCATION,
  IGUROO_PROFILE_DATA_EXPERIENCE,
  IGURU_DEV_URL,
  STATIC_QUALIFICATION,
  STATIC_ENDYEAR,
  STATIC_FIELD,
  STATIC_ALL_DATA_n,
  STATIC_URL_DTAA,
} from "./config";
//import { STATIC_QUALIFICATION } from './urlConst';
import axios from "axios";
import { axiosAwsIam, axiosStaticData } from "./axios/axios.service";
import {
  STATIC_ALL_DATA,
  STATIC_CITY,
  STATIC_COUNTRY,
  STATIC_STATE,
  MENTEES_REQUESTS,
  FIND_MENTOR_MENTEE,
  GET_USER_ALL,
} from "./urlConst";

export async function saveEducation(form) {
  const URL = IGUROO_PROFILE_DATA_EDUCATION;
  const resp = await fetch(URL, {
    method: "POST",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });
  console.log("resp", resp);
  return (await resp.json()).message;
}

export async function saveWorkExperience(form) {
  const URL = IGUROO_PROFILE_DATA_EXPERIENCE;
  //const str = Circular

  console.log(JSON.stringify(form));
  const resp = await fetch(URL, {
    method: "POST",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });
  console.log("resp", resp);
  return (await resp.json()).message;
}
export async function GetQualification() {
  try {
    const URL = STATIC_QUALIFICATION;
    const resp = await fetch(URL, {
      method: "get",

      headers: {
        "Content-Type": "application/json",
      },
    });
    let respons = await resp.json();
    console.log("resp", respons);
    return respons;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}
export async function GetField() {
  try {
    const URL = STATIC_FIELD;
    const resp = await fetch(URL, {
      method: "get",

      headers: {
        "Content-Type": "application/json",
      },
    });
    let respons = await resp.json();
    console.log("resp", respons);
    return respons;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}
export async function GetEndYear() {
  try {
    const URL = STATIC_ENDYEAR;
    const resp = await fetch(URL, {
      method: "get",

      headers: {
        "Content-Type": "application/json",
      },
    });
    let respons = await resp.json();
    console.log("resp", respons);
    return respons;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function GetAllData() {
  try {
    const URL = STATIC_ALL_DATA_n;
    const response = await axios.get(URL);
    return response.data.response.Items;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function getCountryInfo() {
  try {
    console.log(STATIC_URL_DTAA);
    const URL = getEnvUrl() + STATIC_COUNTRY;
    const response = await fetch(URL, {
      method: "GET",
    });
    console.log(response, "response");
    return (await response.json()).response;
  } catch (err) {}
}

export async function getState(countryCode) {
  const URL = getEnvUrl() + STATIC_STATE + `?country_code=${countryCode}`;
  const response = await fetch(URL, {
    method: "GET",
  });

  return (await response.json()).response;
}

export async function getCities(stateCode) {
  const URL = getEnvUrl() + STATIC_CITY + `?state_code=${stateCode}`;
  const response = await fetch(URL, {
    method: "GET",
  });

  return (await response.json()).response;
}

export async function menteesRequests(data) {
  const URL = `${IGURU_DEV_URL}${MENTEES_REQUESTS}`;
  const responce = await axiosStaticData.post(MENTEES_REQUESTS, data);
  return responce?.data.response;
}

export async function getMentorsMentees(mentorId) {
  try {
    const URL = FIND_MENTOR_MENTEE;
    const params = {
      mentorId: mentorId,
    };
    const response = await axiosStaticData.get(URL, { params });
    return response.data.response;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function getPublicMentor(userId) {
  try {
    const URL = `${IGURU_DEV_URL}/user/all?userId=${userId}`;
    const response = await axiosAwsIam.get(URL);
    return response.data?.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export async function getPrivateMentor(userId, id) {
  try {
    const URL = `${IGURU_DEV_URL}/user/all?userId=${userId}`;
    // const params = {
    //   userId:userId
    // }
    let params = {};
    if (!id) {
      params = {
        userId: userId,
      };
    } else {
      params = {
        userId: userId,
        id: id,
      };
    }
    console.log('API Request URL:', URL);
    console.log('API Request Params:', params);
    const response = await axiosStaticData.get(GET_USER_ALL, { params });
    return response.data?.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}
