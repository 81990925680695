//** GinieHealth FeedbackInvite(User Management),can bulck upload the inviting the users,after uploading can view in the Aggrid**//

import { useEffect, useMemo, useRef, useState } from "react";
import { Modal, ModalTitle } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./styles.css";

const FeedbackInvite = ({ feedbacks, show, onHide, title }) => {
  const [showModal, setShowModal] = useState(false);
  const [feedbackList, setFeedbackList] = useState([]);
  const gridRef = useRef();

  const columnDefs = useMemo(
    () => [
      {
        field: "Name",
        header: "First Name",
        cellStyle: { "word-break": "break-word" },
        minWidth: 250,
        sortable: true,
      },

      { field: "email", minWidth: 300, sortable: true },
      { field: "mobile", sortable: true },
      {
        field: "status",
        sortable: true,
      },
      {
        field: "message",
        minWidth: 250,
      },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      flex: 1,
      unSortIcon: true,
      minWidth: 150,
      wrapText: true,
      autoHeight: true,
    }),
    []
  );

  useEffect(() => {
    console.log("bulkuploadCSV", show);
    setShowModal(show);
  }, [show]);

  useEffect(() => {
    (async function () {
      if (feedbacks) {
        const list = feedbacks.map((x) => {
          const details = x?.feedback ? x?.feedback : x;
          console.log("bulkuploadCSV bulkuploadCSV", details, show);
          return {
            Name: details?.user?.firstName + " " + details?.user?.lastName,
            mobile: details?.user?.mobile,
            email: details?.user?.email,
            status: details?.status,
            message: details?.message,
          };
        });
        console.log("list bulkuploadCSV", list);
        setFeedbackList(list);
      }
    })();
  }, [showModal, feedbacks]);

  const sortingOrder = useMemo(() => {
    return ["asc", "desc"];
  }, []);

  return (
    <>
      <Modal
        show={showModal}
        size="lg"
        onHide={onHide}
        className="feedback-popup"
      >
        <Modal.Header closeButton>
          <ModalTitle className="modal-title w-100">{title}</ModalTitle>
        </Modal.Header>
        <div className="ag-theme-alpine m-2" style={{ height: "80vh" }}>
          <AgGridReact
            className="feedback-table"
            gridRef={gridRef}
            animateRows={true}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={feedbackList}
            pagination={true}
            rowHeight={70}
            paginationPageSize={50}
            onPaginationChanged={() => {}}
            onGridReady={() => {}}
            sortingOrder={sortingOrder}
            suppressDragLeaveHidesColumns={true}
            enableCellTextSelection={true}
          />
        </div>
      </Modal>
    </>
  );
};

export default FeedbackInvite;
