//** Common Producted Route -> After Signin only have to Redirect to the Page otherwise will got error PageNotFound **//

import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AppUrlsEnum, AuthStatus } from "../enums";
import { AuthContext } from "../hooks/auth.context.provider";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const authContext = useContext(AuthContext);
  if (authContext.authStatus === AuthStatus.SignedIn) {
    return;
  }
  if (authContext.authStatus === AuthStatus.SignedOut) {
    const thePath = location;
    const lastItem = thePath.search.split("=");
    const search = new URLSearchParams(thePath.search);
    const transcript = search.get("tab");
    if (transcript != null) localStorage.setItem("tab", transcript);
    const attempt = search.get("attempt_id");
    if (attempt != null) localStorage.setItem("transcriptattempt_id", attempt);

    return (
      <Navigate
        to={AppUrlsEnum.authSignin}
        state={{ from: location }}
        replace
      />
    );
  }
  return children;
};

export default ProtectedRoute;
