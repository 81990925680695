//** GinieHealth ProgressBar for calculating Answers **//

import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { _getImage } from "../../common/utils/commonFunctions";

const AssessmentProgressView = (props) => {
  const { myKey, value, isActive, isChecked, url } = props;
  const imageUrl = isChecked
    ? "completed.svg"
    : isActive
    ? "ic_progress_av.png"
    : "ic_progress_na.png";
  let sideClassName = isChecked ? "activeSidebar" : "";
  return (
    <div className="d-flex align-items-center mt-3 mb-1">
      <Image
        resizeMode="contain"
        style={{ width: 20, height: 20, marginRight: 14 }}
        src={_getImage(imageUrl)}
      />
      <p
        style={{
          fontSize: "15px",
          fontWeight: "500",
          color: "#222222",
          margin: 0,
          padding: 0,
        }}
        className={`sidebar ${sideClassName}`}
      >
        <Link to={url} className="sidebar-link">
          {value}
        </Link>
      </p>
    </div>
  );
};

export default AssessmentProgressView;
