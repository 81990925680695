//** Iguroo & GinieHealth ResetPassword UI code**//

import React, { useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { authResetPasswordForm } from "../../FormSchemas";
import { Form, Row, Col, Container } from "react-bootstrap";
import { AuthContext } from "../../hooks/auth.context.provider";
import { CustomInputField } from "../../components/CustomInput/customInputField";
import { SubmitButton } from "../../components/submit.button";
import { StrConstants } from "../../enums";
import { useDispatch } from "react-redux";
import { loader } from "../../redux-tk/slices/loader.slice";
import { useNavigate } from "react-router-dom";
import { Heading } from "../../components/heading.component";
import { Link } from "react-router-dom";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import eyeoff from "../../assets/images/eyeoff.svg";
import eyeopen from "../../assets/images/eyeopen.svg";
import { FormErrorComponent } from "../../components/form.error.component";
import { ConfirmMobileScreen } from "./auth.verification.code";
import { AppUrlsEnum } from "../../enums";
import InputGroup from "react-bootstrap/InputGroup";
import { changePassword, getCurrentUser } from "../../services/cognito.service";
import { Popup } from "../../components/CustomInput/popup";
import { useLocation } from "react-router-dom";

export const ResetPassword = () => {
  const {
    formElementLabels,
    formErrors,
    headingTranslations,
    menteeOnboardingLabels,
  } = useIgurooTranslations();
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(authResetPasswordForm(formErrors)),
  });
  const [passwordShown, setPasswordShown] = useState(false);
  const [cfpasswordShown, setPasswordShownCF] = useState(false);

  const togglePassword = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const togglePasswordCF = () => {
    setPasswordShownCF(cfpasswordShown ? false : true);
  };
  const location = useLocation();
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const [showPopup, setShowPopup] = useState(false);
  console.log(location.state?.pageName, "resetPassword", errors);
  const onSubmit = async (data) => {
    const OTP = location?.state.OTP;
    console.log("rpd", data);
    const username = localStorage.getItem(StrConstants.username);
    try {
      dispatch(loader(true));
      console.log("otp", OTP);
      await authContext.forgotPassword(username?.toLowerCase(), OTP, data.newPassword);
      setShowPopup(true);
      dispatch(loader(false));
      console.log("made call");
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
      } else {
        setError("apiError", { message: err.message });
      }
      dispatch(loader(false));
    }
  };
  return (
    <Row className="sign-head signin ig-auth-class  justify-content-center align-items-center mt-4">
      <Col md={6} lg={4} className="p-2 main-container">
        <Form className="signin-top w-100" onSubmit={handleSubmit(onSubmit)}>
          <Heading className="ig-auth-heading">
            {headingTranslations?.resetPassword}
          </Heading>
          <div>{headingTranslations?.resetPasswordMessage}</div>
          <Container>
            <Form.Group>
              <Col className="mt-1">
                <Form.Label>{formElementLabels?.newPassword}</Form.Label>
                <InputGroup className="ig-password-field">
                  <Controller
                    name={`newPassword`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInputField
                        placeholder={
                          formElementLabels?.placeHolders?.resetPassword
                        }
                        type={passwordShown ? "text" : "password"}
                        hookOnChange={onChange}
                        hookOnBlur={onBlur}
                        hookValue={value}
                        className={`sign-input ${
                          errors.password ? "ig-error-signin" : ""
                        }`}
                      />
                    )}
                  />
                  <InputGroup.Text
                    className={`password-icon ${
                      errors.password ? "ig-error-signin" : ""
                    }`}
                  >
                    <img
                      onClick={togglePassword}
                      src={passwordShown ? eyeopen : eyeoff}
                    />
                  </InputGroup.Text>
                </InputGroup>
                <FormErrorComponent>
                  {errors.newPassword && errors.newPassword?.message}
                </FormErrorComponent>
              </Col>
            </Form.Group>
            <Form.Group>
              <Col className="mt-3">
                <Form.Label>{formElementLabels?.confirmPassword}</Form.Label>
                <InputGroup className="ig-password-field">
                  <Controller
                    name={`repeatPassword`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomInputField
                        type={cfpasswordShown ? "text" : "password"}
                        placeholder={
                          formElementLabels?.placeHolders?.resetPassword
                        }
                        hookOnChange={onChange}
                        hookOnBlur={onBlur}
                        hookValue={value}
                        className={`sign-input ${
                          errors.password ? "ig-error-signin" : ""
                        }`}
                      />
                    )}
                  />
                  <InputGroup.Text
                    className={`password-icon ${
                      errors.password ? "ig-error-signin" : ""
                    }`}
                  >
                    <img
                      onClick={togglePasswordCF}
                      src={cfpasswordShown ? eyeopen : eyeoff}
                    />
                  </InputGroup.Text>
                </InputGroup>
                <FormErrorComponent>
                  {errors.repeatPassword && errors.repeatPassword?.message}
                </FormErrorComponent>
              </Col>
            </Form.Group>
            <Row className="mb-2">
              <FormErrorComponent>
                {errors.apiError && errors.apiError?.message}
              </FormErrorComponent>
            </Row>
            <br />
            <Col lg="12" xs="12" md="12" xl="12" className="d-grid">
              <SubmitButton
                type="submit"
                text={headingTranslations?.resetPassword}
                onClick={() => {
                  clearErrors("apiError");
                }}
              />
              <Popup
                closeOnBackClick={() => {
                  setShowPopup(false);
                  return true;
                }}
                showPopup={showPopup}
                type="popup"
                text={formElementLabels.signIn}
                modalBody={menteeOnboardingLabels?.resetPasswordMessage}
                url={AppUrlsEnum.authSignin}
                title={headingTranslations?.resetPassword}
                homeLink={formElementLabels.homePage}
                submitText={formElementLabels?.signIn}
                className="OTPSuccess"
              />
            </Col>
          </Container>
          <Row className="sigin-pwd text-center justify-content-center">
            <Link to={AppUrlsEnum.authForgetPassword}>
              {" "}
              {formElementLabels?.cancel}
            </Link>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};
