//** Iguroo Card Radio Button Component,can re-use with required field **//

import React from "react";
import { Form } from "react-bootstrap";
import "./styles.scss";

function CardRadioBtn({
  title,
  subtitle,
  id,
  name,
  value,
  className,
  onBlur,
  onChange,
  defaultChecked,
}) {
  return (
    <div className={"card-radio " + className}>
      <Form.Check type="radio" id={id} className="ps-0">
        <Form.Check.Input
          type={"radio"}
          isValid
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          {...(defaultChecked && { defaultChecked: defaultChecked })}
          data-testid="radio-input"
        />
        <Form.Check.Label>
          <h3>{title}</h3>
          <p>{subtitle}</p>
        </Form.Check.Label>
      </Form.Check>
    </div>
  );
}

export default CardRadioBtn;
