import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { MainRoute } from "../src/routes";
import "bootstrap/dist/css/bootstrap.min.css";
import { initi18nService } from "./services/i18n.service";
import AuthProvider, { AuthAllRoutes } from "./hooks/auth.context.provider";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { createRootStore } from "./redux-tk/store";
import IgLoader from "./components/ig.loader";
import { loadCommonPackageConfig } from "./services/config";
//import { transformEntityStateToPersist } from "./redux-tk/store/persist.transform";
require(`./css/${process.env.REACT_APP_CSS_COMMON}`);

initi18nService();
loadCommonPackageConfig();

function App() {
  // const persistConfig = {
  //   key: 'root',
  //   storage: AsyncStorage,
  //   whitelist: [],
  //   transforms: [transformEntityStateToPersist],
  // };
  const { store } = createRootStore();

  return (
    <BrowserRouter>
      <div>
        <Provider store={store}>
          <div className="loader-div">
            <IgLoader></IgLoader>
          </div>
          <ToastContainer />
          <AuthProvider>
            <AuthAllRoutes>
              <MainRoute />
            </AuthAllRoutes>
          </AuthProvider>
        </Provider>
      </div>
    </BrowserRouter>
  );
}

export default App;
