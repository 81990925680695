import moment from "moment";

class CreateAssessmentDtoClass {
  toDto(assessment) {
    return getAssessmentObject(assessment);
  }

  toForm() {}
}

export const CreateAssessmentDto = new CreateAssessmentDtoClass();

export function getAssessmentObject(assessment) {
  // console.log("%%%%%",profile)
  const formData = {
    ...(assessment.name && { name: assessment.name }),
    ...(assessment.status && { status: assessment.status }),
    //   ...(userId && { userId: userId }),
    //   ...((assessment.profilePic || assessment.profilePic === "delete") && {
    //     profilePic: assessment.profilePic === "delete" ? "" : assessment.profilePic,
    //   }),
    //   ...(assessment.newProfilePic && {
    //     newProfilePic: assessment.newProfilePic,
    //   }),
    ...(assessment.title && { title: assessment.title }),
    ...(assessment.subtitle && { subtitle: assessment.subtitle }),
    ...(assessment.language && { language: assessment?.language }),
    ...(assessment.language_level && {
      language_level: assessment.language_level,
    }),
    ...(assessment.category && {
      category: assessment.category,
    }),
    ...(assessment.subcategory && { subcategory: assessment.subcategory }),
    ...(assessment.tags && { tags: assessment.tags }),
    ...(assessment.assessment_info && {
      assessment_info: assessment.assessment_info,
    }),
    //   ...((assessment.profileVideoLink || assessment.profileVideoLink === "delete") && {
    //     profileVideoLink:
    //       assessment.profileVideoLink === "delete" ? "" : assessment.profileVideoLink,
    //   }),
    //   ...(assessment.newProfileVideoLink && {
    //     newProfileVideoLink: assessment.newProfileVideoLink,
    //   }),
    ...(assessment.banner_image && { banner_image: assessment.banner_image }),
    ...(assessment.banner_video && { banner_video: assessment.banner_video }),
    ...(assessment.assessment_metadata && {
      assessment_metadata: assessment.assessment_metadata,
    }),
    ...(assessment.intended_learners && {
      intended_learners: assessment.intended_learners,
    }),
    ...(assessment.what_will_you_learn && {
      what_will_you_learn: assessment.what_will_you_learn,
    }),
    ...(assessment.assessment_for && {
      assessment_for: assessment.assessment_for,
    }),
    ...(assessment.prerequisite && { prerequisite: assessment.prerequisite }),
    ...(assessment.table_of_content && {
      table_of_content: assessment.table_of_content,
    }),
    ...(assessment.attempts_allowed && {
      attempts_allowed: assessment.attempts_allowed,
    }),
    ...(assessment.cutoff_score && { cutoff_score: assessment.cutoff_score.toString() }),
    ...(assessment.duration && { duration: assessment.duration }),
    ...(assessment.isMarketingAllowed && {
      isMarketingAllowed: assessment.isMarketingAllowed,
    }),
    ...(assessment.max_duration_expected && {
      max_duration_expected: assessment.max_duration_expected,
    }),
    ...(assessment.min_duration_expected && {
      min_duration_expected: assessment.min_duration_expected,
    }),
    ...(assessment.number_of_question && {
      no_of_questions_by_section: assessment.number_of_question,
    }),
    ...(assessment.isRadomizeAnswers && {
      isRadomizeAnswers: assessment.isRadomizeAnswers === "Yes",
    }),
    ...(assessment.isRadomizeQuestions && {
      isRadomizeQuestions: assessment.isRadomizeQuestions === "Yes",
    }),
    ...(assessment.isResetAllowed && {
      isResetAllowed: assessment.isResetAllowed === "Yes",
    }),
    ...(assessment.isResumeAllowed && {
      isResumeAllowed: assessment.isResumeAllowed,
    }),
    ...(assessment.isReverseAllowed && {
      isReverseAllowed: assessment.isReverseAllowed,
    }),
    ...(assessment.isSkippingAllowed && {
      isSkippingAllowed: assessment.isSkippingAllowed,
    }),
    ...(assessment.isFree && {
      isFree:
        assessment.isFree === "Free" || assessment.isFree === true
          ? true
          : false,
    }),
    ...(assessment.isShowTimeElaspsed && {
      isShowTimeElaspsed: assessment.isShowTimeElaspsed,
    }),
    ...(assessment.isShowTimeLeft && {
      isShowTimeLeft: assessment.isShowTimeLeft,
    }),
    ...(assessment.timeZone && { timeZone: assessment.timeZone }),
    ...(assessment.isShowTotalTime && {
      isShowTotalTime: assessment.isShowTotalTime,
    }),
    ...(assessment.valid_till && {
      valid_till: Number.isInteger(assessment.valid_till)
        ? assessment.valid_till
        : Date.parse(moment(assessment.valid_till).endOf("day").format())/1000,
    }),
    ...(assessment.isTimeBoxed && {
      isTimeBoxed:
        assessment.isTimeBoxed === "Yes" || assessment.isTimeBoxed === true
          ? true
          : false,
    }),
    ...(assessment.number_of_questions && {
      number_of_questions: assessment.number_of_questions,
    }),
    ...(assessment.price_currency && {
      price_currency: assessment.price_currency,
    }),
    ...(assessment.price && { price: assessment.price }),
    ...(assessment.assessmentText && {
      assessmentText: assessment.assessmentText,
    }),
    ...(assessment.email_template_name && {
      email_template_name: assessment.email_template_name,
    }),
    ...(assessment.customlogi && { customlogic: assessment.customlogic }),
    ...(assessment.custom_logic_value && {
      custom_logic_value: assessment.custom_logic_value,
    }),
    ...(assessment.report && { report: assessment.report }),
    ...(assessment.reportText && { reportText: assessment.reportText }),
    ...(assessment.certificationUrl && {
      certificationUrl: assessment.certificationUrl,
    }),
    ...(assessment.validity_month && {
      validity_month: assessment.validity_month,
    }),
    ...(assessment.validity_year && {
      validity_year: assessment.validity_year,
    }),
    ...(assessment.access_type && { access_type: assessment.access_type }),
    ...(assessment.author_name && { author_name: assessment.author_name }),
    ...(assessment.evaluation_type && {
      evaluation_type: assessment.evaluation_type,
    }),
    ...(assessment.transcript_template && {
      transcript_template: assessment.transcript_template,
    }),
    ...(assessment.custom_report && {
      custom_report: assessment.custom_report,
    }),
    ...(assessment.custom_report_value && {
      custom_report_value: assessment.custom_report_value,
    }),
    ...(assessment.custom_logic && {
      custom_logic: assessment.custom_logic,
    }),
    ...(assessment.custom_logic_value && {
      custom_logic_value: assessment.custom_logic_value,
    }),
    ...(assessment.certification_type && {
      certification_type: assessment.certification_type,
    }),
    ...(assessment.certification_url && {
      certification_url: assessment.certification_url,
    }),
    ...(assessment.transcript_download && { transcript_download:assessment.transcript_download})
    //   ...(assessment.lastName && { lastName: assessment.lastName }),
    //   ...(assessment.email && { email: assessment.email }),
    //   ...(assessment.mobile && { mobile: assessment.mobile }),
    //   ...(assessment.hobbies && { hobbies: assessment.hobbies }),
    //   ...(assessment.skills && { skills: assessment.skills }),
    //   ...(assessment.certifications && { certifications: assessment.certifications }),
    //   ...(assessment.onBoardStatus && { onboard_status: assessment.onBoardStatus }),
    //   ...(assessment.isPrivateProfile && {
    //     isPrivateProfile: assessment.isPrivateProfile,
    //   }),
    //   ...(assessment.isTrusted && { isTrusted: assessment.isTruste }),
    //   ...(assessment.profileLikes && { profileLikes: assessment.profileLikes }),
    //   ...(assessment.areaOfExpertise && {
    //     areaOfExpertise: assessment.areaOfExpertise,
    //   }),
    //   ...(assessment.workingExp && { workingExp: assessment.workingExp }),
    //   ...(assessment.mentoringFrom && { mentoringFrom: assessment.mentoringFrom }),
    //   ...(assessment.onBoardStage && { onBoardStage: assessment.onBoardStage }),
    //   ...(assessment.referenceCode && {reference_code: assessment.referenceCode}),
  };
  return formData;
}

export function fuseAllAssessmentWithForm(apiData, assessmentObj) {
  const {
    created_by,
    updated_by,
    isEnable,
    author_id,
    publisher_id,
    supervisor_id,
    updatedAt,
    ...rest
  } = apiData;

  return {
    ...rest,
    ...assessmentObj,
  };
}
