//** Iguroo CustomCalender Component,Can res-use & change conditions as our convinence **//

import React, { useEffect, useState, useCallback } from "react";
import { Calendar } from "@ishdeepsidhu/react-calendar";

const CustomCalendar = ({
  sessionDates,
  onDateMonthChange,
  onDateChange,
  isHighlighted,
  isDisable,
  isGreyed,
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentYear, setCurrentYear] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);

  const onChange = useCallback(
    (val) => {
      //console.log("curr date",val);
      setCurrentDate(val);
      onDateChange(val);
    },
    [setCurrentDate, onDateChange]
  );
  const isDisabled = useCallback(
    (date) => {
      //console.info("disabled called called", date);
      // if (moment(date, "day").isBefore(moment(new Date()), "day")) {
      //   return true;
      // }
      if (isDisable) {
        return isDisable(date);
      }
    },
    [sessionDates]
  );

  const isHighlight = useCallback(
    (date) => {
      if (typeof isHighlighted === "function") {
        return isHighlighted(date);
      }
    },
    [sessionDates]
  );

  const isGrey = useCallback(
    (date) => {
      if (typeof isGreyed === "function") {
        return isGreyed(date);
      }
      //console.log("isgrey called", date);
    },
    [sessionDates]
  );

  const onMonthChange = useCallback(
    (month) => {
      //console.info("onMonthChange called", month);
      // highlight any data that is divisible by 5
      setCurrentMonth(month);
    },
    [setCurrentMonth]
  );
  //console.log("current year",currentYear)
  const onYearChange = useCallback(
    (year) => {
      //console.info("onYearChange called", year);
      setCurrentYear(year);
      // highlight any data that is divisible by 5
    },
    [setCurrentYear]
  );

  useEffect(() => {
    const fromDate = new Date(currentYear, currentMonth, 1, 0, 0, 0, 0);
    const toDate = new Date(currentYear, currentMonth + 1, 0, 0, 0, 0, 0);
    onDateMonthChange(currentYear, currentMonth, fromDate, toDate);
  }, [currentMonth, currentYear, onDateMonthChange]);

  const onPrevClickCallback = useCallback((year) => {
    //console.info("onPrevClickCallback called", year);
    // highlight any data that is divisible by 5
  }, []);

  const onNextClickCallback = useCallback((year) => {
    //console.info("onNextClickCallback called", year);
    // highlight any data that is divisible by 5
  }, []);

  useEffect(() => {
    // console.info("calling ", currentDate);
  }, [currentDate]);

  return (
    <>
      <Calendar
        size={320}
        fontSize={16}
        isHighlight={isHighlight}
        isDisabled={isDisabled}
        value={currentDate}
        onChange={onChange}
        onChangeMonth={onMonthChange}
        onChangeYear={onYearChange}
        onNextClickCallback={onNextClickCallback}
        onPrevClickCallback={onPrevClickCallback}
        isGrey={isGrey}
      />
    </>
  );
};
export default CustomCalendar;