export const STATIC_COUNTRY = "/staticdata/country?language=english";
export const STATIC_STATE = "/staticdata/state";
export const STATIC_CITY = "/staticdata/city";
export const STATIC_ALL_DATA = "/staticdata/alldata";
export const STATIC_QUALIFICATION =
  "/staticdata?datatype=qualification.english";
export const STATIC_DATA_DATA_TYPE = "/staticdata";
export const POST_USER_AVAILABILITY = "/user/availability";
export const POST_MENTOR_WORK_EXP = "/user/work-history";
export const POST_MENTOR_EDU = "/user/education-history";
export const POST_USER = "/user";
export const GET_USER_ALL = "/user/all";
export const GET_USER_AVAILABILITY = "/user/availability";
export const POST_USER_RATES = "/user/rates";
export const MENTOR_PUBLIC_PROFILE = "/public/profilepublic";
export const OPEN_SEARCH = "/search/mentors";
export const SEARCH_USER = "/search/userByName";
export const USER_APPROVE = "/user/approveUser";
export const MENTEES_REQUESTS = "/search/requests";
export const GET_USER_PROFILE = "/user";
export const GET_LIST_USER_PROFILE = "/user/dashboard";
export const LIKE_API = "/user/like";
export const BOOKMARK_API = "/user/bookmark";
export const SEARCH_REQUESTS = "/search/requests";
export const GET_SESSION_LIST = "/session/list";
export const GET_LIST_SESSION_LIST = "/session/dashboard";
export const GET_SESSION_LIST_DATES_MENTOR = "/session/bymentor";
export const GET_SESSION_LIST_DATES_MENTEE = "/session/byUser";
export const FIND_MENTOR_MENTEE = "/find/mentee";
export const FIND_MENTEE_MENTORS = "/find/mentor";
export const REQUEST_DETAILS = "/request";
export const GET_TOTAL_SESSION = "/session/allsession";
export const BOOK_A_SESSION = "/session/bookslot";
export const CREATE_REQUEST = "/request";
export const CREATE_SESSION = "/session";
export const UPDATE_SESSION = "/session";
export const POST_RATING = "/session/rating";
export const POST_MENTOR_RATING = "/session/ratingbymentor";
export const BOOK_A_SLOT = "/slot";
export const GET_SLOT_LIST_DATES_MENTOR = "/slot/user";
export const APPROVE_USERS = "/user/approveUser";
export const APPROVE_MEDIA = "/user/approveMedia";
export const REJECT_MEDIA = "/user/rejectMedia";
export const RESCHEDULE_A_SESSION = "/session";
export const CONTACT_US = "/contact";
export const MENTOR_PUBLIC_PRIVATE = "/user/profilepublic";
export const FETCH_ZEGO_TOKEN = "/video/zego-token";
export const SWOT_ANALYSIS = "/swot";
export const SESSION_NOTES = "/sessionnotes";
//exam API URL
export const EXAM_TYPE = "/exam/examtype";
export const EXAM_DETAILS = "/exam";
export const EXAM_QUESTIONS = "/exam/questions/";
export const EXAM_ANSWERS = "/exam/answers";
export const EXAM_ATTEMP = "/exam/attempt";
export const EXAM_ATTEMP_COMPLETED = "/exam/attempt/completed";
export const ASSESSMENT_REPORT = "/exam/assessment/report";
//college DB URL
export const COURSE_DATA = "/college/getCourseByInstituteId/";
export const PROGRAMMES_DATA = "/college/getProgramByInstituteId/";
export const SEARCH_COLLEGE = "/college/searchCollege";
export const COLLEGES_DATA = "/college/getfilterAndColleges";
//assessment language
export const ASSESSMENT_LANG_USER = "/user/assessmentlanguage";
// recommendation URL
export const RECOMMENDATION = "/exam/assessment/recommendations";
export const LATEST_ATTEMPT = "/exam/assessment/latest-attempt";
// testimonials URL
export const CREATE_TESTIMONIALS = "/testimonial/mentor";
export const STATUS_TESTIMONIALS = "/testimonial/status";
export const APPROVE_TESTIMONIALS = "/testimonial/approval";
export const UPDATE_TESTIMONIALS = "/testimonial";
export const UPDATE_TESTIMONIALS_ID = "/testimonial/mentor";
export const GET_UPDATE_TESTIMONIALS = "/testimonial/mentor/all";
export const RESET_TESTIMONIALS = "/testimonial/mentee";
//admin allstats
export const GET_USER_ALLSTATS = "/user/allstats";

export const STRIPE_CHECKOUT = "/stripe/createCheckoutSession";
export const STRIPE_DETAILS = "/stripe/getSessionDetail";
export const STRIPE_INVOICE = "/stripe/getInvoice";
export const STRIPE_MAIL_INVOICE = "/stripe/sendMailInvoice";
//Iguroo user management
export const USER_MANAGEMENT = "/user/listiguroouser";
export const POST_IGUROO_NEWUSER = "/usermanagement/postuserforiguroo";
export const GET_ORGANIZATION_ID = "/courses/getAllOrganization";
export const GET_USERROLE_ID = "/courses/getRoles";
export const GET_ASSESSMENT_DATA="/assessment/assessmentsv2";
