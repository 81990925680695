//** Iguroo Assessment,SortableList Component **//
//** Currently not using this,will be implement in future enhancement**//

import update from "immutability-helper";
import { useCallback } from "react";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndTypes } from "../../enums";

export const SortableList = ({
  items,
  setItems,
  dndDone,
  className,
  style,
  SortableItem,
  allowCardSort = true,
  bubbleDndCallback,
  onCardAddedBack,
}) => {
  {
    const moveCard = useCallback((dragIndex, hoverIndex, item) => {
      setItems((prevCards) => {
        //delete item?.isListDrag;
        const updatedItems = update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        });

        if (!allowCardSort) {
          if (bubbleDndCallback) {
            if (typeof dndDone === "function") {
              dndDone(prevCards, dragIndex, hoverIndex, item);
            }
          }
          return prevCards;
        }
        dndDone(updatedItems, dragIndex, hoverIndex, item);
        return updatedItems;
      });
      //dndDone(setitems)
    }, []);

    const addCardBack = useCallback((dragIndex, hoverIndex, item) => {
      setItems((prevCards) => {
        const index = prevCards.findIndex((val) => {
          return val.id === item.id;
        });

        if (index === -1) {
          const updatedItems = update(prevCards, {
            $push: [item],
          });
          if (typeof onCardAddedBack === "function") {
            onCardAddedBack(updatedItems, dragIndex, hoverIndex, item);
          }
          return updatedItems;
        }
        // const updatedItems = update(prevCards, {
        //   $splice: [
        //     [dragIndex, 1],
        //     [hoverIndex, 0, prevCards[dragIndex]],
        //   ],
        // });

        // if (!allowCardSort) {
        //   if (bubbleDndCallback) {
        //     if (typeof dndDone === "function") {
        //       dndDone(prevCards, dragIndex, hoverIndex, item);
        //     }
        //   }
        //   return prevCards;
        // }
        //dndDone(updatedItems, dragIndex, hoverIndex, item);
        return prevCards;
      });
      //dndDone(setitems)
    }, []);

    const [{ handlerId }, drop] = useDrop({
      accept: DndTypes.card,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      drop(item, monitor) {
        const dragIndex = item.index;
        const hoverIndex = item.index; //index ? index : 0;
        addCardBack(dragIndex, hoverIndex, item);
        //moveCard(dragIndex, hoverIndex, item);
      },
      // hover(item, monitor) {
      //   if (!ref.current) {
      //     return;
      //   }
      //   const dragIndex = item.index;
      //   const hoverIndex = index;
      //   // Don't replace items with themselves
      //   if (dragIndex === hoverIndex) {
      //     return;
      //   }
      //   // Determine rectangle on screen
      //   const hoverBoundingRect = ref.current?.getBoundingClientRect();
      //   // Get vertical middle
      //   const hoverMiddleY =
      //     (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      //   // Determine mouse position
      //   const clientOffset = monitor.getClientOffset();
      //   // Get pixels to the top
      //   const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      //   // Only perform the move when the mouse has crossed half of the items height
      //   // When dragging downwards, only move when the cursor is below 50%
      //   // When dragging upwards, only move when the cursor is above 50%
      //   // Dragging downwards
      //   if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      //     return;
      //   }
      //   // Dragging upwards
      //   if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      //     return;
      //   }
      //   // Time to actually perform the action
      //   //moveCard(dragIndex, hoverIndex)
      //   // Note: we're mutating the monitor item here!
      //   // Generally it's better to avoid mutations,
      //   // but it's good here for the sake of performance
      //   // to avoid expensive index searches.
      //   //item.index = hoverIndex
      // },
    });

    const renderCard = useCallback((card, index) => {
      return (
        <SortableItem
          key={card.id}
          index={index}
          id={card.id}
          text={card.text}
          name={card.name}
          moveCard={moveCard}
          addCardBack={addCardBack}
        />
      );
    }, []);

    return (
      <DndProvider backend={HTML5Backend}>
        <div ref={drop} data-handler-id={handlerId} className="h-100">
          <div className={className} style={style}>
            {items.map((card, i) => renderCard(card, i))}
          </div>
        </div>
      </DndProvider>
    );
  }
};
