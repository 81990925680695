//** Iguroo Assessment Text Component**//

import React from "react";
import Card from "react-bootstrap/Card";

const InfoCard = ({ className, headerText, bodyText }) => {
  return (
    <>
      <Card className={className}>
        <Card.Header>{headerText}</Card.Header>
        <Card.Body>
          <Card.Text className="input-card-text">{bodyText}</Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default InfoCard;
