import * as yup from "yup";
export function getRateFormTranslated(formErrors) {
  return yup.object({
    time: yup.string().required(formErrors?.sessionInterval),
    currency: yup.string().required(formErrors?.currencyDetail),
    sessionCharge: yup
      .number(formErrors?.sessionRateIsRequired)
      .typeError(formErrors?.sessionRateIsRequired)
      .required(formErrors?.sessionRateIsRequired)
      .default(0),
    monthlyCharge: yup
      .number()
      .nullable()
      .optional()
      .transform((_, val) => (val !== "" ? Number(val) : null))
      .when("monthlyChargeSwitchEnable", {
        is: true,
        then: () =>
          yup
            .number(formErrors?.monthlyRateIsRequired)
            .typeError(formErrors?.monthlyRateIsRequired)

            .required(formErrors?.monthlyRateIsRequired)
            .default(0),
      }),
    monthlyChargeFree: yup.boolean().default(false),
    quarterlyCharge: yup
      .number()
      .nullable()
      .optional()
      .transform((_, val) => (val !== "" ? Number(val) : null))
      .when("quarterlyChargeSwitchEnable", {
        is: true,
        then: () =>
          yup
            .number(formErrors?.quarterlyRateIsRequired)
            .typeError(formErrors?.quarterlyRateIsRequired)

            .required(formErrors?.quarterlyRateIsRequired)
            .default(0),
      }),
    yearCharge: yup
      .number()
      .nullable()
      .optional()
      .transform((_, val) => (val !== "" ? Number(val) : null))
      .when("yearChargeSwitchEnable", {
        is: true,
        then: () =>
          yup
            .number(formErrors?.yearlyRateIsRequired)
            .typeError(formErrors?.yearlyRateIsRequired)

            .required(formErrors?.yearlyRateIsRequired)
            .default(0),
      }),
    monthlyChargeSwitchEnable: yup.boolean().default(false),
    quarterlyChargeSwitchEnable: yup.boolean().default(false),
    yearChargeSwitchEnable: yup.boolean().default(false),
  });
}
