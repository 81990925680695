import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import AWS from "aws-sdk";
import { getdocSignedURL } from "../../common/aws.helpers";

function CoursePDFViewer({ pdfUrl,isExternal }) {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  const [file, setFile] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
   
    (async function () {
      if (isExternal) {
        setFile(pdfUrl);
      } else {
        let url = await getdocSignedURL(pdfUrl)
        console.log(url, 'url');
        setFile(url);
      }
    })();
  }, [pdfUrl]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <div>
      
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <Page size="A4"
         key={`page_${pageNumber}`}
         renderTextLayer={false}
         renderAnnotationLayer={false}
         customTextRenderer={false}
         className="pdf-page courses-pdf"
         pageNumber={pageNumber} 
        />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>
      <button onClick={handlePreviousPage} disabled={pageNumber <= 1}>
        Previous Page
      </button>
      <button onClick={handleNextPage} disabled={pageNumber >= numPages}>
        Next Page
      </button>
    </div>
  );
}

export default CoursePDFViewer;