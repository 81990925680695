//** FormHeading Component,Heading of Input Field,can use this common Component required Field **//

import React from "react";
import { _getImage } from "../common/utils/commonFunctions";

export function FormHeading(props) {
  const { text, className, svgName } = props;
  return (
    <div className={`ig-heading-form mb-2 ${className}`}>
      {svgName ? (
        <>
          <img src={_getImage(svgName ? svgName : "")} />{" "}
        </>
      ) : (
        ""
      )}
      {props.children?.length > 0 ? props.children : text}
    </div>
  );
}
