//** Key and Value of ProfileLabel Translations -> Check with Value name in en.language.json **//

export function ProfileLabelsTranslations(t) {
  return {
    key: "profileLabels",
    value: {
      sortBy: t("profileLabels.sortBy"),
      areaofExpertise: t("profileLabels.areaofExpertise"),
      language: t("profileLabels.language"),
      availability: t("profileLabels.availability"),
      price: t("profileLabels.price"),
      bookmarked: t("profileLabels.bookmarked"),
      signIn: t("profileLabels.signIn"),
      signUp: t("profileLabels.signUp"),
      or: t("profileLabels.or"),
      requestHelp: t("profileLabels.requestHelp"),
      sentmsg: t("profileLabels.sentmsg"),
      signinAccount: t("profileLabels.signinAccount"),
      personalInfo: t("profileLabels.personalInfo"),
      contactDetails: t("profileLabels.contactDetails"),
      changePassword: t("profileLabels.changePassword"),
    },
  };
}
