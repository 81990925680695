//** Iguroo Assessment Page30 Component**//

import React from "react";
import { Image } from "react-bootstrap";
import { _getImage } from "../../../common/utils/commonFunctions";
import { useIgurooTranslations } from "../../../hooks/use.iguroo.translation";

const Page_30 = () => {
  const { reportAssessmentTranslations } = useIgurooTranslations();
  return (
    <div className="page_30 d-flex flex-column justify-content-center align-items-center">
      <div className="mb-5">
        <h1 className="text-white fw-600 text-center">
          {reportAssessmentTranslations.anyQuestion}
        </h1>
        <p className="text-white fw-400 fs-20 text-center">
          {reportAssessmentTranslations.contactExpert}
        </p>
      </div>
      <div className="mt-5">
        <p className="text-white footer_info">
          <Image src={_getImage("tele.svg")} width={25} height={25} />
          <span className="ms-3 fw-400">(+91) 858-882-5526</span>
        </p>
        <p className="text-white footer_info">
          <Image src={_getImage("w_message.svg")} width={25} height={25} />
          <a className="ms-3 fw-400" href="mailto:info@iguroo.com">
            info@iguroo.com
          </a>
        </p>
        <p className="text-white footer_info">
          <Image src={_getImage("w_globe.svg")} width={25} height={25} />
          <a className="ms-3 fw-400" href="https://iguroo.com">
            iGuroo.com
          </a>
        </p>
      </div>
    </div>
  );
};
export default Page_30;
