import React from "react";
import EducationScreen from "../../mentor/EducationDetails";

const Education = () => {
  return (
    <>
      <EducationScreen settings={true} />
    </>
  );
};

export default Education;
