//** SkipButton can change the text inside Button,can use this common Component required Field **//

import React from "react";
import { Button } from "react-bootstrap";

export function SkipButton(props) {
  const { isDisabled, submit, text, onClick, className, variant } = props;
  return (
    <Button
      className={`skip-btn btn btn-block btn-outline-primary ${className}`}
      variant={variant ? variant : `outline-primary`}
      type="submit"
      onClick={onClick}
    >
      {text ? text : "Skip"}
    </Button>
  );
}
