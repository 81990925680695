//** Iguroo Mentor & Mentee Requests Deatils Hooks**//

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardRequestsAsyncThunk,
  getDashboardRequestsValue,
  getRequestSessionsAsyncThunk,
  getUserProfileSessionAsyncThunk,
  getDashboardRequestsCount,
  getRequestSessionsListAsyncThunk,
  getRequestUsersListAsyncThunk,
} from "../redux-tk/slices/requests.slice";

export default function useRequests({ id, isMentor }) {
  const [totalRequestsCount, setTotlaRequestsCount] = useState(0);
  const [users, setUsers] = useState([]);
  const dashboardRequests = useSelector((state) =>
    getDashboardRequestsValue(state)
  );
  const totalRequests = useSelector((state) =>
    getDashboardRequestsCount(state)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (dashboardRequests) {
      console.log("dashboard", dashboardRequests);
      const usersToFetch = [];
      const sessionsToFetch = [];
      dashboardRequests?.forEach((val) => {
        if (!val.name) {
          console.log("no name found");
          if (
            usersToFetch.indexOf(isMentor ? val.userId : val.mentorId) === -1
          ) {
            usersToFetch.push(isMentor ? val.userId : val.mentorId);
          }
        }
        if (!val.sessionsCompleted) {
          if (sessionsToFetch.indexOf(val.requestNumber) === -1) {
            sessionsToFetch.push(val.requestNumber);
          }
        }
      });
      if (usersToFetch.length > 0) {
        dispatch(getRequestUsersListAsyncThunk({ userIds: usersToFetch }));
      }
      if (sessionsToFetch.length > 0) {
        dispatch(
          getRequestSessionsListAsyncThunk({ requestNumbers: sessionsToFetch })
        );
      }
      setTotlaRequestsCount(totalRequests);
      setUsers(dashboardRequests);
    }
  }, [dashboardRequests]);

  useEffect(() => {
    console.log(id, "id");
    if (id) {
      const param = isMentor ? "mentorId" : "userId";
      console.log(param, "isMentor");
      const body = { req: { [param]: id, size: 5 }, isMentor };
      dispatch(getDashboardRequestsAsyncThunk(body));
    }
  }, [id]);

  return { users, totalRequestsCount };
}
