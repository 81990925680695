import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession
} from "amazon-cognito-identity-js";
import {
  CognitoIdentity,
  CognitoIdentityServiceProvider,
  CognitoIdentityCredentials,
} from "aws-sdk";
import {
  APIGatewayClient,
  CreateApiKeyCommand,
  GetMethodCommand,
} from "@aws-sdk/client-api-gateway";

import {
  region,
  userPoolId,
  clientId,
  identityPoolId,
} from "../services/config";
import { StrConstants } from "../enums";
import { json } from "react-router-dom";

// console.log(`userpool id=${userPoolId}`);
// console.log(`client id=${clientId}`);

const poolData = {
  UserPoolId: `${userPoolId}`,
  ClientId: `${clientId}`,
};

// const cognitoIdentityCreds = {
//   IdentityPoolId: identityPoolId,
//   Logins: {},
// };

const userPool = new CognitoUserPool(poolData);

let currentUser = userPool.getCurrentUser();

export function getCurrentUser() {
  return currentUser;
}

let currentCognitoUser = null;

let session = null;

// export async function getTempIdentity(token) {
//   cognitoIdentityCreds.Logins[
//     "cognito-idp." + region + ".amazonaws.com/" + userPoolId
//   ] = token;
//   const creds = new CognitoIdentityCredentials(cognitoIdentityCreds);
//   //creds.get()
//   // AWS.config.credentials = new CognitoIdentityCredentials(cognitoIdentityCreds);
//   // AWS.config.getCredentials((err,mcreds) => {
//   //   if(err){
//   //     console.log(err);
//   //     return;

//   //   }
//   //   console.log("mcreds",mcreds);
//   // })
//   creds.get((err) => {
//     if (err) {
//       console.log(err);
//     } else {
//       console.log("creds", creds.accessKeyId);
//       console.log("creds", creds.secretAccessKey);
//       console.log("creds", creds.sessionToken);

//       const client = new APIGatewayClient({
//         credentials: {
//           accessKeyId: creds.accessKeyId, //AWS.config.credentials.accessKeyId, //'ACCESS_KEY',
//           secretAccessKey: creds.secretAccessKey, //AWS.config.credentials.secretAccessKey, //'SECRET_KEY',
//           sessionToken: creds.sessionToken, //AWS.config.credentials.sessionToken, // 'SESSION_TOKEN', //OPTIONAL: If you are using temporary credentials you must include the session token
//         },
//         region: region, // OPTIONAL: The region where the API is deployed, by default this parameter is set to us-east-1
//       });
//       var params = {
//         // This is where any modeled request parameters should be added.
//         // The key is the parameter name, as it is defined in the API in API Gateway.
//       };

//       var body = {
//         // This is where you define the body of the request,
//         query: "{person {firstName lastName}}",
//       };

//       var additionalParams = {
//         // If there are any unmodeled query parameters or headers that must be
//         //   sent with the request, add them here.
//         headers: {},
//         queryParams: {},
//       };
//       // const getCommand = new GetMethodCommand({
//       //   httpMethod: 'GET',

//       // })
//       // client.send()
//     }
//   });
// }

function getCognitoUser(username) {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);

  return cognitoUser;
}

export async function getSession() {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser();
  }

  return new Promise(function (resolve, reject) {
    if (!currentUser) {
      reject(new Error("current user is null"));
    }
    currentUser.getSession(function (err, session) {
      if (err) {
        reject(err);
      } else {
        resolve(session);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function refreshSession(refreshToken) {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser();
  }

  return new Promise(function (resolve, reject) {
    if (!currentUser) {
      reject(new Error("current user is null"));
    }
    const token = new CognitoRefreshToken({ RefreshToken: refreshToken })

    currentUser.refreshSession(token, function (err, session) {
      if (err) {
        reject(err);
      } else {
        localStorage.setItem("accessToken", `${session.accessToken.jwtToken}`);
        localStorage.setItem("refreshToken", `${session.refreshToken.token}`);
        localStorage.setItem("idToken", `${session.idToken.jwtToken}`);
        localStorage.setItem("test", `${session.idToken.jwtToken}`);
        resolve(session);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function signUpUserWithEmail(
  username,
  email,
  password,
  mobile,
  firstName,
  lastName,
  profileType
) {
  return new Promise(function (resolve, reject) {
    const attributeList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: email,
      }),
      new CognitoUserAttribute({
        Name: "phone_number",
        Value: mobile,
      }),
      new CognitoUserAttribute({
        Name: "given_name",
        Value: firstName,
      }),
      new CognitoUserAttribute({
        Name: "family_name",
        Value: lastName,
      }),
      new CognitoUserAttribute({
        Name: "name",
        Value: firstName + " " + lastName,
      }),
      new CognitoUserAttribute({
        Name: "custom:userRole",
        Value: profileType,
      }),
    ];

    userPool.signUp(username, password, attributeList, [], function (err, res) {
      if (err) {
        reject(err);
      } else {
        resolve(res);
        console.log(res);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function verifyCode(username, code) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  }).catch((err) => {
    throw err;
  });
}
export async function verifyEmailCode(code,name) {
  return new Promise(function (resolve, reject) {


    // currentUser.getAttributeVerificationCode('email', {
    //   onSuccess: function (result) {
    console.log('call result: ' + code);

    currentUser.verifyAttribute(name, code, {
      onSuccess: function (result) {
        console.log('call result: ' + result);
        resolve(result);
      },
      onFailure: function (err) {
        console.log("otp err", err);
        reject(err);
      },
    });
    // },
    // });

  }).catch((err) => {
    throw err;
  });
}
export async function signinWithOTP(username) {
  return new Promise(function (resolve, reject) {

    let params = {
      AuthFlow: "CUSTOM_AUTH",
      ClientId: clientId,

      AuthParameters: {
        USERNAME: username,
        // UserAttributes:[
        //   new CognitoUserAttribute({
        //     Name: "logged_in_by",
        //     Value: 'email',
        //   })
        // ],
        testAuth: 'email',
        UserAttributes: JSON.stringify({ logged_in_by: 'email' })
      },
      ClientMetadata: {
        customChallenge: 'PASSWORDLESS_AUTH',

        logged_in_by: 'email'

      },
      // UserAttributes:{
      //   logged_in_by:'email'
      // }
    };
    let cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
      region: region,
    });
    currentUser = getCognitoUser(username);
    console.log("data", currentUser);
    // currentUser.setAttribute({ logged_in_by:'email'})
    const det = cognitoidentityserviceprovider.initiateAuth(
      params,
      (err, data) => {
        if (err) return reject(err);
        console.log("data", data.ChallengeParameters.USERNAME);
        localStorage.setItem(StrConstants.username, data.ChallengeParameters.USERNAME);
        session = data.Session;
        resolve(data);
      }
    );
  }).catch((err) => {
    throw err;
  });
}

export async function confrimSigninOTP(username, loginOtp, pageSession) {
  return new Promise((resolve, reject) => {
    let params = {
      ChallengeName: "CUSTOM_CHALLENGE",
      ClientId: clientId,

      ChallengeResponses: {
        USERNAME: username,
        ANSWER: loginOtp,
      },
      Session: pageSession ? pageSession : session,
    };
    let cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
      region: region,
    });
    currentUser = getCognitoUser(username);
    cognitoidentityserviceprovider.respondToAuthChallenge(params, {
      onSuccess: function (data) {
        resolve(data);
        console.log("data", data);

        // authenticationResult = data.AuthenticationResult;
        // idToken = authenticationResult.IdToken;
        // jwtToken = idToken.jwtToken
        // accessToken = authenticationResult.AccessToken;
        // console.log("idToken: " + JSON.stringify(parseJwt(idToken)));
        // console.log("accessToken: " + JSON.stringify(parseJwt(accessToken)));
        // console.log("jwtToken: " + jwtToken);
      },
      call: function (data) {
        //console.log("callback call", data,"err");
        if (!data.data) {
          return reject(data);
        }
        let authenticationResult = data.data.AuthenticationResult;
        const cognitoIdToken = new CognitoIdToken({
          IdToken: authenticationResult.IdToken,
        });
        const cognitoAccessToken = new CognitoAccessToken({
          AccessToken: authenticationResult.AccessToken,
        });
        const cognitoRefreshToken = new CognitoRefreshToken({
          RefreshToken: authenticationResult.RefreshToken,
        });
        currentUser.setSignInUserSession(
          new CognitoUserSession({
            AccessToken: cognitoAccessToken,
            IdToken: cognitoIdToken,
            RefreshToken: cognitoRefreshToken,
          })
        );
        resolve(data);
      },
      onFailure: function (err) {
        console.log("otp err", err);
        reject(err);
      },
    });
  });
}

export async function signinWithGoogle(username, data) {
  try {
    currentUser = getCognitoUser(username);
    const socialTokens = data.signInUserSession;
    const cognitoIdToken = new CognitoIdToken({
      IdToken: socialTokens.idToken.jwtToken,
    });
    const cognitoAccessToken = new CognitoAccessToken({
      AccessToken: socialTokens.accessToken.jwtToken,
    });
    const cognitoRefreshToken = new CognitoRefreshToken({
      RefreshToken: socialTokens.refreshToken.jwtToken,
    });
    currentUser.setSignInUserSession(
      new CognitoUserSession({
        AccessToken: cognitoAccessToken,
        IdToken: cognitoIdToken,
        RefreshToken: cognitoRefreshToken,
      })
    );
  } catch (err) {
    throw err;
  }
}

export async function signInWithEmail(username, password) {
  return new Promise(function (resolve, reject) {
    const authenticationData = {
      Username: username,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    console.log(authenticationDetails);
    currentUser = getCognitoUser(username);
    console.log(
      "username",
      currentUser,
      "session",
      "signin session",
      currentUser.getSignInUserSession()
    );

    //currentUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
    currentUser.authenticateUser(authenticationDetails, {
      onSuccess: function (res) {
        resolve(res);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export function signOut() {
  if (currentUser) {
    currentUser.signOut();
  }
}

export async function getAttributes() {
  return new Promise(function (resolve, reject) {
    currentUser.getUserAttributes(function (err, attributes) {
      if (err) {
        reject(err);
      } else {
        resolve(attributes);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function setAttribute(attribute) {
  return new Promise(function (resolve, reject) {
    const attributeList = [];
    const res = new CognitoUserAttribute(attribute);
    attributeList.push(res);

    currentUser.updateAttributes(attributeList, (err, res) => {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function resendConfirmationCode(username) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    cognitoUser.resendConfirmationCode((err, data) => {
      if (err) reject(err);
      console.log("resend data", data);
      resolve(data);
    });
  }).catch((err) => {
    throw err;
  });
}

export async function sendCode(username) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);
    console.log('username forgotpassword',cognitoUser)
    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }
    console.log('username forgotpassword')
    cognitoUser.forgotPassword({
      onSuccess: function (res) {
        resolve(res);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export async function forgotPassword(username, code, password) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    cognitoUser.confirmPassword(code, password, {
      onSuccess: function () {
        resolve("password updated");
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
}

export async function resetPassword(oldPassword, newPassword) {
  return new Promise(function (resolve, reject) {
    // currentUser = getCognitoUser(username);
    currentUser.changePassword(oldPassword, newPassword, {
      onSuccess: function (res) {
        resolve(res);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export async function changeEmail(newEmail, currentPassword) {
  return new Promise(function (resolve, reject) {
    const attributes = [
      new CognitoUserAttribute({ Name: "email", Value: newEmail }),
    ];
    currentUser.updateAttributes(attributes, function (err, res) {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

// export async function changeName(firstName, lastName,email,mobile) {
//   return new Promise(function (resolve, reject) {
//     let attribute = [
//       new CognitoUserAttribute({
//         Name: "given_name",
//         Value: firstName,
//       }),
//       new CognitoUserAttribute({
//         Name: "family_name",
//         Value: lastName,
//       }),
//       new CognitoUserAttribute({
//         Name: "email",
//         Value: email,
//       }),
//       new CognitoUserAttribute({
//         Name: "mobile",
//         Value: mobile,
//       }),
//     ]
//     currentUser.updateAttributes(attribute, function (err, res) {
//       if (err) {
//         reject(err);
//       } else {
//         resolve(res);
//       }
//     });
//   }).catch((err) => {
//     throw err;
//   });
// }
export async function changeName(firstName, lastName) {
  return new Promise(async function (resolve, reject) {
    let attribute = [
      new CognitoUserAttribute({
        Name: "given_name",
        Value: firstName,
      }),
      new CognitoUserAttribute({
        Name: "family_name",
        Value: lastName,
      }),

      // new CognitoUserAttribute({
      //   Name: "email",
      //   Value: email,
      // }),

      // new CognitoUserAttribute({
      //   Name: "phone_number",
      //   Value: phone_number,
      // }),
    ];
    // let current = currentUser();
    // const session = await getSession();
    // console.log(session,'session 165')
    currentUser.updateAttributes(attribute, function (err, res) {
      if (err) {
        reject(err);
      } else {
        resolve(res);
        // currentUser.getUserAttributes(function (err, attributes) {
        //   if (err) {
        //     console.log(err, 'data 165 if');
        //   } else {
        //     console.log(attributes, 'data 165 else');
        //   }
        // });
      }
    });

  }).catch((err) => {
    throw err;
  });
}
export async function changeEmailPhone(name, value) {
  return new Promise(async function (resolve, reject) {
    let attribute = [

      new CognitoUserAttribute({
        Name: name,
        Value: value,
      }),

    ];
   
    currentUser.updateAttributes(attribute, function (err, res) {
      if (err) {
        reject(err);
      } else {
        resolve(res);
       
      }
    });

  }).catch((err) => {
    throw err;
  });
}
export async function changePassword(oldPassword, newPassword) {
  return new Promise(function (resolve, reject) {
    currentUser.changePassword(oldPassword, newPassword, function (err, res) {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  });
}

export async function resendEmailConfirmCode(email) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(email);

    if (!cognitoUser) {
      reject(`could not find ${email}`);
      return;
    }
    currentUser.getAttributeVerificationCode('email', {
      onSuccess: function (result) {
          console.log('call result: ' + result);
      },
      onFailure: function(err) {
          alert(err);
      },
      inputVerificationCode: function() {
          var verificationCode = prompt('Please input verification code: ' ,'');
          cognitoUser.verifyAttribute('email', verificationCode, this);
      }
    })
  });
}