//** Query Hooks,use in Header(Window Size fit Screen) **//

import React from "react";

export default function useQuery() {
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [viewType, setViewType] = React.useState({
    isMobile: false,
    isTab: false,
    isDesk: false,
  });
  React.useEffect(() => {
    if (windowSize.width < 500) {
      setViewType({
        isMobile: true,
        isTab: false,
        isDesk: false,
      });
    } else if (windowSize.width < 768) {
      setViewType({
        isMobile: false,
        isTab: true,
        isDesk: false,
      });
    } else {
      setViewType({
        isMobile: false,
        isTab: false,
        isDesk: true,
      });
    }
  }, [windowSize]);

  function changeWindowSize() {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  }

  React.useEffect(() => {
    console.log("called", window.innerWidth);
    window.addEventListener("resize", changeWindowSize);
    return () => {
      window.removeEventListener("resize", changeWindowSize);
    };
  }, []);

  return viewType;
}
