//** Assessment Question Type like Dropdown,Radio,Rate,Text Type of questions Components assmbeled here for Iguroo **//

import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Image, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AssessmentDropDownQues from "../../components/assessment/assessment.dropdown.ques";
import AssessmentRateQues from "../../components/assessment/assessment.rate.ques";
import AssessmentSortQues from "../../components/assessment/assessment.sort.ques";
import AssessmentTextQues from "../../components/assessment/assessment.text.ques";
import AssessmentYesNoQues from "../../components/assessment/assessment.Yes.No.ques";
import { Heading } from "../../components/heading.component";
import { ExamQuestionType } from "../../enums/exam.ques.type";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import {
  currentQuestionValue,
  getExamAttemptAsyncThunk,
} from "../../redux-tk/slices/exam.currentQuestion";
import { loader } from "../../redux-tk/slices/loader.slice";
import { GetExam, GetExamDetails } from "../../services/axios/Exam";
import { AuthContext } from "../../hooks/auth.context.provider";
import { Languages } from "../../enums";
import { useTranslation } from "react-i18next";
import { _getImage } from "../../common/utils/commonFunctions";
import {
  answerSelected,
  answerValue,
  changeCurrentQuestion,
} from "../../redux-tk/slices/exam.currentQuestion";
import { ExamAttempt, ResetExam } from "../../services/axios/Exam";
import { useNavigate } from "react-router-dom";
import { AppUrlsEnum } from "../../enums";
import { PopupUnsaved } from "../../components/CustomInput/popupUnsaved";

const TestAssessmentsQuestions = (props) => {
  const {
    formElementLabels,
    menteeOnboardingLabels,
    testAssessmentTranslations,
  } = useIgurooTranslations();
  const {
    text,
    className,
    firstButton,
    secondBtn,
    svgSecond,
    svgFirst,
    onFirstBtnClick,
    onSecondBtnClick,
    previousButton,
    svgPrevious,
    onprevBtnClick,
    disabled,
  } = props;
  const [examDetails, setExamDetails] = useState({});
  const [examQuestions, setExamQuestions] = useState([]);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const location = useLocation();
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [showPopup2, setShowPopup2] = useState(false);
  const [lang, setLang] = useState(Languages.english);

  useEffect(() => {
    const langStatus = localStorage.getItem("assessmentlanguage");
    if (langStatus?.length > 1) {
      i18n.changeLanguage(langStatus?.toLowerCase());
      setLang(langStatus.toLowerCase());
    } else {
      i18n.changeLanguage(Languages.english);
    }
  }, []);

  useEffect(() => {
    (async function () {
      const pageState = location?.state;
      dispatch(loader(true));
      const examData = {
        examId: pageState?.examId,
        userId: authContext.sessionInfo.sub,
      };
      dispatch(getExamAttemptAsyncThunk(examData));
      await GetExamDetails(pageState?.examType)
        .then((res) => {
          const examDet = res?.response?.Items[0];
          //console.log(res,examDet,'console');
          setExamDetails(examDet);
        })
        .catch((err) => console.log(err));
      await GetExam(pageState?.examId)
        .then((res) => {
          console.log(res, "console exam");
          const ques = res?.response;
          setExamQuestions(ques);
          setTotalQuestions(ques.length);
        })
        .catch((err) => console.log(err));
      dispatch(loader(false));
    })();
  }, []);

  const currentQuestion = useSelector((state) => currentQuestionValue(state));

  const onReset = async (data) => {
    try {
      setShowPopup2(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleResetAssessment = async (data) => {
    try {
      dispatch(loader(true));
      const ans = [];
      const pageState = location?.state;
      dispatch(answerSelected(ans));
      let completeExam = false;
      const res = await ExamAttempt(
        pageState?.examId,
        authContext.sessionInfo.sub,
        completeExam,
        ans
      );
      await ResetExam(res.attemptId, res.userId);
      dispatch(changeCurrentQuestion(1));
      navigate(AppUrlsEnum.testAssesment, {
        state: { examType: pageState?.examType, examId: pageState?.examId },
      });
      console.log("props?.examType", pageState?.examType);
      dispatch(loader(false));
    } catch (err) {
      console.log(err);
    }
    console.log("it is resetted");
  };

  const handleLeaveAssessment = () => {
    setShowPopup2(false);
    console.log("stays in same page not resetted");
  };

  return (
    <Container>
      <Row></Row>
      <Row>
        <Col className="mt-4" md="4">
          <Button
            className="btn btn-outline-dark ig-reset-btn"
            onClick={onReset ? onReset : () => {}}
          >
            {svgFirst ? (
              <>
                {" "}
                <Image
                  height={20}
                  width={20}
                  src={_getImage(svgFirst)}
                  className=""
                />{" "}
              </>
            ) : (
              ""
            )}
            {firstButton ? firstButton : testAssessmentTranslations?.resettBtn}
          </Button>
        </Col>
        <Col md="8" className="mt-4">
          <Heading className="headingDashboard">
            {lang == Languages.english
              ? examDetails?.exam_name?.toUpperCase()
              : examDetails?.[`exam_name_${lang}`]?.toUpperCase()}
          </Heading>
        </Col>
      </Row>
      <PopupUnsaved
        showPopup={showPopup2}
        type="popup"
        gotIt={handleLeaveAssessment}
        gotIt2={handleResetAssessment}
        modalBody={menteeOnboardingLabels.assessmentReset}
        yes={formElementLabels.yesResetAssessment}
        cancel={formElementLabels.noResetAssessment}
      />
      {/* These Three Components,Will be used in Future*/}
      {/* <AssessmentYesNoQues /> */}
      {/* <AssessmentTextQues /> */}
      {/* <AssessmentRadioQues/> */}
      {examQuestions?.map((questions) => {
        if (currentQuestion == questions.question_id) {
          return questions.question_type == ExamQuestionType.yesNo ? (
            <AssessmentYesNoQues
              {...questions}
              {...examDetails}
              totalQuestions={totalQuestions}
            />
          ) : questions.question_type == ExamQuestionType.rate ? (
            <AssessmentRateQues
              {...questions}
              {...examDetails}
              totalQuestions={totalQuestions}
            />
          ) : questions.question_type == ExamQuestionType.sort ? (
            <AssessmentSortQues
              {...questions}
              {...examDetails}
              totalQuestions={totalQuestions}
            />
          ) : questions.question_type == ExamQuestionType.text ? (
            <AssessmentTextQues
              {...questions}
              {...examDetails}
              totalQuestions={totalQuestions}
            />
          ) : questions.question_type == ExamQuestionType.dropdown ? (
            <AssessmentDropDownQues
              {...questions}
              {...examDetails}
              totalQuestions={totalQuestions}
            />
          ) : (
            <AssessmentTextQues
              {...questions}
              totalQuestions={totalQuestions}
            />
          );
        } else {
          return;
        }
      })}
    </Container>
  );
};

export default TestAssessmentsQuestions;
