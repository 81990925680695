//** FormLabel Component,Heading of Input Field,can use this common Component required Field **//

export function FormLabelComponent(props) {
  const { text, className } = props;
  return (
    <label className={`ig-form-label formLabel form-label ${className}`}>
      {props.children?.length > 0 ? props.children : text}
    </label>
  );
}
