//** SwotContainer Assessment for Iguroo,Will be use these component in future **//

import { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSwotDataSlice,
  deleteSwotDataSlice,
  getSwotAsyncThunk,
  postSwotAsyncThunk,
  putSwotAsyncThunk,
  swotValue,
  updateSwotDataSlice,
} from "../../redux-tk/slices/swot.slice";
import { AuthContext } from "../../hooks/auth.context.provider";
import { loader } from "../../redux-tk/slices/loader.slice";

export const useSwotConatiner = () => {
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const swotDataValue = useSelector((state) => swotValue(state));

  const addSwotData = useCallback((type, data) => {
    dispatch(addSwotDataSlice({ type, data }));
  }, []);

  const updateSwotData = useCallback((id, type, data) => {
    dispatch(updateSwotDataSlice({ type, id, data }));
  }, []);

  const deleteSwotData = useCallback((id, type) => {
    dispatch(deleteSwotDataSlice({ id, type }));
  }, []);

  const saveSwotData = useCallback(async (swot) => {
    try {
      dispatch(loader(true));
      if (swot?.swotId) {
        let newSwot = Object.fromEntries(
          Object.entries(swot).filter(([k, v]) => k != "createdAt")
        );
        await dispatch(putSwotAsyncThunk(newSwot));
      } else {
        let newSwot = Object.fromEntries(
          Object.entries(swot).filter(([_, v]) => v != null)
        );
        await dispatch(postSwotAsyncThunk(newSwot));
      }
      return true;
    } catch (err) {
      return false;
    } finally {
      dispatch(loader(false));
    }
  }, []);

  useEffect(() => {
    (async function () {
      //console.debug("calling get swot");
      dispatch(loader(true));
      dispatch(getSwotAsyncThunk({ userId: authContext?.sessionInfo?.sub }));
      dispatch(loader(false));
    })();
  }, [authContext?.sessionInfo?.sub]);

  return {
    swotDataValue,
    addSwotData,
    updateSwotData,
    deleteSwotData,
    saveSwotData,
  };
};
