import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import { _getImage } from "../../common/utils/commonFunctions";
import { Link } from "react-router-dom";

export default function CourseURLCard(props) {
    const {
        title,
        text,
        number,
        index,

        link,

    } = props;

    return (
        <>
            <Card
                className={` p-3  course-url-card my-4 w-80`}
            >
                <div className="ig-course-URL-title">{number}{'. '}{title}</div>
                <div className="ig-courses-URL-subtitle mt-3">
                    <a href={link} target="blank"> 

                        <img
                            height={20}
                            width={20}
                            src={_getImage("link-icon.png")}
                            className="icon"
                        />{'  '} {text}
                    </a>
                </div>
            </Card>
        </>
    )
}
