//** Mentee Onboarding Education and WorkExperience **//

import React, { useState, useEffect, useContext } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { _getImage } from "../../../common/utils/commonFunctions";
import { GetDataType } from "../../../services/axios/staticData";
import { allDataConverter } from "../../../helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Row, Col, Container } from "react-bootstrap";
import { getEduWorkExpFormTranslated } from "../../../FormSchemas";
import { useNavigate, useLocation } from "react-router-dom";
import Toaster from "../../../components/toaster";
import { toast } from "react-toastify";
import { SubmitButton } from "../../../components/submit.button";
import { FormErrorComponent } from "../../../components/form.error.component";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteEducationAsyncThunk,
  deletWorkExpAsyncThunk,
  getEducationAsyncThunk,
  getWorkExpAsyncThunk,
  workExpAndEDUAction,
  workExpAndEduValue,
} from "../../../redux-tk/slices/mentee.onboarding.data";
import { useIgurooTranslations } from "../../../hooks/use.iguroo.translation";
import { loader } from "../../../redux-tk/slices/loader.slice";
import { Heading } from "../../../components/heading.component";
import { EducationComponent } from "../../../components/education.component";
import { CustomAddMoreBtn } from "../../../components/CustomInput/customAddMoreBtn";
import { WorkExpItemComponent } from "../../../components/work.exp.item.component";
import {
  PostEduExp,
  PostWorkExp,
} from "../../../services/axios/MentorOnbaording";
import { AuthContext } from "../../../hooks/auth.context.provider";
import { AppUrlsEnum, ProfileType, OnboardingSteps } from "../../../enums";
import {
  getUserAsyncThunk,
  postUserProfileAsyncThunk,
} from "../../../redux-tk/slices/user.slice";
import { getStepsObject } from "../../../helpers";
import { MentorSetupSidebar } from "../../../components/mentor.setup.sidebar";
import { OnboardingHeading } from "../../../components/onboarding.heading.component";
import { SkipButton } from "../../../components/skip.button";
import { showPopupflag } from "../../../redux-tk/slices/showPopupflag.slice";

export const EDUCATION_TEMPLATE = {
  id: -1,
  instituteName: "",
  specialization: "",
  courseName: "",
  year: "",
};

export const WORK_EXPERIENCE_TEMPLATE = {
  id: -1,
  role: "",
  company: "",
  year: "",
};

function MenteeEducationExperience({ settings }) {
  const {
    menteeOnboardingLabels,
    formElementLabels,
    formErrors,
    headingTranslations,
    headerFooterTranslations,
  } = useIgurooTranslations();
  const dispatch = useDispatch();
  const selectedValue = useSelector((state) => workExpAndEduValue(state));
  console.log("se", selectedValue);
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    defaultValues: {
      edu: [...selectedValue?.edu],
      wrk: [...selectedValue?.wrk],
    },
    resolver: yupResolver(getEduWorkExpFormTranslated(formErrors)),
  });
  let ignore = false;

  useEffect(() => {
    if (isDirty) {
      (async function () {
        dispatch(showPopupflag(true));
      })();
    }
  }, [isDirty]);

  useEffect(() => {
    if (!ignore) {
      dispatch(getWorkExpAsyncThunk({ userId: authContext.sessionInfo.sub }));
      dispatch(getEducationAsyncThunk({ userId: authContext.sessionInfo.sub }));
    }
    return () => {
      ignore = true;
    };
  }, []);
  useEffect(() => {
    if (selectedValue[0]?.id != -1) {
      reset({ edu: [...selectedValue?.edu], wrk: [...selectedValue?.wrk] });
    }
  }, [selectedValue]);
  const authContext = useContext(AuthContext);

  const [degreeCertificates, setDegreeCertificateData] = useState([]);
  const [fieldData, setfield] = useState([]);
  const [yearData, setYear] = useState([]);
  useEffect(() => {
    (async function () {
      dispatch(loader(true));
      dispatch(getEducationAsyncThunk({ userId: authContext.sessionInfo.sub }));
      dispatch(getUserAsyncThunk({ userId: authContext.sessionInfo.sub }));
      const qItems = await GetDataType("qualification", "english");
      const endOfYearItems = await GetDataType("endyear", "english");
      const fieldItems = await GetDataType("fieldOfStudy", "english");
      setDegreeCertificateData(qItems);
      setYear(endOfYearItems);
      setfield(fieldItems);
      dispatch(loader(false));
    })();
  }, []);
  const [userEducationRow, setUserEducationRow] = useState([
    EDUCATION_TEMPLATE,
  ]);
  const [count, setCount] = useState(0);
  const { state } = useLocation();
  console.log("state", state);
  const [userWorkExpRow, setUserWorkExpRow] = useState([
    WORK_EXPERIENCE_TEMPLATE,
  ]);

  const navigate = useNavigate();
  const {
    fields: eduField,
    append: appendEdu,
    prepend: prependEdu,
    remove: removeEdu,
    swap: swapEdu,
    move: moveEdu,
    insert: insertEdu,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "edu", // unique name for your Field Array
    shouldUnregister: true,
  });

  const {
    fields: wrkField,
    append: appendWrk,
    prepend: prependWrk,
    remove: removeWrk,
    swap: swapWrk,
    move: moveWrk,
    insert: insertWrk,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "wrk", // unique name for your Field Array
    shouldUnregister: true,
  });
  const skipOnClick = async (e) => {
    e.preventDefault();
    await dispatch(
      postUserProfileAsyncThunk(
        getStepsObject(
          OnboardingSteps[ProfileType.mentee][2].value,
          authContext.sessionInfo.sub
        )
      )
    );
    navigate(AppUrlsEnum.menteeSkill);
  };
  const submitEducationDetails = async (data) => {
    dispatch(loader(true));
    console.log(data, data.wrk, "callstart");
    const finalData = allDataConverter.removeDefaultId(data.edu);
    const result = await PostEduExp(authContext.sessionInfo.sub, finalData);
    console.log("re'", result);
    //const converted = responseToRedux(result.data);
    //console.log("con", converted);
    let saveToRedux = {
      edu: result.data,
      wrk: [WORK_EXPERIENCE_TEMPLATE],
    };
    const workDetail = data.wrk.filter((work) => work.company !== "");
    console.log(workDetail, "callstart nullWork");

    if (workDetail.length > 0) {
      const finalData1 = allDataConverter.removeDefaultId(workDetail);
      console.log(finalData1, "callstart");
      const result1 = await PostWorkExp(
        authContext.sessionInfo.sub,
        finalData1
      );
      const converted = responseToRedux(result.data);
      //  console.log("con", converted);
      saveToRedux = {
        edu: result.data,
        wrk: result1.data,
      };
    } else if (
      data.wrk[0].company === "" &&
      !(data.wrk[0].id === "-1" || data.wrk[0].id === -1)
    ) {
      removeWrk(data.wrk[0].id);
      dispatch(deletWorkExpAsyncThunk({ id: data.wrk[0].id }));
      console.log(data.wrk[0].id, "callstart");
    }

    dispatch(workExpAndEDUAction(saveToRedux));
    Toaster(toast.success, headerFooterTranslations.toasterMsgOnboarding);
    if (!settings) {
      await dispatch(
        postUserProfileAsyncThunk(
          getStepsObject(
            OnboardingSteps[ProfileType.mentee][2].value,
            authContext.sessionInfo.sub
          )
        )
      );
    }

    if (!settings) {
      navigate(AppUrlsEnum.menteeSkill);
    }
    dispatch(loader(false));
  };
  const responseToRedux = (edu) => {
    return edu.map((val) => {
      return {
        id: "",
        instituteName: "",
        specialization: "",
        courseName: "",
        year: "",
      };
    });
  };
  const removeEducationInputs = (inputIndex) => {
    if (eduField.length > 1) {
      const value = getValues(`edu.${inputIndex}.id`);
      if (value === "-1" || value === -1) {
        removeEdu(inputIndex);
        return;
      }
      //console.log('dele"',field,value);
      setPopupFlag();
      dispatch(deleteEducationAsyncThunk({ id: value }));
      //remove(inputIndex);
    }
  };

  const setPopupFlag = () => {
    (async function () {
      dispatch(showPopupflag(true));
    })();
  };

  const addEducationInputs = () => {
    const wet1 = { ...EDUCATION_TEMPLATE };
    appendEdu(wet1);
  };

  const renderEducationInfoRows = () => {
    return (
      <div className="mb-2">
        <Heading>{headingTranslations?.menteeEdu}</Heading>
        <Row>
          <Col>
            <Form.Group>
              {eduField.map((field, index) => {
                return (
                  <EducationComponent
                    key={field.id}
                    removeFunction={removeEducationInputs}
                    hookControl={control}
                    itemIndex={index}
                    errors={errors}
                    certificateItems={degreeCertificates}
                    fieldOfStudyItems={fieldData}
                    endOfYearItems={yearData}
                  />
                );
              })}
              <Row className="mt-3">
                <Col md="4" className="d-grid d-md-block">
                  <CustomAddMoreBtn
                    onClick={() => {
                      addEducationInputs();
                    }}
                  />
                </Col>
                <Col md="8"></Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
      </div>
    );
  };
  const removeAvailabilityInputs = (inputIndex) => {
    if (wrkField.length > 1) {
      const value = getValues(`wrk.${inputIndex}.id`);
      console.log(value);
      if (value === "-1" || value === -1) {
        removeWrk(inputIndex);
        return;
      }
      //console.log('dele"',field,value);
      setPopupFlag();
      dispatch(deletWorkExpAsyncThunk({ id: value }));
    }
  };

  const addAvailabilityInputs = () => {
    const wet = { ...WORK_EXPERIENCE_TEMPLATE };
    appendWrk(wet);
  };
  const renderWorkInfoRows = () => {
    return (
      <div className="mt-4">
        <Heading>{headingTranslations?.menteeWorkExp}</Heading>
        <Row>
          <Col>
            <Form.Group>
              {wrkField.map((field, index) => {
                //console.log("fil'", field);
                return (
                  <WorkExpItemComponent
                    key={field.id}
                    removeFunction={removeAvailabilityInputs}
                    hookControl={control}
                    itemIndex={index}
                    errors={errors}
                    profileType={ProfileType.mentee}
                  />
                );
              })}
              <Row>
                <Col md="4" className="d-grid d-md-block">
                  <CustomAddMoreBtn
                    className="mt-3"
                    onClick={(e) => {
                      addAvailabilityInputs();
                    }}
                  />
                </Col>
                <Col md="8"></Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div>
      {!settings && <OnboardingHeading />}
      <Container className="p-3">
        <Row>
          <Col sm="12" md="12" lg={settings ? 12 : 9}>
            <Form onSubmit={handleSubmit(submitEducationDetails)}>
              {renderEducationInfoRows()}
              {renderWorkInfoRows()}

              <Row className="mt-1 mb-3">
                <FormErrorComponent>
                  {errors.apiError && errors.apiError?.message}
                </FormErrorComponent>
              </Row>

              <hr />
              <Row className="text-end mb-4 justify-content-end save-btn-end">
                <Col xs="6" sm="2" md="2" className="d-grid">
                  {!settings && <SkipButton onClick={skipOnClick} />}
                </Col>

                <Col xs="6" sm="2" md="2" className="d-grid">
                  <SubmitButton text={formElementLabels?.save} />
                </Col>
              </Row>
            </Form>
          </Col>
          {!settings && (
            <Col>
              {menteeOnboardingLabels?.rightSetupModule?.mentee && (
                <MentorSetupSidebar
                  typeToUse={menteeOnboardingLabels?.rightSetupModule?.mentee}
                  active={
                    menteeOnboardingLabels?.rightSetupModule?.mentee?.values
                      ?.eduAndExp
                  }
                />
              )}
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default MenteeEducationExperience;
