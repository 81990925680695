//** Iguroo Mentor Card List Details like name,BookSession,Rating,etc. **//

import React, { useEffect, useState } from "react";
import { Col, Card, Row } from "react-bootstrap";
import { _getImage } from "../common/utils/commonFunctions";
import { CardTitleComponent } from "./cardComponent/card.title";
import { CardSecondaryComponent } from "./cardComponent/card.secondary.text";
import { CardTextComponent } from "./cardComponent/card.text";
import { IconLabelComponent } from "./cardComponent/icon.label";
import { SubmitButton } from "./submit.button";
import { useIgurooTranslations } from "../hooks/use.iguroo.translation";
import { RateDurationComponent } from "./cardComponent/rate.duration.component";
import CustomBadge from "./customBadge/badge";
import { GetTotalSessions } from "../services/axios/Dasboard";
import { AppUrlsEnum, ImgSrcEnums } from "../enums";
import { useNavigate } from "react-router-dom";
import BookSession from "./BookSession/BookSession";
import { getPrivateMentor } from "../services/mentorServices";
import { getUserAvailabilityAsyncThunk } from "../redux-tk/slices/user.slice";
import { GetUserAvailability } from "../services/axios/MentorOnbaording";
import { getRating } from "../services/axios/session";
import Rating from "react-rating";
import { CustomImage } from "./Media";

export function MentorCards(props) {
  const {
    title,
    className,
    language,
    svglang,
    profileTitle,
    svgIconHours,
    bookmark,
    rateText,
    duration,
    skills,
    imageUrl,
    onBookmarkCLick,
    mentorId,
    key,
  } = props;

  const { formElementLabels } = useIgurooTranslations();
  const [session, setSession] = useState("");
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);
  const [user, setUser] = useState({});
  const [show, setShow] = useState(false);
  const [avail, setAvail] = useState([]);

  useEffect(() => {
    (async function () {
      if (mentorId) {
        getPrivateMentor(mentorId)
          .then((res) => {
            setUser(res?.profile);
          })
          .catch((err) => console.log(err, " user err"));
        await GetUserAvailability(mentorId)
          .then((responce) => {
            if (responce.data) {
              setAvail(responce.data);
            }
          })
          .catch((err) => console.log(err, " err"));

        await GetTotalSessions(mentorId)
          .then((resp) => {
            setSession(resp);
          })
          .catch((err) => console.log(err, "err"));

        await getRating(mentorId)
          .then((resp) => {
            // getRating(user?.userId)
            setRating(resp?.data?.response);
          })
          .catch((err) => console.log(err, "callstart rate err"));
      }
    })();
  }, [mentorId]);

  const onMentorImgCLick = () => {
    //console.log(mentorId)
    navigate(`${AppUrlsEnum.menteeMentorProfile}?mentorId=${mentorId}`);
  };
  const onBookCLick = () => {
    setShow(true);
  };

  return (
    <Col md="6" lg="4" className={`d-flex my-2 ${className}`} key={key}>
      <BookSession
        show={show}
        {...user}
        onHide={(show) => setShow(show)}
        primaryBtn={"Book"}
        secondaryBtn={"Cancel"}
        avail={avail}
        rating={rating}
        sessionsHeld={session}
      />
      <Card className="w-100">
        <div className="ig-mentor-img">
          <div role={"button"} onClick={onMentorImgCLick}>
            <CustomImage
              alt="Mentor Image"
              width={"100%"}
              roundedCircle={false}
              resizeMode={"contain"}
              className={className ? className : ""}
              crossOrigin="anonymous"
              src={{
                src: imageUrl ? imageUrl : _getImage("avatar.svg"),
                type: imageUrl ? ImgSrcEnums.props : ImgSrcEnums.normal,
              }}
            />
          </div>
          <IconLabelComponent
            className="ig-bookmark"
            svgName={bookmark ? "bookmark-icons.svg" : "bookmark.svg"}
            onClick={onBookmarkCLick}
          ></IconLabelComponent>
        </div>
        <Card.Body className="justify-content-between">
          <Card.Title onClick={onMentorImgCLick} style={{ cursor: "pointer" }}>
            <CardTitleComponent svgName={user.isTrusted ? "tick_mark.svg" : ""}>
              {title ? title : ""}
            </CardTitleComponent>
          </Card.Title>
          {/* locationText will be implement in future */}
          {/* <CardSecondaryComponent className="mt-2">
            {locationText ? locationText : "Los Andgeles, USA (-08:00 UTC)"}
          </CardSecondaryComponent> */}
          <CardSecondaryComponent
            className="mt-2"
            svgName={svglang ? svglang : "globe.svg"}
          >
            {language ? language : ""}
          </CardSecondaryComponent>
          <CardTextComponent className="mt-2 profile-title  d-flex justify-content-center align-items-center">
            {profileTitle ? profileTitle : ""}
          </CardTextComponent>
          <div className=" mt-2 justify-content-center text-center">
            {skills
              ? skills.map((skill) => (
                  <CustomBadge text={skill.value} type="orange" />
                ))
              : ""}
          </div>
        </Card.Body>
        <Card.Footer className="border-0 pt-0">
          <Row className="mb-3 mx-auto">
            <Col className="px-0 mt-2 d-flex justify-content-between">
              <Rating
                placeholderRating={rating ? rating : 0}
                readonly={true}
                emptySymbol={
                  <img
                    height={20}
                    width={20}
                    src={_getImage("empty_star.svg")}
                    className="icon"
                  />
                }
                placeholderSymbol={
                  <img
                    height={20}
                    width={20}
                    src={_getImage("full_star.svg")}
                    className="icon"
                  />
                }
                fullSymbol={
                  <img
                    height={20}
                    width={20}
                    src={_getImage("full_star.svg")}
                    className="icon"
                  />
                }
              />
              <IconLabelComponent
                svgName={svgIconHours ? svgIconHours : "hours.svg"}
                className="rating-hours"
              >
                `{session ? `${session}` : "0"}
              </IconLabelComponent>
            </Col>
            {/* Likes & Bookmark will be implement in future */}
            {/* <Col xs="3" lg="6" xl="3" className="px-0 mt-2">
              <IconLabelComponent
                svgName={svgIconLikes ? svgIconLikes : "likes.svg"}
                onClick={onLikeClick}
                text={likes ? likes : "0"}
              />
            </Col> */}
            {/* <Col xs="6" lg="12" xl="6" className="px-0 mt-2">
              <IconLabelComponent
                className="ig-bookmark"
                svgName={svgIconBM ? svgIconBM : "bookmark.svg"}
                onClick={onBookmarkCLick}
              ></IconLabelComponent>
            </Col> */}
          </Row>
          <Row className="justify-content-between d-flex ps-1">
            <Col xs="4" className="px-lg-0  px-xl-2 text-start">
              <RateDurationComponent
                rateText={rateText ? rateText : ""}
                duration={duration ? duration : ""}
              />
            </Col>
            <Col xs="8" className=" justify-content-end text-end">
              <SubmitButton
                text={formElementLabels?.bookSession}
                onClick={onBookCLick}
              />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </Col>
  );
}
