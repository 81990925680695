import * as yup from "yup";
export function EmailPopupSV(formErrors) {
  return yup.object({
    emailAdding: yup.string().email().required(formErrors?.supervisorEmail),
    dateField: yup.string().required(formErrors?.supervisorDOB),
    timeField: yup.string().required(formErrors?.supervisorTime),
    availability: yup.string().required(formErrors?.supervisorRadioBtn),
  });
}

export function UserPopupSV(formErrors) {
  return yup.object({
    dateField: yup.string().required(formErrors?.supervisorDOB),
    timeField: yup.string().required(formErrors?.supervisorTime),
    availability: yup.string().required(formErrors?.supervisorRadioBtn),
  });
}

export function actionMailPopupForm() {
  return yup.object({
    subject: yup.string().required("Subject should not be blank"),
    messageMail: yup.string().required("Message should not be blank"),
  });
}
