//** Common LearnerProducted Route -> After Signin only have to Redirect to the Page **//
//** This File is fo Only Assessment,otherwise will got error PageNotFound **//

import React, { useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AppUrlsEnum, AuthStatus, ProfileType } from "../enums";
import { AuthContext } from "../hooks/auth.context.provider";
import { useNavigate } from "react-router-dom";

export const AdminProtectedRoute = ({ children }) => {
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const routeLink = localStorage.getItem("routeLink");
  if (
    authContext.authStatus !== AuthStatus.SignedInTokensGot ||
    authContext.sessionInfo.role !== ProfileType.admin
  ) {
    if (authContext.authStatus === AuthStatus.SignedInTokensGot) {
      if (routeLink) {
        navigate(routeLink);
      } else {
        navigate(-1);
      }
    } else if (authContext.authStatus === AuthStatus.SignedOut) {
      return (
        <Navigate
          to={AppUrlsEnum.authSignin}
          state={{ from: location }}
          replace
        />
      );
    }
  }
  return children;
};

const LearnerProtectedRoute = ({ children }) => {
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const routeLink = localStorage.getItem("routeLink");

  useEffect(() => {
    if (
      authContext.authStatus !== AuthStatus.SignedInTokensGot ||
      authContext.sessionInfo.role !== ProfileType.learner
    ) {
      if (authContext.authStatus === AuthStatus.SignedInTokensGot) {
        if (routeLink) {
          navigate(routeLink);
        } else {
          navigate(-1);
        }
      } else if (authContext.authStatus === AuthStatus.SignedOut) {
        const thePath = location;
        const lastItem = thePath.search.split("=");
        const search = new URLSearchParams(thePath.search);

        const transcript = search.get("tab");
        if (transcript != null) localStorage.setItem("tab", transcript);
        const attempt = search.get("attempt_id");
        if (attempt != null)
          localStorage.setItem("transcriptattempt_id", attempt);

        return (
          <Navigate
            to={AppUrlsEnum.authSignin}
            state={{ from: location }}
            replace
          />
        );
      }
    }
  }, [authContext, navigate, routeLink]);

  return children;
};

export default LearnerProtectedRoute;
