import { RatesTimeFrame } from "../enums";

//** Mentor Basic Profile Data **//
export const userDataToMentorProfile = (profile) => {
  return {
    bio: profile?.bio,
    profileTitle: profile?.profileTitle,
    city: profile?.location?.city,
    state: profile?.location?.state,
    country: profile?.location?.country,
    languages: profile?.languages,
    mentoringFrom: profile?.mentoringFrom,
    gender: profile?.gender,
    profilePic: profile?.profilePic,
    profileVideoLink: profile?.profileVideoLink,
  };
};

//** Mentee Basic Profile Data **//
export const userDataToMenteeProfile = (profile) => {
  console.log("pro", profile);
  return {
    bio: profile?.bio,
    city: profile?.location?.city,
    state: profile?.location?.state,
    country: profile?.location?.country,
    languages: profile?.languages,
    dateofBirth: profile?.dob,
    gender: profile?.gender,
    profileCategory: profile?.profileCategory,
    profilePic: profile?.profilePic,
    profileVideoLink: profile?.profileVideoLink,
  };
};

//** Mentor Session Cost for Free,Monthly,Quarterly,Yearly Data **//
export function ratesToFormData(rates) {
  const finalObj = {};
  rates.forEach((val) => {
    if (!finalObj.currency) {
      finalObj.currency = val.currency;
    }
    if (val.timeFrame === RatesTimeFrame.perSession) {
      finalObj.sessionCharge = val.rate;
    } else if (val.timeFrame === RatesTimeFrame.monthly) {
      finalObj.monthlyChargeSwitchEnable = true;
      finalObj.monthlyChargeFree = val.chargeFree;
      finalObj.monthlyCharge = val.rate;
    } else if (val.timeFrame === RatesTimeFrame.quarterly) {
      finalObj.quarterlyChargeSwitchEnable = true;
      finalObj.quarterlyCharge = val.rate;
    } else if (val.timeFrame === RatesTimeFrame.yearly) {
      finalObj.yearChargeSwitchEnable = true;
      finalObj.yearCharge = val.rate;
    }
  });
  return finalObj;
}
