//** Iguroo Assessment Image card Component**//

import React from "react";
import { Row, Image, Col } from "react-bootstrap";

const ImageCard = ({
  imageSrc,
  belowImgText,
  width,
  height,
  className,
  textClassName,
}) => {
  return (
    <Row>
      <Col className="image-card-container">
        <Image
          className={`${className}`}
          src={imageSrc}
          width={width}
          height={height}
        />
        {belowImgText ? <p className={textClassName}>{belowImgText}</p> : <></>}
      </Col>
    </Row>
  );
};

export default ImageCard;
