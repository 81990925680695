import moment from "moment";
import React, { useEffect, useState } from "react";
import "./supervisor.scss";

export const TodoGridName = ({ value }) => {
  return (
    <div className="d-flex align-items-center">
      <span className="name">{value}</span>
    </div>
  );
};

export const formatDate = ( props ) => {
  console.log(props, "props start time")
  const value = props?.colDef?.field == "start_datetime"? parseInt(props?.value)+1 :parseInt(props?.value);
  return (
    <div>
      {props?.value
        ? moment(value).format(process.env.REACT_APP_DATE_FORMAT)
        : ""}
    </div>
  );
};
