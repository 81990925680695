//** Iguroo Pagination for Mentor & Mentee Aggrid **//

import "./pagination.css";

const Pagination = (props) => {
  const {
    moveToNextPage,
    moveToPrevPage,
    setPageToLast,
    resetPage,
    currentPage,
    totalItems,
    size,
  } = props;
  const lastPage = Math.ceil(totalItems / size);
  const isPreviousDisabled = currentPage === 1 || totalItems < 1;
  const isNextDisabled = totalItems < 1 || currentPage === lastPage;
  const from = totalItems > 0 ? (currentPage - 1) * size + 1 : 0;
  const to =
    totalItems < 1
      ? 0
      : currentPage * size > totalItems
      ? totalItems
      : currentPage * size;
  return (
    <div className="d-flex justify-content-end mt-4 mb-4 align-items-center">
      <span className="itemCount">
        {from} to {to} of {totalItems}
      </span>
      <button disabled={isPreviousDisabled} onClick={resetPage}>{`|<`}</button>
      <button
        className="fs-18"
        onClick={moveToPrevPage}
        disabled={isPreviousDisabled}
      >{`<`}</button>
      <span className="pageCount">
        Page {totalItems > 0 ? currentPage : 0} of {lastPage}
      </span>
      <button
        className="fs-18"
        onClick={moveToNextPage}
        disabled={isNextDisabled}
      >{`>`}</button>
      <button disabled={isNextDisabled} onClick={setPageToLast}>{`>|`}</button>
    </div>
  );
};

export default Pagination;
