import moment from "moment/moment";
import { ProfileType, RequestStatus } from "../enums";
import { RatesTimeFrame } from "../enums/rates.time.frame.enums";
import jwt_decode from "jwt-decode";

export const formatStr = (str, args) => {
  return str.replace(/{{([\d\w]+)}}/g, function (match, number) {
    return typeof args[number] != "undefined" ? args[number] : match;
  });
};

export const extractNameFromS3Url = (url) => {
  const regex = /[\w\W]+\/([\w\W]+)/g;
  const arr = regex.exec(url);
  if (!arr) {
    return null;
  }
  return arr[1];
};

export const calculate_age = (dob) => {
  const today = new Date();
  const birthDate = new Date(dob); // Create a date object directly from `dob1` argument
  let age_now = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age_now--;
  }
  return age_now;
};

export const getSessionType = (session) => {
  if (session === RatesTimeFrame.perSession) {
    return "One";
  } else {
    return session;
  }
};

export const getDateFromTimestamp = (data) => {
  let timestamp = data;
  const date = moment(timestamp).format(`DD/MM/YYYY`);
  return date;
};

export const getTimeFromTimestamp = (startTime, endTime) => {
  const result = `${moment(startTime).format(`h:m`)} - ${moment(endTime).format(
    `h:m A`
  )}`;
  return result;
};

export const isMentorOrPre = (profileType) => {
  console.log(profileType);
  if (!profileType) return false;
  if (profileType.toUpperCase() === ProfileType.mentor) {
    return true;
  } else if (profileType.toUpperCase() === ProfileType.preApproval) {
    return true;
  }
  return false;
};

export const isMentor = (profileType) => {
  if (profileType && profileType.toUpperCase() === ProfileType.mentor) {
    return true;
  }
  return false;
};

export const isMentee = (profileType) => {
  if (profileType && profileType.toUpperCase() === ProfileType.mentee) {
    return true;
  }
  return false;
};

export const isTokenValid = (token) => {
  try {
    const tokenVal = jwt_decode(token);
    const tokenEx = tokenVal.exp;
    const currentDt = Date.now() / 1000;
    return tokenEx > currentDt;
  } catch (err) {
    console.log("idtoken decode error", err);
    return false;
  }
};

export const getRequestKeySession = (requests) => {
  const convertedObj = {};
  requests?.forEach((val) => {
    if (!convertedObj[val.requestNumber]) {
      convertedObj[val.requestNumber] = val;
    }
  });
  return convertedObj;
};

export const getRequestKeyUser = (users, userToUse) => {
  const convertedObj = {};
  users?.forEach((val) => {
    const userToUseInner = userToUse ? userToUse : "userId";
    if (!convertedObj[val[userToUseInner]]) {
      convertedObj[val[userToUseInner]] = val;
    }
  });
  return convertedObj;
};

export const completedSessionsCommonHelper = (sessions) => {
  const completedSessionLength = sessions?.filter((val) => {
    if (val.status.toUpperCase() === RequestStatus.completed) {
      return val;
    } else {
      return null;
    }
  })?.length;
  return completedSessionLength ? completedSessionLength : 0;
};

export const mergeRequestAndSessions = (
  requests,
  sessions,
  users,
  userTypeToUse
) => {
  console.log("all users", users);
  for (let i = 0; i < requests?.length; i++) {
    const req = requests[i];
    const request = sessions[req?.requestNumber];
    const user = users ? users[req[userTypeToUse]] : {};
    if (user) {
      console.log("all users single", user);
      const { profilePic, firstName, lastName } = user;
      req.userData = {
        profilePic,
        firstName,
        lastName,
      };
    }
    if (request) {
      const requestSessions = request?.sessions;
      req.sessionsCompleted = completedSessionsCommonHelper(requestSessions);
      req.allSessions = requestSessions;
      console.log("sessoins request", requestSessions);
      const subtext =
        moment
          .unix(requestSessions[0]?.startTime)
          .format("DD/MM/YYYY - hh:mm A - ") +
        moment.unix(requestSessions[0]?.endTime).format("hh:mm A");
      req.subtext = `${subtext}`;
      req.lastSessionStartTime = moment
        .unix(new Date(requestSessions[0]?.startTime))
        .format("DD/MM/YYYY . hh:mm A");
    }
  }
  return requests;
};
