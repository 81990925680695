//** Iguroo & GinieHealth Common CustomInput Filed Component,can re-use any required field **//

import React from "react";
import { Form } from "react-bootstrap";
import "./custominput.css";

export function CustomInputField(props) {
  const {
    placeholder,
    required,
    name,
    hookOnChange,
    onChange,
    hookOnBlur,
    hookValue,
    type,
    className,
    defaultValue,
    disabled,
    formAs,
    rows,
    min,
    max,
    inputStyle,
    maxLength,
    ariaDescribedBy,
    disableAll,
  } = props;
  console.log("custom input 165 ", name, disabled);

  return (
    <>
      <Form.Label></Form.Label>
      <Form.Control
        {...(formAs && { as: formAs })}
        {...(rows && { rows: rows })}
        type={type ? type : "text"}
        style={{ ...inputStyle }}
        className={`custom-input ${className}`}
        placeholder={placeholder}
        name={name}
        required={required}
        // formNoValidate={true}
        onChange={(val) => {
          if (type == "date" && hookOnChange) {
            const datee = val.target.value.split("-");
            if (datee[0].length < 5) {
              hookOnChange(val);
            }
          } else if (hookOnChange) hookOnChange(val);
          if (type == "date" && onChange) {
            console.log("LOG DATE ", val.target.value);
            onChange(val.target.value);
          } else if (onChange) {
            onChange(val.target.value);
          }
        }}
        {...(hookValue && { value: hookValue })}
        onBlur={(val) => {
          if (hookOnBlur) hookOnBlur(val);
        }}
        {...(defaultValue && { defaultValue: defaultValue })}
        disabled={disabled}
        min={min}
        max={max}
        {...(maxLength && { maxLength: maxLength })}
        {...(ariaDescribedBy && { "aria-describedby": ariaDescribedBy })}
        onKeyDown={(e) => {
          console.log(e, "key");
          if (
            ["-", "e", "+", "E", "ArrowUp", "ArrowDown"].includes(e.key) &&
            type === "number"
          ) {
            e.preventDefault();
          }
          if (disableAll === true) {
            e.preventDefault();
          }
        }}
      />
    </>
  );
}
