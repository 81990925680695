//** GinieHealth Reovked Action Component UI **//

import React, { useState } from "react";
import { useEffect } from "react";
import { useIgurooTranslations } from "../../hooks";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { RevokeAssignmentApi } from "@iguroo/ig-react-assessment-package";
import Toaster from "../../components/toaster";
import { toast } from "react-toastify";

const ActionCellRendererRevoked = (props) => {
  const { genieLabsTranslations } = useIgurooTranslations();
  const { show, onHide } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onHide(true);
    props?.updateList();
    // console.log("updateList")
  };

  useEffect(() => {
    setIsModalOpen(show);
  }, [show]);

  const onSubmit = () => {
    setIsSubmitting(true);
    console.log("LOG Api Call =>> CreateUserAssignmentApi", props);
    const d = RevokeAssignmentApi({
      assessment_version_id: props?.assessment_version_id,
      userId: props?.assessments,
    })
      .then(() => {
        props?.updateList();

        
        Toaster(toast.success, "Revoke Successfully");
        props.LoadPage();
        setIsSubmitting(false);
        handleCloseModal();
        // console.log("updateList")
      })
      .catch((err) => {
        Toaster(toast.error, "Could not update");
        setIsSubmitting(false);
      });
  };
  return (
    <>
      {isModalOpen && (
        <Modal show={isModalOpen} onHide={handleCloseModal}>
          <Modal.Header closeButton style={{ borderBottom: "none" }}>
            <Modal.Title className="modal-title w-100">
              {genieLabsTranslations?.confrimHead}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <Row className="d-flex align-items-center justify-content-center">
              {genieLabsTranslations?.revokeConfirm}
            </Row>
            <Row className="mt-3">
              <Col className="d-flex align-items-end justify-content-end">
                <Button
                  className="cancel-button"
                  onClick={handleCloseModal}
                  style={{ border: "2px solid #EE4C4C" }}
                >
                  {genieLabsTranslations?.noBtn}
                </Button>
              </Col>
              <Col>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={onSubmit}
                  disabled={isSubmitting}
                >
                  {genieLabsTranslations?.yesBtn}
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
export default ActionCellRendererRevoked;
