//** Common MainHeading Component,can re-use this common Component required Field **//

import React from "react";

export function MainHeadingComponent(props) {
  const { text, className } = props;
  return (
    <div className={`ig-main-heading text-center mt-2 mb-5 ${className}`}>
      {props.children?.length > 0 ? props.children : text}
    </div>
  );
}
