import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Languages } from "../enums";
import enJson from "../res/localization/languages/en.langugae.json";
import hindiJson from "../res/localization/languages/hindi.language.json";
import teluguJson from "../res/localization/languages/telugu.language.json";
import odiyaJson from "../res/localization/languages/odiya.language.json";
import tamilJson from "../res/localization/languages/tamil.language.json";
import kannadaJson from "../res/localization/languages/kannada.language.json";

export function initi18nService() {
  i18n.use(initReactI18next).init({
    resources: {
      [Languages.english]: {
        translation: enJson,
      },
      [Languages.hindi]: {
        translation: hindiJson,
      },
      [Languages.telugu]: {
        translation: teluguJson,
      },
      [Languages.odiya]: {
        translation: odiyaJson,
      },
      [Languages.tamil]: {
        translation: tamilJson,
      },
      [Languages.kannada]: {
        translation: kannadaJson,
      },
    },
    lng: Languages.english,
    fallbackLng: Languages.english,
    interpolation: {
      escapeValue: false,
    },
  });
}
