export const RatesTimeFrame = {
    monthly:"Monthly",
    perSession:"perSession",
    quarterly:"Quarterly",
    yearly:"Yearly"
}
export const RatesRadio = {
    monthly:"Monthly",
    perSession:"One Session",
    quarterly:"Quarterly",
    yearly:"Yearly"
}