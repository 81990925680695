//** GinieHealth Assessment Calculated Questions Displayed in the Pdf Popup in Transcript UI code of UserManagement**//

import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import {
  _getCurrencySymbol,
  _getImage,
} from "../../common/utils/commonFunctions";
import { useForm } from "react-hook-form";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { getContactForm } from "../../FormSchemas";
import { contactUs } from "../../services/axios/ContactUs/index";
import { Popup } from "../../components/CustomInput/popup";
import { AuthContext } from "../../hooks/auth.context.provider";
import { Document, Page } from "react-pdf";
require(`../../css/${process.env.REACT_APP_CSS_COMMON}`);

function PopPdf(props) {
  const { show, onHide } = props;
  const { formElementLabels, formErrors, dashboardTranslations } =
    useIgurooTranslations();
  const user = useSelector((state) => state.user.profile);
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    reset,
    clearErrors,
  } = useForm({
    resolver: yupResolver(getContactForm(formErrors)),
    defaultValues: { email: "", mobileNumber: "", firstname: "", lastname: "" },
  });
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const attrs = authContext?.attrInfo;
    if (attrs?.length > 0) {
      attrs?.map((user) => {
        const data = JSON.parse(user);
        if (data.Name === "phone_number") {
          setValue("mobileNumber", data.Value);
        }
        if (data.Name === "email") {
          setValue("email", data?.Value);
        }
      });
    }
  }, [authContext?.attrInfo]);

  const [value, setInputValue] = useState("");
  const [error, setErrors] = useState(false);

  const handleChange = (event) => {
    if (event.target.value.length <= 34) {
      setInputValue(event.target.value);
      setErrors(false);
    } else {
      setErrors(true);
    }
  };

  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    console.log("checkinggg", data);
    try {
      const res = await contactUs(data);
      setShowPopup(true);
      console.log(res);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleGotIt = () => {
    setShowPopup(false);
    onHide(true);
    reset({
      firstname: "",
      lastname: "",
    });
  };

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [renderNavButtons, setRenderNavButtons] = useState(false);

  const onSuccess = () => {
    setPageNumber(1);
    setRenderNavButtons(true);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };
  const nextPage = () => {
    changePage(+1);
  };

  return (
    <>
      <Popup
        closeOnBackClick={false}
        showPopup={showPopup}
        type="popup"

        text={formElementLabels?.buttonSave}
        modalBody={dashboardTranslations?.contactPopup}
        gotIt={handleGotIt}
      />
      <Modal
        size={"lg"}
        show={show}
        backdrop="static"
        onHide={() => {
          clearErrors();
          return onHide(false);
        }}
        className="book-session"
      >
        <Modal.Header closeButton>
          <Modal.Title>{"Transcript"}</Modal.Title>
          {props?.showDownload ? (
            <a href={props.url} download="pdf" target="_blank">
              <Button variant="primary" className="downloadBtn">
                Download
              </Button>
            </a>
          ) : (
            ""
          )}
        </Modal.Header>
        <Form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body scrollable={true}>
            <Document
              file={props.url}
              onLoadSuccess={({ numPages }) => {
                setNumPages(numPages);
                setRenderNavButtons(true);
                onSuccess();
              }}
            >
              <Page
                pageNumber={pageNumber}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                customTextRenderer={false}
                className="pdf-page"
              />
            </Document>
            <p>
              Page {pageNumber} of {numPages}
            </p>
            {renderNavButtons && (
              <div>
                <Button
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  variant="primary"
                >
                  Previous Page
                </Button>
                {"  "}
                <Button
                  disabled={pageNumber === numPages}
                  onClick={nextPage}
                  variant="primary"
                >
                  Next Page
                </Button>
              </div>
            )}
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}

export default PopPdf;
