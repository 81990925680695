
export const requestInterceptor = (instance) => {
    instance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("idToken");
        //console.log("authen",token);
        if (token && config.headers) {
          config.headers['Authorization'] = `${token}`;
        }
        return config;
      },
      (error) => {
        console.log("error in first req interceptor",error);
        Promise.reject(error);
      },
    );
  };

