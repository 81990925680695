import { AppUrlsEnum } from "./app.url.enums";
import { ProfileType } from "./profile.type.enum";

export const OnboardingSteps = {
    [ProfileType.mentor]:{
        1:{
            value: 1,
            url: AppUrlsEnum.mentorProfile
        },
        2:{
            value: 2,
            url: AppUrlsEnum.mentorAreaOfExperience
        },
        3:{
            value: 3,
            url: AppUrlsEnum.mentorWorkExperience
        },
        4:{
            value: 4,
            url: AppUrlsEnum.mentorEducation
        },
        5:{
            value: 5,
            url: AppUrlsEnum.mentorAvailability
        },
        
        6:{
            value: 6,
            url: AppUrlsEnum.mentorRates
        },
    },
    [ProfileType.mentee]:{
        1:{
            value: 1,
            url: AppUrlsEnum.menteeProfile
        },
        2:{
            value: 2,
            url: AppUrlsEnum.menteeEduAndWork
        },
        3:{
            value: 3,
            url: AppUrlsEnum.menteeSkill
        }
    }
}