export const AppUrlsEnum = {
  landingPage: "https://iguroo.com",
  homepage: "/",
  facebookPage: " https://www.facebook.com/iGuroo.fb/ ",
  instagramPage: "https://www.instagram.com/iguroo.insta/",
  twitterPage: "https://twitter.com/iGurooSocial/",
  linkedInPage: "https://www.linkedin.com/company/iguroo/",
  youtubePage: "https://www.youtube.com/@iguroochannel/",
  termOfusePage: "https://iguroo.com/terms-and-conditions/",
  policyPage: "https://iguroo.com/privacy-policy/",
  mentorAreaOfExperience: "/mentor/aoe",
  menteeDashboard1: "/mentee/dashboard",
  menteeSkill: "/mentee/skillProficiency",
  menteeEduAndWork: "/mentee/educationExperience",
  menteeProfile: "/mentee/profile",
  mentorWorkExperience: "/mentor/work-exp",
  mentorEducation: "/mentor/education",
  mentorAvailability: "/mentor/availability",
  mentorProfile: "/mentor/profile",
  mentorDashboard1: "/mentor/dashboard",
  mentorRates: "/mentor/rates",
  mentorMyRequests: "/mentor/requests",
  authSignup: "/auth/signup",
  authSignupMentor:
    "/auth/signup/wpoienaswpsdnpaoifjwpiasqkjnskjhdfiuwadfnkjskllaojiw",
  mentorDashboard: "/dashboard/mentor",
  menteeDashboard: "/dashboard/mentee",
  mentorRequests: "/dashboard/mentor/requests",
  mentorMentees: "/dashboard/mentor/mentees",
  mentorMenteeProfile: "/dashboard/mentor/mentee",
  mentorMeassages: "/dashboard/mentor/messages",
  menteeMentors: "/dashboard/mentee/mentors",
  menteeMentorProfile: "/dashboard/mentee/mentor",
  menteeMessages: "/dashboard/mentee/messages",
  menteeRequests: "/dashboard/mentee/requests",
  mentorSettings: "/dashboard/mentor/settings",
  menteeSettings: "/dashboard/mentee/settings",
  menteeAssessments: "/dashboard/mentee/assessmen",
  mentorLive: "/dashboard/mentor/live/:userName/:roomId",
  menteeLive: "/dashboard/mentee/live/:userName/:roomId",
  mentorRequestDetails: "/dashboard/mentor/requests/:id",
  menteeRequestDetails: "/dashboard/mentee/requests/:id",
  mentorDetailsPublicPage: "/mentordetails/:mentorId",
  adminMentorDetailsPublicPage: "/admin/mentordetails/:mentorId",
  adminMenteeDetailsPublicPage: "/admin/menteedetails/:menteeId",
  unauthorizedMentorProfile: "/mentor-profile",
  liveSession: "/livesession/:requestId/:sessionId/:roomId",
  findMentor: "/dashboard/mentee/findMentor",
  verifyMobile: "/auth/verify-mobile",
  authSignin: "/auth/signin",
  authSigninSocial: "/auth/signin/social",
  requestPage: "/dashboard/mentor/requests/id",
  authForgetPassword: "/auth/forgotpassword",
  authResetPassword: "/auth/resetpassword",
  authUserOtp: "/auth/mobileuser/otp",
  testAssesmentLists: "/assessments",
  adminDashboard: "/admin",
  adminUsers: "/admin/users",
  adminUserManagement: "/admin/Adminusermanagement",
  adminSiteAdministration: "/admin/side-administration",
  adminMentee: "/admin/:menteeId",
  adminProfilePublicPrivate: "/admin/mentor-profile-management",
  assessmentReports: "/assessment-reports",
  testAssesment: "/assessments/selfdiscovery",
  genieLabtestAssesment: "/admin/testAssessments",
  swotAnalysis: "/dashboard/mentee/swot",
  swotAnalysisMentor: "/dashboard/mentor/swot",
  aboutPage: "https://iguroo.com/about-us/",
  collegesDetails: "/colleges/courses/:iguroo_institute_id",
  CollegesSummary: "/colleges/CollegesSummary",
  collegesList: "/colleges",
  testAssesmentsquestion: "/assessment",
  selfDiscovery: "/dashboard/mentee/SelfDiscovery",
  selfDiscoveryMentor: "/dashboard/mentor/SelfDiscovery",
  dndTest: "/dndtest",
  selfDiscoveryColHead: "https://iguroo.com/self-discovery/",
  mentoring: "https://iguroo.com/mentoring/",
  exploreColleges: "https://ig-web-channel.sb.iguroo.com/colleges",
  blogs: "https://iguroo.com/blog/",
  forum: "https://iguroo.com/forum/",
  approveTestimonial: "/admin/approve-testimonial",
  updateTestimonial: "/admin/update-testimonial",
  referencecode: "/auth/signup?referencecode=$referencecode",
  recommendations: "/admin/recommendations",
  myassessment: "/admin/Assessments",
  searchAssessment: "/admin/search-assessments",
  assessmentReportAdmin: "/admin/assessment-report",
  publisher: "/admin/publisher",
  assessmentDetails: "/assessment-details",
  superVisor: "/admin/recruiter",
  authorPage: "/admin/author",
  glAdminDashboard: "admin",
  pricing: "/pricing",
  logicBuilder: "/logic&reportbuilder",
  certification: "/certification",
  author: "/author-details",
  pricing2: "/pricing/:versionId/:assessmentId",
  logicBuilder2: "/logic&reportbuilder/:versionId/:assessmentId",
  certification2: "/certification/:versionId/:assessmentId",
  author2: "/author-details/:versionId/:assessmentId",

  todoSupervisor: "/admin/recruiter/details",
  authorAssessmentDetailPage: "/admin/author/assessmentDetails",

  TestScreen: "/admin/testScreen",
  assessmentReportAdmin: "/admin/assessment-report",

  newAssessment: "/genie/new-assessments",
  publisherAssessmentDetails: "/asessment-cre-details",
  publisherAssessmentDetails2:
    "/asessment-cre-details/:versionId/:assessmentId",
  intendedLearners: "/intended-learners",
  intendedLearners2: "/intended-learners/:versionId/:assessmentId",
  publisherAssessmentSettings: "/assessment-settings",
  publisherAssessmentSettings2: "/assessment-settings/:versionId/:assessmentId",
  StartScreen: "/admin/startScreen",
  Users: "/admin/userManagement",

  //supervisour
  superVisorUser: "/recruiter",
  superVisorUserTodo: "/recruiter/details",
  superVisorGinieUser: "/recruiter/userManagement",

  //Author
  authorUserAssessmentDetailPage: "/author/assessmentDetails",
  authorUserPage: "/author",

  //learner
  learnermyassessment: "/learner/Assessments",
  learnerTestScreen: "/learner/testScreen",
  learnerStartScreen: "/learner/startScreen",
  learner: "/learner",

  //publiser
  publisherUser: "/publisher",
  userSettings: "/admin/usersettings",
  supervisorUserSettings: "/recruiter/usersettings",
  recruiterTranscript: "/recruiter/transcript",
  recruiterUserAssessment: "/recruiter/userAssessment",
  //compliance
  complianceRole: "/compliance",
  compliancePublisher: "/compliance/publisher",
  complianceSuperVisor: "/compliance/Recruiter",
  complianceSvTodo: "/compliance/recruiter/details",
  complianceUserSettings: "/compliance/usersettings",
  complianceGinieSettings: "/compliance/recruiter/usersettings",
  complianceGinieUsers: "/compliance/userManagement",
  
  //stripe
  stripeSuccess: "/dashboard/mentee/payment/success",
  stripeError: "/dashboard/mentee/payment/error",

  //courses
  coursesUrl:"/dashboard/mentee/courses",
  coursesDetail:"/mentee/courses/details",
  myCoursesMentee:"/dashboard/mentee/myCourses",
  //mentor
  coursesUrlMentor:"/dashboard/mentor/courses",
  coursesDetailMentor:"/mentor/courses/details",
  myCoursesMentor:"/dashboard/mentor/myCourses",
  //admin
  coursesUrladmin:"/admin/courses",
  coursesInvite:"/admin/coursesInvite",

  
};
