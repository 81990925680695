//** Mentor Login --> Can view Mentee Details Aggrid of Requests **//
//** AgGrid Data in helpers-->gird.helpers.js File**//

import { useMemo } from "react";
import { AgGridFieldsEnum } from "../enums";
import { getMentorGridDefinitions } from "../helpers/grid.helpers";
import { useIgurooTranslations } from "../hooks/use.iguroo.translation";

export function useMentorRequestDefinitions() {
  const { gridColumnDefinitions } = useIgurooTranslations();
  const headers = useMemo(() => {
    return getMentorGridDefinitions(gridColumnDefinitions);
  }, [gridColumnDefinitions]);

  const fieldsToHide = useMemo(() => {
    return [AgGridFieldsEnum.category, AgGridFieldsEnum.status];
  }, headers);

  return { headers, fieldsToHide };
}