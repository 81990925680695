//** Iguroo Zegocloud UIkit,Recording Options,Cut Call,etc used in this UI code **//
//** Commented code will be used in future Enhancement**//

import React, { useEffect, useState, useRef } from "react";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
// import { ZegoSuperBoardManager } from "zego-superboard-web";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RateMeeting from "../../components/RateMeeting";
import {
  zegoStartRecording,
  zegoStopRecording,
  zegoPauseRecording,
  zegoResumeRecording,
} from "./Utils";
import "./styles.scss";
import { Image } from "react-bootstrap";
import Record from "../../assets/images/rec.svg";
import { AppUrlsEnum, ProfileType, RequestStatus } from "../../enums";
import { ZegoAppId, ZegoServerSecreate } from "../../services/config";
import { JoinRoom, updateSession } from "../../services/axios/session";
import { checkSessionValidity } from "../../helpers";
import moment from "moment";
import { RateMeetingTranslations } from "../../FormSchemas/rate.meeting.form";

const ZegoCloud = ({ handleNotes }) => {
  const { roomId, requestId, sessionId } = useParams();
  const navigate = useNavigate();
  const [displayButtons, setDisplayButtons] = useState(false);
  const [startRecording, setStartRecording] = useState(false);
  const [pauseRecording, setPauseRecording] = useState(false);
  const [isJoined, setIsJoined] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const [showRating2, setShowRating2] = useState(false);
  const [kitToken, setKitToken] = useState("");
  const [zegoInstance, setZegoInstance] = useState(null);
  const [showReturnBtn, setShowReturnBtn] = useState(false);
  const [showReturnText, setShowReturnText] = useState(false);
  const [callStart, setCallStart] = useState("");
  const user = useSelector((state) => state.user.profile);
  const [userlist, setUserList] = useState([]);
  const [timeCount, setTimeCount] = useState(0);
  const [timeCount2, setTimeCount2] = useState(0);
  const [timeStatus, setTimeStatus] = useState(false);
  const [timeStatus2, setTimeStatus2] = useState(false);
  const [showMenteeRating, setShowMenteeRating] = useState(false);
  const [showMentorRating, setShowMentorRating] = useState(false);
  const [showRatingPart, setShowRatingPart] = useState(false);
  const [previousValue, setPreviousValue] = useState(0);
  const videoRef = useRef();
  let startTime = "";
  useEffect(() => {
    if (!localStorage.getItem("zegoToken")) {
      JoinRoom(sessionId, requestId, user.userId)
        .then((res) => {
          if (res) {
            localStorage.setItem("zegoToken", res.token);
          }
        })
        .catch((err) => console.log(err));
    }

    if (
      ZegoServerSecreate &&
      roomId &&
      user.userId &&
      requestId &&
      user.firstName &&
      user.lastName
    ) {
      const token = ZegoUIKitPrebuilt.generateKitTokenForTest(
        parseInt(ZegoAppId),
        ZegoServerSecreate,
        roomId,
        user.userId,
        user ? `${user.firstName} ${user.lastName}` : "Guest"
      );
      setKitToken(token);
    } else if (
      roomId &&
      user.userId &&
      requestId &&
      user.firstName &&
      user.lastName
    ) {
      const token = ZegoUIKitPrebuilt.generateKitTokenForProduction(
        parseInt(ZegoAppId),
        localStorage.getItem("zegoToken"),
        roomId,
        user.userId,
        user ? `${user.firstName} ${user.lastName}` : "Guest"
      );
      setKitToken(token);
    }
  }, [user]);

  useEffect(() => {
    const menteeInterval = setInterval(() => {
      setTimeCount2((oldCount) => oldCount + 1);
    }, 1000);

    return () => {
      clearInterval(menteeInterval);
    };
  });

  useEffect(() => {
    const mentorInterval = setInterval(() => {
      setTimeCount((oldCount) => oldCount + 1);
    }, 1000);

    return () => {
      clearInterval(mentorInterval);
    };
  });

  useEffect(() => {
    if (timeCount >= 300 && showRatingPart && showMentorRating) {
      if (user?.type === ProfileType.mentor) {
        setShowRating(true);
      }
    }
  }, [showMentorRating, timeCount]);

  useEffect(() => {
    if (timeCount2 >= 300 && showRatingPart && showMenteeRating) {
      if (user?.type === ProfileType.mentee) {
        setShowRating2(true);
      }
    }
  }, [showMenteeRating, timeCount2]);

  useEffect(() => {
    let zc = null;
    if (kitToken.length > 1) {
      zc = ZegoUIKitPrebuilt.create(kitToken);
      setZegoInstance(zc);
      console.log(zc, "user &&");
      zc.joinRoom({
        sharedLinks: [
          {
            name: "Personal link",
            url:
              window.location.protocol +
              "//" +
              window.location.host +
              "/livesession/" +
              requestId +
              "/" +
              sessionId +
              "/" +
              roomId,
          },
        ],
        container: videoRef.current,
        scenario: {
          mode: ZegoUIKitPrebuilt.VideoConference,
        },
        showScreenSharingButton: true,
        showTextChat: true,
        showUserList: false,
        showPreJoinView: true,
        maxUsers: 100,
        turnOnMicrophoneWhenJoining: false,
        turnOnCameraWhenJoining: false,
        onJoinRoom: (users) => {
          console.log(zc.root, "users-joined");
          //console.log(moment().unix(),moment(),'callstart')
          const date = moment();
          console.log(user, "console user 1221");
          setCallStart(date.unix());
          startTime = date.unix();
          let currentUser = [];
          currentUser.push({
            userID: user.userId,
            userName: `${user.firstName} ${user.lastName}`,
            userType: user.type,
          });
          setUserList(currentUser);
          console.log(user, currentUser, " console user 1221");
          setIsJoined(true);
          setShowReturnText(false);
          setDisplayButtons(true);
          handleNotes(true);
          handleRoomLogout(zc);
          setShowMenteeRating(false);
          setShowMentorRating(false);
          if (user?.type === ProfileType.mentor) {
            setTimeCount(0);
            setShowRating(false);
          }
          if (user?.type === ProfileType.mentee) {
            setTimeCount2(0);
            setShowRating2(false);
          }
        },
        onLeaveRoom: (users) => {
          console.log(
            Math.abs(checkSessionValidity(startTime)),
            callStart,
            startTime,
            "user-left callstart console",
            userlist.length,
            startRecording,
            (startRecording && userlist.length >= 3) ||
              (!startRecording && userlist.length >= 2),
            checkSessionValidity(callStart) > 5 &&
              ((startRecording && userlist.length >= 3) ||
                (!startRecording && userlist.length >= 2))
          );
          if (
            Math.abs(checkSessionValidity(startTime)) > 5 &&
            ((startRecording && userlist.length >= 3) ||
              (!startRecording && userlist.length >= 2))
          ) {
            const sessionInfo = JSON.parse(
              localStorage.getItem("session_info")
            );
            const userMentee = userlist.filter(
              (userDetail) => userDetail.userType === ProfileType.mentee
            );
            const userMentor = userlist.filter(
              (userDetail) => userDetail.userType === ProfileType.mentor
            );
            console.log(userMentor, userMentee, "callStart");
            const session_info = {
              requestNumber: requestId,
              status: RequestStatus.completed.toLowerCase(),
              startTime: sessionInfo.startTime,
              endTime: sessionInfo.endTime,
              sessionNumber: sessionInfo.sessionNumber?.toString(),
              slot: {
                userId: userMentee[0].userID,
                mentorId: userMentor[0].userID,
                ...(user.userType === ProfileType.mentor && { addCount: true }),
              },
            };
            if (Math.abs(checkSessionValidity(startTime)) > 5) {
              updateSession(session_info);
            }
            //console.log(checkSessionValidity(callStart),'callStart')
          }
          setCallStart("");
          setIsJoined(false);
          setShowReturnText(true);
          setDisplayButtons(false);
          handleNotes(false, true);
          setTimeStatus(false);
          if (user?.type === ProfileType.mentor) {
            setShowMentorRating(true);
            setTimeStatus(false);
            setTimeCount(0);
          }
          if (user?.type === ProfileType.mentee) {
            setShowMenteeRating(true);
            setTimeStatus2(false);
            setTimeCount2(0);
          }
        },
        onUserJoin: (userDetails) => {
          let currentUser = {
            userID: user.userId,
            userName: `${user.firstName} ${user.lastName}`,
            userType: user.type,
          };
          const userListCheck1 = userlist.filter(
            (userDetail) => userDetail.userID === user.userId
          );
          const newUserList = userlist;
          console.log(
            currentUser,
            userDetails,
            userlist,
            userListCheck1,
            "console user 181"
          );
          if (userListCheck1.length === 0) {
            newUserList.push(currentUser);
            console.log(currentUser, userlist, "console user 189");
            // setUserList([...userlist, users]);
          }

          const users = {
            userID: userDetails[0].userID,
            userName: userDetails[0].userName,
            userType:
              user.type == ProfileType.mentee
                ? ProfileType.mentor
                : ProfileType.mentee,
          };
          console.log(users, userDetails, userlist, "console user 181");
          const userListCheck = userlist.filter(
            (userDetail) => userDetail.userID === userDetails[0].userID
          );
          // const newUserList = userlist;
          console.log(
            users,
            userDetails,
            userlist,
            userListCheck,
            "console user 181"
          );
          if (userListCheck.length === 0) {
            newUserList.push(users);
            console.log(users, userlist, "console user 197");
            // setUserList([...userlist, users]);
          }
          console.log(users, newUserList, "console user 181");
          setUserList(newUserList);
        },
      });
    }

    return () => {
      if (zc) {
        zc.destroy();
      }
      setShowReturnBtn(false);
    };
  }, [kitToken]);

  function handleStartRecording() {
    zegoStartRecording(roomId);
    setStartRecording(true);
  }
  function handleStopRecording() {
    zegoStopRecording(roomId);
    setStartRecording(false);
  }
  // function handlePauseRecording() {
  //   zegoPauseRecording(roomId);
  //   setPauseRecording(true);
  // }
  // function handleResumeRecording() {
  //   zegoResumeRecording(roomId);
  //   setPauseRecording(false);
  // }
  function handleRoomLogout(zc) {
    // setTimeout(() => {
    //   console.log("handle-room-logout");
    // zc.destroy();
    //   if (user.type === ProfileType.mentor) {
    //     navigate(AppUrlsEnum.mentorDashboard);
    //   } else {
    //     navigate(AppUrlsEnum.menteeDashboard);
    //   }
    // }, 5000);
  }

  const handleNavigate = () => {
    zegoInstance.destroy();
    navigate(`${AppUrlsEnum.menteeRequests}/${requestId}`);
    setShowReturnBtn(false);
  };

  useEffect(() => {
    setShowReturnText(false);
  }, []);

  useEffect(() => {
    if (timeCount >= 300 && userlist.length === 1) {
      handleNavigate();
    }
  }, [timeCount]);

  useEffect(() => {
    if (userlist.length >= 2) {
      setShowRatingPart(true);
      setPreviousValue(userlist.length);
    } else if (previousValue <= 1) {
      setShowRatingPart(false);
    }

    return () => {
      setShowRatingPart(false);
    };
  }, [userlist.length]);

  return (
    <div className="pt-3 zego-main-dev">
      <div
        className="zego-cloud-div"
        style={isJoined ? { height: "600px" } : {}}
        ref={videoRef}
      />
      {showReturnText && (
        <div className="text-center return-div py-2 mb-3">
          <Link
            className="return-text fw-700"
            to={
              user.type === ProfileType.mentor
                ? AppUrlsEnum.mentorDashboard
                : AppUrlsEnum.menteeDashboard
            }
          >
            Return to dashboard
          </Link>
        </div>
      )}
      {showReturnBtn && (
        <div className="d-flex justify-content-center">
          <span onClick={handleNavigate} className="fw-600 return-text">
            Return to Request Details
          </span>
        </div>
      )}
      {/* {displayButtons && user.type === ProfileType.mentor && (
        <div className="record-btns-div">
          {!startRecording && (
            <button
              className="record-button d-flex"
              onClick={handleStartRecording}
            >
              <Image height={20} width={20} src={Record} className="me-2" />{" "}
              Start
            </button>
          )}
          {startRecording && (
            <button className="record-button" onClick={handleStopRecording}>
              <Image height={20} width={20} src={Record} className="me-2" />{" "}
              Stop
            </button>
          )}
          {!pauseRecording && (
            <button className="record-button" onClick={handlePauseRecording}>
              PR
            </button>
          )}
          {pauseRecording && (
            <button className="record-button" onClick={handleResumeRecording}>
              RR
            </button>
          )}
        </div>
      )} */}
      <RateMeeting
        show={showRating}
        onHide={(show) => setShowRating(show)}
        primaryBtn={"Rate"}
        secondaryBtn={"Skip"}
        timeCount={timeCount}
      />
      <RateMeeting
        show={showRating2}
        onHide={(show) => setShowRating2(show)}
        primaryBtn={"Rate"}
        secondaryBtn={"Skip"}
        timeCount={timeCount2}
      />
    </div>
  );
};

export default ZegoCloud;
