import React, { useContext, useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import globe from "../../../assets/images/globe.svg";
import white_message from "../../../assets/images/white_message.svg";
import brief_case from "../../../assets/images/briefcase.svg";
import book_open from "../../../assets/images/book-open.svg";
import check_circle from "../../../assets/images/check-circle.svg";
import play from "../../../assets/images/play.svg";
import avatar from "../../../assets/images/avatar.svg";
import Button from "react-bootstrap/Button";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import Card from "react-bootstrap/Card";
import ProgressBar from "react-bootstrap/ProgressBar";
import CustomBadge from "../../../components/customBadge/badge";
import { useSelector } from "react-redux";
import {
  calculate_age,
  getDateFromTimestamp,
  isMentorOrPre,
} from "../../../helpers/commons.helpers";
import { menteesRequests } from "../../../services/mentorServices";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GetUser } from "../../../services/axios/MentorOnbaording";
import { GetLatestAttempt } from "../../../services/axios/Recommendation";
import { useIgurooTranslations } from "../../../hooks/use.iguroo.translation";
import moment from "moment";
import { AuthContext } from "../../../hooks/auth.context.provider";
import { ProfileImage } from "../../../components/Media";
import { useParams } from "react-router-dom";
import Admin from "../../Admin/Admin";
import { Link, useLocation } from "react-router-dom";
import { AppUrlsEnum, ProfileType } from "../../../enums";
import { CustomVideo } from "../../../components/Media";
import { AssessmentPopup } from "../../../components/assessment.popup";
import { SubmitButton } from "../../../components/submit.button";
import { GetExamAttemptCompleted } from "../../../services/axios/Exam";
import Recommendation from "../../../components/Recommendation";
import { ImgSrcEnums } from "../../../enums";

const MenteeProfile = ({ isAdmin }) => {
  const { dashboardTranslations, reportAssessmentTranslations } =
    useIgurooTranslations();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [userProfile, setUserProfile] = useState({});
  const [userEducation, setUserEducation] = useState([]);
  const [userWorkExp, setUserWorkExp] = useState([]);
  const [requests, setRequests] = useState([]);
  const authContext = useContext(AuthContext);
  const { menteeId } = useParams();
  const [imgLink, setImgLink] = useState("");
  const [newImgLink, setNewImgLink] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [newVideoLink, setNewVideoLink] = useState("");
  const [attemptId, setAttemptId] = useState("");
  const [menteeReportId, setMenteeReportId] = useState("");
  const [menteeAttempts, setMenteeAttempts] = useState([]);
  const userType = useMemo(() => {
    return authContext?.sessionInfo?.role?.toUpperCase();
  }, [authContext.authStatus]);
  const user = useSelector((state) => state.user.profile);
  const [show, setShow] = useState(false);
  const [showRecPopUp, setShowRecPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleShowPopup = () => {
    const attemptId = location.state?.attemptId;
    setShow({ userId: user.userId, attemptId });
  };

  const handleRecPopUp = () => {
    setShowRecPopUp(!showRecPopUp);
  };

  useEffect(() => {
    (async function () {
      console.log("latestattempts",searchParams.get("menteeId"))
      const res = await GetLatestAttempt(searchParams.get("menteeId"));
      console.log("latestattempts",searchParams.get("menteeId"))
      setMenteeAttempts(res);
    })();
  }, []);

  useEffect(() => {
    if (userType === ProfileType.admin && menteeId) {
      GetUser(menteeId)
        .then((res) => {
          if (res.data) {
            setUserProfile(res.data?.profile);
            setUserWorkExp(res.data?.workHistory);
            setUserEducation(res.data?.educationHistory);
          }
        })
        .catch((err) => console.log(err));
    }

    if (userType !== ProfileType.admin) {
      GetUser(searchParams.get("menteeId"))
        .then((res) => {
          if (res.data) {
            setUserProfile(res.data?.profile);
            setUserWorkExp(res.data?.workHistory);
            setUserEducation(res.data?.educationHistory);
          }
        })
        .catch((err) => console.log(err));

      menteesRequests({
        userId: searchParams.get("menteeId"),
        mentorId: authContext.sessionInfo.sub,
      })
        .then((responce) => {
          if (responce) {
            setRequests(responce);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);
  const navigate = useNavigate();

  const handleCardClick = (title, valueId) => {
    localStorage.setItem(valueId, title);
    if (isMentorOrPre(userProfile?.type)) {
      navigate(AppUrlsEnum.mentorRequestDetails.replace(":id", valueId));
    } else {
      navigate(AppUrlsEnum.menteeRequestDetails.replace(":id", valueId));
    }
  };

  useEffect(() => {
    if (userProfile?.profilePic) {
      setImgLink(userProfile.profilePic);
    }
    if (userProfile?.newProfilePic) {
      setNewImgLink(userProfile.newProfilePic);
    }
    if (userProfile?.profileVideoLink) {
      setVideoLink(userProfile.profileVideoLink);
    }
    if (userProfile?.newProfileVideoLink) {
      setNewVideoLink(userProfile.newProfileVideoLink);
    }
    if(userProfile)
      setIsLoading(false)
  }, [userProfile]);

  useEffect(() => {
    if (searchParams.get("menteeId")) {
      setMenteeReportId(searchParams.get("menteeId"));
      GetExamAttemptCompleted(searchParams.get("menteeId"))
        .then((res) => {
          const examDet = res?.data?.response?.Items;
          const result = examDet?.sort(
            (date1, date2) => moment(date2.updatedAt) - moment(date1.updatedAt)
          );
          setAttemptId(result[0]?.attemptId);
        })
        .catch((err) => console.log(err));
    }
  }, [searchParams.get("menteeId")]);

  return (
    <Container style={{ fontFamily: "manrope" }}>
      {userType === ProfileType.admin && (
        <div className="pt-3">
           <Link
        className="back-link mt-3"
        to={userType === AppUrlsEnum.adminUsers ? AppUrlsEnum.adminUsers : AppUrlsEnum.adminUserManagement}
        onClick={(e) => {
          e.preventDefault();
          navigate(userType === AppUrlsEnum.adminUsers ? AppUrlsEnum.adminUsers : AppUrlsEnum.adminUserManagement);
        }}
      >
        {userType === AppUrlsEnum.adminUsers ? "Back to Approval Management" : "Back to User Management"}
      </Link>
        </div>
      )}
  
      <Row className="mt-4">
        <Col md={4} className="mb-5">
          <Container>
            {/* <Image
              className="mentee-profile-img mb-4 d-block"
              height={90}
              width={90}
              src={userProfile.profilePic ? userProfile.profilePic : avatar}
            /> */}
            {isLoading== false ? (userType === ProfileType.admin ? (
              <ProfileImage
                className="mentee-profile-img mb-4 d-block"
                src={
                  newImgLink
                    ? newImgLink
                    : imgLink
                    ? imgLink
                    : { src: "", type: ImgSrcEnums.normal, del: true }
                }
                alt="Profile Pic"
                width={90}
                height={90}
              />
            ) : (
              <>{console.log(newImgLink, imgLink, isLoading, 'profile finalData')}
              <ProfileImage
                className="mentee-profile-img mb-4 d-block"
                src={imgLink}
                alt="Profile Pic"
                width={90}
                height={90}
              /></>
            )):
            <ProfileImage
            className="mentee-profile-img mb-4 d-block"
            src={userProfile.profilePic}
            alt="Profile Pic"
            width={90}
            height={90}
          />}
          </Container>
          <Container className="mentee-profile-info">
            <ul
              className="mentee-profile-info-list"
              style={{ listStyleType: "none", padding: 0 }}
            >
              <li>
                <h5 className="mentee-profile-name fw-700">
                  {userProfile.firstName} {userProfile.lastName}
                </h5>
              </li>
              <li className="fs-14">
                {calculate_age(userProfile?.dob)} Years /{" "}
                {userProfile?.gender?.value}
              </li>
              <li className="fs-14">
                {userProfile.location?.state.state} /{" "}
                {userProfile.location?.country.country}{" "}
                {/* {userProfile?.timeZone?.value} */}
              </li>
              <li className="fs-14">
                <Image height={15} width={15} src={globe} />{" "}
                {userProfile?.languages?.map((language, i) =>
                  userProfile.languages.length === i + 1
                    ? language.value
                    : `${language.value}, `
                )}
              </li>
              <li className="mt-3">
                <SubmitButton
                  text={reportAssessmentTranslations.selfAssessment}
                  onClick={handleShowPopup}
                  disabled={attemptId === undefined}
                />
                {show && (
                  <AssessmentPopup
                    show={show}
                    onHide={() => setShow(false)}
                    userId={menteeReportId}
                    attemptId={attemptId}
                  />
                )}
              </li>
              <li className="mt-3">
                <SubmitButton
                  text={`Recommendation`}
                  onClick={handleRecPopUp}
                  disabled={attemptId === undefined}
                />
                {showRecPopUp && (
                  <Recommendation
                    show={showRecPopUp}
                    onHide={handleRecPopUp}
                    menteeId={searchParams.get("menteeId")}
                    userId={user.userId}
                    examId={menteeAttempts[0]?.examId}
                    attemptId={menteeAttempts[0]?.attemptId}
                  />
                )}
              </li>
              {menteeAttempts.length < 1 && (
                <li className="mt-3">
                  <span>
                    Note: Reports and Recommendations will be available after
                    completion of assessment
                  </span>
                </li>
              )}
            </ul>
            {/* <Button className="mentee-profile-info-btn fs-15" size="sm">
              <Image height={15} width={15} src={white_message} />
              <span className="ms-2">{dashboardTranslations?.sendMessage}</span>
            </Button> */}
          </Container>
        </Col>
        <Col md={8}>
          <Row className="mentee-details">
            <Col xs={12} md={6}>
              <div className="d-flex">
                <Image height={20} width={20} src={brief_case} />
                <h6
                  style={{ fontWeight: 600, fontSize: "18px" }}
                  className="ms-2"
                >
                  {dashboardTranslations?.workExp}
                </h6>
              </div>
              <p style={{ fontWeight: 550, margin: 0, fontSize: "16px" }}>
                {dashboardTranslations?.working}
              </p>
              {userWorkExp.map((work) => (
                <p className="m-0 p-0 fs-14">
                  {work?.role}, {work?.company}, {work?.year} year
                </p>
              ))}
            </Col>
            <Col xs={12} md={6}>
              <div className="d-flex">
                <Image height={20} width={20} src={book_open} />
                <h6
                  style={{ fontWeight: 600, fontSize: "18px" }}
                  className="ms-2"
                >
                  {dashboardTranslations?.education}
                </h6>
              </div>
              {userEducation?.map((edu) => (
                <>
                  <p className="m-0 p-0 fs-15 fw-700">
                    {edu?.courseName?.value}
                  </p>
                  <p className="m-0 p-0 fs-14">
                    <span className="fw-600">{edu?.instituteName}, </span>
                    {edu?.specialization?.value}, {edu?.year?.value}
                  </p>
                </>
              ))}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p
                className="m-0 p-0"
                style={{ fontWeight: 600, fontSize: "16px" }}
              >
                {dashboardTranslations?.about}
              </p>
              <p className="fs-15 description">{userProfile?.bio}</p>
            </Col>
          </Row>
          {/* {showPopup && (
            <AssessmentPopup onHide={handleClosePopup} isSubmitting={false} />
          )} */}
          <Row>
            <Col>
              {userType === ProfileType.admin ? (
                <CustomVideo
                  videoLink={
                    userProfile?.isProfileVidApproved ? videoLink : newVideoLink
                  }
                />
              ) : (
                <CustomVideo videoLink={userProfile.profileVideoLink} />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex mt-4">
                <Image width={20} height={20} src={check_circle} />
                <h6
                  className="ms-2"
                  style={{ fontWeight: 600, fontSize: "16px" }}
                >
                  {dashboardTranslations?.skills}
                </h6>
              </div>
              <Container className="m-0 p-0 mt-2">
                {userProfile?.skills?.map((skill) => (
                  <CustomBadge text={skill.value} type="orange" />
                ))}
                <p className="mt-2 px-1">
                  {dashboardTranslations?.additionalComments}
                </p>
              </Container>
            </Col>
          </Row>
          {userType !== ProfileType.admin && (
            <Row className="mb-2">
              <Col>
                <Card className="mentee-details-card">
                  <Card.Header>
                    <h6
                      style={{
                        fontWeight: 600,
                        fontSize: "16px",
                        textTransform: "capitalize",
                      }}
                    >
                      {`${userProfile?.firstName}'s  Requests`}{" "}
                      <span className="float-end">
                        <CustomBadge
                          text={requests?.total?.value}
                          type="green"
                        />
                      </span>
                    </h6>
                  </Card.Header>
                  {requests?.hits?.map((request, i) =>
                    i <= 4 ? (
                      <Card.Body
                        className="border-bottom"
                        role={"button"}
                        onClick={() =>
                          handleCardClick(
                            request?._source?.title,
                            request?._source?.requestNumber
                          )
                        }
                      >
                        <h6>
                          <Image src={play} height={20} width={20} />{" "}
                          {request?._source?.title.charAt(0).toUpperCase() +
                            request?._source?.title.slice(1)}
                        </h6>
                        <Container className="m-0 p-0 ps-4">
                          {request?._source?.category?.map((cat) => (
                            <CustomBadge text={cat?.value} type="green" />
                          ))}
                          <p
                            className="mt-2 mentee-card-date"
                            style={{ fontSize: "14px" }}
                          >
                            {moment
                              .unix(request?._source?.startTime)
                              .format("DD/MM/YY - hh:mm A")}
                          </p>
                          {/* <ProgressBar
                          className="mentee-progress"
                          style={{ height: "5px" }}
                          now={60}
                        /> */}
                        </Container>
                      </Card.Body>
                    ) : (
                      <></>
                    )
                  )}
                </Card>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};
export default MenteeProfile;
