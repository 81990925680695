export const AgGridFieldsEnum = {
    title: "title",
    category: "category",
    mentorId: "mentorId",
    status: "status",
    requestNumber: "requestNumber",
    actions: "actions",
    userId: "userId",
    userData: "userData",
    actions:"actions",
    firstName:"firstName",
    lastName:"lastName",
    siNo:"SIno",
    statusAssessment:"assessmentStatus"
  };
  