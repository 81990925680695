//** Onboarding Aoe Page **//

import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Row, Col, Button, Container } from "react-bootstrap";
import { GetDataType } from "../../services/axios/staticData";
import { useNavigate } from "react-router-dom";
import { MentorSetupSidebar } from "../../components/mentor.setup.sidebar";
import { SubmitButton } from "../../components/submit.button";
import { CustomSelect } from "../../components/CustomInput/custom.select";
import { useIgurooTranslations } from "../../hooks/use.iguroo.translation";
import { getAoeFormTranslated } from "../../FormSchemas";
import { Heading } from "../../components/heading.component";
import { AlertComponent } from "../../components/alert.component";
import { FormLabelComponent } from "../../components/form.label.component";
import { AppUrlsEnum, OnboardingSteps, ProfileType } from "../../enums";
import { FormErrorComponent } from "../../components/form.error.component";
import { useDispatch, useSelector } from "react-redux";
import {
  areaOfEmphasAction,
} from "../../redux-tk/slices/mentor.onboarding.data";
import {
  getUserAsyncThunk,
  postUserProfileAsyncThunk,
  userProfileAoeValue,
} from "../../redux-tk/slices/user.slice";
import { AuthContext } from "../../hooks/auth.context.provider";
import { getAoeObject } from "../../helpers";
import { OnboardingHeading } from "../../components/onboarding.heading.component";
import Toaster from "../../components/toaster";
import { toast } from "react-toastify";
import { showPopupflag } from "../../redux-tk/slices/showPopupflag.slice";

export default function AoeScreen({ settings }) {
  const {
    mentorOnboardingLabels,
    formElementLabels,
    formErrors,
    headingTranslations,
    headerFooterTranslations,
  } = useIgurooTranslations();
  const dispatch = useDispatch();
  const userAoeValue = useSelector((state) => userProfileAoeValue(state));
  const authContext = useContext(AuthContext);
  console.log("se", userAoeValue);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: { areaOfExpertise: userAoeValue },
    resolver: yupResolver(getAoeFormTranslated(formErrors)),
  });

  const [areaOfExpertise, setAreaOfExpertise] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (isDirty) {
      (async function () {
        dispatch(showPopupflag(true));
      })();
    }
  }, [isDirty]);

  useEffect(() => {
    if (isDirty) {
      (async function () {
        dispatch(showPopupflag(true));
      })();
    }
  }, [isDirty]);

  useEffect(() => {
    (async function () {
      dispatch(getUserAsyncThunk({ userId: authContext.sessionInfo.sub }));
      const items = await GetDataType("areaOfemphasis", "english");
      console.log("items", items);
      setAreaOfExpertise(items);
    })();
  }, []);

  const onSubmit = async (data) => {
    dispatch(areaOfEmphasAction(data.areaOfExpertise));
    if (settings) {
      await dispatch(
        postUserProfileAsyncThunk(
          getAoeObject(data.areaOfExpertise, 6, authContext?.sessionInfo?.sub)
        )
      );
    } else {
      await dispatch(
        postUserProfileAsyncThunk(
          getAoeObject(
            data.areaOfExpertise,
            OnboardingSteps[ProfileType.mentor][2].value,
            authContext?.sessionInfo?.sub
          )
        )
      );
    }
    Toaster(toast.success, headerFooterTranslations.toasterMsgOnboarding);
    if (!settings) {
      navigate(AppUrlsEnum.mentorWorkExperience);
    }
  };

  useEffect(() => {
    reset({
      areaOfExpertise: userAoeValue,
    });
  }, [userAoeValue]);

  return (
    <div className="aoe-res" data-testid="Welcome to AOE Screen">
      {!settings && <OnboardingHeading profileType={ProfileType.mentor} />}
      <Container className="px-3">
        <Row>
          <Col md={settings ? 12 : 9}>
            {!settings && (
              <>
                <Heading>{headingTranslations?.wayaoe}</Heading>
                <AlertComponent showIcon={true}>
                  {headingTranslations?.aoeScreenAlert}
                </AlertComponent>
              </>
            )}
            <Form onSubmit={handleSubmit(onSubmit)} className="mt-3">
              <Form.Group>
                <Form.Label>
                  <FormLabelComponent>
                    {mentorOnboardingLabels?.areaOfEmphasis}
                  </FormLabelComponent>
                </Form.Label>
                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <CustomSelect
                      opLabel="value"
                      opValue="dataId"
                      items={areaOfExpertise}
                      onReactHookFormBlur={onBlur}
                      sendFullValueInHook={true}
                      onReactHookFormChange={onChange}
                      value={value}
                      normalSelect={true}
                      isMulti={true}
                      placeholder={formElementLabels?.placeHolders?.aoe}
                      ref={ref}
                    />
                  )}
                  name="areaOfExpertise"
                />
                <FormErrorComponent>
                  {errors.areaOfExpertise && errors.areaOfExpertise?.message}
                </FormErrorComponent>
              </Form.Group>

              <Row className="mt-1 mb-3">
                <FormErrorComponent>
                  {errors.apiError && errors.apiError?.message}
                </FormErrorComponent>
              </Row>

              <hr />
              <Row className="text-end mb-4 justify-content-end save-btn-end">
                <Col sm="12" md="2" className="d-grid">
                  <SubmitButton
                    type="submit"
                    text={formElementLabels?.save}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
          {!settings && (
            <Col>
              {mentorOnboardingLabels?.rightSetupModule?.mentor && (
                <MentorSetupSidebar
                  typeToUse={mentorOnboardingLabels?.rightSetupModule?.mentor}
                  active={
                    mentorOnboardingLabels?.rightSetupModule?.mentor?.values
                      ?.aoe
                  }
                />
              )}
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}
