//** GinieHealth Learner Login,CheckBox Question UI component code **//

import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "./component/styles.scss";
import { useForm, Controller } from "react-hook-form";
import { TestQuestionComponent } from "./component/question.component";
import CheckBoxRadioAnswerOptions from "./component/customradioOption";

const CheckboxQuestion = (props) => {
  const {
    control,
    register,
    reset,
    handleSubmit,
    placeHolders,
    watch,
    setError,
    setValue,
    getValues,
    clearErrors,
    formState: { errors, isValid },
  } = useForm();
  const options = [
    { value: "Never" },
    { value: "No Less than once a month" },
    { value: "Monthly" },
    { value: "Weekly" },
    { value: "Daily" },
  ];

  const onSubmit = () => {};

  return (
    <Container className="my-auto">
      <Row className="d-flex justify-content-center">
        <Col md={11}>
          <Row>
            <Col>
              <TestQuestionComponent text="What is your name?" queNo="02" />
            </Col>
          </Row>
          <Row className="test-answer-block p-4 mt-2">
            <Form onSubmit={handleSubmit(onSubmit)} className="mb-3">
              <Row className="mb-3">
                <Col className="select-option d-flex mb-4" md={6}>
                  <Controller
                    name={"answerOption"}
                    control={control}
                    render={({ field: { onChange, onBlur, value, name } }) => (
                      <CheckBoxRadioAnswerOptions
                        type={"checkbox"}
                        options={options}
                        hookOnChange={onChange}
                        hookOnBlur={onBlur}
                        hookValue={value}
                        name={name}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Form>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CheckboxQuestion;
