//** GinieHealth Publisher,Assessment DetailCard component UI code  **//

import { Card, Row, Dropdown, Col, Container } from "react-bootstrap";
import Rating from "react-rating";
import { _getImage } from "../../common/utils/commonFunctions";
import "./styles.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { AppUrlsEnum, ImgSrcEnums } from "../../enums";
import { CustomImage } from "../Media";
import { useEffect } from "react";
import { IconLabelComponent } from "../cardComponent/icon.label";
import moment from "moment";
import {
  card_date_display,
  card_rate_display,
  card_price_display,
} from "../../services/config";

const AssessmentCard = (props) => {
  const {
    data,
    navigateTo,
    itemsPerPage,
    totalRecords,
    currentPage,
    setCurrentPage,
    setItemsPerPage,
    onClick,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const [threeDots, setThreeDots] = useState();
  useEffect(() => {
    console.log("routing", AppUrlsEnum.publisher);
    if (
      pathname === AppUrlsEnum.publisher ||
      pathname === AppUrlsEnum.publisherUser ||
      pathname === AppUrlsEnum.compliancePublisher
    ) {
      setThreeDots(true);
    } else {
      setThreeDots(false);
    }
  }, [AppUrlsEnum.publisher]);

  const changePage = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfFirstItem = currentPage * itemsPerPage + 1;
  const indexOfLastItem = Math.min(
    (currentPage + 1) * itemsPerPage,
    totalRecords
  );

  const handleItemsPageChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(0);
  };

  const renderItemsPageSelect = () => (
    <div>
      <select
        value={itemsPerPage}
        onChange={handleItemsPageChange}
        className="custom-pageChange"
      >
        {Array.from({ length: 20 }, (_, i) => i + 1).map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );

  console.log("currentPage:", currentPage);
  console.log("itemsPerPage:", itemsPerPage);
  console.log("totalRecords:", totalRecords);
  console.log("data.length:", data.length);
  console.log("indexOfFirstItem:", indexOfFirstItem);
  console.log("indexOfLastItem:", indexOfLastItem);

  return (
    <>
      <Row className="mt-1  me-md-2 pe-md-4">
        {data.map((item, index) => {
          var currDate = new Date(new Date().getTime());
          var currDate2 = new Date((item.valid_till + 86340) * 1000);
          const diffMain = Math.ceil(
            (currDate2 - currDate) / (1000 * 3600 * 24)
          );
          return (
            <Col sm={12} md={4} lg={3} className="p-0">
              <Card
                key={index}
                className="m-0 p-2 mx-2 my-2 shadow border-0"
                // style={{ width: "15rem" }}
                role="button"
                onClick={() =>
                  onClick
                    ? onClick(item?.assessment_id, item?.version)
                    : navigate(navigateTo)
                }
              >
                <div className="img-container">
                  {item.banner_image ? (
                    <CustomImage
                      src={{
                        src: `${item?.banner_image}`,
                        type: ImgSrcEnums.props,
                      }}
                      height="150px"
                      width="100%"
                    />
                  ) : (
                    <img
                      height="150px"
                      width="100%"
                      src={_getImage("default_logo_new.jpg")}
                      className="icon"
                    />
                  )}
                </div>
                <Row>
                  <Col>
                    <div className="title my-1">
                      <span>{item.title}</span>
                      {/* <p>
              
              </p> */}
                    </div>
                  </Col>
                  {threeDots && (
                    <Col xs={1} className="rating">
                      <Dropdown className="icon-container">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <p className="three-dots">...</p>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>Publish</Dropdown.Item>
                          <Dropdown.Item>Activate/Deactivate</Dropdown.Item>
                          <Dropdown.Item
                            onMouseDown={() => {
                              navigate(
                                `${AppUrlsEnum.publisherAssessmentDetails}?versionId=${item?.version}&assessmentId=${item?.assessment_id}`
                              );
                            }}
                          >
                            Edit
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  )}
                </Row>

                <div className="author">
                  <span>{item?.author_name ? item?.author_name : ""}</span>
                </div>
                <Container className="">
                  {card_rate_display ? (
                    <Row>
                      <Col xs="6" lg="12" xl="6" className="px-0 ">
                        <IconLabelComponent
                          className="ig-rate"
                          svgName={"full_blue_star.svg"}
                          text={
                            item.rating && item.rating !== 0 ? item.rating : "0"
                          }
                        ></IconLabelComponent>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </Container>
                <Container>
                  <Row>
                    {card_date_display ? (
                      <Col xs="12" md="7" lg="7" xl="7" className="px-0 ">
                        <img
                          className="me-1"
                          width="22px"
                          height="22px"
                          src={_getImage("schedule.svg")}
                        />
                        {diffMain > 0 ? (
                          <span className="validtill-date px-1">
                            {moment
                              .unix(item?.valid_till)
                              .format("ddd DD, MMM")}
                          </span>
                        ) : (
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "13px",
                              backgroundColor: "#D73D3D",
                              border: `1px solid #D73D3D`,
                              borderRadius: "4px",
                              padding: "2px 6px",
                              color: "white",
                              height: "22px",
                              width: "90px",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                            className={`text-bg-#D73D3D mt-1`}
                          >
                            Expired
                          </span>
                        )}
                      </Col>
                    ) : (
                      ""
                    )}
                    {card_price_display ? (
                      <Col
                        xs="12"
                        md="5"
                        lg="5"
                        xl="5"
                        className="px-0 text-right"
                      >
                        <span
                          className={`fees ${!item?.isFree ? " " : "free"}`}
                        >
                          {!item?.isFree ? `${item.price}` : "Free"}
                        </span>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </Container>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            forcePage={currentPage}
            pageCount={Math.ceil(totalRecords / itemsPerPage)}
            onPageChange={changePage}
            containerClassName={"assessmentBtn"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"assessmentActive"}
          />
        </Col>
        <Col className="d-flex justify-content-end mt-2">
          <div className="card-count mb-2 mr-2">
            Results: {indexOfFirstItem}-{indexOfLastItem} of {totalRecords}
          </div>
          <div>{renderItemsPageSelect()}</div>
        </Col>
      </Row>
    </>
  );
};

export default AssessmentCard;
