//** Key and Value of FormErrors Translations -> Check with Value name in en.language.json **//

export function FormErrorsTranslations(t) {
  return {
    key: "formErrors",
    value: {
      areaOfEmphasisRequired: t("formErrors.areaOfEmphasisRequired"),
      areaOfEmphasisRequiredMax: t("formErrors.areaOfEmphasisRequiredMax"),
      availabilityIsRequired: t("formErrors.availabilityIsRequired"),
      startTimeGreatedThanEndTime: t("formErrors.startTimeGreatedThanEndTime"),
      genderError: t("formErrors.genderError"),
      since: t("formErrors.since"),
      language: t("formErrors.language"),
      country: t("formErrors.country"),
      state: t("formErrors.state"),
      city: t("formErrors.city"),
      title: t("formErrors.title"),
      describErrormsg: t("formErrors.describErrormsg"),
      currencyDetail: t("formErrors.currencyDetail"),
      sessionInterval: t("formErrors.sessionInterval"),
      companyIsRequired: t("formErrors.companyIsRequired"),
      roleIsRequired: t("formErrors.roleIsRequired"),
      yearIsRequired: t("formErrors.yearIsRequired"),
      instituteNameIsRequired: t("formErrors.instituteNameIsRequired"),
      courseNameIsRequired: t("formErrors.courseNameIsRequired"),
      fieldofStudyIsRequired: t("formErrors.fieldofStudyIsRequired"),
      specializationIsRequired: t("formErrors.specializationIsRequired"),
      educationYearIsRequired: t("formErrors.educationYearIsRequired"),
      sessionRateIsRequired: t("formErrors.sessionRateIsRequired"),
      monthlyRateIsRequired: t("formErrors.monthlyRateIsRequired"),
      quarterlyRateIsRequired: t("formErrors.quarterlyRateIsRequired"),
      yearlyRateIsRequired: t("formErrors.yearlyRateIsRequired"),
      cannotBeNegative: t("formErrors.cannotBeNegative"),
      skillerror: t("formErrors.skillerror"),
      hobbies: t("formErrors.hobbies"),
      dateofBirth: t("formErrors.dateofBirth"),
      profileCategory: t("formErrors.profileCategory"),
      invalidData: t("formErrors.invalidData"),
      profileVideoLinkRequired: t("formErrors.profileVideoLinkRequired"),
      profilePicRequired: t("formErrors.profilePicRequired"),
      menteeDobGreaterThan: t("formErrors.menteeDobGreaterThan"),
      password: t("formErrors.password"),
      passwordReq: t("formErrors.passwordReq"),
      username: t("formErrors.username"),
      email: t("formErrors.email"),
      firstname: t("formErrors.firstname"),
      lastname: t("formErrors.lastname"),
      mobileNumber: t("formErrors.mobileNumber"),
      termAndPolicy: t("formErrors.termAndPolicy"),
      passwordMatch: t("formErrors.passwordMatch"),
      newPassword: t("formErrors.newPassword"),
      confirmPassword: t("formErrors.confirmPassword"),
      currentPassword: t("formErrors.currentPassword"),
      newPasswordSecurity: t("formErrors.newPasswordSecurity"),
      repeatPassword: t("formErrors.repeatPassword"),
      mobileNum: t("formErrors.mobileNum"),
      slot: t("formErrors.slot"),
      reqtitle: t("formErrors.reqtitle"),
      areasOfInterest: t("formErrors.areasOfInterest"),
      description: t("formErrors.description"),
      numberOfSessions: t("formErrors.numberOfSessions"),
      minreqtitle: t("formErrors.minreqtitle"),
      mindescription: t("formErrors.mindescription"),
      maxreqtitle: t("formErrors.maxreqtitle"),
      firstLastname: t("formErrors.firstLastname"),
      describeTitle: t("formErrors.describeTitle"),
      titleDescription: t("formErrors.titleDescription"),
      describachievements: t("formErrors.describachievements"),
      maxdescription: t("formErrors.maxdescription"),
      uploaderror: t("formErrors.uploaderror"),
      otpError: t("formErrors.otpError"),
      aboutMessage: t("formErrors.aboutMessage"),
      messageTitle: t("formErrors.messageTitle"),
      AssessmentOptionError: t("formErrors.AssessmentOptionError"),
      minAnswer: t("formErrors.minAnswer"),
      maxAnswer: t("formErrors.maxAnswer"),
      maxrAssessmentText: t("formErrors.maxrAssessmentText"),
      swotErrors: {
        pleaseFillOrDelete: t("formErrors.swotErrors.pleaseFillOrDelete"),
        cannotContainOnlySpaces: t(
          "formErrors.swotErrors.cannotContainOnlySpaces"
        ),
      },
      fieldRequired: t("formErrors.fieldRequired"),
      aplhabetRegex: t("formErrors.aplhabetRegex"),
      invalidExp: t("formErrors.invalidExp"),
      noWhiteSpace: t("formErrors.noWhiteSpace"),
      enterOnlyEmail: t("formErrors.enterOnlyEmail"),
      enterOnlyNumber: t("formErrors.enterOnlyNumber"),
      maxWrkExpdesc: t("formErrors.maxWrkExpdesc"),
      dobMinLimit: t("formErrors.dobMinLimit"),
      yearodExpLimit: t("formErrors.yearodExpLimit"),
      nameNumSplCharcError: t("formErrors.nameNumSplCharcError"),
      supervisorDOB: t("formErrors.supervisorDOB"),
      supervisorTime: t("formErrors.supervisorTime"),
      supervisorRadioBtn: t("formErrors.supervisorRadioBtn"),
      supervisorEmail: t("formErrors.supervisorEmail"),
      supervisorUser: t("formErrors.supervisorUser"),
    },
  };
}
