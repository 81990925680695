import { GetListOfUserProfiles } from "../../services/axios/MentorOnbaording";
import { getListFromSessionList } from "../../services/axios/requests";
import {
  getRequestKeySession,
  getRequestKeyUser,
  mergeRequestAndSessions,
} from "../commons.helpers";
import { extractDataFromOpensearch } from "../grid.helpers";

//** Mentor,Mentee,Request Aggrid Combiners**//
export const requestsCombiner = async (d, userToFetch) => {
  const data = extractDataFromOpensearch(d.hits, userToFetch);
  console.log("data got ", data);
  const promises = [
    getListFromSessionList(data.requestNumbers),
    GetListOfUserProfiles(data.userIds),
  ];
  const allPromiseData = await Promise.all(promises);
  const convertedSessions = getRequestKeySession(allPromiseData[0].response);
  const convertedUsers = getRequestKeyUser(allPromiseData[1].data);
  const mergedRequestsAndSessions = mergeRequestAndSessions(
    data.data,
    convertedSessions,
    convertedUsers,
    userToFetch
  );
  return mergedRequestsAndSessions;
};
