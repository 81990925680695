import React, {
  useContext,
  useEffect,
  useMemo,
  useState,

} from "react";
import { Col, Row, Button, Spinner } from "react-bootstrap";
import { useDispatch,useSelector } from "react-redux";

import { _getImage } from "../../common/utils/commonFunctions";
import "./Cources.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import CardAsses from "./CardAsses";
import { Link, useLocation } from "react-router-dom";

import SearchButton from "../../components/PublisherComponent/SearchButton";
import moment from "moment";
import { AppUrlsEnum, ProfileType } from "../../enums";
import { AuthContext } from "../../hooks/auth.context.provider";
import { useGetAssessments } from "../../hooks/useGetAssessments";
import { GetCourseListOrganizationApi } from "@iguroo/ig-react-assessment-package";
import { organizationValue } from "../../redux-tk/slices/general.info.slice";

//const organization_id = 'iguroo';
const Courses = (props) => {
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const organization_id = useSelector((state) => organizationValue(state));

  const userType = useMemo(() => {
    return authContext?.sessionInfo?.role?.toUpperCase();
  }, [authContext.authStatus]);
  const [isLoading, setIsLoading] = useState(true);
  const {
    assessments,
    setAssessments,
    fetchAssessments,
    fetchInitialAssessments,
    getSearchOnChange,
    getSubmit,
    setType,
    type,
  } = useGetAssessments({
    apiFunction: GetCourseListOrganizationApi,
    initialQuery: {
      organization_id: organization_id
    },

  });

  useEffect(() => {
    console.log(assessments,'assessments assessments')
    fetchInitialAssessments({ organization_id: organization_id }).then(()=>{
      setIsLoading(false);
    });
  }, []);

  console.log(assessments,'assessments assessments')
  // const courses = {
  //   data: [{
  //     courses_version_id: "bd558529-ee63-442c-b195-aadd182b13c2-1",
  //     attempts_allowed: 1,
  //     // banner_image: "9ebd098d-fea7-4fa8-8d75-4d572fc69719/images/genie-health-logo-new.jpeg",

  //     due_datetime: 1704047399999,
  //     extended_attempts: 0,
  //     isExtended: false,
  //     percentageCompleted: 0,
  //     start_datetime: 1702035207772,
  //     status: "ACTIVE",
  //     subtitle: "ULearn AWS in 24 hours",
  //     title: "Learn AWS in 2 hours",
  //     userId: "5faab0ac-0b1b-4762-bb89-3ca76a611f2c"

  //   },
  //   {
  //     courses_version_id: "bd558529-ee63-442c-b195-aadd182b13c2-1",
  //     attempts_allowed: 1,
  //     // banner_image: "9ebd098d-fea7-4fa8-8d75-4d572fc69719/images/genie-health-logo-new.jpeg",

  //     due_datetime: 1704047399999,
  //     extended_attempts: 0,
  //     isExtended: false,
  //     percentageCompleted: 0,
  //     start_datetime: 1702035207772,
  //     status: "ACTIVE",
  //     subtitle: "Learn AWS in 24 hours",
  //     title: "Learn AWS in 24 hours",
  //     userId: "5faab0ac-0b1b-4762-bb89-3ca76a611f2c"

  //   }
  //   ],
  //   totalCoursesCount: 2
  // }

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();





  const rowHeight = 80;
  const searchOnChange = () => {

  }
  const submit = () => {

    const date = new Date();
    console.log('submit\n new Date()', date, '\nmoment(new Date()).format()', moment(date).format())
  }
  return (
    <>
      <div className="m-2 p-2 CourseContainer">
      {/* {isLoading && (
        <div className="loader-background"> 
          <div className="stripe_loader">
            <Spinner animation="border" variant="primary" />
          </div>
        </div>
      )} */}
        <div className="mt-4 status-button-myassessment">
          <Row className="d-flex align-items-end">
            <Col sm={12} md={6} lg={6}>

            </Col>
            <Col
              sm={12}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              className="d-flex justify-content-end"
            >
              <SearchButton submit={submit} handleOnChange={searchOnChange} />
            </Col>
          </Row>

        </div>

        {assessments?.length > 0 &&
          (
            <CardAsses
              data={assessments}
              itemsPerPage={itemsPerPage}
              totalRecords={assessments?.length}
              setCurrentPage={setCurrentPage}
              setItemsPerPage={setItemsPerPage}
              currentPage={currentPage}
              showProgress={false}
              showStatus={false}
              navig={userType !== ProfileType.admin ? AppUrlsEnum.coursesInvite : AppUrlsEnum.coursesInvite}
            />

          )}
          {isLoading && (
        <div className="loading"> 
         <h3>Loading ......</h3>
        </div>
      )} 
      </div>
    </>
  );
};
export default Courses;
