//** GinieHealth Learner Login,Heading component of Assesment Name UI component,can re-use it required field **//

import React from "react";

export function MultiMultiTest(props) {
  const { text, className, svgName } = props;
  const headingText = props.children?.length > 0 ? props.children : text;
  // console.log(ques,'console')

  const textDisplay = headingText?.split("_").join(" ");
  return <div className={`ig-heading ${className}`}>{textDisplay}</div>;
}
