//** Iguroo Onboarding &EditAccount Mentor and Mentee WorkExperience Component for Role,Company & YearofExp **//

import React, { useState } from "react";
import { useIgurooTranslations } from "../hooks/use.iguroo.translation";
import { Controller } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import { CustomDeleteBtn } from "./CustomInput/customDeleteBtn";
import { FormErrorComponent } from "./form.error.component";
import { FormLabelComponent } from "./form.label.component";
import { CustomInputField } from "./CustomInput/customInputField";
import { MentorOnboardingLabels } from "../res/localization";
import { ProfileType } from "../enums";

export function WorkExpItemComponent(props) {
  const { itemIndex, hookControl, removeFunction, errors, profileType } = props;
  const { formElementLabels, formErrors } = useIgurooTranslations();
  const removeRow = () => {
    removeFunction(itemIndex);
  };
  const [value, setInputValue] = useState("");

  const [errorMsg, setErrorsmsg] = useState(false);
  const handleChanges = (event) => {
    if (event.target.value.length <= 254) {
      setInputValue(event.target.value);
      setErrorsmsg(false);
    } else {
      setErrorsmsg(true);
    }
  };

  const [error, setErrors] = useState(false);
  const handleChange = (event) => {
    if (event.target.value.length <= 254) {
      setInputValue(event.target.value);
      setErrors(false);
    } else {
      setErrors(true);
    }
  };

  return (
    <Row className="mt-3">
      <Controller
        name={
          profileType === ProfileType.mentee
            ? `wrk.${itemIndex}.id`
            : `workExp.${itemIndex}.id`
        }
        control={hookControl}
        render={({ field: { onChange, onBlur, value } }) => (
          <Form.Control
            type="hidden"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          ></Form.Control>
        )}
      />
      <Col md="4" className="mt-3">
        <label>
          {" "}
          <FormLabelComponent>{MentorOnboardingLabels.role}</FormLabelComponent>
        </label>
        <Controller
          name={
            profileType === ProfileType.mentee
              ? `wrk.${itemIndex}.role`
              : `workExp.${itemIndex}.role`
          }
          control={hookControl}
          render={({ field: { onChange, onBlur, value } }) => (
            <CustomInputField
              placeholder={formElementLabels?.placeHolders?.role}
              hookOnBlur={onBlur}
              hookValue={value}
              hookOnChange={(event) => {
                handleChange(event);
                onChange(event);
              }}
              maxLength={255}
            />
          )}
        />
        {error && (
          <FormErrorComponent>{formErrors.maxdescription}</FormErrorComponent>
        )}
        <FormErrorComponent>
          {profileType === ProfileType.mentee
            ? errors?.wrk?.length > 0 &&
              errors?.wrk[itemIndex]?.role &&
              errors?.wrk[itemIndex].role?.message
            : errors?.workExp?.length > 0 &&
              errors?.workExp[itemIndex]?.role &&
              errors?.workExp[itemIndex].role?.message}
        </FormErrorComponent>
      </Col>
      <Col md="4" className="mt-3">
        <label>
          {" "}
          <FormLabelComponent>
            {MentorOnboardingLabels.company}
          </FormLabelComponent>
        </label>
        <Controller
          name={
            profileType === ProfileType.mentee
              ? `wrk.${itemIndex}.company`
              : `workExp.${itemIndex}.company`
          }
          control={hookControl}
          render={({ field: { onChange, onBlur, value } }) => (
            <CustomInputField
              placeholder={formElementLabels?.placeHolders?.company}
              hookOnBlur={onBlur}
              hookValue={value}
              hookOnChange={(event) => {
                handleChanges(event);
                onChange(event);
              }}
              maxLength={255}
            />
          )}
        />
        {errorMsg && (
          <FormErrorComponent>{formErrors.maxdescription}</FormErrorComponent>
        )}
        <FormErrorComponent>
          {profileType === ProfileType.mentee
            ? errors?.wrk?.length > 0 &&
              errors?.wrk[itemIndex]?.company &&
              errors?.wrk[itemIndex].company?.message
            : errors?.workExp?.length > 0 &&
              errors?.workExp[itemIndex]?.company &&
              errors?.workExp[itemIndex].company?.message}
        </FormErrorComponent>
      </Col>
      <Col md="4" className="mt-3">
        <Row>
          <Col md="10">
            <label>
              <FormLabelComponent>
                {MentorOnboardingLabels.yearofexp}
              </FormLabelComponent>
            </label>
            <Controller
              name={
                profileType === ProfileType.mentee
                  ? `wrk.${itemIndex}.year`
                  : `workExp.${itemIndex}.year`
              }
              control={hookControl}
              render={({ field: { onChange, onBlur, value } }) => (
                <CustomInputField
                  type={"number"}
                  onChange={onChange}
                  hookOnBlur={onBlur}
                  hookValue={value}
                  placeholder="Years"
                  min={0}
                />
              )}
            />
            <FormErrorComponent>
              {profileType === ProfileType.mentee
                ? errors?.wrk?.length > 0 &&
                  errors?.wrk[itemIndex]?.year &&
                  errors?.wrk[itemIndex].year?.message
                : errors?.workExp?.length > 0 &&
                  errors?.workExp[itemIndex]?.year &&
                  errors?.workExp[itemIndex].year?.message}
            </FormErrorComponent>
          </Col>
          <Col
            md="2"
            sm="12"
            className="d-flex align-items-center  justify-content-center mt-4"
          >
            {itemIndex != 0 && <CustomDeleteBtn onClick={() => removeRow()} />}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
