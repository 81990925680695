import { loadAxiosConfig, ConfigVars } from "@iguroo/ig-react-common-package";
import { StorageService } from "./StorageService";
import { STATIC_ALL_DATA } from "./urlConst";

export const IGURU_MAIN_URL = process.env.REACT_APP_IGURU_MAIN_URL;
export const IGURU_DEV_URL = process.env.REACT_APP_IGURU_DEV_URL;
export const IGUROO_PROFILE_DATA = process.env.REACT_APP_IGUROO_PROFILE_DATA;
export const IGUROO_PROFILE_DATA_EDUCATION =
  process.env.REACT_APP_IGUROO_PROFILE_DATA_EDUCATION;

export const IGUROO_PROFILE_DATA_EXPERIENCE =
  process.env.REACT_APP_IGUROO_PROFILE_DATA_EXPERIENCE;

export const IGUROO_PROFILE_MENTEE_DATA =
  process.env.REACT_APP_IGUROO_PROFILE_MENTEE_DATA;

export const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
export const S3_BUCKET_PENDING = process.env.REACT_APP_S3_BUCKET_PENDING;
export const REGION = process.env.REACT_APP_REGION;
export const STATIC_URL_DTAA = process.env.REACT_APP_STATIC_URL_DATA;
export const STATIC_QUALIFICATION = process.env.REACT_APP_STATIC_QUALIFICATION;
export const STATIC_ENDYEAR = process.env.REACT_APP_STATIC_ENDYEAR;
export const STATIC_FIELD = process.env.REACT_APP_STATIC_FIELD;
export const STATIC_ALL_DATA_n = process.env.REACT_APP_STATIC_ALL_DATA;
export const ENV = process.env.REACT_APP_ENV;
// original ones
export const userPoolId = process.env.REACT_APP_USER_POOL_ID;
// console.log(userPoolId);
export const clientId = process.env.REACT_APP_CLIENT_ID;
export const identityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID;
export const region = process.env.REACT_APP_AWS_REGION;
export const awsAmplifyDomain = process.env.REACT_APP_AWS_AMPLIFY_DOMAIN;

export const ZegoAppId = process.env.REACT_APP_ZEGO_APPID;
export const ZegoServerSecreate = process.env.REACT_APP_ZEGO_SERVER_SECREAT;

export const card_date_display = false;
export const card_rate_display = false;
export const card_price_display = false;

//test
// export const userPoolId = "us-east-1_6reWlhqYW";
// export const clientId = "17k2mduqih2qiilb1cp7qvcu4e";
// export const identityPoolId = "us-east-1:ff4d2196-62e6-42f4-8815-b4793c6c1bf5";
// export const region = "us-east-1";
// export const awsAmplifyDomain = "ig-test.auth.us-east-1.amazoncognito.com/";

export default function getEnvUrl() {
  if (ENV === "DEV" || ENV === "PROD") {
    return IGURU_DEV_URL;
  }
}

export const loadCommonPackageConfig = () => {
  ConfigVars.loadConfig({
    IGURU_MAIN_URL,
    IGURU_DEV_URL,
    IGUROO_PROFILE_DATA,
    IGUROO_PROFILE_DATA_EDUCATION,
    IGUROO_PROFILE_DATA_EXPERIENCE,
    IGUROO_PROFILE_MENTEE_DATA,
    S3_BUCKET,
    S3_BUCKET_PENDING,
    REGION,
    STATIC_URL_DATA:STATIC_URL_DTAA,
    STATIC_QUALIFICATION,
    STATIC_ENDYEAR,
    STATIC_FIELD,
    STATIC_ALL_DATA:STATIC_ALL_DATA,
    ENV,
    userPoolId,
    clientId,
    identityPoolId,
    region,
    awsAmplifyDomain,
    ZegoAppId,
    ZegoServerSecreate,
    storageHelper: StorageService,
    getEnvUrl,
    STATIC_ALL_DATA_n: STATIC_ALL_DATA_n,
    STATIC_URL_DTAA: STATIC_URL_DTAA,
  });
  loadAxiosConfig();
};
