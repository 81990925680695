//** Key and Value of TestAssessment Translations -> Check with Value name in en.language.json **//

export function TestAssessmentTranslations(t) {
  return {
    key: "testAssessmentTranslations",
    value: {
      aboutTest: t("testAssessmentTranslations.aboutTest"),
      backTest: t("testAssessmentTranslations.backTest"),
      buttonContinue: t("testAssessmentTranslations.buttonContinue"),
      resettBtn: t("testAssessmentTranslations.resettBtn"),
      previousBtn: t("testAssessmentTranslations.previousBtn"),
      nextBtn: t("testAssessmentTranslations.nextBtn"),
      submit: t("testAssessmentTranslations.submit"),
      reportBtn: t("testAssessmentTranslations.reportBtn"),
      onTestSubmission: t("testAssessmentTranslations.onTestSubmission"),
      section: t("testAssessmentTranslations.section"),
      true: t("testAssessmentTranslations.true"),
      false: t("testAssessmentTranslations.false"),
      question: t("testAssessmentTranslations.question"),
      AssessmentOptionError: t(
        "testAssessmentTranslations.AssessmentOptionError"
      ),
      minAnswer: t("testAssessmentTranslations.minAnswer"),
      maxAnswer: t("testAssessmentTranslations.maxAnswer"),
      maxrAssessmentText: t("testAssessmentTranslations.maxrAssessmentText"),
      textQuesDetail: t("testAssessmentTranslations.textQuesDetail"),
      textQuesNote: t("testAssessmentTranslations.textQuesNote"),
      Done: t("testAssessmentTranslations.Done"),
    },
  };
}
