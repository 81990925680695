//** Iguroo Assessment Page2 Component**//

import React from "react";
import { Image } from "react-bootstrap";
import { _getImage } from "../../../common/utils/commonFunctions";
import { SubmitButton } from "../../../components/submit.button";
import { useIgurooTranslations } from "../../../hooks/use.iguroo.translation";
import { AppUrlsEnum, ProfileType } from "../../../enums";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const Page_2 = ({ condition }) => {
  const { reportAssessmentTranslations } = useIgurooTranslations();

  const user = useSelector((state) => state.user.profile);
  const [menRec, setMenRec] = useState();
  const [wdt, setWdt] = useState(70);
  useEffect(() => {
    if (user.type === ProfileType.mentee) {
      setMenRec(true);
    } else {
      setMenRec(false);
    }
  }, [user.type]);

  return (
    <div className="page_2">
      <div className="mt-4">
        {condition ? (
          <>
            <h1 className="text-center blue_text">
              {reportAssessmentTranslations.congratsHeading}
            </h1>
            <p className="text-center desc-text mt-5 fs-20">
              {reportAssessmentTranslations.congratsDesc}{" "}
            </p>
          </>
        ) : (
          <>
            <h1 className="text-center blue_text">
              {reportAssessmentTranslations.detailedInfoHeading}
            </h1>
            <p className="text-center desc-text mt-5 fs-20">
              {reportAssessmentTranslations.detailedInfoSubHeading}
            </p>
          </>
        )}
      </div>
      <div className="page_2_bg_1 mt-5">
        <Image
          className="location"
          src={_getImage("location.svg")}
          height={100}
          width={100}
        />
      </div>
      <div className="page_2_bg_2 d-flex">
        <div>
          <Image src={_getImage("num_1.svg")} height={wdt} width={wdt} />
          <p className="mt-1">{reportAssessmentTranslations.selfDesc1}</p>
        </div>
        <div>
          <Image src={_getImage("num_2.svg")} height={wdt} width={wdt} />
          <p className="mt-1">{reportAssessmentTranslations.selfDesc2}</p>
        </div>
        <div>
          <Image src={_getImage("num_3.svg")} height={wdt} width={wdt} />
          <p className="mt-1">{reportAssessmentTranslations.selfDesc3}</p>
        </div>
        <div>
          <Image src={_getImage("num_4.svg")} height={wdt} width={wdt} />
          <p className="mt-1">{reportAssessmentTranslations.selfDesc4}</p>
        </div>
        <div>
          <Image src={_getImage("num_5.svg")} height={wdt} width={wdt} />
          <p className="mt-1">{reportAssessmentTranslations.selfDesc5}</p>
        </div>
        <div>
          <Image src={_getImage("num_6.svg")} height={wdt} width={wdt} />
          <p className="mt-1">{reportAssessmentTranslations.selfDesc6}</p>
        </div>
        <div>
          <Image src={_getImage("num_7.svg")} height={wdt} width={wdt} />
          <p className="mt-1">{reportAssessmentTranslations.selfDesc7}</p>
        </div>
      </div>
      {menRec && (
        <>
          <div className="d-flex mt-4 justify-content-center page_2_btn_div">
            <Link to={AppUrlsEnum.findMentor} target="_blank" rel="noopener">
              <SubmitButton
                type="submit"
                text={reportAssessmentTranslations.scheduleSession}
                className="book_session_btn"
              />
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default Page_2;